import { ReactNode, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Textarea,
  Button,
  Highlight,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Container,
  Img,
  MenuDivider,
  Stack,
  InputRightElement,
  Divider,
  Center,
  AccordionButton,
  AccordionItem,
  Accordion,
  Tooltip,
  useDisclosure,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  Icon,
  Input,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Grid,
  GridItem,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  AccordionPanel,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  InputGroup,
  FormErrorMessage,
  FormHelperText,
  Text,
} from "@chakra-ui/react";
import {
  IoMdArrowDroprightCircle,
  IoMdArrowDropleftCircle,
} from "react-icons/io";
import { FaMinus, FaPlus } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { BiWindowClose, BiCalendarEdit } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { EyeIcon, EyeSlashIcon } from "../../../assets/icons/Icons";
import SidebarOperators from "../../../components/SidebarOperators";
import { host3 } from "../../../utils/constantes";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { getMonthName } from "../../../helpers/general";
import { calcularTotalBan } from "../../../helpers/general";
import { calcularRetiros } from "../../../helpers/general";
import { calcularRecargas } from "../../../helpers/general";
import { calcularTotalInicial } from "../../../helpers/general";
import { calcularTotalTermino } from "../../../helpers/general";
import { calcularSumaTotalGanancias } from "../../../helpers/general";
import { text } from "@fortawesome/fontawesome-svg-core";
import { data } from "autoprefixer";

function Diarios() {
  let history = useHistory();
  const years = [2021, 2022, 2023, 2024];
  const token = localStorage.getItem("tokenadmin");
  const { rol, modulo, nombre_modulo, sala, nombre, meta } = jwt_decode(token);
  if (!token) {
    history.push("/");
  }
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  // ---------------------------------
  const [filter, setFilter] = useState({
    fecha: moment().format("YYYY-MM-DD"),
    modulo: "",
  });
  const [initialBox, setInitialBox] = useState([]);

  // const [selectedYear, setSelectedYear] = useState(2024);

  const [typeBox, setTypeBox] = useState("");
  const [idRegularizeBox, setIdRegularizeBox] = useState(null);

  const [boxsArray, setBoxsArray] = useState({ boxs: [] });
  const [inputValues, setInputValues] = useState([]);
  const [savedValues, setSavedValues] = useState({ boxs: [] });
  let meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  //Mensaje
  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        break;
    }
  };

  //----------------------------- ESTADOS ------------------------------------
  const [passwordAdmin, setPasswordAdmin] = useState("");
  const [dataDiary, setDataDiary] = useState({
    id_cuenta_casa: "",
    bank_initial: "",
    initial_pending: "",
    retiros: "",
    recargas: "",
    bank_final: "",
    final_pending: "",
  });

  const handleChangeDiary = ({ target: { name, value } }) => {
    setDataDiary({ ...dataDiary, [name]: value });
  };

  // -------------------------------- CUENTA CASAS ----------------------------
  const [listCasas, setListCasas] = useState([]);

  const fetchCuentaCasas = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/cuenta_casas/${token}/mostrar`
      );
      setListCasas(data.filter((itemFilter) => itemFilter.id_modulo == modulo));
    } catch (error) {
      //console.log(error);
    }
  };

  // ------------------------------ APERTURAR DIA ------------------------------
  const addBoxInital = (data) => {
    if (
      data.id_cuenta_casa == "" ||
      data.bank_initial == "" ||
      data.initial_pending == ""
    ) {
      mensaje("No se puede agregar con campos vacios", "top-right", 2);
      return;
    }

    //Validar que no se agregue la misma cuenta
    const findBox = boxsArray.boxs.filter(
      (itemFilter) => itemFilter.id_cuenta_casa == data.id_cuenta_casa
    );

    if (findBox.length == 1) {
      mensaje("No puede agregar la misma casa", "top-right", 2);
      return;
    }

    const homes = listCasas.find(
      (itemHome) => itemHome.id_cuenta_casas == data.id_cuenta_casa
    );
    boxsArray.boxs.push({
      ...data,
      casa: homes.casa.alias == null ? homes.casa.nombre : homes.casa.alias,
    });
    setDataDiary({
      id_cuenta_casa: "",
      bank_initial: "",
      initial_pending: "",
    });
    //console.log(boxsArray);
  };

  // --------------------------------------- Remover caja inicial
  const removeItemBoxInitial = (indice) => {
    delete boxsArray.boxs[indice];
    const filterRemove = boxsArray.boxs.filter(
      (itemFilter) => itemFilter.id_cuenta_casa != undefined
    );
    setBoxsArray({ boxs: filterRemove });
  };

  // ------------------------------------ Aperturar
  const handleSubmitAperturaDia = async () => {
    return await axios.post(`${host3}/api/cajas/${token}`, boxsArray);
  };

  // ------------------------------------- Cerrar
  const handleSubmitCierreDia = async () => {
    return await axios.post(
      `${host3}/api/cajas/${token}/close-final-box`,
      savedValues
    );
  };

  // -------------------------------------- Regularizar
  const handleSubmitRegularCaja = async () => {
    return await axios.put(
      `${host3}/api/cajas/${token}/regularize-box/${idRegularizeBox}`,
      {
        bank_final: dataRegularize.bank_final,
        final_pending: dataRegularize.pendiente_final,
        retiro: dataRegularize.retiros,
        comentario: dataRegularize.comentario,
      }
    );
  };

  // --------------------------- LISTAR APERTURA INICIAL ----------------------------------
  const [listApertura, setListApertura] = useState([]);

  const fetchApertura = async () => {
    try {
      const { data } = await axios.get(`${host3}/api/cajas/${token}`);
      setListApertura(data);
    } catch (error) {
      // console.log(error);
    }
  };

  const ListFiltered = () => {
    return listApertura
      ?.filter((itemData) => {
        const formatDate = moment(itemData.fecha_registro).format("YYYY-MM-DD");
        return formatDate == moment().format("YYYY-MM-DD");
      })
      .slice(currentPage, currentPage + 11);
  };

  // ------------------------------------ LISTAR CIERRE FINAL --------------------------
  const [listCierre, setListCierre] = useState([]);

  const fetchCierre = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/cajas/${token}/list-final`
      );
      setListCierre(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const ListFiltered2 = () => {
    return listCierre
      ?.filter((itemData) => {
        const formatDate = moment(itemData.fecha_registro).format("YYYY-MM-DD");
        const isToday = formatDate === moment().format("YYYY-MM-DD");
        const isInicio =
          itemData.tipo === "INICIO" &&
          itemData.fecha_cierre === null &&
          moment(itemData.fecha_registro).format("YYYY-MM-DD") !==
            moment().format("YYYY-MM-DD");
        const isFinalToday =
          itemData.tipo === "FINAL" &&
          formatDate === moment().format("YYYY-MM-DD");
        return (isToday && isFinalToday) || isInicio;
      })
      .slice(currentPage3, currentPage3 + 11);
  };

  // ----------------------------- REGULARIZAR CAJA ----------------------------------
  const [dataRegularize, setDataRegularize] = useState({
    bank_final: "",
    pendiente_final: "",
    retiros: "",
    comentario: "",
    password: "",
  });

  const handleChangeRegularize = ({ target: { name, value } }) => {
    setDataRegularize({ ...dataRegularize, [name]: value });
  };

  const fetchRegularizeBox = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/cajas/${token}/list-final`
      );
      setDataRegularize(
        data.find(
          (itemData) => itemData.id_movimiento_diario === idRegularizeBox
        )
      );
    } catch (error) {
      //console.log(error);
    }
  };

  // --------------------------------- VALIDACION DE CONTRASEÑA Y FUNCIONES ----------------
  const validatePassword = async () => {
    try {
      const { status } = await axios.post(
        `${host3}/api/cajas/${token}/validate-final-box`,
        passwordAdmin
      );

      if (status == 201) {
        if (typeBox == "inicial") {
          const { status, data } = await handleSubmitAperturaDia();
          if (status == 201) {
            onClose4();
            setBoxsArray([]);
            //console.log(data.message);
            mensaje(data.message, "top-right", 1);
            setTypeBox("");
          }
        }
        if (typeBox == "final") {
          const { status, data } = await handleSubmitCierreDia();
          if (status == 201) {
            mensaje(data.message, "top-right", 1);
            onClose5();
            setSavedValues({ boxs: [] });
          }
        }
        if (typeBox == "regular") {
          const { status, data } = await handleSubmitRegularCaja();
          if (status == 201) {
            mensaje(data.message, "top-right", 1);
            onClose3();
          }
        }
        fetchApertura();
        fetchCierre();
        //fetchHomeForModule();
        fetchReport();
        onClose2();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
      //console.log();
    }
  };

  // ------------------------------ LISTAR REPORTE ----------------------------------
  const [listReport, setListReport] = useState([]);
  const [Totalban, setTotalBan] = useState(0);
  const [retiroTotal, setRetiroTotal] = useState(0);
  const [recargaTotal, setRecargaTotal] = useState(0);
  const [inicialTotal, setInicialTotal] = useState(0);
  const [terminoTotal, setTerminoTotal] = useState(0);
  const [gananciaTotal, setGananciaTotal] = useState(0);

  const [filteredData, setFilteredData] = useState({
    fecha: moment().format("YYYY-MM-DD"),
    money: "LOCAL",
  });

  const handleFilterChange = ({ target: { name, value } }) => {
    setFilteredData({ ...filteredData, [name]: value });
  };

  const fetchReport = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/cajas/${token}/${modulo}/reports/${filteredData.fecha}/money/${filteredData.money}`
      );
      setListReport(data);
      setTotalBan(calcularTotalBan(data));
      setRetiroTotal(calcularRetiros(data));
      setRecargaTotal(calcularRecargas(data));
      setInicialTotal(calcularTotalInicial(data));
      setTerminoTotal(calcularTotalTermino(data));
      setGananciaTotal(calcularSumaTotalGanancias(data));
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    fetchReport();
  }, [filteredData.money]);

  // ------ BUSCADOR
  const searchReport = () => {
    if (filter.fecha !== "") {
      fetchReport();
      return;
    }
  };

  // ------------------------------------- LISTAR REPORTE GENERAL ----------------------------------------
  const [listReportGeneral, setListReportGeneral] = useState([]);

  const [filteredDataGeneral, setFilteredDataGeneral] = useState({
    fecha: moment().format("YYYY-MM-DD"),
    money: "LOCAL",
  });

  const fetchReportGeneral = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/cajas/${token}/${filteredDataGeneral.money}/report-general/${filteredDataGeneral.fecha}`
      );
      setListReportGeneral(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const handleFilterGeneralChange = ({ target: { name, value } }) => {
    setFilteredDataGeneral({ ...filteredDataGeneral, [name]: value });
  };

  useEffect(() => {
    fetchReportGeneral();
  }, [filteredDataGeneral.money]);

  // ------ BUSCADOR
  const searchReportGeneral = () => {
    if (filteredDataGeneral.fecha !== "") {
      fetchReportGeneral();
      return;
    }
  };

  // -------------------------------------------- LISTAR REPORTE MENSUAL -------------------------------
  const [listMonthReport, setListMonthReport] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState(1);

  const [filteredDataMonth, setFilteredDataMonth] = useState({
    modulo: "",
    money: "LOCAL",
    fecha: moment().year(),
  });

  const fetchMonthReport = async () => {
    let module_option =
      rol.includes("JEFE DE SALA") || rol.includes("ADMINISTRADOR")
        ? filteredDataMonth.modulo
        : modulo;
    try {
      const { data } = await axios.get(
        `${host3}/api/cajas/${token}/reporte-mensual-modulo/${filteredDataMonth.money}/module/${module_option}/date/${filteredDataMonth.fecha}`
      );

      // console.log(data);
      setListMonthReport(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const handleFilterMonthChange = ({ target: { name, value } }) => {
    setFilteredDataMonth({ ...filteredDataMonth, [name]: value });
  };

  const handleMonthChange = (event) => {
    const selectedMonth = parseInt(event.target.value, 10);
    setSelectedMonth(selectedMonth);
  };

  // const handleYearChange = (event) => {
  //   const selectedYear = event.target.value;
  //   setSelectedYear(selectedYear);

  //   fetchGananciaTotalAño(selectedYear);
  // };

  useEffect(() => {
    fetchMonthReport();
  }, [
    filteredDataMonth.money,
    filteredDataMonth.modulo,
    filteredDataMonth.fecha,
  ]);

  // ------------------------ MODULOS ------------------------
  const [listModule, setListModule] = useState([]);

  const fetchModules = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/modulos/${token}/list-modules-for-hall`
      );

      let datas = [];
      const dataFilter = data.filter(
        (itemFilter) => itemFilter.id_sala == sala[0]
      );
      dataFilter.map((itemData) => {
        itemData.modulos.map((itemModule) => datas.push(itemModule));
      });
      filteredDataMonth.modulo = datas[0].id_modulo;
      setListModule(datas);
    } catch (error) {}
  };

  //Regulariza caja final
  const createRegularizeBox = async () => {
    try {
      return await axios.put(
        `${host3}/api/cajas/${token}/regularize-box/${idRegularizeBox}`,
        {
          bank_final: dataRegularize.bank_final,
          final_pending: dataRegularize.pendiente_final,
          retiro: dataRegularize.retiros,
        }
      );
    } catch (error) {
      //console.log(error);
    }
  };

  const saveFinalBoxs = () => {
    let validationData = false;

    const filterDataNotNull = inputValues.filter((item) => item !== undefined);

    if (filterDataNotNull.length < 1) {
      mensaje(
        "Para cerrar tiene que guardar minimo 1 casa...!",
        "top-right",
        2
      );
      return;
    }

    filterDataNotNull.map((itemData) => {
      if (
        itemData.bank_final == undefined ||
        itemData.final_pending == undefined
      ) {
        mensaje("Existe al menos un dato obligatorio...!", "top-right", 2);
        validationData = false;
        return false;
      }

      if (itemData.bank_final == "" || itemData.final_pending == "") {
        mensaje("Existe al menos un dato obligatorio...!", "top-right", 2);
        validationData = false;
        return false;
      }

      validationData = true;
    });

    if (validationData) {
      setSavedValues({ boxs: filterDataNotNull });
      onOpen2();
    }
  };

  // --------------------------------------------------------------------------------------------

  //Paginacion Apertura dias
  const [currentPage, setCurrentPage] = useState(0);

  const nextPage = () => {
    const cant = listApertura.filter((p) => p.id_movimiento_diario).length;

    if (cant > currentPage) {
      setCurrentPage(currentPage + 11);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 11);
    }
  };

  //Paginacion report
  const [currentPage2, setCurrentPage2] = useState(0);

  // ---------------------------

  //Paginacion Cierre dias
  const [currentPage3, setCurrentPage3] = useState(0);

  const nextPageCD = () => {
    const cant = listCierre.filter((p) =>
      p.id_movimiento_diario.toString()
    ).length;

    if (cant > currentPage3) {
      setCurrentPage3(currentPage3 + 11);
    }
  };

  const prevPageCD = () => {
    if (currentPage3 > 0) {
      setCurrentPage3(currentPage3 - 11);
    }
  };

  //Paginacion Regulariza dia
  const [currentPage5, setCurrentPage5] = useState(0);

  // ---------------------modal reporte-----------------------
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();

  const {
    isOpen: isOpen4,
    onOpen: onOpen4,
    onClose: onClose4,
  } = useDisclosure();

  const {
    isOpen: isOpen5,
    onOpen: onOpen5,
    onClose: onClose5,
  } = useDisclosure();

  const {
    isOpen: isOpen6,
    onOpen: onOpen6,
    onClose: onClose6,
  } = useDisclosure();

  const {
    isOpen: isOpen7,
    onOpen: onOpen7,
    onClose: onClose7,
  } = useDisclosure();
  // ---------------Meta agregar-------------------

  const [modelMeta, setModelMeta] = useState({ meta: "" });
  const [metaDesdeBD, setMetaDesdeBD] = useState(0);
  const [gananciaTotalMeta, setgananciaTotalMeta] = useState(0);
  const [gananciaTotalAño, setGananciaTotalAño] = useState(null);

  // Función para agregar las metas
  const handleAgregarMeta = async () => {
    try {
      const { status, data } = await axios.post(`${host3}/api/metas/${token}`, {
        meta: modelMeta.meta,
      });
      if (status === 201) {
        mensaje(data.message, "top-right", 1);
        setModelMeta({ meta: "" });
      }
      listByModule();
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  // ---------------------- Regularizar apertura de caja -------------------
  const [dataRegularizeApertura, setDataRegularizeApertura] = useState({
    id_movimiento_diario: "",
    bank_inicial: "",
    comentario: "",
    password: "",
  });

  const handleRegularizeOpen = ({ target: { name, value } }) => {
    setDataRegularizeApertura({ ...dataRegularizeApertura, [name]: value });
  };

  const regularizeApertura = async () => {
    try {
      const { status, data } = await axios.put(
        `${host3}/api/cajas/${token}/regularize-open-box`,
        dataRegularizeApertura
      );

      if (status === 201) {
        setDataRegularizeApertura({
          id_movimiento_diario: "",
          bank_inicial: "",
          comentario: "",
          password: "",
        });

        mensaje(data.message, "top-right", 1);
        onClose6();
        fetchApertura();
        fetchReport();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  const listByModule = async () => {
    try {
      let newMonth =
        selectedMonth.toString().length == 1
          ? `0${selectedMonth}`
          : selectedMonth;

      const { data } = await axios.get(
        `${host3}/api/metas/${token}/modulo/${modulo}/mes/${newMonth}`
      );

      setMetaDesdeBD(data);
    } catch (error) {
      setMetaDesdeBD(0);
    }
  };

  useEffect(() => {
    listByModule();
    fetchGananciaTotalMeta();
  }, [selectedMonth]);

  const fetchGananciaTotalMeta = async () => {
    try {
      let newMonth =
        selectedMonth.toString().length == 1
          ? `0${selectedMonth}`
          : selectedMonth;

      const { data } = await axios.get(
        `${host3}/api/metas/${token}/modulo/${modulo}/mes/${newMonth}/change/USD`
      );

      setgananciaTotalMeta(data);
    } catch (error) {
      setMetaDesdeBD(0);
    }
  };

  const fetchGananciaTotalAño = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/cajas/${token}/reporte-mensual-modulo/USD/module/1/date/${filteredDataMonth.fecha}`
      );

      setGananciaTotalAño(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const calcularSumaGanancias = (gananciaTotal) => {
    if (gananciaTotal) {
      return gananciaTotal
        .reduce((total, item) => total + item.ganancia, 0)
        .toFixed(2);
    }
    return 0;
  };

  // Cerrar caja de un dia anterior
  const [dataCajaAnterior, setDataCajaAnterior] = useState({
    id_movimiento_diario: "",
    monto_final: "",
    pendiente_final: "",
    password: "",
  });

  const handleChangeCajaAnterior = ({ target: { name, value } }) => {
    setDataCajaAnterior({ ...dataCajaAnterior, [name]: value });
  };

  const closeCajaAnterior = async () => {
    try {
      const { status, data } = await axios.post(
        `${host3}/api/cajas/${token}/close-previous-box`,
        dataCajaAnterior
      );

      if (status == 201) {
        mensaje(data.message, "top-right", 1);
        onClose7();
        fetchCierre();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  // const calcularGananciasAPartir = (gananciaTotal) => {
  //   const meta = metaDesdeBD.meta ?? 0;
  //   return calcularSumaGanancias(gananciaTotal) - meta;
  // };

  const {
    isOpen: isOpenMeta,
    onOpen: onOpenMeta,
    onClose: onCloseMeta,
  } = useDisclosure();

  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar,
  } = useDisclosure();

  useEffect(() => {
    fetchCuentaCasas();

    fetchGananciaTotalAño();
    // calcularSumaGanancias(listReportGeneral);
    // calcularGananciasAPartir(listReportGeneral);
    fetchApertura();
    fetchCierre();
    fetchReport();
    fetchReportGeneral();
    if (rol.includes("JEFE DE SALA") || rol.includes("ADMINISTRADOR")) {
      fetchModules();
    }
  }, []);

  //Validar si tiene pendientes anteriores
  useEffect(() => {
    if (dataDiary.id_cuenta_casa != "") {
      let id_casa = dataDiary.id_cuenta_casa;
      const dateAnterior = moment().subtract(1, "day");

      const findCasa = listCierre.find((itemCierre) => {
        if (itemCierre.id_cuenta_casas == dataDiary.id_cuenta_casa) {
          let dateCasa = moment(itemCierre.fecha_registro).format("YYYY-MM-DD");
          return dateCasa == dateAnterior.format("YYYY-MM-DD");
        }
      });

      if (findCasa) {
        //console.log(findCasa)
        setDataDiary({
          id_cuenta_casa: id_casa,
          bank_initial: "0.00",
          initial_pending: findCasa.pendiente_final,
          retiros: "",
          recargas: "",
          bank_final: "",
          final_pending: "",
        });
      } else {
        setDataDiary({
          id_cuenta_casa: id_casa,
          bank_initial: "0.00",
          initial_pending: "0.00",
          retiros: "",
          recargas: "",
          bank_final: "",
          final_pending: "",
        });
      }
    }
  }, [dataDiary.id_cuenta_casa]);

  useEffect(() => {
    if (idRegularizeBox != null) {
      fetchRegularizeBox();
    }
  }, [idRegularizeBox]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: "1.2rem", textAlign: "center" }}
        pauseOnHover
      />

      <Box
        scrollBehavior={"smooth"}
        bg={"#e5e5e5"}
        height={"auto"}
        maxHeight={"100vh"}
        overflowY={"auto"}
        css={{
          "&::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#6b6b6b",
            borderRadius: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#9b9b9b",
            borderRadius: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#9b9b9b",
          },
        }}
      >
        <Box
          as="section"
          display={{
            "2xl": "unset",
            xl: "unset",
            lg: "none",
            md: "none",
            sm: "none",
          }}
        >
          <SidebarOperators />
        </Box>
        <Drawer isOpen={isOpenBar} onClose={onCloseBar} placement="left">
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w="full" borderRight="none" />
          </DrawerContent>
        </Drawer>
        <Box bg={"#e5e5e5"} h={"100vh"}>
          <Flex
            alignItems={"center"}
            justify={"flex-start"}
            // h={'100%'}
            sx={{
              "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
                {
                  padding: "1.5% 1.5% 1.5% 19%",
                },
            }}
            p={{
              "2xl": "1.5% 3% 1.5% 15%",
              xl: "1.5% 3% 1.5% 18%",
              lg: "1.5% 2% 1.5% 2%",
              md: "1.5% 2% 1.5% 2%",
            }}
            // marginLeft={'15%'}
            // marginRight='2%'
            // marginBottom='1%'
            direction="column"
            gap={"25px"}
          >
            <Box transition=".3s ease" width={"100%"}>
              <Flex gap="2%" alignItems={"center"} as="header" w="full" h="14">
                <IconButton
                  aria-label="Menu"
                  display={{
                    "2xl": "none",
                    xl: "none",
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                  }}
                  background={"#292F36"}
                  color={"#ffffff"}
                  borderRadius={"4px"}
                  fontSize={"1.3rem"}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size="md"
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink color={"#707070"} fontSize={"1.5rem"}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={"bold"}
                      color={"#292F36"}
                      fontSize={"1.5rem"}
                    >
                      {rol.includes("JEFE DE SALA") ||
                      rol.includes("ADMINISTRADOR")
                        ? "REPORTE DE SALA"
                        : "CAJA DIARIA"}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>
            <Flex w={"100%"} justifyContent={"center"}>
              <Flex
                direction={"column"}
                gap={"15px"}
                w={"100%"}
                // h='85vh'
              >
                {rol.includes("SPORTRADER") && (
                  <Flex w={"100%"} justify={"space-between"}>
                    <Flex w={"100%"} gap={"4%"} alignItems={"center"}>
                      <Flex
                        bgColor={"#FFFF"}
                        p={"0.2% 1.2%"}
                        borderRadius={"10px"}
                        gap={"6px"}
                        fontFamily={"monospace"}
                        fontWeight={"600"}
                        fontSize={"1.7rem"}
                      >
                        <Text>Ganancia Total</Text> :{" "}
                        <Text>{gananciaTotalMeta.toFixed(2)}</Text>
                      </Flex>

                      <Flex
                        bgColor={"#FFFF"}
                        s
                        p={"0.2% 1.2%"}
                        borderRadius={"10px"}
                        gap={"6px"}
                        fontFamily={"monospace"}
                        fontWeight={"600"}
                        fontSize={"1.7rem"}
                      >
                        <Text>Ganancia a partir</Text> :{" "}
                        <Text>
                          {metaDesdeBD?.meta
                            ? (gananciaTotalMeta - metaDesdeBD.meta).toFixed(2)
                            : 0}
                        </Text>
                      </Flex>

                      <Tooltip hasArrow label="Agregar Meta" fontSize="2xl">
                        <Flex
                          bgColor={"#FFFF"}
                          _hover={{
                            bg: "#E2E8F0",
                            textColor: "#FFFF",
                            borderColor: "#FFFF",
                          }}
                          cursor={"pointer"}
                          onClick={onOpenMeta}
                          p={"0.2% 1.2%"}
                          borderColor={"#000000"}
                          borderWidth={"2px"}
                          borderRadius={"10px"}
                          gap={"6px"}
                          fontFamily={"monospace"}
                          fontWeight={"600"}
                          fontSize={"1.7rem"}
                        >
                          <Text>Meta</Text> :{" "}
                          <Text>
                            {" "}
                            {metaDesdeBD?.meta ? metaDesdeBD.meta : 0.0}{" "}
                          </Text>
                        </Flex>
                      </Tooltip>

                      <Select
                        onChange={handleMonthChange}
                        value={selectedMonth}
                        w={"auto"}
                        bg={"#FFFF"}
                        borderColor={"#292F36"}
                        borderRadius="10px"
                        border={"2px solid"}
                        name="id_cuenta_bancaria"
                        fontSize={"1.8rem"}
                        height={"40px"}
                      >
                        <option value="all">Filtro Mes</option>
                        {meses.map((mes, index) => (
                          <option key={index} value={index + 1}>
                            {mes}
                          </option>
                        ))}
                      </Select>

                      {/* <Select
                      onChange={handleFilterMonthChange}
                      value={filteredDataMonth.fecha}
                      name="fecha"
                      bg={"white"}
                      placeholder="Filtro de Año"
                      height={"40px"}
                      fontSize={"1.8rem"}
                      border={"2px solid"}
                      color="#292F36"
                      w={"auto"}
                      borderColor={"#292F36"}
                      borderRadius="10px"
                      _dark={{
                        color: "white",
                        borderColor: "#292F36",
                        bg: "#292F36",
                      }}
                    >
                      {years.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </Select> */}

                      {/* <Button
                      w={'auto'}
                      onClick={onOpen4}
                      borderRadius='10px'
                      color={'white'}
                      fontSize={'1.6rem'}
                      bg='#2a2e6e'
                      p={'0.9% 1.2%'}
                      textTransform={'uppercase'}>
                     Agregar meta
                    </Button> */}
                    </Flex>

                    <Flex
                      bgColor={"#FFFF"}
                      p={"0.2% 1.2%"}
                      borderRadius={"10px"}
                      gap={"15px"}
                      fontFamily={"monospace"}
                      fontWeight={"600"}
                      fontSize={"1.7rem"}
                    >
                      <Text>{nombre_modulo}</Text> /{" "}
                      <Text>
                        {rol[0]}: {nombre}
                      </Text>
                    </Flex>
                  </Flex>
                )}

                <Tabs isFitted variant="enclosed" w={"100%"}>
                  <TabList mb="1em" bg={"#FFFF"} borderRadius="15px" p={"0.5%"}>
                    {rol.includes("SPORTRADER") && (
                      <>
                        <Tab
                          borderRadius={"15px"}
                          _selected={{
                            color: "white",
                            bg: "#2a2e6e",
                          }}
                          fontSize={{
                            "2xl": "2.1rem",
                            xl: "1.84rem",
                            lg: "1.84rem",
                            md: "1.74rem",
                          }}
                          textTransform={"uppercase"}
                          fontFamily="monospace"
                          fontWeight={"600"}
                        >
                          Apertura de Día
                        </Tab>
                        <Tab
                          borderRadius={"15px"}
                          _selected={{
                            color: "white",
                            bg: "#2a2e6e",
                          }}
                          fontSize={{
                            "2xl": "2.1rem",
                            xl: "1.84rem",
                            lg: "1.84rem",
                            md: "1.74rem",
                          }}
                          textTransform={"uppercase"}
                          fontFamily="monospace"
                          fontWeight={"600"}
                        >
                          Cierre de Día
                        </Tab>
                        <Tab
                          borderRadius={"15px"}
                          _selected={{
                            color: "white",
                            bg: "#2a2e6e",
                          }}
                          fontSize={{
                            "2xl": "2.1rem",
                            xl: "1.84rem",
                            lg: "1.84rem",
                            md: "1.74rem",
                          }}
                          textTransform={"uppercase"}
                          fontFamily="monospace"
                          fontWeight={"600"}
                        >
                          Reportes
                        </Tab>
                      </>
                    )}

                    {rol.includes("JEFE DE SALA") ||
                      (rol.includes("ADMINISTRADOR") && (
                        <Tab
                          borderRadius={"15px"}
                          _selected={{
                            color: "white",
                            bg: "#2a2e6e",
                          }}
                          fontSize={{
                            "2xl": "2.1rem",
                            xl: "1.84rem",
                            lg: "1.84rem",
                            md: "1.74rem",
                          }}
                          textTransform={"uppercase"}
                          fontFamily="monospace"
                          fontWeight={"600"}
                        >
                          Reporte General
                        </Tab>
                      ))}

                    {/* ------------------------------------------------------ */}

                    <Tab
                      borderRadius={"15px"}
                      _selected={{
                        color: "white",
                        bg: "#2a2e6e",
                      }}
                      fontSize={{
                        "2xl": "2.1rem",
                        xl: "1.84rem",
                        lg: "1.84rem",
                        md: "1.74rem",
                      }}
                      textTransform={"uppercase"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                    >
                      Calendario Mensual
                    </Tab>
                  </TabList>
                  <TabPanels>
                    {rol.includes("SPORTRADER") && (
                      <>
                        {/* ----------------------INICIA------------------------*/}

                        <TabPanel
                          w={"100%"}
                          bg={"#ffff"}
                          borderRadius="10px"
                          p={"2%"}
                          boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                        >
                          <Flex
                            w={"100%"}
                            h="100%"
                            direction={"column"}
                            gap="20px"
                            justifyContent="center"
                          >
                            <Flex w={"100%"} justifyContent="right">
                              <Button
                                w={"auto"}
                                onClick={onOpen4}
                                height={"40px"}
                                borderRadius="10px"
                                color={"white"}
                                fontSize={"1.6rem"}
                                bg="#2a2e6e"
                                p={"1% 1.5%"}
                                textTransform={"uppercase"}
                              >
                                Aperturar Nueva Caja
                              </Button>
                            </Flex>

                            <TableContainer w={"100%"}>
                              <Table variant="simple">
                                <Thead>
                                  <Tr>
                                    <Th w={"25%"} fontSize={"1.8rem"}>
                                      CASA
                                    </Th>
                                    <Th w={"25%"} fontSize={"1.8rem"}>
                                      MONEDA
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      MONTO INICIAL
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      RECARGA
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      RETIRO
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      TOTAL
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      ACCI&Oacute;N
                                    </Th>
                                  </Tr>
                                </Thead>
                                {ListFiltered()?.map((itemInicial) => {
                                  return (
                                    <Tbody fontSize={"1.6rem"}>
                                      <Tr>
                                        <Td
                                          w={"25%"}
                                          padding={"1.05%"}
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemInicial.cuenta_casa.casa.alias ==
                                          null
                                            ? itemInicial.cuenta_casa.casa
                                                .nombre
                                            : itemInicial.cuenta_casa.casa
                                                .alias}
                                        </Td>
                                        <Td
                                          w={"25%"}
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {
                                            itemInicial.cuenta_casa.moneda
                                              .nombre
                                          }
                                        </Td>
                                        <Td
                                          w={"25%"}
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                        >
                                          {itemInicial.monto_inicial}
                                        </Td>
                                        <Td
                                          w={"25%"}
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                        >
                                          {itemInicial.recargas}
                                        </Td>
                                        <Td
                                          w={"25%"}
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                        >
                                          {itemInicial.retiros}
                                        </Td>
                                        <Td
                                          w={"25%"}
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                        >
                                          {itemInicial.total}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          isNumeric
                                        >
                                          <Tooltip
                                            hasArrow
                                            borderRadius={"4px"}
                                            fontSize={"1.2rem"}
                                            label="Regular"
                                            bg="#292F36"
                                          >
                                            <IconButton
                                              onClick={() => {
                                                dataRegularizeApertura.id_movimiento_diario =
                                                  itemInicial.id_movimiento_diario;
                                                onOpen6();
                                              }}
                                              size={"md"}
                                              borderRadius={"4px"}
                                              fontSize={"1.5rem"}
                                              background={"#f59f2c"}
                                              color={"#ffffff"}
                                              aria-label="Regular"
                                              icon={<BiCalendarEdit />}
                                            />
                                          </Tooltip>
                                        </Td>
                                      </Tr>
                                    </Tbody>
                                  );
                                })}
                              </Table>
                            </TableContainer>
                            <Flex
                              marginTop={"0.5%"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              gap={"0.5%"}
                            >
                              <Icon
                                onClick={() => {
                                  prevPage();
                                }}
                                color={"#13235b"}
                                cursor={"pointer"}
                                boxSize="16"
                                _hover={{
                                  color: "gray !important",
                                }}
                                _dark={{
                                  color: "#f59f2c",
                                }}
                                as={IoMdArrowDropleftCircle}
                              />
                              <Icon
                                onClick={() => {
                                  nextPage();
                                }}
                                color={"#13235b"}
                                cursor={"pointer"}
                                boxSize="16"
                                _hover={{
                                  color: "gray !important",
                                }}
                                _dark={{
                                  color: "#f59f2c",
                                }}
                                as={IoMdArrowDroprightCircle}
                              />
                            </Flex>
                          </Flex>
                        </TabPanel>
                      </>
                    )}

                    {rol.includes("SPORTRADER") && (
                      <>
                        {/* ----------------CIERRE------------------------- */}

                        <TabPanel
                          w={"100%"}
                          bg={"#ffff"}
                          borderRadius="10px"
                          p={"2%"}
                          boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                        >
                          <Flex
                            w={"100%"}
                            h="100%"
                            direction={"column"}
                            gap="20px"
                            justifyContent="center"
                          >
                            <Flex w={"100%"} justifyContent="right">
                              <Button
                                w={"auto"}
                                onClick={() => {
                                  fetchApertura();
                                  onOpen5();
                                }}
                                height={"40px"}
                                borderRadius="10px"
                                color={"white"}
                                fontSize={"1.6rem"}
                                bg="#2a2e6e"
                                p={"1% 1.5%"}
                                textTransform={"uppercase"}
                              >
                                Cerrar Caja Del D&iacute;a
                              </Button>
                            </Flex>

                            <TableContainer w={"100%"}>
                              <Table variant="simple">
                                <Thead>
                                  <Tr>
                                    <Th fontSize={"1.8rem"}>Fecha</Th>
                                    <Th fontSize={"1.8rem"}>ESTADO</Th>
                                    <Th fontSize={"1.8rem"}>CASA</Th>
                                    <Th fontSize={"1.8rem"} lineHeight="18px">
                                      MONEDA
                                    </Th>
                                    <Th fontSize={"1.8rem"} lineHeight="18px">
                                      INICIO
                                    </Th>
                                    <Th fontSize={"1.8rem"}>RECARGAS</Th>
                                    <Th fontSize={"1.8rem"}>RETIROS</Th>
                                    <Th fontSize={"1.8rem"}>TOTAL</Th>
                                    <Th fontSize={"1.8rem"} lineHeight="18px">
                                      FINAL
                                    </Th>
                                    <Th isNumeric fontSize={"1.8rem"}>
                                      ACCIONES
                                    </Th>
                                  </Tr>
                                </Thead>
                                {ListFiltered2().map((itemCierre) => {
                                  return (
                                    <Tbody fontSize={"1.6rem"}>
                                      <Tr>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemCierre.tipo == "INICIO"
                                            ? moment(
                                                itemCierre.fecha_registro
                                              ).format("YYYY-MM-DD")
                                            : moment(
                                                itemCierre.fecha_cierre
                                              ).format("YYYY-MM-DD")}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemCierre.tipo}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemCierre.cuenta_casa.casa.alias ==
                                          null
                                            ? itemCierre.cuenta_casa.casa.nombre
                                            : itemCierre.cuenta_casa.casa.alias}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemCierre.cuenta_casa.moneda.nombre}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                        >
                                          {itemCierre.monto_inicial}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                        >
                                          {itemCierre.recargas}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                        >
                                          {itemCierre.retiros}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                        >
                                          {itemCierre.total}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                        >
                                          {itemCierre.bank_final}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          isNumeric
                                        >
                                          {itemCierre.tipo == "FINAL" && (
                                            <Tooltip
                                              hasArrow
                                              borderRadius={"4px"}
                                              fontSize={"1.2rem"}
                                              label="Regular"
                                              bg="#292F36"
                                            >
                                              <IconButton
                                                onClick={() => {
                                                  setIdRegularizeBox(
                                                    itemCierre.id_movimiento_diario
                                                  );
                                                  onOpen3();
                                                }}
                                                size={"md"}
                                                borderRadius={"4px"}
                                                fontSize={"1.5rem"}
                                                background={"#f59f2c"}
                                                color={"#ffffff"}
                                                aria-label="Regular"
                                                icon={<BiCalendarEdit />}
                                              />
                                            </Tooltip>
                                          )}

                                          {itemCierre.tipo == "INICIO" && (
                                            <Tooltip
                                              hasArrow
                                              borderRadius={"4px"}
                                              fontSize={"1.2rem"}
                                              label="Cerrar Día Anterior"
                                              bg="#292F36"
                                            >
                                              <IconButton
                                                onClick={() => {
                                                  dataCajaAnterior.id_movimiento_diario =
                                                    itemCierre.id_movimiento_diario;
                                                  onOpen7();
                                                }}
                                                size={"md"}
                                                borderRadius={"4px"}
                                                fontSize={"1.5rem"}
                                                background={"#119f6a"}
                                                color={"#ffffff"}
                                                aria-label="Cerrar Día Anterior"
                                                icon={<BiCalendarEdit />}
                                              />
                                            </Tooltip>
                                          )}
                                        </Td>
                                      </Tr>
                                    </Tbody>
                                  );
                                })}
                              </Table>
                            </TableContainer>
                            <Flex
                              marginTop={"0.5%"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              gap={"0.5%"}
                            >
                              <Icon
                                onClick={() => {
                                  prevPageCD();
                                }}
                                color={"#13235b"}
                                cursor={"pointer"}
                                boxSize="16"
                                _hover={{
                                  color: "gray !important",
                                }}
                                _dark={{
                                  color: "#f59f2c",
                                }}
                                as={IoMdArrowDropleftCircle}
                              />
                              <Icon
                                onClick={() => {
                                  nextPageCD();
                                }}
                                color={"#13235b"}
                                cursor={"pointer"}
                                boxSize="16"
                                _hover={{
                                  color: "gray !important",
                                }}
                                _dark={{
                                  color: "#f59f2c",
                                }}
                                as={IoMdArrowDroprightCircle}
                              />
                            </Flex>
                          </Flex>
                        </TabPanel>
                      </>
                    )}

                    {rol.includes("SPORTRADER") && (
                      <>
                        {/* ----------------REPORTE------------------------- */}
                        <TabPanel w={"100%"} p={"0%"}>
                          <Flex
                            direction={"column"}
                            justifyContent="center"
                            alignItems="center"
                            gap="20px"
                            w={"100%"}
                            h="100%"
                          >
                            <Flex
                              w={"100%"}
                              justifyContent={"space-between"}
                              padding={"1% 0 0 0"}
                            >
                              <Flex w={"auto"} alignItems={"center"}>
                                <Select
                                  bg={"white"}
                                  onChange={handleFilterChange}
                                  value={filteredData.money}
                                  name="money"
                                  height={"40px"}
                                  fontSize={"1.8rem"}
                                  border={"2px solid"}
                                  color="#292F36"
                                  borderColor={"#292F36"}
                                  borderRadius="10px"
                                  _dark={{
                                    color: "white",
                                    borderColor: "#292F36",
                                    bg: "#292F36",
                                  }}
                                >
                                  <option value="all" disabled>
                                    Filtro Moneda
                                  </option>
                                  <option value="LOCAL">LOCAL</option>
                                  <option value="USD">USD</option>
                                  <option value="EUR" disabled>
                                    EUR
                                  </option>
                                </Select>
                              </Flex>
                              <Flex
                                // w={"23%"}
                                alignItems={"center"}
                                gap={"1.5%"}
                              >
                                <Input
                                  placeholder="Fecha"
                                  onChange={handleFilterChange}
                                  value={filteredData.fecha}
                                  name="fecha"
                                  size="md"
                                  type="date"
                                  bg={"white"}
                                  height={"40px"}
                                  fontSize={"1.8rem"}
                                  border={"2px solid"}
                                  color="#292F36"
                                  borderColor={"#292F36"}
                                  borderRadius="10px"
                                  _dark={{
                                    color: "white",
                                    borderColor: "#292F36",
                                    bg: "#292F36",
                                  }}
                                />
                                <Stack align="center" width={"30%"}>
                                  <Button
                                    w={"100%"}
                                    height={"40px"}
                                    borderRadius="12px"
                                    color={"white"}
                                    onClick={searchReport}
                                    fontSize={"1.8rem"}
                                    bg="#292F36"
                                    _dark={{
                                      color: "white",
                                      bg: "#f59f2c",
                                    }}
                                  >
                                    Buscar
                                  </Button>
                                </Stack>
                              </Flex>
                            </Flex>

                            <Flex
                              w={"100%"}
                              padding={"0"}
                              direction={"column"}
                              rowGap={"12px"}
                            >
                              <Flex
                                w={"100%"}
                                direction={"column"}
                                bg={"#292F36"}
                                height={"auto"}
                                borderRadius={"12px"}
                                _dark={{
                                  bg: "#292F36",
                                }}
                              >
                                <Flex
                                  w={"100%"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                  padding={"1%"}
                                >
                                  <Flex w={"49%"} justifyContent={"center"}>
                                    <Text
                                      fontWeight={"bold"}
                                      color={"white"}
                                      fontSize={{
                                        "2xl": "2rem",
                                        xl: "1.9rem",
                                        lg: "1.84rem",
                                        md: "1.74rem",
                                      }}
                                      _dark={{
                                        color: "white",
                                      }}
                                    >
                                      APERTURA DE DÍA
                                    </Text>
                                  </Flex>
                                  <Flex w={"2%"} justifyContent={"center"}>
                                    <Text
                                      fontWeight={"bold"}
                                      color={"white"}
                                      fontSize={{
                                        "2xl": "2rem",
                                        xl: "1.9rem",
                                        lg: "1.84rem",
                                        md: "1.74rem",
                                      }}
                                      _dark={{
                                        color: "white",
                                      }}
                                    >
                                      |
                                    </Text>
                                  </Flex>
                                  <Flex w={"49%"} justifyContent={"center"}>
                                    <Text
                                      fontWeight={"bold"}
                                      color={"white"}
                                      fontSize={{
                                        "2xl": "2rem",
                                        xl: "1.9rem",
                                        lg: "1.84rem",
                                        md: "1.74rem",
                                      }}
                                      _dark={{
                                        color: "white",
                                      }}
                                    >
                                      CIERRE DE DÍA
                                    </Text>
                                  </Flex>
                                </Flex>
                              </Flex>
                              {listReport?.map((ItemReport) => (
                                <Flex
                                  w={"100%"}
                                  overflowY={"scroll"}
                                  direction={"column"}
                                  height={"auto"}
                                  maxHeight={"672px"}
                                  paddingBottom={"1%"}
                                  rowGap={"17px"}
                                  css={{
                                    "&::-webkit-scrollbar": {
                                      display: "none",
                                    },
                                  }}
                                >
                                  <Flex w={"100%"} gap={"2%"}>
                                    <Flex
                                      w={"49%"}
                                      direction={"column"}
                                      rowGap={"12px"}
                                    >
                                      <Accordion
                                        w={"100%"}
                                        // defaultIndex={[1]}
                                        allowMultiple
                                      >
                                        <AccordionItem
                                          borderStyle={"none"}
                                          justifyContent={"center"}
                                        >
                                          {({ isExpanded }) => (
                                            <>
                                              <AccordionButton
                                                _hover={{
                                                  bg: "white",
                                                }}
                                                _focus={{
                                                  outline: "none",
                                                }}
                                                _dark={{
                                                  bg: "#292929",
                                                }}
                                                boxShadow={
                                                  "0 0 4px rgb(0 0 0 / 24%)"
                                                }
                                                bg={"white"}
                                                height={"45px"}
                                                borderRadius={"8px"}
                                              >
                                                <Box
                                                  color={"black"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  fontSize={"1.8rem"}
                                                  flex="1"
                                                  textAlign="left"
                                                >
                                                  TOTAL INICIAL
                                                </Box>

                                                {/* <Box
                                                fontSize={'1.8rem'}
                                                flex='1'
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'black'}
                                                paddingRight={'4%'}
                                                textAlign='right'>
                                                $ {''}
                                              </Box> */}

                                                <Box
                                                  fontSize={"1.8rem"}
                                                  flex="1"
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"black"}
                                                  paddingRight={"4%"}
                                                  textAlign="right"
                                                >
                                                  {filteredData.money == "EUR"
                                                    ? "€"
                                                    : "$"}{" "}
                                                  {ItemReport.monto_total}
                                                </Box>

                                                {isExpanded ? (
                                                  <FaMinus fontSize="14px" />
                                                ) : (
                                                  <FaPlus fontSize="14px" />
                                                )}
                                              </AccordionButton>

                                              <AccordionPanel
                                                padding={0}
                                                boxShadow={
                                                  "0 0 4px rgb(0 0 0 / 24%)"
                                                }
                                                bg={"white"}
                                                _dark={{
                                                  bg: "#707070",
                                                }}
                                                borderRadius={"0 0 8px 8px"}
                                                margin={"1px 2% 0 2%"}
                                              >
                                                <TableContainer
                                                  width={"100%"}
                                                  id="sdda"
                                                >
                                                  <Table width={"100%"}>
                                                    <Thead>
                                                      <Tr
                                                        border={"none"}
                                                        background={"#d9d9d9"}
                                                        _dark={{
                                                          bg: "#464545",
                                                        }}
                                                        minHeight={"28px"}
                                                      >
                                                        <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          CASA
                                                        </Th>
                                                        <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          MONTO
                                                        </Th>

                                                        {/* <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          MONTO MONEDA
                                                        </Th> */}
                                                      </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                      {ItemReport.casa_total.map(
                                                        (ItemBank, i) => (
                                                          <Tr
                                                            border={"none"}
                                                            key={i}
                                                          >
                                                            <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {ItemBank.casa}
                                                            </Td>
                                                            <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {ItemBank.simbolo}{" "}
                                                              {ItemBank.inicio}{" "}
                                                              {
                                                                ItemBank.nomenclatura
                                                              }
                                                            </Td>
                                                            {/* <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {ItemBank.simbolo}{" "}
                                                              {ItemBank.inicio}{" "}
                                                              {
                                                                ItemBank.nomenclatura
                                                              }
                                                            </Td> */}
                                                          </Tr>
                                                        )
                                                      )}
                                                    </Tbody>
                                                  </Table>
                                                </TableContainer>
                                              </AccordionPanel>
                                            </>
                                          )}
                                        </AccordionItem>
                                      </Accordion>
                                      <Accordion
                                        w={"100%"}
                                        // defaultIndex={[1]}
                                        allowMultiple
                                      >
                                        <AccordionItem
                                          borderStyle={"none"}
                                          justifyContent={"center"}
                                        >
                                          {({ isExpanded }) => (
                                            <>
                                              <AccordionButton
                                                _hover={{
                                                  bg: "white",
                                                }}
                                                _focus={{
                                                  outline: "none",
                                                }}
                                                _dark={{
                                                  bg: "#292929",
                                                }}
                                                boxShadow={
                                                  "0 0 4px rgb(0 0 0 / 24%)"
                                                }
                                                bg={"white"}
                                                height={"45px"}
                                                borderRadius={"8px"}
                                              >
                                                <Box
                                                  color={"black"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  fontSize={"1.8rem"}
                                                  flex="1"
                                                  textAlign="left"
                                                >
                                                  SUMATORIA MONTO INICIAL
                                                </Box>

                                                {/* <Box
                                                fontSize={'1.8rem'}
                                                flex='1'
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'black'}
                                                paddingRight={'4%'}
                                                textAlign='right'>
                                                $ {''}
                                              </Box> */}

                                                <Box
                                                  fontSize={"1.8rem"}
                                                  flex="1"
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"black"}
                                                  paddingRight={"4%"}
                                                  textAlign="right"
                                                >
                                                  {filteredData.money == "EUR"
                                                    ? "€"
                                                    : "$"}{" "}
                                                  {
                                                    ItemReport.bank_total_inicial
                                                  }
                                                </Box>

                                                {isExpanded ? (
                                                  <FaMinus fontSize="14px" />
                                                ) : (
                                                  <FaPlus fontSize="14px" />
                                                )}
                                              </AccordionButton>

                                              <AccordionPanel
                                                padding={0}
                                                boxShadow={
                                                  "0 0 4px rgb(0 0 0 / 24%)"
                                                }
                                                bg={"white"}
                                                _dark={{
                                                  bg: "#707070",
                                                }}
                                                borderRadius={"0 0 8px 8px"}
                                                margin={"1px 2% 0 2%"}
                                              >
                                                <TableContainer
                                                  width={"100%"}
                                                  id="sdda"
                                                >
                                                  <Table width={"100%"}>
                                                    <Thead>
                                                      <Tr
                                                        border={"none"}
                                                        background={"#d9d9d9"}
                                                        _dark={{
                                                          bg: "#464545",
                                                        }}
                                                        minHeight={"28px"}
                                                      >
                                                        <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          CASA
                                                        </Th>
                                                        <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          MONTO
                                                        </Th>

                                                        {/* <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          MONTO MONEDA
                                                        </Th> */}
                                                      </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                      {ItemReport.casa_inicial.map(
                                                        (ItemBank, i) => (
                                                          <Tr
                                                            border={"none"}
                                                            key={i}
                                                          >
                                                            <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {ItemBank.casa}
                                                            </Td>
                                                            <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {ItemBank.simbolo}{" "}
                                                              {ItemBank.inicio}{" "}
                                                              {
                                                                ItemBank.nomenclatura
                                                              }
                                                            </Td>
                                                            {/* <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {ItemBank.simbolo}{" "}
                                                              {ItemBank.inicio}{" "}
                                                              {
                                                                ItemBank.nomenclatura
                                                              }
                                                            </Td> */}
                                                          </Tr>
                                                        )
                                                      )}
                                                    </Tbody>
                                                  </Table>
                                                </TableContainer>
                                              </AccordionPanel>
                                            </>
                                          )}
                                        </AccordionItem>
                                      </Accordion>

                                      <Accordion
                                        w={"100%"}
                                        // defaultIndex={[1]}
                                        allowMultiple
                                      >
                                        <AccordionItem
                                          borderStyle={"none"}
                                          justifyContent={"center"}
                                        >
                                          {({ isExpanded }) => (
                                            <>
                                              <AccordionButton
                                                _hover={{
                                                  bg: "white",
                                                }}
                                                _focus={{
                                                  outline: "none",
                                                }}
                                                _dark={{
                                                  bg: "#292929",
                                                }}
                                                boxShadow={
                                                  "0 0 4px rgb(0 0 0 / 24%)"
                                                }
                                                bg={"white"}
                                                height={"45px"}
                                                borderRadius={"8px"}
                                              >
                                                <Box
                                                  color={"black"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  fontSize={"1.8rem"}
                                                  flex="1"
                                                  textAlign="left"
                                                >
                                                  PENDIENTE INICIAL
                                                </Box>

                                                {/* <Box
                                                fontSize={'1.8rem'}
                                                flex='1'
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'black'}
                                                paddingRight={'4%'}
                                                textAlign='right'>
                                                $ {''}
                                              </Box> */}

                                                <Box
                                                  fontSize={"1.8rem"}
                                                  flex="1"
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"black"}
                                                  paddingRight={"4%"}
                                                  textAlign="right"
                                                >
                                                  {filteredData.money == "EUR"
                                                    ? "€"
                                                    : "$"}{" "}
                                                  {""}
                                                  {
                                                    ItemReport.pendiente_total_inicial
                                                  }
                                                </Box>

                                                {isExpanded ? (
                                                  <FaMinus fontSize="14px" />
                                                ) : (
                                                  <FaPlus fontSize="14px" />
                                                )}
                                              </AccordionButton>

                                              <AccordionPanel
                                                padding={0}
                                                boxShadow={
                                                  "0 0 4px rgb(0 0 0 / 24%)"
                                                }
                                                bg={"white"}
                                                _dark={{
                                                  bg: "#707070",
                                                }}
                                                borderRadius={"0 0 8px 8px"}
                                                margin={"1px 2% 0 2%"}
                                              >
                                                <TableContainer
                                                  width={"100%"}
                                                  id="sdda"
                                                >
                                                  <Table width={"100%"}>
                                                    <Thead>
                                                      <Tr
                                                        border={"none"}
                                                        background={"#d9d9d9"}
                                                        _dark={{
                                                          bg: "#464545",
                                                        }}
                                                        minHeight={"28px"}
                                                      >
                                                        <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          CASA
                                                        </Th>
                                                        <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          MONTO
                                                        </Th>
                                                        {/* <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          MONTO MONEDA
                                                        </Th> */}
                                                      </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                      {ItemReport.casa_pendiente_inicial.map(
                                                        (ItemPending, i) => (
                                                          <Tr
                                                            border={"none"}
                                                            key={i}
                                                          >
                                                            <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {ItemPending.casa}
                                                            </Td>
                                                            <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {
                                                                ItemPending.simbolo
                                                              }{" "}
                                                              {
                                                                ItemPending.inicio
                                                              }{" "}
                                                              {
                                                                ItemPending.nomenclatura
                                                              }
                                                            </Td>
                                                            {/* <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {
                                                                ItemPending.simbolo
                                                              }{" "}
                                                              {
                                                                ItemPending.inicio
                                                              }{" "}
                                                              {
                                                                ItemPending.nomenclatura
                                                              }
                                                            </Td> */}
                                                          </Tr>
                                                        )
                                                      )}
                                                    </Tbody>
                                                  </Table>
                                                </TableContainer>
                                              </AccordionPanel>
                                            </>
                                          )}
                                        </AccordionItem>
                                      </Accordion>
                                    </Flex>

                                    <Flex
                                      w={"49%"}
                                      direction={"column"}
                                      rowGap={"12px"}
                                    >
                                      <Accordion
                                        w={"100%"}
                                        // defaultIndex={[1]}
                                        allowMultiple
                                      >
                                        <AccordionItem
                                          borderStyle={"none"}
                                          justifyContent={"center"}
                                        >
                                          {({ isExpanded }) => (
                                            <>
                                              <AccordionButton
                                                _hover={{
                                                  bg: "white",
                                                }}
                                                _focus={{
                                                  outline: "none",
                                                }}
                                                _dark={{
                                                  bg: "#292929",
                                                }}
                                                boxShadow={
                                                  "0 0 4px rgb(0 0 0 / 24%)"
                                                }
                                                bg={"white"}
                                                height={"45px"}
                                                borderRadius={"8px"}
                                              >
                                                <Box
                                                  color={"black"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  fontSize={"1.8rem"}
                                                  flex="1"
                                                  textAlign="left"
                                                >
                                                  MONTO FINAL
                                                </Box>

                                                {/* <Box
                                                fontSize={'1.8rem'}
                                                flex='1'
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'black'}
                                                paddingRight={'4%'}
                                                textAlign='right'>
                                                $ {''}
                                              </Box> */}

                                                <Box
                                                  fontSize={"1.8rem"}
                                                  flex="1"
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"black"}
                                                  paddingRight={"4%"}
                                                  textAlign="right"
                                                >
                                                  {filteredData.money == "EUR"
                                                    ? "€"
                                                    : "$"}{" "}
                                                  {ItemReport.bank_total_final}
                                                </Box>

                                                {isExpanded ? (
                                                  <FaMinus fontSize="14px" />
                                                ) : (
                                                  <FaPlus fontSize="14px" />
                                                )}
                                              </AccordionButton>

                                              <AccordionPanel
                                                padding={0}
                                                boxShadow={
                                                  "0 0 4px rgb(0 0 0 / 24%)"
                                                }
                                                bg={"white"}
                                                _dark={{
                                                  bg: "#707070",
                                                }}
                                                borderRadius={"0 0 8px 8px"}
                                                margin={"1px 2% 0 2%"}
                                              >
                                                <TableContainer
                                                  width={"100%"}
                                                  id="sdda"
                                                >
                                                  <Table width={"100%"}>
                                                    <Thead>
                                                      <Tr
                                                        border={"none"}
                                                        background={"#d9d9d9"}
                                                        _dark={{
                                                          bg: "#464545",
                                                        }}
                                                        minHeight={"28px"}
                                                      >
                                                        <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          CASA
                                                        </Th>
                                                        <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          MONTO
                                                        </Th>
                                                        {/* <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          MONTO MONEDA
                                                        </Th> */}
                                                      </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                      {ItemReport.casa_final.map(
                                                        (ItemBank, i) => (
                                                          <Tr
                                                            border={"none"}
                                                            key={i}
                                                          >
                                                            <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {ItemBank.casa}
                                                            </Td>
                                                            <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {ItemBank.simbolo}{" "}
                                                              {ItemBank.inicio}{" "}
                                                              {
                                                                ItemBank.nomenclatura
                                                              }
                                                            </Td>
                                                            {/* <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {ItemBank.simbolo}{" "}
                                                              {ItemBank.inicio}{" "}
                                                              {
                                                                ItemBank.nomenclatura
                                                              }
                                                            </Td> */}
                                                          </Tr>
                                                        )
                                                      )}
                                                    </Tbody>
                                                  </Table>
                                                </TableContainer>
                                              </AccordionPanel>
                                            </>
                                          )}
                                        </AccordionItem>
                                      </Accordion>

                                      <Accordion
                                        w={"100%"}
                                        // defaultIndex={[1]}
                                        allowMultiple
                                      >
                                        <AccordionItem
                                          borderStyle={"none"}
                                          justifyContent={"center"}
                                        >
                                          {({ isExpanded }) => (
                                            <>
                                              <AccordionButton
                                                _hover={{
                                                  bg: "white",
                                                }}
                                                _focus={{
                                                  outline: "none",
                                                }}
                                                _dark={{
                                                  bg: "#292929",
                                                }}
                                                boxShadow={
                                                  "0 0 4px rgb(0 0 0 / 24%)"
                                                }
                                                bg={"white"}
                                                height={"45px"}
                                                borderRadius={"8px"}
                                              >
                                                <Box
                                                  color={"black"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  fontSize={"1.8rem"}
                                                  flex="1"
                                                  textAlign="left"
                                                >
                                                  PENDIENTE FINAL
                                                </Box>

                                                {/* <Box
                                                fontSize={'1.8rem'}
                                                flex='1'
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'black'}
                                                paddingRight={'4%'}
                                                textAlign='right'>
                                                $ {''}
                                              </Box> */}

                                                <Box
                                                  fontSize={"1.8rem"}
                                                  flex="1"
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"black"}
                                                  paddingRight={"4%"}
                                                  textAlign="right"
                                                >
                                                  {filteredData.money == "EUR"
                                                    ? "€"
                                                    : "$"}{" "}
                                                  {
                                                    ItemReport.pendiente_total_final
                                                  }
                                                </Box>

                                                {isExpanded ? (
                                                  <FaMinus fontSize="14px" />
                                                ) : (
                                                  <FaPlus fontSize="14px" />
                                                )}
                                              </AccordionButton>

                                              <AccordionPanel
                                                padding={0}
                                                boxShadow={
                                                  "0 0 4px rgb(0 0 0 / 24%)"
                                                }
                                                bg={"white"}
                                                _dark={{
                                                  bg: "#707070",
                                                }}
                                                borderRadius={"0 0 8px 8px"}
                                                margin={"1px 2% 0 2%"}
                                              >
                                                <TableContainer
                                                  width={"100%"}
                                                  id="sdda"
                                                >
                                                  <Table width={"100%"}>
                                                    <Thead>
                                                      <Tr
                                                        border={"none"}
                                                        background={"#d9d9d9"}
                                                        _dark={{
                                                          bg: "#464545",
                                                        }}
                                                        minHeight={"28px"}
                                                      >
                                                        <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          CASA
                                                        </Th>
                                                        <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          MONTO
                                                        </Th>
                                                        {/* <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          MONTO MONEDA
                                                        </Th> */}
                                                      </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                      {ItemReport.casa_pendiente_final.map(
                                                        (ItemPendiente, i) => (
                                                          <Tr
                                                            border={"none"}
                                                            key={i}
                                                          >
                                                            <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {
                                                                ItemPendiente.casa
                                                              }
                                                            </Td>
                                                            {/* <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {
                                                                ItemPendiente.simbolo
                                                              }{" "}
                                                              {
                                                                ItemPendiente.inicio
                                                              }{" "}
                                                              {
                                                                ItemPendiente.nomenclatura
                                                              }
                                                            </Td> */}
                                                            <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {
                                                                ItemPendiente.simbolo
                                                              }{" "}
                                                              {
                                                                ItemPendiente.inicio
                                                              }{" "}
                                                              {
                                                                ItemPendiente.nomenclatura
                                                              }
                                                            </Td>
                                                          </Tr>
                                                        )
                                                      )}
                                                    </Tbody>
                                                  </Table>
                                                </TableContainer>
                                              </AccordionPanel>
                                            </>
                                          )}
                                        </AccordionItem>
                                      </Accordion>

                                      <Accordion
                                        w={"100%"}
                                        // defaultIndex={[1]}
                                        allowMultiple
                                      >
                                        <AccordionItem
                                          borderStyle={"none"}
                                          justifyContent={"center"}
                                        >
                                          {({ isExpanded }) => (
                                            <>
                                              <AccordionButton
                                                _hover={{
                                                  bg: "white",
                                                }}
                                                _focus={{
                                                  outline: "none",
                                                }}
                                                _dark={{
                                                  bg: "#292929",
                                                }}
                                                boxShadow={
                                                  "0 0 4px rgb(0 0 0 / 24%)"
                                                }
                                                bg={"white"}
                                                height={"45px"}
                                                borderRadius={"8px"}
                                              >
                                                <Box
                                                  color={"black"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  fontSize={"1.8rem"}
                                                  flex="1"
                                                  textAlign="left"
                                                >
                                                  RECARGAS
                                                </Box>

                                                {/* <Box
                                                fontSize={'1.8rem'}
                                                flex='1'
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'black'}
                                                paddingRight={'4%'}
                                                textAlign='right'>
                                                $ {''}
                                              </Box> */}

                                                <Box
                                                  fontSize={"1.8rem"}
                                                  flex="1"
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"black"}
                                                  paddingRight={"4%"}
                                                  textAlign="right"
                                                >
                                                  {filteredData.money == "EUR"
                                                    ? "€"
                                                    : "$"}{" "}
                                                  {ItemReport.recargas_totales}
                                                </Box>

                                                {isExpanded ? (
                                                  <FaMinus fontSize="14px" />
                                                ) : (
                                                  <FaPlus fontSize="14px" />
                                                )}
                                              </AccordionButton>

                                              <AccordionPanel
                                                padding={0}
                                                boxShadow={
                                                  "0 0 4px rgb(0 0 0 / 24%)"
                                                }
                                                bg={"white"}
                                                _dark={{
                                                  bg: "#707070",
                                                }}
                                                borderRadius={"0 0 8px 8px"}
                                                margin={"1px 2% 0 2%"}
                                              >
                                                <TableContainer
                                                  width={"100%"}
                                                  id="sdda"
                                                >
                                                  <Table width={"100%"}>
                                                    <Thead>
                                                      <Tr
                                                        border={"none"}
                                                        background={"#d9d9d9"}
                                                        _dark={{
                                                          bg: "#464545",
                                                        }}
                                                        minHeight={"28px"}
                                                      >
                                                        <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          CASA
                                                        </Th>
                                                        <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          MONTO
                                                        </Th>
                                                        {/* <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          MONTO MONEDA
                                                        </Th> */}
                                                      </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                      {ItemReport.recargas.map(
                                                        (ItemRecarga) => (
                                                          <Tr border={"none"}>
                                                            <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {ItemRecarga.casa}
                                                            </Td>
                                                            <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {
                                                                ItemRecarga.simbolo
                                                              }{" "}
                                                              {
                                                                ItemRecarga.monto
                                                              }{" "}
                                                              {
                                                                ItemRecarga.nomenclatura
                                                              }
                                                            </Td>
                                                            {/* <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {
                                                                ItemRecarga.simbolo
                                                              }{" "}
                                                              {
                                                                ItemRecarga.monto
                                                              }{" "}
                                                              {
                                                                ItemRecarga.nomenclatura
                                                              }
                                                            </Td> */}
                                                          </Tr>
                                                        )
                                                      )}
                                                    </Tbody>
                                                  </Table>
                                                </TableContainer>
                                              </AccordionPanel>
                                            </>
                                          )}
                                        </AccordionItem>
                                      </Accordion>

                                      <Accordion
                                        w={"100%"}
                                        // defaultIndex={[1]}
                                        allowMultiple
                                      >
                                        <AccordionItem
                                          borderStyle={"none"}
                                          justifyContent={"center"}
                                        >
                                          {({ isExpanded }) => (
                                            <>
                                              <AccordionButton
                                                _hover={{
                                                  bg: "white",
                                                }}
                                                _focus={{
                                                  outline: "none",
                                                }}
                                                _dark={{
                                                  bg: "#292929",
                                                }}
                                                boxShadow={
                                                  "0 0 4px rgb(0 0 0 / 24%)"
                                                }
                                                bg={"white"}
                                                height={"45px"}
                                                borderRadius={"8px"}
                                              >
                                                <Box
                                                  color={"black"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  fontSize={"1.8rem"}
                                                  flex="1"
                                                  textAlign="left"
                                                >
                                                  RETIROS
                                                </Box>

                                                {/* <Box
                                                fontSize={'1.8rem'}
                                                flex='1'
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'black'}
                                                paddingRight={'4%'}
                                                textAlign='right'>
                                                $ {''}
                                              </Box> */}

                                                <Box
                                                  fontSize={"1.8rem"}
                                                  flex="1"
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"black"}
                                                  paddingRight={"4%"}
                                                  textAlign="right"
                                                >
                                                  {filteredData.money == "EUR"
                                                    ? "€"
                                                    : "$"}{" "}
                                                  {ItemReport.retiros_totales}
                                                </Box>

                                                {isExpanded ? (
                                                  <FaMinus fontSize="14px" />
                                                ) : (
                                                  <FaPlus fontSize="14px" />
                                                )}
                                              </AccordionButton>

                                              <AccordionPanel
                                                padding={0}
                                                boxShadow={
                                                  "0 0 4px rgb(0 0 0 / 24%)"
                                                }
                                                bg={"white"}
                                                _dark={{
                                                  bg: "#707070",
                                                }}
                                                borderRadius={"0 0 8px 8px"}
                                                margin={"1px 2% 0 2%"}
                                              >
                                                <TableContainer
                                                  width={"100%"}
                                                  id="sdda"
                                                >
                                                  <Table width={"100%"}>
                                                    <Thead>
                                                      <Tr
                                                        border={"none"}
                                                        background={"#d9d9d9"}
                                                        _dark={{
                                                          bg: "#464545",
                                                        }}
                                                        minHeight={"28px"}
                                                      >
                                                        <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          CASA
                                                        </Th>
                                                        <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          MONTO
                                                        </Th>
                                                        {/* <Th
                                                          border={"none"}
                                                          textAlign={"center"}
                                                          fontSize={"1.5rem"}
                                                          _dark={{
                                                            color: "white",
                                                          }}
                                                          color={"black"}
                                                        >
                                                          MONTO MONEDA
                                                        </Th> */}
                                                      </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                      {ItemReport.retiros.map(
                                                        (itemRetiro, i) => (
                                                          <Tr
                                                            border={"none"}
                                                            key={i}
                                                          >
                                                            <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {itemRetiro.casa}
                                                            </Td>
                                                            {/* <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {
                                                                itemRetiro.simbolo
                                                              }{" "}
                                                              {itemRetiro.monto}{" "}
                                                              {
                                                                itemRetiro.nomenclatura
                                                              }
                                                            </Td> */}
                                                            <Td
                                                              border={"none"}
                                                              textAlign={
                                                                "center"
                                                              }
                                                              fontSize={
                                                                "1.6rem"
                                                              }
                                                              _dark={{
                                                                color: "white",
                                                              }}
                                                              color={"black"}
                                                            >
                                                              {
                                                                itemRetiro.simbolo
                                                              }{" "}
                                                              {itemRetiro.monto}{" "}
                                                              {
                                                                itemRetiro.nomenclatura
                                                              }
                                                            </Td>
                                                          </Tr>
                                                        )
                                                      )}
                                                    </Tbody>
                                                  </Table>
                                                </TableContainer>
                                              </AccordionPanel>
                                            </>
                                          )}
                                        </AccordionItem>
                                      </Accordion>
                                    </Flex>
                                  </Flex>

                                  <Flex
                                    justifyContent={"center"}
                                    // paddingTop={"1%"}
                                  >
                                    <Accordion
                                      w={{
                                        "2xl": "35%",
                                        xl: "35%",
                                        lg: "35%",
                                        md: "45%",
                                      }}
                                      // defaultIndex={[1]}
                                      allowMultiple
                                    >
                                      <AccordionItem
                                        borderStyle={"none"}
                                        justifyContent={"center"}
                                      >
                                        <>
                                          <AccordionButton
                                            _hover={{
                                              bg: "white",
                                            }}
                                            _focus={{
                                              outline: "none",
                                            }}
                                            _dark={{
                                              bg: "#292929",
                                            }}
                                            cursor={"default"}
                                            boxShadow={
                                              "0 0 4px rgb(0 0 0 / 24%)"
                                            }
                                            bg={"white"}
                                            paddingLeft={"4%"}
                                            height={{
                                              "2xl": "65px",
                                              xl: "55px",
                                              lg: "55px",
                                              md: "55px",
                                            }}
                                            borderRadius={"8px"}
                                          >
                                            <Box
                                              color={"black"}
                                              _dark={{
                                                color: "white",
                                              }}
                                              fontWeight={"bold"}
                                              fontSize={"1.8rem"}
                                              flex="1"
                                              textAlign="left"
                                            >
                                              GANANCIA DIARIA
                                            </Box>
                                            {/* <Box
                                            fontSize={'1.8rem'}
                                            flex='1'
                                            _dark={{
                                              color: 'white'
                                            }}
                                            color={'black'}
                                            paddingRight={'4%'}
                                            textAlign='right'>
                                            S/. {''} - $ {''}
                                          </Box> */}
                                            <Box
                                              fontSize={"1.8rem"}
                                              flex="1"
                                              _dark={{
                                                color: "white",
                                              }}
                                              color={"black"}
                                              paddingRight={"4%"}
                                              textAlign="right"
                                            >
                                              {filteredData.money == "EUR"
                                                ? "€"
                                                : "$"}{" "}
                                              {ItemReport.ganancia}
                                            </Box>
                                          </AccordionButton>
                                        </>
                                      </AccordionItem>
                                    </Accordion>
                                  </Flex>
                                </Flex>
                              ))}
                            </Flex>
                          </Flex>
                        </TabPanel>
                      </>
                    )}

                    {/* ------------------REPORTE GENERAL ---------------- */}
                    {rol.includes("JEFE DE SALA") ||
                      (rol.includes("ADMINISTRADOR") && (
                        <TabPanel
                          w={"100%"}
                          bg={"#ffff"}
                          borderRadius="10px"
                          p={"2%"}
                          boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                        >
                          <Flex
                            w={"100%"}
                            justifyContent={"space-between"}
                            padding={"1% 0 0 0"}
                          >
                            <Flex w={"auto"} alignItems={"center"}>
                              <Select
                                bg={"white"}
                                onChange={handleFilterGeneralChange}
                                value={filteredDataGeneral.money}
                                name="money"
                                height={"40px"}
                                fontSize={"1.8rem"}
                                border={"2px solid"}
                                color="#292F36"
                                borderColor={"#292F36"}
                                borderRadius="10px"
                                _dark={{
                                  color: "white",
                                  borderColor: "#292F36",
                                  bg: "#292F36",
                                }}
                              >
                                <option value="all" disabled>
                                  Filtro Moneda
                                </option>
                                <option value="LOCAL">LOCAL</option>
                                <option value="USD">USD</option>
                                <option value="EUR" disabled>
                                  EUR
                                </option>
                              </Select>
                            </Flex>
                            <Flex
                              // w={"23%"}
                              alignItems={"center"}
                              gap={"1.5%"}
                            >
                              <Input
                                placeholder="Fecha"
                                onChange={handleFilterGeneralChange}
                                value={filteredDataGeneral.fecha}
                                name="fecha"
                                size="md"
                                type="date"
                                bg={"white"}
                                height={"40px"}
                                fontSize={"1.8rem"}
                                border={"2px solid"}
                                color="#292F36"
                                borderColor={"#292F36"}
                                borderRadius="10px"
                                _dark={{
                                  color: "white",
                                  borderColor: "#292F36",
                                  bg: "#292F36",
                                }}
                              />
                              <Stack align="center" width={"30%"}>
                                <Button
                                  w={"100%"}
                                  height={"40px"}
                                  borderRadius="12px"
                                  color={"white"}
                                  onClick={searchReportGeneral}
                                  fontSize={"1.8rem"}
                                  bg="#292F36"
                                  _dark={{
                                    color: "white",
                                    bg: "#f59f2c",
                                  }}
                                >
                                  Buscar
                                </Button>
                              </Stack>
                            </Flex>
                          </Flex>
                          <Flex
                            w={"100%"}
                            paddingTop={"1%"}
                            justifyContent={"right"}
                          >
                            <Flex
                              bg={"transparent"}
                              justify={"center"}
                              w={{
                                "2xl": "15%",
                                xl: "17%",
                                lg: "20%",
                                md: "27%",
                              }}
                              fontSize={"1.5rem"}
                              textTransform={"uppercase"}
                              borderRadius="10px"
                              p={"1% 2%"}
                              gap={"4%"}
                              boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}
                            >
                              <Text fontWeight={"600"}>Ganancia Diaria:</Text>{" "}
                              <Text>
                                {calcularSumaTotalGanancias(
                                  listReportGeneral
                                ).toFixed(2)}
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex
                            w={"100%"}
                            h="100%"
                            marginTop={"20px"}
                            direction={"column"}
                            gap="20px"
                            justifyContent="center"
                          >
                            <TableContainer w={"100%"}>
                              <Table variant="simple">
                                <Thead>
                                  <Tr>
                                    <Th w={"25%"} fontSize={"1.8rem"}>
                                      OPERADOR
                                    </Th>
                                    <Th w={"25%"} fontSize={"1.8rem"}>
                                      BAN TOTAL
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      RETIROS
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      RECARGAS
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      TOTAL INICIAL
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      TOTAL TERMINO
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      GANANCIA
                                    </Th>
                                  </Tr>
                                </Thead>
                                {listReportGeneral?.map((itemInicial) => {
                                  return (
                                    <Tbody fontSize={"1.6rem"}>
                                      <Tr>
                                        <Td
                                          w={"25%"}
                                          padding={"1.05%"}
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemInicial.sportrader}
                                        </Td>
                                        <Td
                                          w={"25%"}
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemInicial.ban_total.toFixed(2)}
                                        </Td>
                                        <Td
                                          w={"25%"}
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                        >
                                          {itemInicial.retiros.toFixed(2)}
                                        </Td>
                                        <Td
                                          w={"25%"}
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                        >
                                          {itemInicial.recargas.toFixed(2)}
                                        </Td>
                                        <Td
                                          w={"25%"}
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                        >
                                          {itemInicial.total_inicio.toFixed(2)}
                                        </Td>
                                        <Td
                                          w={"25%"}
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                        >
                                          {itemInicial.total_termino.toFixed(2)}
                                        </Td>
                                        <Td
                                          w={"25%"}
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                        >
                                          {itemInicial.ganancia.toFixed(2)}
                                        </Td>
                                      </Tr>
                                    </Tbody>
                                  );
                                })}

                                <Tfoot>
                                  <Tr>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      TOTAL:
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      {calcularTotalBan(
                                        listReportGeneral
                                      ).toFixed(2)}
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      {calcularRetiros(
                                        listReportGeneral
                                      ).toFixed(2)}
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      {calcularRecargas(
                                        listReportGeneral
                                      ).toFixed(2)}
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      {calcularTotalInicial(
                                        listReportGeneral
                                      ).toFixed(2)}
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      {calcularTotalTermino(
                                        listReportGeneral
                                      ).toFixed(2)}
                                    </Th>
                                    <Th
                                      w={"25%"}
                                      fontSize={"1.8rem"}
                                      lineHeight="18px"
                                    >
                                      {calcularSumaTotalGanancias(
                                        listReportGeneral
                                      ).toFixed(2)}
                                    </Th>
                                  </Tr>
                                </Tfoot>
                              </Table>
                            </TableContainer>

                            <Flex
                              w={"100%"}
                              justify={"center"}
                              // gap={{
                              //   '2xl': '33%',
                              //   xl: '32%',
                              //   lg: '28%',
                              //   md: '20%'
                              // }}
                            >
                              <Flex
                                // w={'100%'}
                                marginTop={"0.5%"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                gap={"0.5%"}
                              >
                                <Icon
                                  onClick={() => {
                                    prevPage();
                                  }}
                                  color={"#13235b"}
                                  cursor={"pointer"}
                                  boxSize="16"
                                  _hover={{
                                    color: "gray !important",
                                  }}
                                  _dark={{
                                    color: "#f59f2c",
                                  }}
                                  as={IoMdArrowDropleftCircle}
                                />
                                <Icon
                                  onClick={() => {
                                    nextPage();
                                  }}
                                  color={"#13235b"}
                                  cursor={"pointer"}
                                  boxSize="16"
                                  _hover={{
                                    color: "gray !important",
                                  }}
                                  _dark={{
                                    color: "#f59f2c",
                                  }}
                                  as={IoMdArrowDroprightCircle}
                                />
                              </Flex>
                            </Flex>
                          </Flex>
                        </TabPanel>
                      ))}

                    {/* ----------------CALENDARIO MENSUAL------------------------- */}

                    <TabPanel w={"100%"} p={"0%"}>
                      <Flex
                        direction={"column"}
                        justifyContent="center"
                        alignItems="center"
                        gap="20px"
                        w={"100%"}
                        h="100%"
                      >
                        <Flex
                          w={"100%"}
                          justifyContent={"space-between"}
                          gap={"10%"}
                          padding={"1% 0 0 0"}
                        >
                          <Flex w={"auto"}>
                            <Select
                              onChange={handleFilterMonthChange}
                              value={filteredDataMonth.money}
                              bg={"white"}
                              name="money"
                              height={"40px"}
                              fontSize={"1.8rem"}
                              border={"2px solid"}
                              color="#292F36"
                              borderColor={"#292F36"}
                              borderRadius="10px"
                              _dark={{
                                color: "white",
                                borderColor: "#292F36",
                                bg: "#292F36",
                              }}
                            >
                              <option value="" disabled>
                                Filtro Moneda
                              </option>
                              <option value="LOCAL">LOCAL</option>
                              <option value="USD">USD</option>
                              <option value="EUR" disabled>
                                EUR
                              </option>
                            </Select>
                          </Flex>

                          <Flex w={"auto"}>
                            <Select
                              onChange={handleFilterMonthChange}
                              value={filteredDataMonth.fecha}
                              name="fecha"
                              bg={"white"}
                              placeholder="Filtro de Año"
                              height={"40px"}
                              fontSize={"1.8rem"}
                              border={"2px solid"}
                              color="#292F36"
                              borderColor={"#292F36"}
                              borderRadius="10px"
                              _dark={{
                                color: "white",
                                borderColor: "#292F36",
                                bg: "#292F36",
                              }}
                            >
                              {years.map((year, index) => (
                                <option key={index} value={year}>
                                  {year}
                                </option>
                              ))}
                            </Select>
                          </Flex>

                          {rol.includes("JEFE DE SALA") ||
                            (rol.includes("ADMINISTRADOR") && (
                              <Flex w={"auto"} alignItems={"center"}>
                                <Select
                                  bg={"white"}
                                  onChange={handleFilterMonthChange}
                                  value={filteredDataMonth.modulo}
                                  name="modulo"
                                  height={"40px"}
                                  fontSize={"1.8rem"}
                                  border={"2px solid"}
                                  color="#292F36"
                                  borderColor={"#292F36"}
                                  borderRadius="10px"
                                  _dark={{
                                    color: "white",
                                    borderColor: "#292F36",
                                    bg: "#292F36",
                                  }}
                                >
                                  <option value="" disabled>
                                    Seleccionar modulo
                                  </option>
                                  {listModule &&
                                    listModule.map((itemModule, index) => {
                                      return (
                                        <option
                                          key={itemModule.id_modulo}
                                          value={`${itemModule.id_modulo}`}
                                        >
                                          {itemModule.nombre}
                                        </option>
                                      );
                                    })}
                                </Select>
                              </Flex>
                            ))}
                        </Flex>

                        <Flex
                          w={"100%"}
                          padding={"0"}
                          // direction={"column"}
                        >
                          <Grid
                            w={"100%"}
                            templateColumns={{
                              "2xl": "repeat(4, 1fr)",
                              xl: "repeat(4, 1fr)",
                              lg: "repeat(3, 1fr)",
                              md: "repeat(2, 1fr)",
                            }}
                            gap={6}
                          >
                            {listMonthReport.map((itemReport, index) => (
                              <GridItem w="100%" key={index}>
                                <Flex
                                  gap={"5px"}
                                  bg={"#FFFF"}
                                  p={"4% 2%"}
                                  borderRadius={"8px"}
                                  direction={"column"}
                                  alignItems={"center"}
                                >
                                  <Text
                                    textTransform={"uppercase"}
                                    fontSize={"2rem"}
                                    letterSpacing={"1.65px"}
                                    fontFamily={"monospace"}
                                    fontWeight={"600"}
                                  >
                                    {getMonthName(itemReport.mes)}
                                  </Text>
                                  <Flex
                                    w={"100%"}
                                    direction={"column"}
                                    justify={"center"}
                                    alignItems={"center"}
                                    fontSize={"1.5rem"}
                                    gap={"5px"}
                                  >
                                    <Text
                                      textTransform={"uppercase"}
                                      fontFamily={"monospace"}
                                      fontWeight={"600"}
                                    >
                                      Ganancia:
                                    </Text>
                                    <Text
                                      fontFamily={"monospace"}
                                      fontWeight={"600"}
                                    >
                                      {filteredDataMonth.money == "EUR"
                                        ? "€"
                                        : "$"}{" "}
                                      {itemReport.ganancia.toFixed(2)}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </GridItem>
                            ))}
                          </Grid>
                        </Flex>
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>

      {/* ------------------------------Regular Apertura----------------------------- */}
      <Modal
        isOpen={isOpen6}
        onClose={onClose6}
        isCentered
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "20%", xl: "25%", lg: "35%", md: "50%" }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Regularizar Apertura de Caja
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex
              direction={"column"}
              alignItems="center"
              gap="15px"
              w={"100%"}
              h="100%"
            >
              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Monto Inicial:
                </FormLabel>
                <Input
                  name="bank_inicial"
                  placeholder="0.00"
                  value={dataRegularizeApertura.bank_inicial}
                  onChange={handleRegularizeOpen}
                  fontSize={"1.8rem"}
                  h="35"
                  type="number"
                />
              </FormControl>
              <FormControl>
                <FormLabel
                  fontSize={"1.85rem"}
                  textTransform="uppercase"
                  fontWeight={"600"}
                >
                  JUSTIFICACI&Oacute;N:
                </FormLabel>
                <Textarea
                  name="comentario"
                  onChange={handleRegularizeOpen}
                  value={dataRegularizeApertura.comentario}
                  placeholder="Motivo de regularización"
                  fontSize={"1.8rem"}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Contraseña Administrativa:
                </FormLabel>
                <Input
                  name="password"
                  placeholder="*************"
                  value={dataRegularizeApertura.password}
                  onChange={handleRegularizeOpen}
                  fontSize={"1.8rem"}
                  h="35"
                />
              </FormControl>
              <Button
                textTransform="uppercase"
                fontSize={"1.6rem"}
                p="7%"
                onClick={() => {
                  regularizeApertura();
                }}
              >
                Actualizar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* ------------------------------Regular Cierre----------------------------- */}
      <Modal
        isOpen={isOpen3}
        onClose={onClose3}
        isCentered
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "20%", xl: "25%", lg: "35%", md: "50%" }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Regularizaci&oacute;n de Caja
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex
              direction={"column"}
              alignItems="center"
              gap="15px"
              w={"100%"}
              h="100%"
            >
              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Monto Final:
                </FormLabel>
                <Input
                  name="bank_final"
                  value={dataRegularize.bank_final}
                  onChange={handleChangeRegularize}
                  fontSize={"1.8rem"}
                  h="35"
                  type="number"
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Pendiente Final:
                </FormLabel>
                <Input
                  name="pendiente_final"
                  value={dataRegularize.pendiente_final}
                  onChange={handleChangeRegularize}
                  fontSize={"1.8rem"}
                  h="35"
                  type="number"
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Retiros:
                </FormLabel>
                <Input
                  name="retiros"
                  value={dataRegularize.retiros}
                  onChange={handleChangeRegularize}
                  fontSize={"1.8rem"}
                  h="35"
                  type="number"
                />
              </FormControl>
              <FormControl>
                <FormLabel
                  fontSize={"1.85rem"}
                  textTransform="uppercase"
                  fontWeight={"600"}
                >
                  JUSTIFICACI&Oacute;N:
                </FormLabel>
                <Textarea
                  name="comentario"
                  onChange={handleChangeRegularize}
                  value={dataRegularize.comentario}
                  placeholder="Motivo de regularización"
                  fontSize={"1.8rem"}
                />
              </FormControl>
              <Button
                textTransform="uppercase"
                fontSize={"1.6rem"}
                p="7%"
                onClick={() => {
                  setTypeBox("regular");
                  onOpen2();
                }}
              >
                Actualizar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* ------------------------------Crear aperturar----------------------------- */}
      <Modal
        isOpen={isOpen4}
        onClose={onClose4}
        isCentered
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "50%", xl: "70%", lg: "85%", md: "95%" }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Aperturar Caja
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex width={"100%"} justifyContent={"space-between"}>
              <Flex
                direction={"column"}
                alignItems="center"
                gap="15px"
                w={"30%"}
                h="100%"
              >
                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Casa:
                  </FormLabel>
                  <Select
                    name="id_cuenta_casa"
                    w="100%"
                    value={dataDiary.id_cuenta_casa}
                    fontSize={"1.6rem"}
                    height={"40px"}
                    onChange={handleChangeDiary}
                    fontFamily="monospace"
                    fontWeight={"600"}
                    textTransform="uppercase"
                    variant="filled"
                  >
                    <option value="" selected>
                      Seleccionar
                    </option>
                    {listCasas &&
                      listCasas.map((itemHome) => (
                        <option
                          key={itemHome.id_cuenta_casas}
                          value={itemHome.id_cuenta_casas}
                        >
                          {itemHome.persona.apellidos} -{" "}
                          {itemHome.casa.alias == null
                            ? itemHome.casa.nombre
                            : itemHome.casa.alias}
                        </option>
                      ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Monto:
                  </FormLabel>
                  <Input
                    name="bank_initial"
                    value={dataDiary.bank_initial}
                    fontSize={"1.8rem"}
                    h="35"
                    type="number"
                    onChange={handleChangeDiary}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Pendiente:
                  </FormLabel>
                  <Input
                    name="initial_pending"
                    value={dataDiary.initial_pending}
                    fontSize={"1.8rem"}
                    h="35"
                    type="number"
                    onChange={handleChangeDiary}
                  />
                </FormControl>

                <Button
                  textTransform="uppercase"
                  fontSize={"1.6rem"}
                  p="7%"
                  onClick={() => {
                    addBoxInital(dataDiary);
                  }}
                >
                  Agregar
                </Button>
              </Flex>
              <Center height="auto">
                <Divider orientation="vertical" />
              </Center>
              <Flex
                direction={"column"}
                alignItems="center"
                gap="18px"
                w={"65%"}
                h="100%"
              >
                <Text fontWeight={"bold"} fontSize={"2rem"}>
                  LISTA DE APERTURA
                </Text>
                <Flex w={"100%"} padding={"0"}>
                  <TableContainer w={"100%"}>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th fontSize={"1.6rem"} textAlign={"center"}>
                            CASA
                          </Th>
                          <Th fontSize={"1.6rem"} textAlign={"center"}>
                            MONTO
                          </Th>
                          <Th fontSize={"1.6rem"} textAlign={"center"}>
                            PENDIENTE
                          </Th>
                          <Th fontSize={"1.6rem"} textAlign={"center"}>
                            TOTAL
                          </Th>
                          <Th fontSize={"1.6rem"} textAlign={"center"}>
                            ACCIONES
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody fontSize={"1.5rem"}>
                        {boxsArray.boxs &&
                          boxsArray.boxs.map((itemBox, i) => (
                            <Tr key={i}>
                              <Td textAlign={"center"}>{itemBox.casa}</Td>
                              <Td textAlign={"center"}>
                                {itemBox.bank_initial}
                              </Td>
                              <Td textAlign={"center"}>
                                {itemBox.initial_pending}
                              </Td>
                              <Td textAlign={"center"}>
                                {parseFloat(itemBox.bank_initial) +
                                  parseFloat(itemBox.initial_pending)}
                              </Td>
                              <Td
                                fontWeight={"600"}
                                fontFamily="monospace"
                                isNumeric
                              >
                                <Tooltip
                                  hasArrow
                                  borderRadius={"4px"}
                                  fontSize={"1.2rem"}
                                  label="Eliminar"
                                  bg="#292F36"
                                >
                                  <IconButton
                                    // onClick={() => {
                                    //   eliminar(p.id);
                                    // }}
                                    size={"md"}
                                    borderRadius={"4px"}
                                    fontSize={"1.5rem"}
                                    background={"#be0000"}
                                    color={"#ffffff"}
                                    onClick={() => {
                                      removeItemBoxInitial(i);
                                    }}
                                    aria-label="Eliminar"
                                    icon={<MdDelete />}
                                  />
                                </Tooltip>
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"center"} marginTop={"3%"}>
              <Button
                textTransform="uppercase"
                fontSize={"1.7rem"}
                bg="#2a2e6e"
                color={"white"}
                height={"30px"}
                borderRadius={"8px"}
                p="2.2% 4%"
                onClick={() => {
                  if (boxsArray.boxs.length < 2) {
                    mensaje("Debe abrir por lo menos 2 casas", "top-right", 2);
                    return;
                  }
                  setTypeBox("inicial");
                  onOpen2();
                }}
              >
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* ------------------------------Crear Cierre----------------------------- */}
      <Modal
        isOpen={isOpen5}
        onClose={onClose5}
        isCentered
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "45%", xl: "55%", lg: "75%", md: "85%" }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Cierre De Caja
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex alignItems="center" w={"100%"} h="100%">
              <Flex w={"100%"} padding={"0"}>
                <TableContainer w={"100%"}>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th w={"33%"} fontSize={"1.6rem"}>
                          CASA
                        </Th>
                        <Th w={"33%"} fontSize={"1.6rem"}>
                          MONTO FINAL
                        </Th>
                        <Th w={"33%"} fontSize={"1.6rem"}>
                          PENDIENTE FINAL
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody fontSize={"1.5rem"}>
                      {listApertura &&
                        listApertura.map((itemBox, i) => {
                          const formatDate = moment(
                            itemBox.fecha_registro
                          ).format("YYYY-MM-DD");
                          if (formatDate == moment().format("YYYY-MM-DD")) {
                            return (
                              <Tr key={i}>
                                <Td w={"33%"}>
                                  {itemBox.cuenta_casa.casa.alias == null
                                    ? itemBox.cuenta_casa.casa.nombre
                                    : itemBox.cuenta_casa.casa.alias}
                                </Td>
                                <Td w={"33%"}>
                                  <Flex>
                                    <FormControl>
                                      <Input
                                        border={"solid 1px"}
                                        value={inputValues[i]?.bank_final || ""}
                                        onChange={(e) => {
                                          const newInputValues = [
                                            ...inputValues,
                                          ];
                                          newInputValues[i] = {
                                            ...newInputValues[i],
                                            bank_final: e.target.value,
                                            id_box:
                                              itemBox.id_movimiento_diario,
                                          };
                                          setInputValues(newInputValues);
                                        }}
                                        name={`bank_final${i}`}
                                        fontSize={"1.8rem"}
                                        h="35"
                                        type="number"
                                      />
                                    </FormControl>
                                  </Flex>
                                </Td>
                                <Td w={"33%"}>
                                  <Flex>
                                    <FormControl>
                                      <Input
                                        border={"solid 1px"}
                                        value={
                                          inputValues[i]?.final_pending || ""
                                        }
                                        onChange={(e) => {
                                          const newInputValues = [
                                            ...inputValues,
                                          ];
                                          newInputValues[i] = {
                                            ...newInputValues[i],
                                            final_pending: e.target.value,
                                          };
                                          setInputValues(newInputValues);
                                        }}
                                        name={`final_pending${i}`}
                                        fontSize={"1.8rem"}
                                        h="35"
                                        type="number"
                                      />
                                    </FormControl>
                                  </Flex>
                                </Td>
                              </Tr>
                            );
                          }
                        })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            {listApertura.length != 0 && (
              <Flex width={"100%"} justifyContent={"center"} marginTop={"3%"}>
                <Button
                  textTransform="uppercase"
                  fontSize={"1.7rem"}
                  bg="#2a2e6e"
                  color={"white"}
                  height={"30px"}
                  borderRadius={"8px"}
                  p="2.2% 4%"
                  onClick={() => {
                    saveFinalBoxs();
                    setTypeBox("final");
                  }}
                >
                  Guardar
                </Button>
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* ---------------Contraseña--------- */}
      <Modal
        isCentered
        isOpen={isOpen2}
        onClose={onClose2}
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "20%", xl: "25%", lg: "35%", md: "45%" }}
          // marginTop='15%'
          p={"1% 1% 1.5% 1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Confirmar Operaci&Oacute;n de Caja
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex
              direction={"column"}
              alignItems="center"
              gap="15px"
              w={"100%"}
              h="100%"
            >
              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Contraseña:
                </FormLabel>
                <InputGroup size="">
                  <Input
                    name="password"
                    onChange={(e) => {
                      setPasswordAdmin({ password: e.target.value });
                    }}
                    borderRadius="4px"
                    padding={"4% 3%"}
                    type={show ? "text" : "password"}
                    fontSize={"1.8rem"}
                    h="35"
                  />
                  <InputRightElement
                    color={"#292F36"}
                    width="4.5rem"
                    fontSize={"1.4rem"}
                    onClick={handleClick}
                    top={5}
                  >
                    {show ? <EyeIcon /> : <EyeSlashIcon />}
                  </InputRightElement>
                </InputGroup>

                <FormHelperText fontSize={"1.5rem"}>
                  <Text>
                    <Highlight
                      query='"Jefe de Sala"'
                      styles={{ py: "1", fontWeight: "bold" }}
                    >
                      Ingrese contraseña del "Jefe de Sala"
                    </Highlight>
                  </Text>
                </FormHelperText>
              </FormControl>

              <Button
                textTransform="uppercase"
                bg="#2a2e6e"
                color={"white"}
                onClick={validatePassword}
                borderRadius={"6px"}
                fontSize={"1.6rem"}
                height={"35px"}
                width={"100%"}
              >
                Confirmar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* ------------------------------Cerrar Dia Anterior----------------------------- */}
      <Modal
        isOpen={isOpen7}
        onClose={onClose7}
        isCentered
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "30%", xl: "35%", lg: "40%", md: "45%" }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Cierre De Caja Anterior
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex alignItems="center" w={"100%"} h="100%">
              <Flex
                w={"100%"}
                padding={"0"}
                justifyContent={"center"}
                gap={10}
                flexDirection={"column"}
              >
                <Flex>
                  <FormControl>
                    <Input
                      border={"solid 1px"}
                      placeholder="Monto Final"
                      onChange={handleChangeCajaAnterior}
                      name="monto_final"
                      value={dataCajaAnterior.monto_final}
                      fontSize={"1.8rem"}
                      h="35"
                      type="number"
                    />
                  </FormControl>
                </Flex>
                <Flex>
                  <FormControl>
                    <Input
                      border={"solid 1px"}
                      placeholder="Pendiente Final"
                      onChange={handleChangeCajaAnterior}
                      name="pendiente_final"
                      value={dataCajaAnterior.pendiente_final}
                      fontSize={"1.8rem"}
                      h="35"
                      type="number"
                    />
                  </FormControl>
                </Flex>
                <Flex
                  direction={"column"}
                  alignItems="center"
                  gap="15px"
                  w={"100%"}
                  h="100%"
                >
                  <FormControl>
                    <FormLabel fontSize={"2rem"} fontWeight="500">
                      Contraseña:
                    </FormLabel>
                    <InputGroup size="">
                      <Input
                        onChange={handleChangeCajaAnterior}
                        name="password"
                        value={dataCajaAnterior.password}
                        borderRadius="4px"
                        padding={"4% 3%"}
                        type={show ? "text" : "password"}
                        fontSize={"1.8rem"}
                        h="35"
                      />
                      <InputRightElement
                        color={"#292F36"}
                        width="4.5rem"
                        fontSize={"1.4rem"}
                        onClick={handleClick}
                        top={5}
                      >
                        {show ? <EyeIcon /> : <EyeSlashIcon />}
                      </InputRightElement>
                    </InputGroup>

                    <FormHelperText fontSize={"1.5rem"}>
                      <Text>
                        <Highlight
                          query='"Jefe de Sala"'
                          styles={{ py: "1", fontWeight: "bold" }}
                        >
                          Ingrese contraseña del "Jefe de Sala"
                        </Highlight>
                      </Text>
                    </FormHelperText>
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"center"} marginTop={"3%"}>
              <Button
                textTransform="uppercase"
                fontSize={"1.7rem"}
                bg="#2a2e6e"
                color={"white"}
                height={"30px"}
                borderRadius={"8px"}
                p="2.2% 4%"
                onClick={closeCajaAnterior}
              >
                Guardar Cambios
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* ---------------Contraseña--------- */}
      <Modal
        isCentered
        isOpen={isOpen2}
        onClose={onClose2}
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "20%", xl: "25%", lg: "35%", md: "45%" }}
          // marginTop='15%'
          p={"1% 1% 1.5% 1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Confirmar Operaci&Oacute;n de Caja
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex
              direction={"column"}
              alignItems="center"
              gap="15px"
              w={"100%"}
              h="100%"
            >
              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Contraseña:
                </FormLabel>
                <InputGroup size="">
                  <Input
                    name="password"
                    onChange={(e) => {
                      setPasswordAdmin({ password: e.target.value });
                    }}
                    borderRadius="4px"
                    padding={"4% 3%"}
                    type={show ? "text" : "password"}
                    fontSize={"1.8rem"}
                    h="35"
                  />
                  <InputRightElement
                    color={"#292F36"}
                    width="4.5rem"
                    fontSize={"1.4rem"}
                    onClick={handleClick}
                    top={5}
                  >
                    {show ? <EyeIcon /> : <EyeSlashIcon />}
                  </InputRightElement>
                </InputGroup>

                <FormHelperText fontSize={"1.5rem"}>
                  <Text>
                    <Highlight
                      query='"Jefe de Sala"'
                      styles={{ py: "1", fontWeight: "bold" }}
                    >
                      Ingrese contraseña del "Jefe de Sala"
                    </Highlight>
                  </Text>
                </FormHelperText>
              </FormControl>

              <Button
                textTransform="uppercase"
                bg="#2a2e6e"
                color={"white"}
                onClick={validatePassword}
                borderRadius={"6px"}
                fontSize={"1.6rem"}
                height={"35px"}
                width={"100%"}
              >
                Confirmar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* ------------------------------Agregar Meta----------------------------- */}
      <Modal
        isOpen={isOpenMeta}
        onClose={onCloseMeta}
        isCentered
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "22%", xl: "70%", lg: "85%", md: "95%" }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Agregar Meta
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex width={"100%"} justifyContent={"space-between"}>
              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Meta:
                </FormLabel>
                <InputGroup size="">
                  <Input
                    type="text"
                    value={modelMeta.meta}
                    onChange={(e) => {
                      setModelMeta({ ...modelMeta, meta: e.target.value });
                    }}
                    borderRadius="4px"
                    padding={"4% 3%"}
                    fontSize={"1.8rem"}
                    h="35"
                  />
                </InputGroup>
                <FormHelperText fontSize={"1.5rem"}>
                  <Text>
                    <Highlight
                      query='"Mes"'
                      styles={{ py: "1", fontWeight: "bold" }}
                    >
                      Ingrese la meta del "Mes"
                    </Highlight>
                  </Text>
                </FormHelperText>
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"center"} marginTop={"3%"}>
              <Button
                onClick={handleAgregarMeta}
                textTransform="uppercase"
                fontSize={"1.7rem"}
                bg="#2a2e6e"
                color={"white"}
                height={"30px"}
                borderRadius={"8px"}
                p="2.2% 4%"
              >
                Agregar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Diarios;
