import { ReactNode, useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  Box,
  Flex,
  // Avatar,
  // HStack,
  // Link,
  // IconButton,
  Button,
  // Menu,
  // MenuButton,
  // MenuList,
  // MenuItem,
  // Container,
  // Img,
  // MenuDivider,
  Text,
  Grid,
  GridItem,
  Highlight,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
  AccordionItem,
  Accordion,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  IconButton,
  useDisclosure,
  Icon,
  Input,
  Table,
  Thead,
  Tbody,
  // Tfoot,
  Tr,
  Th,
  Td,
  // TableCaption,
  TableContainer,
  Tabs,
  TabList,
  TabPanels,
  Tooltip,
  Tab,
  TabPanel,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Stack,
  InputGroup,
  InputLeftElement,
  // FormErrorMessage,
  // FormHelperText
} from '@chakra-ui/react';
import {
  IoMdArrowDroprightCircle,
  IoMdArrowDropleftCircle
} from 'react-icons/io';
import { BsFillHouseAddFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
import { RiEditFill } from 'react-icons/ri';
import { MdDoDisturbOff } from 'react-icons/md';
import { Search2Icon } from '@chakra-ui/icons';
import SidebarOperators from '../../../components/SidebarOperators';
import { host3 } from '../../../utils/constantes';
import axios from 'axios';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { get } from 'react-hook-form';
function Salas() {
  let history = useHistory();
  const [activo1, setActivo1] = useState(false);
  const token = localStorage.getItem('tokenadmin');
  const { id_franquicia, rol } = jwt_decode(token);

  if (!token) {
    history.push('/');
  }
  useEffect(() => {
    if (token) {
      if (rol.includes('INVERSIONISTA')) {
        history.push('/salas/administracion');
      }
      if (rol.includes('ADMINISTRADOR')) {
        history.push('/salas/Mantenimiento');
      }
    }
  }, []);

  const [search, setSearch] = useState('');

  const [setTiempo] = useState(false);
  const [ordenar, setOrdenar] = useState(false);

  // const [ordenarCiudad, setOrdenarCiudad] = useState(false);
  const [ordenarSala, setOrdenarSala] = useState(false);


  //----------------BUSCAR-----------------------

  const buscar = (e) => {
    setSearch(e.target.value);
  };


  //Mensaje
  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      default:
        break;
    }
  };

  // --------------REGISTRO MODULOS--------------------
  const [listModulo, setListModulo] = useState([]);
  const [listHallModule, setListHallModule] = useState([]);

  const fetchHallModule = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/modulos/${token}/list-modules-for-hall`
      );
      setListHallModule(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [module, setModule] = useState({
    nombre: '',
    usuario: '',
    contraseña: '',
    id_sala: ''
  });

  const handleChangeModule = ({ target: { name, value } }) => {
    setModule({ ...module, [name]: value });
  };

  //Crear modulo
  const createModule = async () => {
    try {
      const { status, data } = await axios.post(
        `${host3}/api/modulos/${token}`,
        module
      );

      if (status == 201) {
        mensaje(data.message, 'top-right', 1);
        onClose2();
        setModule({
          nombre: '',
          usuario: '',
          contraseña: '',
          id_sala: ''
        });
      }
      fetchHallModule();
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
  };

  const ListFiltered = () => {
    if (search) {
      var filtered = listHallModule.filter(
        (p) =>
          p.nombre_sala?.toString().toLowerCase().includes(search.toLowerCase())
      );
      return filtered.slice(currentPage2, currentPage2 + 6);
    } else {
      return listHallModule?.slice(currentPage2, currentPage2 + 6);
    }
   };
   

  // -------------Editar MODULOS--------------------

  const [updateModulo, setUpdateModulo] = useState({
    nombre: '',
    usuario: '',
    contraseña: '',
    id_sala: '',
    usuario_tmoney: ''
  });

  const ChangeModulo = (e) => {
    setUpdateModulo({
      ...updateModulo,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmitUpdateModulo = async (e) => {
    e.preventDefault();
    try {
      const { status, data } = await axios.put(
        `${host3}/api/modulos/${token}/${updateModulo.id_modulo}`,
        updateModulo
      );

      if (status == 201) {
        mensaje(data.message, 'top-right', 1);
        onClose3();
        setUpdateModulo({
          nombre: '',
          usuario: '',
          contraseña: '',
          id_sala: ''
        });
      }
      fetchHallModule();
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
  };

  /* SALA */

  // --------------Agregar SALA --------------------

  const [listSalas, setListSalas] = useState([]);
  const fetchSala = async () => {
    try {
      const { status, data } = await axios.get(
        `${host3}/api/salas/${token}`
      );
      if (status == 200) {
        setListSalas(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [ModelSala, setModelSala] = useState({
    nombre: '',
    direccion: '',
    ciudad: '',
    pais: ''
  });

  const changeSala = (e) => {
    setModelSala({
      ...ModelSala,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmitSala = async (e) => {
    e.preventDefault();
    try {
      const { status, data } = await axios.post(
        `${host3}/api/salas/${token}/`,
        ModelSala
      );

      if (status == 201) {
        mensaje(data.message, 'top-right', 1);
        onClose();
        setModelSala({
          nombre: '',
          direccion: '',
          ciudad: '',
          pais: ''
        });
      }
      fetchSala();
      fetchHallModule();
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
  };

  //----------------Editar-------------------------

  const [updateSala, setSala] = useState({
    nombre: '',
    estado: '',
    direccion: '',
    ciudad: '',
    pais: ''

    //id_franquicia: id_franquicia,
  });

  const ChangeSala = (e) => {
    setSala({
      ...updateSala,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .put(
        `${host3}/api/salas/${token}/${updateSala.id_sala}`,
        updateSala,
        {}
      )
      .then((res) => {
        mensaje(res.data.message, 'top-right', 1);
        onCloseSalas();
        setSala({
          nombre: '',
          estado: '',
          direccion: '',
          ciudad: ''
        });
        fetchSala();
      })
      .catch((err) => {
        mensaje(err.response.data.message, 'top-right', 2);
      });
  };

  //-----------------------Listar SALA------------------------------------------
  const ListFilteredSalas = () => {
    if (search) {
      var filtered = listSalas.filter(
        (p) =>
          p.nombre.toString().includes(search) ||
          p.estado.toString().includes(search)
      );
      return filtered.slice(currentPage, currentPage + 13);
    } else {
      return listSalas?.slice(currentPage, currentPage + 13);
    }
  };

  //Paginacion
  const [currentPage, setCurrentPage] = useState(0);

  const nextPage = () => {
    const cant = listModulo.filter((p) => p.sala.toString()).length;

    if (cant > currentPage) {
      setCurrentPage(currentPage + 13);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 13);
    }
  };

  //Paginacion
  const [currentPage2, setCurrentPage2] = useState(0);

  const nextPage2 = () => {
    const cant = listHallModule.filter((p) =>
      p.nombre_sala.toString()
    ).length;

    if (cant > currentPage2) {
      setCurrentPage2(currentPage2 + 6);
    }
  };

  const prevPage2 = () => {
    if (currentPage2 > 0) {
      setCurrentPage2(currentPage2 - 6);
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenSalas,
    onOpen: onOpenSalas,
    onClose: onCloseSalas
  } = useDisclosure();

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2
  } = useDisclosure();

  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3
  } = useDisclosure();

  const {
    isOpen: isOpen4,
    onOpen: onOpen4,
    onClose: onClose4
  } = useDisclosure();

  const {
    isOpen: isOpen5,
    onOpen: onOpen5,
    onClose: onClose5
  } = useDisclosure();

  const {
    isOpen: isOpen6,
    onOpen: onOpen6,
    onClose: onClose6
  } = useDisclosure();

  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar
  } = useDisclosure();

  useEffect(() => {
    fetchHallModule();
    fetchSala();
    // setListHallModule();
    setTimeout(() => {
      // setTiempo(true);
    }, 8000);
  }, []);

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: '1.2rem', textAlign: 'center' }}
        pauseOnHover
      />

      <Box
        scrollBehavior={'smooth'}
        bg={'#e5e5e5'}
        height={'auto'}
        maxHeight={'100vh'}
        overflowY={'auto'}
        css={{
          '&::-webkit-scrollbar': {
            width: '12px'
          },
          '&::-webkit-scrollbar-track': {
            background: '#6b6b6b',
            borderRadius: '0px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#9b9b9b',
            borderRadius: '6px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#9b9b9b'
          }
        }}>
        <Box
          as='section'
          display={{
            '2xl': 'unset',
            xl: 'unset',
            lg: 'none',
            md: 'none',
            sm: 'none'
          }}>
          <SidebarOperators />
        </Box>
        <Drawer
          isOpen={isOpenBar}
          onClose={onCloseBar}
          placement='left'>
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w='full' borderRight='none' />
          </DrawerContent>
        </Drawer>

        <Box h={'100vh'}>
          <Flex
            alignItems={'center'}
            justify={'flex-start'}
            // h={'100%'}
            sx={{
              '@media only screen and (min-width: 1200px) and (max-width: 1366px)':
                {
                  padding: '1.5% 3% 1.5% 19%'
                }
            }}
            p={{
              '2xl': '1.5% 3% 1.5% 15%',
              xl: '1.5% 3% 1.5% 18%',
              lg: '1.5% 2% 1.5% 2%',
              md: '1.5% 2% 1.5% 2%'
            }}
            // marginLeft={'15%'}
            // marginRight='2%'
            // marginBottom='1%'
            direction='column'
            gap={'25px'}>
            <Box transition='.3s ease' width={'100%'}>
              <Flex
                gap='2%'
                alignItems={'center'}
                as='header'
                w='full'
                h='14'>
                <IconButton
                  aria-label='Menu'
                  display={{
                    '2xl': 'none',
                    xl: 'none',
                    lg: 'flex',
                    md: 'flex',
                    sm: 'flex'
                  }}
                  background={'#292F36'}
                  color={'#ffffff'}
                  borderRadius={'4px'}
                  fontSize={'1.3rem'}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size='md'
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      color={'#707070'}
                      fontSize={'1.5rem'}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={'bold'}
                      color={'#292F36'}
                      fontSize={'1.5rem'}>
                      SALAS
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>
            {/* ------------------------------------------------------ */}

            <Flex width={'100%'} justifyContent={'center'}>
              <Flex
                sx={{
                  '@media only screen and (min-width: 1200px) and (max-width: 1366px)':
                    {
                      w: '80%'
                    }
                }}
                w={{
                  '2xl': '80%',
                  xl: '80%',
                  lg: '80%',
                  md: '100%',
                  sm: '100%'
                }}
                h='85vh'>
                <Tabs isFitted variant='enclosed' w={'100%'}>
                  <TabList
                    mb='1em'
                    bg={'#FFFF'}
                    borderRadius='15px'
                    p={'0.5%'}>
                    <Tab
                      borderRadius={'15px'}
                      _selected={{
                        color: 'white',
                        bg: '#2a2e6e'
                      }}
                      fontSize='2.1rem'
                      textTransform={'uppercase'}
                      fontFamily='monospace'
                      fontWeight={'600'}>
                      Salas
                    </Tab>
                    <Tab
                      borderRadius={'15px'}
                      _selected={{
                        color: 'white',
                        bg: '#2a2e6e'
                      }}
                      fontSize='2.1rem'
                      textTransform={'uppercase'}
                      fontFamily='monospace'
                      fontWeight={'600'}>
                      Asignar Modulos
                    </Tab>
                    {/* <Tab
                      borderRadius={'15px'}
                      _selected={{
                        color: 'white',
                        bg: '#2a2e6e'
                      }}
                      fontSize='2.1rem'
                      textTransform={'uppercase'}
                      fontFamily='monospace'
                      fontWeight={'600'}>
                      Asignacion de Casas
                    </Tab> */}
                  </TabList>

                  <TabPanels
                    display={'flex'}
                    justifyContent={'center'}>
                    <TabPanel
                      w={{
                        '2xl': '90%',
                        xl: '90%',
                        lg: '90%',
                        md: '100%'
                      }}
                      bg={'transparent'}
                      borderRadius='10px'
                      p={'2% 0%'}
                      // boxShadow='rgba(0, 0, 0, 0.35) 0px 5px 15px'
                    >
                      <Flex
                        width={'100%'}
                        justifyContent={'center'}
                        // direction={"column"}
                        gap={'25px'}>
                        <Flex
                          width={'100%'}
                          boxShadow={
                            'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'
                          }
                          background={'white'}
                          borderRadius={'18px'}
                          direction={'column'}
                          padding={'2% 4% 2% 4%'}>
                          <Flex
                            width={'100%'}
                            justifyContent={'right'}>
                            <Stack align='center' width={'auto'}>
                              <Button
                                onClick={onOpen}
                                w={'100%'}
                                height={'40px'}
                                borderRadius='8px'
                                color={'white'}
                                fontSize={'1.6rem'}
                                bg='#2a2e6e'
                                _hover={{
                                  color: 'white',
                                  bg: '#f59f2c'
                                }}>
                                Añadir Sala
                              </Button>
                            </Stack>
                          </Flex>
                          <Flex
                            w={'100%'}
                            h='100%'
                            justifyContent='center'
                            paddingTop={'2%'}>
                            <TableContainer w={'100%'}>
                              <Table variant='simple'>
                                <Thead>
                                  <Tr>
                                    <Th
                                      fontSize={'1.6rem'}
                                      cursor={'pointer'}
                                      onClick={() => {
                                        var ordenDocument;
                                        if (!ordenarSala) {
                                          ordenDocument =
                                            listSalas.sort((a, b) =>
                                              a.nombre.localeCompare(
                                                b.nombre
                                              )
                                            );
                                          setListSalas(ordenDocument);
                                          setOrdenarSala(true);
                                        } else {
                                          ordenDocument =
                                            listSalas.sort((a, b) =>
                                              b.nombre.localeCompare(
                                                a.nombre
                                              )
                                            );
                                          setListSalas(ordenDocument);
                                          setOrdenarSala(false);
                                        }
                                      }}>
                                      SALA
                                    </Th>
                                    <Th fontSize={'1.6rem'}>País</Th>
                                    <Th fontSize={'1.6rem'}>
                                      DIRECCIÓN
                                    </Th>
                                    <Th
                                      cursor={'pointer'}
                                      onClick={() => {
                                        var ordenDocument;
                                        if (!ordenarSala) {
                                          ordenDocument =
                                            listSalas.sort((a, b) =>
                                              a.ciudad.localeCompare(
                                                b.ciudad
                                              )
                                            );
                                          setListSalas(ordenDocument);
                                          setOrdenarSala(true);
                                        } else {
                                          ordenDocument =
                                            listSalas.sort((a, b) =>
                                              b.ciudad.localeCompare(
                                                a.ciudad
                                              )
                                            );
                                          setListSalas(ordenDocument);
                                          setOrdenarSala(false);
                                        }
                                      }}
                                      fontSize={'1.6rem'}>
                                      CIUDAD
                                    </Th>
                                    <Th fontSize={'1.6rem'}>
                                      ESTADO
                                    </Th>
                                    <Th isNumeric fontSize={'1.6rem'}>
                                      ACCIONES
                                    </Th>
                                  </Tr>
                                </Thead>
                                {ListFilteredSalas().map((p) => {
                                  return (
                                    <Tbody fontSize={'1.5rem'}>
                                      <Tr key={p.id_sala}>
                                        <Td>{p.nombre}</Td>
                                        <Td>{p.pais}</Td>

                                        <Td>{p.direccion}</Td>
                                        <Td>{p.ciudad}</Td>
                                        <Td>
                                          {p.estado == 1
                                            ? 'Activado'
                                            : 'Inactivo'}
                                        </Td>
                                        <Td
                                          fontWeight={'600'}
                                          display={'flex'}
                                          justifyContent={'flex-end'}
                                          gap={'5px'}
                                          fontFamily='monospace'
                                          isNumeric>
                                          <Tooltip
                                            hasArrow
                                            borderRadius={'4px'}
                                            fontSize={'1.2rem'}
                                            label='Editar'
                                            bg='#292F36'>
                                            <IconButton
                                              onClick={() => {
                                                setSala(p);
                                                onOpenSalas();
                                              }}
                                              size={'md'}
                                              borderRadius={'4px'}
                                              fontSize={'1.5rem'}
                                              background={'#f59f2c'}
                                              color={'#ffffff'}
                                              aria-label='Editar'
                                              icon={<RiEditFill />}
                                            />
                                          </Tooltip>

                                          {p.estado != 1 ? (
                                            <Tooltip
                                              hasArrow
                                              borderRadius={'4px'}
                                              fontSize={'1.2rem'}
                                              label='Activar'
                                              bg='#292F36'>
                                              <IconButton
                                                // onClick={() => {
                                                //   activar(p.id_colaborador);
                                                //   setActivo1(false);
                                                // }}
                                                onClick={async () => {
                                                  await axios
                                                    .put(
                                                      `${host3}/api/salas/${token}/${p.id_sala}/accion/1`
                                                    )
                                                    .then(
                                                      (result) => {
                                                        if (
                                                          result.status ==
                                                          200
                                                        ) {
                                                          window.alert(
                                                            result
                                                              .data
                                                              .message
                                                          );
                                                        } else {
                                                        }
                                                      }
                                                    )
                                                    .catch(
                                                      (error) => {
                                                        let op =
                                                          window.confirm(
                                                            error
                                                              .response
                                                              .data
                                                              .message
                                                          );
                                                      }
                                                    );
                                                  fetchSala();
                                                }}
                                                size={'md'}
                                                borderRadius={'4px'}
                                                fontSize={'1.5rem'}
                                                background={'#2b752d'}
                                                color={'#ffffff'}
                                                aria-label='Desactivar Sala'
                                                icon={
                                                  <FaCheckCircle />
                                                }
                                              />
                                            </Tooltip>
                                          ) : (
                                            <Tooltip
                                              hasArrow
                                              borderRadius={'4px'}
                                              fontSize={'1.2rem'}
                                              label='Desactivar'
                                              bg='#292F36'>
                                              <IconButton
                                                onClick={async () => {
                                                  if (
                                                    window.confirm(
                                                      '¿Estás seguro que quieres desactivar?'
                                                    )
                                                  ) {
                                                    await axios.put(
                                                      `${host3}/api/salas/${token}/${p.id_sala}/accion/0`
                                                    );
                                                    fetchSala();
                                                  }
                                                }}
                                                size={'md'}
                                                borderRadius={'4px'}
                                                fontSize={'1.5rem'}
                                                background={'#be0000'}
                                                color={'#ffffff'}
                                                aria-label='Desactivar Sala'
                                                icon={
                                                  <MdDoDisturbOff />
                                                }
                                              />
                                            </Tooltip>
                                          )}
                                        </Td>
                                      </Tr>
                                    </Tbody>
                                  );
                                })}
                              </Table>
                              <Flex
                                marginTop={'0.5%'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                gap={'0.5%'}>
                                <Icon
                                  onClick={() => {
                                    prevPage();
                                  }}
                                  color={'#13235b'}
                                  cursor={'pointer'}
                                  boxSize='16'
                                  _hover={{
                                    color: 'gray !important'
                                  }}
                                  _dark={{
                                    color: '#f59f2c'
                                  }}
                                  as={IoMdArrowDropleftCircle}
                                />
                                <Icon
                                  onClick={() => {
                                    nextPage();
                                  }}
                                  color={'#13235b'}
                                  cursor={'pointer'}
                                  boxSize='16'
                                  _hover={{
                                    color: 'gray !important'
                                  }}
                                  _dark={{
                                    color: '#f59f2c'
                                  }}
                                  as={IoMdArrowDroprightCircle}
                                />
                              </Flex>
                            </TableContainer>
                          </Flex>
                        </Flex>
                      </Flex>
                    </TabPanel>

                    {/* ----------------------------------------- */}

                    <TabPanel
                      w={'100%'}
                      display={'flex'}
                      flexDirection='column'
                      gap={'10px'}
                      bg={'transparent'}
                      // boxShadow='rgba(0, 0, 0, 0.35) 0px 5px 15px'
                      borderRadius='10px'
                      p={'0.5% 0%'}>
                         <Flex
                width={{
                  '2xl': '30%',
                  xl: '30%',
                  lg: '40%',
                  md: '50%'
                }}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    children={
                      <Search2Icon
                        color='#2a2e6e'
                        marginLeft={'20%'}
                        fontSize={'1.25rem'}
                        marginTop={'60%'}
                        _dark={{
                          color: '#f59f2c'
                        }}
                      />
                    }
                  />
                  <Input
                    onChange={buscar}
                    type='text'
                    placeholder='Buscar'
                    bg={'white'}
                    height={'40px'}
                    _focus={{
                      borderColor: '#2a2e6e',
                      boxShadow: 'none'
                    }}
                    color='#2a2e6e'
                    fontSize={'1.9rem'}
                    border={'1px solid'}
                    borderColor={'#2a2e6e'}
                    borderRadius='6px'
                    _placeholder={{
                      color: 'rgb(152, 152, 152)'
                    }}
                    _dark={{
                      color: 'white',
                      borderColor: '#686868',
                      bg: '#686868'
                    }}
                  />
                </InputGroup>
              </Flex>
                      <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                        {ListFiltered().map((p) => {
                          return (
                            <GridItem w='100%'>
                              <Flex
                                w={'100%'}
                                h='100%'
                                bg={'white'}
                                borderRadius='10px'
                                boxShadow={
                                  'rgba(0, 0, 0, 0.16) 0px 1px 4px'
                                }
                                padding={'2% 3% 3% 3%'}
                                gap={'6px'}
                                justifyContent='center'
                                direction={'column'}>
                                <Flex
                                  width={'100%'}
                                  marginBottom={'1%'}
                                  justifyContent={'center'}>
                                  <Text
                                    fontWeight={'normal'}
                                    fontSize={'2rem'}>
                                    <Highlight
                                      query='SALA:'
                                      styles={{
                                        // py: '1',
                                        fontWeight: '900'
                                      }}>
                                      {p.nombre_sala}
                                    </Highlight>
                                  </Text>
                                </Flex>

                                <Flex
                                  width={'100%'}
                                  justifyContent={'right'}>
                                  <Button
                                    onClick={() => {
                                      module.id_sala = p.id_sala;
                                      onOpen2();
                                    }}
                                    w={'auto'}
                                    height={'40px'}
                                    borderRadius='8px'
                                    color={'white'}
                                    fontSize={'1.6rem'}
                                    bg='#2a2e6e'
                                    _hover={{
                                      color: 'white',
                                      bg: '#f59f2c'
                                    }}>
                                    Añadir Modulo
                                  </Button>
                                </Flex>

                                <Accordion w={'100%'}>
                                  <AccordionItem
                                    w={'100%'}
                                    borderStyle={'none'}
                                    justifyContent={'center'}>
                                    <AccordionButton
                                      paddingRight={'2%'}
                                      color={'#ffffff'}
                                      _hover={{
                                        bg: '#292F36'
                                      }}
                                      _focus={{
                                        outline: 'none'
                                      }}
                                      _dark={{
                                        bg: '#292F36'
                                      }}
                                      cursor={'auto'}
                                      background={'#292F36'}
                                      boxShadow={
                                        '0 0 4px rgb(0 0 0 / 24%)'
                                      }
                                      height={'45px'}
                                      borderRadius={'8px'}>
                                      <Table>
                                        <Thead>
                                          <Tr width={'100%'}>
                                            <Th
                                              color={'#ffffff'}
                                              border={'none'}
                                              width={'33%'}
                                              fontSize={'1.8rem'}>
                                              MODULO
                                            </Th>
                                            <Th
                                              textAlign={'center'}
                                              color={'#ffffff'}
                                              border={'none'}
                                              width={'33%'}
                                              fontSize={
                                                '1.8rem'
                                              }></Th>
                                            <Th
                                              color={'#ffffff'}
                                              border={'none'}
                                              width={'33%'}
                                              isNumeric
                                              fontSize={
                                                '1.8rem'
                                              }></Th>
                                          </Tr>
                                        </Thead>
                                      </Table>
                                    </AccordionButton>
                                  </AccordionItem>
                                </Accordion>

                                {p.modulos.map((itemModule) => (
                                  <>
                                    <Accordion
                                      w={'100%'}
                                      gap={'15px'}
                                      // defaultIndex={[0]}
                                      allowMultiple>
                                      <Flex
                                        w={'100%'}
                                        gap={'15px'}
                                        direction={'column'}>
                                        <AccordionItem
                                          w={'100%'}
                                          borderStyle={'none'}
                                          justifyContent={'center'}>
                                          <AccordionButton
                                            _hover={{
                                              bg: 'white'
                                            }}
                                            _focus={{
                                              outline: 'none'
                                            }}
                                            _dark={{
                                              bg: '#292929'
                                            }}
                                            boxShadow={
                                              '0 0 4px rgb(0 0 0 / 24%)'
                                            }
                                            bg={'white'}
                                            height={'45px'}
                                            borderRadius={'8px'}>
                                            <Table>
                                              <Tbody
                                                fontSize={'1.7rem'}>
                                                <Tr width={'100%'}>
                                                  <Td
                                                    width={'45.5%'}
                                                    fontWeight={'600'}
                                                    fontFamily='monospace'>
                                                    {
                                                      itemModule.nombre
                                                    }
                                                  </Td>
                                                  <Td
                                                    width={'50%'}
                                                    isNumeric
                                                    fontWeight={'600'}
                                                    fontFamily='monospace'></Td>
                                                </Tr>
                                              </Tbody>
                                            </Table>

                                            <AccordionIcon
                                              fontSize={'1.6rem'}
                                            />
                                          </AccordionButton>

                                          <AccordionPanel
                                            padding={0}
                                            boxShadow={
                                              '0 0 4px rgb(0 0 0 / 24%)'
                                            }
                                            bg={'white'}
                                            _dark={{
                                              bg: '#707070'
                                            }}
                                            borderRadius={
                                              '0 0 8px 8px'
                                            }
                                            margin={'1px 2% 1% 2%'}>
                                            {/* Eitar Modulo */}

                                            <TableContainer
                                              width={'100%'}>
                                              <Table width={'100%'}>
                                                <Thead>
                                                  <Tr
                                                    border={'none'}
                                                    background={
                                                      '#d9d9d9'
                                                    }
                                                    _dark={{
                                                      bg: '#464545'
                                                    }}
                                                    minHeight={
                                                      '28px'
                                                    }>
                                                    <Th
                                                      border={'none'}
                                                      width={'33%'}
                                                      text
                                                      Align={'center'}
                                                      fontSize={
                                                        '1.6rem'
                                                      }
                                                      _dark={{
                                                        color: 'white'
                                                      }}
                                                      color={
                                                        '#13235b'
                                                      }>
                                                      CORREO
                                                    </Th>
                                                    <Th
                                                      border={'none'}
                                                      width={'33%'}
                                                      textAlign={
                                                        'center'
                                                      }
                                                      fontSize={
                                                        '1.6rem'
                                                      }
                                                      _dark={{
                                                        color: 'white'
                                                      }}
                                                      color={
                                                        '#13235b'
                                                      }>
                                                      CONTRASEÑA
                                                    </Th>
                                                    <Th
                                                      border={'none'}
                                                      width={'33%'}
                                                      textAlign={
                                                        'center'
                                                      }
                                                      fontSize={
                                                        '1.6rem'
                                                      }
                                                      _dark={{
                                                        color: 'white'
                                                      }}
                                                      color={
                                                        '#13235b'
                                                      }>
                                                      ESTADO
                                                    </Th>
                                                    <Th
                                                      border={'none'}
                                                      width={'33%'}
                                                      textAlign={
                                                        'center'
                                                      }
                                                      fontSize={
                                                        '1.6rem'
                                                      }
                                                      _dark={{
                                                        color: 'white'
                                                      }}
                                                      color={
                                                        '#13235b'
                                                      }>
                                                      ACCIONES
                                                    </Th>
                                                  </Tr>
                                                </Thead>

                                                <Tbody
                                                  bg={'transparent'}>
                                                  <Tr border={'none'}>
                                                    <Td
                                                      border={'none'}
                                                      width={'33%'}
                                                      // textAlign={'center'}
                                                      fontSize={
                                                        '1.7rem'
                                                      }
                                                      _dark={{
                                                        color: 'white'
                                                      }}
                                                      color={
                                                        '#13235b'
                                                      }>
                                                      {
                                                        itemModule.usuario
                                                      }
                                                    </Td>
                                                    <Td
                                                      border={'none'}
                                                      width={'33%'}
                                                      textAlign={
                                                        'center'
                                                      }
                                                      fontSize={
                                                        '1.7rem'
                                                      }
                                                      _dark={{
                                                        color: 'white'
                                                      }}
                                                      color={
                                                        '#13235b'
                                                      }>
                                                      {
                                                        itemModule.contraseña
                                                      }
                                                    </Td>
                                                    <Td
                                                      border={'none'}
                                                      width={'33%'}
                                                      textAlign={
                                                        'center'
                                                      }
                                                      fontSize={
                                                        '1.7rem'
                                                      }
                                                      _dark={{
                                                        color: 'white'
                                                      }}
                                                      color={
                                                        '#13235b'
                                                      }>
                                                      {itemModule.estado ==
                                                      1
                                                        ? 'Activo'
                                                        : 'Inactivo'}
                                                    </Td>
                                                    <Td
                                                      border={'none'}
                                                      width={'33%'}
                                                      textAlign={
                                                        'center'
                                                      }
                                                      fontSize={
                                                        '1.6rem'
                                                      }
                                                      _dark={{
                                                        color: 'white'
                                                      }}
                                                      color={
                                                        '#13235b'
                                                      }>
                                                      <Flex
                                                        justifyContent={
                                                          'center'
                                                        }
                                                        gap={'4%'}>
                                                        <Tooltip
                                                          hasArrow
                                                          borderRadius={
                                                            '4px'
                                                          }
                                                          fontSize={
                                                            '1.2rem'
                                                          }
                                                          label='Editar'
                                                          bg='#292F36'>
                                                          <IconButton
                                                            onClick={() => {
                                                              setUpdateModulo(
                                                                itemModule
                                                              );
                                                              onOpen3();
                                                            }}
                                                            size={
                                                              'md'
                                                            }
                                                            borderRadius={
                                                              '4px'
                                                            }
                                                            fontSize={
                                                              '1.5rem'
                                                            }
                                                            background={
                                                              '#f59f2c'
                                                            }
                                                            color={
                                                              '#ffffff'
                                                            }
                                                            aria-label='Editar'
                                                            icon={
                                                              <RiEditFill />
                                                            }
                                                          />
                                                        </Tooltip>

                                                        {itemModule.estado !=
                                                        1 ? (
                                                          <Tooltip
                                                            hasArrow
                                                            borderRadius={
                                                              '4px'
                                                            }
                                                            fontSize={
                                                              '1.2rem'
                                                            }
                                                            label='Activar'
                                                            bg='#292F36'>
                                                            <IconButton
                                                              onClick={async () => {
                                                                await axios
                                                                  .put(
                                                                    `${host3}/api/modulos/${token}/${itemModule.id_modulo}/accion/1`
                                                                  )
                                                                  .then(
                                                                    (
                                                                      result
                                                                    ) => {
                                                                      if (
                                                                        result.status ==
                                                                        200
                                                                      ) {
                                                                        window.alert(
                                                                          result
                                                                            .data
                                                                            .message
                                                                        );
                                                                      } else {
                                                                      }
                                                                    }
                                                                  )
                                                                  .catch(
                                                                    (
                                                                      error
                                                                    ) => {
                                                                      let op =
                                                                        window.confirm(
                                                                          error
                                                                            .response
                                                                            .data
                                                                            .message
                                                                        );
                                                                    }
                                                                  );
                                                                fetchHallModule();
                                                              }}
                                                              size={
                                                                'md'
                                                              }
                                                              borderRadius={
                                                                '4px'
                                                              }
                                                              fontSize={
                                                                '1.5rem'
                                                              }
                                                              background={
                                                                '#2b752d'
                                                              }
                                                              color={
                                                                '#ffffff'
                                                              }
                                                              aria-label='Desactivar Sala'
                                                              icon={
                                                                <FaCheckCircle />
                                                              }
                                                            />
                                                          </Tooltip>
                                                        ) : (
                                                          <Tooltip
                                                            hasArrow
                                                            borderRadius={
                                                              '4px'
                                                            }
                                                            fontSize={
                                                              '1.2rem'
                                                            }
                                                            label='Desactivar'
                                                            bg='#292F36'>
                                                            <IconButton
                                                              onClick={async () => {
                                                                if (
                                                                  window.confirm(
                                                                    '¿Estás seguro que quieres desactivar?'
                                                                  )
                                                                ) {
                                                                  await axios.put(
                                                                    `${host3}/api/modulos/${token}/${itemModule.id_modulo}/accion/0`
                                                                  );
                                                                  fetchHallModule();
                                                                }
                                                              }}
                                                              size={
                                                                'md'
                                                              }
                                                              borderRadius={
                                                                '4px'
                                                              }
                                                              fontSize={
                                                                '1.5rem'
                                                              }
                                                              background={
                                                                '#be0000'
                                                              }
                                                              color={
                                                                '#ffffff'
                                                              }
                                                              aria-label='Desactivar Sala'
                                                              icon={
                                                                <MdDoDisturbOff />
                                                              }
                                                            />
                                                          </Tooltip>
                                                        )}
                                                      </Flex>
                                                    </Td>
                                                  </Tr>
                                                </Tbody>
                                              </Table>
                                            </TableContainer>
                                          </AccordionPanel>
                                        </AccordionItem>
                                      </Flex>
                                    </Accordion>
                                  </>
                                ))}
                              </Flex>
                            </GridItem>
                          );
                        })}
                      </Grid>
                      <Flex
                        marginTop={'0.5%'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        gap={'0.5%'}>
                        <Icon
                          onClick={() => {
                            prevPage2();
                          }}
                          color={'#13235b'}
                          cursor={'pointer'}
                          boxSize='16'
                          _hover={{
                            color: 'gray !important'
                          }}
                          _dark={{
                            color: '#f59f2c'
                          }}
                          as={IoMdArrowDropleftCircle}
                        />
                        <Icon
                          onClick={() => {
                            nextPage2();
                          }}
                          color={'#13235b'}
                          cursor={'pointer'}
                          boxSize='16'
                          _hover={{
                            color: 'gray !important'
                          }}
                          _dark={{
                            color: '#f59f2c'
                          }}
                          as={IoMdArrowDroprightCircle}
                        />
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>

      {/* ------------------------------Añadir modulo----------------------------- */}
      <Modal
        isOpen={isOpen2}
        onClose={onClose2}
        isCentered
        alignItems='center'
        display='flex'
        height='100%'>
        <ModalOverlay />
        <ModalContent
          maxW={{
            '2xl': '20%',
            xl: '28%',
            lg: '40%',
            md: '50%',
            sm: '80%'
          }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Nuevo modulo
          </ModalHeader>
          <ModalCloseButton fontSize={'1.4rem'} />
          <ModalBody>
            <Flex
              direction={'column'}
              alignItems='center'
              gap='15px'
              w={'100%'}
              h='100%'>
              <FormControl>
                <FormLabel fontSize={'2rem'} fontWeight='500'>
                  Modulo:
                </FormLabel>
                <Input
                  name='nombre'
                  fontSize={'1.8rem'}
                  h='35'
                  type='text'
                  onChange={handleChangeModule}
                  value={module.nombre}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={'2rem'} fontWeight='500'>
                  Usuario:
                </FormLabel>
                <Input
                  name='usuario'
                  fontSize={'1.8rem'}
                  h='35'
                  type='text'
                  onChange={handleChangeModule}
                  value={module.usuario}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={'2rem'} fontWeight='500'>
                  Contraseña:
                </FormLabel>
                <Input
                  name='contraseña'
                  fontSize={'1.8rem'}
                  h='35'
                  type='text'
                  onChange={handleChangeModule}
                  value={module.contraseña}
                />
              </FormControl>
              <Button
                onClick={() => {
                  createModule();
                }}
                textTransform='uppercase'
                fontSize={'1.6rem'}
                p='7%'>
                Guardar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* ------------------------------Modal Editar modulo----------------------------- */}
      <Modal
        isOpen={isOpen3}
        onClose={onClose3}
        isCentered
        alignItems='center'
        display='flex'
        height='100%'>
        <ModalOverlay />
        <ModalContent
          maxW={{
            '2xl': '20%',
            xl: '28%',
            lg: '40%',
            md: '50%',
            sm: '80%'
          }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Editar modulo
          </ModalHeader>
          <ModalCloseButton fontSize={'1.4rem'} />
          <ModalBody>
            <Flex
              direction={'column'}
              alignItems='center'
              gap='15px'
              w={'100%'}
              h='100%'>
              <FormControl>
                <FormLabel fontSize={'2rem'} fontWeight='500'>
                  Modulo:
                </FormLabel>
                <Input
                  value={updateModulo.nombre}
                  onChange={ChangeModulo}
                  name='nombre'
                  fontSize={'1.8rem'}
                  h='35'
                  type='text'
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={'2rem'} fontWeight='500'>
                  Usuario:
                </FormLabel>
                <Input
                  value={updateModulo.usuario}
                  onChange={ChangeModulo}
                  name='usuario'
                  fontSize={'1.8rem'}
                  h='35'
                  type='text'
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={'2rem'} fontWeight='500'>
                  Contraseña:
                </FormLabel>
                <Input
                  value={updateModulo.contraseña}
                  onChange={ChangeModulo}
                  name='contraseña'
                  fontSize={'1.8rem'}
                  h='35'
                  type='text'
                />
              </FormControl>

              <Button
                onClick={handleSubmitUpdateModulo}
                textTransform='uppercase'
                fontSize={'1.6rem'}
                p='7%'>
                Actualizar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* -----------------------------------------Salas-------------------------------------------------------------------------*/}
      {/* ---------------Agregar SALA--------- */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW={'xl'} p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Añadir Sala
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex width={'100%'} justifyContent={'space-between'}>
              <Flex
                direction={'column'}
                alignItems='center'
                gap='12px'
                w={'100%'}
                h='100%'>
                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Sala:
                    </FormLabel>
                    <Input
                      onChange={changeSala}
                      value={ModelSala.nombre}
                      name='nombre'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                </Flex>
                <Flex w={'100%'} direction={'column'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      País:
                    </FormLabel>
                    <Input
                      onChange={changeSala}
                      value={ModelSala.pais}
                      name='pais'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Dirección:
                    </FormLabel>
                    <Input
                      onChange={changeSala}
                      value={ModelSala.direccion}
                      name='direccion'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Ciudad:
                    </FormLabel>
                    <Input
                      onChange={changeSala}
                      value={ModelSala.ciudad}
                      name='ciudad'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={'100%'}
              justifyContent={'center'}
              marginTop={'2%'}>
              <Button
                w={'auto'}
                onClick={handleSubmitSala}
                height={'35px'}
                borderRadius={'8px'}
                textTransform='uppercase'
                color={'white'}
                fontSize={'1.6rem'}
                bg='#2a2e6e'
                _hover={{
                  color: 'white',
                  bg: '#f59f2c'
                }}
                p='2%'>
                Agregar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* --------------------Editar----------------- */}
      <Modal isOpen={isOpenSalas} onClose={onCloseSalas} isCentered>
        <ModalOverlay />
        <ModalContent maxW={'xl'} p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Editar Sala
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex width={'100%'} justifyContent={'space-between'}>
              <Flex
                direction={'column'}
                alignItems='center'
                gap='12px'
                w={'100%'}
                h='100%'>
                <FormControl>
                  <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                    Sala:
                  </FormLabel>
                  <Input
                    value={updateSala.nombre}
                    onChange={ChangeSala}
                    name='nombre'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                    País:
                  </FormLabel>
                  <Input
                    value={updateSala.pais}
                    onChange={ChangeSala}
                    name='pais'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                    Dirección:
                  </FormLabel>
                  <Input
                    value={updateSala.direccion}
                    onChange={ChangeSala}
                    name='direccion'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                    Ciudad:
                  </FormLabel>
                  <Input
                    value={updateSala.ciudad}
                    onChange={ChangeSala}
                    name='ciudad'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                  />
                </FormControl>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={'100%'}
              justifyContent={'center'}
              marginTop={'2%'}>
              <Button
                onClick={handleSubmit}
                height={'35px'}
                borderRadius={'8px'}
                textTransform='uppercase'
                color={'white'}
                fontSize={'1.6rem'}
                bg='#2a2e6e'
                _hover={{
                  color: 'white',
                  bg: '#f59f2c'
                }}
                p='2% 4%'>
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Salas;
