import { ReactNode, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Textarea,
  Tooltip,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Container,
  Img,
  MenuDivider,
  useDisclosure,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  Icon,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Text,
} from "@chakra-ui/react";
import {
  IoMdArrowDroprightCircle,
  IoMdArrowDropleftCircle,
} from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { BsInfoCircleFill, BsFillCalendar2CheckFill } from "react-icons/bs";
import { IoIosAddCircle } from "react-icons/io";
import { Search2Icon } from "@chakra-ui/icons";
import { RiEditFill } from "react-icons/ri";
import { FiMenu } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { host3 } from "../../../utils/constantes";
import SidebarOperators from "../../../components/SidebarOperators";
import jwt_decode from "jwt-decode";
import ListMonedas from "../../../sample/listMonedas.json";
import listCasasMantenimiento from "../../../sample/listCasasMantenimiento.json";
import moment from "moment";

function MCasas() {
  let history = useHistory();
  const token = localStorage.getItem("tokenadmin");
  const { id_persona, modulo, nombre_modulo, rol, nombre, sala } =
    jwt_decode(token);
  if (!token) {
    history.push("/");
  }
  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        break;
    }
  };

  const [tiempo, setTiempo] = useState(false);
  const [casas, setCasas] = useState([]);
  const [combineds, setCombineds] = useState([]);

  const [dataMovements, setDataMovements] = useState({
    tipo: "",
    id_cuenta_casas: "",
    monto: "0",
    pendiente: "",
    comentario: "",
    comision: "0",
    desfases: "0",
  });

  const handleChangeMovements = ({ target: { name, value } }) => {
    setDataMovements({ ...dataMovements, [name]: value });
  };

  // ---------------------- CUENTA CASAS --------------
  const [listCuentaCasas, setListCuentaCasas] = useState([]);

  // const fetchCuentaCasas = async () => {
  //   try {
  //     const { data } = await axios.get(
  //       `${host3}/api/cuenta_casas/${token}/mostrar`
  //     );
  //     setListCuentaCasas(
  //       data.filter((itemFilter) => itemFilter.id_modulo == modulo)
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const fetchCuentaCasas = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/movimiento_casas/${token}/mostrar-cuentas`
      );
      setListCuentaCasas(data);
    } catch (error) {
      console.log(error);
    }
  };

  // ---------- RECARGAS | RETIROS ---------------
  // --------------- Filtros
  const [filterMovements, setFilterMovements] = useState({
    fecha: moment().format("YYYY-MM-DD"),
    modulo: "1",
  });

  const handleChangeFilterMovement = ({ target: { name, value } }) => {
    setFilterMovements({ ...filterMovements, [name]: value });
  };

  // --------------- Mostrar

  const [listMovements, setListMovements] = useState([]);
  const [listFilterMovement, setListFilterMovement] = useState([]);

  const fetchMovements = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/movimiento_casas/${token}/listar`
      );

      let list = data.filter(
        (itemFilter) =>
          itemFilter.tipo == "RETIRO" || itemFilter.tipo == "RECARGA"
      );

      setListMovements(list);

      setListFilterMovement(
        list.filter(
          (itemFilter) =>
            itemFilter.fecha_registro == filterMovements.fecha &&
            itemFilter.id_modulo == filterMovements.modulo
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const ListFiltered = () => {
    return listFilterMovement?.slice(currentPage, currentPage + 13);
  };

  useEffect(() => {
    let modulo_rol = rol.includes("JEFE DE SALA") || rol.includes("ADMINISTRADOR")
      ? filterMovements.modulo
      : modulo;
    setListFilterMovement(
      listMovements.filter(
        (itemFilter) =>
          itemFilter.fecha_registro == filterMovements.fecha &&
          itemFilter.id_modulo == modulo_rol
      )
    );
  }, [filterMovements.fecha, filterMovements.modulo]);

  // ---------------CREAR MOVIMIENTOS
  const handleSubmitMovements = async () => {
    try {
      const { status, data } = await axios.post(
        `${host3}/api/movimiento_casas/${token}`,
        dataMovements
      );
      if (status == 201) {
        mensaje(data.message, "top-right", 1);
        setDataMovements({
          tipo: "",
          id_cuenta_casas: "",
          monto: "0",
          pendiente: "",
          comentario: "",
          comision: "0",
          desfases: "0",
        });
        fetchMovements();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  // ------------------------ MODULOS ------------------------
  const [listModule, setListModule] = useState([]);

  const fetchModules = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/modulos/${token}/list-modules-for-hall`
      );

      let datas = [];
      const dataFilter = data.filter(
        (itemFilter) => itemFilter.id_sala == sala[0]
      );
      dataFilter.map((itemData) => {
        itemData.modulos.map((itemModule) => datas.push(itemModule));
      });
      filterMovements.modulo = datas[0].id_modulo;
      setListModule(datas);
    } catch (error) {}
  };

  // --------------- ELIMINAR RECARGA
  const handleDeleteRecharge = async (id) => {
    try {
      const { status, data } = await axios.delete(
        `${host3}/api/movimiento_casas/${token}/${id}`
      );
      if (status == 201) {
        mensaje(data.message, "top-right", 1);
        fetchMovements();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  // --------------- EDITAR RETIRO
  const handleUpdateMovements = async (option) => {
    try {
      const { status, data } = await axios.put(
        `${host3}/api/movimiento_casas/${token}/pendientes/${dataMovements.movimiento_id}/accion/${option}`,
        dataMovements
      );
      if (status == 201) {
        mensaje(data.message, "top-right", 1);
        setDataMovements({
          tipo: "",
          id_cuenta_casas: "",
          monto: "0",
          pendiente: "",
          comentario: "",
          comision: "0",
          desfases: "0",
        });
        fetchMovements();
        onClose5();
      }
    } catch (error) {
      //console.log(error)
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  // ---------------------------------------- SPORTRADER -------------------------
  const [listSportrader, setListSportrader] = useState([]);

  const fetchSportrader = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/salas/${token}/list-sportrader-sala`
      );

      let sportrader = [];

      data.map((itemData) => {
        itemData.personas.map((itemPersona) => sportrader.push(itemPersona));
      });

      setListSportrader(
        sportrader.filter(
          (itemSportrader) => itemSportrader.id_persona != id_persona
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  // ------------------------- Por modulo
  const [listSportModule, setListSportModule] = useState([]);

  const fetchSportraderModule = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/operador-modulos/${token}`
      );
      setListSportModule(data);
    } catch (error) {
      console.log(error);
    }
  };

  // ----------------------------- COMBINADAS ----------------------------------

  // ------------------------------------ LISTAR
  const [listCombinadaSolicitante, setListCombinadaSolicitante] = useState([]);
  const [listCombinadaApoyo, setListCombinadaApoyo] = useState([]);

  //Solicitante
  const fetchCombinedByRequest = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/combinadas/${token}/solicitante`
      );

      setListCombinadaSolicitante(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const ListFiltered2 = () => {
    return listCombinadaSolicitante?.slice(currentPage, currentPage + 13);
  };

  //Apoyo
  const fetchCombinedBySupport = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/combinadas/${token}/apoyo`
      );

      setListCombinadaApoyo(data);
    } catch (error) {
      //console.log(error);
    }
  };

  const ListFiltered3 = () => {
    return listCombinadaApoyo?.slice(currentPage, currentPage + 13);
  };

  // ---------------------------------------- CREAR
  const [dataCombinada, setDataCombinada] = useState({
    num_ticket: "",
    casa: "",
    moneda: "",
    stake: "",
    cuota: "",
    id_apoyo: "",
  });

  const clearCombinada = () => {
    setDataCombinada({
      num_ticket: "",
      casa: "",
      moneda: "",
      stake: "",
      cuota: "",
      id_apoyo: "",
    });
  };

  const handleChangeCombineds = ({ target: { name, value } }) => {
    setDataCombinada({ ...dataCombinada, [name]: value });
  };

  const handleSubmitCombined = async () => {
    try {
      const { status, data } = await axios.post(
        `${host3}/api/combinadas/${token}`,
        dataCombinada
      );

      if (status == 201) {
        fetchCombinedByRequest();
        mensaje(data.message, "top-right", 1);
        clearCombinada();
        onClose3();
        fetchCombinedBySupport();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  // ------------------------------------------ ACTUALIZAR
  const handleUpdateCombined = async () => {
    try {
      const { status, data } = await axios.put(
        `${host3}/api/combinadas/${token}/${dataCombinada.id_combinada}`,
        dataCombinada
      );

      if (status == 201) {
        fetchCombinedByRequest();
        mensaje(data.message, "top-right", 1);
        clearCombinada();
        onClose2();
        fetchCombinedBySupport();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  // ------------------------------------------ ELIMINAR
  const handleDeleteCombined = async (id) => {
    try {
      const { status } = await axios.delete(
        `${host3}/api/combinadas/${token}/${id}`
      );
      if (status == 201) {
        window.location.reload();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  // ---------------------------------- ACTUALIZAR ESTADO APOYO
  const [dataUpdateStatus, setDataUpdateStatus] = useState({
    id_combinada: "",
    reembolso: "",
    status: "",
  });

  const handleChangeStatusCombined = ({ target: { name, value } }) => {
    setDataUpdateStatus({ ...dataUpdateStatus, [name]: value });
  };

  const handleUpdateStatusCombined = async () => {
    try {
      const { status, data } = await axios.put(
        `${host3}/api/combinadas/${token}/estado/${dataUpdateStatus.id_combinada}`,
        dataUpdateStatus
      );

      if (status == 201) {
        fetchCombinedBySupport();
        mensaje(data.message, "top-right", 1);
        setDataUpdateStatus({
          id_combinada: "",
          reembolso: "",
          status: "",
        });
        onClose4();
        fetchCombinedByRequest();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  //Paginacion Recargas
  const [currentPage, setCurrentPage] = useState(0);

  const nextPage = () => {
    const cant = casas.filter((p) => p.movimiento_id).length;

    if (cant > currentPage) {
      setCurrentPage(currentPage + 13);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 13);
    }
  };
  // ---------------------------
  //Paginacion Combinacion
  const [currentPage2, setCurrentPage2] = useState(0);

  const nextPage2 = () => {
    const cant = combineds.filter((c) => c.id.toString()).length;

    if (cant > currentPage2) {
      setCurrentPage2(currentPage2 + 11);
    }
  };

  const prevPage2 = () => {
    if (currentPage2 > 0) {
      setCurrentPage2(currentPage2 - 11);
    }
  };

  // -----------UseEffects----------------
  useEffect(() => {
    fetchCuentaCasas();
    fetchSportraderModule();
    fetchMovements();
    fetchSportrader();
    fetchCombinedByRequest();
    fetchModules();
    fetchCombinedBySupport();
  }, []);
  // ---------------------------

  const { isOpen, onOpen, onClose } = useDisclosure();

  // ---------------------modal combinadas-----------------------
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();

  const {
    isOpen: isOpen4,
    onOpen: onOpen4,
    onClose: onClose4,
  } = useDisclosure();
  const {
    isOpen: isOpen5,
    onOpen: onOpen5,
    onClose: onClose5,
  } = useDisclosure();

  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar,
  } = useDisclosure();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: "1.2rem", textAlign: "center" }}
        pauseOnHover
      />

      <Box
        scrollBehavior={"smooth"}
        bg={"#e5e5e5"}
        height={"auto"}
        maxHeight={"100vh"}
        overflowY={"auto"}
        css={{
          "&::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#6b6b6b",
            borderRadius: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#9b9b9b",
            borderRadius: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#9b9b9b",
          },
        }}
      >
        <Box
          as="section"
          display={{
            "2xl": "unset",
            xl: "unset",
            lg: "none",
            md: "none",
            sm: "none",
          }}
        >
          <SidebarOperators />
        </Box>
        <Drawer isOpen={isOpenBar} onClose={onCloseBar} placement="left">
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w="full" borderRight="none" />
          </DrawerContent>
        </Drawer>
        <Box h={"100vh"}>
          <Flex
            alignItems={"center"}
            justify={"flex-start"}
            h={"100%"}
            sx={{
              "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
                {
                  padding: "1.5% 3% 1.5% 19%",
                },
            }}
            p={{
              "2xl": "1.5% 3% 1.5% 15%",
              xl: "1.5% 3% 1.5% 18%",
              lg: "1.5% 2% 1.5% 2%",
              md: "1.5% 2% 1.5% 2%",
            }}
            // marginLeft={'15%'}
            // marginRight='2%'
            // marginBottom='1%'
            direction="column"
            gap={"25px"}
          >
            <Box transition=".3s ease" width={"100%"}>
              <Flex gap="2%" alignItems={"center"} as="header" w="full" h="14">
                <IconButton
                  aria-label="Menu"
                  display={{
                    "2xl": "none",
                    xl: "none",
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                  }}
                  background={"#292F36"}
                  color={"#ffffff"}
                  borderRadius={"4px"}
                  fontSize={"1.3rem"}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size="md"
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink color={"#707070"} fontSize={"1.5rem"}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={"bold"}
                      color={"#292F36"}
                      fontSize={"1.5rem"}
                    >
                      {rol.includes("JEFE DE SALA") || rol.includes("ADMINISTRADOR")
                        ? "REPORTE DE JUGADAS"
                        : "JUGADAS DIARIAS"}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>
            <Flex w={"100%"} justify={"flex-end"}>
              <Flex
                bgColor={"#FFFF"}
                p={"0.2% 1.2%"}
                borderRadius={"10px"}
                gap={"15px"}
                fontFamily={"monospace"}
                fontWeight={"600"}
                fontSize={"1.7rem"}
              >
                <Text>{nombre_modulo}</Text> /{" "}
                <Text>
                  {rol[0]}: {nombre}
                </Text>
              </Flex>
            </Flex>
            <Flex w={"100%"}>
              <Flex w={"100%"} h="85vh">
                <Tabs isFitted variant="enclosed" w={"100%"}>
                  <TabList mb="1em" bg={"#FFFF"} borderRadius="15px" p={"0.5%"}>
                    <Tab
                      borderRadius={"15px"}
                      _selected={{
                        color: "white",
                        bg: "#2a2e6e",
                      }}
                      fontSize={{
                        "2xl": "2.1rem",
                        xl: "1.84rem",
                        lg: "1.84rem",
                        md: "1.7rem",
                      }}
                      textTransform={"uppercase"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                    >
                      Recargas-Retiros
                    </Tab>

                    {!rol.includes("JEFE DE SALA") || rol.includes("ADMINISTRADOR") && (
                      <Tab
                        borderRadius={"15px"}
                        _selected={{
                          color: "white",
                          bg: "#2a2e6e",
                        }}
                        fontSize={{
                          "2xl": "2.1rem",
                          xl: "1.84rem",
                          lg: "1.84rem",
                          md: "1.7rem",
                        }}
                        textTransform={"uppercase"}
                        fontFamily="monospace"
                        fontWeight={"600"}
                      >
                        Combinadas
                      </Tab>
                    )}
                  </TabList>
                  <TabPanels>
                    <TabPanel
                      w={"100%"}
                      bg={"#ffff"}
                      borderRadius="10px"
                      p={"2%"}
                      boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                    >
                      <Flex
                        w={"100%"}
                        h="100%"
                        direction={"column"}
                        gap="20px"
                        justifyContent="center"
                      >
                        <Flex w={"100%"} justifyContent="space-between">
                          <Flex w={"auto"} alignItems={"center"} gap={"1.5%"}>
                            <Input
                              placeholder="Fecha de inicio"
                              onChange={handleChangeFilterMovement}
                              value={filterMovements.fecha}
                              name="fecha"
                              size="md"
                              type="date"
                              bg={"white"}
                              height={"40px"}
                              fontSize={"1.8rem"}
                              color="#2a2e6e"
                              borderColor={"#2a2e6e"}
                              _dark={{
                                color: "white",
                                borderColor: "#686868",
                                bg: "#686868",
                              }}
                            />
                            {/* 
                            <Stack align="center" width={"48%"}>
                              <Button
                                // onClick={handleClickSearch}
                                w={"100%"}
                                height={"40px"}
                                borderRadius="10px"
                                color={"white"}
                                fontSize={"1.6rem"}
                                bg="#2a2e6e"
                                _dark={{
                                  color: "white",
                                  bg: "#f59f2c",
                                }}
                              >
                                Buscar
                              </Button>
                            </Stack> */}
                          </Flex>

                          {rol.includes("JEFE DE SALA") || rol.includes("ADMINISTRADOR") && (
                            <Flex w={"auto"} alignItems={"center"}>
                              <Select
                                bg={"white"}
                                onChange={handleChangeFilterMovement}
                                value={filterMovements.modulo}
                                name="modulo"
                                height={"40px"}
                                fontSize={"1.8rem"}
                                border={"2px solid"}
                                color="#292F36"
                                borderColor={"#292F36"}
                                borderRadius="10px"
                                _dark={{
                                  color: "white",
                                  borderColor: "#292F36",
                                  bg: "#292F36",
                                }}
                              >
                                <option value="" disabled>
                                  Seleccionar modulo
                                </option>
                                {listModule &&
                                  listModule.map((itemModule, index) => {
                                    return (
                                      <option
                                        key={itemModule.id_modulo}
                                        value={`${itemModule.id_modulo}`}
                                      >
                                        {itemModule.nombre}
                                      </option>
                                    );
                                  })}
                              </Select>
                            </Flex>
                          )}

                          {rol.includes("SPORTRADER") && (
                            <Button
                              w={"15%"}
                              onClick={onOpen}
                              height={"40px"}
                              borderRadius="10px"
                              color={"white"}
                              fontSize={"1.6rem"}
                              bg="#2a2e6e"
                              p={"1% 1.5%"}
                              textTransform={"uppercase"}
                            >
                              Agregar
                            </Button>
                          )}
                        </Flex>

                        <TableContainer w={"100%"}>
                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                <Th fontSize={"1.8rem"}>FECHA</Th>
                                <Th fontSize={"1.8rem"}>CASA</Th>
                                <Th fontSize={"1.8rem"}>TIPO</Th>
                                <Th fontSize={"1.8rem"}>MONTO</Th>
                                <Th fontSize={"1.8rem"}>COMISI&Oacute;N</Th>
                                <Th fontSize={"1.8rem"}>MONEDA</Th>
                                <Th fontSize={"1.8rem"}>COMENTARIO</Th>
                                {filterMovements.fecha ==
                                  moment().format("YYYY-MM-DD") && (
                                  <Th fontSize={"1.8rem"} isNumeric>
                                    ACCION
                                  </Th>
                                )}
                              </Tr>
                            </Thead>
                            {ListFiltered().map((p) => {
                              return (
                                <Tbody fontSize={"1.6rem"}>
                                  <Tr>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {p.fecha_registro}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {p.cuenta_casa.casa.alias == null
                                        ? p.cuenta_casa.casa.nombre
                                        : p.cuenta_casa.casa.alias}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {p.tipo}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {p.tipo == "RETIRO"
                                        ? p.pendiente
                                        : p.monto}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {p.comision}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {p.cuenta_casa.moneda.nombre}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {p.comentario != null
                                        ? p.comentario
                                        : "----"}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                      isNumeric
                                    >
                                      {!rol.includes("JEFE DE SALA") || rol.includes("ADMINISTRADOR") && (
                                        <>
                                          {filterMovements.fecha ==
                                            moment().format("YYYY-MM-DD") && (
                                            <>
                                              {/* Eliminar Recarga */}
                                              {p.tipo == "RECARGA" &&
                                                p.estado == 1 && (
                                                  <Tooltip
                                                    hasArrow
                                                    borderRadius={"4px"}
                                                    fontSize={"1.2rem"}
                                                    label="Eliminar"
                                                    bg="#292F36"
                                                  >
                                                    <IconButton
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDeleteRecharge(
                                                          p.movimiento_id
                                                        );
                                                      }}
                                                      size={"md"}
                                                      borderRadius={"4px"}
                                                      fontSize={"1.5rem"}
                                                      background={"#be0000"}
                                                      color={"#ffffff"}
                                                      aria-label="Eliminar"
                                                      icon={<MdDelete />}
                                                    />
                                                  </Tooltip>
                                                )}

                                              {/* Eliminar Retiro */}
                                              {p.tipo == "RETIRO" &&
                                                p.estado == 2 && (
                                                  <Tooltip
                                                    hasArrow
                                                    borderRadius={"4px"}
                                                    fontSize={"1.2rem"}
                                                    label="Anular"
                                                    bg="#292F36"
                                                  >
                                                    <IconButton
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        setDataMovements(p);
                                                        onOpen5();
                                                        //deleteMovement(p.movimiento_id);
                                                      }}
                                                      size={"md"}
                                                      borderRadius={"4px"}
                                                      fontSize={"1.5rem"}
                                                      background={"#be0000"}
                                                      color={"#ffffff"}
                                                      aria-label="Anular"
                                                      icon={<MdDelete />}
                                                    />
                                                  </Tooltip>
                                                )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </Td>
                                  </Tr>
                                </Tbody>
                              );
                            })}
                          </Table>
                        </TableContainer>

                        <Flex
                          marginTop={"0.5%"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={"0.5%"}
                        >
                          <Icon
                            onClick={() => {
                              prevPage();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDropleftCircle}
                          />
                          <Icon
                            onClick={() => {
                              nextPage();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDroprightCircle}
                          />
                        </Flex>
                      </Flex>
                    </TabPanel>
                    {/* ----------------------------------------- */}
                    <TabPanel
                      w={"100%"}
                      bg={"transparent"}
                      borderRadius="10px"
                      p={"1% 2%"}
                      // boxShadow='rgba(0, 0, 0, 0.35) 0px 5px 15px;'
                    >
                      <Tabs isFitted variant="enclosed" w={"100%"}>
                        <TabList
                          mb="1em"
                          bg={"#FFFF"}
                          borderRadius="15px"
                          p={"0.5%"}
                        >
                          <Tab
                            borderRadius={"15px"}
                            _selected={{
                              color: "white",
                              bg: "#2a2e6e",
                            }}
                            fontSize={{
                              "2xl": "2.1rem",
                              xl: "1.84rem",
                              lg: "1.84rem",
                              md: "1.7rem",
                            }}
                            textTransform={"uppercase"}
                            fontFamily="monospace"
                            fontWeight={"600"}
                          >
                            Solicitud Combinada
                          </Tab>
                          <Tab
                            borderRadius={"15px"}
                            _selected={{
                              color: "white",
                              bg: "#2a2e6e",
                            }}
                            fontSize={{
                              "2xl": "2.1rem",
                              xl: "1.84rem",
                              lg: "1.84rem",
                              md: "1.7rem",
                            }}
                            textTransform={"uppercase"}
                            fontFamily="monospace"
                            fontWeight={"600"}
                          >
                            Apoyo Combinada
                          </Tab>
                        </TabList>
                        <TabPanels>
                          <TabPanel
                            w={"100%"}
                            bg={"#FFFF"}
                            borderRadius="10px"
                            p={"2%"}
                            boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                          >
                            <Flex
                              direction={"column"}
                              justifyContent="center"
                              alignItems="center"
                              gap="20px"
                              w={"100%"}
                              h="100%"
                            >
                              <Flex w={"100%"} justifyContent={"flex-end"}>
                                <Button
                                  w={"15%"}
                                  onClick={onOpen3}
                                  height={"40px"}
                                  borderRadius="10px"
                                  color={"white"}
                                  fontSize={"1.6rem"}
                                  bg="#2a2e6e"
                                  p={"1% 1.5%"}
                                  textTransform={"uppercase"}
                                >
                                  {" "}
                                  Agregar
                                </Button>
                              </Flex>
                              <TableContainer w={"100%"}>
                                <Table variant="simple">
                                  <Thead>
                                    <Tr>
                                      <Th fontSize={"1.8rem"}>NRO TICKET</Th>
                                      <Th fontSize={"1.8rem"}>CASA</Th>
                                      <Th fontSize={"1.8rem"}>MONEDA</Th>
                                      <Th fontSize={"1.8rem"}>STAKE</Th>
                                      <Th fontSize={"1.8rem"}>CUOTA</Th>
                                      <Th fontSize={"1.8rem"}>APOYO</Th>
                                      <Th fontSize={"1.8rem"}>REEMBOLSO</Th>
                                      <Th lineHeight="18px" fontSize={"1.8rem"}>
                                        GANANCIA
                                      </Th>
                                      <Th fontSize={"1.8rem"}>ESTADO</Th>
                                      <Th fontSize={"1.8rem"}>ACCIONES</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody fontSize={"1.6rem"}>
                                    {ListFiltered2()?.map((itemSolicitante) => (
                                      <Tr>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemSolicitante.num_ticket}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemSolicitante.casa}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemSolicitante.moneda}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemSolicitante.stake}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemSolicitante.cuota}
                                        </Td>

                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemSolicitante.apoyo.apellidos}{" "}
                                          {itemSolicitante.apoyo.nombres}
                                        </Td>

                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemSolicitante.reembolso}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemSolicitante.ganancia_solicitante}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemSolicitante.estado}
                                        </Td>
                                        {itemSolicitante.estado ==
                                          "PENDIENTE" && (
                                          <Td
                                            fontWeight={"600"}
                                            fontFamily="monospace"
                                            textTransform={"uppercase"}
                                            // isNumeric
                                          >
                                            <Flex gap={"4%"}>
                                              <Tooltip
                                                hasArrow
                                                borderRadius={"4px"}
                                                fontSize={"1.2rem"}
                                                label="Editar"
                                                bg="#292F36"
                                              >
                                                <IconButton
                                                  onClick={() => {
                                                    setDataCombinada(
                                                      itemSolicitante
                                                    );
                                                    onOpen2();
                                                  }}
                                                  size={"md"}
                                                  borderRadius={"4px"}
                                                  fontSize={"1.5rem"}
                                                  background={"#f59f2c"}
                                                  color={"#ffffff"}
                                                  aria-label="Editar"
                                                  icon={<RiEditFill />}
                                                />
                                              </Tooltip>
                                              <Tooltip
                                                hasArrow
                                                borderRadius={"4px"}
                                                fontSize={"1.2rem"}
                                                label="Eliminar"
                                                bg="#292F36"
                                              >
                                                <IconButton
                                                  onClick={() => {
                                                    handleDeleteCombined(
                                                      itemSolicitante.id_combinada
                                                    );
                                                  }}
                                                  size={"md"}
                                                  borderRadius={"4px"}
                                                  fontSize={"1.5rem"}
                                                  background={"#be0000"}
                                                  color={"#ffffff"}
                                                  aria-label="Eliminar"
                                                  icon={<MdDelete />}
                                                />
                                              </Tooltip>
                                            </Flex>
                                          </Td>
                                        )}
                                      </Tr>
                                    ))}
                                  </Tbody>
                                </Table>
                              </TableContainer>
                              <Flex
                                marginTop={"0.5%"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                gap={"0.5%"}
                              >
                                <Icon
                                  onClick={() => {
                                    prevPage2();
                                  }}
                                  color={"#13235b"}
                                  cursor={"pointer"}
                                  boxSize="16"
                                  _hover={{
                                    color: "gray !important",
                                  }}
                                  _dark={{
                                    color: "#f59f2c",
                                  }}
                                  as={IoMdArrowDropleftCircle}
                                />
                                <Icon
                                  onClick={() => {
                                    nextPage2();
                                  }}
                                  color={"#13235b"}
                                  cursor={"pointer"}
                                  boxSize="16"
                                  _hover={{
                                    color: "gray !important",
                                  }}
                                  _dark={{
                                    color: "#f59f2c",
                                  }}
                                  as={IoMdArrowDroprightCircle}
                                />
                              </Flex>
                            </Flex>
                          </TabPanel>
                          {/* --------------------------------------------------- */}
                          <TabPanel
                            w={"100%"}
                            bg={"#FFFF"}
                            borderRadius="10px"
                            p={"2%"}
                            boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                          >
                            <Flex
                              direction={"column"}
                              justifyContent="center"
                              alignItems="center"
                              gap="20px"
                              w={"100%"}
                              h="100%"
                            >
                              <TableContainer w={"100%"}>
                                <Table variant="simple">
                                  <Thead>
                                    <Tr>
                                      <Th fontSize={"1.8rem"}>NRO TICKET</Th>
                                      <Th fontSize={"1.8rem"}>CASA</Th>
                                      <Th fontSize={"1.8rem"}>MONEDA</Th>
                                      <Th fontSize={"1.8rem"}>STAKE</Th>
                                      <Th fontSize={"1.8rem"}>CUOTA</Th>
                                      <Th fontSize={"1.8rem"}>SOLICITANTE</Th>
                                      <Th fontSize={"1.8rem"}>REEMBOLSO</Th>
                                      <Th lineHeight="18px" fontSize={"1.8rem"}>
                                        GANANCIA
                                      </Th>
                                      <Th fontSize={"1.8rem"}>ESTADO</Th>
                                      <Th fontSize={"1.8rem"}>ACCIONES</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody fontSize={"1.6rem"}>
                                    {ListFiltered3()?.map((itemApoyo) => (
                                      <Tr>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemApoyo.num_ticket}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemApoyo.casa}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemApoyo.moneda}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemApoyo.stake}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemApoyo.cuota}
                                        </Td>

                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemApoyo.solicitante.apellidos}{" "}
                                          {itemApoyo.solicitante.nombres}
                                        </Td>

                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemApoyo.reembolso}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemApoyo.ganancia_apoyo}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                        >
                                          {itemApoyo.estado}
                                        </Td>
                                        <Td
                                          fontWeight={"600"}
                                          fontFamily="monospace"
                                          textTransform={"uppercase"}
                                          // isNumeric
                                        >
                                          <Flex gap={"4%"}>
                                            <Tooltip
                                              hasArrow
                                              borderRadius={"4px"}
                                              fontSize={"1.2rem"}
                                              label="Editar"
                                              bg="#292F36"
                                            >
                                              <IconButton
                                                onClick={() => {
                                                  dataUpdateStatus.id_combinada =
                                                    itemApoyo.id_combinada;
                                                  onOpen4();
                                                }}
                                                size={"md"}
                                                borderRadius={"4px"}
                                                fontSize={"1.5rem"}
                                                background={"#f59f2c"}
                                                color={"#ffffff"}
                                                aria-label="Editar"
                                                icon={<RiEditFill />}
                                              />
                                            </Tooltip>
                                          </Flex>
                                        </Td>
                                      </Tr>
                                    ))}
                                  </Tbody>
                                </Table>
                              </TableContainer>
                              <Flex
                                marginTop={"0.5%"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                gap={"0.5%"}
                              >
                                <Icon
                                  onClick={() => {
                                    prevPage2();
                                  }}
                                  color={"#13235b"}
                                  cursor={"pointer"}
                                  boxSize="16"
                                  _hover={{
                                    color: "gray !important",
                                  }}
                                  _dark={{
                                    color: "#f59f2c",
                                  }}
                                  as={IoMdArrowDropleftCircle}
                                />
                                <Icon
                                  onClick={() => {
                                    nextPage2();
                                  }}
                                  color={"#13235b"}
                                  cursor={"pointer"}
                                  boxSize="16"
                                  _hover={{
                                    color: "gray !important",
                                  }}
                                  _dark={{
                                    color: "#f59f2c",
                                  }}
                                  as={IoMdArrowDroprightCircle}
                                />
                              </Flex>
                            </Flex>
                          </TabPanel>
                        </TabPanels>
                      </Tabs>

                      {/* <Flex
                        direction={'column'}
                        justifyContent='center'
                        alignItems='center'
                        gap='20px'
                        w={'100%'}
                        h='100%'>
                        <TableContainer w={'100%'}>
                          <Table variant='simple'>
                            <Thead>
                              <Tr>
                                <Th fontSize={"1.8rem"}>FECHA</Th>
                                <Th fontSize={"1.8rem"}>SOLICITANTE</Th>
                                <Th fontSize={"1.8rem"}>CASA</Th>
                                <Th fontSize={"1.8rem"}>COUTA</Th>
                                <Th fontSize={"1.8rem"}>STAKE</Th>
                                <Th fontSize={"1.8rem"}>GANANCIA</Th>
                                <Th lineHeight="18px" fontSize={"1.8rem"}>
                                  MONTO
                                  <br /> GANADO
                                </Th>
                                <Th fontSize={"1.8rem"}>RESULTADO</Th>
                                <Th fontSize={"1.8rem"}>APOYO</Th>
                              </Tr>
                            </Thead>
                            {ListFiltered2().map((c) => {
                              return (
                                <Tbody fontSize={"1.6rem"}>
                                  <Tr>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {moment
                                        .parseZone(c.fecha)
                                        .local()
                                        .format("YYYY-MM-DD HH:mm")}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {c.solicitante}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {c.casa_solicitante}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {c.cuota_solicitante}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {c.stake_solicitante}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {c.ganancia_solicitante}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {c.monto_ganado_solicitante}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {c.ganancia_total_solicitante}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {c.casa_ganadora}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      <Flex
                                        width={"auto"}
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                      >
                                        <Flex>
                                          {c.apoyos
                                            .slice(0, 1)
                                            .map((ap, index) => (
                                              <span key={index}>
                                                {index === 0 ? "" : ", "}
                                                {ap.nombres}
                                              </span>
                                            ))}
                                          &nbsp;
                                          {c.apoyos.length > 1 && (
                                            <Tooltip
                                              background={"#292F36"}
                                              color={"#ffffff"}
                                              textAlign={"center"}
                                              fontSize={"1.5rem"}
                                              label={c.apoyos
                                                .slice(1)
                                                .map((ap) => ap.nombres)
                                                .join(", ")}
                                              placement="bottom-end"
                                            >
                                              <span>...</span>
                                            </Tooltip>
                                          )}
                                        </Flex>
                                        <Flex>
                                          <Tooltip
                                            hasArrow
                                            borderRadius={"4px"}
                                            fontSize={"1.2rem"}
                                            label="Datos"
                                            bg="#292F36"
                                          >
                                            <IconButton
                                              onClick={() => {
                                                setApoyoSeleccionado(c);
                                                onOpen3();
                                              }}
                                              size={"md"}
                                              borderRadius={"4px"}
                                              fontSize={"1.5rem"}
                                              background={"#f59f2c"}
                                              color={"#ffffff"}
                                              aria-label="Datos"
                                              icon={<BsInfoCircleFill />}
                                            />
                                          </Tooltip>
                                        </Flex>
                                      </Flex>
                                    </Td>
                                  </Tr>
                                </Tbody>
                              );
                            })}
                          </Table>
                        </TableContainer>
                        <Flex
                          marginTop={"0.5%"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={"0.5%"}
                        >
                          <Icon
                            onClick={() => {
                              prevPage2();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDropleftCircle}
                          />
                          <Icon
                            onClick={() => {
                              nextPage2();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDroprightCircle}
                          />
                        </Flex>
                      </Flex> */}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>

      {/* ------------------------------Agregar RR----------------------------- */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent maxW={"4xl"} marginTop="10%" p={"1%"}>
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Recargas y Retiro
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex
              direction={"column"}
              gap="15px"
              alignItems="center"
              w={"100%"}
              h="100%"
            >
              <Flex width={"100%"} justifyContent={"space-between"}>
                <Flex
                  direction={"column"}
                  alignItems="center"
                  gap="12px"
                  w={"100%"}
                  h="100%"
                >
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Cuenta Casa (Denominación):
                    </FormLabel>
                    <Select
                      onChange={handleChangeMovements}
                      value={dataMovements.id_cuenta_casas}
                      name="id_cuenta_casas"
                      w="100%"
                      fontSize={"1.6rem"}
                      height={"40px"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                      textTransform="uppercase"
                      variant="filled"
                    >
                      <option value="" selected>
                        Seleccionar
                      </option>
                      {listCuentaCasas &&
                        listCuentaCasas.map((itemHome) => (
                          <option
                            key={itemHome.id_movimiento_diario}
                            value={itemHome.cuenta_casa.id_cuenta_casas}
                          >
                            {itemHome.cuenta_casa.persona.apellidos} -{" "}
                            {itemHome.cuenta_casa.casa.alias == null
                              ? itemHome.cuenta_casa.casa.nombre
                              : itemHome.cuenta_casa.casa.alias}{" "}
                            - {itemHome.cuenta_casa.moneda.nombre}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  <Flex w={"100%"} gap={"5%"}>
                    <FormControl>
                      <FormLabel
                        fontSize={"1.85rem"}
                        textTransform="uppercase"
                        fontWeight={"600"}
                      >
                        Monto:
                      </FormLabel>
                      <Input
                        name="monto"
                        onChange={handleChangeMovements}
                        value={dataMovements.monto}
                        fontSize={"1.8rem"}
                        h="35"
                        type="number"
                      />
                    </FormControl>
                  </Flex>
                  <Flex w={"100%"} gap={"5%"}>
                    <FormControl>
                      <FormLabel
                        fontSize={"1.85rem"}
                        textTransform="uppercase"
                        fontWeight={"600"}
                      >
                        Comisi&oacute;n:
                      </FormLabel>
                      <Input
                        name="comision"
                        onChange={handleChangeMovements}
                        value={dataMovements.comision}
                        fontSize={"1.8rem"}
                        h="35"
                        type="number"
                      />
                    </FormControl>
                  </Flex>
                  <FormControl>
                    <FormLabel
                      fontSize={"1.85rem"}
                      textTransform="uppercase"
                      fontWeight={"600"}
                    >
                      Comentario:
                    </FormLabel>
                    <Textarea
                      name="comentario"
                      onChange={handleChangeMovements}
                      value={dataMovements.comentario}
                      placeholder=""
                      fontSize={"1.8rem"}
                    />
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={"100%"}
              justifyContent={"center"}
              marginTop={"2%"}
              gap={"3%"}
            >
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  dataMovements.tipo = "RECARGA";
                  handleSubmitMovements();
                }}
                height={"35px"}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                bg="#2a2e6e"
                _hover={{
                  color: "white",
                  bg: "#f59f2c",
                }}
                p="2%"
              >
                Recarga
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  dataMovements.tipo = "RETIRO";
                  handleSubmitMovements();
                }}
                height={"35px"}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                colorScheme="red"
                p="2% 4%"
              >
                Retiro
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* ------------------------------Editar RR----------------------------- */}
      <Modal
        isOpen={isOpen5}
        onClose={onClose5}
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent maxW={"4xl"} marginTop="10%" p={"1%"}>
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Retiro
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex
              direction={"column"}
              gap="15px"
              alignItems="center"
              w={"100%"}
              h="100%"
            >
              <Flex width={"100%"} justifyContent={"space-between"}>
                <Flex
                  direction={"column"}
                  alignItems="center"
                  gap="12px"
                  w={"100%"}
                  h="100%"
                >
                  {/* <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Cuenta Casa (Denominación):
                    </FormLabel>
                    <Select
                      onChange={handleChangeMovements}
                      value={dataMovements.id_cuenta_casas}
                      name="id_cuenta_casas"
                      w="100%"
                      fontSize={"1.6rem"}
                      height={"40px"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                      textTransform="uppercase"
                      variant="filled"
                    >
                      <option value="" selected>
                        Seleccionar
                      </option>
                      {listCuentaCasas &&
                        listCuentaCasas.map((itemHome) => (
                          <option
                          //selected={6}
                            key={itemHome.id_cuenta_casas}
                            value={itemHome.id_cuenta_casas}
                          >
                            {itemHome.persona.apellidos} -{" "}
                            {itemHome.casa.nombre} - {itemHome.moneda.nombre}
                          </option>
                        ))}
                    </Select>
                  </FormControl> */}
                  <Flex w={"100%"} gap={"5%"}>
                    <FormControl>
                      <FormLabel
                        fontSize={"1.85rem"}
                        textTransform="uppercase"
                        fontWeight={"600"}
                      >
                        Monto:
                      </FormLabel>
                      <Input
                        name="pendiente"
                        onChange={handleChangeMovements}
                        value={dataMovements.pendiente}
                        fontSize={"1.8rem"}
                        h="35"
                        type="number"
                      />
                    </FormControl>
                  </Flex>
                  <Flex w={"100%"} gap={"5%"}>
                    <FormControl>
                      <FormLabel
                        fontSize={"1.85rem"}
                        textTransform="uppercase"
                        fontWeight={"600"}
                      >
                        Comisi&oacute;n:
                      </FormLabel>
                      <Input
                        name="comision"
                        onChange={handleChangeMovements}
                        value={dataMovements.comision}
                        fontSize={"1.8rem"}
                        h="35"
                        type="number"
                      />
                    </FormControl>
                  </Flex>
                  <FormControl>
                    <FormLabel
                      fontSize={"1.85rem"}
                      textTransform="uppercase"
                      fontWeight={"600"}
                    >
                      Comentario:
                    </FormLabel>
                    <Textarea
                      name="comentario"
                      onChange={handleChangeMovements}
                      value={dataMovements.comentario}
                      placeholder=""
                      fontSize={"1.8rem"}
                    />
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={"100%"}
              justifyContent={"center"}
              marginTop={"2%"}
              gap={"3%"}
            >
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  //dataMovements.tipo = "RETIRO";
                  handleUpdateMovements(1);
                }}
                height={"35px"}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                bg="#2a2e6e"
                _hover={{
                  color: "white",
                  bg: "#f59f2c",
                }}
                p="2%"
              >
                Modificar
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  //dataMovements.tipo = "RETIRO";
                  handleUpdateMovements(2);
                }}
                height={"35px"}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                colorScheme="red"
                p="2% 4%"
              >
                Anular
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* ------------------------------Solicitud combinada----------------------------- */}
      <Modal
        isOpen={isOpen3}
        onClose={onClose3}
        alignItems="center"
        display="flex"
        isCentered
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "32%", xl: "36%", lg: "45%", md: "60%" }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Solicitud de combinada
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex direction={"column"} gap="40px" w={"100%"}>
              <Flex w={"100%"} gap={"5%"}>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Casa:
                  </FormLabel>
                  <Select
                    onChange={handleChangeCombineds}
                    value={dataCombinada.casa}
                    placeholder="Seleccione Casa"
                    name="casa"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    {listCasasMantenimiento.map((itemCasa) => (
                      <option key={itemCasa.casaF_id} value={itemCasa.nombre}>
                        {itemCasa.nombre.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Moneda:
                  </FormLabel>
                  <Select
                    onChange={handleChangeCombineds}
                    value={dataCombinada.moneda}
                    placeholder="Seleccione Moneda"
                    name="moneda"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    {ListMonedas.map((itemMoneda) => (
                      <option key={itemMoneda.pais} value={itemMoneda.nom}>
                        {itemMoneda.nom}-{itemMoneda.moneda}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>

              <Flex w={"100%"} gap={"5%"}>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Cuota:
                  </FormLabel>
                  <Input
                    onChange={handleChangeCombineds}
                    value={dataCombinada.cuota}
                    name="cuota"
                    fontSize={"1.8rem"}
                    h="35"
                    type="number"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Stake:
                  </FormLabel>
                  <Input
                    onChange={handleChangeCombineds}
                    value={dataCombinada.stake}
                    name="stake"
                    fontSize={"1.8rem"}
                    h="35"
                    type="number"
                  />
                </FormControl>
              </Flex>
              <Flex w={"100%"} gap={"5%"}>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Apoyo:
                  </FormLabel>
                  <Select
                    onChange={handleChangeCombineds}
                    value={dataCombinada.id_apoyo}
                    placeholder="Elegir Apoyo"
                    name="id_apoyo"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    {listSportrader &&
                      listSportrader.map((itemSportrader) => (
                        <option value={itemSportrader.id_persona}>
                          {itemSportrader.apellidos} {itemSportrader.nombres}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Flex>
              <Flex w={"100%"} gap={"5%"}>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Nro.Ticket:
                  </FormLabel>
                  <Input
                    onChange={handleChangeCombineds}
                    value={dataCombinada.num_ticket}
                    name="num_ticket"
                    fontSize={"1.8rem"}
                    h="35"
                    type="text"
                  />
                </FormControl>
              </Flex>

              <Flex
                width={"100%"}
                justifyContent={"center"}
                // marginTop={'2%'}
              >
                <Button
                  onClick={handleSubmitCombined}
                  borderRadius={"8px"}
                  textTransform="uppercase"
                  color={"white"}
                  fontSize={"1.6rem"}
                  bg="#2a2e6e"
                  _hover={{
                    color: "white",
                    bg: "#f59f2c",
                  }}
                  p="3%"
                >
                  Guardar
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* ------------------------------ Editar Solicitud combinada----------------------------- */}
      <Modal
        isOpen={isOpen2}
        onClose={() => {
          clearCombinada();
          onClose2();
        }}
        alignItems="center"
        display="flex"
        isCentered
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "32%", xl: "36%", lg: "45%", md: "60%" }}
          p={"1% 2% 1% 2%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Editar Solicitud de combinada
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex direction={"column"} gap="40px" w={"100%"}>
              <Flex w={"100%"} gap={"5%"}>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Casa:
                  </FormLabel>
                  <Select
                    onChange={handleChangeCombineds}
                    value={dataCombinada.casa}
                    placeholder="Seleccione Casa"
                    name="casa"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    {listCasasMantenimiento.map((itemCasa) => (
                      <option key={itemCasa.casaF_id} value={itemCasa.nombre}>
                        {itemCasa.nombre.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Moneda:
                  </FormLabel>
                  <Select
                    onChange={handleChangeCombineds}
                    value={dataCombinada.moneda}
                    placeholder="Seleccione Moneda"
                    name="moneda"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    {ListMonedas.map((itemMoneda) => (
                      <option key={itemMoneda.pais} value={itemMoneda.nom}>
                        {itemMoneda.nom}-{itemMoneda.moneda}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>

              <Flex w={"100%"} gap={"5%"}>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Cuota:
                  </FormLabel>
                  <Input
                    onChange={handleChangeCombineds}
                    value={dataCombinada.cuota}
                    name="cuota"
                    fontSize={"1.8rem"}
                    h="35"
                    type="number"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Stake:
                  </FormLabel>
                  <Input
                    onChange={handleChangeCombineds}
                    value={dataCombinada.stake}
                    name="stake"
                    fontSize={"1.8rem"}
                    h="35"
                    type="number"
                  />
                </FormControl>
              </Flex>
              <Flex w={"100%"} gap={"5%"}>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Apoyo:
                  </FormLabel>
                  <Select
                    onChange={handleChangeCombineds}
                    value={dataCombinada.id_apoyo}
                    placeholder="Elegir Apoyo"
                    name="id_apoyo"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    {listSportrader &&
                      listSportrader.map((itemSportrader) => (
                        <option value={itemSportrader.id_persona}>
                          {itemSportrader.apellidos} {itemSportrader.nombres}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Flex>
              <Flex w={"100%"} gap={"5%"}>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Nro.Ticket:
                  </FormLabel>
                  <Input
                    onChange={handleChangeCombineds}
                    value={dataCombinada.num_ticket}
                    name="num_ticket"
                    fontSize={"1.8rem"}
                    h="35"
                    type="text"
                  />
                </FormControl>
              </Flex>

              <Flex
                width={"100%"}
                justifyContent={"center"}
                // marginTop={'2%'}
              >
                <Button
                  onClick={handleUpdateCombined}
                  borderRadius={"8px"}
                  textTransform="uppercase"
                  color={"white"}
                  fontSize={"1.6rem"}
                  bg="#2a2e6e"
                  _hover={{
                    color: "white",
                    bg: "#f59f2c",
                  }}
                  p="3%"
                >
                  Guardar
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* ------------------------------ Editar Apoyo combinada----------------------------- */}
      <Modal
        isOpen={isOpen4}
        onClose={onClose4}
        alignItems="center"
        display="flex"
        isCentered
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "32%", xl: "36%", lg: "45%", md: "60%" }}
          p={"1% 2% 1% 2%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Editar Apoyo de combinada
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex direction={"column"} gap="40px" w={"100%"}>
              <Flex w={"100%"} gap={"5%"}>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Reembolso:
                  </FormLabel>
                  <Input
                    onChange={handleChangeStatusCombined}
                    value={dataUpdateStatus.reembolso}
                    name="reembolso"
                    fontSize={"1.8rem"}
                    h="35"
                    type="number"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Estado:
                  </FormLabel>
                  <Select
                    onChange={handleChangeStatusCombined}
                    value={dataUpdateStatus.status}
                    name="status"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    <option value="" disabled selected>
                      Elecci&oacute;n de estado
                    </option>
                    <option value="GANADA">Ganada</option>
                    <option value="PERDIDA">Perdida</option>
                    <option value="ANULADA">Anulada</option>
                  </Select>
                </FormControl>
              </Flex>

              <Flex
                width={"100%"}
                justifyContent={"center"}
                // marginTop={'2%'}
              >
                <Button
                  onClick={handleUpdateStatusCombined}
                  borderRadius={"8px"}
                  textTransform="uppercase"
                  color={"white"}
                  fontSize={"1.6rem"}
                  bg="#2a2e6e"
                  _hover={{
                    color: "white",
                    bg: "#f59f2c",
                  }}
                  p="3%"
                >
                  Guardar
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default MCasas;
