import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Text,
  Image,
  IconButton,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  useDisclosure,
  useColorMode,
  Collapse,
} from "@chakra-ui/react";

import {
  BsCreditCard2BackFill,
  BsBank,
  BsFillHouseFill,
  BsCashStack,
} from "react-icons/bs";
import { BsWrench } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  MdKeyboardArrowRight,
  MdOutlineSportsSoccer,
  MdOutlineStreetview,
  MdOutlineCurrencyExchange,
} from "react-icons/md";
import { FiMenu, FiSearch } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import { FaChessRook } from "react-icons/fa";
import { GoGraph, GoSignOut } from "react-icons/go";
import { FaClock } from "react-icons/fa";
import { RiComputerFill, RiAdminFill } from "react-icons/ri";
import { FaBook } from "react-icons/fa";

export default function Sidebar({ isOpen9, onOpen9, onClose9 }) {
  // const [soporte, setSoporte] = useState(true);
  const history = useHistory();
  const token = localStorage.getItem("tokenadmin");
  const { rol, sala } = jwt_decode(token);

  const { colorMode, toggleColorMode } = useColorMode();

  const Mantenimiento = useDisclosure();
  const Modulos = useDisclosure();
  const MovimientoCasas = useDisclosure();
  const MovimientoBanco = useDisclosure();
  const Diarios = useDisclosure();
  const cerrarsesion = () => {
    localStorage.removeItem("tokenadmin");
    history.push("/");
  };
  const NavItem = (props) => {
    const { icon, children, ...rest } = props;
    return (
      <Flex
        align="center"
        px="4"
        mx="2"
        rounded="md"
        py="3"
        cursor="pointer"
        color="whiteAlpha.700"
        _hover={{
          bg: "blackAlpha.300",
          color: "whiteAlpha.900",
        }}
        role="group"
        fontWeight="semibold"
        transition=".15s ease"
        {...rest}
      >
        {icon && (
          <Icon
            mr="2"
            boxSize="6"
            _groupHover={{
              color: "gray",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    );
  };

  // const cerrarsesion = () => {
  //   localStorage.clear();
  //   history.push('/');
  // };
  // const permisos = () => {
  //   if (correo == 'soporte@tmoney.com') {
  //     setSoporte(false);
  //   }
  // };

  // useEffect(() => {
  //   permisos();
  // });

  const SidebarContent = (props) => (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg="#292F36"
      boxShadow={"rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px"}
      borderColor="blackAlpha.300"
      borderRightWidth="1px"
      w="96"
      {...props}
    >
      <Flex px="4" py="12" ml="8" align="center" justifyContent={"left"}>
        {/* <Image
          w={"15%"}
          p={"3% 4%"}
          borderRadius={6}
          _dark={{
            bg: "#f59f2c",
          }}
          bg={"#13235b"}
          src={
            "https://res.cloudinary.com/ddsfttqtk/image/upload/v1668095336/T-Money/logo_icon_pjmouz.png"
          }
        /> */}
        <Text
          textTransform={"uppercase"}
          fontSize="2.2rem"
          ml="4"
          color={"#FFFFFF"}
          fontWeight="bold"
        >
          Salas - op
        </Text>
      </Flex>
      <Text
        textTransform={"uppercase"}
        textAlign="center"
        fontSize="1.3rem"
        color={"#FFFFFF"}
        pb="5"
        fontWeight="bold"
      >
        {rol[0] != "SPORTRADER" && rol[0]}
      </Text>
      <Flex
        w={"100%"}
        direction="column"
        gap={"10px"}
        as="nav"
        sx={{
          "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
            {
              fontSize: "1.6rem",
            },
        }}
        fontSize="1.7rem"
        color="black"
        textColor={"#707070"}
        aria-label="Main Navigation"
      >
        {rol.includes("TMONEY") && (
          <>
            <NavItem
              ml="8"
              onClick={() => {
                history.push("/salas/MSalas");
              }}
              icon={FaChessRook}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"}>SALAS </Text>
            </NavItem>

            <NavItem
              onClick={() => {
                history.push("/salas/Inversionista");
              }}
              ml="8"
              icon={IoIosPeople}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"}>INVERSIONISTA</Text>
            </NavItem>
          </>
        )}

        {rol.includes("INVERSIONISTA") && (
          <NavItem
            onClick={() => {
              history.push("/salas/administracion");
            }}
            ml="8"
            icon={RiAdminFill}
            color={"#FFFFFF"}
          >
            <Text color={"#FFFFFF"}>ADMINISTRADOR</Text>
          </NavItem>
        )}

        {rol.includes("ADMINISTRADOR") && (
          <>
            <NavItem
              onClick={() => {
                history.push("/salas/Mantenimiento");
              }}
              ml="8"
              icon={BsWrench}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"} textTransform="uppercase">
                MANTENEDORES
              </Text>
            </NavItem>

            <NavItem
              onClick={() => {
                history.push("/salas/SportTrader");
              }}
              ml="8"
              icon={MdOutlineSportsSoccer}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"} textTransform="uppercase">
                Sport Trader
              </Text>
            </NavItem>

            <NavItem
              onClick={() => {
                history.push("/salas/Entidad");
              }}
              ml="8"
              icon={MdOutlineStreetview}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"} textTransform="uppercase">
                Entidad
              </Text>
            </NavItem>

            <NavItem
              ml="8"
              icon={BsBank}
              color={"#FFFFFF"}
              onClick={() => {
                history.push("/salas/CuentasBancos");
              }}
            >
              <Text color={"#FFFFFF"}>CUENTAS BANCOS</Text>
            </NavItem>

            <NavItem
              ml="8"
              onClick={() => {
                history.push("/salas/MBanco");
              }}
              icon={BsCreditCard2BackFill}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"} textTransform="uppercase">
                {" "}
                Movimiento Banco{" "}
              </Text>
            </NavItem>
            <NavItem
              ml="8"
              onClick={() => {
                history.push("/salas/RegistroHoras");
              }}
              icon={FaClock}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"} textTransform="uppercase">
                {" "}
                Registro de Horas
              </Text>
            </NavItem>

            <NavItem
              ml="8"
              onClick={() => {
                history.push("/salas/CuentasCasas");
              }}
              icon={BsFillHouseFill}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"}>CUENTAS CASAS</Text>
            </NavItem>

            <NavItem
              ml="8"
              onClick={() => {
                history.push("/salas/TipoCambio");
              }}
              icon={MdOutlineCurrencyExchange}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"} textTransform="uppercase">
                {" "}
                Tipo de Cambio{" "}
              </Text>
            </NavItem>

            <NavItem
              ml="8"
              onClick={() => {
                history.push("/salas/Diarios");
              }}
              icon={RiComputerFill}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"} textTransform="uppercase">
                Reporte de Sala
              </Text>
            </NavItem>

            <NavItem
              ml="8"
              onClick={() => {
                history.push("/salas/MCasas");
              }}
              icon={BsCashStack}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"} textTransform="uppercase">
                Reporte de Jugadas
              </Text>
            </NavItem>

            <NavItem
              ml="8"
              onClick={() => {
                history.push("/salas/Rdiario");
              }}
              icon={BsCashStack}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"} textTransform="uppercase">
                BALANCE DIARIO
              </Text>
            </NavItem>
          </>
        )}
        {/* <NavItem
          ml="8"
          onClick={() => {
            history.push("/salas/Modulos");
          }}
          icon={RiComputerFill}
          color={"#FFFFFF"}
        >
          <Text color={"#FFFFFF"}>MODULOS </Text>
        </NavItem> */}

        {rol.includes("JEFE DE SALA") && (
          <>
            <NavItem
              ml="8"
              onClick={() => {
                history.push("/salas/RegistroHoras");
              }}
              icon={FaClock}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"} textTransform="uppercase">
                {" "}
                Registro de Horas
              </Text>
            </NavItem>

            <NavItem
              ml="8"
              onClick={() => {
                history.push("/salas/CuentasCasas");
              }}
              icon={BsFillHouseFill}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"}>CUENTAS CASAS</Text>
            </NavItem>

            <NavItem
              ml="8"
              onClick={() => {
                history.push("/salas/TipoCambio");
              }}
              icon={MdOutlineCurrencyExchange}
              color={"#FFFFFF"}
            >
              <Text color={"#FFFFFF"} textTransform="uppercase">
                {" "}
                Tipo de Cambio{" "}
              </Text>
            </NavItem>
          </>
        )}

        {(rol.includes("JEFE DE SALA") || rol.includes("SPORTRADER")) && (
          <NavItem
            ml="8"
            onClick={() => {
              history.push("/salas/Diarios");
            }}
            icon={RiComputerFill}
            color={"#FFFFFF"}
          >
            <Text color={"#FFFFFF"} textTransform="uppercase">
              {rol.includes("JEFE DE SALA") ? "Reporte de Sala" : "Caja Diaria"}
            </Text>
          </NavItem>
        )}

        {(rol.includes("JEFE DE SALA") || rol.includes("SPORTRADER")) && (
          <NavItem
            ml="8"
            onClick={() => {
              history.push("/salas/MCasas");
            }}
            icon={BsCashStack}
            color={"#FFFFFF"}
          >
            <Text color={"#FFFFFF"} textTransform="uppercase">
              {rol.includes("JEFE DE SALA")
                ? "Reporte de Jugadas"
                : "Jugadas Diarias"}
            </Text>
          </NavItem>
        )}

        {rol.includes("JEFE DE SALA") && (
          <NavItem
            ml="8"
            onClick={() => {
              history.push("/salas/Rdiario");
            }}
            icon={BsCashStack}
            color={"#FFFFFF"}
          >
            <Text color={"#FFFFFF"} textTransform="uppercase">
              BALANCE DIARIO
            </Text>
          </NavItem>
        )}

        <Flex
          sx={{
            "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
              {
                w: "100%",
                position: "initial",
              },
          }}
          width={{
            "2xl": "12.4%",
            xl: "16.5%",
            lg: "100%",
            md: "100%",
          }}
          position={"fixed"}
          bottom={"0"}
          marginBottom={"2%"}
          as="nav"
          fontSize="1.8rem"
          color="black"
          textColor={"#707070"}
          aria-label="Main Navigation"
        >
          <NavItem
            width={"100%"}
            onClick={() => cerrarsesion()}
            ml="8"
            icon={GoSignOut}
            color={"#FFFF"}
          >
            <Text color={"#FFFF"}>Cerrar sesión</Text>
          </NavItem>
        </Flex>
      </Flex>
    </Box>
  );

  return <SidebarContent />;
}
