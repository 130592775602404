import React, { Component, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  InputGroup,
  Center,
  // InputLeftAddon,
  // Avatar,
  // HStack,
  // Link,
  IconButton,
  Button,
  // Menu,
  // MenuButton,
  // MenuList,
  // MenuItem,
  // Container,
  // Img,
  // MenuDivider,
  useDisclosure,
  // useColorModeValue,
  Stack,
  // MenuGroup,
  // Popover,
  Divider,
  // PopoverTrigger,
  // PopoverContent,
  // PopoverArrow,
  // NavItem,
  // Collapse,
  Text,
  // Badge,
  Icon,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  Tooltip,
  Input,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  // Tfoot,
  Tr,
  Th,
  Td,
  // TableCaption,
  TableContainer,
  FormControl,
  FormLabel,
  // FormErrorMessage,
  Select,
  // FormHelperText,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  // Checkbox,
  // CheckboxGroup,
} from "@chakra-ui/react";

import { Search2Icon } from "@chakra-ui/icons";

// import { EditIcon } from '@chakra-ui/icons';
// import { FaEdit } from 'react-icons/fa';

import { useHistory } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { RiEditFill } from "react-icons/ri";
import { FiMenu } from "react-icons/fi";
import { MdDoDisturbOff } from "react-icons/md";
import SidebarOperators from "../../../components/SidebarOperators";
import { host3 } from "../../../utils/constantes";
import axios from "axios";
import {
  IoMdArrowDroprightCircle,
  IoMdArrowDropleftCircle,
} from "react-icons/io";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { parseString } from "rrule/dist/esm/parsestring";
import Colaboradores from "../Colaboradores/Colaboradores";
import jwt_decode from "jwt-decode";
function CuentasBancos() {
  let history = useHistory();
  const token = localStorage.getItem("tokenadmin");
  const { id_persona } = jwt_decode(token);

  if (!token) {
    history.push("/");
  }

  useEffect(() => {
    const { rol } = jwt_decode(token);
    if (token) {
      if (rol.includes("TMONEY")) {
        history.push("/salas/MSalas");
      }
      if (rol.includes("INVERSIONISTA")) {
        history.push("/salas/administracion");
      }
      if (rol.includes("JEFE DE SALA")) {
        history.push("/salas/CuentasCasas");
      }
    }
  }, []);

  const [search, setSearch] = useState("");
  const [tiempo, setTiempo] = useState(false);

  const [isbilletera, setIsbilletera] = useState(false);
  const [isprincipal, setIsprincipal] = useState(false);
  const [isretenido, setIsretenido] = useState(false);

  //Mensaje
  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        break;
    }
  };

  const buscar = (e) => {
    setSearch(e.target.value);
  };

  //---------------------Tarjetas bancarias -----------------------------------

  const [listTarjetas, setListTarjetas] = useState([]);

  const fetchTarjetasBanco = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/tarjetas-bancarias/${token}`
      );
      setListTarjetas(data);
    } catch (error) {
      setListTarjetas([]);
    }
  };

  const ListFiltered = () => {
    if (search != "") {
      let dataFilter = listTarjetas.filter(
        (item) =>
          item.sala.nombre.includes(search.toUpperCase()) ||
          item.correo_afiliado.includes(search) ||
          item.persona.nombres.includes(search.toUpperCase()) ||
          item.persona.apellidos.includes(search.toUpperCase()) ||
          item.banco.nombre.includes(search.toUpperCase()) ||
          item.moneda.nombre.includes(search.toUpperCase()) ||
          item.numero_tarjeta.includes(search)
      );
      return dataFilter.slice(currentPage, currentPage + 13);
    }
    return listTarjetas?.slice(currentPage, currentPage + 13);
  };

  //--------------------AÑADIR TARJETAS BANCARIAS -----------------------

  const [dataTarjeta, setDataTarjeta] = useState({
    id_sala: "",
    id_titular: "",
    id_banco: "",
    numero_tarjeta: "",
    fecha_vencimiento: "",
    cvv: "",
    clave_cajero: "",
    clave_web: "",
    telefono_afiliado: "",
    id_operador_phone: "",
    correo_afiliado: "",
    clave_correo: "",
    id_moneda: "",
  });

  const handleChangeTarjeta = (e) => {
    let inputValue = e.target.value;
    if (e.target.name === "numero_tarjeta" && !/^\d{0,16}$/.test(inputValue)) {
      return;
    }
    if (e.target.name === "cvv" && !/^\d{0,3}$/.test(inputValue)) {
      return;
    }
    setDataTarjeta({
      ...dataTarjeta,
      [e.target.name]: e.target.value,
    });
  };

  const clearStateTarjeta = () => {
    setDataTarjeta({
      id_sala: "",
      id_titular: "",
      id_banco: "",
      numero_tarjeta: "",
      fecha_vencimiento: "",
      cvv: "",
      clave_cajero: "",
      clave_web: "",
      telefono_afiliado: "",
      id_operador_phone: "",
      correo_afiliado: "",
      clave_correo: "",
      id_moneda: "",
    });
  };

  const handleSubmitTarjetas = async () => {
    try {
      const { status, data } = await axios.post(
        `${host3}/api/tarjetas-bancarias/${token}`,
        dataTarjeta
      );

      if (status == 201) {
        mensaje(data.message, "top-right", 1);
        onCloseTarjetasBank();
        clearStateTarjeta();
        fetchTarjetasBanco();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  // Actualizar datos de tarjeta
  const handleSubmitTarjetasUpdate = async () => {
    try {
      const { status, data } = await axios.put(
        `${host3}/api/tarjetas-bancarias/${token}/${dataTarjeta.id_colaborador_banco}`,
        dataTarjeta
      );

      if (status == 201) {
        mensaje(data.message, "top-right", 1);
        onCloseEditarBank();
        clearStateTarjeta();
        fetchTarjetasBanco();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  // Accion de la tarjeta | Dar de baja | Habilitar
  const handleSubmitChangeStatusTarjeta = async (id, action) => {
    try {
      const { status, data } = await axios.put(
        `${host3}/api/tarjetas-bancarias/${token}/${id}/unsubscribe/${action}`
      );
      if (status == 201) {
        mensaje(data.message, "top-right", 1);
        fetchTarjetasBanco();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  //--------------------AÑADIR CUENTAS BANCARIAS -----------------------

  const [listCuentas, setListCuentas] = useState([]);

  const fetchCuentaBanco = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/cuentas_bancarias/${token}`
      );
      setListCuentas(data);
    } catch (error) {
      setListCuentas([]);
    }
  };

  const ListFiltered1 = () => {
    if (search != "") {
      let dataFilter = listCuentas.filter(
        (item) =>
          item.sala.nombre.includes(search.toUpperCase()) ||
          item.denominacion.includes(search.toUpperCase()) ||
          item.correo.includes(search) ||
          item.persona.nombres.includes(search.toUpperCase()) ||
          item.persona.apellidos.includes(search.toUpperCase()) ||
          item.banco.nombre.includes(search.toUpperCase()) ||
          item.moneda.nombre.includes(search.toUpperCase())
      );
      return dataFilter.slice(currentPage1, currentPage1 + 13);
    }
    return listCuentas?.slice(currentPage1, currentPage1 + 13);
  };

  const [dataCuentaBanco, setDataCuentaBanco] = useState({
    id_titular: "",
    id_banco: "",
    id_moneda: "",
    denominacion: "",
    numero_cuenta: "",
    id_sala: "",
    cci: "",
    monto: "",
    pendiente: "",
    correo: "",
    clave_correo: "",
    clave_billetera: "",
    codigos_seguridad: "",
    esbilletera: "",
    id_colaborador_banco: "",
    esprincipal: "",
    retenido: ""
  });

  const handleChangeCuenta = (e) => {
    setDataCuentaBanco({
      ...dataCuentaBanco,
      [e.target.name]: e.target.value,
    });
  };

  const clearStateCuenta = () => {
    setDataCuentaBanco({
      id_titular: "",
      id_banco: "",
      id_moneda: "",
      denominacion: "",
      numero_cuenta: "",
      id_sala: "",
      cci: "",
      monto: "",
      pendiente: "",
      correo: "",
      clave_correo: "",
      clave_billetera: "",
      codigos_seguridad: "",
      esbilletera: "",
      id_colaborador_banco: "",
      esprincipal: "",
    });
  };

  const handleSubmitCuentas = async () => {
    try {
      dataCuentaBanco.esbilletera = isbilletera ? 1 : 0;
      dataCuentaBanco.esprincipal = isprincipal ? 1 : 0;

      const { status, data } = await axios.post(
        `${host3}/api/cuentas_bancarias/${token}`,
        dataCuentaBanco
      );

      if (status == 201) {
        mensaje(data.message, "top-right", 1);
        onClose();
        clearStateCuenta();
        fetchCuentaBanco();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  const handleSubmitCuentasUpdate = async () => {
    try {
      dataCuentaBanco.esbilletera = isbilletera ? 1 : 0;
      dataCuentaBanco.esprincipal = isprincipal ? 1 : 0;
      dataCuentaBanco.retenido = isretenido ? 1 : 0;

      const { status, data } = await axios.put(
        `${host3}/api/cuentas_bancarias/${token}/${dataCuentaBanco.id_cuenta_bancaria}`,
        dataCuentaBanco
      );

      if (status == 201) {
        mensaje(data.message, "top-right", 1);
        onClose2();
        clearStateCuenta();
        fetchCuentaBanco();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  //------------------------ Salas --------------------------------
  const [listSala, setListSala] = useState([]);

  const fetchSalas = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/personas/${token}/list-hall/${id_persona}`
      );
      setListSala(data);
    } catch (error) {
      setListSala([]);
    }
  };

  //-------------------- TITULAR ----------------------------------------

  const [listTitular, setListTitular] = useState([]);

  const fetchEntities = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/salas/${token}/list-entity/${id_persona}`
      );

      let dataTitular = [];

      if (dataTarjeta.id_sala != "") {
        data.map((itemSala) => {
          if (itemSala.sala.id_sala == dataTarjeta.id_sala) {
            itemSala.sala.personas.map((itemTitular) => {
              dataTitular.push({
                id_persona: itemTitular.id_persona,
                fullName: `${itemTitular.apellidos} ${itemTitular.nombres}`,
                estado: itemTitular.estado,
              });
            });
          }
        });
      }

      setListTitular(dataTitular);
    } catch (error) {
      setListTitular([]);
    }
  };

  //---------------------BANCOS-----------------------------

  const [listBanco, setListBanco] = useState([]);

  const fetchBanco = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/bancos/${token}/listar/${id_persona}`
      );

      let dataTitular = [];

      if (dataTarjeta.id_sala != "") {
        data.map((itemSala) => {
          if (itemSala.sala.id_sala == dataTarjeta.id_sala) {
            itemSala.sala.bancos.map((itemTitular) => {
              dataTitular.push({
                id_banco: itemTitular.id_banco,
                nombre: itemTitular.nombre,
                estado: itemTitular.estado,
              });
            });
          }
        });
      }

      setListBanco(dataTitular);
    } catch (error) {
      setListBanco([]);
    }
  };

  //---------------------MONEDAS-----------------------------
  const [listMoneda, setListMoneda] = useState([]);

  const fetchMoneda = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/monedas/${token}/listar/${id_persona}`
      );

      let dataTitular = [];

      if (dataTarjeta.id_sala != "") {
        data.map((itemSala) => {
          if (itemSala.sala.id_sala == dataTarjeta.id_sala) {
            itemSala.sala.monedas.map((itemTitular) => {
              dataTitular.push({
                id_moneda: itemTitular.id_moneda,
                nombre: itemTitular.nombre,
                estado: itemTitular.estado,
              });
            });
          }
        });
      }

      setListMoneda(dataTitular);
    } catch (error) {
      setListMoneda([]);
    }
  };

  //---------------------OPERADORES TELEFONICOS-----------------------------

  const [listOperador, setListOperador] = useState([]);

  const fetchOperadorTelefonico = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/operador-telefonos/${token}/listar/${id_persona}`
      );

      let dataTitular = [];

      if (dataTarjeta.id_sala != "") {
        data.map((itemSala) => {
          if (itemSala.sala.id_sala == dataTarjeta.id_sala) {
            itemSala.sala.operador_telefonos.map((itemTitular) => {
              dataTitular.push({
                id_operador_phone: itemTitular.id_operador_phone,
                nombre: itemTitular.nombre,
                estado: itemTitular.estado,
              });
            });
          }
        });
      }

      setListOperador(dataTitular);
    } catch (error) {
      setListOperador([]);
    }
  };

  useEffect(() => {
    if (dataTarjeta.id_sala != "") {
      fetchEntities();
      fetchBanco();
      fetchMoneda();
      fetchOperadorTelefonico();
    }
  }, [dataTarjeta.id_sala]);

  useEffect(() => {
    fetchSalas();
    fetchCuentaBanco();
    fetchTarjetasBanco();
    setTimeout(() => {
      setTiempo(true);
    }, 8000);
  }, []);

  //Paginacion
  const [currentPage, setCurrentPage] = useState(0);
  const nextPage = () => {
    const cant = listTarjetas.filter((p) =>
      p.id_colaborador_banco.toString()
    ).length;

    if (cant > currentPage) {
      setCurrentPage(currentPage + 13);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 13);
    }
  };

  const [currentPage1, setCurrentPage1] = useState(0);
  const nextPage1 = () => {
    const cant = listCuentas.filter((p) =>
      p.id_cuenta_bancaria.toString()
    ).length;

    if (cant > currentPage1) {
      console.log(currentPage1);
      setCurrentPage1(currentPage1 + 13);
    }
  };

  const prevPage1 = () => {
    if (currentPage1 > 0) {
      setCurrentPage1(currentPage1 - 13);
    }
  };
  // ---------------------------

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();

  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar,
  } = useDisclosure();

  // --------------------Tarjetas Bancos-----------------------

  const {
    isOpen: isOpenTarjetasBank,
    onOpen: onOpenTarjetasBank,
    onClose: onCloseTarjetasBank,
  } = useDisclosure();

  const {
    isOpen: isOpenEditarBank,
    onOpen: onOpenEditarBank,
    onClose: onCloseEditarBank,
  } = useDisclosure();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: "1.2rem", textAlign: "center" }}
        pauseOnHover
      />

      <Box
        scrollBehavior={"smooth"}
        bg={"#e5e5e5"}
        height={"auto"}
        maxHeight={"100vh"}
        overflowY={"auto"}
        css={{
          "&::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#6b6b6b",
            borderRadius: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#9b9b9b",
            borderRadius: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#9b9b9b",
          },
        }}
      >
        <Box
          as="section"
          display={{
            "2xl": "unset",
            xl: "unset",
            lg: "none",
            md: "none",
            sm: "none",
          }}
        >
          <SidebarOperators />
        </Box>
        <Drawer isOpen={isOpenBar} onClose={onCloseBar} placement="left">
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w="full" borderRight="none" />
          </DrawerContent>
        </Drawer>
        <Box bg={"#e5e5e5"} h={"100vh"}>
          <Flex
            alignItems={"center"}
            justify={"flex-start"}
            // h={'100%'}
            sx={{
              "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
                {
                  padding: "1.5% 1.5% 1.5% 19%",
                },
            }}
            p={{
              "2xl": "1.5% 3% 1.5% 15%",
              xl: "1.5% 3% 1.5% 18%",
              lg: "1.5% 2% 1.5% 2%",
              md: "1.5% 2% 1.5% 2%",
            }}
            // marginLeft={'15%'}
            // marginRight='2%'
            // marginBottom='1%'
            direction="column"
            gap={"25px"}
          >
            <Box transition=".3s ease" width={"100%"}>
              <Flex gap="2%" alignItems={"center"} as="header" w="full" h="14">
                <IconButton
                  aria-label="Menu"
                  display={{
                    "2xl": "none",
                    xl: "none",
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                  }}
                  background={"#292F36"}
                  color={"#ffffff"}
                  borderRadius={"4px"}
                  fontSize={"1.3rem"}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size="md"
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink color={"#707070"} fontSize={"1.5rem"}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={"bold"}
                      color={"#292F36"}
                      fontSize={"1.5rem"}
                    >
                      CUENTAS BANCOS
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>
            {/* ------------------------CONTENT------------------------------ */}

            <Tabs isFitted variant="enclosed" w={"100%"}>
              <TabList mb="1em" bg={"#FFFF"} borderRadius="15px" p={"0.5%"}>
                <Tab
                  borderRadius={"15px"}
                  _selected={{
                    color: "white",
                    bg: "#2a2e6e",
                  }}
                  fontSize="2.1rem"
                  textTransform={"uppercase"}
                  fontFamily="monospace"
                  fontWeight={"600"}
                >
                  Tarjetas de Banco
                </Tab>
                <Tab
                  borderRadius={"15px"}
                  _selected={{
                    color: "white",
                    bg: "#2a2e6e",
                  }}
                  fontSize="2.1rem"
                  textTransform={"uppercase"}
                  fontFamily="monospace"
                  fontWeight={"600"}
                >
                  Cuenta Bancaria
                </Tab>
              </TabList>

              <TabPanels>
                {/* ---------------TARJETA BANCO----------------------- */}
                <TabPanel
                  w={"100%"}
                  display={"flex"}
                  flexDirection="column"
                  gap={"10px"}
                  bg={"transparent"}
                  // boxShadow='rgba(0, 0, 0, 0.35) 0px 5px 15px'
                  borderRadius="10px"
                  p={"2% 0%"}
                >
                  <Flex width={"100%"} direction={"column"} gap={"25px"}>
                    <Flex width={"100%"} justifyContent={"space-between"}>
                      <Flex width={"30%"}>
                        <Stack w={"100%"}>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={
                                <Search2Icon
                                  color="#2a2e6e"
                                  marginLeft={"20%"}
                                  fontSize={"1.25rem"}
                                  marginTop={"60%"}
                                  _dark={{
                                    color: "#f59f2c",
                                  }}
                                />
                              }
                            />
                            <Input
                              onChange={buscar}
                              type="text"
                              placeholder="Buscar"
                              bg={"white"}
                              height={"40px"}
                              _focus={{
                                borderColor: "#2a2e6e",
                                boxShadow: "none",
                              }}
                              color="#2a2e6e"
                              fontSize={"1.9rem"}
                              border={"1px solid"}
                              borderColor={"#2a2e6e"}
                              borderRadius="6px"
                              _placeholder={{
                                color: "rgb(152, 152, 152)",
                              }}
                              _dark={{
                                color: "white",
                                borderColor: "#686868",
                                bg: "#686868",
                              }}
                            />
                          </InputGroup>
                        </Stack>
                      </Flex>

                      {/* <Flex width={'auto'} justifyContent={'right'}>
                        <Stack align='center' width={'100%'}>
                          <Button
                            // onClick={onOpen3}
                            w={'100%'}
                            height={'40px'}
                            borderRadius='8px'
                            color={'white'}
                            fontSize={'1.6rem'}
                            bg='#2a2e6e'
                            _hover={{
                              color: 'white',
                              bg: '#f59f2c'
                            }}>
                            RESUMEN DE TABLA
                          </Button>
                        </Stack>
                      </Flex> */}
                    </Flex>

                    <Flex
                      boxShadow={
                        "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"
                      }
                      background={"white"}
                      borderRadius={"18px"}
                      direction={"column"}
                      padding={"2% 2% 2% 2%"}
                    >
                      <Flex width={"100%"} justifyContent={"right"}>
                        <Stack align="center" width={"auto"}>
                          <Button
                            onClick={onOpenTarjetasBank}
                            w={"100%"}
                            height={"40px"}
                            borderRadius="8px"
                            color={"white"}
                            fontSize={"1.6rem"}
                            textTransform={"uppercase"}
                            bg="#2a2e6e"
                            _hover={{
                              color: "white",
                              bg: "#f59f2c",
                            }}
                          >
                            Añadir Tarjeta
                          </Button>
                        </Stack>
                      </Flex>
                      <Flex
                        w={"100%"}
                        h="100%"
                        direction={"column"}
                        justifyContent="center"
                        paddingTop={"2%"}
                      >
                        <TableContainer w={"100%"}>
                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                <Th fontSize={"1.6rem"}>SALA</Th>
                                <Th fontSize={"1.6rem"}>BANCO</Th>
                                <Th fontSize={"1.6rem"}>MONEDA</Th>
                                <Th fontSize={"1.6rem"}>TITULAR</Th>
                                <Th fontSize={"1.6rem"}>NRO. TARJETA</Th>
                                <Th fontSize={"1.6rem"}>FECHA VENCIMIENTO</Th>
                                <Th fontSize={"1.6rem"}>CVV</Th>
                                <Th fontSize={"1.6rem"}>CLAVE CAJERO</Th>
                                <Th fontSize={"1.6rem"}>CLAVE WEB</Th>
                                <Th fontSize={"1.6rem"}>TELEFONO</Th>
                                <Th fontSize={"1.6rem"}>OPERADOR</Th>
                                <Th fontSize={"1.6rem"}>CORREO</Th>
                                <Th fontSize={"1.6rem"}>CLAVE CORREO</Th>
                                <Th fontSize={"1.6rem"}>FECHA INICIO</Th>
                                <Th fontSize={"1.6rem"}>FECHA BAJA</Th>

                                <Th fontSize={"1.6rem"}>ESTADO</Th>
                                <Th isNumeric fontSize={"1.6rem"}>
                                  ACCIONES
                                </Th>
                              </Tr>
                            </Thead>

                            <Tbody fontSize={"1.5rem"}>
                              {ListFiltered().map((itemTarjeta) => (
                                <Tr key={itemTarjeta.id_colaborador_banco}>
                                  <Td>{itemTarjeta.sala.nombre}</Td>
                                  <Td>{itemTarjeta.banco.nombre}</Td>
                                  <Td>{itemTarjeta.moneda.nombre}</Td>
                                  <Td>
                                    {itemTarjeta.persona.apellidos}{" "}
                                    {itemTarjeta.persona.nombres}
                                  </Td>
                                  <Td>{itemTarjeta.numero_tarjeta}</Td>
                                  <Td>{itemTarjeta.fecha_vencimiento}</Td>
                                  <Td>{itemTarjeta.cvv}</Td>
                                  <Td>{itemTarjeta.clave_cajero}</Td>

                                  <Td>{itemTarjeta.clave_web}</Td>
                                  <Td>{itemTarjeta.telefono_afiliado}</Td>
                                  <Td>
                                    {itemTarjeta.operador_telefono.nombre}
                                  </Td>
                                  <Td>{itemTarjeta.correo_afiliado}</Td>

                                  <Td>{itemTarjeta.clave_correo}</Td>
                                  <Td>{itemTarjeta.fecha_registro}</Td>

                                  <Td>{itemTarjeta.fecha_baja}</Td>

                                  <Td>
                                    {itemTarjeta.estado
                                      ? "ACTIVO"
                                      : "NO ACTIVADO"}
                                  </Td>
                                  <Td
                                    fontWeight={"600"}
                                    fontFamily="monospace"
                                    isNumeric
                                  >
                                    <Flex justifyContent={"right"} gap={"4%"}>
                                      <Tooltip
                                        hasArrow
                                        borderRadius={"4px"}
                                        fontSize={"1.2rem"}
                                        label="Editar"
                                        bg="#292F36"
                                      >
                                        <IconButton
                                          onClick={() => {
                                            setDataTarjeta(itemTarjeta);
                                            onOpenEditarBank();
                                          }}
                                          size={"md"}
                                          borderRadius={"4px"}
                                          fontSize={"1.5rem"}
                                          background={"#f59f2c"}
                                          color={"#ffffff"}
                                          aria-label="Editar"
                                          icon={<RiEditFill />}
                                        />
                                      </Tooltip>
                                      {itemTarjeta.estado != 1 ? (
                                        <Tooltip
                                          hasArrow
                                          borderRadius={"4px"}
                                          fontSize={"1.2rem"}
                                          label="Habilitar"
                                          bg="#292F36"
                                        >
                                          <IconButton
                                            onClick={() => {
                                              handleSubmitChangeStatusTarjeta(
                                                itemTarjeta.id_colaborador_banco,
                                                1
                                              );
                                              //setActivo1(false);
                                            }}
                                            size={"md"}
                                            borderRadius={"4px"}
                                            fontSize={"1.5rem"}
                                            background={"#2b752d"}
                                            color={"#ffffff"}
                                            aria-label="Habilitar"
                                            icon={<FaCheckCircle />}
                                          />
                                        </Tooltip>
                                      ) : (
                                        <Tooltip
                                          hasArrow
                                          borderRadius={"4px"}
                                          fontSize={"1.2rem"}
                                          label="Dar de baja"
                                          bg="#292F36"
                                        >
                                          <IconButton
                                            onClick={() => {
                                              handleSubmitChangeStatusTarjeta(
                                                itemTarjeta.id_colaborador_banco,
                                                0
                                              );
                                              //setActivo1(true);
                                            }}
                                            size={"md"}
                                            borderRadius={"4px"}
                                            fontSize={"1.5rem"}
                                            background={"#be0000"}
                                            color={"#ffffff"}
                                            aria-label="Dar de baja"
                                            icon={<MdDoDisturbOff />}
                                          />
                                        </Tooltip>
                                      )}
                                    </Flex>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </TableContainer>
                        <Flex
                          marginTop={"0.5%"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={"0.5%"}
                        >
                          <Icon
                            onClick={() => {
                              prevPage();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDropleftCircle}
                          />
                          <Icon
                            onClick={() => {
                              nextPage();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDroprightCircle}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </TabPanel>

                {/* ---------------CUENTA BANCO----------------------- */}

                <TabPanel
                  w={"100%"}
                  bg={"transparent"}
                  borderRadius="10px"
                  p={"2% 0%"}
                  // boxShadow='rgba(0, 0, 0, 0.35) 0px 5px 15px'
                >
                  <Flex width={"100%"} direction={"column"} gap={"25px"}>
                    <Flex width={"100%"} justifyContent={"space-between"}>
                      <Flex width={"30%"}>
                        <Stack w={"100%"}>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={
                                <Search2Icon
                                  color="#2a2e6e"
                                  marginLeft={"20%"}
                                  fontSize={"1.25rem"}
                                  marginTop={"60%"}
                                  _dark={{
                                    color: "#f59f2c",
                                  }}
                                />
                              }
                            />
                            <Input
                              onChange={buscar}
                              type="text"
                              placeholder="Buscar"
                              bg={"white"}
                              height={"40px"}
                              _focus={{
                                borderColor: "#2a2e6e",
                                boxShadow: "none",
                              }}
                              color="#2a2e6e"
                              fontSize={"1.9rem"}
                              border={"1px solid"}
                              borderColor={"#2a2e6e"}
                              borderRadius="6px"
                              _placeholder={{
                                color: "rgb(152, 152, 152)",
                              }}
                              _dark={{
                                color: "white",
                                borderColor: "#686868",
                                bg: "#686868",
                              }}
                            />
                          </InputGroup>
                        </Stack>
                      </Flex>
                      {/*<Flex width={"auto"} justifyContent={"right"}>
                        <Stack align="center" width={"100%"}>
                          <Button
                            onClick={onOpen3}
                            w={"100%"}
                            height={"40px"}
                            borderRadius="8px"
                            color={"white"}
                            fontSize={"1.6rem"}
                            bg="#2a2e6e"
                            _hover={{
                              color: "white",
                              bg: "#f59f2c",
                            }}
                          >
                            RESUMEN DE TABLA
                          </Button>
                        </Stack>
                      </Flex>*/}
                    </Flex>

                    <Flex
                      boxShadow={
                        "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"
                      }
                      background={"white"}
                      borderRadius={"18px"}
                      direction={"column"}
                      padding={"2%  "}
                    >
                      <Flex width={"100%"} justifyContent={"right"}>
                        <Stack align="center" width={"auto"}>
                          <Button
                            onClick={onOpen}
                            w={"100%"}
                            height={"40px"}
                            borderRadius="8px"
                            color={"white"}
                            fontSize={"1.6rem"}
                            textTransform={"uppercase"}
                            bg="#2a2e6e"
                            _hover={{
                              color: "white",
                              bg: "#f59f2c",
                            }}
                          >
                            Añadir Cuenta Bancaria
                          </Button>
                        </Stack>
                      </Flex>
                      <Flex
                        w={"100%"}
                        h="100%"
                        direction={"column"}
                        justifyContent="center"
                        paddingTop={"2%"}
                      >
                        <TableContainer w={"100%"}>
                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                <Th fontSize={"1.6rem"}>SALA</Th>
                                <Th fontSize={"1.6rem"}>DENOMINACIÓN</Th>
                                <Th fontSize={"1.6rem"}>CUENTA</Th>
                                <Th fontSize={"1.6rem"}>TITULAR</Th>
                                <Th fontSize={"1.6rem"}>BANCO</Th>
                                <Th fontSize={"1.6rem"}>MONEDA</Th>
                                <Th fontSize={"1.6rem"}>MONTO</Th>
                                <Th fontSize={"1.6rem"}>PENDIENTE</Th>
                                <Th fontSize={"1.6rem"}>ES BILLETERA</Th>
                                <Th isNumeric fontSize={"1.6rem"}>
                                  CUENTA MADRE
                                </Th>
                                <Th isNumeric fontSize={"1.6rem"}>
                                  RETENIDO
                                </Th>

                                <Th fontSize={"1.6rem"}>ESTADO</Th>
                                {/* <Th isNumeric fontSize={'1.6rem'}>
                                  ACCIONES
                                </Th> */}
                              </Tr>
                            </Thead>
                            {ListFiltered1()?.map((itemCuenta) => {
                              return (
                                <Tbody fontSize={"1.5rem"}>
                                  <Tr>
                                    <Td>{itemCuenta.sala.nombre}</Td>
                                    <Td>{itemCuenta.denominacion}</Td>
                                    <Td>{itemCuenta.numero_cuenta}</Td>
                                    <Td>
                                      {itemCuenta.persona.apellidos}{" "}
                                      {itemCuenta.persona.nombres}
                                    </Td>
                                    <Td>{itemCuenta.banco.nombre}</Td>
                                    <Td>{itemCuenta.moneda.nombre}</Td>

                                    <Td>{itemCuenta.monto}</Td>
                                    <Td>{itemCuenta.pendiente}</Td>

                                    <Td>
                                      {itemCuenta.esbilletera == 1
                                        ? "SI"
                                        : "NO"}
                                    </Td>
                                    <Td>
                                      {itemCuenta.esprincipal == 1
                                        ? "SI"
                                        : "NO"}
                                    </Td>
                                    <Td>
                                      {itemCuenta.retenido == 1 ? "SI" : "NO"}
                                    </Td>
                                    <Td>
                                      {itemCuenta.estado == 1
                                        ? "Activado"
                                        : "Inactivo"}
                                    </Td>

                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      isNumeric
                                    >
                                      <Flex justifyContent={"right"} gap={"4%"}>
                                        <Tooltip
                                          hasArrow
                                          borderRadius={"4px"}
                                          fontSize={"1.2rem"}
                                          label="Editar"
                                          bg="#292F36"
                                        >
                                          <IconButton
                                            onClick={() => {
                                              setIsprincipal(
                                                itemCuenta.esprincipal
                                              );
                                              setIsbilletera(
                                                itemCuenta.esbilletera
                                              );
                                              setIsretenido(itemCuenta.retenido)
                                              setDataCuentaBanco(itemCuenta);
                                              onOpen2();
                                            }}
                                            size={"md"}
                                            borderRadius={"4px"}
                                            fontSize={"1.5rem"}
                                            background={"#f59f2c"}
                                            color={"#ffffff"}
                                            aria-label="Editar"
                                            icon={<RiEditFill />}
                                          />
                                        </Tooltip>
                                        {/*itemCuenta.estado == !1 ? (
                                          <Tooltip
                                            hasArrow
                                            borderRadius={"4px"}
                                            fontSize={"1.2rem"}
                                            label="Activar"
                                            bg="#292F36"
                                          >
                                            <IconButton
                                              onClick={() => {
                                                activar(p.id_colaborador_banco);
                                                setActivo1(false);
                                              }}
                                              size={"md"}
                                              borderRadius={"4px"}
                                              fontSize={"1.5rem"}
                                              background={"#2b752d"}
                                              color={"#ffffff"}
                                              aria-label="activar"
                                              icon={<FaCheckCircle />}
                                            />
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            hasArrow
                                            borderRadius={"4px"}
                                            fontSize={"1.2rem"}
                                            label="Dar de baja"
                                            bg="#292F36"
                                          >
                                            <IconButton
                                              onClick={() => {
                                                darDeBaja(
                                                  p.id_colaborador_banco
                                                );
                                                setActivo1(true);
                                              }}
                                              size={"md"}
                                              borderRadius={"4px"}
                                              fontSize={"1.5rem"}
                                              background={"#be0000"}
                                              color={"#ffffff"}
                                              aria-label="Dar de baja"
                                              icon={<MdDoDisturbOff />}
                                            />
                                          </Tooltip>
                                        )*/}
                                      </Flex>
                                    </Td>
                                  </Tr>
                                </Tbody>
                              );
                            })}
                          </Table>
                        </TableContainer>
                        <Flex
                          marginTop={"0.5%"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={"0.5%"}
                        >
                          <Icon
                            onClick={() => {
                              prevPage1();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDropleftCircle}
                          />
                          <Icon
                            onClick={() => {
                              nextPage1();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDroprightCircle}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Box>
      </Box>

      {/* ---------------Agregar Tarjetas bancos--------- */}
      <Modal
        isOpen={isOpenTarjetasBank}
        onClose={onCloseTarjetasBank}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "50%", xl: "70%", lg: "88%", md: "98%" }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Añadir Tarjetas Bancos
          </ModalHeader>
          <ModalCloseButton fontSize={"1.2rem"} />
          <ModalBody>
            <Flex width={"100%"} justifyContent={"space-between"}>
              <Flex
                direction={"column"}
                alignItems="center"
                gap="12px"
                w={"46%"}
                h="100%"
              >
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Sala:
                    </FormLabel>
                    <Select
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.id_sala}
                      name="id_sala"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" disabled selected>
                        Elige la opción
                      </option>
                      {listSala &&
                        listSala.map((itemSala) => (
                          <option
                            key={itemSala.id_sala}
                            value={`${itemSala.id_sala}`}
                          >
                            {itemSala.sala.nombre}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Flex>

                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Titular:
                  </FormLabel>
                  <Select
                    onChange={handleChangeTarjeta}
                    value={dataTarjeta.id_titular}
                    name="id_titular"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    <option value="" disabled selected>
                      Elige la opción
                    </option>
                    {listTitular &&
                      listTitular.map((itemTitular) => (
                        <option
                          value={itemTitular.id_persona}
                          key={itemTitular.id_persona}
                        >
                          {itemTitular.fullName}
                        </option>
                      ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Banco:
                  </FormLabel>
                  <Select
                    onChange={handleChangeTarjeta}
                    value={dataTarjeta.id_banco}
                    name="id_banco"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    <option value="" disabled selected>
                      Elige la opción
                    </option>
                    {listBanco &&
                      listBanco.map((itemBanco) => (
                        <option
                          key={itemBanco.id_banco}
                          value={`${itemBanco.id_banco}`}
                        >
                          {itemBanco.nombre}
                        </option>
                      ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Moneda:
                  </FormLabel>
                  <Select
                    onChange={handleChangeTarjeta}
                    value={dataTarjeta.id_moneda}
                    name="id_moneda"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    <option value="" disabled selected>
                      Elige la opción
                    </option>
                    {listMoneda &&
                      listMoneda.map((itemMoneda) => (
                        <option
                          key={itemMoneda.id_moneda}
                          value={`${itemMoneda.id_moneda}`}
                        >
                          {itemMoneda.nombre}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Flex>
              <Center height="405px">
                <Divider orientation="vertical" />
              </Center>
              <Flex
                direction={"column"}
                alignItems="center"
                gap="12px"
                w={"46%"}
                h="100%"
              >
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Número de tarjeta:
                  </FormLabel>
                  <Input
                    onChange={handleChangeTarjeta}
                    value={dataTarjeta.numero_tarjeta}
                    name="numero_tarjeta"
                    fontSize={"1.8rem"}
                    h="35"
                    type="number"
                  />
                </FormControl>
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      CVV:
                    </FormLabel>
                    <Input
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.cvv}
                      name="cvv"
                      fontSize={"1.8rem"}
                      h="35"
                      type="number"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Fecha de vencimiento:
                    </FormLabel>
                    <Input
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.fecha_vencimiento}
                      name="fecha_vencimiento"
                      fontSize={"1.8rem"}
                      h="35"
                      //type='date'
                      placeholder="MM/AA"
                    />
                  </FormControl>
                </Flex>
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Clave Cajero:
                    </FormLabel>
                    <Input
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.clave_cajero}
                      name="clave_cajero"
                      fontSize={"1.8rem"}
                      h="35"
                      type="number"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Clave Web:
                    </FormLabel>
                    <Input
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.clave_web}
                      name="clave_web"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>
                </Flex>
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Teléfono Afiliado:
                    </FormLabel>
                    <Input
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.telefono_afiliado}
                      name="telefono_afiliado"
                      fontSize={"1.8rem"}
                      h="35"
                      type="number"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Operador del teléfono:
                    </FormLabel>
                    <Select
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.id_operador_phone}
                      name="id_operador_phone"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" disabled selected>
                        Elige la opción
                      </option>
                      {listOperador &&
                        listOperador.map((itemOperador) => (
                          <option
                            key={itemOperador.id_operador_phone}
                            value={`${itemOperador.id_operador_phone}`}
                          >
                            {itemOperador.nombre}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Flex>
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Correo Afiliado:
                    </FormLabel>
                    <Input
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.correo_afiliado}
                      name="correo_afiliado"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Clave del correo:
                    </FormLabel>
                    <Input
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.clave_correo}
                      name="clave_correo"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"center"}>
              <Button
                onClick={handleSubmitTarjetas}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                bg="#2a2e6e"
                _hover={{
                  color: "white",
                  bg: "#f59f2c",
                }}
                p={{
                  "2xl": "2% 4%",
                  xl: "2% 4%",
                  lg: "2% 4%",
                  md: "2% 4%",
                }}
              >
                Agregar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* --------------------Editar----------------- */}
      <Modal
        isOpen={isOpenEditarBank}
        onClose={() => {
          clearStateTarjeta();
          onCloseEditarBank();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "55%", xl: "70%", lg: "90%", md: "95%" }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Editar Tarjeta
          </ModalHeader>
          <ModalCloseButton fontSize={"1.2rem"} />
          <ModalBody>
            <Flex width={"100%"} justifyContent={"space-between"}>
              <Flex
                direction={"column"}
                alignItems="center"
                gap="12px"
                w={"46%"}
                h="100%"
              >
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Sala:
                    </FormLabel>
                    <Select
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.id_sala}
                      name="id_sala"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" disabled>
                        Elige la opción
                      </option>
                      {listSala &&
                        listSala.map((itemSala) => (
                          <option
                            key={itemSala.id_sala}
                            value={itemSala.id_sala}
                          >
                            {itemSala.sala.nombre}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Flex>

                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Titular:
                  </FormLabel>
                  <Select
                    onChange={handleChangeTarjeta}
                    value={dataTarjeta.id_titular}
                    name="id_titular"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    <option value="" disabled selected>
                      Elige la opción
                    </option>
                    {listTitular &&
                      listTitular.map((itemTitular) => (
                        <option
                          value={itemTitular.id_persona}
                          key={itemTitular.id_persona}
                        >
                          {itemTitular.fullName}
                        </option>
                      ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Banco:
                  </FormLabel>
                  <Select
                    onChange={handleChangeTarjeta}
                    value={dataTarjeta.id_banco}
                    name="id_banco"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    <option value="" disabled selected>
                      Elige la opción
                    </option>
                    {listBanco &&
                      listBanco.map((itemBanco) => (
                        <option
                          key={itemBanco.id_banco}
                          value={`${itemBanco.id_banco}`}
                        >
                          {itemBanco.nombre}
                        </option>
                      ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Moneda:
                  </FormLabel>
                  <Select
                    onChange={handleChangeTarjeta}
                    value={dataTarjeta.id_moneda}
                    name="id_moneda"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    <option value="" disabled selected>
                      Elige la opción
                    </option>
                    {listMoneda &&
                      listMoneda.map((itemMoneda) => (
                        <option
                          key={itemMoneda.id_moneda}
                          value={`${itemMoneda.id_moneda}`}
                        >
                          {itemMoneda.nombre}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Flex>
              <Center height="405px">
                <Divider orientation="vertical" />
              </Center>
              <Flex
                direction={"column"}
                alignItems="center"
                gap="12px"
                w={"46%"}
                h="100%"
              >
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Número de tarjeta:
                  </FormLabel>
                  <Input
                    onChange={handleChangeTarjeta}
                    value={dataTarjeta.numero_tarjeta}
                    name="numero_tarjeta"
                    fontSize={"1.8rem"}
                    h="35"
                    type="number"
                  />
                </FormControl>
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      CVV:
                    </FormLabel>
                    <Input
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.cvv}
                      name="cvv"
                      fontSize={"1.8rem"}
                      h="35"
                      type="number"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Fecha de vencimiento:
                    </FormLabel>
                    <Input
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.fecha_vencimiento}
                      name="fecha_vencimiento"
                      fontSize={"1.8rem"}
                      h="35"
                      type="date"
                      placeholder="MM/AA"
                    />
                  </FormControl>
                </Flex>
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Clave Cajero:
                    </FormLabel>
                    <Input
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.clave_cajero}
                      name="clave_cajero"
                      fontSize={"1.8rem"}
                      h="35"
                      type="number"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Clave Web:
                    </FormLabel>
                    <Input
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.clave_web}
                      name="clave_web"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>
                </Flex>
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Teléfono Afiliado:
                    </FormLabel>
                    <Input
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.telefono_afiliado}
                      name="telefono_afiliado"
                      fontSize={"1.8rem"}
                      h="35"
                      type="number"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Operador del teléfono:
                    </FormLabel>
                    <Select
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.id_operador_phone}
                      name="id_operador_phone"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" disabled selected>
                        Elige la opción
                      </option>
                      {listOperador &&
                        listOperador.map((itemOperador) => (
                          <option
                            key={itemOperador.id_operador_phone}
                            value={`${itemOperador.id_operador_phone}`}
                          >
                            {itemOperador.nombre}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Flex>
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Correo Afiliado:
                    </FormLabel>
                    <Input
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.correo_afiliado}
                      name="correo_afiliado"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Clave del correo:
                    </FormLabel>
                    <Input
                      onChange={handleChangeTarjeta}
                      value={dataTarjeta.clave_correo}
                      name="clave_correo"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"center"} marginTop={"2%"}>
              <Button
                onClick={handleSubmitTarjetasUpdate}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                bg="#2a2e6e"
                _hover={{
                  color: "white",
                  bg: "#f59f2c",
                }}
                p="2%"
              >
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* --------------------------------------------------------------------------------------- */}
      {/* ---------------Agregar Cuentas--------- */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{
            "2xl": isbilletera == true ? "60%" : "30%",
            xl: "70%",
            lg: "88%",
            md: "98%",
          }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Detalles de la Cuenta Bancaria
          </ModalHeader>
          <ModalCloseButton fontSize={"1.2rem"} />
          <ModalBody>
            <Flex w={"100%"} gap={"20px"} direction={"column"}>
              <Flex
                width={"100%"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={"20px"}
              >
                <Flex
                  direction={"column"}
                  alignItems="center"
                  gap="12px"
                  w={"100%"}
                  h="100%"
                >
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Sala:
                    </FormLabel>
                    <Select
                      onChange={handleChangeCuenta}
                      value={dataCuentaBanco.id_sala}
                      name="id_sala"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" disabled selected>
                        Elige la opción
                      </option>
                      {listSala &&
                        listSala.map((itemSala) => (
                          <option
                            key={itemSala.id_sala}
                            value={itemSala.id_sala}
                          >
                            {itemSala.sala.nombre}
                          </option>
                        ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Denominacion:
                    </FormLabel>
                    <Input
                      onChange={handleChangeCuenta}
                      value={dataCuentaBanco.denominacion}
                      name="denominacion"
                      placeholder="OPCIONAL"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>

                  {/*<Flex w={"100%"} gap={"5%"}>
                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        Banco:
                      </FormLabel>
                      <Select
                        onChange={handleChangeCuenta}
                        value={dataCuentaBanco.id_banco}
                        name="id_banco"
                        fontSize={"1.8rem"}
                        h="35"
                      >
                        <option value="" disabled selected>
                          Elige la opción
                        </option>
                        {listBanco &&
                          listBanco.map((lr) => {
                            return (
                              <option value={`${lr.banco_id}`}>
                                {lr.nombre}
                              </option>
                            );
                          })}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        Moneda:
                      </FormLabel>
                      <Select
                        onChange={handleChangeCuenta}
                        value={dataCuentaBanco.id_moneda}
                        name="id_moneda"
                        fontSize={"1.8rem"}
                        h="35"
                      >
                        <option value="" disabled selected>
                          Elige la opción
                        </option>
                        {listMoneda &&
                          listMoneda.map((lr) => {
                            return (
                              <option value={`${lr.moneda_id}`}>
                                {lr.nombre}
                              </option>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Flex>*/}

                  <Flex w={"100%"} gap={"5%"}>
                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        Número de cuenta:
                      </FormLabel>
                      <Input
                        onChange={handleChangeCuenta}
                        value={dataCuentaBanco.numero_cuenta}
                        name="numero_cuenta"
                        fontSize={"1.8rem"}
                        h="35"
                        type="number"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        CCI:
                      </FormLabel>
                      <Input
                        onChange={handleChangeCuenta}
                        value={dataCuentaBanco.cci}
                        name="cci"
                        fontSize={"1.8rem"}
                        h="35"
                        type="number"
                      />
                    </FormControl>
                  </Flex>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Tarjeta Banco:
                    </FormLabel>
                    <Select
                      onChange={handleChangeCuenta}
                      value={dataCuentaBanco.id_colaborador_banco}
                      name="id_colaborador_banco"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" disabled selected>
                        Elige la opción
                      </option>
                      {listTarjetas &&
                        listTarjetas.map((itemTarjeta) => {
                          if (
                            itemTarjeta.sala.id_sala ==
                              dataCuentaBanco.id_sala &&
                            itemTarjeta.fecha_baja == null
                          ) {
                            return (
                              <option
                                key={itemTarjeta.id_colaborador_banco}
                                value={itemTarjeta.id_colaborador_banco}
                              >
                                {itemTarjeta.banco.nombre}-
                                {itemTarjeta.persona.nombres}{" "}
                                {itemTarjeta.persona.apellidos}-
                                {itemTarjeta.moneda.nombre}
                              </option>
                            );
                          }
                        })}
                    </Select>
                  </FormControl>

                  <Flex
                    w={"100%"}
                    gap={"5%"}
                    alignItems={"center"}
                    justify={"space-evenly"}
                  >
                    <Stack
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      fontSize={"1.5rem"}
                    >
                      <IconButton
                        onClick={() => {
                          setIsprincipal(!isprincipal);
                        }}
                        _hover={"none"}
                        _active={"none"}
                        fontSize={"1.7rem"}
                        background={"transparent"}
                        color={"blue"}
                        aria-label="check"
                        icon={
                          isprincipal == true ? (
                            <ImCheckboxChecked />
                          ) : (
                            <ImCheckboxUnchecked />
                          )
                        }
                      />

                      <Text>Cuenta Madre</Text>
                    </Stack>

                    <Stack
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      fontSize={"1.5rem"}
                    >
                      <IconButton
                        onClick={() => {
                          setIsbilletera(!isbilletera);
                        }}
                        _hover={"none"}
                        _active={"none"}
                        fontSize={"1.7rem"}
                        background={"transparent"}
                        color={"blue"}
                        aria-label="check"
                        icon={
                          isbilletera == true ? (
                            <ImCheckboxChecked />
                          ) : (
                            <ImCheckboxUnchecked />
                          )
                        }
                      />

                      <Text>Billetera</Text>
                    </Stack>
                  </Flex>
                </Flex>

                {isbilletera == true ? (
                  <Center height="335px">
                    <Divider orientation="vertical" />
                  </Center>
                ) : (
                  ""
                )}

                {isbilletera == true ? (
                  <Flex
                    direction={"column"}
                    alignItems="center"
                    gap="12px"
                    w={"46%"}
                    h="100%"
                  >
                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        Email:
                      </FormLabel>
                      <Input
                        onChange={handleChangeCuenta}
                        value={dataCuentaBanco.correo}
                        name="correo"
                        fontSize={"1.8rem"}
                        h="35"
                        type="text"
                      />
                    </FormControl>

                    <Flex w={"100%"} gap={"5%"}>
                      <FormControl>
                        <FormLabel fontSize={"1.85rem"} fontWeight="500">
                          Clave Email:
                        </FormLabel>
                        <Input
                          onChange={handleChangeCuenta}
                          value={dataCuentaBanco.clave_correo}
                          name="clave_correo"
                          fontSize={"1.8rem"}
                          h="35"
                          type="text"
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel fontSize={"1.85rem"} fontWeight="500">
                          Clave Billetera:
                        </FormLabel>
                        <Input
                          onChange={handleChangeCuenta}
                          value={dataCuentaBanco.clave_billetera}
                          name="clave_billetera"
                          fontSize={"1.8rem"}
                          h="35"
                          type="text"
                        />
                      </FormControl>
                    </Flex>

                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        Codigo Seguridad:
                      </FormLabel>
                      <Input
                        onChange={handleChangeCuenta}
                        value={dataCuentaBanco.codigos_seguridad}
                        name="codigos_seguridad"
                        fontSize={"1.8rem"}
                        h="35"
                        type="text"
                      />
                    </FormControl>
                  </Flex>
                ) : (
                  ""
                )}
              </Flex>

              <Flex width={"100%"} justifyContent={"center"}>
                <Button
                  onClick={handleSubmitCuentas}
                  borderRadius={"8px"}
                  textTransform="uppercase"
                  color={"white"}
                  fontSize={"1.6rem"}
                  bg="#2a2e6e"
                  _hover={{
                    color: "white",
                    bg: "#f59f2c",
                  }}
                  p={{
                    "2xl": "2% 4%",
                    xl: "2% 4%",
                    lg: "2% 4%",
                    md: "2% 4%",
                  }}
                >
                  Agregar
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* --------------------Editar----------------- */}
      <Modal
        isOpen={isOpen2}
        onClose={() => {
          clearStateCuenta();
          onClose2();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          maxW={{
            "2xl": isbilletera == true ? "60%" : "30%",
            xl: "70%",
            lg: "88%",
            md: "98%",
          }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Editar Cuenta
          </ModalHeader>
          <ModalCloseButton fontSize={"1.2rem"} />
          <ModalBody>
            <Flex w={"100%"} gap={"20px"} direction={"column"}>
              <Flex
                width={"100%"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={"20px"}
              >
                <Flex
                  direction={"column"}
                  alignItems="center"
                  gap="12px"
                  w={"100%"}
                  h="100%"
                >
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Sala:
                    </FormLabel>
                    <Select
                      onChange={handleChangeCuenta}
                      value={dataCuentaBanco.id_sala}
                      name="id_sala"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      {listSala &&
                        listSala.map((itemSala) => (
                          <option
                            key={itemSala.id_sala}
                            value={`${itemSala.id_sala}`}
                          >
                            {itemSala.sala.nombre}
                          </option>
                        ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Denominacion:
                    </FormLabel>
                    <Input
                      onChange={handleChangeCuenta}
                      value={dataCuentaBanco.denominacion}
                      name="denominacion"
                      placeholder="OPCIONAL"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>

                  <Flex w={"100%"} gap={"5%"}>
                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        Número de cuenta:
                      </FormLabel>
                      <Input
                        onChange={handleChangeCuenta}
                        value={dataCuentaBanco.numero_cuenta}
                        name="numero_cuenta"
                        fontSize={"1.8rem"}
                        h="35"
                        type="number"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        CCI:
                      </FormLabel>
                      <Input
                        onChange={handleChangeCuenta}
                        value={dataCuentaBanco.cci}
                        name="cci"
                        fontSize={"1.8rem"}
                        h="35"
                        type="number"
                      />
                    </FormControl>
                  </Flex>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Tarjeta Banco:
                    </FormLabel>
                    <Select
                      onChange={handleChangeCuenta}
                      value={dataCuentaBanco.id_colaborador_banco}
                      name="id_colaborador_banco"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" disabled selected>
                        Elige la opción
                      </option>
                      {listTarjetas &&
                        listTarjetas.map((itemTarjeta) => {
                          if (
                            itemTarjeta.sala.id_sala ==
                              dataCuentaBanco.id_sala &&
                            itemTarjeta.fecha_baja == null
                          ) {
                            return (
                              <option
                                key={itemTarjeta.id_colaborador_banco}
                                value={itemTarjeta.id_colaborador_banco}
                              >
                                {itemTarjeta.banco.nombre}-
                                {itemTarjeta.persona.apellidos}-
                                {itemTarjeta.moneda.nombre}
                              </option>
                            );
                          }
                        })}
                    </Select>
                  </FormControl>

                  <Flex
                    w={"100%"}
                    gap={"5%"}
                    alignItems={"center"}
                    justify={"space-evenly"}
                  >
                    <Stack
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      fontSize={"1.5rem"}
                    >
                      <IconButton
                        onClick={() => {
                          setIsprincipal(!isprincipal);
                        }}
                        _hover={"none"}
                        _active={"none"}
                        fontSize={"1.7rem"}
                        background={"transparent"}
                        color={"blue"}
                        aria-label="check"
                        icon={
                          isprincipal == true ? (
                            <ImCheckboxChecked />
                          ) : (
                            <ImCheckboxUnchecked />
                          )
                        }
                      />

                      <Text>Cuenta Madre</Text>
                    </Stack>
                    <Stack
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      fontSize={"1.5rem"}
                    >
                      <IconButton
                        onClick={() => {
                          setIsretenido(!isretenido);
                        }}
                        _hover={"none"}
                        _active={"none"}
                        fontSize={"1.7rem"}
                        background={"transparent"}
                        color={"blue"}
                        aria-label="check"
                        icon={
                          isretenido == true ? (
                            <ImCheckboxChecked />
                          ) : (
                            <ImCheckboxUnchecked />
                          )
                        }
                      />

                      <Text>Retenido</Text>
                    </Stack>

                    <Stack
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      fontSize={"1.5rem"}
                    >
                      <IconButton
                        onClick={() => {
                          setIsbilletera(!isbilletera);
                        }}
                        _hover={"none"}
                        _active={"none"}
                        fontSize={"1.7rem"}
                        background={"transparent"}
                        color={"blue"}
                        aria-label="check"
                        icon={
                          isbilletera == true ? (
                            <ImCheckboxChecked />
                          ) : (
                            <ImCheckboxUnchecked />
                          )
                        }
                      />

                      <Text>Billetera</Text>
                    </Stack>
                  </Flex>
                </Flex>

                {isbilletera == true ? (
                  <Center height="335px">
                    <Divider orientation="vertical" />
                  </Center>
                ) : (
                  ""
                )}

                {isbilletera == true ? (
                  <Flex
                    direction={"column"}
                    alignItems="center"
                    gap="12px"
                    w={"46%"}
                    h="100%"
                  >
                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        Email:
                      </FormLabel>
                      <Input
                        onChange={handleChangeCuenta}
                        value={dataCuentaBanco.correo}
                        name="correo"
                        fontSize={"1.8rem"}
                        h="35"
                        type="text"
                      />
                    </FormControl>

                    <Flex w={"100%"} gap={"5%"}>
                      <FormControl>
                        <FormLabel fontSize={"1.85rem"} fontWeight="500">
                          Clave Email:
                        </FormLabel>
                        <Input
                          onChange={handleChangeCuenta}
                          value={dataCuentaBanco.clave_correo}
                          name="clave_correo"
                          fontSize={"1.8rem"}
                          h="35"
                          type="text"
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel fontSize={"1.85rem"} fontWeight="500">
                          Clave Billetera:
                        </FormLabel>
                        <Input
                          onChange={handleChangeCuenta}
                          value={dataCuentaBanco.clave_billetera}
                          name="clave_billetera"
                          fontSize={"1.8rem"}
                          h="35"
                          type="text"
                        />
                      </FormControl>
                    </Flex>

                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        Codigo Seguridad:
                      </FormLabel>
                      <Input
                        onChange={handleChangeCuenta}
                        value={dataCuentaBanco.codigos_seguridad}
                        name="codigos_seguridad"
                        fontSize={"1.8rem"}
                        h="35"
                        type="text"
                      />
                    </FormControl>
                  </Flex>
                ) : (
                  ""
                )}
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"center"} marginTop={"2%"}>
              <Button
                onClick={handleSubmitCuentasUpdate}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                bg="#2a2e6e"
                _hover={{
                  color: "white",
                  bg: "#f59f2c",
                }}
                p="2%"
              >
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* ---------------Resumen Tabla--------- 
      <Modal isOpen={isOpen3} onClose={onClose3} isCentered>
        <ModalOverlay />
        <ModalContent maxW={"4xl"} p={"1%"}>
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Resumen de toda la tabla
          </ModalHeader>
          <ModalCloseButton fontSize={"1.2rem"} />
          <ModalBody>
            <Flex width={"100%"}>
              <Flex
                direction={"column"}
                alignItems="center"
                gap="18px"
                w={"100%"}
                h="100%"
              >
                <Flex w={"100%"} padding={"4%"}>
                  <TableContainer w={"100%"}>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th fontSize={"1.6rem"} textAlign={"center"}>
                            MONEDA
                          </Th>
                          <Th fontSize={"1.6rem"} textAlign={"center"}>
                            TOTAL
                          </Th>
                          <Th fontSize={"1.6rem"} textAlign={"center"}>
                            PENDIENTES
                          </Th>
                        </Tr>
                      </Thead>
                      {ListFiltered1().map((p) => {
                        return (
                          <Tbody fontSize={"1.5rem"}>
                            <Tr>
                              <Td>{p.moneda.nombre}</Td>
                              <Td>{p.total}</Td>
                              <Td>{p.total_pendiente}</Td>
                            </Tr>
                          </Tbody>
                        );
                      })}
                    </Table>
                    <Flex
                      marginTop={"2%"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      gap={"0.5%"}
                    >
                      <Icon
                        onClick={() => {
                          prevPage1();
                        }}
                        color={"#13235b"}
                        cursor={"pointer"}
                        boxSize="16"
                        _hover={{
                          color: "gray !important",
                        }}
                        _dark={{
                          color: "#f59f2c",
                        }}
                        as={IoMdArrowDropleftCircle}
                      />
                      <Icon
                        onClick={() => {
                          nextPage1();
                        }}
                        color={"#13235b"}
                        cursor={"pointer"}
                        boxSize="16"
                        _hover={{
                          color: "gray !important",
                        }}
                        _dark={{
                          color: "#f59f2c",
                        }}
                        as={IoMdArrowDroprightCircle}
                      />
                    </Flex>
                  </TableContainer>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>*/}
    </>
  );
}

export default CuentasBancos;
