import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  // Avatar,
  // HStack,
  // Link,
  IconButton,
  Button,
  // Menu,
  // MenuButton,
  // MenuList,
  // MenuItem,
  // Container,
  // Img,
  // MenuDivider,
  useDisclosure,
  // useColorModeValue,
  Stack,
  // MenuGroup,
  // Popover,
  // Divider,
  // PopoverTrigger,
  // PopoverContent,
  // PopoverArrow,
  // NavItem,
  // Collapse,
  Text,
  // Badge,
  Icon,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tooltip,
  // Tfoot,
  Tr,
  Th,
  Td,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  // TableCaption,
  TableContainer,
  FormControl,
  FormLabel,
  // FormErrorMessage,
  Select,
  // FormHelperText,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel
} from '@chakra-ui/react';
import {
  RiEditFill,
  RiInformationFill,
  RiUserAddFill
} from 'react-icons/ri';
import { MdDoDisturbOff, MdCancel, MdGroupAdd } from 'react-icons/md';
import { Search2Icon } from '@chakra-ui/icons';
import { RiFileExcel2Fill } from 'react-icons/ri';
// import { EditIcon } from '@chakra-ui/icons';
// import { FaEdit } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
import { utils, writeFile, read, writeFileXLSX } from 'xlsx';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import SidebarOperators from '../../../components/SidebarOperators';
import axios from 'axios';
import {
  IoMdArrowDroprightCircle,
  IoMdArrowDropleftCircle
} from 'react-icons/io';
import { FaCheckCircle } from 'react-icons/fa';
import { host3 } from '../../../utils/constantes';
import jwt_decode from 'jwt-decode';

function Colaboradores(props) {
  let history = useHistory();
  const [activo1, setActivo1] = useState(false);
  const token = localStorage.getItem('tokenadmin');
  const { id_franquicia } = jwt_decode(token);
  useEffect(() => {
    const { rol } = jwt_decode(token);
    if (token) {
      if (rol.includes('INVERSIONISTA')) {
        history.push('/salas/administracion');
      }
      if (rol.includes('ADMINISTRADOR')) {
        history.push('/salas/Mantenimiento');
      }
    }
  }, []);

  if (!token) {
    history.push('/');
  }
  const [ordenarPnatural, setOrdenarPnatural] = useState(false);
  const [ordenarPjuridica, setOrdenarPjuridica] = useState(false);
  const [ordenarContrats, setOrdenarContrats] = useState(false);

  const [search, setSearch] = useState('');
  const [search2, setSearch2] = useState('');

  //Mensaje
  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      default:
        break;
    }
  };

  // -----------------LISTAR COLABORADORES--------------------
  var dataLimpia = [];
  const [listColaborador, setListColaborador] = useState([]);
  const [listColaboradorJuridico, setlistColaboradorJuridico] =
    useState([]);

  const fetchPersona = async () => {
    try {
      const { status, data } = await axios.get(
        `${host3}/api/personas/${token}`
      );
      if (status == 200) {
        setListColaborador(
          data.filter(
            (itemFilter) => itemFilter.tipo_documento == 'DNI'
          )
        );
        setlistColaboradorJuridico(
          data.filter(
            (itemFilter) => itemFilter.tipo_documento == 'RUC'
          )
        );
      }
    } catch (error) {
      setListColaborador([]);
    }
  };

  const exportExcel = () => {
    for (const e of listColaborador) {
      if (e.estado == 1) {
        e.estado = 'Activo';
      } else {
        e.estado = 'Inactivo';
      }

      const roles = e.colaborador_rols
        .map((item) => item.rol.nombre)
        .join(', ');

      dataLimpia.push({
        'Apellidos y Nombres': e.apellidos + ' ' + e.nombres,
        Nacimiento: moment
          .parseZone(e.birth_date)
          .local()
          .format('YYYY-MM-DD'),
        Telefono: e.telefono,
        Correo: e.correo,
        Tipo: e.tipo_documento,
        Documento: e.documento,
        Roles: roles,
        Estado: e.estado
      });
    }

    setDataLimpiarExcel(dataLimpia);
  };

  const exportExcelJuridico = () => {
    for (const j of listColaboradorJuridico) {
      if (j.estado == 1) {
        j.estado = 'Activo';
      } else {
        j.estado = 'Inactivo';
      }

      dataLimpia.push({
        'Apellidos y Nombres': j.apellidos + ' ' + j.nombres,
        Telefono: j.telefono,
        Correo: j.correo,
        Tipo: j.tipo_documento,
        Documento: j.documento,
        Estado: j.estado
      });
    }

    setDataLimpiarExcel(dataLimpia);
  };

  //------------------EXCEL-----------------------------------
  const [dataLimpiarExcel, setDataLimpiarExcel] = useState([]);
  const [tiempo, setTiempo] = useState(false);

  useEffect(() => {
    exportExcel();
    exportExcelJuridico();
  }, [listColaborador, listColaboradorJuridico]);

  // -------------------Persona Natural---------------------------------
  const exportAsExcelNatural = () => {
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(
      dataLimpiarExcel.filter(
        (itemFilter) => itemFilter.Tipo == 'DNI'
      )
    );

    // Establecer el ancho de las columnas
    ws['!cols'] = [
      { key: 'apellidos_nombres', wch: 30 },
      { key: 'nacimiento', wch: 15 },
      { key: 'telefono', wch: 15 },
      { key: 'correo', wch: 20 },
      { key: 'tipo', wch: 15 },
      { key: 'documento', wch: 15 },
      { key: 'roles', wch: 15 },
      { key: 'estado', wch: 15 }
      // Añade más columnas según sea necesario
    ];

    utils.book_append_sheet(wb, ws, `Colaboradores`);
    writeFile(
      wb,
      `Colaboradores_Naturales_${moment().format('LTS')}.xlsx`
    );
  };
  // -------------------Persona Juridica---------------------------------
  const exportAsExcelJuridico = () => {
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(
      dataLimpiarExcel.filter(
        (itemFilter) => itemFilter.Tipo == 'RUC'
      )
    );

    // Establecer el ancho de las columnas
    ws['!cols'] = [
      { key: 'apellidos_nombres', wch: 25 },
      { key: 'telefono', wch: 15 },
      { key: 'correo', wch: 20 },
      { key: 'tipo', wch: 15 },
      { key: 'documento', wch: 15 },
      { key: 'estado', wch: 15 }
      // Añade más columnas según sea necesario
    ];

    utils.book_append_sheet(wb, ws, `Colaboradores`);
    writeFile(
      wb,
      `Colaboradores_Jurídicos_${moment().format('LTS')}.xlsx`
    );
  };

  const ListFilteredNatural = () => {
    if (search) {
      var filtered = listColaborador.filter(
        (p) =>
          p.nombres?.includes(search.toUpperCase()) ||
          p.correo?.includes(search.toLowerCase()) ||
          p.apellidos?.includes(search.toUpperCase()) ||
          p.telefono?.includes(search)
      );
      return filtered.slice(currentPage, currentPage + 13);
    } else {
      return listColaborador?.slice(currentPage, currentPage + 13);
    }
  };

  const ListFilteredJuridico = () => {
    if (search) {
      var filtered = listColaboradorJuridico.filter(
        (p) =>
          p.nombres?.includes(search.toUpperCase()) ||
          p.correo?.includes(search.toLowerCase()) ||
          p.apellidos?.includes(search.toUpperCase()) ||
          p.telefono?.includes(search)
      );
      return filtered.slice(currentPage, currentPage + 13);
    } else {
      return listColaboradorJuridico?.slice(
        currentPage,
        currentPage + 13
      );
    }
  };

  // --------------AGREGAR COLABORADOR--------------------
  const [ModelColaborador, setModelColaborador] = useState({
    apellidos: '',
    nombres: '',
    telefono: '',
    correo: '',
    tipo_documento: '',
    documento: '',
    id_sala: 'inversionista',
    fecha_nacimiento: '',
    clave_sistema: '',
    id_sala: 'inversionista',

    roles: []
  });

  const changeColaborador = (e) => {
    let inputValue = e.target.value;
    // Validar que la entrada sea un número y tiene exactamente 8 dígitos
    if (e.target.name === 'documento' && !/^\d{0,8}$/.test(inputValue)) {
      return;
    }
    setModelColaborador({
      ...ModelColaborador,
      [e.target.name]: inputValue
    });
  };

  const handleSelectChange = (e) => {
    setModelColaborador({
      ...ModelColaborador,
      estado: parseInt(e.target.value)
    });
  };

  //------------------ASIGNAR EL ROL--------------
  const addRole = () => {
    var roles = ModelColaborador.roles;
    if (verificar() == true) {
      return;
    } else {
      roles.push(rol);
      setModelColaborador({
        ...ModelColaborador,
        roles: roles
      });
      fetchPersona();
    }
  };
  //-------------SELECCIONAR ROLES EN LA LISTA-----------
  const [r, setR] = useState('');
  const [rol, setRol] = useState({ id: 0, nombre: '' });
  const handleChangeRoles = (e) => {
    let separador = e.target.value.split('-');
    let rolfinal = separador[1];
    // console.log(separador);
    setRol({
      id: parseInt(separador[0]),
      nombre: separador[1]
    });
    setR(e.target.value);
    // console.log(e.target.value);
    // let roles = ModelColaborador.roles;
    // setModelColaborador({
    //   ...ModelColaborador,
    //   roles: roles.push(rol.id)
    // });
  };

  //-----------CREAR COLABORADOR---------------
  const crearColaborador = async (e) => {
    e.preventDefault();
    try {
      const { status, data } = await axios.post(
        `${host3}/api/personas/${token}`,
        ModelColaborador
      );

      if (status == 201) {
        mensaje(data.message, 'top-right', 1);
        onClose();
        setModelColaborador({
          apellidos: '',
          nombres: '',
          telefono: '',
          correo: '',
          tipo_documento: '',
          documento: '',
          id_sala: 'inversionista',
          fecha_nacimiento: '',
          clave_sistema: '',
          id_sala: 'inversionista',

          roles: []
        });
        fetchPersona();
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
  };

  //-----------------VERIFICAR-----------------------------------

  const verificar = () => {
    const roles = ModelColaborador.roles;
    let verificar = roles.find((r) => r.id == rol.id);
    // console.log(roles, verificar);
    if (!verificar) {
      return false;
    }
    return true;
    // return RolExiste;
  };

  //------------------EDITAR---------------------
  const [role] = useState({ id: '', nombre: '' });
  const editColaborador = (a) => {
    // console.log(a.rols);
    let roles = [];
    a.colaborador_rols.map((r) =>
      roles.push({
        id: r.rol_id,
        nombre: r.rol.nombre,
        id_colaborador_rol: r.id_colaborador_rol
      })
    );
    setColaborador({ ...a, roles: roles });

    onOpen2();
  };

  const [Colaborador, setColaborador] = useState({
    apellidos: '',
    nombres: '',
    telefono: '',
    correo: '',
    tipo_documento: '',
    documento: '',
    id_sala: 'inversionista',
    fecha_nacimiento: '',
    clave_sistema: '',
    id_sala: 'inversionista',

    roles: []
  });

  const roles = () => {
    let exist = 0;
    var roles = Colaborador.roles;
    //console.log(roles)
    roles.map(({ id }) => {
      if (id == rol.id) {
        exist = exist + 1;
      }
    });

    if (exist > 0) {
      return;
    }
    roles.push(rol);

    setColaborador({
      ...Colaborador,
      roles: roles
    });

    fetchPersona();
  };

  const handleChange = (e) => {
    let inputValue = e.target.value;
    // Validar que la entrada sea un número y tiene exactamente 8 dígitos
    if (e.target.name === 'documento' && !/^\d{0,8}$/.test(inputValue)) {
    return;
  }
    setColaborador({
      ...Colaborador,
      [e.target.name]: e.target.value
    });
  };

  const handleSelect = (e) => {
    setColaborador({
      ...Colaborador,
      estado: parseInt(e.target.value)
    });
  };

  const handleSubmitUpdateColaborador = async (e) => {
    e.preventDefault();
    try {
      const { data, status } = await axios.put(
        `${host3}/api/personas/${token}/${Colaborador.id_persona}`,
        Colaborador
      );
      if (status == 201) {
        mensaje(data.message, 'top-right', 1);
        setColaborador({
          apellidos: '',
          nombres: '',
          telefono: '',
          correo: '',
          tipo_documento: '',
          documento: '',
          fecha_nacimiento: '',
          clave_sistema: '',
          id_sala: 'inversionista',
          roles: []
        });
        onClose2();
        fetchPersona();
      }
      //console.log(data);
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
      //console.log(error);
    }
  };

  //------------------ELIMINAR---------------

  const eliminar = (id_eliminar) => {
    setModelColaborador((prevState) => ({
      ...ModelColaborador,
      roles: prevState.roles.filter(({ id }) => id != id_eliminar)
    }));
  };
  const eliminarEdit = async (id_eliminar, id_rol) => {
    try {
      const { status, data } = await axios.delete(
        `${host3}/api/roles/${token}/colaborador-rol/${id_rol}`
      );
      if (status == 201) {
        setColaborador((prevState) => ({
          ...Colaborador,
          roles: prevState.roles.filter(({ id }) => id != id_eliminar)
          /*rols: prevState.rols.filter(
            ({ colaborador_rol }) => colaborador_rol.rol_id !== id_eliminar
          ),*/
        }));
      }
      fetchPersona();
    } catch (error) {
      console.log(error);
    }
  };

  //----------------BUSCAR-----------------------

  const buscar = (e) => {
    setSearch(e.target.value);
  };

  const buscar2 = (e) => {
    setSearch2(e.target.value);
  };

  //FILTRAR POR ROL

  const ChangeRolColaborador = (e) => {
    if (e.target.value != '') {
      FiltrarColaboradorRol(e.target.value);
    } else {
      fetchPersona();
    }
  };

  const FiltrarColaboradorRol = async (id_rol) => {
    await axios
      .get(`${host3}/api/roles/${token}/rol-colaborador/${id_rol}`)
      .then((response) => {
        //console.log(response.data);
        setListColaborador(response.data);
      })
      .catch((err) => {
        setListColaborador([]);
      });
  };

  //-----------------DAR DE BAJA COLABORADOR----------------------
  const darDeBaja = async (id_colaborador) => {
    // const NOW = new Date();
    const datosActualizados = {
      estado: false
      // date_baja: NOW
    };

    await axios
      .get(
        `${host3}/api/colaborador/${token}/baja/${id_colaborador}`,
        datosActualizados
      )
      .then((response) => {
        console.log('Ha sido dado de baja exitosamente');
        fetchPersona();
      })
      .catch((error) => {
        console.error('Error al dar de baja:', error);
      });
  };

  //-----------------ACTIVAR COLABORADOR----------------------

  const accionColaboradorEstado = async (id_colaborador, action) => {
    try {
      const { status, data } = await axios.put(
        `${host3}/api/personas/${token}/${id_colaborador}/accion/${action}`
      );

      if (status == 201) {
        console.log(data.message);
        fetchPersona();
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Contratos

  const [dataContrato, setDataContrato] = useState({
    id_persona: '',
    id_sala: '',
    inicio_capacitacion: '',
    fin_capacitacion: '',
    inicio_software: '',
    fin_software: '',
    capacitador: '',
    fecha_firma: '',
    tipo_relacion: '',
    tipo_contrato: ''
  });

  const [listContrato, setListContrato] = useState([]);

  const handleChangeContrato = ({ target: { name, value } }) => {
    setDataContrato({ ...dataContrato, [name]: value });
  };

  const fetchContratos = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/contratos/${token}`
      );
      setListContrato(data);
    } catch (error) {
      console.log(error);
    }
  };

  const ListFilteredContrato = () => {
    if (search) {
      var filtered = listContrato.filter(
        (p) =>
          p.capacitador?.includes(search.toUpperCase()) ||
          p.sala.nombre?.includes(search.toUpperCase()) ||
          p.persona.nombres?.includes(search.toUpperCase()) ||
          p.persona.apellidos?.includes(search.toUpperCase())
      );
      return filtered.slice(currentPage, currentPage + 13);
    } else {
      return listContrato?.slice(currentPage, currentPage + 13);
    }
  };

  //Crear contrato
  const createContrato = async () => {
    try {
      const { status, data } = await axios.post(
        `${host3}/api/contratos/${token}`,
        dataContrato
      );

      if (status == 201) {
        mensaje(data.message, 'top-right', 1);
        setDataContrato({
          id_persona: '',
          id_sala: '',
          inicio_capacitacion: '',
          fin_capacitacion: '',
          inicio_software: '',
          fin_software: '',
          capacitador: '',
          fecha_firma: '',
          tipo_relacion: '',
          tipo_contrato: ''
        });
      }
      onCloseNContrato();
      fetchContratos();
    } catch (error) {
      console.log(error);
      mensaje(error.response.data.message, 'top-right', 2);
    }
  };

  //Actualizar contrato
  const updateContrato = async () => {
    try {
      const { status, data } = await axios.put(
        `${host3}/api/contratos/${token}/${dataContrato.id_contratos}`,
        dataContrato
      );
      if (status === 201) {
        mensaje(data.message, 'top-right', 1);
        setDataContrato({
          id_persona: '',
          id_sala: '',
          inicio_capacitacion: '',
          fin_capacitacion: '',
          inicio_software: '',
          fin_software: '',
          capacitador: '',
          fecha_firma: '',
          tipo_relacion: '',
          tipo_contrato: ''
        });
        onCloseEContrato();
        fetchContratos();
      }
    } catch (error) {
      console.log(error);
      mensaje(error.response.data.message, 'top-right', 2);
    }
  };

  //Salas
  const [listSalas, setListSalas] = useState([]);

  const fetchSalas = async () => {
    try {
      const { data } = await axios.get(`${host3}/api/salas/${token}`);
      setListSalas(data);
    } catch (error) {
      console.log(error);
    }
  };

  //Inversionista

  const [listInversionista, setListInversionista] = useState([]);

  const fetchInversionista = async (id_rol) => {
    await axios
      .get(`${host3}/api/roles/${token}/rol-colaborador/2`)
      .then((response) => {
        //console.log(response.data);
        setListInversionista(response.data);
      })
      .catch((err) => {
        setListInversionista([]);
      });
  };

  //Exportar contratos
  const exportExcelContratos = () => {
    var dataLimpia = [];
    for (const e of listContrato) {
      if (e.estado == 1) {
        e.estado = 'Activo';
      } else {
        e.estado = 'Inactivo';
      }

      dataLimpia.push({
        Tipo: e.tipo_relacion,
        Inversionista: e.persona.apellidos + ' ' + e.persona.nombres,
        'Inicio Capacitación': moment
          .parseZone(e.inicio_capacitacion)
          .local()
          .format('YYYY-MM-DD'),
        'Fin Capacitación': moment
          .parseZone(e.fin_capacitacion)
          .local()
          .format('YYYY-MM-DD'),
        'Inicio Sistema': moment
          .parseZone(e.inicio_software)
          .local()
          .format('YYYY-MM-DD'),
        'Fin Sistema': moment
          .parseZone(e.fin_software)
          .local()
          .format('YYYY-MM-DD'),
        Firma: moment
          .parseZone(e.fecha_firma)
          .local()
          .format('YYYY-MM-DD'),
        Sala: e.sala.nombre,
        Capacitador: e.capacitador,
        Estado: e.estado
      });
    }

    setDataLimpiarExcelContrato(dataLimpia);
  };

  //------------------EXCEL-CONTRATO-----------------------------------
  const [dataLimpiarExcelContrato, setDataLimpiarExcelContrato] =
    useState([]);

  useEffect(() => {
    exportExcelContratos();
  }, [listContrato]);


  // -------------------Persona Juridica---------------------------------
  const exportExcelContrato = () => {
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(dataLimpiarExcelContrato);

    // Establecer el ancho de las columnas
    ws['!cols'] = [
      { key: 'tipo', wch: 16 },
      { key: 'Inversionista', wch: 30 },
      { key: 'inicio_capacitación', wch: 20 },
      { key: 'fin_capacitación', wch: 15 },
      { key: 'inicio_sistema', wch: 15 },
      { key: 'fin_sistema', wch: 15 },
      { key: 'Firma', wch: 15 },
      { key: 'Sala', wch: 15 },
      { key: 'Capacitador', wch: 18 },
      { key: 'Estado', wch: 15 }

      // Añade más columnas según sea necesario
    ];

    utils.book_append_sheet(wb, ws, `Contratos`);
    writeFile(wb, `Contratos_${moment().format('LTS')}.xlsx`);
  };

  //PERSONA JURIDICA

  //-----------------------AGREGAR---------------------
  const [ModelPersona, setModelPersona] = useState({
    apellidos: '',
    nombres: '',
    telefono: '',
    correo: '',
    id_sala: 'inversionista',
    tipo_documento: '',
    documento: '',
    fecha_nacimiento: '',
    clave_sistema: '',
    id_sala: 'inversionista',
    roles: []
  });

  const changePersona = (e) => {
    let inputValue = e.target.value;
    // Validar que la entrada sea un número y tiene exactamente 8 dígitos
     if (e.target.name === 'documento' && !/^\d{0,11}$/.test(inputValue)) {
    return;
  }
    setModelPersona({
      ...ModelPersona,
      [e.target.name]: e.target.value
    });
  };

  const crearPersona = async (e, rol_id) => {
    e.preventDefault();
    try {
      const { status, data } = await axios.post(
        `${host3}/api/personas/${token}`,
        ModelPersona
      );
      if (status === 200) {
        mensaje(data.message, 'top-right', 1);
        onClose();
        setModelPersona({
          apellidos: '',
          nombres: '',
          telefono: '',
          correo: '',
          tipo_documento: '',
          documento: '',
          fecha_nacimiento: '',
          id_sala: 'inversionista',
          clave_sistema: ''
        });
      }
      fetchPersona();
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
  };

  //----------------------EDITAR--------------------------
  const [updatePersona, setPersona] = useState({
    apellidos: '',
    nombres: '',
    telefono: '',
    correo: '',
    tipo_documento: '',
    documento: '',
    fecha_nacimiento: '',
    clave_sistema: '',
    id_sala: 'inversionista',
    roles: []
  });

  const changeUpdate = (e) => {
    setPersona({
      ...updatePersona,
      [e.target.name]: e.target.value
    });
  };

  const editarPersona = async (e, rol_id) => {
    e.preventDefault();
    try {
      updatePersona.roles = [{ id: 2, nombre: 'INVERSIONISTA' }];
      const { data, status } = await axios.put(
        `${host3}/api/personas/${token}/${updatePersona.id_persona}`,
        updatePersona
      );
      if (status == 201) {
        mensaje(data.message, 'top-right', 1);
        onCloseEditjuridica();
        setPersona({
          apellidos: '',
          nombres: '',
          telefono: '',
          id_sala: 'inversionista',
          correo: '',
          tipo_documento: '',
          documento: '',
          fecha_nacimiento: '',
          clave_sistema: '',
          id_sala: 'inversionista',

          roles: []
        });
        fetchPersona();
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
      //console.log(error);
    }
  };

  //---------------LISTAR REPRESENTANTE--------------------------
  const [listRepresentante, setListRepresentante] = useState([]);
  const [inversionista, setInversionista] = useState(null);

  const representante = async (id) => {
    try {
      return await axios.get(
        `${host3}/api/personas/${token}/list/${id}`
      );
    } catch (error) {
      console.log(error);
    }
  };
  const ListFiltered = () => {
    return listRepresentante?.slice(currentPage, currentPage + 13);
  };

  //--------------------QUITAR-----------------------------

  const quitar = async (id) => {
    try {
      const { status, data } = await axios.delete(
        `${host3}/api/personas/${token}/remove-representative/${id}`
      );
      if (status == 201) {
        mensaje(data.message, 'top-right', 1);
        //representante();
        setInversionista(null);
        onCloseInfoRe();
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
    asignacion();
    fetchPersona();
  };

  // //---------------------ASIGNAR--------------------------

  const [asignateData, setAsignateData] = useState({
    id_inversionista: '',
    id_representante: ''
  });

  const handleAsignateRepresent = async (representante) => {
    asignateData.id_representante = representante;
    try {
      const { status, data } = await axios.post(
        `${host3}/api/personas/${token}/asignate-representative`,
        asignateData
      );
      if (status === 201) {
        mensaje(data.message, 'top-right', 1);
        asignacion();
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
    fetchPersona();
  };

  // //-------------------LISTAR ASIGNACION-------------------
  const [listAsignacion, setListAsignacion] = useState([]);

  const asignacion = async () => {
    try {
      const { status, data } = await axios.get(
        `${host3}/api/personas/${token}/mostrar`
      );
      if (status === 200) {
        setListAsignacion(data);
      }
    } catch (error) {
      setListAsignacion([]);
    }
  };

  const ListFiltered2 = () => {
    if (search2) {
      var filtered = listAsignacion.filter(
        (p) =>
          p.nombres?.includes(search2.toUpperCase()) ||
          p.apellidos?.includes(search2.toUpperCase()) ||
          p.telefono?.includes(search2) ||
          p.documento?.includes(search2)
      );
      return filtered.slice(currentPage2, currentPage2 + 13);
    }
    return listAsignacion?.slice(currentPage2, currentPage2 + 13);
  };

  //------------------------------------------------------------
  useEffect(() => {
    const loadData = async (id) => {
      try {
        const { data } = await representante(id);
        setListRepresentante(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (inversionista != null) {
      loadData(inversionista);
    }

    //representante();
  }, [inversionista]);

  //Paginacion

  const [currentPage, setCurrentPage] = useState(0);
  const nextPage = () => {
    const cant = listColaborador.filter((p) => p.id_persona).length;

    if (cant > currentPage) {
      setCurrentPage(currentPage + 13);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 13);
    }
  };

  // ---------------------------------------------------------------------------------
  const [currentPage2, setCurrentPage2] = useState(0);
  const nextPage2 = () => {
    const cant = listContrato.filter((p) => p.id_contratos).length;

    if (cant > currentPage2) {
      setCurrentPage2(currentPage2 + 13);
    }
  };

  const prevPage2 = () => {
    if (currentPage2 > 0) {
      setCurrentPage2(currentPage2 - 13);
    }
  };

  // ---------------------------
  const [currentModalData, setCurrentModalData] = useState(null);
  const closeTimeoutRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2
  } = useDisclosure();

  const {
    isOpen: isOpenPjuridica,
    onOpen: onOpenPjuridica,
    onClose: onClosePjuridica
  } = useDisclosure();

  const {
    isOpen: isOpenEditjuridica,
    onOpen: onOpenEditjuridica,
    onClose: onCloseEditjuridica
  } = useDisclosure();

  const {
    isOpen: isOpenInfoRe,
    onOpen: onOpenInfoRe,
    onClose: onCloseInfoRe
  } = useDisclosure();

  const {
    isOpen: isOpenARepresentante,
    onOpen: onOpenARepresentante,
    onClose: onCloseARepresentante
  } = useDisclosure();

  const {
    isOpen: isOpenNContrato,
    onOpen: onOpenNContrato,
    onClose: onCloseNContrato
  } = useDisclosure();

  const {
    isOpen: isOpenEContrato,
    onOpen: onOpenEContrato,
    onClose: onCloseEContrato
  } = useDisclosure();

  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3
  } = useDisclosure();

  const openModal = (data) => {
    setCurrentModalData(data);
    onOpen3();
  };

  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar
  } = useDisclosure();

  const closeModal = () => {
    setCurrentModalData(null);
    onClose3();
  };
  const [listRoles, setListRoles] = useState([
    { id_rol: 2, nombre: 'INVERSIONISTA' },
    { id_rol: 7, nombre: 'REPRESENTANTE' }
  ]);

  useEffect(() => {
    asignacion();
    fetchPersona();
    fetchContratos();
    fetchInversionista();
    fetchSalas();
    representante();
    setTimeout(() => {
      setTiempo(true);
    }, 1000);
  }, []);
  // let history = useHistory();
  // const token = localStorage.getItem('tokenadmin');
  if (!token) {
    history.push('/');
  }
  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: '1.2rem', textAlign: 'center' }}
        pauseOnHover
      />

      <Box
        scrollBehavior={'smooth'}
        bg={'#e5e5e5'}
        height={'auto'}
        maxHeight={'100vh'}
        overflowY={'auto'}
        css={{
          '&::-webkit-scrollbar': {
            width: '12px'
          },
          '&::-webkit-scrollbar-track': {
            background: '#6b6b6b',
            borderRadius: '0px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#9b9b9b',
            borderRadius: '6px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#9b9b9b'
          }
        }}>
        <Box
          as='section'
          display={{
            '2xl': 'unset',
            xl: 'unset',
            lg: 'none',
            md: 'none',
            sm: 'none'
          }}>
          <SidebarOperators />
        </Box>
        <Drawer
          isOpen={isOpenBar}
          onClose={onCloseBar}
          placement='left'>
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w='full' borderRight='none' />
          </DrawerContent>
        </Drawer>
        <Box bg={'#e5e5e5'} h={'100vh'}>
          <Flex
            alignItems={'center'}
            justify={'flex-start'}
            // h={'100%'}
            sx={{
              '@media only screen and (min-width: 1200px) and (max-width: 1366px)':
                {
                  padding: '1.5% 1.5% 1.5% 19%'
                }
            }}
            p={{
              '2xl': '1.5% 3% 1.5% 15%',
              xl: '1.5% 3% 1.5% 18%',
              lg: '1.5% 2% 1.5% 2%',
              md: '1.5% 2% 1.5% 2%'
            }}
            // marginLeft={"15%"}
            // marginRight="2%"
            // marginBottom="1%"
            direction='column'
            gap={'25px'}>
            <Box transition='.3s ease' width={'100%'}>
              <Flex
                gap='2%'
                alignItems={'center'}
                as='header'
                w='full'
                h='14'>
                <IconButton
                  aria-label='Menu'
                  display={{
                    '2xl': 'none',
                    xl: 'none',
                    lg: 'flex',
                    md: 'flex',
                    sm: 'flex'
                  }}
                  background={'#292F36'}
                  color={'#ffffff'}
                  borderRadius={'4px'}
                  fontSize={'1.3rem'}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size='md'
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      color={'#707070'}
                      fontSize={'1.5rem'}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={'bold'}
                      color={'#292F36'}
                      fontSize={'1.5rem'}>
                      INVERSIONISTA
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>

            <Flex width={'100%'} justifyContent={'center'}>
              <Flex w={'100%'} h='85vh'>
                <Tabs isFitted variant='enclosed' w={'100%'}>
                  <TabList
                    mb='1em'
                    bg={'#FFFF'}
                    borderRadius='15px'
                    p={'0.5%'}>
                    <Tab
                      borderRadius={'15px'}
                      _selected={{
                        color: 'white',
                        bg: '#2a2e6e'
                      }}
                      fontSize='2.1rem'
                      textTransform={'uppercase'}
                      fontFamily='monospace'
                      fontWeight={'600'}>
                      PERSONA NATURAL
                    </Tab>
                    <Tab
                      borderRadius={'15px'}
                      _selected={{
                        color: 'white',
                        bg: '#2a2e6e'
                      }}
                      fontSize='2.1rem'
                      textTransform={'uppercase'}
                      fontFamily='monospace'
                      fontWeight={'600'}>
                      PERSONA JURIDICA
                    </Tab>
                    <Tab
                      borderRadius={'15px'}
                      _selected={{
                        color: 'white',
                        bg: '#2a2e6e'
                      }}
                      fontSize='2.1rem'
                      textTransform={'uppercase'}
                      fontFamily='monospace'
                      fontWeight={'600'}>
                      CONTRATOS
                    </Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel
                      w={'100%'}
                      bg={'transparent'}
                      borderRadius='10px'
                      p={'2%'}
                      // boxShadow='rgba(0, 0, 0, 0.35) 0px 5px 15px'
                    >
                      <Flex
                        width={'100%'}
                        direction={'column'}
                        gap={'25px'}>
                        <Flex
                          width={'100%'}
                          justifyContent={'space-between'}>
                          <Flex
                            width={{
                              '2xl': '30%',
                              xl: '30%',
                              lg: '40%',
                              md: '50%'
                            }}>
                            <Stack w={'100%'}>
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents='none'
                                  children={
                                    <Search2Icon
                                      color='#2a2e6e'
                                      marginLeft={'20%'}
                                      fontSize={'1.25rem'}
                                      marginTop={'60%'}
                                      _dark={{
                                        color: '#f59f2c'
                                      }}
                                    />
                                  }
                                />
                                <Input
                                  onChange={buscar}
                                  type='text'
                                  placeholder='Buscar'
                                  bg={'white'}
                                  height={'40px'}
                                  _focus={{
                                    borderColor: '#2a2e6e',
                                    boxShadow: 'none'
                                  }}
                                  color='#2a2e6e'
                                  fontSize={'1.9rem'}
                                  border={'1px solid'}
                                  borderColor={'#2a2e6e'}
                                  borderRadius='6px'
                                  _placeholder={{
                                    color: 'rgb(152, 152, 152)'
                                  }}
                                  _dark={{
                                    color: 'white',
                                    borderColor: '#686868',
                                    bg: '#686868'
                                  }}
                                />
                              </InputGroup>
                            </Stack>
                            {tiempo && (
                              <Button
                                onClick={exportAsExcelNatural}
                                background={'#4caf50'}
                                color={'#fff'}
                                height={'40px'}
                                borderRadius={'5px'}
                                padding={'6px 16px'}
                                marginLeft={'15px'}
                                _hover={'none'}>
                                <Icon
                                  boxSize='10'
                                  as={RiFileExcel2Fill}
                                />
                              </Button>
                            )}
                          </Flex>

                          <Flex
                            width={'auto'}
                            justifyContent={'space-between'}>
                            <Select
                              w={'100%'}
                              bg={'white'}
                              height={'40px'}
                              color='#2a2e6e'
                              fontSize={'1.8rem'}
                              border={'1px solid'}
                              borderColor={'#2a2e6e'}
                              borderRadius='6px'
                              autoComplete='off'
                              placeholder='Seleccione El Rol'
                              _dark={{
                                color: 'white',
                                borderColor: '#686868',
                                bg: '#686868'
                              }}
                              name='searchRol'
                              onChange={ChangeRolColaborador}
                              required>
                              {listRoles &&
                                listRoles.map((lr) => {
                                  return (
                                    <option
                                      value={`${lr.id_rol}-${lr.nombre}`}>
                                      {lr.nombre}
                                    </option>
                                  );
                                })}
                            </Select>
                          </Flex>
                        </Flex>

                        {/* --------------------------------------------------------- */}

                        <Flex
                          boxShadow={
                            'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'
                          }
                          background={'white'}
                          borderRadius={'18px'}
                          direction={'column'}
                          padding={'2% 4% 2% 4%'}>
                          <Flex
                            width={'100%'}
                            justifyContent={'right'}>
                            <Stack align='center' width={'auto'}>
                              <Button
                                onClick={onOpen}
                                w={'100%'}
                                height={'40px'}
                                borderRadius='8px'
                                color={'white'}
                                fontSize={'1.6rem'}
                                textTransform={'uppercase'}
                                bg='#2a2e6e'
                                _hover={{
                                  color: 'white',
                                  bg: '#f59f2c'
                                }}>
                                Agregar
                              </Button>
                            </Stack>
                          </Flex>
                          {/* --------------------------------------------------------- */}
                          <Flex
                            direction={'column'}
                            w={'100%'}
                            h='100%'
                            justifyContent='center'
                            paddingTop={'2%'}>
                            <TableContainer w={'100%'}>
                              <Table variant='simple'>
                                <Thead>
                                  <Tr>
                                    <Th fontSize={'1.6rem'}>ID</Th>
                                    <Th
                                      fontSize={'1.6rem'}
                                      cursor={'pointer'}
                                      onClick={() => {
                                        var ordenDocument;
                                        if (!ordenarPnatural) {
                                          ordenDocument =
                                            listColaborador.sort(
                                              (a, b) =>
                                                a.apellidos.localeCompare(
                                                  b.apellidos
                                                )
                                            );
                                          setListColaborador(
                                            ordenDocument
                                          );
                                          setOrdenarPnatural(true);
                                        } else {
                                          ordenDocument =
                                            listColaborador.sort(
                                              (a, b) =>
                                                b.apellidos.localeCompare(
                                                  a.apellidos
                                                )
                                            );
                                          setListColaborador(
                                            ordenDocument
                                          );
                                          setOrdenarPnatural(false);
                                        }
                                      }}>
                                      APELLIDOS
                                    </Th>
                                    <Th fontSize={'1.6rem'}>
                                      NOMBRES
                                    </Th>
                                    <Th fontSize={'1.6rem'}>
                                      NACIMIENTO
                                    </Th>
                                    <Th fontSize={'1.6rem'}>
                                      TELEFONO
                                    </Th>
                                    <Th
                                      fontSize={'1.6rem'}
                                      cursor={'pointer'}
                                      onClick={() => {
                                        var ordenDocument;
                                        if (!ordenarPnatural) {
                                          ordenDocument =
                                            listColaborador.sort(
                                              (a, b) =>
                                                a.correo.localeCompare(
                                                  b.correo
                                                )
                                            );
                                          setListColaborador(
                                            ordenDocument
                                          );
                                          setOrdenarPnatural(true);
                                        } else {
                                          ordenDocument =
                                            listColaborador.sort(
                                              (a, b) =>
                                                b.correo.localeCompare(
                                                  a.correo
                                                )
                                            );
                                          setListColaborador(
                                            ordenDocument
                                          );
                                          setOrdenarPnatural(false);
                                        }
                                      }}>
                                      CORREO
                                    </Th>
                                    <Th fontSize={'1.6rem'}>ROLES</Th>
                                    <Th fontSize={'1.6rem'}>
                                      ESTADO
                                    </Th>
                                    <Th isNumeric fontSize={'1.6rem'}>
                                      ACCIONES
                                    </Th>
                                  </Tr>
                                </Thead>

                                <Tbody fontSize={'1.5rem'}>
                                  {ListFilteredNatural().map(
                                    (itemNatural, index) => (
                                      <Tr
                                      // key={p.id_colaborador}
                                      >
                                        <Td>
                                          {index+1}
                                          {/* {p.id_colaborador} */}
                                        </Td>
                                        <Td>
                                          {itemNatural.apellidos}
                                          {/* {p.id_colaborador} */}
                                        </Td>
                                        <Td>
                                          {itemNatural.nombres}
                                          {/* {p.apellidos + " " + p.nombres} */}
                                        </Td>
                                        <Td>
                                          {
                                            itemNatural.fecha_nacimiento
                                          }
                                          {/* {p.birth_date} */}
                                        </Td>
                                        <Td>
                                          {itemNatural.telefono}
                                          {/* {p.telefono} */}
                                        </Td>
                                        <Td>
                                          {itemNatural.correo}
                                          {/* {p.correo} */}
                                        </Td>
                                        <Td>
                                          {itemNatural.colaborador_rols
                                            .slice(0, 2)
                                            .map((rm, index) => (
                                              <span key={index}>
                                                {index === 0
                                                  ? ''
                                                  : ', '}
                                                {rm.rol.nombre}
                                              </span>
                                            ))}
                                          {itemNatural
                                            .colaborador_rols.length >
                                            2 && (
                                            <Tooltip
                                              background={'#292F36'}
                                              color={'#ffffff'}
                                              textAlign={'center'}
                                              fontSize={'1.5rem'}
                                              label={itemNatural.colaborador_rols
                                                .slice(2)
                                                .map(
                                                  (rm) =>
                                                    rm.rol.nombre
                                                )
                                                .join(', ')}
                                              placement='bottom-end'>
                                              <span>...</span>
                                            </Tooltip>
                                          )}
                                        </Td>
                                        <Td>
                                          {itemNatural.estado}
                                          {/* {p.estado} */}
                                        </Td>
                                        <Td
                                          fontWeight={'600'}
                                          fontFamily='monospace'
                                          isNumeric>
                                          <Flex
                                            justifyContent={'right'}
                                            gap={'4%'}>
                                            <Tooltip
                                              hasArrow
                                              borderRadius={'4px'}
                                              fontSize={'1.2rem'}
                                              label='Editar'
                                              bg='#292F36'>
                                              <IconButton
                                                onClick={() => {
                                                  editColaborador(
                                                    itemNatural
                                                  );
                                                }}
                                                size={'md'}
                                                borderRadius={'4px'}
                                                fontSize={'1.5rem'}
                                                background={'#f59f2c'}
                                                color={'#ffffff'}
                                                aria-label='Editar'
                                                icon={<RiEditFill />}
                                              />
                                            </Tooltip>
                                            {/* -------------- */}

                                            {itemNatural.estado !=
                                            'Activo' ? (
                                              <Tooltip
                                                hasArrow
                                                borderRadius={'4px'}
                                                fontSize={'1.2rem'}
                                                label='Activar'
                                                bg='#292F36'>
                                                <IconButton
                                                  onClick={() => {
                                                    accionColaboradorEstado(
                                                      itemNatural.id_persona,
                                                      1
                                                    );
                                                    setActivo1(false);
                                                  }}
                                                  size={'md'}
                                                  borderRadius={'4px'}
                                                  fontSize={'1.5rem'}
                                                  background={
                                                    '#2b752d'
                                                  }
                                                  color={'#ffffff'}
                                                  aria-label='Activar usuario'
                                                  icon={
                                                    <FaCheckCircle />
                                                  }
                                                />
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                hasArrow
                                                borderRadius={'4px'}
                                                fontSize={'1.2rem'}
                                                label='Desactivar'
                                                bg='#292F36'>
                                                <IconButton
                                                  onClick={() => {
                                                    accionColaboradorEstado(
                                                      itemNatural.id_persona,
                                                      0
                                                    );
                                                    setActivo1(true);
                                                  }}
                                                  size={'md'}
                                                  borderRadius={'4px'}
                                                  fontSize={'1.5rem'}
                                                  background={
                                                    '#be0000'
                                                  }
                                                  color={'#ffffff'}
                                                  aria-label='Desactivar usuario'
                                                  icon={
                                                    <MdDoDisturbOff />
                                                  }
                                                />
                                              </Tooltip>
                                            )}

                                            {/* {p.estado != "Activo" ? (
                                    <Tooltip
                                      hasArrow
                                      borderRadius={"4px"}
                                      fontSize={"1.2rem"}
                                      label="Activar"
                                      bg="#292F36"
                                    >
                                      <IconButton
                                        onClick={() => {
                                          activar(p.id_colaborador);
                                          setActivo1(false);
                                        }}
                                        size={"md"}
                                        borderRadius={"4px"}
                                        fontSize={"1.5rem"}
                                        background={"#2b752d"}
                                        color={"#ffffff"}
                                        aria-label="Desactivar casa"
                                        icon={<FaCheckCircle />}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      hasArrow
                                      borderRadius={"4px"}
                                      fontSize={"1.2rem"}
                                      label="Desactivar"
                                      bg="#292F36"
                                    >
                                      <IconButton
                                        onClick={() => {
                                          darDeBaja(p.id_colaborador);
                                          setActivo1(true);
                                        }}
                                        size={"md"}
                                        borderRadius={"4px"}
                                        fontSize={"1.5rem"}
                                        background={"#be0000"}
                                        color={"#ffffff"}
                                        aria-label="Desactivar casa"
                                        icon={<MdDoDisturbOff />}
                                      />
                                    </Tooltip>
                                  )} */}
                                          </Flex>
                                        </Td>
                                      </Tr>
                                    )
                                  )}
                                </Tbody>
                                {/* {ListFiltered().map((p) => {
                        return (
                          
                        );
                      })} */}
                              </Table>
                            </TableContainer>

                            <Flex
                              marginTop={'0.5%'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              gap={'0.5%'}>
                              <Icon
                                onClick={() => {
                                  prevPage();
                                }}
                                color={'#13235b'}
                                cursor={'pointer'}
                                boxSize='16'
                                _hover={{
                                  color: 'gray !important'
                                }}
                                _dark={{
                                  color: '#f59f2c'
                                }}
                                as={IoMdArrowDropleftCircle}
                              />
                              <Icon
                                onClick={() => {
                                  nextPage();
                                }}
                                color={'#13235b'}
                                cursor={'pointer'}
                                boxSize='16'
                                _hover={{
                                  color: 'gray !important'
                                }}
                                _dark={{
                                  color: '#f59f2c'
                                }}
                                as={IoMdArrowDroprightCircle}
                              />
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    </TabPanel>
                    {/* --------------------------------------------------} */}
                    <TabPanel
                      w={'100%'}
                      bg={'transparent'}
                      borderRadius='10px'
                      p={'2%'}
                      // boxShadow='rgba(0, 0, 0, 0.35) 0px 5px 15px'
                    >
                      <Flex
                        width={'100%'}
                        direction={'column'}
                        gap={'25px'}>
                        <Flex
                          width={'100%'}
                          justifyContent={'space-between'}>
                          <Flex
                            width={{
                              '2xl': '30%',
                              xl: '30%',
                              lg: '40%',
                              md: '50%'
                            }}>
                            <Stack w={'100%'}>
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents='none'
                                  children={
                                    <Search2Icon
                                      color='#2a2e6e'
                                      marginLeft={'20%'}
                                      fontSize={'1.25rem'}
                                      marginTop={'60%'}
                                      _dark={{
                                        color: '#f59f2c'
                                      }}
                                    />
                                  }
                                />
                                <Input
                                  onChange={buscar}
                                  type='text'
                                  placeholder='Buscar'
                                  bg={'white'}
                                  height={'40px'}
                                  _focus={{
                                    borderColor: '#2a2e6e',
                                    boxShadow: 'none'
                                  }}
                                  color='#2a2e6e'
                                  fontSize={'1.9rem'}
                                  border={'1px solid'}
                                  borderColor={'#2a2e6e'}
                                  borderRadius='6px'
                                  _placeholder={{
                                    color: 'rgb(152, 152, 152)'
                                  }}
                                  _dark={{
                                    color: 'white',
                                    borderColor: '#686868',
                                    bg: '#686868'
                                  }}
                                />
                              </InputGroup>
                            </Stack>
                            {tiempo && (
                              <Button
                                onClick={exportAsExcelJuridico}
                                background={'#4caf50'}
                                color={'#fff'}
                                height={'40px'}
                                borderRadius={'5px'}
                                padding={'6px 16px'}
                                marginLeft={'15px'}
                                _hover={'none'}>
                                <Icon
                                  boxSize='10'
                                  as={RiFileExcel2Fill}
                                />
                              </Button>
                            )}
                          </Flex>
                        </Flex>

                        {/* --------------------------------------------------------- */}

                        <Flex
                          boxShadow={
                            'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'
                          }
                          background={'white'}
                          borderRadius={'18px'}
                          direction={'column'}
                          padding={'2% 4% 2% 4%'}>
                          <Flex
                            width={'100%'}
                            justifyContent={'right'}>
                            <Stack align='center' width={'auto'}>
                              <Button
                                onClick={onOpenPjuridica}
                                w={'100%'}
                                height={'40px'}
                                borderRadius='8px'
                                color={'white'}
                                fontSize={'1.6rem'}
                                textTransform={'uppercase'}
                                bg='#2a2e6e'
                                _hover={{
                                  color: 'white',
                                  bg: '#f59f2c'
                                }}>
                                Agregar
                              </Button>
                            </Stack>
                          </Flex>
                          {/* --------------------------------------------------------- */}
                          <Flex
                            direction={'column'}
                            w={'100%'}
                            h='100%'
                            justifyContent='center'
                            paddingTop={'2%'}>
                            <TableContainer w={'100%'}>
                              <Table variant='simple'>
                                <Thead>
                                  <Tr>
                                    <Th fontSize={'1.6rem'}>ID</Th>
                                    <Th
                                      fontSize={'1.6rem'}
                                      cursor={'pointer'}
                                      onClick={() => {
                                        var ordenDocument;
                                        if (!ordenarPjuridica) {
                                          ordenDocument =
                                            listColaboradorJuridico.sort(
                                              (a, b) =>
                                                a.nombres.localeCompare(
                                                  b.nombres
                                                )
                                            );
                                          setlistColaboradorJuridico(
                                            ordenDocument
                                          );
                                          setOrdenarPjuridica(true);
                                        } else {
                                          ordenDocument =
                                            listColaboradorJuridico.sort(
                                              (a, b) =>
                                                b.nombres.localeCompare(
                                                  a.nombres
                                                )
                                            );
                                          setlistColaboradorJuridico(
                                            ordenDocument
                                          );
                                          setOrdenarPjuridica(false);
                                        }
                                      }}>
                                      Nombres
                                    </Th>
                                    <Th
                                      fontSize={'1.6rem'}
                                      cursor={'pointer'}
                                      onClick={() => {
                                        var ordenDocument;
                                        if (!ordenarPjuridica) {
                                          ordenDocument =
                                            listColaboradorJuridico.sort(
                                              (a, b) =>
                                                a.apellidos.localeCompare(
                                                  b.apellidos
                                                )
                                            );
                                          setlistColaboradorJuridico(
                                            ordenDocument
                                          );
                                          setOrdenarPjuridica(true);
                                        } else {
                                          ordenDocument =
                                            listColaboradorJuridico.sort(
                                              (a, b) =>
                                                b.apellidos.localeCompare(
                                                  a.apellidos
                                                )
                                            );
                                          setlistColaboradorJuridico(
                                            ordenDocument
                                          );
                                          setOrdenarPjuridica(false);
                                        }
                                      }}>
                                      APELLIDOS
                                    </Th>
                                    <Th fontSize={'1.6rem'}>
                                      TELEFONO
                                    </Th>
                                    <Th
                                      fontSize={'1.6rem'}
                                      cursor={'pointer'}
                                      onClick={() => {
                                        var ordenDocument;
                                        if (!ordenarPjuridica) {
                                          ordenDocument =
                                            listColaboradorJuridico.sort(
                                              (a, b) =>
                                                a.correo.localeCompare(
                                                  b.correo
                                                )
                                            );
                                          setlistColaboradorJuridico(
                                            ordenDocument
                                          );
                                          setOrdenarPjuridica(true);
                                        } else {
                                          ordenDocument =
                                            listColaboradorJuridico.sort(
                                              (a, b) =>
                                                b.correo.localeCompare(
                                                  a.correo
                                                )
                                            );
                                          setlistColaboradorJuridico(
                                            ordenDocument
                                          );
                                          setOrdenarPjuridica(false);
                                        }
                                      }}>
                                      CORREO
                                    </Th>
                                    <Th fontSize={'1.6rem'}>
                                      TIPO DOCUMENTO
                                    </Th>
                                    <Th fontSize={'1.6rem'}>
                                      DOCUMENTO
                                    </Th>
                                    <Th fontSize={'1.6rem'}>
                                      ESTADO
                                    </Th>
                                    <Th isNumeric fontSize={'1.6rem'}>
                                      ACCIONES
                                    </Th>
                                  </Tr>
                                </Thead>

                                <Tbody fontSize={'1.5rem'}>
                                  {ListFilteredJuridico().map(
                                    (itemJuridico, index) => (
                                      <Tr
                                        key={itemJuridico.id_persona}>
                                        <Td>
                                          {index+1}
                                        </Td>
                                        <Td>
                                          {itemJuridico.nombres}
                                        </Td>
                                        <Td>
                                          {itemJuridico.apellidos}
                                        </Td>
                                        <Td>
                                          {itemJuridico.telefono}
                                        </Td>
                                        <Td>{itemJuridico.correo}</Td>
                                        <Td>
                                          {
                                            itemJuridico.tipo_documento
                                          }
                                        </Td>

                                        <Td>
                                          {itemJuridico.documento}
                                        </Td>
                                        <Td>{itemJuridico.estado}</Td>
                                        <Td
                                          fontWeight={'600'}
                                          fontFamily='monospace'
                                          isNumeric>
                                          <Flex
                                            justifyContent={'right'}
                                            gap={'4%'}>
                                            <Tooltip
                                              hasArrow
                                              borderRadius={'4px'}
                                              fontSize={'1.2rem'}
                                              label='Editar'
                                              bg='#292F36'>
                                              <IconButton
                                                onClick={() => {
                                                  setPersona(
                                                    itemJuridico
                                                  );
                                                  onOpenEditjuridica();
                                                }}
                                                size={'md'}
                                                borderRadius={'4px'}
                                                fontSize={'1.5rem'}
                                                background={'#f59f2c'}
                                                color={'#ffffff'}
                                                aria-label='Editar'
                                                icon={<RiEditFill />}
                                              />
                                            </Tooltip>

                                            {/* -----------INFORMACION------------- */}
                                            {itemJuridico
                                              .representantes
                                              .length != 0 && (
                                              <Tooltip
                                                hasArrow
                                                borderRadius={'4px'}
                                                fontSize={'1.2rem'}
                                                label='info'
                                                bg='#292F36'>
                                                <IconButton
                                                  onClick={() => {
                                                    onOpenInfoRe();
                                                    setInversionista(
                                                      itemJuridico.id_persona
                                                    );
                                                  }}
                                                  // onClick={onOpenInfoRe}
                                                  size={'md'}
                                                  borderRadius={'4px'}
                                                  fontSize={'1.5rem'}
                                                  background={
                                                    '#2a2e6e'
                                                  }
                                                  color={'#ffffff'}
                                                  aria-label='info'
                                                  icon={
                                                    <RiInformationFill />
                                                  }
                                                />
                                              </Tooltip>
                                            )}

                                            {/* ----------ASIGNAR REPRESENTANTE-------------- */}
                                            {itemJuridico
                                              .representantes
                                              .length == 0 && (
                                              <Tooltip
                                                hasArrow
                                                borderRadius={'4px'}
                                                fontSize={'1.2rem'}
                                                label='Asignar Representante'
                                                bg='#292F36'>
                                                <IconButton
                                                  onClick={() => {
                                                    asignateData.id_inversionista =
                                                      itemJuridico.id_persona;
                                                    onOpenARepresentante();
                                                  }}
                                                  size={'md'}
                                                  borderRadius={'4px'}
                                                  fontSize={'1.5rem'}
                                                  background={'Green'}
                                                  color={'#ffffff'}
                                                  aria-label='asignar representante '
                                                  icon={
                                                    <RiUserAddFill />
                                                  }
                                                />
                                              </Tooltip>
                                            )}

                                            {/* ----------activar-------------- */}

                                            {itemJuridico.estado !=
                                            'Activo' ? (
                                              <Tooltip
                                                hasArrow
                                                borderRadius={'4px'}
                                                fontSize={'1.2rem'}
                                                label='Activar'
                                                bg='#292F36'>
                                                <IconButton
                                                  onClick={() => {
                                                    accionColaboradorEstado(
                                                      itemJuridico.id_persona,
                                                      1
                                                    );
                                                    setActivo1(false);
                                                  }}
                                                  size={'md'}
                                                  borderRadius={'4px'}
                                                  fontSize={'1.5rem'}
                                                  background={
                                                    '#2b752d'
                                                  }
                                                  color={'#ffffff'}
                                                  aria-label='Desactivar casa'
                                                  icon={
                                                    <FaCheckCircle />
                                                  }
                                                />
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                hasArrow
                                                borderRadius={'4px'}
                                                fontSize={'1.2rem'}
                                                label='Desactivar'
                                                bg='#292F36'>
                                                <IconButton
                                                  onClick={() => {
                                                    accionColaboradorEstado(
                                                      itemJuridico.id_persona,
                                                      0
                                                    );
                                                    setActivo1(true);
                                                  }}
                                                  size={'md'}
                                                  borderRadius={'4px'}
                                                  fontSize={'1.5rem'}
                                                  background={
                                                    '#be0000'
                                                  }
                                                  color={'#ffffff'}
                                                  aria-label='Desactivar casa'
                                                  icon={
                                                    <MdDoDisturbOff />
                                                  }
                                                />
                                              </Tooltip>
                                            )}
                                            {/* ----------Fin  activar-------------- */}
                                          </Flex>
                                        </Td>
                                      </Tr>
                                    )
                                  )}
                                </Tbody>
                              </Table>
                            </TableContainer>

                            <Flex
                              marginTop={'0.5%'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              gap={'0.5%'}>
                              <Icon
                                onClick={() => {
                                  prevPage();
                                }}
                                color={'#13235b'}
                                cursor={'pointer'}
                                boxSize='16'
                                _hover={{
                                  color: 'gray !important'
                                }}
                                _dark={{
                                  color: '#f59f2c'
                                }}
                                as={IoMdArrowDropleftCircle}
                              />
                              <Icon
                                onClick={() => {
                                  nextPage();
                                }}
                                color={'#13235b'}
                                cursor={'pointer'}
                                boxSize='16'
                                _hover={{
                                  color: 'gray !important'
                                }}
                                _dark={{
                                  color: '#f59f2c'
                                }}
                                as={IoMdArrowDroprightCircle}
                              />
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    </TabPanel>

                    {/* --------------------------------------------------} */}
                    <TabPanel
                      w={'100%'}
                      bg={'transparent'}
                      borderRadius='10px'
                      p={'2%'}
                      // boxShadow='rgba(0, 0, 0, 0.35) 0px 5px 15px'
                    >
                      <Flex
                        width={'100%'}
                        direction={'column'}
                        gap={'25px'}>
                        <Flex
                          width={'100%'}
                          justifyContent={'space-between'}>
                          <Flex
                            width={{
                              '2xl': '30%',
                              xl: '30%',
                              lg: '40%',
                              md: '50%'
                            }}>
                            <Stack w={'100%'}>
                              <InputGroup>
                                <InputLeftElement
                                  pointerEvents='none'
                                  children={
                                    <Search2Icon
                                      color='#2a2e6e'
                                      marginLeft={'20%'}
                                      fontSize={'1.25rem'}
                                      marginTop={'60%'}
                                      _dark={{
                                        color: '#f59f2c'
                                      }}
                                    />
                                  }
                                />
                                <Input
                                  onChange={buscar}
                                  type='text'
                                  placeholder='Buscar'
                                  bg={'white'}
                                  height={'40px'}
                                  _focus={{
                                    borderColor: '#2a2e6e',
                                    boxShadow: 'none'
                                  }}
                                  color='#2a2e6e'
                                  fontSize={'1.9rem'}
                                  border={'1px solid'}
                                  borderColor={'#2a2e6e'}
                                  borderRadius='6px'
                                  _placeholder={{
                                    color: 'rgb(152, 152, 152)'
                                  }}
                                  _dark={{
                                    color: 'white',
                                    borderColor: '#686868',
                                    bg: '#686868'
                                  }}
                                />
                              </InputGroup>
                            </Stack>
                            {tiempo && (
                              <Button
                                onClick={exportExcelContrato}
                                background={'#4caf50'}
                                color={'#fff'}
                                height={'40px'}
                                borderRadius={'5px'}
                                padding={'6px 16px'}
                                marginLeft={'15px'}
                                _hover={'none'}>
                                <Icon
                                  boxSize='10'
                                  as={RiFileExcel2Fill}
                                />
                              </Button>
                            )}
                          </Flex>
                        </Flex>

                        {/* --------------------------------------------------------- */}

                        <Flex
                          boxShadow={
                            'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'
                          }
                          background={'white'}
                          borderRadius={'18px'}
                          direction={'column'}
                          padding={'2% 4% 2% 4%'}>
                          <Flex
                            width={'100%'}
                            justifyContent={'right'}>
                            <Stack align='center' width={'auto'}>
                              <Button
                                onClick={onOpenNContrato}
                                w={'100%'}
                                height={'40px'}
                                borderRadius='8px'
                                color={'white'}
                                fontSize={'1.6rem'}
                                textTransform={'uppercase'}
                                bg='#2a2e6e'
                                _hover={{
                                  color: 'white',
                                  bg: '#f59f2c'
                                }}>
                                NUEVO CONTRATO
                              </Button>
                            </Stack>
                          </Flex>
                          {/* --------------------------------------------------------- */}
                          <Flex
                            direction={'column'}
                            w={'100%'}
                            h='100%'
                            justifyContent='center'
                            paddingTop={'2%'}>
                            <TableContainer w={'100%'}>
                              <Table variant='simple'>
                                <Thead>
                                  <Tr>
                                    <Th fontSize={'1.6rem'}>
                                      TIPO </Th>
                                      <Th fontSize={'1.6rem'}>
                                      TIPO CONTRATO </Th>
                                    <Th
                                      fontSize={'1.6rem'}
                                      cursor={'pointer'}
                                      onClick={() => {
                                        var ordenDocument;
                                        if (!ordenarContrats) {
                                          ordenDocument =
                                            listContrato.sort(
                                              (a, b) =>
                                                a.fecha_firma.localeCompare(
                                                  b.fecha_firma
                                                )
                                            );
                                          setListContrato(
                                            ordenDocument
                                          );
                                          setOrdenarContrats(true);
                                        } else {
                                          ordenDocument =
                                            listContrato.sort(
                                              (a, b) =>
                                                b.fecha_firma.localeCompare(
                                                  a.fecha_firma
                                                )
                                            );
                                          setListContrato(
                                            ordenDocument
                                          );
                                          setOrdenarContrats(false);
                                        }
                                      }}>
                                      FECHA FIRMA
                                    </Th>
                                    <Th fontSize={'1.6rem'}>SALA</Th>
                                    <Th
                                      fontSize={'1.6rem'}
                                      cursor={'pointer'}
                                      onClick={() => {
                                        var ordenDocument;
                                        if (!ordenarContrats) {
                                          ordenDocument =
                                            listContrato.sort(
                                              (a, b) =>
                                                a.persona.apellidos.localeCompare(
                                                  b.persona.apellidos
                                                )
                                            );
                                          setListContrato(
                                            ordenDocument
                                          );
                                          setOrdenarContrats(true);
                                        } else {
                                          ordenDocument =
                                            listContrato.sort(
                                              (a, b) =>
                                                b.persona.apellidos.localeCompare(
                                                  a.persona.apellidos
                                                )
                                            );
                                          setListContrato(
                                            ordenDocument
                                          );
                                          setOrdenarContrats(false);
                                        }
                                      }}>
                                      INVERSIONISTA
                                    </Th>

                                    <Th fontSize={'1.6rem'}>
                                      C INICIO
                                    </Th>
                                    <Th fontSize={'1.6rem'}>
                                      C FINAL
                                    </Th>
                                    <Th fontSize={'1.6rem'}>
                                      S INICIO
                                    </Th>
                                    <Th fontSize={'1.6rem'}>
                                      S FINAL
                                    </Th>
                                    <Th fontSize={'1.6rem'}>
                                      CAPACITADOR
                                    </Th>
                                    <Th isNumeric fontSize={'1.6rem'}>
                                      ACCION
                                    </Th>
                                  </Tr>
                                </Thead>

                                <Tbody fontSize={'1.5rem'}>
                                  {ListFilteredContrato().map(
                                    (ItemContrato) => (
                                      <Tr
                                        key={
                                          ItemContrato.id_contratos
                                        }>
                                        <Td>
                                          {ItemContrato.tipo_relacion}
                                        </Td>
                                        <Td>
                                          {ItemContrato.tipo_contrato}
                                        </Td>
                                        <Td>
                                          {ItemContrato.fecha_firma}
                                        </Td>
                                        <Td>
                                          {ItemContrato.sala.nombre}
                                        </Td>
                                        <Td>
                                          {
                                            ItemContrato.persona
                                              .apellidos
                                          }{' '}
                                          {
                                            ItemContrato.persona
                                              .nombres
                                          }
                                        </Td>
                                        <Td>
                                          {
                                            ItemContrato.inicio_capacitacion
                                          }
                                        </Td>
                                        <Td>
                                          {
                                            ItemContrato.fin_capacitacion
                                          }
                                        </Td>
                                        <Td>
                                          {
                                            ItemContrato.inicio_software
                                          }
                                        </Td>

                                        <Td>
                                          {ItemContrato.fin_software}
                                        </Td>
                                        <Td>
                                          {ItemContrato.capacitador}
                                        </Td>

                                        <Td
                                          fontWeight={'600'}
                                          fontFamily='monospace'
                                          isNumeric>
                                          <Flex
                                            justifyContent={'right'}
                                            gap={'4%'}>
                                            <Tooltip
                                              hasArrow
                                              borderRadius={'4px'}
                                              fontSize={'1.2rem'}
                                              label='Editar'
                                              bg='#292F36'>
                                              <IconButton
                                                onClick={() => {
                                                  setDataContrato(
                                                    ItemContrato
                                                  );
                                                  onOpenEContrato();
                                                }}
                                                size={'md'}
                                                borderRadius={'4px'}
                                                fontSize={'1.5rem'}
                                                background={'#f59f2c'}
                                                color={'#ffffff'}
                                                aria-label='Editar'
                                                icon={<RiEditFill />}
                                              />
                                            </Tooltip>
                                          </Flex>
                                        </Td>
                                      </Tr>
                                    )
                                  )}
                                </Tbody>
                              </Table>
                            </TableContainer>

                            <Flex
                              marginTop={'0.5%'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              gap={'0.5%'}>
                              <Icon
                                onClick={() => {
                                  prevPage2();
                                }}
                                color={'#13235b'}
                                cursor={'pointer'}
                                boxSize='16'
                                _hover={{
                                  color: 'gray !important'
                                }}
                                _dark={{
                                  color: '#f59f2c'
                                }}
                                as={IoMdArrowDropleftCircle}
                              />
                              <Icon
                                onClick={() => {
                                  nextPage2();
                                }}
                                color={'#13235b'}
                                cursor={'pointer'}
                                boxSize='16'
                                _hover={{
                                  color: 'gray !important'
                                }}
                                _dark={{
                                  color: '#f59f2c'
                                }}
                                as={IoMdArrowDroprightCircle}
                              />
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>

      {/* ---------------Persona Natural--------- */}

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '50%', xl: '60%', lg: '80%', md: '95%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Añadir colaboradores
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex width={'100%'} justifyContent={'space-between'}>
              <Flex
                direction={'column'}
                alignItems='center'
                gap='12px'
                w={'55%'}
                h='100%'>
                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Apellidos:
                    </FormLabel>
                    <Input
                      value={ModelColaborador.apellidos}
                      onChange={changeColaborador}
                      name='apellidos'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Nombres:
                    </FormLabel>
                    <Input
                      value={ModelColaborador.nombres}
                      onChange={changeColaborador}
                      name='nombres'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                </Flex>

                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Teléfono:
                    </FormLabel>
                    <Input
                      value={ModelColaborador.telefono}
                      onChange={changeColaborador}
                      name='telefono'
                      fontSize={'1.8rem'}
                      h='35'
                      type='number'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Correo (Email):
                    </FormLabel>
                    <Input
                      value={ModelColaborador.correo}
                      onChange={changeColaborador}
                      name='correo'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                </Flex>
                <FormControl>
                  <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                    Clave Sistema:
                  </FormLabel>
                  <Input
                    value={ModelColaborador.clave_sistema}
                    onChange={changeColaborador}
                    name='clave_sistema'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                  />
                </FormControl>
                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Tipo de documento:
                    </FormLabel>
                    <Select
                      value={ModelColaborador.tipo_documento}
                      onChange={changeColaborador}
                      name='tipo_documento'
                      fontSize={'1.8rem'}
                      h='35'>
                      <option value='' disabled>
                        Tipo
                      </option>
                      <option value='DNI'>DNI</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Nro. de documento:
                    </FormLabel>
                    <Input
                      value={ModelColaborador.documento}
                      onChange={changeColaborador}
                      name='documento'
                      fontSize={'1.8rem'}
                      h='35'
                      type='number'
                    />
                  </FormControl>
                </Flex>

                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Fecha de nacimiento:
                    </FormLabel>
                    <Input
                      value={ModelColaborador.fecha_nacimiento}
                      onChange={changeColaborador}
                      name='fecha_nacimiento'
                      fontSize={'1.8rem'}
                      h='35'
                      type='date'
                    />
                  </FormControl>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems='center'
                gap='18px'
                w={'40%'}
                h='100%'>
                <Text fontWeight={'bold'} fontSize={'2rem'}>
                  Roles
                </Text>
                <Flex
                  paddingTop={'3%'}
                  justifyContent={'center'}
                  width={'92%'}
                  gap={'2%'}>
                  <Select
                    onChange={handleChangeRoles}
                    w={'68%'}
                    bg={'white'}
                    height={'30px'}
                    color='#2a2e6e'
                    fontSize={'1.6rem'}
                    border={'1px solid'}
                    value={r}
                    borderColor={'#2a2e6e'}
                    borderRadius='6px'
                    autoComplete='off'
                    _dark={{
                      color: 'white',
                      borderColor: '#686868',
                      bg: '#686868'
                    }}
                    required>
                    <option value='' disabled selected>
                      Roles del colaborador
                    </option>

                    {listRoles &&
                      listRoles.map((lr) => {
                        return (
                          <option value={`${lr.id_rol}-${lr.nombre}`}>
                            {lr.nombre}
                          </option>
                        );
                      })}
                  </Select>
                  <Stack align='center' width={'24%'}>
                    <Button
                      onClick={addRole}
                      w={'100%'}
                      height={'30px'}
                      borderRadius='8px'
                      color={'white'}
                      fontSize={'1.6rem'}
                      bg='#2a2e6e'
                      _hover={{
                        color: 'white',
                        bg: '#f59f2c'
                      }}>
                      Asignar
                    </Button>
                  </Stack>
                </Flex>
                <Flex w={'100%'} padding={'4%'}>
                  <TableContainer w={'100%'}>
                    <Table variant='simple'>
                      <Thead>
                        <Tr>
                          <Th
                            fontSize={'1.6rem'}
                            textAlign={'center'}>
                            ROL
                          </Th>
                          <Th
                            fontSize={'1.6rem'}
                            textAlign={'center'}>
                            ACCIONES
                          </Th>
                        </Tr>
                      </Thead>
                      {ModelColaborador.roles.map((p) => {
                        return (
                          <Tbody fontSize={'1.5rem'}>
                            <Tr>
                              <Td>{p.nombre}</Td>
                              <Td
                                fontWeight={'600'}
                                fontFamily='monospace'
                                isNumeric>
                                <Flex justifyContent={'center'}>
                                  <Button
                                    onClick={() => {
                                      eliminar(p.id);
                                    }}
                                    fontSize={'1.5rem'}
                                    fontFamily='monospace'
                                    textTransform={'uppercase'}>
                                    Eliminar
                                  </Button>
                                </Flex>
                              </Td>
                            </Tr>
                          </Tbody>
                        );
                      })}
                    </Table>
                  </TableContainer>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={'100%'}
              justifyContent={'center'}
              marginTop={'2%'}>
              <Button
                onClick={crearColaborador}
                borderRadius={'8px'}
                textTransform='uppercase'
                color={'white'}
                fontSize={'1.6rem'}
                bg='#2a2e6e'
                _hover={{
                  color: 'white',
                  bg: '#f59f2c'
                }}
                p='2%'>
                Agregar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* --------------------Editar Natural----------------- */}
      <Modal isOpen={isOpen2} onClose={onClose2} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '50%', xl: '70%', lg: '80%', md: '95%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Editar colaborador
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex width={'100%'} justifyContent={'space-between'}>
              <Flex
                direction={'column'}
                alignItems='center'
                gap='12px'
                w={'55%'}
                h='100%'>
                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Apellido:
                    </FormLabel>
                    <Input
                      value={Colaborador.apellidos}
                      onChange={handleChange}
                      name='apellidos'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Nombres:
                    </FormLabel>
                    <Input
                      value={Colaborador.nombres}
                      onChange={handleChange}
                      name='nombres'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                </Flex>

                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Teléfono:
                    </FormLabel>
                    <Input
                      value={Colaborador.telefono}
                      onChange={handleChange}
                      name='telefono'
                      fontSize={'1.8rem'}
                      h='35'
                      type='number'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Correo (Email):
                    </FormLabel>
                    <Input
                      value={Colaborador.correo}
                      onChange={handleChange}
                      name='correo'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                </Flex>
                <FormControl>
                  <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                    Clave Sistema:
                  </FormLabel>
                  <Input
                    value={Colaborador.clave_sistema}
                    onChange={handleChange}
                    name='clave_sistema'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                  />
                </FormControl>
                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Tipo de documento:
                    </FormLabel>
                    <Select
                      value={Colaborador.tipo_documento}
                      onChange={handleChange}
                      name='tipo_documento'
                      fontSize={'1.8rem'}
                      h='35'>
                      <option disabled value=''>
                        Tipo
                      </option>
                      <option value='DNI' selected>
                        DNI
                      </option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Nro. de documento:
                    </FormLabel>
                    <Input
                      value={Colaborador.documento}
                      onChange={handleChange}
                      name='documento'
                      fontSize={'1.8rem'}
                      h='35'
                      type='number'
                    />
                  </FormControl>
                </Flex>

                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Fecha de nacimiento:
                    </FormLabel>
                    <Input
                      value={Colaborador.fecha_nacimiento}
                      onChange={handleChange}
                      name='fecha_nacimiento'
                      fontSize={'1.8rem'}
                      h='35'
                      type='date'
                    />
                  </FormControl>
                </Flex>
              </Flex>
              <Flex
                direction={'column'}
                alignItems='center'
                gap='18px'
                w={'40%'}
                h='100%'>
                <Text fontWeight={'bold'} fontSize={'2rem'}>
                  Roles
                </Text>
                <Flex
                  paddingTop={'3%'}
                  justifyContent={'center'}
                  width={'92%'}
                  gap={'2%'}>
                  <Select
                    onChange={handleChangeRoles}
                    w={'68%'}
                    bg={'white'}
                    height={'30px'}
                    color='#2a2e6e'
                    fontSize={'1.6rem'}
                    border={'1px solid'}
                    borderColor={'#2a2e6e'}
                    borderRadius='6px'
                    autoComplete='off'
                    _dark={{
                      color: 'white',
                      borderColor: '#686868',
                      bg: '#686868'
                    }}
                    required>
                    <option value='' disabled selected>
                      Roles del colaborador
                    </option>
                    {listRoles &&
                      listRoles.map((lr) => {
                        return (
                          <option value={`${lr.id_rol}-${lr.nombre}`}>
                            {lr.nombre}
                          </option>
                        );
                      })}
                  </Select>
                  <Stack align='center' width={'24%'}>
                    <Button
                      onClick={roles}
                      w={'100%'}
                      height={'30px'}
                      borderRadius='8px'
                      color={'white'}
                      fontSize={'1.6rem'}
                      bg='#2a2e6e'
                      _hover={{
                        color: 'white',
                        bg: '#f59f2c'
                      }}>
                      Asignar
                    </Button>
                  </Stack>
                </Flex>
                <Flex w={'100%'} padding={'4%'}>
                  <TableContainer w={'100%'}>
                    <Table variant='simple'>
                      <Thead>
                        <Tr>
                          <Th
                            fontSize={'1.6rem'}
                            textAlign={'center'}>
                            ROL
                          </Th>
                          <Th
                            fontSize={'1.6rem'}
                            textAlign={'center'}>
                            ACCIONES
                          </Th>
                        </Tr>
                      </Thead>
                      {Colaborador.roles &&
                        Colaborador.roles.map((p) => {
                          return (
                            <Tbody fontSize={'1.5rem'}>
                              <Tr>
                                <Td>{p.nombre}</Td>
                                <Td
                                  fontWeight={'600'}
                                  fontFamily='monospace'
                                  isNumeric>
                                  <Flex justifyContent={'center'}>
                                    <Button
                                      onClick={() => {
                                        eliminarEdit(
                                          p.id,
                                          p.id_colaborador_rol
                                        );
                                      }}
                                      fontSize={'1.5rem'}
                                      fontFamily='monospace'
                                      textTransform={'uppercase'}>
                                      Eliminar
                                    </Button>
                                  </Flex>
                                </Td>
                              </Tr>
                            </Tbody>
                          );
                        })}
                    </Table>
                  </TableContainer>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={'100%'}
              justifyContent={'center'}
              marginTop={'2%'}>
              <Button
                onClick={handleSubmitUpdateColaborador}
                borderRadius={'8px'}
                textTransform='uppercase'
                color={'white'}
                fontSize={'1.6rem'}
                bg='#2a2e6e'
                _hover={{
                  color: 'white',
                  bg: '#f59f2c'
                }}
                p='2%'>
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* ---------------Persona juridica--------- */}
      <Modal
        isOpen={isOpenPjuridica}
        onClose={onClosePjuridica}
        isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '40%', xl: '40%', lg: '50%', md: '65%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Añadir Persona Juridica
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex width={'100%'} justifyContent={'space-between'}>
              <Flex
                direction={'column'}
                alignItems='center'
                gap='12px'
                w={'100%'}
                h='100%'>
                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Apellidos:
                    </FormLabel>
                    <Input
                      value={ModelPersona.apellidos}
                      onChange={changePersona}
                      name='apellidos'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Nombres:
                    </FormLabel>
                    <Input
                      value={ModelPersona.nombres}
                      onChange={changePersona}
                      name='nombres'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                </Flex>

                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Teléfono:
                    </FormLabel>
                    <Input
                      value={ModelPersona.telefono}
                      onChange={changePersona}
                      name='telefono'
                      fontSize={'1.8rem'}
                      h='35'
                      type='number'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Correo (Email):
                    </FormLabel>
                    <Input
                      value={ModelPersona.correo}
                      onChange={changePersona}
                      name='correo'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                </Flex>
                <FormControl>
                  <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                    Clave Sistema:
                  </FormLabel>
                  <Input
                    value={ModelPersona.clave_sistema}
                    onChange={changePersona}
                    name='clave_sistema'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                  />
                </FormControl>
                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Tipo de documento:
                    </FormLabel>
                    <Select
                      value={ModelPersona.tipo_documento}
                      onChange={changePersona}
                      name='tipo_documento'
                      //placeholder="RUC"
                      fontSize={'1.8rem'}
                      h='35'>
                      <option value=''>Tipo</option>
                      <option value='RUC'>RUC</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Nro. de RUC:
                    </FormLabel>
                    <Input
                      value={ModelPersona.documento}
                      onChange={changePersona}
                      name='documento'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                </Flex>

                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Fecha de nacimiento:
                    </FormLabel>
                    <Input
                      value={ModelPersona.fecha_nacimiento}
                      onChange={changePersona}
                      name='fecha_nacimiento'
                      fontSize={'1.8rem'}
                      h='35'
                      type='date'
                    />
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={'100%'}
              justifyContent={'center'}
              marginTop={'2%'}>
              <Button
                onClick={(e) => {
                  ModelPersona.roles.push({
                    id: 2,
                    nombre: 'INVERSIONISTA'
                  });
                  onClosePjuridica();
                  crearPersona(e);
                }}
                borderRadius={'8px'}
                textTransform='uppercase'
                color={'white'}
                fontSize={'1.6rem'}
                bg='#2a2e6e'
                _hover={{
                  color: 'white',
                  bg: '#f59f2c'
                }}
                p='2%'>
                Agregar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* --------------------Editar juridica----------------- */}
      <Modal
        isOpen={isOpenEditjuridica}
        onClose={onCloseEditjuridica}
        isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '40%', xl: '40%', lg: '55%', md: '65%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Editar Persona Juridica
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex width={'100%'} justifyContent={'space-between'}>
              <Flex
                direction={'column'}
                alignItems='center'
                gap='12px'
                w={'100%'}
                h='100%'>
                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Apellido:
                    </FormLabel>
                    <Input
                      value={updatePersona.apellidos}
                      onChange={changeUpdate}
                      name='apellidos'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Nombres:
                    </FormLabel>
                    <Input
                      value={updatePersona.nombres}
                      onChange={changeUpdate}
                      name='nombres'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                </Flex>

                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Teléfono:
                    </FormLabel>
                    <Input
                      value={updatePersona.telefono}
                      onChange={changeUpdate}
                      name='telefono'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Correo (Email):
                    </FormLabel>
                    <Input
                      value={updatePersona.correo}
                      onChange={changeUpdate}
                      name='correo'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                </Flex>

                <FormControl>
                  <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                    Clave Sistema:
                  </FormLabel>
                  <Input
                    value={updatePersona.clave_sistema}
                    onChange={changeUpdate}
                    name='clave_sistema'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                  />
                </FormControl>

                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Tipo de documento:
                    </FormLabel>
                    <Select
                      value={updatePersona.tipo_documento}
                      onChange={changeUpdate}
                      name='tipo_documento'
                      //placeholder="RUC"
                      fontSize={'1.8rem'}
                      h='35'>
                      <option value='' selected>
                        Tipo
                      </option>
                      <option value='DNI'>RUC</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Nro. de documento:
                    </FormLabel>
                    <Input
                      value={updatePersona.documento}
                      onChange={changeUpdate}
                      name='documento'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                </Flex>

                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Fecha de nacimiento:
                    </FormLabel>
                    <Input
                      value={updatePersona.fecha_nacimiento}
                      onChange={changeUpdate}
                      name='fecha_nacimiento'
                      fontSize={'1.8rem'}
                      h='35'
                      type='date'
                    />
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={'100%'}
              justifyContent={'center'}
              marginTop={'2%'}>
              <Button
                onClick={editarPersona}
                borderRadius={'8px'}
                textTransform='uppercase'
                color={'white'}
                fontSize={'1.6rem'}
                bg='#2a2e6e'
                _hover={{
                  color: 'white',
                  bg: '#f59f2c'
                }}
                p='2%'>
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* --------------------Info Representante ----------------- */}
      <Modal isOpen={isOpenInfoRe} onClose={onCloseInfoRe} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '60%', xl: '70%', lg: '80%', md: '95%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Info Representantes
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <TableContainer w={'100%'}>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th fontSize={'1.6rem'}>ID</Th>
                    <Th fontSize={'1.6rem'}>NOMBRES</Th>
                    <Th fontSize={'1.6rem'}>TELEFONO</Th>
                    <Th fontSize={'1.6rem'}>CORREO</Th>
                    <Th fontSize={'1.6rem'}>TIPO DOCUMENTO</Th>
                    <Th fontSize={'1.6rem'}>DOCUMENTO</Th>
                    <Th isNumeric fontSize={'1.6rem'}>
                      ACCIONES
                    </Th>
                  </Tr>
                </Thead>

                <Tbody fontSize={'1.5rem'}>
                  {ListFiltered()?.map((p) => (
                    <Tr
                    // key={p.id_persona}
                    >
                      <Td>{p.id_persona}</Td>
                      <Td>{p.apellidos + ' ' + p.nombres}</Td>
                      <Td>{p.telefono}</Td>
                      <Td>{p.correo}</Td>
                      <Td>{p.tipo_documento}</Td>
                      <Td>{p.documento}</Td>

                      <Td
                        fontWeight={'600'}
                        fontFamily='monospace'
                        isNumeric>
                        <Flex justifyContent={'right'} gap={'4%'}>
                          <Tooltip
                            hasArrow
                            borderRadius={'4px'}
                            fontSize={'1.2rem'}
                            label='Quitar'
                            bg='#292F36'>
                            <IconButton
                              onClick={() => {
                                // onOpen2();
                                quitar(p.id);
                              }}
                              size={'md'}
                              borderRadius={'4px'}
                              fontSize={'1.5rem'}
                              background={'red'}
                              color={'#ffffff'}
                              aria-label='quitar'
                              icon={<MdCancel />}
                            />
                          </Tooltip>
                          {/* -------------- */}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={'100%'}
              justifyContent={'center'}
              marginTop={'2%'}></Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* --------------------Asignar Representante ----------------- */}
      <Modal
        isOpen={isOpenARepresentante}
        onClose={onCloseARepresentante}
        isCentered>
        <ModalOverlay />
        <ModalContent
          sx={{
            '@media only screen and (min-width: 1200px) and (max-width: 1366px)':
              {
                maxW: '80%'
              }
          }}
          maxW={{ '2xl': '60%', xl: '75%', lg: '95%', md: '95%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Asignar Representantes
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex direction={'column'} gap={'20px'}>
              <Flex
                width={{
                  '2xl': '30%',
                  xl: '30%',
                  lg: '40%',
                  md: '50%'
                }}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    children={
                      <Search2Icon
                        color='#2a2e6e'
                        marginLeft={'20%'}
                        fontSize={'1.25rem'}
                        marginTop={'60%'}
                        _dark={{
                          color: '#f59f2c'
                        }}
                      />
                    }
                  />
                  <Input
                    onChange={buscar2}
                    type='text'
                    placeholder='Buscar'
                    bg={'white'}
                    height={'40px'}
                    _focus={{
                      borderColor: '#2a2e6e',
                      boxShadow: 'none'
                    }}
                    color='#2a2e6e'
                    fontSize={'1.9rem'}
                    border={'1px solid'}
                    borderColor={'#2a2e6e'}
                    borderRadius='6px'
                    _placeholder={{
                      color: 'rgb(152, 152, 152)'
                    }}
                    _dark={{
                      color: 'white',
                      borderColor: '#686868',
                      bg: '#686868'
                    }}
                  />
                </InputGroup>
              </Flex>

              <TableContainer w={'100%'}>
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th fontSize={'1.6rem'}>APELLIDOS</Th>
                      <Th fontSize={'1.6rem'}>NOMBRES</Th>
                      <Th fontSize={'1.6rem'}>TELEFONO</Th>
                      <Th fontSize={'1.6rem'}>CORREO</Th>
                      <Th fontSize={'1.6rem'}>TIPO DOCUMENTO</Th>
                      <Th fontSize={'1.6rem'}>DOCUMENTO</Th>
                      <Th isNumeric fontSize={'1.6rem'}>
                        ACCIONES
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody fontSize={'1.5rem'}>
                    {ListFiltered2().map((a) => (
                      <Tr>
                        <Td>{a.apellidos}</Td>
                        <Td>{a.nombres}</Td>
                        <Td>{a.telefono}</Td>
                        <Td>{a.correo}</Td>
                        <Td>{a.tipo_documento}</Td>
                        <Td>{a.documento}</Td>
                        <Td
                          fontWeight={'600'}
                          fontFamily='monospace'
                          isNumeric>
                          <Flex justifyContent={'right'} gap={'4%'}>
                            <Tooltip
                              hasArrow
                              borderRadius={'4px'}
                              fontSize={'1.2rem'}
                              label='Asignar'
                              bg='#292F36'>
                              <IconButton
                                onClick={() => {
                                  handleAsignateRepresent(
                                    a.id_persona
                                  );
                                }}
                                size={'md'}
                                borderRadius={'4px'}
                                fontSize={'1.5rem'}
                                background={'#2b752d'}
                                color={'#ffffff'}
                                aria-label='Asignar'
                                icon={<MdGroupAdd />}
                              />
                            </Tooltip>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Flex
                  marginTop={'0.5%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  gap={'0.5%'}>
                  <Icon
                    onClick={() => {
                      prevPage2();
                    }}
                    color={'#13235b'}
                    cursor={'pointer'}
                    boxSize='16'
                    _hover={{
                      color: 'gray !important'
                    }}
                    _dark={{
                      color: '#f59f2c'
                    }}
                    as={IoMdArrowDropleftCircle}
                  />
                  <Icon
                    onClick={() => {
                      nextPage2();
                    }}
                    color={'#13235b'}
                    cursor={'pointer'}
                    boxSize='16'
                    _hover={{
                      color: 'gray !important'
                    }}
                    _dark={{
                      color: '#f59f2c'
                    }}
                    as={IoMdArrowDroprightCircle}
                  />
                </Flex>
              </TableContainer>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* --------------------Nuevo Contrato ----------------- */}
      <Modal
        isOpen={isOpenNContrato}
        onClose={onCloseNContrato}
        isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '40%', xl: '40%', lg: '60%', md: '70%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Nuevo Contrato
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex gap={'5%'}>
              <Flex w={'50%'} direction={'column'} gap={'10px'}>
                <Text fontSize={'3xl'} fontWeight={'600'}>
                  DATOS GENERALES
                </Text>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Fecha de Firma:
                  </FormLabel>
                  <Input
                    name='fecha_firma'
                    fontSize={'1.8rem'}
                    h='35'
                    type='date'
                    onChange={handleChangeContrato}
                    value={dataContrato.fecha_firma}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Tipo Contrato:
                  </FormLabel>
                  <Select
                    name='tipo_contrato'
                    w='100%'
                    fontSize={'1.6rem'}
                    height={'40px'}
                    fontFamily='monospace'
                    fontWeight={'600'}
                    placeholder='Seleccionar'
                    textTransform='uppercase'
                    variant='filled'
                    onChange={handleChangeContrato}
                    value={dataContrato.tipo_contrato}>
                    <option value='INDIVIDUAL'>INDIVIDUAL</option>
                    <option value='SOCIEDAD'>SOCIEDAD</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Tipo:
                  </FormLabel>
                  <Select
                    name='tipo_relacion'
                    w='100%'
                    fontSize={'1.6rem'}
                    height={'40px'}
                    fontFamily='monospace'
                    fontWeight={'600'}
                    placeholder='Seleccionar'
                    textTransform='uppercase'
                    variant='filled'
                    onChange={handleChangeContrato}
                    value={dataContrato.tipo_relacion}>
                    <option value='NUEVO'>NUEVO</option>
                    <option value='RENOVACION'>RENOVACION</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Sala:
                  </FormLabel>
                  <Select
                    name='id_sala'
                    w='100%'
                    fontSize={'1.6rem'}
                    height={'40px'}
                    fontFamily='monospace'
                    fontWeight={'600'}
                    //placeholder="Seleccionar"
                    textTransform='uppercase'
                    variant='filled'
                    onChange={handleChangeContrato}
                    value={dataContrato.id_sala}>
                    <option value='' selected>
                      Selecciona
                    </option>
                    {listSalas &&
                      listSalas.map((itemSala) => (
                        <option value={itemSala.id_sala}>
                          {itemSala.nombre}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Inversionista:
                  </FormLabel>
                  <Select
                    name='id_persona'
                    w='100%'
                    fontSize={'1.6rem'}
                    height={'40px'}
                    fontFamily='monospace'
                    fontWeight={'600'}
                    //placeholder="Seleccionar"
                    textTransform='uppercase'
                    variant='filled'
                    onChange={handleChangeContrato}
                    value={dataContrato.id_persona}>
                    <option value='' selected>
                      Selecciona
                    </option>
                    {listInversionista &&
                      listInversionista.map((itemData) => (
                        <option value={itemData.id_persona}>
                          {itemData.apellidos} {itemData.nombres}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Capacitador:
                  </FormLabel>
                  <Input
                    name='capacitador'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={handleChangeContrato}
                    value={dataContrato.capacitador}
                  />
                </FormControl>
              </Flex>
              {/* -------------------------------------------------------------------------- */}
              <Flex
                w={'50%'}
                direction={'column'}
                gap={'10px'}
                justifyContent={'space-between'}>
                <Flex w={'100%'} direction={'column'}>
                  <Text fontSize={'3xl'} fontWeight={'600'}>
                    CAPACITACION
                  </Text>
                  <FormControl>
                    <FormLabel fontSize={'2rem'} fontWeight='500'>
                      Fecha Inicial:
                    </FormLabel>
                    <Input
                      name='inicio_capacitacion'
                      fontSize={'1.8rem'}
                      h='35'
                      type='date'
                      onChange={handleChangeContrato}
                      value={dataContrato.inicio_capacitacion}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'2rem'} fontWeight='500'>
                      Fecha Final:
                    </FormLabel>
                    <Input
                      name='fin_capacitacion'
                      fontSize={'1.8rem'}
                      h='35'
                      type='date'
                      onChange={handleChangeContrato}
                      value={dataContrato.fin_capacitacion}
                    />
                  </FormControl>
                </Flex>
                <Flex w={'100%'} direction={'column'} gap={'10px'}>
                  <Text fontSize={'3xl'} fontWeight={'600'}>
                    SOFTWARE
                  </Text>
                  <FormControl>
                    <FormLabel fontSize={'2rem'} fontWeight='500'>
                      Fecha Inicial:
                    </FormLabel>
                    <Input
                      name='inicio_software'
                      fontSize={'1.8rem'}
                      h='35'
                      type='date'
                      onChange={handleChangeContrato}
                      value={dataContrato.inicio_software}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'2rem'} fontWeight='500'>
                      Fecha Final:
                    </FormLabel>
                    <Input
                      name='fin_software'
                      fontSize={'1.8rem'}
                      h='35'
                      type='date'
                      onChange={handleChangeContrato}
                      value={dataContrato.fin_software}
                    />
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={'100%'}
              justifyContent={'center'}
              marginTop={'2%'}>
              <Button
                onClick={createContrato}
                borderRadius={'8px'}
                textTransform='uppercase'
                color={'white'}
                fontSize={'1.6rem'}
                bg='#2a2e6e'
                _hover={{
                  color: 'white',
                  bg: '#f59f2c'
                }}
                p='2%'>
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* --------------------Editar Contrato ----------------- */}
      <Modal
        isOpen={isOpenEContrato}
        onClose={onCloseEContrato}
        isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '40%', xl: '40%', lg: '60%', md: '75%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Editar Contrato
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex gap={'5%'}>
              <Flex w={'50%'} direction={'column'} gap={'10px'}>
                <Text fontSize={'3xl'} fontWeight={'600'}>
                  DATOS GENERALES
                </Text>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Fecha de Firma:
                  </FormLabel>
                  <Input
                    name='fecha_firma'
                    fontSize={'1.8rem'}
                    h='35'
                    type='date'
                    onChange={handleChangeContrato}
                    value={dataContrato.fecha_firma}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Tipo Contrato:
                  </FormLabel>
                  <Select
                    name='tipo_contrato'
                    w='100%'
                    fontSize={'1.6rem'}
                    height={'40px'}
                    fontFamily='monospace'
                    fontWeight={'600'}
                    textTransform='uppercase'
                    onChange={handleChangeContrato}
                    value={dataContrato.tipo_contrato}
                    variant='filled'>
                    <option value='INDIVIDUAL'>INDIVIDUAL</option>
                    <option value='SOCIEDAD'>SOCIEDAD</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Tipo :
                  </FormLabel>
                  <Select
                    name='tipo_relacion'
                    w='100%'
                    fontSize={'1.6rem'}
                    height={'40px'}
                    fontFamily='monospace'
                    fontWeight={'600'}
                    textTransform='uppercase'
                    onChange={handleChangeContrato}
                    value={dataContrato.tipo_relacion}
                    variant='filled'>
                    <option value='NUEVO'>NUEVO</option>
                    <option value='RENOVACION'>RENOVACION</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Sala:
                  </FormLabel>
                  <Select
                    name='id_sala'
                    w='100%'
                    fontSize={'1.6rem'}
                    height={'40px'}
                    fontFamily='monospace'
                    fontWeight={'600'}
                    //placeholder="Seleccionar"
                    textTransform='uppercase'
                    variant='filled'
                    onChange={handleChangeContrato}
                    value={dataContrato.id_sala}>
                    {listSalas &&
                      listSalas.map((itemSala) => (
                        <option
                          value={itemSala.id_sala}
                          selected={
                            dataContrato.id_sala == itemSala.id_sala
                          }>
                          {itemSala.nombre}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Inversionista:
                  </FormLabel>
                  <Select
                    name='id_persona'
                    w='100%'
                    fontSize={'1.6rem'}
                    height={'40px'}
                    fontFamily='monospace'
                    fontWeight={'600'}
                    placeholder='Seleccionar'
                    textTransform='uppercase'
                    variant='filled'
                    value={dataContrato.id_persona}
                    onChange={handleChangeContrato}>
                    {listInversionista &&
                      listInversionista.map((itemData) => (
                        <option
                          value={itemData.id_persona}
                          selected={
                            dataContrato.id_inversionista ==
                            itemData.id_persona
                          }>
                          {itemData.apellidos} {itemData.nombres}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Capacitador:
                  </FormLabel>
                  <Input
                    name='capacitador'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={handleChangeContrato}
                    value={dataContrato.capacitador}
                  />
                </FormControl>
              </Flex>
              {/* -------------------------------------------------------------------------- */}
              <Flex
                w={'50%'}
                direction={'column'}
                gap={'10px'}
                justifyContent={'space-between'}>
                <Flex w={'100%'} direction={'column'}>
                  <Text fontSize={'3xl'} fontWeight={'600'}>
                    CAPACITACION
                  </Text>
                  <FormControl>
                    <FormLabel fontSize={'2rem'} fontWeight='500'>
                      Fecha de Inicial:
                    </FormLabel>
                    <Input
                      name='inicio_capacitacion'
                      fontSize={'1.8rem'}
                      h='35'
                      type='date'
                      onChange={handleChangeContrato}
                      value={dataContrato.inicio_capacitacion}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'2rem'} fontWeight='500'>
                      Fecha de Final:
                    </FormLabel>
                    <Input
                      name='fin_capacitacion'
                      fontSize={'1.8rem'}
                      h='35'
                      type='date'
                      onChange={handleChangeContrato}
                      value={dataContrato.fin_capacitacion}
                    />
                  </FormControl>
                </Flex>
                <Flex w={'100%'} direction={'column'} gap={'10px'}>
                  <Text fontSize={'3xl'} fontWeight={'600'}>
                    SOFTWARE
                  </Text>
                  <FormControl>
                    <FormLabel fontSize={'2rem'} fontWeight='500'>
                      Fecha de Inicial:
                    </FormLabel>
                    <Input
                      name='inicio_software'
                      fontSize={'1.8rem'}
                      h='35'
                      type='date'
                      onChange={handleChangeContrato}
                      value={dataContrato.inicio_software}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'2rem'} fontWeight='500'>
                      Fecha de Final:
                    </FormLabel>
                    <Input
                      name='fin_software'
                      fontSize={'1.8rem'}
                      h='35'
                      type='date'
                      onChange={handleChangeContrato}
                      value={dataContrato.fin_software}
                    />
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={'100%'}
              justifyContent={'center'}
              marginTop={'2%'}>
              <Button
                onClick={updateContrato}
                borderRadius={'8px'}
                textTransform='uppercase'
                color={'white'}
                fontSize={'1.6rem'}
                bg='#2a2e6e'
                _hover={{
                  color: 'white',
                  bg: '#f59f2c'
                }}
                p='2%'>
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* --------------------roles----------------- */}
      {currentModalData && (
        <Modal isOpen={isOpen3} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <p>
                Roles:{' '}
                {currentModalData.rols
                  .map((rm1) => rm1.nombre)
                  .join(', ')}
              </p>
              Agrega más detalles del modal aquí
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='blue' onClick={closeModal}>
                Cerrar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default Colaboradores;
