import { mode } from '@chakra-ui/theme-tools';

export const globalStyles = {
  colors: {
    gray: {
      700: '#1f2733'
    },
    orange: {
      500: '#F39D2F',
      200: '#F39D2F'
    },
    blue: {
      100: '#13235B'
    }
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode('#d8d8d8', 'gray.800')(props),
        fontFamily: "'Roboto', sans-serif"
      },
      html: {
        fontFamily: "'Roboto', sans-serif"
      }
    })
  }
};
