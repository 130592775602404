import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  Textarea,
  IconButton,
  Button,
  useDisclosure,
  Stack,
  Text,
  Icon,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  FormControl,
  FormLabel,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  ControlBox,
} from "@chakra-ui/react";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { BsFillCalendar2CheckFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { Search2Icon } from "@chakra-ui/icons";
import { RiFileExcel2Fill } from "react-icons/ri";
import { MdAssignmentAdd } from "react-icons/md";
import SidebarOperators from "../../../components/SidebarOperators";
import { host3 } from "../../../utils/constantes";
import axios from "axios";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { utils, writeFile } from "xlsx";

import {
  IoMdArrowDroprightCircle,
  IoMdArrowDropleftCircle,
} from "react-icons/io";
import listCategories from "../../../sample/listCategories.json";

function MBanco() {
  let history = useHistory();
  const token = localStorage.getItem("tokenadmin");
  const { id_persona, rol } = jwt_decode(token);

  if (!token) {
    history.push("/");
  }

  useEffect(() => {
    if (token) {
      if (rol.includes("TMONEY")) {
        history.push("/salas/MSalas");
      }
    }
  }, []);
  if (!token) {
    history.push("/");
  }

  const [isCaja, setCaja] = useState(false);

  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        break;
    }
  };
  const tabla4 = [
    {
      id: "1",
      fecha: "12/04/2002",
      cuenta: "2514562589521452254",
      monto: "100",
      moneda: "S/.",
      comentario: "De lo mejor en esto",
    },
    {
      id: "2",
      fecha: "12/04/2002",
      cuenta: "2514562589521452254",
      monto: "100",
      moneda: "S/.",
      comentario: "De lo mejor en esto",
    },
    {
      id: "3",
      fecha: "12/04/2002",
      cuenta: "2514562589521452254",
      monto: "100",
      moneda: "S/.",
      comentario: "De lo mejor en esto",
    },
    {
      id: "4",
      fecha: "12/04/2002",
      cuenta: "2514562589521452254",
      monto: "100",
      moneda: "S/.",
      comentario: "De lo mejor en esto",
    },
    {
      id: "5",
      fecha: "12/04/2002",
      cuenta: "2514562589521452254",
      monto: "100",
      moneda: "S/.",
      comentario: "De lo mejor en esto",
    },
  ];

  const [search, setSearch] = useState("");
  const [tiempo, setTiempo] = useState(false);

  //------------------- LISTAR SALA ---------------------------------------------------

  const [listSala, setListSala] = useState([]);
  const [listSalaFilter, setListSalaFilter] = useState([]);

  const fetchSalas = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/personas/${token}/list-hall/${id_persona}`
      );
      setListSala(data);
      setListSalaFilter(data);
    } catch (error) {
      setListSala([]);
    }
  };

  //--------------------- ESTADO PARA MODALES ----------------------------------------

  const [dataMovement, setDataMovement] = useState({
    id_sala: "",
    id_cuenta_origen: "",
    id_cuenta_destino: "",
    tipo: "",
    monto_origen: "",
    id_moneda_origen: "",
    id_moneda_destino: "",
    monto_destino: "",
    comision: "",
    tipo_cambio: 0,
    comentario: "",
    ganancia: 0,
    perdida: 0,
  });

  const clearData = () => {
    setDataMovement({
      id_sala: "",
      id_cuenta_origen: "",
      id_cuenta_destino: "",
      tipo: "",
      monto_origen: "",
      id_moneda_origen: "",
      id_moneda_destino: "",
      monto_destino: "",
      comision: "",
      tipo_cambio: "",
      comentario: "",
      ganancia: 0,
      perdida: 0,
    });
  };

  const handleChangeMovimiento = (e) => {
    setDataMovement({
      ...dataMovement,
      [e.target.name]: e.target.value,
    });
  };

  //-------------------- BUSCAR POR SALA ------------------------------------------

  const [searchSala, setSearchSala] = useState("");

  useEffect(() => {
    if (searchSala == "all") {
      dataMovement.id_sala = "";
      Modelgastos.id_sala = "";
      setFilterListMoneda(listMoneda);
      setFilterListMovimiento(listMovimientos);
      setFilterListTransferencia(listTransferencia);
      setFilterListDivisa(listDivisa);
      setFilterListGasto(listgastos);
      setFilteredListPendientes(listPendientes);
      // Sumar todos los pendientes
      let montoPendiente = 0.0;

      totalPendiente.map((itemPendiente) => {
        montoPendiente =
          parseFloat(montoPendiente) +
          parseFloat(itemPendiente.pendiente_total);
      });

      setFilterTotalPendiente({ pendiente_total: montoPendiente });
      return;
    }

    dataMovement.id_sala = searchSala;
    Modelgastos.id_sala = searchSala;
    setFilterListMoneda(
      listMoneda.filter((itemFilter) => itemFilter.id_sala == searchSala)
    );
    setFilterListMovimiento(
      listMovimientos.filter((itemFilter) => itemFilter.id_sala == searchSala)
    );
    setFilterListTransferencia(
      listTransferencia.filter((itemFilter) => itemFilter.id_sala == searchSala)
    );
    setFilterListDivisa(
      listDivisa.filter((itemFilter) => itemFilter.id_sala == searchSala)
    );
    setFilteredListPendientes(
      listPendientes.filter((itemFilter) => itemFilter.id_sala == searchSala)
    );
    setFilterListGasto(
      listgastos.filter((itemFilter) => itemFilter.sala.id_sala == searchSala)
    );
    setFilterTotalPendiente(
      totalPendiente.find((itemFilter) => itemFilter.id_sala == searchSala)
    );
  }, [searchSala]);

  // ---------------------------- LISTAR MONEDA -------------------------------------

  const [listMoneda, setListMoneda] = useState([]);
  const [filterListMoneda, setFilterListMoneda] = useState([]);

  const fetchMoneda = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/monedas/${token}/listar/${id_persona}`
      );
      let monedas = [];

      data.map((itemData) => {
        itemData.sala.monedas.map((itemMoneda) =>
          monedas.push({
            id_sala: itemData.sala.id_sala,
            sala: itemData.sala.nombre,
            id_moneda: itemMoneda.id_moneda,
            nombre: itemMoneda.nombre,
          })
        );
      });

      setListMoneda(monedas);
      setFilterListMoneda(monedas);
    } catch (error) {
      setListMoneda([]);
    }
  };

  //------------------- LISTAR CUENTAS BANCO ------------------------------------------

  const [listbakAccount, setListbakAccount] = useState([]);

  const fetchBankAccount = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/cuentas_bancarias/${token}`
      );
      setListbakAccount(
        data.filter((itemFilter) => itemFilter.id_sala == dataMovement.id_sala)
      );
    } catch (error) {
      setListbakAccount([]);
    }
  };

  useEffect(() => {
    fetchBankAccount();
  }, [dataMovement.id_sala]);

  // -----------------LISTAR DEPOSITO/RETIRO---------------------------------------------

  const [listMovimientos, setListMovimientos] = useState([]);
  const [filterListMovimiento, setFilterListMovimiento] = useState([]);
  const [searchDeposito, setSearchDeposito] = useState({
    name: "",
    date_initial: "",
    date_end: "",
  });

  const fetchMovimientoDepositoRetiro = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/movimientos-bancos/${token}/${id_persona}`
      );
      setListMovimientos(
        data.filter(
          (itemFilter) =>
            itemFilter.tipo != "TRANSFERENCIA" && itemFilter.tipo != "DIVISA"
        )
      );
      setFilterListMovimiento(listMovimientos);
    } catch (error) {
      setListMovimientos([]);
    }
  };

  const ListFiltered = () => {
    if (searchDeposito.name != "") {
      var filtered = filterListMovimiento.filter(
        (p) =>
          p.cuenta_destino.includes(searchDeposito.name.toUpperCase()) ||
          p.tipo.includes(searchDeposito.name.toUpperCase()) ||
          p.moneda_origen.includes(searchDeposito.name.toUpperCase())
      );
      return filtered.slice(currentPage2, currentPage2 + 13);
    } else {
      let list =
        searchSala != ""
          ? filterListMovimiento?.slice(currentPage2, currentPage2 + 13)
          : [];
      return list;
    }
  };

  // ----------------------- BUSCAR ----------------------------------------

  const handleChangeSearchDeposito = (e) => {
    setSearchDeposito({
      ...searchDeposito,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickSearchDeposito = () => {
    if (searchSala == "") {
      mensaje("Primero elija un filtro", "top-right", 2);
      return;
    }

    if (searchDeposito.date_initial == "" || searchDeposito.date_end == "") {
      mensaje("El rango de fecha debe estar completo", "top-right", 2);
      return;
    }

    const fechaInicio = new Date(searchDeposito.date_initial); // Establece la fecha de inicio
    const fechaFin = new Date(searchDeposito.date_end); // Establece la fecha de fin

    const filteredListMovimiento = listMovimientos.filter((itemFilter) => {
      const fechaRegistro = new Date(itemFilter.fecha_registro);
      return fechaRegistro >= fechaInicio && fechaRegistro <= fechaFin;
    });

    const filteredSala = filteredListMovimiento.filter(
      (itemFilter) => itemFilter.id_sala == searchSala
    );

    setFilterListMovimiento(
      searchSala == "all" ? filteredListMovimiento : filteredSala
    );
  };

  //----------------------------- CREAR -------------------------------------

  const handleSubmitDepositoRetiro = async () => {
    try {
      const getSala = searchSala;
      dataMovement.comision =
        dataMovement.comision != "" ? dataMovement.comision : "0";
      const { status, data } = await axios.post(
        `${host3}/api/movimientos-bancos/${token}/movimiento/${dataMovement.tipo}`,
        dataMovement
      );
      if (status == 201) {
        setSearchSala("");
        mensaje(data.message, "top-right", 1);
        onClose();
        clearData();
        fetchMovimientoDepositoRetiro();
        setTimeout(() => {
          setSearchSala(getSala);
        }, 50);
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  //----------------------------ELIMINAR DEPOSITO/RETIRO------------------------------------

  const deleteDeposito = async (id_movimiento) => {
    try {
      const getSala = searchSala;
      const { status, data } = await axios.delete(
        `${host3}/api/movimientos-bancos/${token}/${id_movimiento}`
      );
      if (status == 201) {
        setSearchSala("");
        mensaje(data.message, "top-right", 1);
        fetchMovimientoDepositoRetiro();
        setTimeout(() => {
          setSearchSala(getSala);
        }, 50);
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  // ------------------------LISTAR TRANSFERENCIA----------------------------------------
  const [listTransferencia, setListTransferencia] = useState([]);
  const [filterListTransferencia, setFilterListTransferencia] = useState([]);
  const [searchTransferencia, setSearchTransferencia] = useState({
    name: "",
    date_initial: "",
    date_end: "",
  });

  const fetchTransferencia = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/movimientos-bancos/${token}/${id_persona}`
      );
      setListTransferencia(
        data.filter((itemFilter) => itemFilter.tipo == "TRANSFERENCIA")
      );
      setFilterListTransferencia(listTransferencia);
    } catch (error) {
      setListTransferencia([]);
    }
  };

  const ListFiltered2 = () => {
    if (searchTransferencia.name != "") {
      var filtered = filterListTransferencia.filter(
        (p) =>
          p.cuenta_origen.includes(searchTransferencia.name.toUpperCase()) ||
          p.cuenta_destino.includes(searchTransferencia.name.toUpperCase()) ||
          p.moneda_origen.includes(searchTransferencia.name.toUpperCase())
      );
      return filtered.slice(currentPage2, currentPage2 + 13);
    } else {
      let list =
        searchSala != ""
          ? filterListTransferencia?.slice(currentPage2, currentPage2 + 13)
          : [];
      return list;
    }
  };

  // ----------------------- BUSCAR ----------------------------------------

  const handleChangeSearchTransferencia = (e) => {
    setSearchTransferencia({
      ...searchTransferencia,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickSearchTransferencia = () => {
    if (searchSala == "") {
      mensaje("Primero elija un filtro", "top-right", 2);
      return;
    }

    if (
      searchTransferencia.date_initial == "" ||
      searchTransferencia.date_end == ""
    ) {
      mensaje("El rango de fecha debe estar completo", "top-right", 2);
      return;
    }

    const fechaInicio = new Date(searchTransferencia.date_initial); // Establece la fecha de inicio
    const fechaFin = new Date(searchTransferencia.date_end); // Establece la fecha de fin

    const filteredListMovimiento = listTransferencia.filter((itemFilter) => {
      const fechaRegistro = new Date(itemFilter.fecha_registro);
      return fechaRegistro >= fechaInicio && fechaRegistro <= fechaFin;
    });

    const filteredSala = filteredListMovimiento.filter(
      (itemFilter) => itemFilter.id_sala == searchSala
    );

    setFilterListTransferencia(
      searchSala == "all" ? filteredListMovimiento : filteredSala
    );
  };

  //----------------------- CREAR -----------------------------------

  const handleSubmitTransferencia = async () => {
    try {
      const getSala = searchSala;
      dataMovement.comision =
        dataMovement.comision != "" ? dataMovement.comision : "0";
      const { status, data } = await axios.post(
        `${host3}/api/movimientos-bancos/${token}/transferencias`,
        dataMovement
      );
      if (status == 201) {
        setSearchSala("");
        mensaje(data.message, "top-right", 1);
        onClose2();
        clearData();
        fetchTransferencia();
        setTimeout(() => {
          setSearchSala(getSala);
        }, 50);
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  //----------------------------ELIMINAR TRANSFERENCIA------------------------------------

  const deleteTransferencia = async (id_movimiento_banco) => {
    try {
      const getSala = searchSala;
      const { status, data } = await axios.delete(
        `${host3}/api/movimientos-bancos/${token}/transferencias/${id_movimiento_banco}`
      );
      if (status == 201) {
        setSearchSala("");
        mensaje(data.message, "top-right", 1);
        // setTimeout(() => {
        // }, 50);
        setSearchSala(getSala);
      }
      fetchTransferencia();
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  // ------------------------------- LISTAR DIVISAS -----------------------------------------

  const [listDivisa, setlistDivisa] = useState([]);
  const [filterListDivisa, setFilterListDivisa] = useState([]);
  const [searchDivisa, setSearchDivisa] = useState({
    name: "",
    date_initial: "",
    date_end: "",
  });

  const fetchDivisa = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/movimientos-bancos/${token}/${id_persona}`
      );
      setlistDivisa(data.filter((itemFilter) => itemFilter.tipo == "DIVISA"));
      setFilterListDivisa(listDivisa);
    } catch (error) {
      setListTransferencia([]);
    }
  };

  const ListFiltered3 = () => {
    if (searchDivisa.name != "") {
      var filtered = filterListDivisa.filter(
        (p) =>
          p.cuenta_origen.includes(searchDivisa.name.toUpperCase()) ||
          p.cuenta_destino.includes(searchDivisa.name.toUpperCase()) ||
          p.moneda_origen.includes(searchDivisa.name.toUpperCase()) ||
          p.moneda_origen.includes(searchDivisa.name.toUpperCase())
      );
      return filtered.slice(currentPage2, currentPage2 + 13);
    } else {
      let list =
        searchSala != ""
          ? filterListDivisa?.slice(currentPage2, currentPage2 + 13)
          : [];
      return list;
    }
  };

  // ----------------------- BUSCAR ----------------------------------------

  const handleChangeSearchDivisa = (e) => {
    setSearchDivisa({
      ...searchDivisa,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickSearchDivisa = () => {
    if (searchSala == "") {
      mensaje("Primero elija un filtro", "top-right", 2);
      return;
    }

    if (searchDivisa.date_initial == "" || searchDivisa.date_end == "") {
      mensaje("El rango de fecha debe estar completo", "top-right", 2);
      return;
    }

    const fechaInicio = new Date(searchDivisa.date_initial); // Establece la fecha de inicio
    const fechaFin = new Date(searchDivisa.date_end); // Establece la fecha de fin

    const filteredListMovimiento = listDivisa.filter((itemFilter) => {
      const fechaRegistro = new Date(itemFilter.fecha_registro);
      return fechaRegistro >= fechaInicio && fechaRegistro <= fechaFin;
    });

    const filteredSala = filteredListMovimiento.filter(
      (itemFilter) => itemFilter.id_sala == searchSala
    );

    setFilterListDivisa(
      searchSala == "all" ? filteredListMovimiento : filteredSala
    );
  };

  // ---------------------------------- CREAR -----------------------------------------------

  const handleSubmitDivisa = async () => {
    try {
      const getSala = searchSala;
      dataMovement.comision =
        dataMovement.comision != "" ? dataMovement.comision : "0";
      const { status, data } = await axios.post(
        `${host3}/api/movimientos-bancos/${token}/cambio-divisa`,
        dataMovement
      );
      if (status == 201) {
        setSearchSala("");
        mensaje(data.message, "top-right", 1);
        onCloseCDivisas();
        clearData();
        fetchDivisa();
        setTimeout(() => {
          setSearchSala(getSala);
        }, 50);
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  // -------------------------------- Calcular divisa ---------------------------------

  const [divisaDinamica, setDivisaDinamica] = useState({
    monto: 0,
    ganancia: 0,
    perdida: 0
  })

  // const calcularTotalDivisa = async () => {
  //   try {
  //     const { status, data } = await axios.post(
  //       `${host3}/api/movimientos-bancos/${token}/calcular-divisa`,
  //       dataMovement
  //     );

  //     if (status == 201) {
  //       setDivisaDinamica({...divisaDinamica, monto: data.monto, ganancia: data.ganancia, perdida: data.perdida})
  //     }
  //   } catch (error) {
  //     setDivisaDinamica({...divisaDinamica, monto: 0, ganancia: 0, perdida: 0})
  //   }
  // };

  useEffect(()=>{
    const load = async ()=>{
      try {
        const { status, data } = await axios.post(
          `${host3}/api/movimientos-bancos/${token}/calcular-divisa`,
          dataMovement
        );
  
        if (status == 201) {
          setDivisaDinamica({...divisaDinamica, monto: data.monto, ganancia: data.ganancia, perdida: data.perdida})
        }
      } catch (error) {
        setDivisaDinamica({...divisaDinamica, monto: 0, ganancia: 0, perdida: 0})
      }
    }
    load()
  },[dataMovement])

  // -------------------------------------- PENDIENTES ---------------------------------
  const [listPendientes, setListPendientes] = useState([]);
  const [filteredListPendientes, setFilteredListPendientes] = useState([]);

  const fetchPendientes = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/movimiento_casas/${token}/pendientes`
      );
      setListPendientes(data);
      setFilteredListPendientes(listPendientes);
    } catch (error) {
      setListTransferencia([]);
    }
  };

  const ListFiltered5 = () => {
    if (search) {
      var filtered = filteredListPendientes.filter(
        (p) =>
          p.gasto.toString().includes(search) ||
          p.estado.toString().includes(search)
      );
      return filtered.slice(currentPage4, currentPage4 + 8);
    } else {
      let list =
        searchSala != ""
          ? filteredListPendientes?.slice(currentPage2, currentPage2 + 13)
          : [];
      return list;
    }
  };

  // ------------------------------------ LISTAR TOTAL DE CONFIRMADOS
  const [totalPendiente, setTotalPendiente] = useState([]);
  const [filterTotalPendiente, setFilterTotalPendiente] = useState([]);

  const fetchCountPendiente = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/movimiento_casas/${token}/pendientes/count`
      );
      setTotalPendiente(data);
      setFilterTotalPendiente(totalPendiente);
    } catch (error) {
      setListTransferencia([]);
    }
  };

  // ----------------------------------------------- CONFIRMAR
  const handleConfirmedPendiente = async (id) => {
    try {
      const getSala = searchSala;
      const { status, data } = await axios.post(
        `${host3}/api/movimiento_casas/${token}/pendientes/${id}`
      );

      if (status == 201) {
        setSearchSala("");
        //fetchCountPendiente()
        mensaje(data.message, "top-right", 1);
        fetchPendientes();
        setTimeout(() => {
          setSearchSala(getSala);
        }, 50);
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
    fetchCountPendiente();
  };

  // -----------------------LISTAR PAGOS/GASTOS----------------------------------------------------------------

  const [listgastos, setListgastos] = useState([]);
  const [filterListGasto, setFilterListGasto] = useState([]);

  const [searchGasto, setSearchGasto] = useState({
    name: "",
    date_initial: "",
    date_end: "",
  });

  const fetchGastos = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/movimientos-bancos/${token}/gastos-pagos/${id_persona}`
      );
      setListgastos(data);
      setFilterListGasto(listgastos);
    } catch (error) {
      setListgastos([]);
    }
  };

  const ListFiltered4 = () => {
    if (search) {
      var filtered = filterListGasto.filter(
        (p) =>
          p.gasto.toString().includes(search) ||
          p.estado.toString().includes(search)
      );
      return filtered.slice(currentPage4, currentPage4 + 8);
    } else {
      let list =
        searchSala != ""
          ? filterListGasto?.slice(currentPage2, currentPage2 + 13)
          : [];
      return list;
    }
  };

  //----------------------LISTAR RECARGAS ------------------
  const [listRecargas, setListRecargas] = useState([]);
  const [filterListRecargas, setFilterListRecargas] = useState([]);
  const [searchRecarga, setSearchRecarga] = useState({
    name: "",
    date_initial: "",
    date_end: "",
  });

  const fetchRecargas = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/movimiento_casas/${token}/recargas`
      );
      setListRecargas(data);
      setFilterListRecargas(listRecargas);
    } catch (error) {
      setListRecargas([]);
      console.log(error);
    }
  };

  const listFilteredRecargas = () => {
    return filterListRecargas.slice(currentPage2, currentPage2 + 13);
  };

  // ----------------------- BUSCAR RECARGAS ----------------------------------------

  const handleChangeSearchRecarga = (e) => {
    setSearchRecarga({
      ...searchRecarga,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickSearchRecarga = () => {
    if (searchRecarga.date_initial === "" || searchRecarga.date_end === "") {
      mensaje("El rango de fecha debe estar completo", "top-right", 2);
      return;
    }

    const fechaInicio = new Date(searchRecarga.date_initial); // Establece la fecha de inicio
    const fechaFin = new Date(searchRecarga.date_end); // Establece la fecha de fin

    const filteredListRecarga = listRecargas.filter((itemFilter) => {
      const fechaRegistro = new Date(itemFilter.fecha_registro);
      return fechaRegistro >= fechaInicio && fechaRegistro <= fechaFin;
    });

    setFilterListRecargas(filteredListRecarga);
  };
  //----------------------------AGREGAR PAGOS/GASTOS-------------------------------------------

  const [Modelgastos, setModelgastos] = useState({
    id_sala: "",
    caja_chica: "",
    categoria: "",
    monto: "",
    comentario: "",
    id_moneda: "",
    tipo: "",
    id_persona: id_persona,
  });

  const changeGasto = (e) => {
    setModelgastos({
      ...Modelgastos,
      [e.target.name]: e.target.value,
    });
  };

  const crearEgreso = async () => {
    try {
      const getSala = searchSala;
      Modelgastos.caja_chica = isCaja ? "1" : "0";
      const response = await axios.post(
        `${host3}/api/movimientos-bancos/${token}/${Modelgastos.categoria}/${Modelgastos.tipo}`,
        Modelgastos
      );
      setSearchSala("");
      mensaje(response.data.message, "top-right", 1);
      setModelgastos({
        id_sala: "",
        categoria: "",
        monto: "",
        comentario: "",
        id_moneda: "",
        caja_chica: "",
        tipo: "",
        id_persona: id_persona,
      });
      fetchGastos();
      onClose4();
      setTimeout(() => {
        setSearchSala(getSala);
      }, 50);
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
      //console.log(error);
    }
  };

  //----------------------------ELIMINAR PAGO/GASTO------------------------------------

  const deletegastos = async (id_gastos) => {
    try {
      const getSala = searchSala;
      await axios.delete(
        `${host3}/api/movimientos-bancos/${token}/eliminar/${id_gastos}`
      );
      setSearchSala("");
      fetchGastos();
      setTimeout(() => {
        setSearchSala(getSala);
      }, 50);
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  //-------------------- BUSCAR POR CATEGORIA ------------------------------------------

  const [searchCategoria, setSearchCategoria] = useState("");

  useEffect(() => {
    if (searchSala == "all") {
      if (searchCategoria == "all") {
        setFilterListGasto(listgastos);
        return;
      }
      setFilterListGasto(
        listgastos.filter(
          (itemFilter) => itemFilter.categoria == searchCategoria
        )
      );
      return;
    }

    if (searchSala != "") {
      if (searchCategoria == "all") {
        setFilterListGasto(
          listgastos.filter(
            (itemFilter) => itemFilter.sala.id_sala == searchSala
          )
        );
        return;
      }
      setFilterListGasto(
        listgastos.filter(
          (itemFilter) =>
            itemFilter.categoria == searchCategoria &&
            itemFilter.sala.id_sala == searchSala
        )
      );
      return;
    }
  }, [searchCategoria]);

  // ----------------------- BUSCAR ----------------------------------------

  const handleChangeSearchGasto = (e) => {
    setSearchGasto({
      ...searchGasto,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickSearchGasto = () => {
    if (searchGasto.date_initial == "" || searchGasto.date_end == "") {
      mensaje("El rango de fecha debe estar completo", "top-right", 2);
      return;
    }

    const fechaInicio = new Date(searchGasto.date_initial); //fecha de inicio
    const fechaFin = new Date(searchGasto.date_end); // Establece la fecha de fin

    const filteredListMovimiento = listgastos.filter((itemFilter) => {
      const fechaRegistro = new Date(itemFilter.fecha_registro);
      return fechaRegistro >= fechaInicio && fechaRegistro <= fechaFin;
    });

    const filteredSala = filteredListMovimiento.filter(
      (itemFilter) => itemFilter.sala.id_sala == searchSala
    );

    setFilterListGasto(
      searchSala == "all" ? filteredListMovimiento : filteredSala
    );
  };
  // ------------------------- EXCEL ----------------------------------

  //--DEPOSITO
  const [dataExcelDeposito, setDataExcelDeposito] = useState([]);

  const exportExcelDeposito = () => {
    let data = [];

    filterListMovimiento.map((itemMovimiento, i) => {
      data.push({
        "N°": i + 1,
        Fecha: itemMovimiento.fecha_registro,
        "Cuenta Bancaria": itemMovimiento.cuenta_origen,
        Tipo: itemMovimiento.tipo,
        Monto: itemMovimiento.monto_origen,
        Comision: itemMovimiento.comision,
        Moneda: itemMovimiento.moneda_origen,
        Comentario:
          itemMovimiento.comentario != ""
            ? itemMovimiento.comentario
            : "-----------",
      });
    });

    setDataExcelDeposito(data);
  };

  useEffect(() => {
    exportExcelDeposito();
  }, [filterListMovimiento]);

  const exportAsExcelDepositol = () => {
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(dataExcelDeposito);

    // Establecer el ancho de las columnas
    ws["!cols"] = [
      { key: "orden", wch: 5 },
      { key: "fecha", wch: 15 },
      { key: "cuenta_bancaria", wch: 25 },
      { key: "tipo", wch: 15 },
      { key: "monto", wch: 20 },
      { key: "comision", wch: 15 },
      { key: "moneda", wch: 15 },
      { key: "comentario", wch: 15 },
    ];

    utils.book_append_sheet(wb, ws, `Depositos_Retiros`);
    writeFile(wb, `Movimientos_DEPOSITO_RETIRO_${moment().format("LTS")}.xlsx`);
  };

  //--TRANSFERENCIA
  const [dataExcelTransferencia, setDataExcelTransferencia] = useState([]);

  const exportExcelTransferencia = () => {
    let data = [];

    filterListTransferencia.map((itemMovimiento, i) => {
      data.push({
        "N°": i + 1,
        Fecha: itemMovimiento.fecha_registro,
        "Cuenta Origen": itemMovimiento.cuenta_origen,
        "Cuenta Destino": itemMovimiento.cuenta_destino,
        Monto: itemMovimiento.monto_origen,
        Comision: itemMovimiento.comision,
        Moneda: itemMovimiento.moneda_origen,
        Comentario:
          itemMovimiento.comentario != ""
            ? itemMovimiento.comentario
            : "-----------",
      });
    });

    setDataExcelTransferencia(data);
  };

  useEffect(() => {
    exportExcelTransferencia();
  }, [filterListTransferencia]);

  const exportAsExcelTransferencia = () => {
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(dataExcelTransferencia);

    // Establecer el ancho de las columnas
    ws["!cols"] = [
      { key: "orden", wch: 5 },
      { key: "fecha", wch: 15 },
      { key: "cuenta_origen", wch: 25 },
      { key: "cuenta_destino", wch: 25 },
      { key: "monto", wch: 15 },
      { key: "comision", wch: 15 },
      { key: "moneda", wch: 15 },
      { key: "comentario", wch: 15 },
    ];

    utils.book_append_sheet(wb, ws, `Transferencia`);
    writeFile(wb, `Movimientos_TRANSFERENCIAS_${moment().format("LTS")}.xlsx`);
  };

  //--DIVISAS
  const [dataExcelDivisa, setDataExcelDivisa] = useState([]);

  const exportExcelDivisa = () => {
    let data = [];

    filterListDivisa.map((itemMovimiento, i) => {
      data.push({
        "N°": i + 1,
        Fecha: itemMovimiento.fecha_registro,
        "Tipo Cambio": itemMovimiento.tipo_cambio,
        "Cuenta Origen": itemMovimiento.cuenta_origen,
        "Monto Origen": itemMovimiento.monto_origen,
        "Moneda Origen": itemMovimiento.moneda_origen,
        "Cuenta Destino": itemMovimiento.cuenta_destino,
        "Monto Destino": itemMovimiento.monto_destino,
        "Moneda Destino": itemMovimiento.moneda_destino,
        Comision: itemMovimiento.comision,
      });
    });

    setDataExcelDivisa(data);
  };

  useEffect(() => {
    exportExcelDivisa();
  }, [filterListDivisa]);

  const exportAsExcelDivisa = () => {
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(dataExcelDivisa);
    utils.book_append_sheet(wb, ws, `Cambio Divisas`);
    writeFile(wb, `Movimientos_DIVISAS_${moment().format("LTS")}.xlsx`);
  };

  useEffect(() => {
    fetchSalas();
    fetchPendientes();
    fetchCountPendiente();
    fetchGastos();
    fetchDivisa();
    fetchMovimientoDepositoRetiro();
    fetchMovimientoDepositoRetiro();
    fetchRecargas();
    fetchMoneda();
    fetchTransferencia();
    setTimeout(() => {
      setTiempo(true);
    }, 2000);
  }, []);

  //Paginacion1
  const [currentPage, setCurrentPage] = useState(0);

  const nextPage = () => {
    const cant = filterListMovimiento.filter(
      (p) => p.id_movimiento_banco
    ).length;

    if (cant > currentPage) {
      setCurrentPage(currentPage + 13);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 13);
    }
  };
  // ---------------------------

  //Paginacion2
  const [currentPage2, setCurrentPage2] = useState(0);
  const nextPage2 = () => {
    const cant = filterListTransferencia.filter(
      (p) => p.id_movimiento_banco
    ).length;

    if (cant > currentPage2) {
      setCurrentPage2(currentPage2 + 13);
    }
  };

  const prevPage2 = () => {
    if (currentPage2 > 0) {
      setCurrentPage2(currentPage2 - 13);
    }
  };
  // ---------------------------
  //Paginacion4
  const [currentPage4, setCurrentPage4] = useState(0);
  const nextPage4 = () => {
    const cant = tabla4.filter((p) => p.id.toString()).length;

    if (cant > currentPage4) {
      setCurrentPage4(currentPage4 + 13);
    }
  };

  const prevPage4 = () => {
    if (currentPage4 > 0) {
      setCurrentPage4(currentPage4 - 13);
    }
  };

  //Paginacion5
  const [currentPage5, setCurrentPage5] = useState(0);
  const nextPage5 = () => {
    const cant = filteredListPendientes.filter((p) =>
      p.movimiento_id.toString()
    ).length;

    if (cant > currentPage5) {
      setCurrentPage5(currentPage5 + 13);
    }
  };

  const prevPage5 = () => {
    if (currentPage5 > 0) {
      setCurrentPage5(currentPage5 - 13);
    }
  };

  const buscar = (e) => {
    setSearch(e.target.value);
  };

  // --------------------------------------
  const { isOpen, onOpen, onClose } = useDisclosure();
  // --------------------------------------
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  // --------------------------------------
  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();
  // ----------------------------------------
  const {
    isOpen: isOpen4,
    onOpen: onOpen4,
    onClose: onClose4,
  } = useDisclosure();
  // ----------------------------------------
  const {
    isOpen: isOpenRecargas,
    onOpen: onOpenRecargas,
    onClose: onCloseRecargas,
  } = useDisclosure();
  // --------------------------------------
  const {
    isOpen: isOpenCDivisas,
    onOpen: onOpenCDivisas,
    onClose: onCloseCDivisas,
  } = useDisclosure();
  // --------------------------------------
  const {
    isOpen: isOpen6,
    onOpen: onOpen6,
    onClose: onClose6,
  } = useDisclosure();

  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar,
  } = useDisclosure();
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: "1.2rem", textAlign: "center" }}
        pauseOnHover
      />
      <Box
        scrollBehavior={"smooth"}
        bg={"#e5e5e5"}
        height={"auto"}
        maxHeight={"100vh"}
        overflowY={"auto"}
        css={{
          "&::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#6b6b6b",
            borderRadius: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#9b9b9b",
            borderRadius: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#9b9b9b",
          },
        }}
      >
        <Box
          as="section"
          display={{
            "2xl": "unset",
            xl: "unset",
            lg: "none",
            md: "none",
            sm: "none",
          }}
        >
          <SidebarOperators />
        </Box>
        <Drawer isOpen={isOpenBar} onClose={onCloseBar} placement="left">
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w="full" borderRight="none" />
          </DrawerContent>
        </Drawer>
        <Box bg={"#e5e5e5"} h={"100vh"}>
          <Flex
            alignItems={"center"}
            justify={"flex-start"}
            // h={'100%'}
            sx={{
              "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
                {
                  padding: "1.5% 3% 1.5% 19%",
                },
            }}
            p={{
              "2xl": "1.5% 1.5% 1.5% 15%",
              xl: "1.5% 1.5% 1.5% 18%",
              lg: "1.5% 2% 1.5% 2%",
              md: "1.5% 2% 1.5% 2%",
            }}
            // marginLeft={'15%'}
            // marginRight='2%'
            // marginBottom='1%'
            direction="column"
            gap={"25px"}
          >
            <Box transition=".3s ease" width={"100%"}>
              <Flex gap="2%" alignItems={"center"} as="header" w="full" h="14">
                <IconButton
                  aria-label="Menu"
                  display={{
                    "2xl": "none",
                    xl: "none",
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                  }}
                  background={"#292F36"}
                  color={"#ffffff"}
                  borderRadius={"4px"}
                  fontSize={"1.3rem"}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size="md"
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink color={"#707070"} fontSize={"1.5rem"}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={"bold"}
                      color={"#292F36"}
                      fontSize={"1.5rem"}
                    >
                      MOVIMIENTOS BANCO
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>

            {/* ---------------------------------------------------------------------------- */}

            <Flex
              w={"100%"}
              direction={"column"}
              justifyContent={"center"}
              gap={"20px"}
            >
              <Flex w={{ "2xl": "20%", xl: "25%", lg: "30%", md: "30%" }}>
                <Select
                  value={searchSala}
                  onChange={(e) => {
                    setSearchSala(e.target.value);
                  }}
                  w="100%"
                  height={"40px"}
                  fontFamily="monospace"
                  fontWeight={"600"}
                  textTransform="uppercase"
                  variant="filled"
                  name="sala"
                  border={"1px solid"}
                  borderColor={"#2a2e6e"}
                  bgColor={"white"}
                  fontSize={"1.8rem"}
                  h="35"
                >
                  <option disabled value="" selected>
                    Mis Salas
                  </option>
                  <option value="all">Mostrar todas</option>
                  {listSalaFilter &&
                    listSalaFilter.map((itemSala) => (
                      <option key={itemSala.id_sala} value={itemSala.id_sala}>
                        {itemSala.sala.nombre}
                      </option>
                    ))}
                </Select>
              </Flex>

              <Flex w={"95%"} h="85vh">
                <Tabs isFitted variant="enclosed" w={"100%"}>
                  <TabList mb="1em" bg={"#FFFF"} borderRadius="15px" p={"0.5%"}>
                    <Tab
                      borderRadius={"15px"}
                      _selected={{
                        color: "white",
                        bg: "#2a2e6e",
                      }}
                      fontSize={{
                        "2xl": "2.1rem",
                        xl: "1.84rem",
                        lg: "1.84rem",
                        md: "1.84rem",
                      }}
                      textTransform={"uppercase"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                    >
                      Deposito / Retiro
                    </Tab>
                    <Tab
                      borderRadius={"15px"}
                      _selected={{
                        color: "white",
                        bg: "#2a2e6e",
                      }}
                      fontSize={{
                        "2xl": "2.1rem",
                        xl: "1.84rem",
                        lg: "1.84rem",
                        md: "1.84rem",
                      }}
                      textTransform={"uppercase"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                    >
                      Transferencia
                    </Tab>

                    <Tab
                      borderRadius={"15px"}
                      _selected={{
                        color: "white",
                        bg: "#2a2e6e",
                      }}
                      fontSize={{
                        "2xl": "2.1rem",
                        xl: "1.84rem",
                        lg: "1.84rem",
                        md: "1.84rem",
                      }}
                      textTransform={"uppercase"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                    >
                      Cambio de Divisas
                    </Tab>

                    <Tab
                      borderRadius={"15px"}
                      _selected={{
                        color: "white",
                        bg: "#2a2e6e",
                      }}
                      fontSize={{
                        "2xl": "2.1rem",
                        xl: "1.84rem",
                        lg: "1.84rem",
                        md: "1.7rem",
                      }}
                      textTransform={"uppercase"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                    >
                      pendientes
                    </Tab>

                    <Tab
                      borderRadius={"15px"}
                      _selected={{
                        color: "white",
                        bg: "#2a2e6e",
                      }}
                      fontSize={{
                        "2xl": "2.1rem",
                        xl: "1.84rem",
                        lg: "1.84rem",
                        md: "1.84rem",
                      }}
                      textTransform={"uppercase"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                    >
                      Pagos Y/O Gastos
                    </Tab>
                    <Tab
                      borderRadius={"15px"}
                      _selected={{
                        color: "white",
                        bg: "#2a2e6e",
                      }}
                      fontSize={{
                        "2xl": "2.1rem",
                        xl: "1.84rem",
                        lg: "1.84rem",
                        md: "1.84rem",
                      }}
                      textTransform={"uppercase"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                    >
                      Recargas
                    </Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel
                      w={"100%"}
                      bg={"#ffff"}
                      borderRadius="10px"
                      p={"2%"}
                      boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                    >
                      <Flex
                        w={"100%"}
                        h="100%"
                        direction={"column"}
                        gap="25px"
                        justifyContent="center"
                      >
                        {searchSala != "" && (
                          <Flex w={"100%"} justifyContent="space-between">
                            <Flex
                              width={{
                                "2xl": "30%",
                                xl: "30%",
                                lg: "40%",
                                md: "50%",
                              }}
                            >
                              <Stack w={"100%"}>
                                <InputGroup>
                                  <InputLeftElement
                                    pointerEvents="none"
                                    children={
                                      <Search2Icon
                                        color="#2a2e6e"
                                        marginLeft={"20%"}
                                        fontSize={"1.25rem"}
                                        marginTop={"60%"}
                                        _dark={{
                                          color: "#f59f2c",
                                        }}
                                      />
                                    }
                                  />
                                  <Input
                                    onChange={handleChangeSearchDeposito}
                                    type="text"
                                    placeholder="Buscar"
                                    name="name"
                                    value={searchDeposito.name}
                                    bg={"white"}
                                    height={"40px"}
                                    _focus={{
                                      borderColor: "#2a2e6e",
                                      boxShadow: "none",
                                    }}
                                    color="#2a2e6e"
                                    fontSize={"1.9rem"}
                                    border={"1px solid"}
                                    borderColor={"#2a2e6e"}
                                    borderRadius="6px"
                                    _placeholder={{
                                      color: "rgb(152, 152, 152)",
                                    }}
                                    _dark={{
                                      color: "white",
                                      borderColor: "#686868",
                                      bg: "#686868",
                                    }}
                                  />
                                </InputGroup>
                              </Stack>
                              {tiempo && (
                                <Button
                                  onClick={exportAsExcelDepositol}
                                  background={"#4caf50"}
                                  color={"#fff"}
                                  height={"40px"}
                                  borderRadius={"5px"}
                                  padding={"6px 16px"}
                                  marginLeft={"15px"}
                                  _hover={"none"}
                                >
                                  <Icon boxSize="10" as={RiFileExcel2Fill} />
                                </Button>
                              )}
                            </Flex>

                            <Flex w={"40%"} alignItems={"center"} gap={"1.5%"}>
                              <Input
                                placeholder="Fecha de inicio"
                                onChange={handleChangeSearchDeposito}
                                value={searchDeposito.date_initial}
                                name="date_initial"
                                size="md"
                                type="date"
                                bg={"white"}
                                height={"40px"}
                                fontSize={"1.8rem"}
                                color="#2a2e6e"
                                borderColor={"#2a2e6e"}
                                _dark={{
                                  color: "white",
                                  borderColor: "#686868",
                                  bg: "#686868",
                                }}
                              />
                              <Text fontSize={"1.8rem"}>al</Text>
                              <Input
                                placeholder="Fecha de inicio"
                                value={searchDeposito.date_end}
                                name="date_end"
                                size="md"
                                type="date"
                                bg={"white"}
                                height={"40px"}
                                fontSize={"1.8rem"}
                                color="#2a2e6e"
                                borderColor={"#2a2e6e"}
                                onChange={handleChangeSearchDeposito}
                                _dark={{
                                  color: "white",
                                  borderColor: "#686868",
                                  bg: "#686868",
                                }}
                              />
                              <Stack align="center" width={"48%"}>
                                <Button
                                  onClick={handleClickSearchDeposito}
                                  w={"100%"}
                                  height={"40px"}
                                  borderRadius="10px"
                                  color={"white"}
                                  fontSize={"1.6rem"}
                                  bg="#2a2e6e"
                                  _dark={{
                                    color: "white",
                                    bg: "#f59f2c",
                                  }}
                                >
                                  Buscar
                                </Button>
                              </Stack>
                            </Flex>

                            <Button
                              w={"15%"}
                              onClick={onOpen}
                              height={"40px"}
                              borderRadius="10px"
                              color={"white"}
                              fontSize={"1.6rem"}
                              bg="#2a2e6e"
                              p={"1% 1.5%"}
                              textTransform={"uppercase"}
                            >
                              Agregar
                            </Button>
                          </Flex>
                        )}

                        <TableContainer w={"100%"}>
                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                <Th fontSize={"1.8rem"}>TIPO</Th>
                                <Th fontSize={"1.8rem"}>FECHA</Th>
                                <Th fontSize={"1.8rem"}>
                                  CUENTA BANCO (DENOMINACIÓN)
                                </Th>
                                <Th fontSize={"1.8rem"}>MONTO</Th>
                                <Th fontSize={"1.8rem"}>MONEDA</Th>
                                <Th fontSize={"1.8rem"}>COMISION</Th>

                                <Th fontSize={"1.8rem"}>COMENTARIO</Th>

                                <Th fontSize={"1.8rem"} isNumeric>
                                  ACCION
                                </Th>
                              </Tr>
                            </Thead>
                            {ListFiltered().map((itemMovimiento) => {
                              return (
                                <Tbody
                                  fontSize={"1.6rem"}
                                  key={itemMovimiento.id_movimiento_banco}
                                >
                                  <Tr>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                      style={{
                                        color:
                                          itemMovimiento.tipo === "DEPOSITO"
                                            ? "green"
                                            : "red",
                                      }}
                                    >
                                      {itemMovimiento.tipo}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {itemMovimiento.fecha_registro}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      // textAlign={"center"}
                                    >
                                      {itemMovimiento.cuenta_destino}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemMovimiento.monto_origen}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemMovimiento.moneda_destino}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemMovimiento.comision}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemMovimiento.comentario}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                      isNumeric
                                    >
                                      <Tooltip
                                        hasArrow
                                        borderRadius={"4px"}
                                        fontSize={"1.2rem"}
                                        label="Eliminar"
                                        bg="#292F36"
                                      >
                                        <IconButton
                                          onClick={() => {
                                            deleteDeposito(
                                              itemMovimiento.id_movimiento_banco
                                            );
                                          }}
                                          size={"md"}
                                          borderRadius={"4px"}
                                          fontSize={"1.5rem"}
                                          background={"#be0000"}
                                          color={"#ffffff"}
                                          aria-label="Eliminar"
                                          icon={<MdDelete />}
                                        />
                                      </Tooltip>
                                    </Td>
                                  </Tr>
                                </Tbody>
                              );
                            })}
                          </Table>
                        </TableContainer>

                        <Flex
                          marginTop={"0.5%"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={"0.5%"}
                        >
                          <Icon
                            onClick={() => {
                              prevPage();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDropleftCircle}
                          />
                          <Icon
                            onClick={() => {
                              nextPage();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDroprightCircle}
                          />
                        </Flex>
                      </Flex>
                    </TabPanel>
                    {/* ----------------------------------------- */}
                    <TabPanel
                      w={"100%"}
                      bg={"#ffff"}
                      borderRadius="10px"
                      p={"2%"}
                      boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                    >
                      <Flex
                        direction="column"
                        w={"100%"}
                        h="100%"
                        gap="20px"
                        justifyContent="center"
                      >
                        {searchSala != "" && (
                          <Flex w={"100%"} justifyContent="space-between">
                            <Flex
                              width={{
                                "2xl": "30%",
                                xl: "30%",
                                lg: "40%",
                                md: "50%",
                              }}
                            >
                              <Stack w={"100%"}>
                                <InputGroup>
                                  <InputLeftElement
                                    pointerEvents="none"
                                    children={
                                      <Search2Icon
                                        color="#2a2e6e"
                                        marginLeft={"20%"}
                                        fontSize={"1.25rem"}
                                        marginTop={"60%"}
                                        _dark={{
                                          color: "#f59f2c",
                                        }}
                                      />
                                    }
                                  />
                                  <Input
                                    onChange={handleChangeSearchTransferencia}
                                    name="name"
                                    value={searchTransferencia.name}
                                    type="text"
                                    placeholder="Buscar"
                                    bg={"white"}
                                    height={"40px"}
                                    _focus={{
                                      borderColor: "#2a2e6e",
                                      boxShadow: "none",
                                    }}
                                    color="#2a2e6e"
                                    fontSize={"1.9rem"}
                                    border={"1px solid"}
                                    borderColor={"#2a2e6e"}
                                    borderRadius="6px"
                                    _placeholder={{
                                      color: "rgb(152, 152, 152)",
                                    }}
                                    _dark={{
                                      color: "white",
                                      borderColor: "#686868",
                                      bg: "#686868",
                                    }}
                                  />
                                </InputGroup>
                              </Stack>
                              {tiempo && (
                                <Button
                                  onClick={exportAsExcelTransferencia}
                                  background={"#4caf50"}
                                  color={"#fff"}
                                  height={"40px"}
                                  borderRadius={"5px"}
                                  padding={"6px 16px"}
                                  marginLeft={"15px"}
                                  _hover={"none"}
                                >
                                  <Icon boxSize="10" as={RiFileExcel2Fill} />
                                </Button>
                              )}
                            </Flex>

                            <Flex w={"40%"} alignItems={"center"} gap={"1.5%"}>
                              <Input
                                placeholder="Fecha de inicio"
                                size="md"
                                type="date"
                                onChange={handleChangeSearchTransferencia}
                                name="date_initial"
                                value={searchTransferencia.date_initial}
                                bg={"white"}
                                height={"40px"}
                                fontSize={"1.8rem"}
                                color="#2a2e6e"
                                borderColor={"#2a2e6e"}
                                _dark={{
                                  color: "white",
                                  borderColor: "#686868",
                                  bg: "#686868",
                                }}
                                // onChange={handleFecha}
                              />
                              <Text fontSize={"1.8rem"}>al</Text>
                              <Input
                                placeholder="Fecha de inicio"
                                size="md"
                                type="date"
                                onChange={handleChangeSearchTransferencia}
                                name="date_end"
                                value={searchTransferencia.date_end}
                                bg={"white"}
                                height={"40px"}
                                fontSize={"1.8rem"}
                                color="#2a2e6e"
                                borderColor={"#2a2e6e"}
                                _dark={{
                                  color: "white",
                                  borderColor: "#686868",
                                  bg: "#686868",
                                }}
                                // onChange={handleFecha}
                              />
                              <Stack align="center" width={"48%"}>
                                <Button
                                  onClick={handleClickSearchTransferencia}
                                  w={"100%"}
                                  height={"40px"}
                                  borderRadius="10px"
                                  color={"white"}
                                  fontSize={"1.6rem"}
                                  bg="#2a2e6e"
                                  _dark={{
                                    color: "white",
                                    bg: "#f59f2c",
                                  }}
                                >
                                  Buscar
                                </Button>
                              </Stack>
                            </Flex>

                            <Button
                              w={"15%"}
                              onClick={onOpen2}
                              height={"40px"}
                              borderRadius="10px"
                              color={"white"}
                              fontSize={"1.6rem"}
                              bg="#2a2e6e"
                              p={"1% 1.5%"}
                              textTransform={"uppercase"}
                            >
                              Agregar
                            </Button>
                          </Flex>
                        )}
                        <TableContainer w={"100%"}>
                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                <Th fontSize={"1.8rem"}>FECHA</Th>
                                <Th fontSize={"1.8rem"}>CUENTA ORIGEN</Th>
                                <Th fontSize={"1.8rem"}>CUENTA DESTINO</Th>
                                <Th fontSize={"1.8rem"}>MONTO</Th>
                                <Th fontSize={"1.8rem"}>MONEDA</Th>
                                <Th fontSize={"1.8rem"}>COMENTARIO</Th>

                                <Th fontSize={"1.8rem"} isNumeric>
                                  ACCION
                                </Th>
                              </Tr>
                            </Thead>
                            {ListFiltered2().map((itemTransferencia) => {
                              return (
                                <Tbody
                                  fontSize={"1.6rem"}
                                  key={itemTransferencia.id_movimiento_banco}
                                >
                                  <Tr>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {itemTransferencia.fecha_registro}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {itemTransferencia.cuenta_origen}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {itemTransferencia.cuenta_destino}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemTransferencia.monto_origen}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemTransferencia.moneda_origen}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemTransferencia.comentario}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                      isNumeric
                                    >
                                      <Tooltip
                                        hasArrow
                                        borderRadius={"4px"}
                                        fontSize={"1.2rem"}
                                        label="Eliminar"
                                        bg="#292F36"
                                      >
                                        <IconButton
                                          onClick={() => {
                                            deleteTransferencia(
                                              itemTransferencia.id_movimiento_banco
                                            );
                                          }}
                                          size={"md"}
                                          borderRadius={"4px"}
                                          fontSize={"1.5rem"}
                                          background={"#be0000"}
                                          color={"#ffffff"}
                                          aria-label="Eliminar"
                                          icon={<MdDelete />}
                                        />
                                      </Tooltip>
                                    </Td>
                                  </Tr>
                                </Tbody>
                              );
                            })}
                          </Table>
                        </TableContainer>
                        <Flex
                          marginTop={"0.5%"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={"0.5%"}
                        >
                          <Icon
                            onClick={() => {
                              prevPage2();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDropleftCircle}
                          />
                          <Icon
                            onClick={() => {
                              nextPage2();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDroprightCircle}
                          />
                        </Flex>
                      </Flex>
                    </TabPanel>

                    {/* ----------------------------------------- */}
                    <TabPanel
                      w={"100%"}
                      bg={"#ffff"}
                      borderRadius="10px"
                      p={"2%"}
                      boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                    >
                      <Flex
                        direction="column"
                        w={"100%"}
                        h="100%"
                        gap="20px"
                        justifyContent="center"
                      >
                        {searchSala != "" && (
                          <Flex w={"100%"} justifyContent="space-between">
                            <Flex
                              width={{
                                "2xl": "30%",
                                xl: "30%",
                                lg: "40%",
                                md: "50%",
                              }}
                            >
                              <Stack w={"100%"}>
                                <InputGroup>
                                  <InputLeftElement
                                    pointerEvents="none"
                                    children={
                                      <Search2Icon
                                        color="#2a2e6e"
                                        marginLeft={"20%"}
                                        fontSize={"1.25rem"}
                                        marginTop={"60%"}
                                        _dark={{
                                          color: "#f59f2c",
                                        }}
                                      />
                                    }
                                  />
                                  <Input
                                    onChange={handleChangeSearchDivisa}
                                    name="name"
                                    value={searchDivisa.name}
                                    type="text"
                                    placeholder="Buscar"
                                    bg={"white"}
                                    height={"40px"}
                                    _focus={{
                                      borderColor: "#2a2e6e",
                                      boxShadow: "none",
                                    }}
                                    color="#2a2e6e"
                                    fontSize={"1.9rem"}
                                    border={"1px solid"}
                                    borderColor={"#2a2e6e"}
                                    borderRadius="6px"
                                    _placeholder={{
                                      color: "rgb(152, 152, 152)",
                                    }}
                                    _dark={{
                                      color: "white",
                                      borderColor: "#686868",
                                      bg: "#686868",
                                    }}
                                  />
                                </InputGroup>
                              </Stack>
                              {tiempo && (
                                <Button
                                  onClick={exportAsExcelDivisa}
                                  background={"#4caf50"}
                                  color={"#fff"}
                                  height={"40px"}
                                  borderRadius={"5px"}
                                  padding={"6px 16px"}
                                  marginLeft={"15px"}
                                  _hover={"none"}
                                >
                                  <Icon boxSize="10" as={RiFileExcel2Fill} />
                                </Button>
                              )}
                            </Flex>

                            <Flex w={"40%"} alignItems={"center"} gap={"1.5%"}>
                              <Input
                                onChange={handleChangeSearchDivisa}
                                name="date_initial"
                                value={searchDivisa.date_initial}
                                placeholder="Fecha de inicio"
                                size="md"
                                type="date"
                                bg={"white"}
                                height={"40px"}
                                fontSize={"1.8rem"}
                                color="#2a2e6e"
                                borderColor={"#2a2e6e"}
                                _dark={{
                                  color: "white",
                                  borderColor: "#686868",
                                  bg: "#686868",
                                }}
                              />
                              <Text fontSize={"1.8rem"}>al</Text>
                              <Input
                                onChange={handleChangeSearchDivisa}
                                name="date_end"
                                value={searchDivisa.date_end}
                                placeholder="Fecha de inicio"
                                size="md"
                                type="date"
                                bg={"white"}
                                height={"40px"}
                                fontSize={"1.8rem"}
                                color="#2a2e6e"
                                borderColor={"#2a2e6e"}
                                _dark={{
                                  color: "white",
                                  borderColor: "#686868",
                                  bg: "#686868",
                                }}
                              />
                              <Stack align="center" width={"48%"}>
                                <Button
                                  onClick={handleClickSearchDivisa}
                                  w={"100%"}
                                  height={"40px"}
                                  borderRadius="10px"
                                  color={"white"}
                                  fontSize={"1.6rem"}
                                  bg="#2a2e6e"
                                  _dark={{
                                    color: "white",
                                    bg: "#f59f2c",
                                  }}
                                >
                                  Buscar
                                </Button>
                              </Stack>
                            </Flex>

                            <Button
                              w={"15%"}
                              onClick={onOpenCDivisas}
                              height={"40px"}
                              borderRadius="10px"
                              color={"white"}
                              fontSize={"1.6rem"}
                              bg="#2a2e6e"
                              p={"1% 1.5%"}
                              textTransform={"uppercase"}
                            >
                              Agregar
                            </Button>
                          </Flex>
                        )}
                        <TableContainer w={"100%"}>
                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                <Th fontSize={"1.8rem"}>FECHA</Th>
                                <Th fontSize={"1.8rem"}>TIPO CAMBIO</Th>
                                <Th fontSize={"1.8rem"}>CUENTA ORIGEN</Th>
                                <Th fontSize={"1.8rem"}>MONTO ORIGEN</Th>
                                <Th fontSize={"1.8rem"}>MONEDA ORIGEN</Th>
                                <Th fontSize={"1.8rem"}>CUENTA DESTINO</Th>
                                <Th fontSize={"1.8rem"}>MONTO DESTINO</Th>
                                <Th fontSize={"1.8rem"}>MONEDA DESTINO</Th>
                                <Th fontSize={"1.8rem"}>TC REAL</Th>
                                <Th fontSize={"1.8rem"}>GANANCIA</Th>
                                <Th fontSize={"1.8rem"}>PERDIDA</Th>

                                {/* <Th fontSize={'1.8rem'} isNumeric>
                                  ACCION
                                </Th> */}
                              </Tr>
                            </Thead>
                            {ListFiltered3().map((itemDivisa) => {
                              return (
                                <Tbody fontSize={"1.6rem"}>
                                  <Tr>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {itemDivisa.fecha_registro}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {itemDivisa.tipo_cambio}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemDivisa.cuenta_origen}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemDivisa.monto_origen}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemDivisa.moneda_origen}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemDivisa.cuenta_destino}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemDivisa.monto_destino}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemDivisa.moneda_destino}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemDivisa.cambio_real
                                        ? itemDivisa.cambio_real
                                        : 0}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemDivisa.ganancia_cambio}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemDivisa.perdida_cambio}
                                    </Td>
                                    {/* <Td
                                  fontWeight={'600'}
                                  fontFamily='monospace'
                                  textTransform={'uppercase'}
                                  isNumeric>
                                  <Tooltip
                                    hasArrow
                                    borderRadius={'4px'}
                                    fontSize={'1.2rem'}
                                    label='Eliminar'
                                    bg='#292F36'>
                                    <IconButton
                                      onClick={() => {
                                        deletegastos(p.id_gasto);
                                      }}
                                      size={'md'}
                                      borderRadius={'4px'}
                                      fontSize={'1.5rem'}
                                      background={'#be0000'}
                                      color={'#ffffff'}
                                      aria-label='Eliminar'
                                      icon={<MdDelete />}
                                    />
                                  </Tooltip>
                                </Td> */}
                                  </Tr>
                                </Tbody>
                              );
                            })}
                          </Table>
                        </TableContainer>
                        <Flex
                          marginTop={"0.5%"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={"0.5%"}
                        >
                          <Icon
                            onClick={() => {
                              prevPage5();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDropleftCircle}
                          />
                          <Icon
                            onClick={() => {
                              nextPage5();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDroprightCircle}
                          />
                        </Flex>
                      </Flex>
                    </TabPanel>
                    {/* ----------------------------------------- */}
                    <TabPanel
                      w={"100%"}
                      bg={"#ffff"}
                      borderRadius="10px"
                      p={"2%"}
                      boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                    >
                      <Flex
                        direction="column"
                        w={"100%"}
                        h="100%"
                        gap="20px"
                        justifyContent="center"
                      >
                        <Flex
                          w={"100%"}
                          justifyContent="flex-end"
                          marginBottom={"2%"}
                        >
                          <TableContainer w={"auto"}>
                            <Table variant="simple">
                              <Thead>
                                <Tr>
                                  <Th textAlign={"center"} fontSize={"1.5rem"}>
                                    Monto Confirmado
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody fontSize={"1.6rem"}>
                                <Tr>
                                  <Td
                                    textAlign={"center"}
                                    fontWeight={"600"}
                                    fontFamily="monospace"
                                  >
                                    {filterTotalPendiente?.pendiente_total}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </TableContainer>

                          {/* <Button
                            w={'15%'}
                            height={'40px'}
                            borderRadius='10px'
                            color={'white'}
                            fontSize={'1.6rem'}
                            bg='#2a2e6e'
                            p={'1% 1.5%'}
                            textTransform={'uppercase'}>
                            Agregar
                          </Button> */}
                        </Flex>
                        <TableContainer w={"100%"}>
                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                <Th fontSize={"1.8rem"}>FECHA</Th>
                                <Th fontSize={"1.8rem"}>CUENTA BANCO</Th>
                                <Th fontSize={"1.8rem"}>CASA</Th>
                                <Th fontSize={"1.8rem"}>PENDIENTE</Th>
                                <Th fontSize={"1.8rem"}>MONEDA</Th>
                                <Th fontSize={"1.8rem"}>COMISION</Th>
                                <Th fontSize={"1.8rem"} isNumeric>
                                  ACCION
                                </Th>
                              </Tr>
                            </Thead>
                            {ListFiltered5().map((itemPendiente) => {
                              return (
                                <Tbody fontSize={"1.6rem"}>
                                  <Tr>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {itemPendiente.fecha_registro}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {
                                        itemPendiente.cuenta_casa
                                          .cuenta_bancarium.denominacion
                                      }
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {itemPendiente.cuenta_casa.casa.nombre}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemPendiente.pendiente}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemPendiente.cuenta_casa.moneda.nombre}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {itemPendiente.comision}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                      isNumeric
                                    >
                                      <Tooltip
                                        hasArrow
                                        borderRadius={"4px"}
                                        fontSize={"1.2rem"}
                                        label="Confirmar"
                                        bg="#292F36"
                                      >
                                        <IconButton
                                          onClick={() => {
                                            handleConfirmedPendiente(
                                              itemPendiente.movimiento_id
                                            );
                                          }}
                                          size={"md"}
                                          borderRadius={"4px"}
                                          fontSize={"1.5rem"}
                                          background={"#15296f"}
                                          color={"#ffffff"}
                                          aria-label="Confirmar"
                                          icon={<BsFillCalendar2CheckFill />}
                                        />
                                      </Tooltip>
                                    </Td>
                                  </Tr>
                                </Tbody>
                              );
                            })}
                          </Table>
                        </TableContainer>
                        <Flex
                          marginTop={"0.5%"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={"0.5%"}
                        >
                          <Icon
                            onClick={() => {
                              prevPage5();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDropleftCircle}
                          />
                          <Icon
                            onClick={() => {
                              nextPage5();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDroprightCircle}
                          />
                        </Flex>
                      </Flex>
                    </TabPanel>
                    {/* ----------------------------------------- */}
                    <TabPanel
                      w={"100%"}
                      bg={"#ffff"}
                      borderRadius="10px"
                      p={"2%"}
                      boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                    >
                      <Flex
                        direction="column"
                        w={"100%"}
                        h="100%"
                        gap="20px"
                        justifyContent="center"
                      >
                        {searchSala != "" && (
                          <Flex w={"100%"} justifyContent="space-between">
                            <Flex
                              width={{
                                "2xl": "20%",
                                xl: "26%",
                                lg: "25%",
                                md: "40%",
                              }}
                            >
                              <Select
                                // value={updateCBanco.id_colaborador}
                                // onChange={handleChangeCBanco}
                                value={searchCategoria}
                                onChange={(e) => {
                                  setSearchCategoria(e.target.value);
                                }}
                                w="100%"
                                height={"40px"}
                                fontFamily="monospace"
                                fontWeight={"600"}
                                textTransform="uppercase"
                                variant="filled"
                                name="sala"
                                border={"1px solid"}
                                borderColor={"#2a2e6e"}
                                bgColor={"white"}
                                fontSize={"1.8rem"}
                                h="35"
                              >
                                <option disabled value="" selected>
                                  Filtrado de Categorias
                                </option>
                                <option value="all">TODOS</option>
                                {listCategories &&
                                  listCategories.map((itemCategory, i) => (
                                    <option key={i} value={itemCategory.value}>
                                      {itemCategory.name}
                                    </option>
                                  ))}
                              </Select>
                            </Flex>

                            <Flex w={"40%"} alignItems={"center"} gap={"1.5%"}>
                              <Input
                                placeholder="Fecha de inicio"
                                onChange={handleChangeSearchGasto}
                                value={searchGasto.date_initial}
                                name="date_initial"
                                size="md"
                                type="date"
                                bg={"white"}
                                height={"40px"}
                                fontSize={"1.8rem"}
                                color="#2a2e6e"
                                borderColor={"#2a2e6e"}
                                _dark={{
                                  color: "white",
                                  borderColor: "#686868",
                                  bg: "#686868",
                                }}
                              />
                              <Text fontSize={"1.8rem"}>al</Text>
                              <Input
                                placeholder="Fecha de inicio"
                                value={searchGasto.date_end}
                                onChange={handleChangeSearchGasto}
                                name="date_end"
                                size="md"
                                type="date"
                                bg={"white"}
                                height={"40px"}
                                fontSize={"1.8rem"}
                                color="#2a2e6e"
                                borderColor={"#2a2e6e"}
                                _dark={{
                                  color: "white",
                                  borderColor: "#686868",
                                  bg: "#686868",
                                }}
                                // onChange={handleFecha}
                              />
                              <Stack align="center" width={"48%"}>
                                <Button
                                  // onClick={buscarFechas}
                                  onClick={handleClickSearchGasto}
                                  w={"100%"}
                                  height={"40px"}
                                  borderRadius="10px"
                                  color={"white"}
                                  fontSize={"1.6rem"}
                                  bg="#2a2e6e"
                                  _dark={{
                                    color: "white",
                                    bg: "#f59f2c",
                                  }}
                                >
                                  Buscar
                                </Button>
                              </Stack>
                            </Flex>

                            <Button
                              w={"15%"}
                              onClick={onOpen4}
                              height={"40px"}
                              borderRadius="10px"
                              color={"white"}
                              fontSize={"1.6rem"}
                              bg="#2a2e6e"
                              p={"1% 1.5%"}
                              textTransform={"uppercase"}
                            >
                              Agregar
                            </Button>
                          </Flex>
                        )}

                        <TableContainer w={"100%"}>
                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                <Th fontSize={"1.8rem"}>ID</Th>
                                <Th fontSize={"1.8rem"}>FECHA</Th>
                                {/* <Th fontSize={"1.8rem"}>TIPO</Th> */}
                                <Th fontSize={"1.8rem"}>Categoria</Th>
                                <Th fontSize={"1.8rem"}>MONTO</Th>
                                <Th fontSize={"1.8rem"}>MONEDA</Th>
                                <Th fontSize={"1.8rem"}>Comentario</Th>

                                <Th fontSize={"1.8rem"} isNumeric>
                                  ACCION
                                </Th>
                              </Tr>
                            </Thead>
                            {ListFiltered4().map((p) => {
                              return (
                                <Tbody fontSize={"1.6rem"}>
                                  <Tr>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {p.id_gastos}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {p.fecha_registro}
                                    </Td>
                                    {/* <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {p.tipo}
                                    </Td> */}
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {p.categoria}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {p.monto}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {p.moneda.nombre}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {p.comentario}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                      isNumeric
                                    >
                                      <Tooltip
                                        hasArrow
                                        borderRadius={"4px"}
                                        fontSize={"1.2rem"}
                                        label="Eliminar"
                                        bg="#292F36"
                                      >
                                        <IconButton
                                          onClick={() => {
                                            deletegastos(p.id_gastos);
                                          }}
                                          size={"md"}
                                          borderRadius={"4px"}
                                          fontSize={"1.5rem"}
                                          background={"#be0000"}
                                          color={"#ffffff"}
                                          aria-label="Eliminar"
                                          icon={<MdDelete />}
                                        />
                                      </Tooltip>
                                    </Td>
                                  </Tr>
                                </Tbody>
                              );
                            })}
                          </Table>
                        </TableContainer>
                        <Flex
                          marginTop={"0.5%"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={"0.5%"}
                        >
                          <Icon
                            onClick={() => {
                              prevPage4();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDropleftCircle}
                          />
                          <Icon
                            onClick={() => {
                              nextPage4();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDroprightCircle}
                          />
                        </Flex>
                      </Flex>
                    </TabPanel>
                    {/* ----------------------------------------- */}
                    <TabPanel
                      w={"100%"}
                      bg={"#ffff"}
                      borderRadius="10px"
                      p={"2%"}
                      boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                    >
                      <Flex
                        direction="column"
                        w={"100%"}
                        h="100%"
                        gap="20px"
                        justifyContent="center"
                      >
                        {searchSala != "" && (
                          <Flex w={"100%"} justifyContent="space-between">
                            {/* <Flex
                              width={{
                                '2xl': '20%',
                                xl: '26%',
                                lg: '25%',
                                md: '40%'
                              }}>
                              <Select
                                // value={updateCBanco.id_colaborador}
                                // onChange={handleChangeCBanco}
                                value={searchCategoria}
                                onChange={(e) => {
                                  setSearchCategoria(e.target.value);
                                }}
                                w='100%'
                                height={'40px'}
                                fontFamily='monospace'
                                fontWeight={'600'}
                                textTransform='uppercase'
                                variant='filled'
                                name='sala'
                                border={'1px solid'}
                                borderColor={'#2a2e6e'}
                                bgColor={'white'}
                                fontSize={'1.8rem'}
                                h='35'>
                                <option disabled value='' selected>
                                  Filtrado de Categorias
                                </option>
                                <option value='all'>TODOS</option>
                                {listCategories &&
                                  listCategories.map(
                                    (itemCategory, i) => (
                                      <option
                                        key={i}
                                        value={itemCategory.value}>
                                        {itemCategory.name}
                                      </option>
                                    )
                                  )}
                              </Select>
                            </Flex> */}

                            <Flex w={"40%"} alignItems={"center"} gap={"1.5%"}>
                              <Input
                                placeholder="Fecha Inicio"
                                onChange={handleChangeSearchRecarga}
                                value={searchRecarga.date_initial}
                                name="date_initial"
                                size="md"
                                type="date"
                                bg={"white"}
                                height={"40px"}
                                fontSize={"1.8rem"}
                                color="#2a2e6e"
                                borderColor={"#2a2e6e"}
                                _dark={{
                                  color: "white",
                                  borderColor: "#686868",
                                  bg: "#686868",
                                }}
                              />
                              <Text fontSize={"1.8rem"}>al</Text>
                              <Input
                                placeholder="Fecha Final"
                                value={searchRecarga.date_end}
                                onChange={handleChangeSearchRecarga}
                                name="date_end"
                                size="md"
                                type="date"
                                bg={"white"}
                                height={"40px"}
                                fontSize={"1.8rem"}
                                color="#2a2e6e"
                                borderColor={"#2a2e6e"}
                                _dark={{
                                  color: "white",
                                  borderColor: "#686868",
                                  bg: "#686868",
                                }}
                              />
                              <Stack align="center" width={"48%"}>
                                <Button
                                  onClick={handleClickSearchRecarga}
                                  w={"100%"}
                                  height={"40px"}
                                  borderRadius="10px"
                                  color={"white"}
                                  fontSize={"1.6rem"}
                                  bg="#2a2e6e"
                                  _dark={{
                                    color: "white",
                                    bg: "#f59f2c",
                                  }}
                                >
                                  Buscar
                                </Button>
                              </Stack>
                            </Flex>

                            {/* <Button
                              w={'15%'}
                              onClick={onOpen4}
                              height={'40px'}
                              borderRadius='10px'
                              color={'white'}
                              fontSize={'1.6rem'}
                              bg='#2a2e6e'
                              p={'1% 1.5%'}
                              textTransform={'uppercase'}>
                              Agregar
                            </Button> */}
                          </Flex>
                        )}

                        <TableContainer w={"100%"}>
                          <Table variant="simple">
                            <Thead>
                              <Tr>
                                <Th fontSize={"1.8rem"}>DENOMINACIÓN</Th>
                                <Th fontSize={"1.8rem"}>MONTO</Th>
                                <Th fontSize={"1.8rem"}>COMISION</Th>
                                <Th fontSize={"1.8rem"}>COMENTARIO</Th>
                                <Th fontSize={"1.8rem"}>MODULO</Th>

                                <Th fontSize={"1.8rem"} isNumeric>
                                  ACCION
                                </Th>
                              </Tr>
                            </Thead>
                            {listRecargas.map((p) => {
                              return (
                                <Tbody fontSize={"1.6rem"}>
                                  <Tr>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {p.denominacion}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                    >
                                      {p.monto}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {p.comision}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {p.comentario}
                                    </Td>
                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      {p.modulo}
                                    </Td>

                                    <Td
                                      fontWeight={"600"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                      isNumeric
                                    >
                                      <Tooltip
                                        hasArrow
                                        borderRadius={"4px"}
                                        fontSize={"1.2rem"}
                                        label="Asignar"
                                        bg="#292F36"
                                      >
                                        <IconButton
                                          onClick={() => {
                                            onOpenRecargas();
                                          }}
                                          size={"md"}
                                          borderRadius={"4px"}
                                          fontSize={"1.5rem"}
                                          background={"#2449D2"}
                                          color={"#ffffff"}
                                          aria-label="Asignar"
                                          icon={<MdAssignmentAdd />}
                                        />
                                      </Tooltip>
                                    </Td>
                                  </Tr>
                                </Tbody>
                              );
                            })}
                          </Table>
                        </TableContainer>
                        <Flex
                          marginTop={"0.5%"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={"0.5%"}
                        >
                          <Icon
                            onClick={() => {
                              prevPage4();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDropleftCircle}
                          />
                          <Icon
                            onClick={() => {
                              nextPage4();
                            }}
                            color={"#13235b"}
                            cursor={"pointer"}
                            boxSize="16"
                            _hover={{
                              color: "gray !important",
                            }}
                            _dark={{
                              color: "#f59f2c",
                            }}
                            as={IoMdArrowDroprightCircle}
                          />
                        </Flex>
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>

      {/* ---------------------------------Modales-------------------------------------------------- */}

      {/* ---------------Agregar Depositos/Retiros--------- */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW={"3xl"} p={"1%"}>
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Depósito y Retiro
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex width={"100%"} justifyContent={"space-between"}>
              <Flex
                direction={"column"}
                alignItems="center"
                gap="12px"
                w={"100%"}
                h="100%"
              >
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Sala:
                  </FormLabel>
                  <Select
                    onChange={handleChangeMovimiento}
                    value={dataMovement.id_sala}
                    name="id_sala"
                    w="100%"
                    fontSize={"1.6rem"}
                    height={"40px"}
                    fontFamily="monospace"
                    fontWeight={"600"}
                    textTransform="uppercase"
                    variant="filled"
                  >
                    <option value="" selected>
                      Seleccionar
                    </option>
                    {listSala &&
                      listSala.map((itemSala) => (
                        <option key={itemSala.id_sala} value={itemSala.id_sala}>
                          {itemSala.sala.nombre}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Cuenta Banco (Denominación):
                  </FormLabel>
                  <Select
                    onChange={handleChangeMovimiento}
                    value={dataMovement.id_cuenta_origen}
                    name="id_cuenta_origen"
                    w="100%"
                    fontSize={"1.6rem"}
                    height={"40px"}
                    fontFamily="monospace"
                    fontWeight={"600"}
                    textTransform="uppercase"
                    variant="filled"
                  >
                    <option value="" selected>
                      Seleccionar
                    </option>

                    {listbakAccount &&
                      listbakAccount.map((itemAccoun) => (
                        <option
                          key={itemAccoun.id_cuenta_bancaria}
                          value={itemAccoun.id_cuenta_bancaria}
                        >
                          {itemAccoun.denominacion}
                        </option>
                      ))}
                  </Select>
                </FormControl>
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Monto:
                    </FormLabel>
                    <Input
                      onChange={handleChangeMovimiento}
                      value={dataMovement.monto_origen}
                      name="monto_origen"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Comision:
                    </FormLabel>
                    <Input
                      onChange={handleChangeMovimiento}
                      value={dataMovement.comision}
                      name="comision"
                      fontSize={"1.8rem"}
                      placeholder="OPCIONAL"
                      h="35"
                      type="number"
                    />
                  </FormControl>
                </Flex>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Comentario:
                  </FormLabel>
                  <Textarea
                    onChange={handleChangeMovimiento}
                    value={dataMovement.comentario}
                    name="comentario"
                    placeholder="OPCIONAL"
                    fontSize={"1.8rem"}
                  />
                </FormControl>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={"100%"}
              justifyContent={"center"}
              marginTop={"2%"}
              gap={"3%"}
            >
              <Button
                onClick={() => {
                  dataMovement.tipo = "DEPOSITO";
                  handleSubmitDepositoRetiro();
                }}
                height={"35px"}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                bg="#2a2e6e"
                _hover={{
                  color: "white",
                  bg: "#f59f2c",
                }}
                p="2%"
              >
                Depósito
              </Button>
              <Button
                onClick={() => {
                  dataMovement.tipo = "RETIRO";
                  handleSubmitDepositoRetiro();
                }}
                height={"35px"}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                colorScheme="red"
                p="2% 4%"
              >
                Retiro
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* ---------------Agregar Tranferencia--------- */}
      <Modal isOpen={isOpen2} onClose={onClose2} isCentered>
        <ModalOverlay />
        <ModalContent maxW={"4xl"} p={"1%"}>
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Tranferencia
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex width={"100%"} justifyContent={"space-between"}>
              <Flex
                direction={"column"}
                alignItems="center"
                gap="12px"
                w={"100%"}
                h="100%"
              >
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Sala:
                  </FormLabel>
                  <Select
                    onChange={handleChangeMovimiento}
                    value={dataMovement.id_sala}
                    name="id_sala"
                    w="100%"
                    fontSize={"1.6rem"}
                    height={"40px"}
                    fontFamily="monospace"
                    fontWeight={"600"}
                    textTransform="uppercase"
                    variant="filled"
                  >
                    <option value="" selected>
                      Seleccionar
                    </option>
                    {listSala &&
                      listSala.map((itemSala) => (
                        <option key={itemSala.id_sala} value={itemSala.id_sala}>
                          {itemSala.sala.nombre}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Cuenta Origen:
                  </FormLabel>
                  <Select
                    onChange={handleChangeMovimiento}
                    value={dataMovement.id_cuenta_origen}
                    name="id_cuenta_origen"
                    w="100%"
                    fontSize={"1.6rem"}
                    height={"40px"}
                    fontFamily="monospace"
                    fontWeight={"600"}
                    textTransform="uppercase"
                    variant="filled"
                  >
                    <option value="" selected>
                      Seleccionar
                    </option>

                    {listbakAccount &&
                      listbakAccount.map((itemAccoun) => (
                        <option
                          key={itemAccoun.id_cuenta_bancaria}
                          value={itemAccoun.id_cuenta_bancaria}
                        >
                          {itemAccoun.denominacion}
                        </option>
                      ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Cuenta Destino:
                  </FormLabel>
                  <Select
                    onChange={handleChangeMovimiento}
                    value={dataMovement.id_cuenta_destino}
                    name="id_cuenta_destino"
                    w="100%"
                    fontSize={"1.6rem"}
                    height={"40px"}
                    fontFamily="monospace"
                    fontWeight={"600"}
                    textTransform="uppercase"
                    variant="filled"
                  >
                    <option value="" selected>
                      Seleccionar
                    </option>

                    {listbakAccount &&
                      listbakAccount.map((itemAccoun) => (
                        <option
                          key={itemAccoun.id_cuenta_bancaria}
                          value={itemAccoun.id_cuenta_bancaria}
                        >
                          {itemAccoun.denominacion}
                        </option>
                      ))}
                  </Select>
                </FormControl>
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Monto:
                    </FormLabel>
                    <Input
                      onChange={handleChangeMovimiento}
                      value={dataMovement.monto_origen}
                      name="monto_origen"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Comision:
                    </FormLabel>
                    <Input
                      onChange={handleChangeMovimiento}
                      value={dataMovement.comision}
                      name="comision"
                      fontSize={"1.8rem"}
                      h="35"
                      placeholder="OPCIONAL"
                      type="number"
                    />
                  </FormControl>
                </Flex>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Comentario:
                  </FormLabel>
                  <Textarea
                    onChange={handleChangeMovimiento}
                    value={dataMovement.comentario}
                    name="comentario"
                    placeholder="OPCIONAL"
                    fontSize={"1.8rem"}
                  />
                </FormControl>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"center"} marginTop={"2%"}>
              <Button
                onClick={() => {
                  dataMovement.tipo = "transferencia";
                  handleSubmitTransferencia();
                }}
                height={"35px"}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                bg="#2a2e6e"
                _hover={{
                  color: "white",
                  bg: "#f59f2c",
                }}
                p="2%"
              >
                Transferir
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* ---------------Agregar Pagos/Gastos--------- */}
      <Modal isOpen={isOpen4} onClose={onClose4} isCentered>
        <ModalOverlay />
        <ModalContent maxW={"4xl"} p={"1%"}>
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Pagos Y/O Gastos
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex width={"100%"} justifyContent={"space-between"}>
              <Flex
                direction={"column"}
                alignItems="center"
                gap="12px"
                w={"100%"}
                h="100%"
              >
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"2rem"} fontWeight="500">
                      Sala:
                    </FormLabel>
                    <Select
                      name="id_sala"
                      w="100%"
                      fontSize={"1.6rem"}
                      height={"40px"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                      //placeholder="Seleccionar"
                      textTransform="uppercase"
                      variant="filled"
                      onChange={changeGasto}
                      value={Modelgastos.id_sala}
                    >
                      <option value="" selected>
                        Seleccionar
                      </option>
                      {listSala &&
                        listSala.map((itemSala) => (
                          <option
                            key={itemSala.id_sala}
                            value={itemSala.id_sala}
                          >
                            {itemSala.sala.nombre}
                          </option>
                        ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize={"2rem"} fontWeight="500">
                      Categoria:
                    </FormLabel>
                    <Select
                      onChange={changeGasto}
                      value={Modelgastos.categoria}
                      name="categoria"
                      w="100%"
                      fontSize={"1.6rem"}
                      height={"40px"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                      placeholder="Seleccione Categoria"
                      textTransform="uppercase"
                      variant="filled"
                      // onChange={handleChangeContrato}
                      // value={dataContrato.id_sala}
                    >
                      {listCategories &&
                        listCategories.map((itemCategory, i) => (
                          <option key={i} value={itemCategory.value}>
                            {itemCategory.name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Flex>

                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Monto:
                    </FormLabel>
                    <Input
                      onChange={changeGasto}
                      value={Modelgastos.monto}
                      name="monto"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Moneda:
                    </FormLabel>
                    <Select
                      onChange={changeGasto}
                      value={Modelgastos.id_moneda}
                      name="id_moneda"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" disabled selected>
                        Elige la opción
                      </option>

                      {filterListMoneda &&
                        filterListMoneda.map((itemMoneda) => (
                          <option
                            key={itemMoneda.id_moneda}
                            value={itemMoneda.id_moneda}
                          >
                            {searchSala == "all" && `${itemMoneda.sala} -`}{" "}
                            {itemMoneda.nombre}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Flex>

                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Comentario:
                  </FormLabel>
                  <Textarea
                    onChange={changeGasto}
                    value={Modelgastos.comentario}
                    name="comentario"
                    placeholder="Escribe Tu Comentario"
                    fontSize={"1.8rem"}
                  />
                </FormControl>

                <Flex w={"100%"} justifyContent={"center"}>
                  <Stack
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={"1.5rem"}
                  >
                    <IconButton
                      onClick={() => {
                        setCaja(!isCaja);
                      }}
                      _hover={"none"}
                      _active={"none"}
                      fontSize={"1.7rem"}
                      background={"transparent"}
                      color={"blue"}
                      aria-label="check"
                      icon={
                        isCaja == true ? (
                          <ImCheckboxChecked />
                        ) : (
                          <ImCheckboxUnchecked />
                        )
                      }
                    />

                    <Text>Caja Chica</Text>
                  </Stack>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={"100%"}
              justifyContent={"center"}
              marginTop={"2%"}
              gap={"3%"}
            >
              <Button
                onClick={() => {
                  Modelgastos.tipo = "EGRESO";
                  crearEgreso();
                }}
                height={"35px"}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                colorScheme="blue"
                p="2% 4%"
              >
                Registrar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* ---------------Recargas--------------------- */}
      <Modal isOpen={isOpenRecargas} onClose={onCloseRecargas} isCentered>
        <ModalOverlay />
        <ModalContent maxW={"2xl"} p={"1%"}>
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Asignar Cuenta Bancaria
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex width={"100%"} justifyContent={"space-between"}>
              <Flex
                direction={"column"}
                alignItems="center"
                gap="12px"
                w={"100%"}
                h="100%"
              >
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"2rem"} fontWeight="500">
                      Cuentas Bancarias :
                    </FormLabel>
                    <Select
                      name="id_sala"
                      w="100%"
                      fontSize={"1.6rem"}
                      height={"40px"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                      //placeholder="Seleccionar"
                      textTransform="uppercase"
                      variant="filled"
                      onChange={changeGasto}
                      value={Modelgastos.id_sala}
                    >
                      <option value="" selected>
                        Seleccionar
                      </option>
                      {/* {listSala &&
                        listSala.map((itemSala) => (
                          <option
                            key={itemSala.id_sala}
                            value={itemSala.id_sala}>
                            {itemSala.sala.nombre}
                          </option>
                        ))} */}
                    </Select>
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={"100%"}
              justifyContent={"center"}
              marginTop={"2%"}
              gap={"3%"}
            >
              <Button
                onClick={() => {
                  // Modelgastos.tipo = 'PAGO';
                  // crearGasto();
                }}
                height={"35px"}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                bg="#2a2e6e"
                _hover={{
                  color: "white",
                  bg: "#f59f2c",
                }}
                p="2%"
              >
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* --------------------Cambio de divisas----------------- */}
      <Modal isOpen={isOpenCDivisas} onClose={onCloseCDivisas} isCentered>
        <ModalOverlay />
        <ModalContent maxW={"4xl"} p={"1%"}>
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Cambio de Divisas
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex width={"100%"} justifyContent={"space-between"}>
              <Flex
                direction={"column"}
                alignItems="center"
                gap="12px"
                w={"100%"}
                h="100%"
              >
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Sala:
                  </FormLabel>
                  <Select
                    onChange={handleChangeMovimiento}
                    value={dataMovement.id_sala}
                    name="id_sala"
                    w="100%"
                    fontSize={"1.6rem"}
                    height={"40px"}
                    fontFamily="monospace"
                    fontWeight={"600"}
                    textTransform="uppercase"
                    variant="filled"
                  >
                    <option value="" selected>
                      Seleccionar
                    </option>
                    {listSala &&
                      listSala.map((itemSala) => (
                        <option key={itemSala.id_sala} value={itemSala.id_sala}>
                          {itemSala.sala.nombre}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Cuenta Origen:
                    </FormLabel>
                    <Select
                      onChange={handleChangeMovimiento}
                      value={dataMovement.id_cuenta_origen}
                      name="id_cuenta_origen"
                      w="100%"
                      fontSize={"1.6rem"}
                      height={"40px"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                      textTransform="uppercase"
                      variant="filled"
                    >
                      <option value="" selected>
                        Seleccionar
                      </option>

                      {listbakAccount &&
                        listbakAccount.map((itemAccoun) => (
                          <option
                            key={itemAccoun.id_cuenta_bancaria}
                            value={itemAccoun.id_cuenta_bancaria}
                          >
                            {itemAccoun.denominacion}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Cuenta Destino:
                    </FormLabel>
                    <Select
                      onChange={handleChangeMovimiento}
                      value={dataMovement.id_cuenta_destino}
                      name="id_cuenta_destino"
                      w="100%"
                      fontSize={"1.6rem"}
                      height={"40px"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                      textTransform="uppercase"
                      variant="filled"
                    >
                      <option value="" selected>
                        Seleccionar
                      </option>

                      {listbakAccount &&
                        listbakAccount.map((itemAccoun) => (
                          <option
                            key={itemAccoun.id_cuenta_bancaria}
                            value={itemAccoun.id_cuenta_bancaria}
                          >
                            {itemAccoun.denominacion}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Flex>

                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Monto Origen:
                    </FormLabel>
                    <Input
                      onChange={handleChangeMovimiento}
                      value={dataMovement.monto_origen}
                      name="monto_origen"
                      fontSize={"1.8rem"}
                      h="35"
                      type="number"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Monto Destino:
                    </FormLabel>
                    <Input
                      // onChange={handleChangeMovimiento}
                      value={divisaDinamica.monto}
                      name="monto"
                      isDisabled
                      fontSize={"1.8rem"}
                      h="35"
                      readOnly
                      type="number"
                    />
                  </FormControl>
                </Flex>

                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Moneda Destino:
                    </FormLabel>
                    <Select
                      onChange={handleChangeMovimiento}
                      value={dataMovement.id_moneda_destino}
                      name="id_moneda_destino"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" selected>
                        Elige la opción
                      </option>
                      {filterListMoneda &&
                        filterListMoneda.map((itemMoneda) => (
                          <option
                            key={itemMoneda.id_moneda}
                            value={itemMoneda.id_moneda}
                          >
                            {searchSala == "all" && `${itemMoneda.sala} -`}{" "}
                            {itemMoneda.nombre}
                          </option>
                        ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Tipo de Cambio:
                    </FormLabel>
                    <Input
                      value={dataMovement.tipo_cambio}
                      onChange={handleChangeMovimiento}
                      name="tipo_cambio"
                      fontSize={"1.8rem"}
                      h="35"
                      type="number"
                    />
                  </FormControl>
                </Flex>

                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Ganancia:
                    </FormLabel>
                    <Input
                    isDisabled
                      onChange={handleChangeMovimiento}
                      value={divisaDinamica.ganancia}
                      name="ganancia"
                      fontSize={"1.8rem"}
                      h="35"
                      readOnly
                      type="number"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Perdida:
                    </FormLabel>
                    <Input
                    isDisabled
                      onChange={handleChangeMovimiento}
                      value={divisaDinamica.perdida}
                      name="perdida"
                      fontSize={"1.8rem"}
                      h="35"
                      readOnly
                      type="number"
                    />
                  </FormControl>
                </Flex>

                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Comision:
                  </FormLabel>
                  <Input
                    onChange={handleChangeMovimiento}
                    value={dataMovement.comision}
                    name="comision"
                    fontSize={"1.8rem"}
                    h="35"
                    type="number"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Comentario:
                  </FormLabel>
                  <Textarea
                    onChange={handleChangeMovimiento}
                    value={dataMovement.comentario}
                    name="comentario"
                    placeholder="Escribe Tu Comentario"
                    fontSize={"1.8rem"}
                  />
                </FormControl>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"center"} marginTop={"2%"}>
              <Button
                onClick={() => {
                  dataMovement.tipo = "DIVISA";
                  handleSubmitDivisa();
                }}
                height={"35px"}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                bg="#2a2e6e"
                _hover={{
                  color: "white",
                  bg: "#f59f2c",
                }}
                p="2%"
              >
                Agregar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default MBanco;
