import React, { Component, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  // ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  useToast,
  DrawerContent,
  DrawerOverlay,
  Drawer,
  // Avatar,
  // HStack,
  // Link,
  IconButton,
  Button,
  // Menu,
  // MenuButton,
  // MenuList,
  // MenuItem,
  // Container,
  // Img,
  // MenuDivider,
  useDisclosure,
  // useColorModeValue,
  Stack,
  // MenuGroup,
  // Popover,
  // Divider,
  // PopoverTrigger,
  // PopoverContent,
  // PopoverArrow,
  // NavItem,
  // Collapse,
  // Text,
  // Badge,
  Icon,
  // Drawer,
  // DrawerContent,
  // DrawerOverlay,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  // Tfoot,
  Tr,
  Th,
  Td,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  // TableCaption,
  TableContainer,
  FormControl,
  FormLabel,
  // FormErrorMessage,
  // Select,
  FormHelperText,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  // Center,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  // Highlight,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import { RiEditFill } from "react-icons/ri";
import { FaCheckCircle } from "react-icons/fa";
import { MdDoDisturbOff } from "react-icons/md";
import { IoIosAddCircle } from "react-icons/io";
import { Search2Icon } from "@chakra-ui/icons";
import { FiMenu } from "react-icons/fi";
import SidebarOperators from "../../../components/SidebarOperators";
import { host3 } from "../../../utils/constantes";
// import moment from 'moment';
import axios from "axios";
import {
  IoMdArrowDroprightCircle,
  IoMdArrowDropleftCircle,
} from "react-icons/io";
import jwt_decode from "jwt-decode";
import "../../../theme/styles";
import ListMonedas from "../../../sample/listMonedas.json";
import casas from "../../../sample/listCasasMantenimiento.json";

function Mantenimiento() {
  let history = useHistory();
  const [activo1, setActivo1] = useState(false);
  const [activo2, setActivo2] = useState(false);
  const [activo3, setActivo3] = useState(false);
  const [activo4, setActivo4] = useState(false);
  const token = localStorage.getItem("tokenadmin");
  const { id_persona, rol } = jwt_decode(token);
  //const toast = useToast();
  useEffect(() => {
    if (token) {
      if (rol.includes("TMONEY")) {
        history.push("/salas/MSalas");
      }
      if (rol.includes("INVERSIONISTA")) {
        history.push("/salas/administracion");
      }
    }
  }, []);

  const ListOperadores = [
    {
      nom: "MOVISTAR",
    },
    {
      nom: "CLARO",
    },
    {
      nom: "BITEL",
    },
    {
      nom: "ENTEL",
    },
  ];

  const [search, setSearch] = useState("");
  const [, setTiempo] = useState(false);

  //Mensaje
  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        break;
    }
  };

  // --------------LISTAR CASAS---------------------------------
  const url = `${host3}/api/casas/${token}/listar/${id_persona}`;
  const [listCasas, setListCasas] = useState([]);

  const getCasas = async () => {
    await axios.get(url).then((response) => {
      setListCasas(response.data);
      //console.log(response.data);
    });
  };

  const ListFiltered = () => {
    return listCasas?.slice(currentPage, currentPage + 12);
  };

  // --------------LISTAR BANCOS------------------------------------

  const url1 = `${host3}/api/bancos/${token}/listar/${id_persona}`;
  const [listBancos, setListBancos] = useState([]);

  const getBanco = async () => {
    await axios.get(url1).then((response) => {
      setListBancos(response.data);
    });
  };

  const ListFiltered2 = () => {
    return listBancos?.slice(currentPage2, currentPage2 + 12);
  };

  // --------------LISTAR MONEDAS------------------------------------
  const url2 = `${host3}/api/monedas/${token}/listar/${id_persona}`;
  const [listMonedas, setListMonedas] = useState([]);

  const getMonedas = async () => {
    await axios.get(url2).then((response) => {
      setListMonedas(response.data);
    });
  };

  const ListFiltered3 = () => {
    return listMonedas?.slice(currentPage3, currentPage3 + 12);
  };

  // --------------LISTAR OPERADOR DE TELEFONO --------------------
  const url3 = `${host3}/api/operador-telefonos/${token}/listar/${id_persona}`;
  const [listoperadorPhone, setListOperadorPhone] = useState([]);

  const getoperadorPhone = async () => {
    await axios.get(url3).then((response) => {
      setListOperadorPhone(response.data);
    });
  };

  const ListFiltered5 = () => {
    return listoperadorPhone?.slice(currentPage5, currentPage5 + 12);
  };

  // ------------------AGREGAR CASAS-------------------------------

  const [ModelCasa, setModelCasa] = useState({
    nombre: "",
    id_sala: "",
  });

  const crearCasa = async (e) => {
    e.preventDefault();
    await axios
      .post(`${host3}/api/casas/${token}/crear`, ModelCasa, {})
      .then((res) => {
        mensaje(res.data.message, "top-right", 1);
        setModelCasa({
          nombre: "",
          id_sala: ModelCasa.id_sala,
        });
        getCasas();
      })
      .catch((err) => {
        mensaje(err.response.data.message, "top-right", 2);
      });
  };

  const ListFilteredCasas = () => {
    if (search) {
      var filtered = casas.filter(
        (c) =>
          c.nombre?.toString().includes(search) ||
          c.nombre?.toString().toUpperCase().includes(search) ||
          c.nombre?.toString().toLowerCase().includes(search)
      );
      return filtered.slice(currentPage8, currentPage8 + 8);
    } else {
      return casas?.slice(currentPage8, currentPage8 + 8);
    }
  };

  const buscarCasas = (e) => {
    setSearch(e.target.value);
  };

  // ------------------AGREGAR BANCO-------------------------------

  const [ModelBanco, setModelBanco] = useState({
    nombre: "",
    id_sala: "",
  });

  const changeBanco = (e) => {
    setModelBanco({
      ...ModelBanco,
      [e.target.name]: e.target.value,
    });
  };

  const crearBanco = async (e) => {
    e.preventDefault();
    await axios
      .post(`${host3}/api/bancos/${token}/crear`, ModelBanco, {})
      .then((res) => {
        mensaje(res.data.message, "top-right", 1);
        onClose3();
        setModelBanco({
          nombre: "",
          id_sala: "",
        });
        getBanco();
      })
      .catch((err) => {
        mensaje(err.response.data.message, "top-right", 2);
      });
  };

  // -------------------AGREGAR MONEDA---------------------------------
  const [ModelMoneda, setModelMoneda] = useState({
    nombre: "",
    nomenclatura: "",
    simbolo: "",
    id_sala: "",
  });

  const agregarMoneda = async (e) => {
    e.preventDefault();
    await axios
      .post(`${host3}/api/monedas/${token}/crear`, ModelMoneda, {})
      .then((res) => {
        mensaje(res.data.message, "top-right", 1);
        onClose();
        setModelMoneda({
          nombre: "",
          nomenclatura: "",
          simbolo: "",
          id_sala: ModelMoneda.id_sala,
        });
        getMonedas();
      })
      .catch((err) => {
        mensaje(err.response.data.message, "top-right", 2);
      });
  };

  const ListFiltered4 = () => {
    if (search) {
      var filtered = ListMonedas.filter(
        (p) =>
          p.moneda?.toString().includes(search) ||
          p.moneda?.toString().toUpperCase().includes(search) ||
          p.moneda?.toString().toLowerCase().includes(search) ||
          p.nom?.toString().includes(search) ||
          p.nom?.toString().toUpperCase().includes(search) ||
          p.nom?.toString().toLowerCase().includes(search) ||
          p.pais?.toString().includes(search) ||
          p.pais?.toString().toUpperCase().includes(search) ||
          p.pais?.toString().toLowerCase().includes(search)
      );
      return filtered.slice(currentPage4, currentPage4 + 8);
    } else {
      return ListMonedas?.slice(currentPage4, currentPage4 + 8);
    }
  };

  const buscar = (e) => {
    setSearch(e.target.value);
  };

  // --------------AGREGAR OPERADOR TELEFONO ------------------------

  const [ModelOperador, setModelOperador] = useState({
    nombre: "",
    id_sala: "",
  });

  const changeOperador = (o) => {
    setModelOperador({
      ...ModelOperador,
      [o.target.name]: o.target.value,
    });
  };

  const agregarOperador = async (o) => {
    o.preventDefault();
    await axios
      .post(`${host3}/api/operador-telefonos/${token}/crear`, ModelOperador, {})
      .then((res) => {
        mensaje(res.data.message, "top-right", 1);
        onClose7();
        setModelOperador({
          nombre: "",
          id_sala: "",
        });
        getoperadorPhone();
      })
      .catch((err) => {
        mensaje(err.response.data.message, "top-right", 2);
      });
  };

  const ListFiltered6 = () => {
    return ListOperadores?.slice(currentPage6, currentPage6 + 8);
  };

  //--------------------EDITAR CASA-----------------------------------

  const [updateCasa, setCasa] = useState({
    nombre: "",
  });

  const handleChange = (e) => {
    setModelCasa({
      ...ModelCasa,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .put(
        `${host3}/api/casas/${token}/actualizar/${ModelCasa.id_casa}`,
        ModelCasa
      )
      .then((res) => {
        mensaje(res.data.message, "top-right", 1);
        onClose2();
        setModelCasa({
          nombre: "",
          id_sala: ModelCasa.id_sala,
        });
        getCasas();
      })
      .catch((err) => {
        mensaje(err.response.data.message, "top-right", 2);
      });
  };

  //-----------------DAR DE BAJA CASA----------------------
  const darDeBaja = async (casa_id) => {
    const datosActualizados = {
      estado: false,
    };

    // Solicitud al servidor para dar de baja al usuario
    await axios
      .get(`${host3}/api/casas/${token}/baja/${casa_id}`, datosActualizados)
      .then((response) => {
        console.log("Ha sido dado de baja exitosamente");
        getCasas();
      })
      .catch((error) => {
        console.error("Error al dar de baja:", error);
      });
  };

  //-----------------ACTIVAR CASA----------------------

  const activar = async (casa_id) => {
    const datosActualizados = {
      estado: true,
    };
    await axios
      .put(`${host3}/api/casas/${token}/activar/${casa_id}`, datosActualizados)
      .then((response) => {
        console.log("Ha sido activada exitosamente");
        getCasas();
      })
      .catch((error) => {
        console.error("Error al activar la casa:", error);
      });
  };

  //----------------------EDITAR BANCO-----------------------------------

  const [updateBanco, setBanco] = useState({
    nombre: "",
  });

  const handleChange2 = (e) => {
    setBanco({
      ...updateBanco,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    await axios
      .put(`${host3}/api/bancos/${token}/actualizar`, updateBanco, {})
      .then((res) => {
        mensaje(res.data.message, "top-right", 1);
        onClose4();
        setBanco({
          nombre: "",
        });
        getBanco();
      })
      .catch((err) => {
        mensaje(err.response.data.message, "top-right", 2);
      });
  };

  //-----------------DAR DE BAJA BANCO----------------------
  const darDeBaja2 = async (banco_id) => {
    const datosActualizados = {
      estado: false,
    };

    await axios
      .get(`${host3}/api/bancos/${token}/baja/${banco_id}`, datosActualizados)
      .then((response) => {
        console.log("Ha sido dado de baja exitosamente");
        getBanco();
      })
      .catch((error) => {
        console.error("Error al dar de baja:", error);
      });
  };

  //-----------------ACTIVAR BANCO----------------------

  const activar2 = async (banco_id) => {
    const datosActualizados = {
      estado: true,
    };
    await axios
      .put(
        `${host3}/api/bancos/${token}/activar/${banco_id}`,
        datosActualizados
      )
      .then((response) => {
        console.log("Ha sido activada exitosamente");
        getBanco();
      })
      .catch((error) => {
        console.error("Error al activar la casa:", error);
      });
  };

  //-----------------DAR DE BAJA MONEDA----------------------
  const darDeBaja3 = async (moneda_id) => {
    const datosActualizados = {
      estado: false,
    };
    await axios
      .get(`${host3}/api/monedas/${token}/baja/${moneda_id}`, datosActualizados)
      .then((response) => {
        console.log("Ha sido dado de baja exitosamente");
        getMonedas();
      })
      .catch((error) => {
        console.error("Error al dar de baja:", error);
      });
  };

  //-----------------ACTIVAR MONEDA----------------------

  const activar3 = async (moneda_id) => {
    const datosActualizados = {
      estado: true,
    };
    await axios
      .put(
        `${host3}/api/monedas/${token}/activar/${moneda_id}`,
        datosActualizados
      )
      .then((response) => {
        console.log("Ha sido activada exitosamente");
        getMonedas();
      })
      .catch((error) => {
        console.error("Error al activar la casa:", error);
      });
  };

  //---------------EDITAR OPERADOR TELEFONO-----------------------------

  const [updateOperador, setOperador] = useState({
    nombre: "",
  });

  const handleChange4 = (e) => {
    setOperador({
      ...updateOperador,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit4 = async (e) => {
    e.preventDefault();
    await axios
      .put(
        `${host3}/api/operador-telefonos/${token}/actualizar`,
        updateOperador,
        {}
      )
      .then((res) => {
        mensaje(res.data.message, "top-right", 1);
        onClose8();
        setOperador({
          nombre: "",
        });
        getoperadorPhone();
      })
      .catch((err) => {
        mensaje(err.response.data.message, "top-right", 2);
      });
  };

  //-----------------DAR DE BAJA PHONE----------------------
  const darDeBaja4 = async (id_operador_phone) => {
    const datosActualizados = {
      estado: false,
    };
    await axios
      .get(
        `${host3}/api/operador-telefonos/${token}/baja/${id_operador_phone}`,
        datosActualizados
      )
      .then((response) => {
        console.log("Ha sido dado de baja exitosamente");
        getoperadorPhone();
      })
      .catch((error) => {
        console.error("Error al dar de baja:", error);
      });
  };

  //-----------------ACTIVAR PHONE----------------------

  const activar4 = async (id_operador_phone) => {
    const datosActualizados = {
      estado: true,
    };
    await axios
      .put(
        `${host3}/api/operador-telefonos/${token}/activar/${id_operador_phone}`,
        datosActualizados
      )
      .then((response) => {
        console.log("Ha sido activada exitosamente");
        getoperadorPhone();
      })
      .catch((error) => {
        console.error("Error al activar la casa:", error);
      });
  };

  //Paginacion
  const [currentPage, setCurrentPage] = useState(0);

  const nextPage = () => {
    const cant = listCasas.filter((p) => p.casa_id).length;

    if (cant > currentPage) {
      setCurrentPage(currentPage + 12);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 12);
    }
  };
  //Paginacion Casas

  const [currentPageC, setCurrentPageC] = useState(0);
  const nextPageC = () => {
    const cant = casas.filter((c) => c.casas.toString()).length;
    if (cant > currentPageC) {
      setCurrentPage4(currentPageC + 8);
    }
  };

  const prevPageC = () => {
    if (currentPageC > 0) {
      setCurrentPageC(currentPageC - 8);
    }
  };

  // ---------------------------

  //Paginacion banco
  const [currentPage2, setCurrentPage2] = useState(0);

  const nextPage2 = () => {
    const cant = listBancos.filter((p) => p.banco_id).length;

    if (cant > currentPage2) {
      setCurrentPage2(currentPage2 + 12);
    }
  };

  const prevPage2 = () => {
    if (currentPage2 > 0) {
      setCurrentPage2(currentPage2 - 12);
    }
  };

  //Paginacion moneda
  const [currentPage3, setCurrentPage3] = useState(0);

  const nextPage3 = () => {
    const cant = listCasas.filter((p) =>
      p.nombre.toString().includes(search)
    ).length;

    if (cant > currentPage3) {
      setCurrentPage3(currentPage3 + 8);
    }
  };

  const prevPage3 = () => {
    if (currentPage3 > 0) {
      setCurrentPage3(currentPage3 - 8);
    }
  };
  //Paginacion casas
  const [currentPage8, setCurrentPage8] = useState(0);

  const nextPage8 = () => {
    const cant = casas.filter((p) =>
      p.casaF_id.toString().includes(search)
    ).length;

    if (cant > currentPage8) {
      setCurrentPage8(currentPage8 + 8);
    }
  };

  const prevPage8 = () => {
    if (currentPage8 > 0) {
      setCurrentPage8(currentPage8 - 8);
    }
  };
  //Paginacion agregar moneda
  const [currentPage4, setCurrentPage4] = useState(0);
  const nextPage4 = () => {
    const cant = ListMonedas.filter((p) => p.moneda.toString()).length;
    if (cant > currentPage4) {
      setCurrentPage4(currentPage4 + 8);
    }
  };

  const prevPage4 = () => {
    if (currentPage4 > 0) {
      setCurrentPage4(currentPage4 - 8);
    }
  };

  //Paginacion operador
  const [currentPage5, setCurrentPage5] = useState(0);
  const nextPage5 = () => {
    const cant = listoperadorPhone.filter((p) => p.id_operador_phone).length;
    if (cant > currentPage5) {
      setCurrentPage5(currentPage5 + 13);
    }
  };

  const prevPage5 = () => {
    if (currentPage5 > 0) {
      setCurrentPage5(currentPage5 - 13);
    }
  };

  //Paginacion agregar operador
  const [currentPage6, setCurrentPage6] = useState(0);
  const nextPage6 = () => {
    const cant = ListOperadores.filter((p) => p.nom.toString()).length;

    if (cant > currentPage6) {
      setCurrentPage6(currentPage6 + 8);
    }
  };

  const prevPage6 = () => {
    if (currentPage6 > 0) {
      setCurrentPage6(currentPage6 - 8);
    }
  };
  // ---------------------------

  // ---------------------Casas-----------------------
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  // ---------------------Bancos-----------------------

  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();

  const {
    isOpen: isOpen4,
    onOpen: onOpen4,
    onClose: onClose4,
  } = useDisclosure();

  // ---------------------Monedas-------------------
  const {
    isOpen: isOpen5,
    onOpen: onOpen5,
    onClose: onClose5,
  } = useDisclosure();

  const {
    isOpen: isOpen6,
    onOpen: onOpen6,
    onClose: onClose6,
  } = useDisclosure();

  // ---------------------Operador Telefono-------------------
  const {
    isOpen: isOpen7,
    onOpen: onOpen7,
    onClose: onClose7,
  } = useDisclosure();

  const {
    isOpen: isOpen8,
    onOpen: onOpen8,
    onClose: onClose8,
  } = useDisclosure();

  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar,
  } = useDisclosure();

  useEffect(() => {
    // getMoneda();
    getCasas();
    getBanco();
    getMonedas();
    getoperadorPhone();
    setTimeout(() => {
      setTiempo(true);
    }, 8000);
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: "1.2rem", textAlign: "center" }}
        pauseOnHover
      />
      <Box
        scrollBehavior={"smooth"}
        bg={"#e5e5e5"}
        height={"auto"}
        maxHeight={"100vh"}
        overflowY={"auto"}
        css={{
          "&::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#6b6b6b",
            borderRadius: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#9b9b9b",
            borderRadius: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#9b9b9b",
          },
        }}
      >
        <Box
          as="section"
          display={{
            "2xl": "unset",
            xl: "unset",
            lg: "none",
            md: "none",
            sm: "none",
          }}
        >
          <SidebarOperators />
        </Box>
        <Drawer isOpen={isOpenBar} onClose={onCloseBar} placement="left">
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w="full" borderRight="none" />
          </DrawerContent>
        </Drawer>

        <Box h={"100vh"}>
          <Flex
            alignItems={"center"}
            justify={"flex-start"}
            // h={'100%'}
            sx={{
              "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
                {
                  padding: "1.5% 3% 1.5% 19%",
                },
            }}
            p={{
              "2xl": "1.5% 3% 1.5% 15%",
              xl: "1.5% 3% 1.5% 18%",
              lg: "1.5% 2% 1.5% 2%",
              md: "1.5% 2% 1.5% 2%",
            }}
            // paddingLeft={'15%'}
            // paddingTop='1%'
            // paddingRight={'1%'}
            // paddingBottom='1%'
            direction="column"
            gap={"25px"}
          >
            <Box transition=".3s ease" width={"100%"}>
              <Flex gap="2%" alignItems={"center"} as="header" w="full" h="14">
                <IconButton
                  aria-label="Menu"
                  display={{
                    "2xl": "none",
                    xl: "none",
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                  }}
                  background={"#292F36"}
                  color={"#ffffff"}
                  borderRadius={"4px"}
                  fontSize={"1.3rem"}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size="md"
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink color={"#707070"} fontSize={"1.5rem"}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={"bold"}
                      color={"#292F36"}
                      fontSize={"1.5rem"}
                    >
                      MANTENIMIENTO
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>
            {/* --------------------------------------------------------------------------------------- */}
            <Flex w={{ "2xl": "90%", xl: "82%", lg: "100%", md: "100%" }}>
              <Flex
                w={"100%"}
                //  h='85vh'
              >
                <Tabs isFitted variant="enclosed" w={"100%"}>
                  <TabList mb="1em" bg={"#FFFF"} borderRadius="15px" p={"0.5%"}>
                    <Tab
                      borderRadius={"15px"}
                      _selected={{
                        color: "white",
                        bg: "#2a2e6e",
                      }}
                      sx={{
                        "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
                          {
                            fontSize: "1.7rem",
                          },
                      }}
                      fontSize={{
                        "2xl": "2.1rem",
                        xl: "1.84rem",
                        lg: "1.84rem",
                        md: "1.84rem",
                      }}
                      textTransform={"uppercase"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                    >
                      Casas
                    </Tab>
                    <Tab
                      borderRadius={"15px"}
                      _selected={{
                        color: "white",
                        bg: "#2a2e6e",
                      }}
                      sx={{
                        "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
                          {
                            fontSize: "1.7rem",
                          },
                      }}
                      fontSize={{
                        "2xl": "2.1rem",
                        xl: "1.84rem",
                        lg: "1.84rem",
                        md: "1.84rem",
                      }}
                      textTransform={"uppercase"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                    >
                      Bancos
                    </Tab>
                    <Tab
                      borderRadius={"15px"}
                      _selected={{
                        color: "white",
                        bg: "#2a2e6e",
                      }}
                      sx={{
                        "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
                          {
                            fontSize: "1.7rem",
                          },
                      }}
                      fontSize={{
                        "2xl": "2.1rem",
                        xl: "1.84rem",
                        lg: "1.84rem",
                        md: "1.84rem",
                      }}
                      textTransform={"uppercase"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                    >
                      Monedas
                    </Tab>
                    <Tab
                      borderRadius={"15px"}
                      _selected={{
                        color: "white",
                        bg: "#2a2e6e",
                      }}
                      sx={{
                        "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
                          {
                            fontSize: "1.7rem",
                          },
                      }}
                      fontSize={{
                        "2xl": "2.1rem",
                        xl: "1.84rem",
                        lg: "1.84rem",
                        md: "1.84rem",
                      }}
                      textTransform={"uppercase"}
                      fontFamily="monospace"
                      fontWeight={"600"}
                    >
                      Operador de Telefono
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel
                      w={"100%"}
                      bg={"transparent"}
                      borderRadius="10px"
                      p={"2%"}
                      // boxShadow='rgba(0, 0, 0, 0.35) 0px 5px 15px;'
                    >
                      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                        {ListFiltered()?.map((itemSala) => {
                          return (
                            <GridItem>
                              <Flex
                                w={"100%"}
                                h="100%"
                                bg={"white"}
                                borderRadius="10px"
                                boxShadow={"rgba(0, 0, 0, 0.16) 0px 1px 4px"}
                                padding={"2% 3% 3% 3%"}
                                gap={"6px"}
                                justifyContent="center"
                                direction={"column"}
                              >
                                {/* <Flex
                              width={'100%'}
                              marginBottom={'1%'}
                              justifyContent={'center'}>
                              <Text
                                fontWeight={'normal'}
                                fontSize={'2rem'}>
                                <Highlight
                                  query='SALA:'
                                  styles={{
                                    // py: '1',
                                    fontWeight: '900'
                                  }}>
                                  {p.nombre} 
                                </Highlight>
                              </Text>
                            </Flex> */}

                                <Flex width={"100%"} justifyContent={"right"}>
                                  <Button
                                    w={"auto"}
                                    height={"40px"}
                                    borderRadius="10px"
                                    color={"white"}
                                    fontSize={"1.6rem"}
                                    bg="#2a2e6e"
                                    onClick={() => {
                                      ModelCasa.id_sala = itemSala.sala.id_sala;
                                      onOpen();
                                    }}
                                    p={"1% 1.5%"}
                                    textTransform={"uppercase"}
                                  >
                                    Agregar Casas
                                  </Button>
                                </Flex>

                                <Accordion w={"100%"}>
                                  <AccordionItem
                                    w={"100%"}
                                    borderStyle={"none"}
                                    justifyContent={"center"}
                                  >
                                    <AccordionButton
                                      paddingRight={"2%"}
                                      color={"#ffffff"}
                                      _hover={{
                                        bg: "#292F36",
                                      }}
                                      _focus={{
                                        outline: "none",
                                      }}
                                      _dark={{
                                        bg: "#292F36",
                                      }}
                                      cursor={"auto"}
                                      background={"#292F36"}
                                      boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                                      height={"45px"}
                                      borderRadius={"8px"}
                                    >
                                      <Table>
                                        <Thead>
                                          <Tr width={"100%"}>
                                            <Th
                                              color={"#ffffff"}
                                              border={"none"}
                                              width={"33%"}
                                              fontSize={"1.8rem"}
                                            >
                                              SALA
                                            </Th>
                                            <Th
                                              textAlign={"center"}
                                              color={"#ffffff"}
                                              border={"none"}
                                              width={"33%"}
                                              fontSize={"1.8rem"}
                                            ></Th>
                                            <Th
                                              color={"#ffffff"}
                                              border={"none"}
                                              width={"33%"}
                                              isNumeric
                                              fontSize={"1.8rem"}
                                            ></Th>
                                          </Tr>
                                        </Thead>
                                      </Table>
                                    </AccordionButton>
                                  </AccordionItem>
                                </Accordion>

                                <Accordion
                                  w={"100%"}
                                  gap={"15px"}
                                  // defaultIndex={[0]}
                                  allowMultiple
                                >
                                  <Flex
                                    w={"100%"}
                                    gap={"15px"}
                                    direction={"column"}
                                  >
                                    <AccordionItem
                                      // key={itemModulo.id_modulo}
                                      w={"100%"}
                                      borderStyle={"none"}
                                      justifyContent={"center"}
                                    >
                                      <AccordionButton
                                        _hover={{
                                          bg: "white",
                                        }}
                                        _focus={{
                                          outline: "none",
                                        }}
                                        _dark={{
                                          bg: "#292929",
                                        }}
                                        boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                                        bg={"white"}
                                        height={"45px"}
                                        borderRadius={"8px"}
                                      >
                                        <Table>
                                          <Tbody fontSize={"1.7rem"}>
                                            <Tr width={"100%"}>
                                              <Td
                                                width={"45.5%"}
                                                fontWeight={"600"}
                                                fontFamily="monospace"
                                              >
                                                {itemSala.sala.nombre}
                                              </Td>
                                              <Td
                                                width={"50%"}
                                                isNumeric
                                                fontWeight={"600"}
                                                fontFamily="monospace"
                                              ></Td>
                                            </Tr>
                                          </Tbody>
                                        </Table>

                                        <AccordionIcon fontSize={"1.6rem"} />
                                      </AccordionButton>

                                      <AccordionPanel
                                        padding={0}
                                        boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                                        bg={"white"}
                                        _dark={{
                                          bg: "#707070",
                                        }}
                                        borderRadius={"0 0 8px 8px"}
                                        margin={"1px 2% 1% 2%"}
                                      >
                                        {/* Eitar Modulo */}
                                        <TableContainer width={"100%"}>
                                          <Table width={"100%"}>
                                            <Thead>
                                              <Tr
                                                border={"none"}
                                                background={"#d9d9d9"}
                                                _dark={{
                                                  bg: "#464545",
                                                }}
                                                minHeight={"28px"}
                                              >
                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  text
                                                  Align={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  CASAS
                                                </Th>
                                                {/* <Th
                                                  border={'none'}
                                                  width={'33%'}
                                                  text
                                                  Align={'center'}
                                                  fontSize={'1.6rem'}
                                                  _dark={{
                                                    color: 'white'
                                                  }}
                                                  color={'#13235b'}>
                                                  ALIAS
                                                </Th> */}

                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  textAlign={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  ESTADO
                                                </Th>
                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  textAlign={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  ACCIONES
                                                </Th>
                                              </Tr>
                                            </Thead>

                                            {itemSala.sala.casas?.map(
                                              (itemCasa) => (
                                                <Tbody bg={"transparent"}>
                                                  <Tr border={"none"}>
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      // textAlign={'center'}
                                                      fontSize={"1.7rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      {itemCasa.nombre}
                                                    </Td>
                                                    {/* <Td
                                                      border={'none'}
                                                      width={'33%'}
                                                      // textAlign={'center'}
                                                      fontSize={
                                                        '1.7rem'
                                                      }
                                                      _dark={{
                                                        color: 'white'
                                                      }}
                                                      color={
                                                        '#13235b'
                                                      }>
                                                      {itemCasa.alias}
                                                    </Td> */}
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      textAlign={"center"}
                                                      fontSize={"1.7rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      {itemCasa.estado == 1
                                                        ? "Activo"
                                                        : "Inactivo"}
                                                    </Td>
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      textAlign={"center"}
                                                      fontSize={"1.6rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      <Flex
                                                        justifyContent={
                                                          "center"
                                                        }
                                                        gap={"8%"}
                                                      >
                                                        <Tooltip
                                                          hasArrow
                                                          borderRadius={"4px"}
                                                          fontSize={"1.2rem"}
                                                          label="Colocar alias"
                                                          bg="#292F36"
                                                        >
                                                          <IconButton
                                                            onClick={() => {
                                                              setModelCasa(
                                                                itemCasa
                                                              );
                                                              onOpen2();
                                                            }}
                                                            size={"md"}
                                                            borderRadius={"4px"}
                                                            fontSize={"1.5rem"}
                                                            background={
                                                              "#f59f2c"
                                                            }
                                                            color={"#ffffff"}
                                                            aria-label="Colocar alias"
                                                            icon={
                                                              <RiEditFill />
                                                            }
                                                          />
                                                        </Tooltip>
                                                        {itemCasa.estado ==
                                                        !1 ? (
                                                          <Tooltip
                                                            hasArrow
                                                            borderRadius={"4px"}
                                                            fontSize={"1.2rem"}
                                                            label="Activar"
                                                            bg="#292F36"
                                                          >
                                                            <IconButton
                                                              onClick={() => {
                                                                activar(
                                                                  itemCasa.id_casa
                                                                );
                                                                setActivo1(
                                                                  false
                                                                );
                                                              }}
                                                              size={"md"}
                                                              borderRadius={
                                                                "4px"
                                                              }
                                                              fontSize={
                                                                "1.5rem"
                                                              }
                                                              background={
                                                                "#2b752d"
                                                              }
                                                              color={"#ffffff"}
                                                              aria-label="Activar"
                                                              icon={
                                                                <FaCheckCircle />
                                                              }
                                                            />
                                                          </Tooltip>
                                                        ) : (
                                                          <Tooltip
                                                            hasArrow
                                                            borderRadius={"4px"}
                                                            fontSize={"1.2rem"}
                                                            label="Desactivar"
                                                            bg="#292F36"
                                                          >
                                                            <IconButton
                                                              onClick={() => {
                                                                darDeBaja(
                                                                  itemCasa.id_casa
                                                                );
                                                                setActivo1(
                                                                  true
                                                                );
                                                              }}
                                                              size={"md"}
                                                              borderRadius={
                                                                "4px"
                                                              }
                                                              fontSize={
                                                                "1.5rem"
                                                              }
                                                              background={
                                                                "#be0000"
                                                              }
                                                              color={"#ffffff"}
                                                              aria-label="Desactivar"
                                                              icon={
                                                                <MdDoDisturbOff />
                                                              }
                                                            />
                                                          </Tooltip>
                                                        )}
                                                      </Flex>
                                                    </Td>
                                                  </Tr>
                                                </Tbody>
                                              )
                                            )}
                                          </Table>
                                        </TableContainer>
                                      </AccordionPanel>
                                    </AccordionItem>
                                  </Flex>
                                </Accordion>
                              </Flex>
                            </GridItem>
                          );
                        })}
                      </Grid>
                    </TabPanel>
                    {/* -----------------BANCOS-------------------------- */}
                    <TabPanel
                      w={"100%"}
                      bg={"transparent"}
                      borderRadius="10px"
                      p={"2%"}
                      // boxShadow='rgba(0, 0, 0, 0.35) 0px 5px 15px;'
                    >
                      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                        {ListFiltered2()?.map((ItemSala) => {
                          return (
                            <GridItem>
                              <Flex
                                w={"100%"}
                                h="100%"
                                bg={"white"}
                                borderRadius="10px"
                                boxShadow={"rgba(0, 0, 0, 0.16) 0px 1px 4px"}
                                padding={"2% 3% 3% 3%"}
                                gap={"6px"}
                                justifyContent="center"
                                direction={"column"}
                              >
                                <Flex width={"100%"} justifyContent={"right"}>
                                  <Button
                                    w={"auto"}
                                    height={"40px"}
                                    borderRadius="10px"
                                    color={"white"}
                                    fontSize={"1.6rem"}
                                    bg="#2a2e6e"
                                    onClick={() => {
                                      ModelBanco.id_sala =
                                        ItemSala.sala.id_sala;
                                      onOpen3();
                                    }}
                                    p={"1% 1.5%"}
                                    textTransform={"uppercase"}
                                  >
                                    Agregar Bancos
                                  </Button>
                                </Flex>

                                <Accordion w={"100%"}>
                                  <AccordionItem
                                    w={"100%"}
                                    borderStyle={"none"}
                                    justifyContent={"center"}
                                  >
                                    <AccordionButton
                                      paddingRight={"2%"}
                                      color={"#ffffff"}
                                      _hover={{
                                        bg: "#292F36",
                                      }}
                                      _focus={{
                                        outline: "none",
                                      }}
                                      _dark={{
                                        bg: "#292F36",
                                      }}
                                      cursor={"auto"}
                                      background={"#292F36"}
                                      boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                                      height={"45px"}
                                      borderRadius={"8px"}
                                    >
                                      <Table>
                                        <Thead>
                                          <Tr width={"100%"}>
                                            <Th
                                              color={"#ffffff"}
                                              border={"none"}
                                              width={"33%"}
                                              fontSize={"1.8rem"}
                                            >
                                              SALA
                                            </Th>
                                            <Th
                                              textAlign={"center"}
                                              color={"#ffffff"}
                                              border={"none"}
                                              width={"33%"}
                                              fontSize={"1.8rem"}
                                            ></Th>
                                            <Th
                                              color={"#ffffff"}
                                              border={"none"}
                                              width={"33%"}
                                              isNumeric
                                              fontSize={"1.8rem"}
                                            ></Th>
                                          </Tr>
                                        </Thead>
                                      </Table>
                                    </AccordionButton>
                                  </AccordionItem>
                                </Accordion>

                                <Accordion
                                  w={"100%"}
                                  gap={"15px"}
                                  // defaultIndex={[0]}
                                  allowMultiple
                                >
                                  <Flex
                                    w={"100%"}
                                    gap={"15px"}
                                    direction={"column"}
                                  >
                                    <AccordionItem
                                      // key={itemModulo.id_modulo}
                                      w={"100%"}
                                      borderStyle={"none"}
                                      justifyContent={"center"}
                                    >
                                      <AccordionButton
                                        _hover={{
                                          bg: "white",
                                        }}
                                        _focus={{
                                          outline: "none",
                                        }}
                                        _dark={{
                                          bg: "#292929",
                                        }}
                                        boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                                        bg={"white"}
                                        height={"45px"}
                                        borderRadius={"8px"}
                                      >
                                        <Table>
                                          <Tbody fontSize={"1.7rem"}>
                                            <Tr width={"100%"}>
                                              <Td
                                                width={"45.5%"}
                                                fontWeight={"600"}
                                                fontFamily="monospace"
                                              >
                                                {ItemSala.sala.nombre}
                                              </Td>
                                              <Td
                                                width={"50%"}
                                                isNumeric
                                                fontWeight={"600"}
                                                fontFamily="monospace"
                                              ></Td>
                                            </Tr>
                                          </Tbody>
                                        </Table>

                                        <AccordionIcon fontSize={"1.6rem"} />
                                      </AccordionButton>

                                      <AccordionPanel
                                        padding={0}
                                        boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                                        bg={"white"}
                                        _dark={{
                                          bg: "#707070",
                                        }}
                                        borderRadius={"0 0 8px 8px"}
                                        margin={"1px 2% 1% 2%"}
                                      >
                                        {/* Eitar Modulo */}
                                        <TableContainer width={"100%"}>
                                          <Table width={"100%"}>
                                            <Thead>
                                              <Tr
                                                border={"none"}
                                                background={"#d9d9d9"}
                                                _dark={{
                                                  bg: "#464545",
                                                }}
                                                minHeight={"28px"}
                                              >
                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  text
                                                  Align={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  CASAS
                                                </Th>

                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  textAlign={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  ESTADO
                                                </Th>
                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  textAlign={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  ACCIONES
                                                </Th>
                                              </Tr>
                                            </Thead>

                                            {ItemSala.sala.bancos.map(
                                              (itemBanco) => (
                                                <Tbody bg={"transparent"}>
                                                  <Tr
                                                    border={"none"}
                                                    // key={
                                                    //   itemCuentaCasa.id_modulo_casa
                                                    // }
                                                  >
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      // textAlign={'center'}
                                                      fontSize={"1.7rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      {itemBanco.nombre}
                                                    </Td>
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      textAlign={"center"}
                                                      fontSize={"1.7rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      {itemBanco.estado == 1
                                                        ? "Activo"
                                                        : "Inactivo"}
                                                    </Td>
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      textAlign={"center"}
                                                      fontSize={"1.6rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      <Flex
                                                        justifyContent={
                                                          "center"
                                                        }
                                                        gap={"2%"}
                                                      >
                                                        <Tooltip
                                                          hasArrow
                                                          borderRadius={"4px"}
                                                          fontSize={"1.2rem"}
                                                          label="Editar"
                                                          bg="#292F36"
                                                        >
                                                          <IconButton
                                                            onClick={() => {
                                                              setBanco(
                                                                itemBanco
                                                              );
                                                              onOpen4();
                                                            }}
                                                            // onClick={() => {
                                                            //   setBanco(itemBanco);
                                                            //   onOpen4();
                                                            // }}
                                                            size={"md"}
                                                            borderRadius={"4px"}
                                                            fontSize={"1.5rem"}
                                                            background={
                                                              "#f59f2c"
                                                            }
                                                            color={"#ffffff"}
                                                            aria-label="Editar Banco"
                                                            icon={
                                                              <RiEditFill />
                                                            }
                                                          />
                                                        </Tooltip>
                                                        {itemBanco.estado ==
                                                        !1 ? (
                                                          <Tooltip
                                                            hasArrow
                                                            borderRadius={"4px"}
                                                            fontSize={"1.2rem"}
                                                            label="Activar"
                                                            bg="#292F36"
                                                          >
                                                            <IconButton
                                                              onClick={() => {
                                                                activar2(
                                                                  itemBanco.id_banco
                                                                );
                                                                setActivo1(
                                                                  false
                                                                );
                                                              }}
                                                              size={"md"}
                                                              borderRadius={
                                                                "4px"
                                                              }
                                                              fontSize={
                                                                "1.5rem"
                                                              }
                                                              background={
                                                                "#2b752d"
                                                              }
                                                              color={"#ffffff"}
                                                              aria-label="Activar"
                                                              icon={
                                                                <FaCheckCircle />
                                                              }
                                                            />
                                                          </Tooltip>
                                                        ) : (
                                                          <Tooltip
                                                            hasArrow
                                                            borderRadius={"4px"}
                                                            fontSize={"1.2rem"}
                                                            label="Desactivar"
                                                            bg="#292F36"
                                                          >
                                                            <IconButton
                                                              onClick={() => {
                                                                darDeBaja2(
                                                                  itemBanco.id_banco
                                                                );
                                                                setActivo1(
                                                                  true
                                                                );
                                                              }}
                                                              size={"md"}
                                                              borderRadius={
                                                                "4px"
                                                              }
                                                              fontSize={
                                                                "1.5rem"
                                                              }
                                                              background={
                                                                "#be0000"
                                                              }
                                                              color={"#ffffff"}
                                                              aria-label="Desactivar"
                                                              icon={
                                                                <MdDoDisturbOff />
                                                              }
                                                            />
                                                          </Tooltip>
                                                        )}
                                                      </Flex>
                                                    </Td>
                                                  </Tr>
                                                </Tbody>
                                              )
                                            )}
                                          </Table>
                                        </TableContainer>
                                      </AccordionPanel>
                                    </AccordionItem>
                                  </Flex>
                                </Accordion>
                              </Flex>
                            </GridItem>
                          );
                        })}
                      </Grid>
                    </TabPanel>

                    {/* ------------------Monedas----------------------- */}
                    <TabPanel
                      w={"100%"}
                      bg={"transparent"}
                      borderRadius="10px"
                      p={"2%"}
                      // boxShadow='rgba(0, 0, 0, 0.35) 0px 5px 15px;'
                    >
                      {/* --------------------------------------------------------- */}

                      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                        {ListFiltered3()?.map((itemSala) => {
                          return (
                            <GridItem>
                              <Flex
                                w={"100%"}
                                h="100%"
                                bg={"white"}
                                borderRadius="10px"
                                boxShadow={"rgba(0, 0, 0, 0.16) 0px 1px 4px"}
                                padding={"2% 3% 3% 3%"}
                                gap={"6px"}
                                justifyContent="center"
                                direction={"column"}
                              >
                                <Flex width={"100%"} justifyContent={"right"}>
                                  <Button
                                    w={"auto"}
                                    height={"40px"}
                                    borderRadius="10px"
                                    color={"white"}
                                    fontSize={"1.6rem"}
                                    bg="#2a2e6e"
                                    onClick={() => {
                                      ModelMoneda.id_sala =
                                        itemSala.sala.id_sala;
                                      onOpen5();
                                    }}
                                    p={"1% 1.5%"}
                                    textTransform={"uppercase"}
                                  >
                                    Agregar Monedas
                                  </Button>
                                </Flex>

                                <Accordion w={"100%"}>
                                  <AccordionItem
                                    w={"100%"}
                                    borderStyle={"none"}
                                    justifyContent={"center"}
                                  >
                                    <AccordionButton
                                      paddingRight={"2%"}
                                      color={"#ffffff"}
                                      _hover={{
                                        bg: "#292F36",
                                      }}
                                      _focus={{
                                        outline: "none",
                                      }}
                                      _dark={{
                                        bg: "#292F36",
                                      }}
                                      cursor={"auto"}
                                      background={"#292F36"}
                                      boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                                      height={"45px"}
                                      borderRadius={"8px"}
                                    >
                                      <Table>
                                        <Thead>
                                          <Tr width={"100%"}>
                                            <Th
                                              color={"#ffffff"}
                                              border={"none"}
                                              width={"33%"}
                                              fontSize={"1.8rem"}
                                            >
                                              SALA
                                            </Th>
                                            <Th
                                              textAlign={"center"}
                                              color={"#ffffff"}
                                              border={"none"}
                                              width={"33%"}
                                              fontSize={"1.8rem"}
                                            ></Th>
                                            <Th
                                              color={"#ffffff"}
                                              border={"none"}
                                              width={"33%"}
                                              isNumeric
                                              fontSize={"1.8rem"}
                                            ></Th>
                                          </Tr>
                                        </Thead>
                                      </Table>
                                    </AccordionButton>
                                  </AccordionItem>
                                </Accordion>

                                <Accordion
                                  w={"100%"}
                                  gap={"15px"}
                                  // defaultIndex={[0]}
                                  allowMultiple
                                >
                                  <Flex
                                    w={"100%"}
                                    gap={"15px"}
                                    direction={"column"}
                                  >
                                    <AccordionItem
                                      // key={itemModulo.id_modulo}
                                      w={"100%"}
                                      borderStyle={"none"}
                                      justifyContent={"center"}
                                    >
                                      <AccordionButton
                                        _hover={{
                                          bg: "white",
                                        }}
                                        _focus={{
                                          outline: "none",
                                        }}
                                        _dark={{
                                          bg: "#292929",
                                        }}
                                        boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                                        bg={"white"}
                                        height={"45px"}
                                        borderRadius={"8px"}
                                      >
                                        <Table>
                                          <Tbody fontSize={"1.7rem"}>
                                            <Tr width={"100%"}>
                                              <Td
                                                width={"45.5%"}
                                                fontWeight={"600"}
                                                fontFamily="monospace"
                                              >
                                                {itemSala.sala.nombre}
                                              </Td>
                                              <Td
                                                width={"50%"}
                                                isNumeric
                                                fontWeight={"600"}
                                                fontFamily="monospace"
                                              ></Td>
                                            </Tr>
                                          </Tbody>
                                        </Table>

                                        <AccordionIcon fontSize={"1.6rem"} />
                                      </AccordionButton>

                                      <AccordionPanel
                                        padding={0}
                                        boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                                        bg={"white"}
                                        _dark={{
                                          bg: "#707070",
                                        }}
                                        borderRadius={"0 0 8px 8px"}
                                        margin={"1px 2% 1% 2%"}
                                      >
                                        {/* Eitar Modulo */}
                                        <TableContainer width={"100%"}>
                                          <Table width={"100%"}>
                                            <Thead>
                                              <Tr
                                                border={"none"}
                                                background={"#d9d9d9"}
                                                _dark={{
                                                  bg: "#464545",
                                                }}
                                                minHeight={"28px"}
                                              >
                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  text
                                                  Align={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  ID
                                                </Th>
                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  text
                                                  Align={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  NOM
                                                </Th>
                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  text
                                                  Align={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  MONEDA
                                                </Th>
                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  text
                                                  Align={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  SIMBOLO
                                                </Th>
                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  textAlign={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  ESTADO
                                                </Th>
                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  textAlign={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  ACCIONES
                                                </Th>
                                              </Tr>
                                            </Thead>

                                            {itemSala.sala.monedas.map(
                                              (itemMoneda) => (
                                                <Tbody bg={"transparent"}>
                                                  <Tr
                                                    border={"none"}
                                                    // key={
                                                    //   itemCuentaCasa.id_modulo_casa
                                                    // }
                                                  >
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      // textAlign={'center'}
                                                      fontSize={"1.7rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      {itemMoneda.id_moneda}
                                                    </Td>
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      // textAlign={'center'}
                                                      fontSize={"1.7rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      {itemMoneda.nomenclatura}
                                                    </Td>
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      // textAlign={'center'}
                                                      fontSize={"1.7rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      {itemMoneda.nombre}
                                                    </Td>
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      textAlign={"center"}
                                                      fontSize={"1.7rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      {itemMoneda.simbolo}
                                                    </Td>
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      textAlign={"center"}
                                                      fontSize={"1.7rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      {itemMoneda.estado == 1
                                                        ? "Activo"
                                                        : "Inactivo"}
                                                    </Td>
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      textAlign={"center"}
                                                      fontSize={"1.6rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      <Flex
                                                        justifyContent={
                                                          "center"
                                                        }
                                                        gap={"2%"}
                                                      >
                                                        {/* <Tooltip
                                                          hasArrow
                                                          borderRadius={
                                                            '4px'
                                                          }
                                                          fontSize={
                                                            '1.2rem'
                                                          }
                                                          label='Editar'
                                                          bg='#292F36'>
                                                          <IconButton
                                                            onClick={() => {
                                                              setBanco(
                                                                itemMoneda
                                                              );
                                                              onOpen4();
                                                            }}
                                                            size={
                                                              'md'
                                                            }
                                                            borderRadius={
                                                              '4px'
                                                            }
                                                            fontSize={
                                                              '1.5rem'
                                                            }
                                                            background={
                                                              '#f59f2c'
                                                            }
                                                            color={
                                                              '#ffffff'
                                                            }
                                                            aria-label='Editar Banco'
                                                            icon={
                                                              <RiEditFill />
                                                            }
                                                          />
                                                        </Tooltip> */}
                                                        {itemMoneda.estado ==
                                                        !1 ? (
                                                          <Tooltip
                                                            hasArrow
                                                            borderRadius={"4px"}
                                                            fontSize={"1.2rem"}
                                                            label="Activar"
                                                            bg="#292F36"
                                                          >
                                                            <IconButton
                                                              onClick={() => {
                                                                activar3(
                                                                  itemMoneda.id_moneda
                                                                );
                                                                setActivo1(
                                                                  false
                                                                );
                                                              }}
                                                              size={"md"}
                                                              borderRadius={
                                                                "4px"
                                                              }
                                                              fontSize={
                                                                "1.5rem"
                                                              }
                                                              background={
                                                                "#2b752d"
                                                              }
                                                              color={"#ffffff"}
                                                              aria-label="Activar"
                                                              icon={
                                                                <FaCheckCircle />
                                                              }
                                                            />
                                                          </Tooltip>
                                                        ) : (
                                                          <Tooltip
                                                            hasArrow
                                                            borderRadius={"4px"}
                                                            fontSize={"1.2rem"}
                                                            label="Desactivar"
                                                            bg="#292F36"
                                                          >
                                                            <IconButton
                                                              onClick={() => {
                                                                darDeBaja3(
                                                                  itemMoneda.id_moneda
                                                                );
                                                                setActivo1(
                                                                  true
                                                                );
                                                              }}
                                                              size={"md"}
                                                              borderRadius={
                                                                "4px"
                                                              }
                                                              fontSize={
                                                                "1.5rem"
                                                              }
                                                              background={
                                                                "#be0000"
                                                              }
                                                              color={"#ffffff"}
                                                              aria-label="Desactivar"
                                                              icon={
                                                                <MdDoDisturbOff />
                                                              }
                                                            />
                                                          </Tooltip>
                                                        )}
                                                      </Flex>
                                                    </Td>
                                                  </Tr>
                                                </Tbody>
                                              )
                                            )}
                                          </Table>
                                        </TableContainer>
                                      </AccordionPanel>
                                    </AccordionItem>
                                  </Flex>
                                </Accordion>
                              </Flex>
                            </GridItem>
                          );
                        })}
                      </Grid>
                    </TabPanel>

                    {/* ----------------------------------------- */}

                    <TabPanel
                      w={"100%"}
                      bg={"transparent"}
                      borderRadius="10px"
                      p={"2%"}
                      // boxShadow='rgba(0, 0, 0, 0.35) 0px 5px 15px;'
                    >
                      {/* -------------------------------------------------------------------- */}

                      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                        {ListFiltered5()?.map((itemSala) => {
                          return (
                            <GridItem>
                              <Flex
                                w={"100%"}
                                h="100%"
                                bg={"white"}
                                borderRadius="10px"
                                boxShadow={"rgba(0, 0, 0, 0.16) 0px 1px 4px"}
                                padding={"2% 3% 3% 3%"}
                                gap={"6px"}
                                justifyContent="center"
                                direction={"column"}
                              >
                                <Flex width={"100%"} justifyContent={"right"}>
                                  <Button
                                    w={"auto"}
                                    height={"40px"}
                                    borderRadius="10px"
                                    color={"white"}
                                    fontSize={"1.6rem"}
                                    bg="#2a2e6e"
                                    onClick={() => {
                                      ModelOperador.id_sala =
                                        itemSala.sala.id_sala;
                                      onOpen7();
                                    }}
                                    p={"1% 1.5%"}
                                    textTransform={"uppercase"}
                                  >
                                    Agregar Operador
                                  </Button>
                                </Flex>

                                <Accordion w={"100%"}>
                                  <AccordionItem
                                    w={"100%"}
                                    borderStyle={"none"}
                                    justifyContent={"center"}
                                  >
                                    <AccordionButton
                                      paddingRight={"2%"}
                                      color={"#ffffff"}
                                      _hover={{
                                        bg: "#292F36",
                                      }}
                                      _focus={{
                                        outline: "none",
                                      }}
                                      _dark={{
                                        bg: "#292F36",
                                      }}
                                      cursor={"auto"}
                                      background={"#292F36"}
                                      boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                                      height={"45px"}
                                      borderRadius={"8px"}
                                    >
                                      <Table>
                                        <Thead>
                                          <Tr width={"100%"}>
                                            <Th
                                              color={"#ffffff"}
                                              border={"none"}
                                              width={"33%"}
                                              fontSize={"1.8rem"}
                                            >
                                              SALA
                                            </Th>
                                            <Th
                                              textAlign={"center"}
                                              color={"#ffffff"}
                                              border={"none"}
                                              width={"33%"}
                                              fontSize={"1.8rem"}
                                            ></Th>
                                            <Th
                                              color={"#ffffff"}
                                              border={"none"}
                                              width={"33%"}
                                              isNumeric
                                              fontSize={"1.8rem"}
                                            ></Th>
                                          </Tr>
                                        </Thead>
                                      </Table>
                                    </AccordionButton>
                                  </AccordionItem>
                                </Accordion>

                                <Accordion
                                  w={"100%"}
                                  gap={"15px"}
                                  // defaultIndex={[0]}
                                  allowMultiple
                                >
                                  <Flex
                                    w={"100%"}
                                    gap={"15px"}
                                    direction={"column"}
                                  >
                                    <AccordionItem
                                      // key={itemModulo.id_modulo}
                                      w={"100%"}
                                      borderStyle={"none"}
                                      justifyContent={"center"}
                                    >
                                      <AccordionButton
                                        _hover={{
                                          bg: "white",
                                        }}
                                        _focus={{
                                          outline: "none",
                                        }}
                                        _dark={{
                                          bg: "#292929",
                                        }}
                                        boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                                        bg={"white"}
                                        height={"45px"}
                                        borderRadius={"8px"}
                                      >
                                        <Table>
                                          <Tbody fontSize={"1.7rem"}>
                                            <Tr width={"100%"}>
                                              <Td
                                                width={"45.5%"}
                                                fontWeight={"600"}
                                                fontFamily="monospace"
                                              >
                                                {itemSala.sala.nombre}
                                              </Td>
                                              <Td
                                                width={"50%"}
                                                isNumeric
                                                fontWeight={"600"}
                                                fontFamily="monospace"
                                              ></Td>
                                            </Tr>
                                          </Tbody>
                                        </Table>

                                        <AccordionIcon fontSize={"1.6rem"} />
                                      </AccordionButton>

                                      <AccordionPanel
                                        padding={0}
                                        boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                                        bg={"white"}
                                        _dark={{
                                          bg: "#707070",
                                        }}
                                        borderRadius={"0 0 8px 8px"}
                                        margin={"1px 2% 1% 2%"}
                                      >
                                        {/* Eitar Modulo */}
                                        <TableContainer width={"100%"}>
                                          <Table width={"100%"}>
                                            <Thead>
                                              <Tr
                                                border={"none"}
                                                background={"#d9d9d9"}
                                                _dark={{
                                                  bg: "#464545",
                                                }}
                                                minHeight={"28px"}
                                              >
                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  text
                                                  Align={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  OPERADOR
                                                </Th>

                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  textAlign={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  ESTADO
                                                </Th>
                                                <Th
                                                  border={"none"}
                                                  width={"33%"}
                                                  textAlign={"center"}
                                                  fontSize={"1.6rem"}
                                                  _dark={{
                                                    color: "white",
                                                  }}
                                                  color={"#13235b"}
                                                >
                                                  ACCIONES
                                                </Th>
                                              </Tr>
                                            </Thead>

                                            {itemSala.sala.operador_telefonos.map(
                                              (itemTelefono) => (
                                                <Tbody bg={"transparent"}>
                                                  <Tr
                                                    border={"none"}
                                                    // key={
                                                    //   itemCuentaCasa.id_modulo_casa
                                                    // }
                                                  >
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      // textAlign={'center'}
                                                      fontSize={"1.7rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      {itemTelefono.nombre}
                                                    </Td>
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      textAlign={"center"}
                                                      fontSize={"1.7rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      {itemTelefono.estado == 1
                                                        ? "Activo"
                                                        : "Inactivo"}
                                                    </Td>
                                                    <Td
                                                      border={"none"}
                                                      width={"33%"}
                                                      textAlign={"center"}
                                                      fontSize={"1.6rem"}
                                                      _dark={{
                                                        color: "white",
                                                      }}
                                                      color={"#13235b"}
                                                    >
                                                      <Flex
                                                        justifyContent={
                                                          "center"
                                                        }
                                                        gap={"2%"}
                                                      >
                                                        <Tooltip
                                                          hasArrow
                                                          borderRadius={"4px"}
                                                          fontSize={"1.2rem"}
                                                          label="Editar"
                                                          bg="#292F36"
                                                        >
                                                          <IconButton
                                                            onClick={() => {
                                                              setOperador(
                                                                itemTelefono
                                                              );
                                                              onOpen8();
                                                            }}
                                                            size={"md"}
                                                            borderRadius={"4px"}
                                                            fontSize={"1.5rem"}
                                                            background={
                                                              "#f59f2c"
                                                            }
                                                            color={"#ffffff"}
                                                            aria-label="Editar Banco"
                                                            icon={
                                                              <RiEditFill />
                                                            }
                                                          />
                                                        </Tooltip>
                                                        {itemTelefono.estado ==
                                                        !1 ? (
                                                          <Tooltip
                                                            hasArrow
                                                            borderRadius={"4px"}
                                                            fontSize={"1.2rem"}
                                                            label="Activar"
                                                            bg="#292F36"
                                                          >
                                                            <IconButton
                                                              onClick={() => {
                                                                activar4(
                                                                  itemTelefono.id_operador_phone
                                                                );
                                                                setActivo1(
                                                                  false
                                                                );
                                                              }}
                                                              size={"md"}
                                                              borderRadius={
                                                                "4px"
                                                              }
                                                              fontSize={
                                                                "1.5rem"
                                                              }
                                                              background={
                                                                "#2b752d"
                                                              }
                                                              color={"#ffffff"}
                                                              aria-label="Activar"
                                                              icon={
                                                                <FaCheckCircle />
                                                              }
                                                            />
                                                          </Tooltip>
                                                        ) : (
                                                          <Tooltip
                                                            hasArrow
                                                            borderRadius={"4px"}
                                                            fontSize={"1.2rem"}
                                                            label="Desactivar"
                                                            bg="#292F36"
                                                          >
                                                            <IconButton
                                                              onClick={() => {
                                                                darDeBaja4(
                                                                  itemTelefono.id_operador_phone
                                                                );
                                                                setActivo1(
                                                                  true
                                                                );
                                                              }}
                                                              size={"md"}
                                                              borderRadius={
                                                                "4px"
                                                              }
                                                              fontSize={
                                                                "1.5rem"
                                                              }
                                                              background={
                                                                "#be0000"
                                                              }
                                                              color={"#ffffff"}
                                                              aria-label="Desactivar"
                                                              icon={
                                                                <MdDoDisturbOff />
                                                              }
                                                            />
                                                          </Tooltip>
                                                        )}
                                                      </Flex>
                                                    </Td>
                                                  </Tr>
                                                </Tbody>
                                              )
                                            )}
                                          </Table>
                                        </TableContainer>
                                      </AccordionPanel>
                                    </AccordionItem>
                                  </Flex>
                                </Accordion>
                              </Flex>
                            </GridItem>
                          );
                        })}
                      </Grid>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>

      {/* ---------------------------------Casas-------------------------------------------------- */}

      {/* ---------------Agregar Casas--------- */}
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />

        <ModalContent
          maxW={{ "2xl": "20%", xl: "35%", lg: "45%", md: "60%" }}
          // marginTop='5%'
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Agregar Casas
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex
              direction={"column"}
              gap={{
                "2xl": "20px",
                xl: "20px",
                lg: "20px",
                md: "15px",
              }}
              w={"100%"}
              h="100%"
            >
              <FormControl w={"auto"}>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Agregar:
                </FormLabel>
                <Stack w={"100%"}>
                  <Input
                    value={ModelCasa.nombre}
                    onChange={handleChange}
                    name="nombre"
                    type="text"
                    placeholder="Casa"
                    bg={"white"}
                    height={"40px"}
                    _focus={{
                      borderColor: "#2a2e6e",
                      boxShadow: "none",
                    }}
                    color="#2a2e6e"
                    fontSize={"1.9rem"}
                    border={"1px solid"}
                    borderColor={"#2a2e6e"}
                    borderRadius="6px"
                    _placeholder={{ color: "rgb(152, 152, 152)" }}
                    _dark={{
                      color: "white",
                      borderColor: "#686868",
                      bg: "#686868",
                    }}
                  />
                </Stack>
              </FormControl>
              <Flex width={"100%"} justifyContent={"center"}>
                <Button
                  w={"100%"}
                  height={"40px"}
                  borderRadius="10px"
                  color={"white"}
                  fontSize={"1.6rem"}
                  bg="#2a2e6e"
                  onClick={crearCasa}
                  p={"1% 1.5%"}
                  textTransform={"uppercase"}
                >
                  Guardar
                </Button>
              </Flex>

              {/* <FormControl w={"55%"}>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Filtrar:
                </FormLabel>
                <Stack w={"100%"}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={
                        <Search2Icon
                          color="#2a2e6e"
                          marginLeft={"20%"}
                          fontSize={"1.25rem"}
                          marginTop={"60%"}
                          _dark={{
                            color: "#f59f2c",
                          }}
                        />
                      }
                    />
                    <Input
                      onChange={buscarCasas}
                      type="text"
                      placeholder="Buscar"
                      bg={"white"}
                      height={"40px"}
                      _focus={{
                        borderColor: "#2a2e6e",
                        boxShadow: "none",
                      }}
                      color="#2a2e6e"
                      fontSize={"1.9rem"}
                      border={"1px solid"}
                      borderColor={"#2a2e6e"}
                      borderRadius="6px"
                      _placeholder={{ color: "rgb(152, 152, 152)" }}
                      _dark={{
                        color: "white",
                        borderColor: "#686868",
                        bg: "#686868",
                      }}
                    />
                  </InputGroup>
                </Stack>
              </FormControl>

              <Flex
                w={"100%"}
                h="100%"
                direction={"column"}
                justifyContent="center"
              >
                <TableContainer w={"100%"}>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th fontSize={"1.8rem"} textTransform="uppercase">
                          Casa
                        </Th>

                        <Th
                          fontSize={"1.8rem"}
                          textTransform="uppercase"
                          isNumeric
                        >
                          ACCION
                        </Th>
                      </Tr>
                    </Thead>

                    <Tbody fontSize={"1.6rem"}>
                      {ListFilteredCasas().map((c) => {
                        return (
                          <Tr>
                            <Td
                              fontWeight={"600"}
                              fontFamily="monospace"
                              textTransform={"uppercase"}
                            >
                              {c.nombre}
                            </Td>

                            <Td
                              fontWeight={"600"}
                              fontFamily="monospace"
                              isNumeric
                            >
                              <Tooltip
                                hasArrow
                                borderRadius={"4px"}
                                fontSize={"1.2rem"}
                                label="Agregar"
                                bg="#292F36"
                              >
                                <IconButton
                                  onClick={(e) => {
                                    ModelCasa.nombre = c.nombre;
                                    crearCasa(e);
                                    toast({
                                      title: "Casa",
                                      position: "top",
                                      description: "Se agrego correctamente",
                                      status: "success",
                                      duration: 3000,
                                      isClosable: true,
                                    });
                                  }}
                                  size={"md"}
                                  borderRadius={"4px"}
                                  fontSize={"1.5rem"}
                                  background={"#2a2e6e"}
                                  color={"#ffffff"}
                                  aria-label="Editar"
                                  icon={<IoIosAddCircle />}
                                />
                              </Tooltip>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
                <Flex
                  marginTop={"0.5%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={"0.5%"}
                >
                  <Icon
                    onClick={() => {
                      prevPage8();
                    }}
                    color={"#13235b"}
                    cursor={"pointer"}
                    boxSize="16"
                    _hover={{
                      color: "gray !important",
                    }}
                    _dark={{
                      color: "#f59f2c",
                    }}
                    as={IoMdArrowDropleftCircle}
                  />
                  <Icon
                    onClick={() => {
                      nextPage8();
                    }}
                    color={"#13235b"}
                    cursor={"pointer"}
                    boxSize="16"
                    _hover={{
                      color: "gray !important",
                    }}
                    _dark={{
                      color: "#f59f2c",
                    }}
                    as={IoMdArrowDroprightCircle}
                  />
                </Flex>

              </Flex> */}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* --------------------Editar Casas----------------- */}
      <Modal
        isCentered
        isOpen={isOpen2}
        onClose={() => {
          setModelCasa({
            nombre: "",
            id_sala: "",
          });
          onClose2();
        }}
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "20%", xl: "25%", lg: "35%", md: "44%" }}
          // marginTop='15%'
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Editar Casa
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex
              direction={"column"}
              alignItems="center"
              gap="15px"
              w={"100%"}
              h="100%"
            >
              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Nombre:
                </FormLabel>
                <Input
                  value={ModelCasa.nombre}
                  onChange={handleChange}
                  name="nombre"
                  fontSize={"1.8rem"}
                  h="35"
                  type="text"
                />
              </FormControl>
              <Button
                onClick={handleSubmit}
                textTransform="uppercase"
                fontSize={"1.6rem"}
                p={{
                  "2xl": "5.5% 8%",
                  xl: "5.5% 8%",
                  lg: "5.5% 9%",
                  md: "5.5% 8%",
                }}
              >
                Actualizar
              </Button>
            </Flex>
          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>

      {/* ------------------------------------Bancos----------------------------------------------- */}
      {/* ---------------Agregar Banco--------- */}
      <Modal
        isCentered
        isOpen={isOpen3}
        onClose={onClose3}
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "20%", xl: "25%", lg: "35%", md: "42%" }}
          // marginTop='15%'
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Agregar Bancos
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex
              direction={"column"}
              alignItems="center"
              gap="15px"
              w={"100%"}
              h="100%"
            >
              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Banco:
                </FormLabel>
                <Input
                  onChange={changeBanco}
                  value={ModelBanco.nombre}
                  name="nombre"
                  fontSize={"1.8rem"}
                  h="35"
                  type="text"
                />
                <FormHelperText fontSize={"1.5rem"}>
                  Ingrese correctamente el nombre del Banco
                </FormHelperText>
              </FormControl>

              <Button
                onClick={crearBanco}
                textTransform="uppercase"
                fontSize={"1.6rem"}
                p={{
                  "2xl": "5.5% 8%",
                  xl: "5.5% 8%",
                  lg: "5.5% 9%",
                  md: "5.5% 8%",
                }}
              >
                Agregar
              </Button>
            </Flex>
          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
      {/* --------------------Editar Banco----------------- */}
      <Modal
        isCentered
        isOpen={isOpen4}
        onClose={onClose4}
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "20%", xl: "25%", lg: "35%", md: "42%" }}
          // marginTop='15%'
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            EDITAR BANCO
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex
              direction={"column"}
              alignItems="center"
              gap="15px"
              w={"100%"}
              h="100%"
            >
              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Banco:
                </FormLabel>
                <Input
                  value={updateBanco.nombre}
                  name="nombre"
                  onChange={handleChange2}
                  fontSize={"1.8rem"}
                  h="35"
                  type="text"
                />
                <FormHelperText fontSize={"1.5rem"}>
                  Ingrese correctamente el nombre del banco
                </FormHelperText>
              </FormControl>
              {/* <FormControl>
                <FormLabel fontSize={'2rem'} fontWeight='500'>
                  Estado:
                </FormLabel>
                <Select
                  placeholder='Estado'
                  placeholder='Estado'
                  _placeholder={{
                    textcolor: '#f8f8f8'
                    textcolor: '#f8f8f8'
                  }}
                  value={updateBanco.estado}
                  onChange={handleSelectChange2}
                  fontSize={'1.8rem'}
                  fontWeight='500'>
                  fontSize={'1.8rem'}
                  fontWeight='500'>
                  <option value={1}>Activo</option>
                  <option value={0}>No Activo</option>
                </Select>
              </FormControl> */}
              <Button
                onClick={handleSubmit2}
                textTransform="uppercase"
                fontSize={"1.6rem"}
                p={{
                  "2xl": "5.5% 8%",
                  xl: "5.5% 8%",
                  lg: "5.5% 9%",
                  md: "5.5% 8%",
                }}
              >
                Actualizar
              </Button>
            </Flex>
          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>

      {/* ------------------------------------Monedas----------------------------------------------- */}
      {/* ---------------Agregar moneda--------- */}
      <Modal
        isCentered
        isOpen={isOpen5}
        onClose={onClose5}
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "60%", xl: "65%", lg: "80%", md: "97%" }}
          // marginTop='5%'
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Agregar Monedas
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex
              direction={"column"}
              gap={{
                "2xl": "40px",
                xl: "20px",
                lg: "20px",
                md: "15px",
              }}
              w={"100%"}
              h="100%"
            >
              <FormControl w={"45%"}>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Filtrar:
                </FormLabel>
                <Stack w={"100%"}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={
                        <Search2Icon
                          color="#2a2e6e"
                          marginLeft={"20%"}
                          fontSize={"1.25rem"}
                          marginTop={"60%"}
                          _dark={{
                            color: "#f59f2c",
                          }}
                        />
                      }
                    />
                    <Input
                      onChange={buscar}
                      type="text"
                      placeholder="Buscar"
                      bg={"white"}
                      height={"40px"}
                      _focus={{
                        borderColor: "#2a2e6e",
                        boxShadow: "none",
                      }}
                      color="#2a2e6e"
                      fontSize={"1.9rem"}
                      border={"1px solid"}
                      borderColor={"#2a2e6e"}
                      borderRadius="6px"
                      _placeholder={{ color: "rgb(152, 152, 152)" }}
                      _dark={{
                        color: "white",
                        borderColor: "#686868",
                        bg: "#686868",
                      }}
                    />
                  </InputGroup>
                </Stack>
              </FormControl>
              <Flex
                w={"100%"}
                h="100%"
                direction={"column"}
                justifyContent="center"
              >
                <TableContainer w={"100%"}>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th fontSize={"1.8rem"} textTransform="uppercase">
                          Pais
                        </Th>
                        <Th fontSize={"1.8rem"} textTransform="uppercase">
                          Moneda
                        </Th>
                        <Th fontSize={"1.8rem"} textTransform="uppercase">
                          Nomenclatura
                        </Th>
                        <Th fontSize={"1.8rem"} textTransform="uppercase">
                          Simbol
                        </Th>

                        <Th
                          fontSize={"1.8rem"}
                          textTransform="uppercase"
                          isNumeric
                        >
                          ACCION
                        </Th>
                      </Tr>
                    </Thead>
                    {ListFiltered4().map((p) => {
                      return (
                        <Tbody fontSize={"1.6rem"}>
                          <Tr>
                            <Td
                              fontWeight={"600"}
                              fontFamily="monospace"
                              textTransform={"uppercase"}
                            >
                              {p.pais}
                            </Td>
                            <Td
                              fontWeight={"600"}
                              fontFamily="monospace"
                              textTransform={"uppercase"}
                            >
                              {p.moneda}
                            </Td>
                            <Td
                              fontWeight={"600"}
                              fontFamily="monospace"
                              textTransform={"uppercase"}
                            >
                              {p.nom}
                            </Td>
                            <Td
                              fontWeight={"600"}
                              fontFamily="monospace"
                              textTransform={"uppercase"}
                            >
                              {p.simbolo}
                            </Td>
                            <Td
                              fontWeight={"600"}
                              fontFamily="monospace"
                              isNumeric
                            >
                              <Tooltip
                                hasArrow
                                borderRadius={"4px"}
                                fontSize={"1.2rem"}
                                label="Agregar"
                                bg="#292F36"
                              >
                                <IconButton
                                  onClick={(e) => {
                                    ModelMoneda.nombre = p.moneda;
                                    ModelMoneda.nomenclatura = p.nom;
                                    ModelMoneda.simbolo = p.simbolo;

                                    agregarMoneda(e);
                                    /*toast({
                                      title: 'Moneda',
                                      position: 'top',
                                      description:
                                        'Se agrego correctamente',
                                      status: 'success',
                                      duration: 3000,
                                      isClosable: true
                                    });*/
                                  }}
                                  size={"md"}
                                  borderRadius={"4px"}
                                  fontSize={"1.5rem"}
                                  background={"#2a2e6e"}
                                  color={"#ffffff"}
                                  aria-label="Editar"
                                  icon={<IoIosAddCircle />}
                                />
                              </Tooltip>
                            </Td>
                          </Tr>
                        </Tbody>
                      );
                    })}
                  </Table>
                </TableContainer>
                <Flex
                  marginTop={"0.5%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={"0.5%"}
                >
                  <Icon
                    onClick={() => {
                      prevPage4();
                    }}
                    color={"#13235b"}
                    cursor={"pointer"}
                    boxSize="16"
                    _hover={{
                      color: "gray !important",
                    }}
                    _dark={{
                      color: "#f59f2c",
                    }}
                    as={IoMdArrowDropleftCircle}
                  />
                  <Icon
                    onClick={() => {
                      nextPage4();
                    }}
                    color={"#13235b"}
                    cursor={"pointer"}
                    boxSize="16"
                    _hover={{
                      color: "gray !important",
                    }}
                    _dark={{
                      color: "#f59f2c",
                    }}
                    as={IoMdArrowDroprightCircle}
                  />
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* ------------------------------------Operador de telefono--------------------------------- */}
      {/* ---------------Agregar operador--------- */}
      <Modal
        isCentered
        isOpen={isOpen7}
        onClose={onClose7}
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          sx={{
            "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
              {
                maxW: "27%",
              },
          }}
          maxW={{ "2xl": "20%", xl: "25%", lg: "35%", md: "45%" }}
          // marginTop='5%'
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Agregar Operadores
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex direction={"column"} gap="15px" w={"100%"} h="100%">
              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Operadores:
                </FormLabel>
                <Input
                  onChange={changeOperador}
                  value={ModelOperador.nombre}
                  name="nombre"
                  fontSize={"1.8rem"}
                  h="35"
                  type="text"
                />
                <FormHelperText fontSize={"1.5rem"}>
                  Ingrese correctamente el nombre del operador
                </FormHelperText>
              </FormControl>

              <Button
                onClick={agregarOperador}
                textTransform="uppercase"
                fontSize={"1.6rem"}
                p={{
                  "2xl": "5.5% 8%",
                  xl: "5.5% 8%",
                  lg: "5.5% 9%",
                  md: "5.5% 8%",
                }}
              >
                Agregar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* --------------------Editar operador----------------- */}
      <Modal
        isCentered
        isOpen={isOpen8}
        onClose={onClose8}
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "20%", xl: "25%", lg: "35%", md: "44%" }}
          // marginTop='15%'
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Editar Operador
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex
              direction={"column"}
              alignItems="center"
              gap="15px"
              w={"100%"}
              h="100%"
            >
              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Operador:
                </FormLabel>
                <Input
                  value={updateOperador.nombre}
                  name="nombre"
                  onChange={handleChange4}
                  fontSize={"1.8rem"}
                  h="35"
                  type="text"
                />
                {/* <FormHelperText fontSize={'1.5rem'}>
                  Ingrese correctamente el nombre de la moneda
                </FormHelperText> */}
              </FormControl>
              {/* <FormControl>
                <FormLabel fontSize={'2rem'} fontWeight='500'>
                  Estado:
                </FormLabel>
                <Select
                  placeholder='Estado'
                  _placeholder={{
                    textcolor: '#f8f8f8'
                  }}
                  value={updateOperador.estado}
                  onChange={handleSelectChange4}
                  fontSize={'1.8rem'}
                  fontWeight='500'>
                  <option value={1}>Activo</option>
                  <option value={0}>No Activo</option>
                </Select>
              </FormControl> */}
              <Button
                onClick={handleSubmit4}
                textTransform="uppercase"
                fontSize={"1.6rem"}
                p={{
                  "2xl": "5.5% 8%",
                  xl: "5.5% 8%",
                  lg: "5.5% 9%",
                  md: "5.5% 8%",
                }}
              >
                Actualizar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Mantenimiento;
