import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  Box,
  Flex,
  IconButton,
  Button,
  useDisclosure,
  Text,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  Input,
  FormControl,
  FormLabel
} from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import SidebarOperators from '../../../components/SidebarOperators';
import { host3 } from '../../../utils/constantes';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

function RegistroHoras() {
  let history = useHistory();
  const [activo1, setActivo1] = useState(false);
  const token = localStorage.getItem('tokenadmin');
  const { rol , sala } = jwt_decode(token);

  useEffect(() => {
    const { rol } = jwt_decode(token);
    if (token) {
      if (rol == 'OPERADOR') {
        history.push('/salas/MCasas');
      }
    }
  }, []);

  if (!token) {
    history.push('/');
  }

  const [tiempo, setTiempo] = useState(false);

  //Mensaje

  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setTiempo(true);
    }, 8000);
  }, []);


  // -------------- AGREGAR FECHA ----------------------

  const [ModelFecha , setModelFecha] = useState({
    id_control: "",
    id_sala: "",
    fecha_inicio: "",
    hora_inicio: "",
    fecha_salida: "",
    hora_salida: "",
  });

  const handleChange = (e) => {
    setModelFecha({
      ...ModelFecha,
      [e.target.name]: e.target.value,
    })
  };

  const fetchControlesAsistencia = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/control_asistencias/${token}/listar`);
      if (data[0].id_control === undefined) {
      setModelFecha({
        id_control: "",
        id_sala: "",
        fecha_inicio: "",
        hora_inicio: "",
        fecha_salida: "",
        hora_salida: "",
      });
      return;
      }
      setModelFecha(data[0]);
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  const agregarFecha = async () => {
    try {
      const response = await axios.post(
        `${host3}/api/control_asistencias/${token}`,
      ModelFecha
      );
      fetchControlesAsistencia();
      mensaje(response.data.message, 'top_right', 1);
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  }

  const actualizar = async (e) => {
    try {
      const response = await axios.put(
        `${host3}/api/control_asistencias/${token}/${ModelFecha.id_control}`,
        ModelFecha
      );
      fetchControlesAsistencia();
      mensaje(response.data.message, 'top-right', 1);
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
  }
  
  useEffect(() => {
    fetchControlesAsistencia();
    setTimeout(() => {
      setTiempo(true);
    }, 8000);
  }, []);


  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar
  } = useDisclosure();

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: '1.2rem', textAlign: 'center' }}
        pauseOnHover
      />

      <Box
        scrollBehavior={'smooth'}
        bg={'#e5e5e5'}
        height={'auto'}
        maxHeight={'100vh'}
        overflowY={'auto'}
        css={{
          '&::-webkit-scrollbar': {
            width: '12px'
          },
          '&::-webkit-scrollbar-track': {
            background: '#6b6b6b',
            borderRadius: '0px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#9b9b9b',
            borderRadius: '6px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#9b9b9b'
          }
        }}>
        <Box
          as='section'
          display={{
            '2xl': 'unset',
            xl: 'unset',
            lg: 'none',
            md: 'none',
            sm: 'none'
          }}>
          <SidebarOperators />
        </Box>
        <Drawer
          isOpen={isOpenBar}
          onClose={onCloseBar}
          placement='left'>
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w='full' borderRight='none' />
          </DrawerContent>
        </Drawer>

        <Box bg={'#e5e5e5'} h={'100vh'}>
          <Flex
            alignItems={'center'}
            justify={'flex-start'}
            // h={'100%'}
            sx={{
              '@media only screen and (min-width: 1200px) and (max-width: 1366px)':
                {
                  padding: '1.5% 1.5% 1.5% 19%'
                }
            }}
            p={{
              '2xl': '1.5% 1% 1.5% 14%',
              xl: '1.5% 3% 1.5% 18%',
              lg: '1.5% 2% 1.5% 2%',
              md: '1.5% 2% 1.5% 2%'
            }}
            // marginLeft={'15%'}
            // marginRight='2%'
            // marginBottom='1%'
            direction='column'
            gap={'25px'}>
            <Box transition='.3s ease' width={'100%'}>
              <Flex
                gap='2%'
                alignItems={'center'}
                as='header'
                w='full'
                h='14'>
                <IconButton
                  aria-label='Menu'
                  display={{
                    '2xl': 'none',
                    xl: 'none',
                    lg: 'flex',
                    md: 'flex',
                    sm: 'flex'
                  }}
                  background={'#292F36'}
                  color={'#ffffff'}
                  borderRadius={'4px'}
                  fontSize={'1.3rem'}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size='md'
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      color={'#707070'}
                      fontSize={'1.5rem'}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={'bold'}
                      color={'#292F36'}
                      fontSize={'1.5rem'}>
                      REGISTRO DE HORAS
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>
            {/* ---------------------------------------------------------------------------------- */}

            <Flex
              width={{
                '2xl': '60%',
                xl: '80%',
                lg: '95%',
                md: '100%'
              }}
              direction={'column'}
              gap={'25px'}>
              <Flex
                boxShadow={
                  'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'
                }
                justifyContent={'center'}
                alignItems={'center'}
                background={'white'}
                borderRadius={'18px'}
                direction={'column'}
                gap={'25px'}
                padding={'3% 4% 2% 4%'}>
                <Text
                  fontSize={'2rem'}
                  textTransform={'uppercase'}
                  fontWeight={'bold'}
                  textColor={'#13235b'}
                  fontFamily={'monospace'}>
                  REGISTRO DE HORAS
                </Text>

                <Flex w={'100%'} gap={'35px'}>
                  <Flex w={'100%'} gap={'25px'} direction={'column'}>
                    <FormControl>
                      <FormLabel
                        fontSize={'1.85rem'}
                        fontWeight='500'>
                        FECHA INGRESO :
                      </FormLabel>
                      <Flex gap={'15px'} alignItems={'center'}>
                        <Input
                          value={ModelFecha.fecha_inicio}
                          onChange={handleChange}
                          name='fecha_inicio'
                          fontSize={'1.8rem'}
                          h='35'
                          type='date'
                        />
                       
                      </Flex>
                    </FormControl>

                    <FormControl>
                      <FormLabel
                        fontSize={'1.85rem'}
                        fontWeight='500'>
                        HORA DE INGRESO :
                      </FormLabel>
                      <Flex gap={'15px'} alignItems={'center'}>
                        <Input
                          value={ModelFecha.hora_inicio}
                          onChange={handleChange}
                          name='hora_inicio'
                          fontSize={'1.8rem'}
                          h='35'
                          type='time'
                        />
                    
                      </Flex>
                    </FormControl>
                  </Flex>

                  <Flex w={'100%'} gap={'25px'} direction={'column'}>
                    <FormControl>
                      <FormLabel
                        fontSize={'1.85rem'}
                        fontWeight='500'>
                        FECHA DE SALIDA :
                      </FormLabel>
                      <Flex gap={'15px'} alignItems={'center'}>
                        <Input
                          value={ModelFecha.fecha_salida}
                          onChange={handleChange}
                          name='fecha_salida'
                          fontSize={'1.8rem'}
                          h='35'
                          type='date'
                        />
                       
                      </Flex>
                    </FormControl>

                    <FormControl>
                      <FormLabel
                        fontSize={'1.85rem'}
                        fontWeight='500'>
                        HORA DE SALIDA :
                      </FormLabel>
                      <Flex gap={'15px'} alignItems={'center'}>
                        <Input
                          value={ModelFecha.hora_salida}
                          onChange={handleChange}
                          name='hora_salida'
                          fontSize={'1.8rem'}
                          h='35'
                          type='time'
                        />
                       
                      </Flex>
                    </FormControl>
                  </Flex>
                </Flex>

                <Flex
                  width={'100%'}
                  justifyContent={'center'}
                  // marginTop={'2%'}
                >
                {ModelFecha.id_control === '' ? (
                  <Button
                    onClick={agregarFecha}
                    borderRadius={'8px'}
                    textTransform='uppercase'
                    color={'white'}
                    fontSize={'1.6rem'}
                    bg='#2a2e6e'
                    _hover={{
                      color: 'white',
                      bg: '#f59f2c'
                    }}
                    p='2%'>
                    Guardar
                  </Button>
                  ): (
                    <Button
                      onClick={actualizar}
                      borderRadius={'8px'}
                      textTransform='uppercase'
                      color={'white'}
                      fontSize={'1.6rem'}
                      bg='#2a2e6e'
                      _hover={{
                        color: 'white',
                        bg: '#f59f2c'
                      }}
                      p='2%'>
                      Actualizar
                      </Button>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </>
  );
}

export default RegistroHoras;
