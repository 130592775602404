// import { ReactNode } from 'react';
import {
  Box,
  Flex,
  // Avatar,
  // HStack,
  // Link,
  // IconButton,
  // Button,
  // Menu,
  // MenuButton,
  // MenuList,
  // MenuItem,
  // Container,
  // Img,
  // MenuDivider,
  // useDisclosure,
  // useColorModeValue,
  // Stack,
  // MenuGroup,
  // Popover,
  // Divider,
  // PopoverTrigger,
  // PopoverContent,
  // PopoverArrow,
  // NavItem,
  // Collapse,
  // Text,
  // Badge,
  // Icon,
  // Drawer,
  // DrawerContent,
  // DrawerOverlay,
  // Input,
  // InputGroup,
  // InputLeftElement
} from '@chakra-ui/react';

//Validar Token
import { useHistory } from 'react-router-dom';

import SidebarOperators from '../../../components/SidebarOperators';
//validacion
function HomeView() {
  let history = useHistory();
  const token = localStorage.getItem('tokenadmin');
  if (!token) {
    history.push('/');
  }
  return (
    <>
      <Box>
        <SidebarOperators />
        <Box h={'100vh'} background='white'>
          <Flex marginLeft={'14%'}>Home Operadores</Flex>
        </Box>
      </Box>
    </>
  );
}

export default HomeView;
