import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Flex,
  IconButton,
  Button,
  useDisclosure,
  Text,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  Input,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import SidebarOperators from "../../../components/SidebarOperators";
import { host3 } from "../../../utils/constantes";
import axios from "axios";
import jwt_decode from "jwt-decode";

function TipoCambio() {
  let history = useHistory();
  const [activo1, setActivo1] = useState(false);
  const token = localStorage.getItem("tokenadmin");
  const { rol } = jwt_decode(token);

  useEffect(() => {
    const { rol } = jwt_decode(token);
    if (token) {
      if (rol == "OPERADOR") {
        history.push("/salas/MCasas");
      }
    }
  }, []);

  if (!token) {
    history.push("/");
  }

  const [tiempo, setTiempo] = useState(false);

  //Mensaje

  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setTiempo(true);
    }, 8000);
  }, []);

  //--- CREAR TIPO CAMBIO
  const [cambio, setCambio] = useState({
    id_tipo_cambio: "",
    dolar_euro: "",
    euro_dolar: "",
    dolar_local: "",
    local_dolar: "",
    euro_local: "",
    local_euro: "",
    id_sala: "",
  });

  const handleDolarEChange = (e) => {
    setCambio({
      ...cambio,
      [e.target.name]: e.target.value,
    });
  };

  const handleEuroDChange = (e) => {
    setCambio({
      ...cambio,
      [e.target.name]: e.target.value,
    });
  };

  const handleEuroLChange = (e) => {
    setCambio({
      ...cambio,
      [e.target.name]: e.target.value,
    });
  };

  const handleLocalEChange = (e) => {
    setCambio({
      ...cambio,
      [e.target.name]: e.target.value,
    });
  };

  const handleDolarLChange = (e) => {
    setCambio({
      ...cambio,
      [e.target.name]: e.target.value,
    });
  };

  const handleLocalDChange = (e) => {
    setCambio({
      ...cambio,
      [e.target.name]: e.target.value,
    });
  };

  const agregar = async () => {
    try {
      const response = await axios.post(
        `${host3}/api/tipo_cambios/${token}/crear`,
        cambio
      );
      mostrarDatos();
      mensaje(response.data.message, "top_right", 1);
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  const actualizar = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${host3}/api/tipo_cambios/${token}/actualizar/${cambio.id_tipo_cambio}`,
        cambio
      );
      mostrarDatos();
      mensaje(response.data.message, "top-right", 1);
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  const mostrarDatos = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/tipo_cambios/${token}/listar`
      );
      if (data.id_tipo_cambio === undefined) {
        setCambio({
          id_tipo_cambio: "",
          dola_euro: "",
          euro_dolar: "",
          dolar_local: "",
          local_dolar: "",
          euro_local: "",
          local_euro: "",
          id_sala: "",
        });
        return;
      }
      setCambio(data);
    } catch (error) {
      mensaje("Aun no ha registrado un tipo de cambio", "top-right", 2);
    }
  };

  const handleClickAutomatic = (attr, value) => {
    let result = 1 / parseFloat(value);
    setCambio({
      ...cambio,
      [attr]: result.toFixed(2),
    });
  };

  useEffect(() => {
    mostrarDatos();
    setTimeout(() => {
      setTiempo(true);
    }, 8000);
  }, []);

  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar,
  } = useDisclosure();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: "1.2rem", textAlign: "center" }}
        pauseOnHover
      />

      <Box
        scrollBehavior={"smooth"}
        bg={"#e5e5e5"}
        height={"auto"}
        maxHeight={"100vh"}
        overflowY={"auto"}
        css={{
          "&::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#6b6b6b",
            borderRadius: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#9b9b9b",
            borderRadius: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#9b9b9b",
          },
        }}
      >
        <Box
          as="section"
          display={{
            "2xl": "unset",
            xl: "unset",
            lg: "none",
            md: "none",
            sm: "none",
          }}
        >
          <SidebarOperators />
        </Box>
        <Drawer isOpen={isOpenBar} onClose={onCloseBar} placement="left">
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w="full" borderRight="none" />
          </DrawerContent>
        </Drawer>

        <Box bg={"#e5e5e5"} h={"100vh"}>
          <Flex
            alignItems={"center"}
            justify={"flex-start"}
            // h={'100%'}
            sx={{
              "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
                {
                  padding: "1.5% 1.5% 1.5% 19%",
                },
            }}
            p={{
              "2xl": "1.5% 1% 1.5% 14%",
              xl: "1.5% 3% 1.5% 18%",
              lg: "1.5% 2% 1.5% 2%",
              md: "1.5% 2% 1.5% 2%",
            }}
            // marginLeft={'15%'}
            // marginRight='2%'
            // marginBottom='1%'
            direction="column"
            gap={"25px"}
          >
            <Box transition=".3s ease" width={"100%"}>
              <Flex gap="2%" alignItems={"center"} as="header" w="full" h="14">
                <IconButton
                  aria-label="Menu"
                  display={{
                    "2xl": "none",
                    xl: "none",
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                  }}
                  background={"#292F36"}
                  color={"#ffffff"}
                  borderRadius={"4px"}
                  fontSize={"1.3rem"}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size="md"
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink color={"#707070"} fontSize={"1.5rem"}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={"bold"}
                      color={"#292F36"}
                      fontSize={"1.5rem"}
                    >
                      TIPO DE CAMBIO
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>
            {/* ---------------------------------------------------------------------------------- */}

            <Flex
              width={{
                "2xl": "60%",
                xl: "80%",
                lg: "95%",
                md: "100%",
              }}
              direction={"column"}
              gap={"25px"}
            >
              <Flex
                boxShadow={
                  "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"
                }
                justifyContent={"center"}
                alignItems={"center"}
                background={"white"}
                borderRadius={"18px"}
                direction={"column"}
                gap={"25px"}
                padding={"3% 4% 2% 4%"}
              >
                <Text
                  fontSize={"2rem"}
                  textTransform={"uppercase"}
                  fontWeight={"bold"}
                  textColor={"#13235b"}
                  fontFamily={"monospace"}
                >
                  Tipo de Cambio
                </Text>

                <Flex w={"100%"} gap={"35px"}>
                  <Flex w={"100%"} gap={"25px"} direction={"column"}>
                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        EURO - DOLAR :
                      </FormLabel>
                      <Flex gap={"15px"} alignItems={"center"}>
                        <Input
                          value={cambio.euro_dolar}
                          onChange={handleEuroDChange}
                          name="euro_dolar"
                          fontSize={"1.8rem"}
                          h="35"
                          type="number"
                        />
                        <Button
                          isDisabled={cambio.euro_dolar}
                          onClick={() => {
                            handleClickAutomatic(
                              "euro_dolar",
                              cambio.dolar_euro
                            );
                          }}
                          p={{
                            "2xl": "0% 10%",
                            xl: "0% 10%",
                            lg: "0% 10%",
                            md: "0% 15%",
                          }}
                          textTransform={"uppercase"}
                          color={"white"}
                          fontSize={"1.4rem"}
                          bg="#2a2e6e"
                          _hover={{
                            color: "white",
                            bg: "#f59f2c",
                          }}
                          h="35"
                          borderRadius={"8px"}
                        >
                          AUTOMATICO
                        </Button>
                      </Flex>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        DOLAR - EURO :
                      </FormLabel>
                      <Flex gap={"15px"} alignItems={"center"}>
                        <Input
                          value={cambio.dolar_euro}
                          onChange={handleDolarEChange}
                          name="dolar_euro"
                          fontSize={"1.8rem"}
                          h="35"
                          type="number"
                        />
                        <Button
                          isDisabled={cambio.dolar_euro}
                          onClick={() => {
                            handleClickAutomatic(
                              "dolar_euro",
                              cambio.euro_dolar
                            );
                          }}
                          p={{
                            "2xl": "0% 10%",
                            xl: "0% 10%",
                            lg: "0% 10%",
                            md: "0% 15%",
                          }}
                          textTransform={"uppercase"}
                          color={"white"}
                          fontSize={"1.4rem"}
                          bg="#2a2e6e"
                          _hover={{
                            color: "white",
                            bg: "#f59f2c",
                          }}
                          h="35"
                          borderRadius={"8px"}
                        >
                          Automatico
                        </Button>
                      </Flex>
                    </FormControl>
                  </Flex>

                  <Flex w={"100%"} gap={"25px"} direction={"column"}>
                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        DOLAR - LOCAL :
                      </FormLabel>
                      <Flex gap={"15px"} alignItems={"center"}>
                        <Input
                          value={cambio.dolar_local}
                          onChange={handleDolarLChange}
                          name="dolar_local"
                          fontSize={"1.8rem"}
                          h="35"
                          type="number"
                        />
                        <Button
                          isDisabled={cambio.dolar_local}
                          onClick={() => {
                            handleClickAutomatic(
                              "dolar_local",
                              cambio.local_dolar
                            );
                          }}
                          p={{
                            "2xl": "0% 10%",
                            xl: "0% 10%",
                            lg: "0% 10%",
                            md: "0% 15%",
                          }}
                          textTransform={"uppercase"}
                          color={"white"}
                          fontSize={"1.4rem"}
                          bg="#2a2e6e"
                          _hover={{
                            color: "white",
                            bg: "#f59f2c",
                          }}
                          h="35"
                          borderRadius={"8px"}
                        >
                          AUTOMATICO
                        </Button>
                      </Flex>
                    </FormControl>

                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        LOCAL - DOLAR :
                      </FormLabel>
                      <Flex gap={"15px"} alignItems={"center"}>
                        <Input
                          value={cambio.local_dolar}
                          onChange={handleLocalDChange}
                          name="local_dolar"
                          fontSize={"1.8rem"}
                          h="35"
                          type="number"
                        />
                        <Button
                          isDisabled={cambio.local_dolar}
                          onClick={() => {
                            handleClickAutomatic(
                              "local_dolar",
                              cambio.dolar_local
                            );
                          }}
                          p={{
                            "2xl": "0% 10%",
                            xl: "0% 10%",
                            lg: "0% 10%",
                            md: "0% 15%",
                          }}
                          textTransform={"uppercase"}
                          color={"white"}
                          fontSize={"1.4rem"}
                          bg="#2a2e6e"
                          _hover={{
                            color: "white",
                            bg: "#f59f2c",
                          }}
                          h="35"
                          borderRadius={"8px"}
                        >
                          AUTOMATICO
                        </Button>
                      </Flex>
                    </FormControl>
                  </Flex>

                  <Flex w={"100%"} gap={"25px"} direction={"column"}>
                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        EURO - LOCAL:
                      </FormLabel>
                      <Flex gap={"15px"} alignItems={"center"}>
                        <Input
                          value={cambio.euro_local}
                          onChange={handleEuroLChange}
                          name="euro_local"
                          fontSize={"1.8rem"}
                          h="35"
                          type="number"
                        />
                        <Button
                          isDisabled={cambio.euro_local}
                          onClick={() => {
                            handleClickAutomatic(
                              "euro_local",
                              cambio.local_euro
                            );
                          }}
                          p={{
                            "2xl": "0% 10%",
                            xl: "0% 10%",
                            lg: "0% 10%",
                            md: "0% 15%",
                          }}
                          textTransform={"uppercase"}
                          color={"white"}
                          fontSize={"1.4rem"}
                          bg="#2a2e6e"
                          _hover={{
                            color: "white",
                            bg: "#f59f2c",
                          }}
                          h="35"
                          borderRadius={"8px"}
                        >
                          AUTOMATICO
                        </Button>
                      </Flex>
                    </FormControl>

                    <FormControl>
                      <FormLabel fontSize={"1.85rem"} fontWeight="500">
                        LOCAL - EURO:
                      </FormLabel>
                      <Flex gap={"15px"} alignItems={"center"}>
                        <Input
                          value={cambio.local_euro}
                          onChange={handleLocalEChange}
                          name="local_euro"
                          fontSize={"1.8rem"}
                          h="35"
                          type="number"
                        />
                        <Button
                          isDisabled={cambio.local_euro}
                          onClick={() => {
                            handleClickAutomatic(
                              "local_euro",
                              cambio.euro_local
                            );
                          }}
                          p={{
                            "2xl": "0% 10%",
                            xl: "0% 10%",
                            lg: "0% 10%",
                            md: "0% 15%",
                          }}
                          textTransform={"uppercase"}
                          color={"white"}
                          fontSize={"1.4rem"}
                          bg="#2a2e6e"
                          _hover={{
                            color: "white",
                            bg: "#f59f2c",
                          }}
                          h="35"
                          borderRadius={"8px"}
                        >
                          AUTOMATICO
                        </Button>
                      </Flex>
                    </FormControl>
                  </Flex>
                </Flex>

                <Flex
                  width={"100%"}
                  justifyContent={"center"}
                  // marginTop={'2%'}
                >
                  {cambio.id_tipo_cambio == "" ? (
                    <Button
                      onClick={agregar}
                      borderRadius={"8px"}
                      textTransform="uppercase"
                      color={"white"}
                      fontSize={"1.6rem"}
                      bg="#2a2e6e"
                      _hover={{
                        color: "white",
                        bg: "#f59f2c",
                      }}
                      p="2%"
                    >
                      Guardar
                    </Button>
                  ) : (
                    <Button
                      onClick={actualizar}
                      borderRadius={"8px"}
                      textTransform="uppercase"
                      color={"white"}
                      fontSize={"1.6rem"}
                      bg="#2a2e6e"
                      _hover={{
                        color: "white",
                        bg: "#f59f2c",
                      }}
                      p="2%"
                    >
                      Actualizar
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </>
  );
}

export default TipoCambio;
