import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Vista Salas
import LoginView from "../views/Auth/LoginView";
import HomeView from "../views/Intranet/HomeView/homeViews";
import Colaboradores from "../views/Intranet/Colaboradores/Colaboradores";
import CuentasCasas from "../views/Intranet/CuentasCasas/CuentasCasas";
import CuentasBancos from "../views/Intranet/CuentasBancos/CuentasBancos";
import Salas from "../views/Intranet/Salas/Salas";
import Mantenimiento from "../views/Intranet/Mantenimiento/Matenimiento";
import MBanco from "../views/Intranet/MBanco/MBanco";
import Modulos from "../views/Intranet/Modulos/Modulos";
import MCasas from "../views/Intranet/MCasas/MCasas";
import Diarios from "../views/Intranet/Diarios/Diarios";
import NotFoundView from "../views/Intranet/NotFoundView/NotFoundView";
import Administracion from "../views/Intranet/Administracion/Administracion";
import SportTrader from "../views/Intranet/SportTrader/SportTrader";
import Entidad from "../views/Intranet/Entidades/Entidad";
import TipoCambio from "../views/Intranet/TipoCambio/TipoCambio";
import RegistroHoras from "../views/Intranet/RegistroHoras/RegistroHoras";
import Rdirario from "../views/Intranet/Rdiario/Rdiario";

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        {/* Páginas Salas */}
        <Route exact path="/" component={LoginView} />
        <Route exact path="/salas/home" component={HomeView} />
        <Route exact path="/salas/Mantenimiento" component={Mantenimiento} />
        <Route exact path="/salas/SportTrader" component={SportTrader} />
        <Route exact path="/salas/Entidad" component={Entidad} />
        <Route exact path="/salas/Inversionista" component={Colaboradores} />

        <Route exact path="/salas/administracion" component={Administracion} />
        <Route exact path="/salas/CuentasBancos" component={CuentasBancos} />
        <Route exact path="/salas/CuentasCasas" component={CuentasCasas} />
        <Route exact path="/salas/MSalas" component={Salas} />
        <Route exact path="/salas/Modulos" component={Modulos} />
        <Route exact path="/salas/MCasas" component={MCasas} />
        <Route exact path="/salas/Rdiario" component={Rdirario} />

        <Route exact path="/salas/TipoCambio" component={TipoCambio} />

        <Route exact path="/salas/MBanco" component={MBanco} />
        <Route exact path="/salas/RegistroHoras" component={RegistroHoras} />
        <Route exact path="/salas/Diarios" component={Diarios} />

        {/* Error 404 */}
        <Route path="*" component={NotFoundView} />
      </Switch>
    </Router>
  );
}
