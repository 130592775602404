import { ReactNode, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Textarea,
  Tooltip,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Container,
  Img,
  MenuDivider,
  useDisclosure,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  Icon,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
// ------------------icons----------------------
import { FaPlus } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
// ----------------------------------------

import { useHistory } from "react-router-dom";
import SidebarOperators from "../../../components/SidebarOperators";
import jwt_decode from "jwt-decode";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import moment from "moment";
import { host3 } from "../../../utils/constantes";
function Rdiario() {
  let history = useHistory();
  const token = localStorage.getItem("tokenadmin");
  const { id_persona, modulo, nombre_modulo, rol, nombre, sala } =
    jwt_decode(token);
  if (!token) {
    history.push("/");
  }
  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        break;
    }
  };

  const [tablesData, setTablesData] = useState([
    {
      name: "Fondo En Banco   1",
      total: "2992.28",
      tipo: "INIICO",
      num_tabla: 1,
      items: [
        {
          id: 1,
          name: "",
          quantity: "",
        },
        {
          id: 2,
          name: "",
          quantity: "",
        },
        {
          id: 3,
          name: "",
          quantity: "",
        },
        {
          id: 4,
          title: "",
          name: "",
          quantity: "",
        },
        {
          id: 5,
          name: "",
          quantity: "",
        },
        {
          id: 6,
          name: "",
          quantity: "",
        },
      ],
    },
    {
      name: "Fondo En Banco   2",
      total: "1500.00",
      tipo: "INICIO",
      num_tabla: 2,
      items: [
        {
          id: 1,
          name: "",
          quantity: "",
        },
        {
          id: 2,
          name: "",
          quantity: "",
        },
        {
          id: 3,
          name: "",
          quantity: "",
        },
        {
          id: 4,
          title: "",
          name: "",
          quantity: "",
        },
        {
          id: 5,
          name: "",
          quantity: "",
        },
        {
          id: 6,
          name: "",
          quantity: "",
        },
      ],
    },
    {
      name: "Fondo En Banco   3",
      total: "1200.00",
      tipo: "INICIO",
      num_tabla: 3,
      items: [
        {
          id: 1,
          name: "",
          quantity: "",
        },
        {
          id: 2,
          name: "",
          quantity: "",
        },
        {
          id: 3,
          name: "",
          quantity: "",
        },
        {
          id: 4,
          title: "",
          name: "",
          quantity: "",
        },
        {
          id: 5,
          name: "",
          quantity: "",
        },
        {
          id: 6,
          name: "",
          quantity: "",
        },
      ],
    },
  ]);

  const [tablesData2, setTablesData2] = useState([
    {
      name: "Fondo En Banco   1",
      total: "2992.28",
      tipo: "FINAL",
      num_tabla: 1,
      items: [
        {
          id: 1,
          name: "",
          quantity: "",
        },
        {
          id: 2,
          name: "",
          quantity: "",
        },
        {
          id: 3,
          name: "",
          quantity: "",
        },
        {
          id: 4,
          title: "",
          name: "",
          quantity: "",
        },
        {
          id: 5,
          name: "",
          quantity: "",
        },
        {
          id: 6,
          name: "",
          quantity: "",
        },
      ],
    },
    {
      name: "Fondo En Banco   2",
      total: "1500.00",
      tipo: "FINAL",
      num_tabla: 2,
      items: [
        {
          id: 1,
          name: "",
          quantity: "",
        },
        {
          id: 2,
          name: "",
          quantity: "",
        },
        {
          id: 3,
          name: "",
          quantity: "",
        },
        {
          id: 4,
          title: "",
          name: "",
          quantity: "",
        },
        {
          id: 5,
          name: "",
          quantity: "",
        },
        {
          id: 6,
          name: "",
          quantity: "",
        },
      ],
    },
    {
      name: "Fondo En Banco   3",
      total: "1200.00",
      tipo: "FINAL",
      num_tabla: 3,
      items: [
        {
          id: 1,
          name: "",
          quantity: "",
        },
        {
          id: 2,
          name: "",
          quantity: "",
        },
        {
          id: 3,
          name: "",
          quantity: "",
        },
        {
          id: 4,
          title: "",
          name: "",
          quantity: "",
        },
        {
          id: 5,
          name: "",
          quantity: "",
        },
        {
          id: 6,
          name: "",
          quantity: "",
        },
      ],
    },
  ]);

  const [showDelete, setShowDelete] = useState(false);
  const [isInputEnabled, setIsInputEnabled] = useState(false);

  const handleAddClick = (table) => {
    setShowDelete(!showDelete);
    setIsInputEnabled(!isInputEnabled);

    if (isInputEnabled && table == 1) {
      saveFondosBanco(1);
    }

    if (isInputEnabled && table == 2) {
      saveFondosBanco(2);
    }
  };

  const handleDelete = (indexToRemove) => {
    setTablesData(tablesData.filter((_, index) => index !== indexToRemove));
  };

  const handleDelete2 = (indexToRemove) => {
    setTablesData2(tablesData2.filter((_, index) => index !== indexToRemove));
  };

  // Controlador único para manejar los cambios en cualquier campo de entrada
  const handleInputChange = (event, indiceTabla, indiceItem, campo) => {
    const nuevoValor = event.target.value;

    setTablesData((tablesDataAnterior) => {
      const tablesDataActualizado = [...tablesDataAnterior];
      if (campo === "tableName") {
        // Si el campo a editar es el nombre de la tabla
        tablesDataActualizado[indiceTabla].name = nuevoValor;
      } else {
        // Si el campo a editar es el nombre o la cantidad de un ítem
        tablesDataActualizado[indiceTabla].items[indiceItem][campo] =
          nuevoValor;
      }
      return tablesDataActualizado;
    });
  };

  const calcularTotalPorTabla = (tabla) => {
    let total = 0;
    tabla.items.forEach((item) => {
      const cantidad = parseFloat(item.quantity) || 0;
      total += cantidad;
    });
    return total.toFixed(2); // Ajusta el número de decimales según tus necesidades
  };

  const handleInputChange2 = (event, indiceTabla, indiceItem, campo) => {
    const nuevoValor = event.target.value;

    setTablesData2((tablesDataAnterior) => {
      const tablesDataActualizado = [...tablesDataAnterior];
      if (campo === "tableName") {
        // Si el campo a editar es el nombre de la tabla
        tablesDataActualizado[indiceTabla].name = nuevoValor;
      } else {
        // Si el campo a editar es el nombre o la cantidad de un ítem
        tablesDataActualizado[indiceTabla].items[indiceItem][campo] =
          nuevoValor;
      }
      return tablesDataActualizado;
    });
  };

  const [date, setDate] = useState({
    fecha: moment().format("YYYY-MM-DD"),
  });

  //Funcion para formateo de fecha
  const formateDate = () => {
    let fecha = new Date(date.fecha);
    const dias = [
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
      "domingo",
    ];
    const meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    let day_number = moment(date.fecha).format("DD");
    let day = fecha.getDay();
    let mes = fecha.getMonth();
    let year = moment(date.fecha).format("YYYY");

    return `${dias[day].toUpperCase()} ${day_number} DE ${meses[
      mes
    ].toUpperCase()} DEL ${year}`;
  };

  //Funcion para cambio de fecha
  const nextAndBeforeDate = (type) => {
    if (type == "next") {
      const nuevaFecha = moment(date.fecha).add(1, "days").format("YYYY-MM-DD");
      setDate({ fecha: nuevaFecha });
    }

    if (type == "before") {
      const nuevaFecha = moment(date.fecha)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      setDate({ fecha: nuevaFecha });
    }
  };

  //Estado
  const [listRecharges, setListRecharges] = useState([]);
  const [amountTotalRecharges, setAmountTotalRecharges] = useState(0);

  // Funciones API
  const fetchRecharges = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/movimiento_casas/${token}/list-recharge/${date.fecha}`
      );
      setListRecharges(data.recargas);
      setAmountTotalRecharges(data.total_recargas);
    } catch (error) {
      console.log(error);
    }
  };

  const [listRetirement, setListRetirement] = useState([]);
  const [amountTotalRetirements, setAmountTotalRetirements] = useState(0);

  // Funciones API
  const fetchRetirements = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/movimiento_casas/${token}/list-retirement/${date.fecha}`
      );
      setListRetirement(data.retiros);
      setAmountTotalRetirements(data.total_retiros);
    } catch (error) {
      console.log(error);
    }
  };

  // Funcion para modulos de inicio
  const [listInitialDay, setListInitialDay] = useState([]);
  const [amountInitialDay, setAmountInitialDay] = useState(0);

  const fetchInitialDay = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/cajas/${token}/USD/list-start-of-day/${date.fecha}`
      );
      setListInitialDay(data.modulos);
      setAmountInitialDay(data.total_modulos);
    } catch (error) {
      console.log(error);
    }
  };

  // Funcion para modulos de fin
  const [listEndDay, setListEndDay] = useState([]);
  const [amountEndDay, setAmountEndDay] = useState(0);

  const fetchEndDay = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/cajas/${token}/USD/list-end-of-day/${date.fecha}`
      );
      setListEndDay(data.modulos);
      setAmountEndDay(data.total_modulos);
    } catch (error) {
      console.log(error);
    }
  };

  // Fondos apertura
  const [fondosApertura, setFondosApertura] = useState([]);
  const [accounstApertura, setAccounstApertura] = useState([]);
  const [fondosFinal, setFondosFinal] = useState([]);
  const [accounstFinal, setAccounstFinal] = useState([]);

  const fetchOpenFondos = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/balances/${token}/fondos/${date.fecha}`
      );
      setFondosApertura(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFinalFondos = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/balances/${token}/fondos-final/${date.fecha}`
      );
      setFondosFinal(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAccountsOpenFondos = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/balances/${token}/accounts/${date.fecha}`
      );
      setAccounstApertura(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAccountsFinalFondos = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/balances/${token}/accounts-final/${date.fecha}`
      );
      setAccounstFinal(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [expenseApertura, setExpenseApertura] = useState([]);

  const fetchAccountsOpenExpenses = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/balances/${token}/expenses`
      );
      setExpenseApertura(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [retenidoApertura, setRetenidoApertura] = useState([]);
  const [retenidoFinal, setRetenidoFinal] = useState([]);

  const fetchAccountsOpenRetenidos = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/balances/${token}/retentions/${date.fecha}`
      );
      setRetenidoApertura(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAccountsFinalRetenidos = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/balances/${token}/retentions-final/${date.fecha}`
      );
      setRetenidoFinal(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [casaRetenidoApertura, setCasaRetenidoApertura] = useState([]);
  const [casaRetenidoFinal, setCasaRetenidoFinal] = useState([]);

  const fetchCasaOpenRetenidos = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/balances/${token}/retention-casas/${date.fecha}`
      );
      setCasaRetenidoApertura(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCasaFinalRetenidos = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/balances/${token}/retention-casas-final/${date.fecha}`
      );
      setCasaRetenidoFinal(data);
    } catch (error) {
      console.log(error);
    }
  };

  const [cambiosApertura, setCambiosApertura] = useState([]);

  const fetchCasaOpenCambioss = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/balances/${token}/cambios`
      );
      setCambiosApertura(data);
    } catch (error) {
      console.log(error);
    }
  };

  const totalFondosInicio = () => {
    let banco_billetera =
      parseFloat(fondosApertura.billetera_dolar) +
      parseFloat(fondosApertura.billetera_euro_dolar) +
      parseFloat(fondosApertura.billetera_local_dolar) +
      parseFloat(fondosApertura.cuenta_madre_dolar) +
      parseFloat(fondosApertura.cuenta_madre_euro_dolar) +
      parseFloat(fondosApertura.cuenta_madre_local_dolar) +
      parseFloat(fondosApertura.cuenta_otra) +
      parseFloat(fondosApertura.cuenta_otra_euro_dolar) +
      parseFloat(fondosApertura.cuenta_otra_local_dolar);

    let retenido =
      parseFloat(retenidoApertura.bank_total) +
      parseFloat(retenidoApertura.wallet_total);
    let retenido_casa = parseFloat(casaRetenidoApertura.total_casa);
    let gastos = parseFloat(expenseApertura.total);

    let total =
      parseFloat(banco_billetera) +
      parseFloat(retenido) +
      parseFloat(retenido_casa) +
      parseFloat(gastos);

    return total;
  };

  const totalQuantity = totalFondosInicio();

  let cambios_total =
    parseFloat(cambiosApertura.total_ganada) +
    parseFloat(cambiosApertura.total_perdida);

  const totalQuantity2 = totalFondosInicio() + cambios_total;

  const saveFondoForDay = async (tipo) => {
    let datas =
      tipo == "INICIO"
        ? {
            fondos: fondosApertura,
            datos: accounstApertura,
            fondo_retencion: retenidoApertura,
            dato_retencion: casaRetenidoApertura,
            tipo,
          }
        : {
            fondos: fondosApertura,
            datos: accounstApertura,
            fondo_retencion: retenidoApertura,
            dato_retencion: casaRetenidoApertura,
            ganancia_perdida: cambiosApertura,
            tipo,
          };
    try {
      const { status, data } = await axios.post(
        `${host3}/api/balances/${token}/`,
        datas
      );

      if (status == 201) {
        mensaje(data.message, "top-right", 1);
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 3);
    }
  };

  useEffect(() => {
    fetchRecharges();
    fetchRetirements();
    fetchInitialDay();
    fetchEndDay();
    fetchOpenFondos();
    fetchFinalFondos()
    fetchAccountsOpenFondos();
    fetchAccountsFinalFondos()
    fetchAccountsOpenExpenses();
    fetchAccountsOpenRetenidos();
    fetchAccountsFinalRetenidos()
    fetchCasaOpenRetenidos();
    fetchCasaFinalRetenidos()
    fetchCasaOpenCambioss();
  }, [date.fecha]);

  // Notas
  const [note, setNote] = useState({
    update: false,
    inicio: "",
    termino: "",
  });

  const handleChangNote = ({ target: { name, value } }) => {
    setNote({ ...note, [name]: value });
  };

  const [type, setType] = useState("INICIO");

  const saveNoteForDay = async () => {
    const datas =
      type == "INICIO"
        ? { fecha: date.fecha, nota: note.inicio, type: type }
        : { fecha: date.fecha, nota: note.termino, type: type };

    try {
      const { status, data } = await axios.post(
        `${host3}/api/cajas/${token}/note`,
        datas
      );

      if (status == 201) {
        mensaje(data.message, "top-right", 1);
        setNote({
          update: false,
          inicio: "",
          termino: "",
        });
        fetchNoteForDayAndType();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  const updateNoteForDay = async () => {
    const datas =
      type == "INICIO"
        ? { fecha: date.fecha, nota: note.inicio, type: type }
        : { fecha: date.fecha, nota: note.termino, type: type };

    try {
      const { status, data } = await axios.put(
        `${host3}/api/cajas/${token}/note/${note.id_nota}`,
        datas
      );

      if (status == 201) {
        mensaje(data.message, "top-right", 1);
        fetchNoteForDayAndType();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  const fetchNoteForDayAndType = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/cajas/${token}/notes/${type}/date/${date.fecha}`
      );

      if (type == "INICIO") {
        setNote({
          update: true,
          id_nota: data.id_nota,
          inicio: data.nota,
          final: "",
        });
      }
      if (type == "FINAL") {
        setNote({
          update: true,
          id_nota: data.id_nota,
          inicio: "",
          termino: data.nota,
        });
      }
    } catch (error) {
      setNote({
        id_nota: "",
        inicio: "",
        termino: "",
      });
    }
  };

  useEffect(() => {
    fetchNoteForDayAndType();
  }, [type, date.fecha]);

  // Capital Inicial
  const [capital, setCapital] = useState({
    capital: "",
    id_sala: sala[0],
    fecha: "",
  });

  const handleChangCapital = ({ target: { name, value } }) => {
    setCapital({ ...capital, [name]: value });
  };

  const saveCapitalInicial = async () => {
    try {
      capital.fecha = date.fecha;

      const { status, data } = await axios.post(
        `${host3}/api/capital_sala/${token}`,
        capital
      );

      if (status == 201) {
        mensaje(data.message, "top-right", 1);
        setCapital({
          capital: "",
          id_sala: sala[0],
          fecha: "",
        });
        fetchCapital();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  const updateCapitalInicial = async () => {
    try {
      const { status, data } = await axios.put(
        `${host3}/api/capital_sala/${token}/actualizar-capital/${capital.id_capital}`,
        {
          nuevoCapital: capital.capital,
          id_sala: sala[0],
          nuevaFecha: date.fecha,
        }
      );

      if (status == 201) {
        mensaje(data.message, "top-right", 1);
        setCapital({
          capital: "",
          id_sala: sala[0],
          fecha: "",
        });
        fetchCapital();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  const fetchCapital = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/capital_sala/${token}/listar-capital/${date.fecha}`
      );
      setCapital(data[0]);
    } catch (error) {
      setCapital({
        capital: "0.00",
        id_sala: sala[0],
        fecha: "",
      });
    }
  };

  //Fondos banco
  const saveFondosBanco = async (tabla) => {
    const datas =
      tabla == 1
        ? {
            id_sala: sala[0],
            items: tablesData,
            fecha_inicio: date.fecha,
            tipo: type,
            edit: tablesData[0].edit ? true : false,
          }
        : {
            id_sala: sala[0],
            items: tablesData2,
            fecha_inicio: date.fecha,
            tipo: type,
            edit: tablesData2[0].edit ? true : false,
          };
    try {
      const { status, data } = await axios.post(
        `${host3}/api/fondo_bancos/${token}`,
        datas
      );

      if (status == 201) {
        fetchFondosBanco();
        fetchFondosBancoFin();
        //console.log(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFondosBanco = async () => {
    try {
      const { status, data } = await axios.get(
        `${host3}/api/fondo_bancos/${token}/list_fondo/${date.fecha}`
      );
      if (status == 200) {
        setTablesData(data);
      }
    } catch (error) {
      setTablesData([
        {
          name: "Fondo En Banco   1",
          total: "2992.28",
          tipo: "INIICO",
          num_tabla: 1,
          items: [
            {
              id: 1,
              name: "",
              quantity: "",
            },
            {
              id: 2,
              name: "",
              quantity: "",
            },
            {
              id: 3,
              name: "",
              quantity: "",
            },
            {
              id: 4,
              title: "",
              name: "",
              quantity: "",
            },
            {
              id: 5,
              name: "",
              quantity: "",
            },
            {
              id: 6,
              name: "",
              quantity: "",
            },
          ],
        },
        {
          name: "Fondo En Banco   2",
          total: "1500.00",
          tipo: "INICIO",
          num_tabla: 2,
          items: [
            {
              id: 1,
              name: "",
              quantity: "",
            },
            {
              id: 2,
              name: "",
              quantity: "",
            },
            {
              id: 3,
              name: "",
              quantity: "",
            },
            {
              id: 4,
              title: "",
              name: "",
              quantity: "",
            },
            {
              id: 5,
              name: "",
              quantity: "",
            },
            {
              id: 6,
              name: "",
              quantity: "",
            },
          ],
        },
        {
          name: "Fondo En Banco   3",
          total: "1200.00",
          tipo: "INICIO",
          num_tabla: 3,
          items: [
            {
              id: 1,
              name: "",
              quantity: "",
            },
            {
              id: 2,
              name: "",
              quantity: "",
            },
            {
              id: 3,
              name: "",
              quantity: "",
            },
            {
              id: 4,
              title: "",
              name: "",
              quantity: "",
            },
            {
              id: 5,
              name: "",
              quantity: "",
            },
            {
              id: 6,
              name: "",
              quantity: "",
            },
          ],
        },
      ]);
    }
  };

  const fetchFondosBancoFin = async () => {
    try {
      const { status, data } = await axios.get(
        `${host3}/api/fondo_bancos/${token}/list_fondo_fin/${date.fecha}`
      );
      if (status == 200) {
        setTablesData2(data);
      }
    } catch (error) {
      setTablesData2([
        {
          name: "Fondo En Banco   1",
          total: "2992.28",
          tipo: "FINAL",
          num_tabla: 1,
          items: [
            {
              id: 1,
              name: "",
              quantity: "",
            },
            {
              id: 2,
              name: "",
              quantity: "",
            },
            {
              id: 3,
              name: "",
              quantity: "",
            },
            {
              id: 4,
              title: "",
              name: "",
              quantity: "",
            },
            {
              id: 5,
              name: "",
              quantity: "",
            },
            {
              id: 6,
              name: "",
              quantity: "",
            },
          ],
        },
        {
          name: "Fondo En Banco   2",
          total: "1500.00",
          tipo: "FINAL",
          num_tabla: 2,
          items: [
            {
              id: 1,
              name: "",
              quantity: "",
            },
            {
              id: 2,
              name: "",
              quantity: "",
            },
            {
              id: 3,
              name: "",
              quantity: "",
            },
            {
              id: 4,
              title: "",
              name: "",
              quantity: "",
            },
            {
              id: 5,
              name: "",
              quantity: "",
            },
            {
              id: 6,
              name: "",
              quantity: "",
            },
          ],
        },
        {
          name: "Fondo En Banco   3",
          total: "1200.00",
          tipo: "FINAL",
          num_tabla: 3,
          items: [
            {
              id: 1,
              name: "",
              quantity: "",
            },
            {
              id: 2,
              name: "",
              quantity: "",
            },
            {
              id: 3,
              name: "",
              quantity: "",
            },
            {
              id: 4,
              title: "",
              name: "",
              quantity: "",
            },
            {
              id: 5,
              name: "",
              quantity: "",
            },
            {
              id: 6,
              name: "",
              quantity: "",
            },
          ],
        },
      ]);
      //console.log(error)
    }
  };

  //Añadir nueva tabla
  const addTable = () => {
    const ultimoNumero = tablesData.reduce((max, tabla) => {
      const num = tabla.num_tabla || 0;
      return num > max ? num : max;
    }, 0);

    const nuevaTabla = {
      name: `Fondo En Banco ${ultimoNumero + 1}`,
      total: "0.00",
      tipo: "INICIO", // Ajusta según tus necesidades
      num_tabla: ultimoNumero + 1,
      items: [
        { id: 1, name: "", quantity: "" },
        { id: 2, name: "", quantity: "" },
        { id: 3, name: "", quantity: "" },
        { id: 4, title: "", name: "", quantity: "" },
        { id: 5, name: "", quantity: "" },
        { id: 6, name: "", quantity: "" },
      ],
    };

    setTablesData((estadoAnterior) => [...estadoAnterior, nuevaTabla]);
  };

  const addTable2 = () => {
    const ultimoNumero = tablesData2.reduce((max, tabla) => {
      const num = tabla.num_tabla || 0;
      return num > max ? num : max;
    }, 0);

    const nuevaTabla = {
      name: `Fondo En Banco ${ultimoNumero + 1}`,
      total: "0.00",
      tipo: "FINAL", // Ajusta según tus necesidades
      num_tabla: ultimoNumero + 1,
      items: [
        { id: 1, name: "", quantity: "" },
        { id: 2, name: "", quantity: "" },
        { id: 3, name: "", quantity: "" },
        { id: 4, title: "", name: "", quantity: "" },
        { id: 5, name: "", quantity: "" },
        { id: 6, name: "", quantity: "" },
      ],
    };

    setTablesData2((estadoAnterior) => [...estadoAnterior, nuevaTabla]);
  };

  //Calculos
  const gananciaNetaSinComision = () => {
    let inicial_dia = parseFloat(totalQuantity) + parseFloat(amountInitialDay);
    let termino_dia = parseFloat(totalQuantity2) + parseFloat(amountEndDay);

    return parseFloat(termino_dia) - parseFloat(inicial_dia);
  };

  useEffect(() => {
    fetchCapital();
    fetchFondosBanco();
    fetchFondosBancoFin();
  }, [date.fecha]);

  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar,
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: "1.2rem", textAlign: "center" }}
        pauseOnHover
      />

      <Box
        scrollBehavior={"smooth"}
        bg={"#e5e5e5"}
        height={"auto"}
        maxHeight={"100vh"}
        overflowY={"auto"}
        css={{
          "&::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#6b6b6b",
            borderRadius: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#9b9b9b",
            borderRadius: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#9b9b9b",
          },
        }}
      >
        <Box
          as="section"
          display={{
            "2xl": "unset",
            xl: "unset",
            lg: "none",
            md: "none",
            sm: "none",
          }}
        >
          <SidebarOperators />
        </Box>
        <Drawer isOpen={isOpenBar} onClose={onCloseBar} placement="left">
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w="full" borderRight="none" />
          </DrawerContent>
        </Drawer>
        <Box h={"100vh"}>
          <Flex
            alignItems={"center"}
            justify={"flex-start"}
            h={"100%"}
            sx={{
              "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
                {
                  padding: "1.5% 3% 1.5% 19%",
                },
            }}
            p={{
              "2xl": "1.5% 3% 1.5% 14%",
              xl: "1.5% 3% 1.5% 18%",
              lg: "1.5% 2% 1.5% 2%",
              md: "1.5% 2% 1.5% 2%",
            }}
            // marginLeft={'15%'}
            // marginRight='2%'
            // marginBottom='1%'
            direction="column"
            // gap={'25px'}
          >
            <Box transition=".3s ease" width={"100%"}>
              <Flex gap="2%" alignItems={"center"} as="header" w="full" h="14">
                <IconButton
                  aria-label="Menu"
                  display={{
                    "2xl": "none",
                    xl: "none",
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                  }}
                  background={"#292F36"}
                  color={"#ffffff"}
                  borderRadius={"4px"}
                  fontSize={"1.3rem"}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size="md"
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink color={"#707070"} fontSize={"1.5rem"}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={"bold"}
                      color={"#292F36"}
                      fontSize={"1.5rem"}
                    >
                      Balance de Sala
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>
            <Flex w={"100%"} justify={""} direction={"column"} gap={"15px"}>
              <Flex w={"100%"} justify={"space-between"} gap={1}>
                <IconButton
                  onClick={() => nextAndBeforeDate("before")}
                  bg={"#FFFF"}
                  color={"#2A2E6E"}
                  fontSize={"3rem"}
                  w={"75px"}
                  h={"56px"}
                  borderRadius="10px 0px 0px 10px"
                  icon={<RiArrowLeftSLine />}
                />
                <Flex
                  bg={"#FFF"}
                  w={"100%"}
                  justify={"center"}
                  align={"center"}
                >
                  <Text
                    fontSize={"1.433rem"}
                    fontFamily={"ObjectiveBold"}
                    textColor={"#2A2E6E"}
                  >
                    {formateDate()}
                  </Text>
                </Flex>
                <IconButton
                  onClick={() => nextAndBeforeDate("next")}
                  bg={"#FFFF"}
                  color={"#2A2E6E"}
                  fontSize={"3rem"}
                  w={"75px"}
                  h={"56px"}
                  borderRadius="0px 10px 10px 0px"
                  icon={<RiArrowRightSLine />}
                />
              </Flex>
              <Flex>
                <Tabs isFitted variant="enclosed" w={"100%"}>
                  <TabList mb="1em" bg={"#FFFF"} borderRadius="15px">
                    <Tab
                      onClick={() => setType("INICIO")}
                      borderRadius={"10px 0px 0px 10px"}
                      _selected={{
                        color: "white",
                        bg: "#FFB400",
                        textColor: "#2A2E6E",
                      }}
                      fontSize={{
                        "2xl": "1.6rem",
                        xl: "1.84rem",
                        lg: "1.84rem",
                        md: "1.7rem",
                      }}
                      letterSpacing={"0.83px"}
                      textTransform={"uppercase"}
                      fontFamily="ObjectiveBold"
                      fontWeight={"600"}
                    >
                      INICIO DEL DÍA
                    </Tab>
                    <Tab
                      onClick={() => setType("FINAL")}
                      borderRadius={"0px 10px 10px 0px"}
                      _selected={{
                        color: "white",
                        bg: "#FFB400",
                        textColor: "#2A2E6E",
                      }}
                      fontSize={{
                        "2xl": "1.6rem",
                        xl: "1.84rem",
                        lg: "1.84rem",
                        md: "1.7rem",
                      }}
                      fontFamily="ObjectiveBold"
                      textTransform={"uppercase"}
                      fontWeight={"600"}
                    >
                      TERMINO DEL DÍA
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel w={"100%"}>
                      <Flex direction={"column"} gap={4}>
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          gap="20px"
                          w={"100%"}
                          h="100%"
                        >
                          <TableContainer
                            w={"100%"}
                            borderRadius="10px"
                            border="1px solid"
                            bgColor={"#FFFF"}
                            borderColor={"#2A2E6E"}
                          >
                            <Table variant="simple" borderCollapse="collapse">
                              <Thead textColor={"#FFFF"}>
                                <Tr>
                                  <Th
                                    fontSize={"1.4rem"}
                                    letterSpacing={"0.83px"}
                                    textColor={"#FFFF"}
                                    bgColor={"#2A2E6E"}
                                    textAlign={"center"}
                                    borderRight="1px solid #2A2E6E"
                                    borderBottom="1px solid"
                                  >
                                    DINERO INICIAL DEL DÍA
                                  </Th>
                                  <Th
                                    fontSize={"1.4rem"}
                                    letterSpacing={"0.83px"}
                                    textColor={"#FFFF"}
                                    bgColor={"#2A2E6E"}
                                    textAlign={"center"}
                                    borderRight="1px solid #2A2E6E"
                                    borderBottom="1px solid"
                                  >
                                    RECARGAS
                                  </Th>
                                  <Th
                                    fontSize={"1.4rem"}
                                    letterSpacing={"0.83px"}
                                    textColor={"#FFFF"}
                                    bgColor={"#2A2E6E"}
                                    textAlign={"center"}
                                    borderRight="1px solid #2A2E6E"
                                    borderBottom="1px solid"
                                  >
                                    RETIROS
                                  </Th>
                                  <Th
                                    fontSize={"1.4rem"}
                                    letterSpacing={"0.83px"}
                                    textColor={"#FFFF"}
                                    bgColor={"#2A2E6E"}
                                    textAlign={"center"}
                                    borderBottom="1px solid"
                                  >
                                    DINERO TOTAL INICIAL DEL DÍA
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Td
                                    textColor={"#2A2E6E"}
                                    fontSize={"1.25rem"}
                                    textAlign={"center"}
                                    fontFamily={"ObjectiveRegular"}
                                    borderRight="1px solid #2A2E6E"
                                  >
                                    {(
                                      parseFloat(totalQuantity) +
                                      parseFloat(amountInitialDay)
                                    ).toFixed(2)}
                                  </Td>
                                  <Td
                                    textColor={"#2A2E6E"}
                                    fontSize={"1.25rem"}
                                    textAlign={"center"}
                                    fontFamily={"ObjectiveRegular"}
                                    borderRight="1px solid #2A2E6E"
                                  >
                                    {amountTotalRecharges.toFixed(2)}
                                  </Td>
                                  <Td
                                    textColor={"#2A2E6E"}
                                    fontSize={"1.25rem"}
                                    textAlign={"center"}
                                    fontFamily={"ObjectiveRegular"}
                                    borderRight="1px solid #2A2E6E"
                                  >
                                    {amountTotalRetirements.toFixed(2)}
                                  </Td>
                                  <Td
                                    textColor={"#2A2E6E"}
                                    fontSize={"1.25rem"}
                                    textAlign={"center"}
                                    fontFamily={"ObjectiveRegular"}
                                  >
                                    {(
                                      parseFloat(totalQuantity) +
                                      parseFloat(amountInitialDay) -
                                      parseFloat(amountTotalRecharges) +
                                      parseFloat(amountTotalRetirements)
                                    ).toFixed(2)}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </TableContainer>
                          {/* ------------------------- */}

                          <TableContainer
                            w={"20%"}
                            onClick={onOpen}
                            cursor={"pointer"}
                            borderRadius="10px"
                            border="1px solid"
                            bgColor={"#FFFF"}
                            borderColor={"#2A2E6E"}
                          >
                            <Table variant="simple" borderCollapse="collapse">
                              <Thead textColor={"#FFFF"}>
                                <Tr>
                                  <Th
                                    fontSize={"1.4rem"}
                                    letterSpacing={"0.83px"}
                                    textColor={"#2A2E6E"}
                                    bgColor={"#FFB400"}
                                    textAlign={"center"}
                                    borderRight="1px solid #2A2E6E"
                                    borderBottom="1px solid"
                                  >
                                    CAPITAL INICIAL
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Td
                                    textColor={"#2A2E6E"}
                                    fontSize={"1.25rem"}
                                    textAlign={"center"}
                                    fontFamily={"ObjectiveRegular"}
                                  >
                                    {capital.capital}
                                  </Td>
                                </Tr>
                                {/* Add more rows as needed */}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </Flex>
                        {/* --------------------------------------- */}
                        <Flex gap={"15px"} w={"100%"}>
                          <Flex w={"65%"} direction={"column"} gap={"15px"}>
                            <Flex
                              w={"100%"}
                              direction={"column"}
                              borderRadius={"10px"}
                              borderColor={"#2A2E6E"}
                              bgColor={"#FFFF"}
                              borderBottom={"1px"}
                              borderRight={"1px"}
                              borderLeft={"1px"}
                              pb={"2%"}
                            >
                              <Flex
                                bgColor={"#2A2E6E"}
                                align={"center"}
                                justify={"center"}
                                p={"1% 1% 0.5% 1%"}
                                w={"100%"}
                                borderRadius={"10px 10px 0px 0px"}
                              >
                                <Text
                                  textColor={"#FFFF"}
                                  letterSpacing={"0.83px"}
                                  fontFamily={"ObjectiveBold"}
                                  fontSize={"1.4rem"}
                                >
                                  DINERO INICIAL DEL DÍA
                                </Text>
                              </Flex>
                              <Flex
                                borderRadius={"0px 0px 10px 10px"}
                                p={"2%"}
                                gap={"10px"}
                              >
                                <Flex w={"100%"} justify={"center"}>
                                  <Box
                                    w={"100%"}
                                    p={"1.5% 2% 1% 2%"}
                                    borderRadius={"10px 0px 0px 10px"}
                                    bg={"#2A2E6E"}
                                    textColor={"#FFFF"}
                                  >
                                    <Text
                                      fontSize={"1.4rem"}
                                      fontFamily={"ObjectiveExtraBold"}
                                    >
                                      FONDOS TOTALES
                                    </Text>
                                  </Box>
                                  {/* ---------------------------------- */}
                                  <Box
                                    w={"100%"}
                                    p={"1.5% 2% 1% 2%"}
                                    borderRadius={"0px 10px 10px 0px"}
                                    border={"1px solid #2A2E6E"}
                                  >
                                    <Text
                                      fontSize={"1.4rem"}
                                      textAlign={"right"}
                                      fontFamily={"ObjectiveExtraBold"}
                                    >
                                      {totalQuantity.toFixed(2)}
                                    </Text>
                                  </Box>
                                </Flex>
                                {/* -------------------------------------------------- */}
                                {/* <Flex
                                  w={"30%"}
                                  // justify={'right'}
                                  align={"center"}
                                  gap={"1px"}
                                >
                                  <Box
                                    w={"100%"}
                                    h={"100%"}
                                    p={"3.5% 2% 3% 6%"}
                                    onClick={() => handleAddClick(1)}
                                    cursor={"pointer"}
                                    borderRadius={"10px"}
                                    bg={"#2A2E6E"}
                                    textColor={"#FFFF"}
                                  >
                                    <Text
                                      fontSize={"1.4rem"}
                                      letterSpacing={"0.63px"}
                                      textAlign={"center"}
                                      fontFamily={"ObjectiveRegular"}
                                    >
                                      {isInputEnabled
                                        ? "GUARDAR TABLAS"
                                        : "EDITAR TABLAS"}
                                    </Text>
                                  </Box>
                                </Flex> */}
                              </Flex>

                              <Flex p={"0% 2%"} w={"100%"} gap={"26px"}>
                                <div className="w-full">
                                  <h1 className="font-[ObjectiveExtraBold] text-center text-3xl text-white py-4 px-6 rounded-t-2xl bg-[#2a2e6e]">
                                    Cta Bancos-BillVirtual
                                  </h1>
                                  <Accordion
                                    className="w-[100%] border-t-transparent -mt-2"
                                    allowMultiple
                                  >
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Cta Madre USD - ${" "}
                                            {fondosApertura.cuenta_madre_dolar}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>

                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstApertura.cuentas_madre_dolar?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Cta Madre LOC (
                                            {fondosApertura.cuenta_madre_local})
                                            - ${" "}
                                            {
                                              fondosApertura.cuenta_madre_local_dolar
                                            }
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstApertura.cuentas_madre_local?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Cta Madre EUR (
                                            {fondosApertura.cuenta_madre_euro})
                                            - ${" "}
                                            {
                                              fondosApertura.cuenta_madre_euro_dolar
                                            }
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstApertura.cuentas_madre_euro?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem className="-mt-1">
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Otras Ctas USD - ${" "}
                                            {fondosApertura.cuenta_otra}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstApertura.cuentas_dolar?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>

                                    <AccordionItem className="-mt-1">
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Otras Ctas LOC (
                                            {fondosApertura.cuenta_otra_local})
                                            - ${" "}
                                            {
                                              fondosApertura.cuenta_otra_local_dolar
                                            }
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstApertura.cuentas_local?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem className="-mt-1">
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-y-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Otras Ctas EUR (
                                            {fondosApertura.cuenta_otra_euro}) -
                                            ${" "}
                                            {
                                              fondosApertura.cuenta_otra_euro_dolar
                                            }
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstApertura.cuentas_euro?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem className="-mt-1">
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Billetera USD - ${" "}
                                            {fondosApertura.billetera_dolar}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstApertura.billetera_dolar?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem className="-mt-1">
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Billetera LOC (
                                            {
                                              fondosApertura.billetera_local_dolar
                                            }
                                            ) - ${" "}
                                            {fondosApertura.billetera_local}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstApertura.billetera_local?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem className="-mt-1">
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] rounded-b-2xl border-y-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Billetera EUR (
                                            {fondosApertura.billetera_euro}) - ${" "}
                                            {fondosApertura.billetera_euro}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstApertura.billetera_euro?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                  </Accordion>
                                </div>
                                <div className="w-full flex flex-col">
                                  <h1 className="font-[ObjectiveExtraBold] text-center text-3xl text-white py-4 px-6 rounded-t-2xl bg-[#2a2e6e]">
                                    Retenido
                                  </h1>
                                  <Accordion
                                    className="w-[100%] border-t-transparent -mt-2"
                                    allowMultiple
                                  >
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Casas - ${" "}
                                            {casaRetenidoApertura.total_casa}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>

                                      <div className="max-h-[250px] overflow-auto">
                                        {casaRetenidoApertura.list_homes?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Cta de Bancos - ${" "}
                                            {retenidoApertura.bank_total}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[250px] overflow-auto">
                                        {retenidoApertura.bank_accounts?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] rounded-b-2xl border-y-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Bill Virtual - ${" "}
                                            {retenidoApertura.wallet_total}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[250px] overflow-auto">
                                        {retenidoApertura.wallet_accounts?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                  </Accordion>
                                  <h1 className="font-[ObjectiveExtraBold] text-center text-3xl text-white py-4 px-6 rounded-t-2xl bg-[#2a2e6e] mt-5">
                                    Otros-Gastos Acum MEs
                                  </h1>
                                  <Accordion
                                    className="w-[100%] border-t-transparent -mt-2"
                                    allowMultiple
                                  >
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] rounded-b-2xl border-y-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Gastos Mes - ${" "}
                                            {expenseApertura.total}{" "}
                                            {rol.includes("ADMINISTRADOR") && (
                                              <span
                                                className="text-[#2a2e6e] font-bold text-xl ml-5 underline hover:no-underline"
                                                onClick={() =>
                                                  history.push("/salas/MBanco")
                                                }
                                              >
                                                ver mas detalles
                                              </span>
                                            )}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>

                                      <div className="max-h-[200px] overflow-auto">
                                        {expenseApertura.expenses?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                  </Accordion>
                                </div>
                              </Flex>
                              <div className="flex justify-center">
                                <Button
                                  w={"50%"}
                                  onClick={() => saveFondoForDay("INICIO")}
                                  fontFamily={"ObjectiveBold"}
                                  fontSize={"1.5rem"}
                                  p={"2%"}
                                  marginTop={"20px"}
                                  textColor={"#2A2E6E"}
                                  bgColor={"#FFB400"}
                                >
                                  {" "}
                                  Guardar Fondos
                                </Button>
                              </div>
                            </Flex>
                            {/* --------------------------------------- */}
                            <Flex
                              w={"100%"}
                              direction={"column"}
                              borderRadius={"10px"}
                              borderColor={"#2A2E6E"}
                              bgColor={"#FFFF"}
                              borderBottom={"1px"}
                              borderRight={"1px"}
                              borderLeft={"1px"}
                            >
                              <Flex
                                bgColor={"#2A2E6E"}
                                align={"center"}
                                justify={"center"}
                                p={"1% 1% 0.5% 1%"}
                                w={"100%"}
                                borderRadius={"10px 10px 0px 0px"}
                              >
                                <Text
                                  textColor={"#FFFF"}
                                  letterSpacing={"0.83px"}
                                  fontFamily={"ObjectiveBold"}
                                  fontSize={"1.4rem"}
                                >
                                  NOTAS
                                </Text>
                              </Flex>
                              <Flex
                                direction={"column"}
                                gap={5}
                                borderRadius={"0px 0px 10px 10px"}
                                p={"2% 1%"}
                              >
                                <Textarea
                                  name="inicio"
                                  onChange={handleChangNote}
                                  value={note.inicio}
                                  textColor={"#2A2E6E"}
                                  textAlign={"left"}
                                  fontSize={"1.3rem"}
                                  fontFamily={"ObjectiveRegular"}
                                  placeholder="Algo que agregar"
                                ></Textarea>
                                {!note.update ? (
                                  <Button
                                    onClick={saveNoteForDay}
                                    fontFamily={"ObjectiveBold"}
                                    fontSize={"1.5rem"}
                                    p={"2%"}
                                    textColor={"#2A2E6E"}
                                    bgColor={"#FFB400"}
                                  >
                                    {" "}
                                    Guardar
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={updateNoteForDay}
                                    fontFamily={"ObjectiveBold"}
                                    fontSize={"1.5rem"}
                                    p={"2%"}
                                    textColor={"#2A2E6E"}
                                    bgColor={"#FFB400"}
                                  >
                                    {" "}
                                    Modificar
                                  </Button>
                                )}
                              </Flex>
                            </Flex>
                          </Flex>
                          {/* ---------------------------------------------------- */}

                          <Flex
                            w={"45%"}
                            h={"100%"}
                            gap={"25px"}
                            direction={"column"}
                            borderRadius={"0px 0px 10px 10px"}
                          >
                            <TableContainer
                              w={"100%"}
                              borderRadius="10px"
                              border="1px solid"
                              bgColor={"#FFFF"}
                              borderColor={"#2A2E6E"}
                            >
                              <Table variant="simple" borderCollapse="collapse">
                                <Thead textColor={"#FFFF"}>
                                  <Tr>
                                    <Th
                                      p={"2.5% 1% 2% 1%"}
                                      fontSize={"1.4rem"}
                                      letterSpacing={"0.83px"}
                                      textColor={"#2A2E6E"}
                                      bgColor={"#2A2E6E"}
                                      textAlign={"center"}
                                      // borderRight='1px solid #2A2E6E'
                                      borderBottom="1px solid"
                                    >
                                      <Flex
                                        direction={"column"}
                                        gap={"10px"}
                                        textColor={"#FFFF"}
                                      >
                                        <Text
                                          fontFamily={"ObjectiveRegular"}
                                          opacity={1}
                                          fontSize={"1.1rem"}
                                          letterSpacing={"0.63px"}
                                        >
                                          DINERO QUE ESTA EN EL CUADRE DIARIO -
                                          DINERO EN CASAS
                                        </Text>
                                        <Text
                                          letterSpacing={"3.33px"}
                                          fontSize={"1.4rem"}
                                          fontFamily={"ObjectiveBold"}
                                        >
                                          INICIO DEL DÍA
                                        </Text>
                                      </Flex>
                                    </Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {listInitialDay?.map(
                                    ({ nombre, total_inicio }, index) => (
                                      <Tr key={index}>
                                        <Flex justify={"space-around"}>
                                          <Td
                                            pt={"2%"}
                                            pb={"1%"}
                                            w={"100%"}
                                            textColor={"#2A2E6E"}
                                            textAlign={"left"}
                                            border={"0px solid"}
                                            borderBottom="1px solid"
                                            borderRight="1px solid #2A2E6E"
                                            fontSize={"1.3rem"}
                                            fontFamily={"ObjectiveRegular"}
                                          >
                                            {nombre}
                                          </Td>
                                          <Td
                                            pt={"2%"}
                                            pb={"1%"}
                                            textColor={"#2A2E6E"}
                                            textAlign={"right"}
                                            border={"0px solid"}
                                            fontSize={"1.3rem"}
                                            w={"40%"}
                                            borderBottom="1px solid"
                                            fontFamily={"ObjectiveRegular"}
                                          >
                                            {total_inicio.toFixed(2)}
                                          </Td>
                                        </Flex>
                                      </Tr>
                                    )
                                  )}

                                  {/* ------------------------------------------------------------------- */}
                                  <Tr>
                                    <Flex justify={"space-around"}>
                                      <Td
                                        w={"100%"}
                                        textColor={"#2A2E6E"}
                                        fontSize={"1.25rem"}
                                        textAlign={"right"}
                                        borderRight="1px solid #2A2E6E"
                                        fontFamily={"ObjectiveExtraBold"}
                                      >
                                        TOTAL
                                      </Td>
                                      <Td
                                        w={"40%"}
                                        textColor={"#2A2E6E"}
                                        fontSize={"1.25rem"}
                                        textAlign={"right"}
                                        fontFamily={"ObjectiveExtraBold"}
                                      >
                                        {amountInitialDay.toFixed(2)}
                                      </Td>
                                    </Flex>
                                  </Tr>
                                </Tbody>
                              </Table>
                            </TableContainer>
                            {/* ------------------------------------------ */}
                            <Flex gap={"15px"}>
                              <TableContainer
                                w={"100%"}
                                borderRadius="10px"
                                border="1px solid"
                                bgColor={"#FFFF"}
                                borderColor={"#2A2E6E"}
                              >
                                <Table
                                  variant="simple"
                                  borderCollapse="collapse"
                                >
                                  <Thead textColor={"#FFFF"}>
                                    <Tr>
                                      <Th
                                        p={"3% 1% 2% 1%"}
                                        fontSize={"1.4rem"}
                                        letterSpacing={"3.33px"}
                                        textColor={"#FFFF"}
                                        bgColor={"#2A2E6E"}
                                        textAlign={"center"}
                                        // borderRight='1px solid #2A2E6E'
                                        fontFamily={"ObjectiveBold"}
                                        borderBottom="1px solid"
                                      >
                                        RECARGAS
                                      </Th>
                                      <Th
                                        p={"3% 1% 2% 1%"}
                                        fontSize={"1.4rem"}
                                        letterSpacing={"3.33px"}
                                        textColor={"#FFFF"}
                                        bgColor={"#2A2E6E"}
                                        textAlign={"center"}
                                        // borderRight='1px solid #2A2E6E'
                                        fontFamily={"ObjectiveBold"}
                                        borderBottom="1px solid"
                                      >
                                        MONTO
                                      </Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    {listRecharges?.map(
                                      ({ modulo, monto }, index) => (
                                        <Tr key={index}>
                                          <Td
                                            pt={"2%"}
                                            pb={"1%"}
                                            textColor={"#2A2E6E"}
                                            textAlign={"left"}
                                            border={"0px solid"}
                                            borderBottom="1px solid"
                                            borderRight="1px solid #2A2E6E"
                                            fontSize={"1.3rem"}
                                            fontFamily={"ObjectiveRegular"}
                                          >
                                            {modulo}
                                          </Td>
                                          <Td
                                            pt={"2%"}
                                            pb={"1%"}
                                            textColor={"#2A2E6E"}
                                            textAlign={"right"}
                                            border={"0px solid"}
                                            fontSize={"1.3rem"}
                                            borderBottom="1px solid"
                                            fontFamily={"ObjectiveRegular"}
                                          >
                                            {monto.toFixed(2)}
                                          </Td>
                                        </Tr>
                                      )
                                    )}

                                    {/* ------------------------------------------------------------------- */}
                                    <Tr>
                                      <Td
                                        textColor={"#2A2E6E"}
                                        fontSize={"1.25rem"}
                                        textAlign={"right"}
                                        borderRight="1px solid #2A2E6E"
                                        fontFamily={"ObjectiveExtraBold"}
                                      >
                                        TOTAL
                                      </Td>
                                      <Td
                                        textColor={"#2A2E6E"}
                                        fontSize={"1.25rem"}
                                        textAlign={"right"}
                                        fontFamily={"ObjectiveExtraBold"}
                                      >
                                        {amountTotalRecharges.toFixed(2)}
                                      </Td>
                                    </Tr>
                                  </Tbody>
                                </Table>
                              </TableContainer>
                              {/* ------------------------- */}
                              <TableContainer
                                w={"100%"}
                                borderRadius="10px"
                                border="1px solid"
                                bgColor={"#FFFF"}
                                borderColor={"#2A2E6E"}
                              >
                                <Table
                                  variant="simple"
                                  borderCollapse="collapse"
                                >
                                  <Thead textColor={"#FFFF"}>
                                    <Tr>
                                      <Th
                                        p={"3% 1% 2% 1%"}
                                        fontSize={"1.4rem"}
                                        letterSpacing={"3.33px"}
                                        textColor={"#FFFF"}
                                        bgColor={"#2A2E6E"}
                                        textAlign={"center"}
                                        // borderRight='1px solid #2A2E6E'
                                        fontFamily={"ObjectiveBold"}
                                        borderBottom="1px solid"
                                      >
                                        RETIROS
                                      </Th>
                                      <Th
                                        p={"3% 1% 2% 1%"}
                                        fontSize={"1.4rem"}
                                        letterSpacing={"3.33px"}
                                        textColor={"#FFFF"}
                                        bgColor={"#2A2E6E"}
                                        textAlign={"center"}
                                        // borderRight='1px solid #2A2E6E'
                                        fontFamily={"ObjectiveBold"}
                                        borderBottom="1px solid"
                                      >
                                        MONTO
                                      </Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    {listRetirement?.map(
                                      ({ modulo, monto }, index) => (
                                        <Tr key={index}>
                                          <Td
                                            pt={"2%"}
                                            pb={"1%"}
                                            textColor={"#2A2E6E"}
                                            textAlign={"left"}
                                            border={"0px solid"}
                                            borderBottom="1px solid"
                                            borderRight="1px solid #2A2E6E"
                                            fontSize={"1.3rem"}
                                            fontFamily={"ObjectiveRegular"}
                                          >
                                            {modulo}
                                          </Td>
                                          <Td
                                            pt={"2%"}
                                            pb={"1%"}
                                            textColor={"#2A2E6E"}
                                            textAlign={"right"}
                                            border={"0px solid"}
                                            fontSize={"1.3rem"}
                                            borderBottom="1px solid"
                                            fontFamily={"ObjectiveRegular"}
                                          >
                                            {monto}
                                          </Td>
                                        </Tr>
                                      )
                                    )}

                                    {/* ------------------------------------------------------------------- */}
                                    <Tr>
                                      <Td
                                        textColor={"#2A2E6E"}
                                        fontSize={"1.25rem"}
                                        textAlign={"right"}
                                        borderRight="1px solid #2A2E6E"
                                        fontFamily={"ObjectiveExtraBold"}
                                      >
                                        TOTAL
                                      </Td>
                                      <Td
                                        textColor={"#2A2E6E"}
                                        fontSize={"1.25rem"}
                                        textAlign={"right"}
                                        fontFamily={"ObjectiveExtraBold"}
                                      >
                                        {amountTotalRetirements.toFixed(2)}
                                      </Td>
                                    </Tr>
                                  </Tbody>
                                </Table>
                              </TableContainer>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    </TabPanel>
                    {/* --------------------------------------------------- */}
                    <TabPanel w={"100%"}>
                      <Flex direction={"column"} gap={4}>
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          gap="20px"
                          w={"100%"}
                          h="100%"
                        >
                          <TableContainer
                            w={"100%"}
                            borderRadius="10px"
                            border="1px solid"
                            bgColor={"#FFFF"}
                            borderColor={"#2A2E6E"}
                          >
                            <Table variant="simple" borderCollapse="collapse">
                              <Thead textColor={"#FFFF"}>
                                <Tr>
                                  <Th
                                    fontSize={"1.4rem"}
                                    letterSpacing={"0.83px"}
                                    textColor={"#FFFF"}
                                    bgColor={"#2A2E6E"}
                                    textAlign={"center"}
                                    borderRight="1px solid #2A2E6E"
                                    borderBottom="1px solid"
                                  >
                                    DINERO TERMINO DEL DÍA
                                  </Th>
                                  <Th
                                    fontSize={"1.4rem"}
                                    letterSpacing={"0.83px"}
                                    textColor={"#FFFF"}
                                    bgColor={"#2A2E6E"}
                                    textAlign={"center"}
                                    borderRight="1px solid #2A2E6E"
                                    borderBottom="1px solid"
                                  >
                                    GANANCIA DEL D&Iacute;A SIN COMISIONES
                                  </Th>
                                  <Th
                                    fontSize={"1.4rem"}
                                    letterSpacing={"0.83px"}
                                    textColor={"#FFFF"}
                                    bgColor={"#2A2E6E"}
                                    textAlign={"center"}
                                    borderBottom="1px solid"
                                  >
                                    GANANCIA NETA DEL D&Iacute;A
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Td
                                    textColor={"#2A2E6E"}
                                    fontSize={"1.25rem"}
                                    textAlign={"center"}
                                    fontFamily={"ObjectiveRegular"}
                                    borderRight="1px solid #2A2E6E"
                                  >
                                    {(
                                      parseFloat(totalQuantity2) +
                                      parseFloat(amountEndDay)
                                    ).toFixed(2)}
                                  </Td>
                                  <Td
                                    textColor={"#2A2E6E"}
                                    fontSize={"1.25rem"}
                                    textAlign={"center"}
                                    fontFamily={"ObjectiveRegular"}
                                    borderRight="1px solid #2A2E6E"
                                  >
                                    {gananciaNetaSinComision().toFixed(2)}
                                  </Td>
                                  <Td
                                    textColor={"#2A2E6E"}
                                    fontSize={"1.25rem"}
                                    textAlign={"center"}
                                    fontFamily={"ObjectiveRegular"}
                                  >
                                    {gananciaNetaSinComision().toFixed(2)}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </TableContainer>
                          {/* ------------------------- */}

                          <TableContainer
                            w={"20%"}
                            borderRadius="10px"
                            border="1px solid"
                            bgColor={"#FFFF"}
                            borderColor={"#2A2E6E"}
                          >
                            <Table variant="simple" borderCollapse="collapse">
                              <Thead textColor={"#FFFF"}>
                                <Tr>
                                  <Th
                                    fontSize={"1.4rem"}
                                    letterSpacing={"0.83px"}
                                    textColor={"#2A2E6E"}
                                    bgColor={"#FFB400"}
                                    textAlign={"center"}
                                    borderRight="1px solid #2A2E6E"
                                    borderBottom="1px solid"
                                  >
                                    CAPITAL FINAL
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Td
                                    textColor={"#2A2E6E"}
                                    fontSize={"1.25rem"}
                                    textAlign={"center"}
                                    fontFamily={"ObjectiveRegular"}
                                  >
                                    {(
                                      parseFloat(capital.capital) +
                                      gananciaNetaSinComision()
                                    ).toFixed(2)}
                                  </Td>
                                </Tr>
                                {/* Add more rows as needed */}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </Flex>
                        {/* --------------------------------------- */}
                        <Flex gap={"15px"} w={"100%"}>
                          <Flex w={"65%"} direction={"column"} gap={"15px"}>
                            <Flex
                              w={"100%"}
                              direction={"column"}
                              borderRadius={"10px"}
                              borderColor={"#2A2E6E"}
                              bgColor={"#FFFF"}
                              borderBottom={"1px"}
                              borderRight={"1px"}
                              borderLeft={"1px"}
                              pb={"2%"}
                            >
                              <Flex
                                bgColor={"#2A2E6E"}
                                align={"center"}
                                justify={"center"}
                                p={"1% 1% 0.5% 1%"}
                                w={"100%"}
                                borderRadius={"10px 10px 0px 0px"}
                              >
                                <Text
                                  textColor={"#FFFF"}
                                  letterSpacing={"0.83px"}
                                  fontFamily={"ObjectiveBold"}
                                  fontSize={"1.4rem"}
                                >
                                  DINERO FINAL DEL DÍA
                                </Text>
                              </Flex>
                              <Flex
                                borderRadius={"0px 0px 10px 10px"}
                                p={"2%"}
                                gap={"10px"}
                              >
                                <Flex w={"100%"} justify={"center"}>
                                  <Box
                                    w={"100%"}
                                    p={"1.5% 2% 1% 2%"}
                                    borderRadius={"10px 0px 0px 10px"}
                                    bg={"#2A2E6E"}
                                    textColor={"#FFFF"}
                                  >
                                    <Text
                                      fontSize={"1.4rem"}
                                      fontFamily={"ObjectiveExtraBold"}
                                    >
                                      FONDOS TOTALES
                                    </Text>
                                  </Box>
                                  {/* ---------------------------------- */}
                                  <Box
                                    w={"100%"}
                                    p={"1.5% 2% 1% 2%"}
                                    borderRadius={"0px 10px 10px 0px"}
                                    border={"1px solid #2A2E6E"}
                                  >
                                    <Text
                                      fontSize={"1.4rem"}
                                      textAlign={"right"}
                                      fontFamily={"ObjectiveExtraBold"}
                                    >
                                      {totalQuantity2.toFixed(2)}
                                    </Text>
                                  </Box>
                                </Flex>
                                {/* -------------------------------------------------- */}
                                {/* <Flex
                                  w={"30%"}
                                  // justify={'right'}
                                  align={"center"}
                                  gap={"1px"}
                                >
                                  <Box
                                    w={"100%"}
                                    h={"100%"}
                                    p={"3.5% 2% 3% 6%"}
                                    onClick={() => handleAddClick(1)}
                                    cursor={"pointer"}
                                    borderRadius={"10px"}
                                    bg={"#2A2E6E"}
                                    textColor={"#FFFF"}
                                  >
                                    <Text
                                      fontSize={"1.4rem"}
                                      letterSpacing={"0.63px"}
                                      textAlign={"center"}
                                      fontFamily={"ObjectiveRegular"}
                                    >
                                      {isInputEnabled
                                        ? "GUARDAR TABLAS"
                                        : "EDITAR TABLAS"}
                                    </Text>
                                  </Box>
                                </Flex> */}
                              </Flex>

                              <Flex p={"0% 2%"} w={"100%"} gap={"26px"}>
                                <div className="w-full">
                                  <h1 className="font-[ObjectiveExtraBold] text-center text-3xl text-white py-4 px-6 rounded-t-2xl bg-[#2a2e6e]">
                                    Cta Bancos-BillVirtual
                                  </h1>
                                  <Accordion
                                    className="w-[100%] border-t-transparent -mt-2"
                                    allowMultiple
                                  >
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Cta Madre USD - ${" "}
                                            {fondosFinal.cuenta_madre_dolar}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>

                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstFinal.cuentas_madre_dolar?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Cta Madre LOC (
                                            {fondosFinal.cuenta_madre_local})
                                            - ${" "}
                                            {
                                              fondosFinal.cuenta_madre_local_dolar
                                            }
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstFinal.cuentas_madre_local?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Cta Madre EUR (
                                            {fondosFinal.cuenta_madre_euro})
                                            - ${" "}
                                            {
                                              fondosFinal.cuenta_madre_euro_dolar
                                            }
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstFinal.cuentas_madre_euro?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem className="-mt-1">
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Otras Ctas USD - ${" "}
                                            {fondosFinal.cuenta_otra}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstFinal.cuentas_dolar?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>

                                    <AccordionItem className="-mt-1">
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Otras Ctas LOC (
                                            {fondosFinal.cuenta_otra_local})
                                            - ${" "}
                                            {
                                              fondosFinal.cuenta_otra_local_dolar
                                            }
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstFinal.cuentas_local?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem className="-mt-1">
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-y-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Otras Ctas EUR (
                                            {fondosFinal.cuenta_otra_euro}) -
                                            ${" "}
                                            {
                                              fondosFinal.cuenta_otra_euro_dolar
                                            }
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstFinal.cuentas_euro?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem className="-mt-1">
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Billetera USD - ${" "}
                                            {fondosFinal.billetera_dolar}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstFinal.billetera_dolar?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem className="-mt-1">
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Billetera LOC (
                                            {
                                              fondosFinal.billetera_local_dolar
                                            }
                                            ) - ${" "}
                                            {fondosFinal.billetera_local}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstFinal.billetera_local?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem className="-mt-1">
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] rounded-b-2xl border-y-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Billetera EUR (
                                            {fondosFinal.billetera_euro}) - ${" "}
                                            {fondosFinal.billetera_euro}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[350px] overflow-auto">
                                        {accounstFinal.billetera_euro?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                  </Accordion>
                                </div>
                                <div className="w-full flex flex-col">
                                  <h1 className="font-[ObjectiveExtraBold] text-center text-3xl text-white py-4 px-6 rounded-t-2xl bg-[#2a2e6e]">
                                    Retenido
                                  </h1>
                                  <Accordion
                                    className="w-[100%] border-t-transparent -mt-2"
                                    allowMultiple
                                  >
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Casas - ${" "}
                                            {retenidoFinal.total_casa}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>

                                      <div className="max-h-[250px] overflow-auto">
                                        {casaRetenidoFinal.list_homes?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Cta de Bancos - ${" "}
                                            {retenidoFinal.bank_total}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[250px] overflow-auto">
                                        {retenidoFinal.bank_accounts?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] rounded-b-2xl border-y-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Bill Virtual - ${" "}
                                            {retenidoFinal.wallet_total}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>
                                      <div className="max-h-[250px] overflow-auto">
                                        {retenidoFinal.wallet_accounts?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                  </Accordion>
                                  <h1 className="font-[ObjectiveExtraBold] text-center text-3xl text-white py-4 px-6 rounded-t-2xl bg-[#2a2e6e] mt-5">
                                    Otros-Gastos Acum MEs
                                  </h1>
                                  <Accordion
                                    className="w-[100%] border-t-transparent -mt-2"
                                    allowMultiple
                                  >
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e]"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Gastos Mes - ${" "}
                                            {expenseApertura.total}{" "}
                                            {rol.includes("ADMINISTRADOR") && (
                                              <span
                                                className="text-[#2a2e6e] font-bold text-xl ml-5 underline hover:no-underline"
                                                onClick={() =>
                                                  history.push("/salas/MBanco")
                                                }
                                              >
                                                ver mas detalles
                                              </span>
                                            )}
                                          </Box>
                                          <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          />
                                        </AccordionButton>
                                      </h2>

                                      <div className="max-h-[200px] overflow-auto">
                                        {expenseApertura.expenses?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div>
                                    </AccordionItem>
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] border-t-[#2a2e6e] cursor-default"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Perdidas TC/TR -{" "}
                                            <span className="font-[ObjectiveBold] text-[#ff0000]">
                                              $ {cambiosApertura.total_perdida}
                                            </span>{" "}
                                            {rol.includes("ADMINISTRADOR") && (
                                              <span
                                                className="text-[#2a2e6e] font-bold text-xl ml-5 underline hover:no-underline cursor-pointer"
                                                onClick={() =>
                                                  history.push("/salas/MBanco")
                                                }
                                              >
                                                ver mas detalles
                                              </span>
                                            )}
                                          </Box>
                                          {/* <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          /> */}
                                        </AccordionButton>
                                      </h2>

                                      {/* <div className="max-h-[200px] overflow-auto">
                                        {expenseApertura.expenses?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div> */}
                                    </AccordionItem>
                                    <AccordionItem>
                                      <h2>
                                        <AccordionButton
                                          className=" bg-white border border-y-transparent border-x-[#2a2e6e] rounded-b-2xl border-y-[#2a2e6e] cursor-default"
                                          _hover={{
                                            bgColor: "none",
                                          }}
                                        >
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            className="text-xl py-4"
                                            fontFamily={"ObjectiveBold"}
                                          >
                                            Ganancias TC/TR -{" "}
                                            <span className="font-[ObjectiveBold] text-[#00ee64]">
                                              $ {cambiosApertura.total_ganada}
                                            </span>{" "}
                                            {rol.includes("ADMINISTRADOR") && (
                                              <span
                                                className="text-[#2a2e6e] font-bold text-xl ml-5 underline hover:no-underline cursor-pointer"
                                                onClick={() =>
                                                  history.push("/salas/MBanco")
                                                }
                                              >
                                                ver mas detalles
                                              </span>
                                            )}
                                          </Box>
                                          {/* <AccordionIcon
                                            color={"#156082"}
                                            w={"2em"}
                                            h={"2em"}
                                          /> */}
                                        </AccordionButton>
                                      </h2>

                                      {/* <div className="max-h-[200px] overflow-auto">
                                        {expenseApertura.expenses?.map(
                                          (item) => (
                                            <AccordionPanel
                                              pb={4}
                                              className="bg-[#f3f2f2] text-lg text-[#292f36] font-semibold"
                                            >
                                              {item.nombre}
                                            </AccordionPanel>
                                          )
                                        )}
                                      </div> */}
                                    </AccordionItem>
                                  </Accordion>
                                </div>
                              </Flex>
                              <div className="flex justify-center">
                                <Button
                                  w={"50%"}
                                  onClick={() => saveFondoForDay("FINAL")}
                                  fontFamily={"ObjectiveBold"}
                                  fontSize={"1.5rem"}
                                  p={"2%"}
                                  marginTop={"20px"}
                                  textColor={"#2A2E6E"}
                                  bgColor={"#FFB400"}
                                >
                                  {" "}
                                  Guardar Fondos Finales
                                </Button>
                              </div>
                            </Flex>
                          </Flex>
                          {/* ---------------------------------------------------- */}

                          <Flex
                            w={"45%"}
                            h={"100%"}
                            gap={"25px"}
                            direction={"column"}
                            borderRadius={"0px 0px 10px 10px"}
                          >
                            <TableContainer
                              w={"100%"}
                              borderRadius="10px"
                              border="1px solid"
                              bgColor={"#FFFF"}
                              borderColor={"#2A2E6E"}
                            >
                              <Table variant="simple" borderCollapse="collapse">
                                <Thead textColor={"#FFFF"}>
                                  <Tr>
                                    <Th
                                      p={"2.5% 1% 2% 1%"}
                                      fontSize={"1.4rem"}
                                      letterSpacing={"0.83px"}
                                      textColor={"#2A2E6E"}
                                      bgColor={"#2A2E6E"}
                                      textAlign={"center"}
                                      // borderRight='1px solid #2A2E6E'
                                      borderBottom="1px solid"
                                    >
                                      <Flex
                                        direction={"column"}
                                        gap={"10px"}
                                        textColor={"#FFFF"}
                                      >
                                        <Text
                                          fontFamily={"ObjectiveRegular"}
                                          opacity={1}
                                          fontSize={"1.1rem"}
                                          letterSpacing={"0.63px"}
                                        >
                                          DINERO QUE ESTA EN EL CUADRE DIARIO -
                                          DINERO EN CASAS
                                        </Text>
                                        <Text
                                          letterSpacing={"3.33px"}
                                          fontSize={"1.4rem"}
                                          fontFamily={"ObjectiveBold"}
                                        >
                                          FINAL DEL DÍA
                                        </Text>
                                      </Flex>
                                    </Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {listEndDay?.map(
                                    ({ nombre, total_final }) => (
                                      <Tr>
                                        <Flex justify={"space-around"}>
                                          <Td
                                            pt={"2%"}
                                            pb={"1%"}
                                            w={"100%"}
                                            textColor={"#2A2E6E"}
                                            textAlign={"left"}
                                            border={"0px solid"}
                                            borderBottom="1px solid"
                                            borderRight="1px solid #2A2E6E"
                                            fontSize={"1.3rem"}
                                            fontFamily={"ObjectiveRegular"}
                                          >
                                            {nombre}
                                          </Td>
                                          <Td
                                            pt={"2%"}
                                            pb={"1%"}
                                            textColor={"#2A2E6E"}
                                            textAlign={"right"}
                                            border={"0px solid"}
                                            fontSize={"1.3rem"}
                                            w={"40%"}
                                            borderBottom="1px solid"
                                            fontFamily={"ObjectiveRegular"}
                                          >
                                            {total_final.toFixed(2)}
                                          </Td>
                                        </Flex>
                                      </Tr>
                                    )
                                  )}

                                  {/* ------------------------------------------------------------------- */}
                                  <Tr>
                                    <Flex justify={"space-around"}>
                                      <Td
                                        w={"100%"}
                                        textColor={"#2A2E6E"}
                                        fontSize={"1.25rem"}
                                        textAlign={"right"}
                                        borderRight="1px solid #2A2E6E"
                                        fontFamily={"ObjectiveExtraBold"}
                                      >
                                        TOTAL
                                      </Td>
                                      <Td
                                        w={"40%"}
                                        textColor={"#2A2E6E"}
                                        fontSize={"1.25rem"}
                                        textAlign={"right"}
                                        fontFamily={"ObjectiveExtraBold"}
                                      >
                                        {amountEndDay.toFixed(2)}
                                      </Td>
                                    </Flex>
                                  </Tr>
                                </Tbody>
                              </Table>
                            </TableContainer>
                            {/* ------------------------------------------ */}
                            <Flex
                              w={"100%"}
                              direction={"column"}
                              borderRadius={"10px"}
                              borderColor={"#2A2E6E"}
                              bgColor={"#FFFF"}
                              borderBottom={"1px"}
                              borderRight={"1px"}
                              borderLeft={"1px"}
                            >
                              <Flex
                                bgColor={"#2A2E6E"}
                                align={"center"}
                                justify={"center"}
                                p={"1% 1% 0.5% 1%"}
                                w={"100%"}
                                borderRadius={"10px 10px 0px 0px"}
                              >
                                <Text
                                  textColor={"#FFFF"}
                                  letterSpacing={"0.83px"}
                                  fontFamily={"ObjectiveBold"}
                                  fontSize={"1.4rem"}
                                >
                                  NOTAS
                                </Text>
                              </Flex>
                              <Flex
                                direction={"column"}
                                gap={5}
                                borderRadius={"0px 0px 10px 10px"}
                                p={"2% 1%"}
                              >
                                <Textarea
                                  name="termino"
                                  onChange={handleChangNote}
                                  value={note.termino}
                                  textColor={"#2A2E6E"}
                                  textAlign={"left"}
                                  fontSize={"1.3rem"}
                                  fontFamily={"ObjectiveRegular"}
                                  placeholder="Algo que agregar"
                                ></Textarea>
                                {!note.update ? (
                                  <Button
                                    onClick={saveNoteForDay}
                                    fontFamily={"ObjectiveBold"}
                                    fontSize={"1.5rem"}
                                    p={"2%"}
                                    textColor={"#2A2E6E"}
                                    bgColor={"#FFB400"}
                                  >
                                    {" "}
                                    Guardar
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={updateNoteForDay}
                                    fontFamily={"ObjectiveBold"}
                                    fontSize={"1.5rem"}
                                    p={"2%"}
                                    textColor={"#2A2E6E"}
                                    bgColor={"#FFB400"}
                                  >
                                    {" "}
                                    Modificar
                                  </Button>
                                )}
                              </Flex>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} w="100%" isCentered>
        <ModalOverlay />
        <ModalContent maxWidth="20%">
          <ModalHeader fontSize={"2rem"} fontFamily={"ObjectiveMedium"}>
            Ingresar Capital Inicial
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel fontSize={"2rem"} fontFamily={"ObjectiveMedium"}>
                Capital Inicial:
              </FormLabel>
              <Input
                name="capital"
                value={capital.capital}
                onChange={handleChangCapital}
                h={"45px"}
                fontSize={"2rem"}
                type="number"
                placeholder="200"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            {capital.id_capital ? (
              <Button
                onClick={updateCapitalInicial}
                fontFamily={"ObjectiveBold"}
                fontSize={"1.4rem"}
                p={"4%"}
                textColor={"#2A2E6E"}
                bgColor={"#FFB400"}
              >
                Modificar
              </Button>
            ) : (
              <Button
                onClick={saveCapitalInicial}
                fontFamily={"ObjectiveBold"}
                fontSize={"1.4rem"}
                p={"4%"}
                textColor={"#2A2E6E"}
                bgColor={"#FFB400"}
              >
                Guardar
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Rdiario;
