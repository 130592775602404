
import React, { Component, useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Select,
  Flex,
  Tooltip,
  // InputGroup,
  // Center,
  // InputLeftAddon,
  // Avatar,
  // HStack,
  // Link,
  IconButton,
  Button,
  // Menu,
  // MenuButton,
  // MenuList,
  // MenuItem,
  // Container,
  // Img,
  // MenuDivider,
  useDisclosure,
  // useColorModeValue,
  Stack,
  // MenuGroup,
  // Popover,
  // Divider,
  // PopoverTrigger,
  // PopoverContent,
  // PopoverArrow,
  // NavItem,
  // Collapse,
  // Text,
  // Badge,
  Icon,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  Input,
  // InputLeftElement,
  Table,
  Thead,
  Tbody,
  // Tfoot,
  Tr,
  Th,
  Td,
  // TableCaption,
  TableContainer,
  FormControl,
  FormLabel
  // FormErrorMessage,
  // Select,
  // FormHelperText,
  // Tabs,
  // TabList,
  // TabPanels,
  // Tab,
  // TabPanel
} from '@chakra-ui/react';

// import { Search2Icon } from '@chakra-ui/icons';
// import { EditIcon } from '@chakra-ui/icons';
// import { FaEdit } from 'react-icons/fa';

import { useHistory } from 'react-router-dom';
import { RiEditFill } from 'react-icons/ri';
import { FiMenu } from 'react-icons/fi';
import SidebarOperators from '../../../components/SidebarOperators';
import { host3 } from '../../../utils/constantes';
import axios from 'axios';

import {
  IoMdArrowDroprightCircle,
  IoMdArrowDropleftCircle
} from 'react-icons/io';
import jwt_decode from 'jwt-decode';

function Salas() {
  let history = useHistory();
  const token = localStorage.getItem('tokenadmin');
  const { id_franquicia, rol } = jwt_decode(token);
  useEffect(() => {
    /*if (token) {
      if (rol != "ADMINISTRADOR" && rol == "JEFE DE SALA") {
        history.push("/salas/Colaboradores");
      }
      if (rol != "ADMINISTRADOR" && rol == "OPERADOR") {
        history.push("/salas/MCasas");
      }
    }*/
  }, []);
  if (!token) {
    history.push('/');
  }

  const resumen = [
    {
      id: '1',
      moneda: 'Dolar',
      total: '1000',
      pendientes: '500'
    },
    {
      id: '2',
      moneda: 'Dolar',
      total: '1000',
      pendientes: '500'
    },
    {
      id: '3',
      moneda: 'Dolar',
      total: '1000',
      pendientes: '500'
    },
    {
      id: '4',
      moneda: 'Dolar',
      total: '1000',
      pendientes: '500'
    },
    {
      id: '5',
      moneda: 'Dolar',
      total: '1000',
      pendientes: '500'
    },
    {
      id: '6',
      moneda: 'Sol',
      total: '1000',
      pendientes: '500'
    },
    {
      id: '7',
      moneda: 'Sol',
      total: '1000',
      pendientes: '500'
    },
    {
      id: '8',
      moneda: 'Sol',
      total: '1000',
      pendientes: '500'
    },
    {
      id: '9',
      moneda: 'Sol',
      total: '1000',
      pendientes: '500'
    },
    {
      id: '10',
      moneda: 'Sol',
      total: '1000',
      pendientes: '500'
    }
  ];

  const [search, setSearch] = useState('');
  const [tiempo, setTiempo] = useState(false);

  //Mensaje
  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      default:
        break;
    }
  };

  //-----------------------Listar------------------------------------------

  const url = `${host3}/api/sala/${token}/listar`;
  const [listSala, setListSala] = useState([]);

  const getSala = async () => {
    try {
      const { data } = await axios.get(url);
      setListSala(data);
    } catch (error) {
      console.log(error);
    }
  };

  const ListFiltered = () => {
    if (search) {
      var filtered = listSala.filter(
        (p) =>
          p.nombre.toString().includes(search) ||
          p.estado.toString().includes(search)
      );
      return filtered.slice(currentPage, currentPage + 13);
    } else {
      return listSala?.slice(currentPage, currentPage + 13);
    }
  };

  // --------------Agregar--------------------

  const [ModelSala, setModelSala] = useState({
    nombre: '',
    estado: '',
    direccion: '',
    ciudad: ''
    //id_franquicia: id_franquicia,
  });

  const changeSala = (e) => {
    setModelSala({
      ...ModelSala,
      [e.target.name]: e.target.value
    });
  };

  const crearSala = async (e) => {
    e.preventDefault();
    try {
      const { status, data } = await axios.post(
        `${host3}/api/sala/${token}/crear`,
        ModelSala
      );

      if (status == 201) {
        mensaje(data.message, 'top-right', 1);
        onClose();
        setModelSala({
          nombre: '',
          estado: '',
          direccion: '',
          ciudad: ''
          //id_franquicia: id_franquicia,
        });
        getSala();
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
  };

  //----------------Editar-------------------------

  const [updateSala, setSala] = useState({
    nombre: '',
    estado: '',
    direccion: '',
    ciudad: ''
    //id_franquicia: id_franquicia,
  });

  const ChangeSala = (e) => {
    setSala({
      ...updateSala,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .put(
        `${host3}/api/sala/${token}/actualizar/${updateSala.id_sala}`,
        updateSala,
        {}
      )
      .then((res) => {
        mensaje(res.data.message, 'top-right', 1);
        console.log(res);
        onClose2();
        setSala({
          nombre: '',
          estado: '',
          direccion: '',
          ciudad: '',
          id_franquicia: id_franquicia
        });
        getSala();
      })
      .catch((err) => {
        mensaje(err.response.data.message, 'top-right', 2);
      });
  };

  //----------------Eliminar----------------------------

  const handleDelete = async (id_sala) => {
    await axios
      .delete(`${host3}/api/sala/${token}/eliminar/${id_sala}`)
      .then((res) => {
        console.log(res);
        console.log(handleDelete);
      });
    getSala();
  };

  const buscar = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    getSala();
    setTimeout(() => {
      setTiempo(true);
    }, 8000);
  }, []);

  //Paginacion
  const [currentPage, setCurrentPage] = useState(0);
  const nextPage = () => {
    const cant = listSala.filter((p) => p.nombre.toString()).length;

    if (cant > currentPage) {
      setCurrentPage(currentPage + 13);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 13);
    }
  };

  const [currentPage1, setCurrentPage1] = useState(0);
  const nextPage1 = () => {
    const cant = resumen.filter((p) => p.id.toString()).length;

    if (cant > currentPage1) {
      setCurrentPage1(currentPage1 + 5);
    }
  };

  const prevPage1 = () => {
    if (currentPage1 > 0) {
      setCurrentPage1(currentPage1 - 5);
    }
  };

  // ---------------------------

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2
  } = useDisclosure();
  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3
  } = useDisclosure();

  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar
  } = useDisclosure();

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: '1.2rem', textAlign: 'center' }}
        pauseOnHover
      />
      <Box
        scrollBehavior={'smooth'}
        bg={'#e5e5e5'}
        height={'auto'}
        maxHeight={'100vh'}
        overflowY={'auto'}
        css={{
          '&::-webkit-scrollbar': {
            width: '12px'
          },
          '&::-webkit-scrollbar-track': {
            background: '#6b6b6b',
            borderRadius: '0px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#9b9b9b',
            borderRadius: '6px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#9b9b9b'
          }
        }}>
        <Box
          as='section'
          display={{
            '2xl': 'unset',
            xl: 'unset',
            lg: 'none',
            md: 'none',
            sm: 'none'
          }}>
          <SidebarOperators />
        </Box>
        <Drawer
          isOpen={isOpenBar}
          onClose={onCloseBar}
          placement='left'>
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w='full' borderRight='none' />
          </DrawerContent>
        </Drawer>


        <Box bg={'#e5e5e5'} h={'100vh'}>

          <Flex
            alignItems={'center'}
            justify={'flex-start'}
            // h={'100%'}
            sx={{
              '@media only screen and (min-width: 1200px) and (max-width: 1366px)':
                {
                  padding: '1.5% 1.5% 1.5% 19%'
                }
            }}
            p={{
              '2xl': '1.5% 3% 1.5% 15%',
              xl: '1.5% 3% 1.5% 18%',
              lg: '1.5% 2% 1.5% 2%',
              md: '1.5% 2% 1.5% 2%'
            }}
            // marginLeft={'15%'}
            // marginRight='2%'
            // marginBottom='1%'
            direction='column'
            gap={'25px'}>
            <Box transition='.3s ease' width={'100%'}>
              <Flex
                gap='2%'
                alignItems={'center'}
                as='header'
                w='full'
                h='14'>
                <IconButton
                  aria-label='Menu'
                  display={{
                    '2xl': 'none',
                    xl: 'none',
                    lg: 'flex',
                    md: 'flex',
                    sm: 'flex'
                  }}
                  background={'#292F36'}
                  color={'#ffffff'}
                  borderRadius={'4px'}
                  fontSize={'1.3rem'}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size='md'
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      color={'#707070'}
                      fontSize={'1.5rem'}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={'bold'}
                      color={'#292F36'}
                      fontSize={'1.5rem'}>
                      SALAS
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>

            <Flex
              width={'100%'}
              justifyContent={'center'}
              // direction={"column"}
              gap={'25px'}>
              {/* <Flex width={"100%"} justifyContent={"space-between"}>
                <Flex width={'30%'}>
                  <Stack w={'100%'}>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents='none'
                        children={
                          <Search2Icon
                            color='#2a2e6e'
                            marginLeft={'20%'}
                            fontSize={'1.25rem'}
                            marginTop={'60%'}
                            _dark={{
                              color: '#f59f2c'
                            }}
                          />
                        }
                      />
                      <Input
                        type='text'
                        placeholder='Buscar'
                        bg={'white'}
                        height={'40px'}
                        _focus={{
                          borderColor: '#2a2e6e',
                          boxShadow: 'none'
                        }}
                        color='#2a2e6e'
                        fontSize={'1.9rem'}
                        border={'1px solid'}
                        borderColor={'#2a2e6e'}
                        borderRadius='6px'
                        _placeholder={{ color: 'rgb(152, 152, 152)' }}
                        _dark={{
                          color: 'white',
                          borderColor: '#686868',
                          bg: '#686868'
                        }}
                      />
                    </InputGroup>
                  </Stack>
                </Flex>
              </Flex> */}
              <Flex
                width={'100%'}
                boxShadow={
                  'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'
                }
                background={'white'}
                borderRadius={'18px'}
                direction={'column'}
                padding={'2% 4% 2% 4%'}>
                <Flex width={'100%'} justifyContent={'right'}>
                  <Stack align='center' width={'auto'}>
                    <Button
                      onClick={onOpen}
                      w={'100%'}
                      height={'40px'}
                      borderRadius='8px'
                      color={'white'}
                      fontSize={'1.6rem'}
                      bg='#2a2e6e'
                      _hover={{
                        color: 'white',
                        bg: '#f59f2c'
                      }}>
                      Añadir Sala
                    </Button>
                  </Stack>
                </Flex>
                <Flex
                  w={'100%'}
                  h='100%'
                  justifyContent='center'
                  paddingTop={'2%'}>
                  <TableContainer w={'100%'}>
                    <Table variant='simple'>
                      <Thead>
                        <Tr>
                          <Th fontSize={'1.6rem'}>SALA</Th>
                          <Th fontSize={'1.6rem'}>DIRECCIÓN</Th>
                          <Th fontSize={'1.6rem'}>TELEFONO</Th>
                          {/* <Th fontSize={"1.6rem"}>ESTADO</Th> */}
                          <Th isNumeric fontSize={'1.6rem'}>
                            ACCIONES
                          </Th>
                        </Tr>
                      </Thead>
                      {ListFiltered().map((p) => {
                        return (
                          <Tbody fontSize={'1.5rem'}>
                            <Tr key={p.id_sala}>
                              <Td>{p.nombre}</Td>
                              <Td>{p.direccion}</Td>
                              <Td>{p.ciudad}</Td>
                              {/* <Td>{p.estado == 1 ? "Activado" : "Inactivo"}</Td> */}
                              <Td
                                fontWeight={'600'}
                                fontFamily='monospace'
                                isNumeric>
                                <Tooltip
                                  hasArrow
                                  borderRadius={'4px'}
                                  fontSize={'1.2rem'}
                                  label='Editar'
                                  bg='#292F36'>
                                  <IconButton
                                    onClick={() => {
                                      setSala(p);
                                      onOpen2();
                                    }}
                                    size={'md'}
                                    borderRadius={'4px'}
                                    fontSize={'1.5rem'}
                                    background={'#f59f2c'}
                                    color={'#ffffff'}
                                    aria-label='Editar'
                                    icon={<RiEditFill />}
                                  />
                                </Tooltip>
                                {/* //condicionar para que aparesca un boton si el estado esta desactivado o activado */}
                                {/* {p.estado == 1 ? (
                                  <Flex justifyContent={"center"} gap={"3%"}>
                                    <Button
                                      onClick={() => {
                                        setSala(p);
                                        onOpen2();
                                      }}
                                      fontSize={"1.5rem"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      Editar
                                    </Button>
                                    <Button
                                      onClick={async () => {
                                        if (
                                          window.confirm(
                                            "¿Estás seguro que quieres desactivar?"
                                          )
                                        ) {
                                          await axios.get(
                                            `${host3}/api/sala/${token}/desactivar/${p.id_sala}`
                                          );
                                          getSala();
                                        }
                                      }}
                                      colorScheme="red"
                                      fontSize={"1.5rem"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      Desactivar
                                    </Button>
                                  </Flex>
                                ) : (
                                  <Flex justifyContent={"center"} gap={"3%"}>
                                    <Button
                                      onClick={async () => {
                                        await axios
                                          .get(
                                            `${host3}/api/sala/${token}/activar/${p.id_sala}`
                                          )
                                          .then((result) => {
                                            if (result.status == 200) {
                                              window.alert(result.data.message);
                                            } else {
                                            }
                                          })
                                          .catch((error) => {
                                            let op = window.confirm(
                                              error.response.data.message
                                            );
                                          });
                                        getSala();
                                      }}
                                      fontSize={"1.5rem"}
                                      fontFamily="monospace"
                                      textTransform={"uppercase"}
                                    >
                                      Activar
                                    </Button>
                                  </Flex>
                                )} */}
                              </Td>
                            </Tr>
                          </Tbody>
                        );
                      })}
                    </Table>
                    <Flex
                      marginTop={'0.5%'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      gap={'0.5%'}>
                      <Icon
                        onClick={() => {
                          prevPage();
                        }}
                        color={'#13235b'}
                        cursor={'pointer'}
                        boxSize='16'
                        _hover={{
                          color: 'gray !important'
                        }}
                        _dark={{
                          color: '#f59f2c'
                        }}
                        as={IoMdArrowDropleftCircle}
                      />
                      <Icon
                        onClick={() => {
                          nextPage();
                        }}
                        color={'#13235b'}
                        cursor={'pointer'}
                        boxSize='16'
                        _hover={{
                          color: 'gray !important'
                        }}
                        _dark={{
                          color: '#f59f2c'
                        }}
                        as={IoMdArrowDroprightCircle}
                      />
                    </Flex>
                  </TableContainer>
                </Flex>
              </Flex>
              
            </Flex>


          </Flex>

        </Box>


      </Box>

      {/* ---------------Agregar cuenta casas--------- */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW={'4xl'} p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Añadir Sala
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex width={'100%'} justifyContent={'space-between'}>
              <Flex
                direction={'column'}
                alignItems='center'
                gap='12px'
                w={'100%'}
                h='100%'>
                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Sala:
                    </FormLabel>
                    <Input
                      onChange={changeSala}
                      value={ModelSala.nombre}
                      name='nombre'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Estado:
                    </FormLabel>
                    <Select
                      placeholder='Estado'
                      _placeholder={{
                        textcolor: '#f8f8f8'
                      }}
                      name='estado'
                      onChange={changeSala}
                      value={ModelSala.estado}
                      fontSize={'1.8rem'}
                      fontWeight='500'>
                      <option value={1}>Activo</option>
                      <option value={0}>No Activo</option>
                    </Select>
                  </FormControl>
                </Flex>
                <Flex w={'100%'} gap={'5%'}>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Dirección:
                    </FormLabel>
                    <Input
                      onChange={changeSala}
                      value={ModelSala.direccion}
                      name='direccion'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                      Ciudad:
                    </FormLabel>
                    <Input
                      onChange={changeSala}
                      value={ModelSala.ciudad}
                      name='ciudad'
                      fontSize={'1.8rem'}
                      h='35'
                      type='text'
                    />
                  </FormControl>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={'100%'}
              justifyContent={'center'}
              marginTop={'2%'}>
              <Button
                onClick={crearSala}
                height={'35px'}
                borderRadius={'8px'}
                textTransform='uppercase'
                color={'white'}
                fontSize={'1.6rem'}
                bg='#2a2e6e'
                _hover={{
                  color: 'white',
                  bg: '#f59f2c'
                }}
                p='2%'>
                Agregar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* --------------------Editar----------------- */}
      <Modal isOpen={isOpen2} onClose={onClose2} isCentered>
        <ModalOverlay />
        <ModalContent maxW={'xl'} p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Editar Sala
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex width={'100%'} justifyContent={'space-between'}>
              <Flex
                direction={'column'}
                alignItems='center'
                gap='12px'
                w={'100%'}
                h='100%'>
                <FormControl>
                  <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                    Sala:
                  </FormLabel>
                  <Input
                    value={updateSala.nombre}
                    onChange={ChangeSala}
                    name='nombre'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                    Dirección:
                  </FormLabel>
                  <Input
                    value={updateSala.direccion}
                    onChange={ChangeSala}
                    name='direccion'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                    Ciudad:
                  </FormLabel>
                  <Input
                    value={updateSala.ciudad}
                    onChange={ChangeSala}
                    name='ciudad'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                  />
                </FormControl>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={'100%'}
              justifyContent={'center'}
              marginTop={'2%'}>
              <Button
                onClick={handleSubmit}
                height={'35px'}
                borderRadius={'8px'}
                textTransform='uppercase'
                color={'white'}
                fontSize={'1.6rem'}
                bg='#2a2e6e'
                _hover={{
                  color: 'white',
                  bg: '#f59f2c'
                }}
                p='2% 4%'>
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Salas;


