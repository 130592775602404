import React, { useState, useEffect } from "react";
import axios from "axios";
import { host3 } from "../../../utils/constantes";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Chakra imports
import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import jwt_decode from "jwt-decode";
// Assets
import { EyeIcon, EyeSlashIcon } from "../../../assets/icons/Icons";

function FormContent({ ...props }) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [enabledButton, setEnabledButton] = useState(false);

  const token = localStorage.getItem("tokenadmin");

  let history = useHistory();

  const [datos, setDatos] = useState({
    pass: "",
    correo: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setEnabledButton(true);
      const { status, data } = await axios.post(
        `${host3}/api/credenciales`,
        datos
      );
      if (status == 201) {
        setEnabledButton(false);
        localStorage.setItem("tokenadmin", data.token);
        mensaje(data.message, "top-center", 1);

        setTimeout(() => {
          const { rol } = jwt_decode(data.token);

          if (rol.includes("TMONEY")) {
            history.push("salas/MSalas");
          }

          if (rol.includes("INVERSIONISTA")) {
            history.push("salas/administracion");
          }

          if (rol.includes("ADMINISTRADOR")) {
            history.push("salas/Mantenimiento");
          }

          if (rol.includes("JEFE DE SALA")) {
            history.push("salas/CuentasCasas");
          }

          if (rol.includes("SPORTRADER")) {
            history.push("salas/Diarios");
          }
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      mensaje(error.response.data.message, "top-center", 2);
    }
  };

  //Actualizar valores del data
  const handleChange = (e) => {
    setDatos({ ...datos, [e.target.name]: e.target.value });
  };

  /*>Toast*/
  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        break;
    }
  };

  const innerBoxStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    boxSize: "full",
    color: "white",
    fontWeight: "bold",
    fontSize: "20px",
  };

  useEffect(() => {
    if (token) {
      const { rol } = jwt_decode(token);
      if (rol.includes("TMONEY") || rol.includes("INVERSIONISTA")) {
        history.push("salas/MSalas");
      }

      if (rol.includes("ADMINISTRADOR")) {
        history.push("salas/Mantenimiento");
      }

      if (rol.includes("JEFE DE SALA")) {
        history.push("salas/CuentasCasas");
      }

      if (rol.includes("SPORTRADER")) {
        history.push("salas/MCasas");
      }
    }
  }, []);

  return (
    <Flex position="relative" mb="40px" h={"100vh"} bg={"#13235b"}>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: "1.5rem", textAlign: "center" }}
        pauseOnHover
      />
      <Flex
        sx={innerBoxStyles}
        h={{ sm: "initial", md: "75vh", lg: "100vh" }}
        w="100%"
        mx="auto"
        justifyContent="center"
        mb="30px"
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column-reverse"}
      >
        <Flex w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction="column"
            w="100%"
            display={"flex"}
            mx="auto"
            alignItems={"center"}
            justifyContent="center"
            maxW="444px"
            borderRadius={15}
            boxShadow={
              "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
            }
            background="white"
            p="48px"
          >
            <Heading color="#13235b" fontSize="2.6rem" mb="5% !important">
              SISTEMA DE SALAS - OP
            </Heading>

            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="1.7rem"
                fontWeight="bold"
                color={"#13235b"}
              >
                Correo
              </FormLabel>
              <Input
                border={"2px solid"}
                borderColor={"#2a2e6e"}
                _placeholder={{ color: "#2a2e6e" }}
                borderRadius="15px"
                padding={"4% 3%"}
                mb="24px"
                fontSize="1.7rem"
                type="text"
                name="correo"
                onChange={(e) => {
                  handleChange(e);
                }}
                color={"#13235b"}
                placeholder="Tu correo"
                size=""
              />
              <FormLabel
                ms="4px"
                fontSize="1.7rem"
                fontWeight="bold"
                color={"#13235b"}
              >
                Contraseña
              </FormLabel>

              <InputGroup size="">
                <Input
                  border={"2px solid"}
                  borderColor={"#2a2e6e"}
                  _placeholder={{ color: "#2a2e6e" }}
                  borderRadius="15px"
                  padding={"4% 3%"}
                  fontSize="1.7rem"
                  type={show ? "text" : "password"}
                  name="pass"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Contraseña"
                  color={"#13235b"}
                  size=""
                />
                <InputRightElement
                  color={"#2a2e6e"}
                  width="4.5rem"
                  onClick={handleClick}
                  top={6}
                >
                  {show ? <EyeSlashIcon /> : <EyeIcon />}
                </InputRightElement>
              </InputGroup>
              <Button
                fontSize="1.6rem"
                type="submit"
                bg="#f68b18"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="20px"
                _hover={{
                  bg: "#243d99",
                  color: "white",
                }}
                _active={{
                  bg: "teal.500",
                }}
                disabled={enabledButton}
                onClick={handleSubmit}
              >
                INICIAR SESIÓN
              </Button>
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default FormContent;
