import React, { Component, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  InputGroup,
  IconButton,
  Button,
  useDisclosure,
  Stack,
  Tooltip,
  Text,
  Icon,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  Input,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";
import { FaCheckCircle } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { useHistory } from "react-router-dom";
import { RiEditFill, RiFileExcel2Fill } from "react-icons/ri";
import { MdDoDisturbOff } from "react-icons/md";
import SidebarOperators from "../../../components/SidebarOperators";
import { host3 } from "../../../utils/constantes";
import { utils, writeFile } from "xlsx";
import moment from "moment";

import axios from "axios";
import {
  IoMdArrowDroprightCircle,
  IoMdArrowDropleftCircle,
} from "react-icons/io";
import jwt_decode from "jwt-decode";
import { data } from "autoprefixer";

function CuentasCasas() {
  let history = useHistory();
  const [activo1, setActivo1] = useState(false);
  const [isverificado, setIsverificado] = useState(false);

  const token = localStorage.getItem("tokenadmin");
  const { sala, rol, id_persona } = jwt_decode(token);
  if (!token) {
    history.push("/");
  }

  useEffect(() => {
    if (token) {
      if (rol.includes("TMONEY")) {
        history.push("/salas/MSalas");
      }
      // if (rol.includes("ADMINISTRADOR")) {
      //   history.push("/salas/Mantenimiento");
      // }
    }
  }, []);

  const [ordenarCuentasCasas, setOrdenarCuentasCasas] = useState(false);

  //----------------BUSCAR-----------------------

  const [search, setSearch] = useState("");
  const [tiempo, setTiempo] = useState(false);

  //Mensaje
  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        break;
    }
  };

  //-------------------CUENTA CASAS--------------------------
  const url = `${host3}/api/cuenta_casas/${token}/mostrar`;
  const [cuentaCasas, setListcuentaCasas] = useState([]);
  const [filterListCuenta, setFilterListCuenta] = useState([]);
  const [searchCuenta, setSearchCuenta] = useState({
    name: "",
    date_initial: "",
    date_end: "",
  });

  const getCuentaCasas = async () => {
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        setListcuentaCasas(cuentaCasas);
        setListcuentaCasas(response.data);
        setFilterListCuenta(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const ListFiltered = () => {
    if (search) {
      var filtered =
        filterListCuenta.length > 0
          ? filterListCuenta.filter(
              (p) =>
                p.casa.nombre.includes(search.toUpperCase()) ||
                p.sala.nombre.includes(search.toUpperCase()) ||
                p.moneda.nombre.includes(search.toUpperCase()) ||
                p.usuario.includes(search.toUpperCase())
            )
          : cuentaCasas.filter(
              (p) =>
                p.casa.nombre.includes(search.toUpperCase()) ||
                p.sala.nombre.includes(search.toUpperCase()) ||
                p.moneda.nombre.includes(search.toUpperCase()) ||
                p.usuario.includes(search.toUpperCase())
            );
      return filtered.slice(currentPage, currentPage + 13);
    } else {
      return filterListCuenta.length <= 0
        ? cuentaCasas?.slice(currentPage, currentPage + 13)
        : filterListCuenta?.slice(currentPage, currentPage + 13);
    }
  };

  //------------------AGREGAR CUENTA CASAS--------------------------
  const [isretenido, setIsretenido] = useState(false);
  const [ModelCuenta, setModelCuenta] = useState({
    id_cuenta_bancaria: "",
    id_modulo: "",
    usuario: "",
    clave: "",
    id_casa: "",
    retenido: "",
    status: "",
  });

  const handleChange = (e) => {
    setModelCuenta({
      ...ModelCuenta,
      [e.target.name]: e.target.value,
    });
  };

  const [listCasa, setListCasa] = useState([]);
  const getCasa = async () => {
    await axios
      .get(`${host3}/api/casas/${token}/listar/${id_persona}`)
      .then((response) => {
        let data = [];
        response.data.map((itemData) => {
          itemData.sala.casas.map((itemCasa) => {
            data.push(itemCasa);
          });
        });
        setListCasa(data);
      });
  };

  const [listModulo, setListModulo] = useState([]);
  const getModulo = async () => {
    await axios
      .get(`${host3}/api/modulos/${token}/list-modules-for-hall`)
      .then((response) => {
        let data = [];
        const dataFilter = response.data.filter(
          (itemFilter) => itemFilter.id_sala == sala[0]
        );
        dataFilter.map((itemData) => {
          itemData.modulos.map((itemModule) => data.push(itemModule));
        });
        setListModulo(data);
      });
  };

  const [lisTarjeta, setListTarjeta] = useState([]);
  const getTarjeta = async () => {
    await axios
      .get(`${host3}/api/cuentas_bancarias/${token}/`)
      .then((response) => {
        setListTarjeta(
          response.data.filter((itemFilter) => itemFilter.id_sala == sala[0])
        );
      });
  };

  const agregar = async () => {
    ModelCuenta.status = isverificado ? 1 : 0;
    try {
      const response = await axios.post(
        `${host3}/api/cuenta_casas/${token}`,
        ModelCuenta
      );
      setModelCuenta({
        id_cuenta_bancaria: "",
        id_modulo: "",
        usuario: "",
        clave: "",
        id_casa: "",
        status: "",
      });
      mensaje(response.data.message, "top-right", 1);
      onClose();
      await getCuentaCasas();
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  //------------------------EDITAR CUENTA CASAS----------------a

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    ModelCuenta.status = isverificado ? 1 : 0;
    ModelCuenta.retenido = isretenido ? 1 : 0;
    try {
      const response = await axios.put(
        `${host3}/api/cuenta_casas/${token}/${ModelCuenta.id_cuenta_casas}`,
        ModelCuenta
      );
      mensaje(response.data.message, "top-right", 1);
      //console.log(response);
      onClose2();
      setModelCuenta({
        id_cuenta_bancaria: "",
        id_modulo: "",
        usuario: "",
        clave: "",
        id_casa: "",
      });
      getCuentaCasas();
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
      //console.error(error);
    }
  };

  //-------------------------DAR DE BAJA ---------------------------

  const darDeBajaUsuario = async (id_cuenta_casas) => {
    // Obtener la fecha actual
    const NOW = new Date();

    // Datos de actualización
    const datosActualizados = {
      estado: false,
      fecha_baja: NOW,
    };
    await axios
      .get(
        `${host3}/api/cuenta_casas/${token}/baja/${id_cuenta_casas}`,
        datosActualizados
      )
      .then((response) => {
        getCuentaCasas();
      })
      .catch((error) => {
        //console.error('Error al dar de baja al usuario:', error);
      });
  };

  //-----------------ACTIVAR----------------------

  const activar = async (id_cuenta_casas) => {
    const datosActualizados = {
      estado: true,
    };
    await axios
      .put(
        `${host3}/api/cuenta_casas/${token}/activar/${id_cuenta_casas}`,
        datosActualizados
      )
      .then((response) => {
        getCuentaCasas();
      })
      .catch((error) => {
        console.error("Error al activar la casa:", error);
      });
  };

  //-------------------EXCEL-----------------------
  const [dataExcel, setDataExcel] = useState([]);

  const exportExcelCuenta = () => {
    let data = [];

    if (filterListCuenta.length > 0) {
      filterListCuenta.map((p, i) => {
        data.push({
          "N°": i + 1,
          Fecha: p.fecha_registro,
          Titular: p.persona?.apellidos + " " + p.persona?.nombres,
          "Cuenta Bancaria": p.cuenta_bancarium?.numero_cuenta,
          Modulo: p.modulo.nombre,
          Casa: p.casa.nombre,
          Moneda: p.moneda?.nombre,
          Monto: p.monto,
          Pendiente: p.pendiente,
        });
      });
    } else {
      cuentaCasas.map((p, i) => {
        data.push({
          "N°": i + 1,
          Fecha: p.fecha_registro,
          Titular: p.persona?.apellidos + " " + p.persona?.nombres,
          "Cuenta Bancaria": p.cuenta_bancarium?.numero_cuenta,
          Modulo: p.modulo.nombre,
          Casa: p.casa.nombre,
          Moneda: p.moneda?.nombre,
          Monto: p.monto,
          Pendiente: p.pendiente,
        });
      });
    }

    setDataExcel(data);
  };

  useEffect(() => {
    exportExcelCuenta();
  }, [filterListCuenta, cuentaCasas]);

  const exportAsExcel = () => {
    let wb = utils.book_new();
    let ws = utils.json_to_sheet(dataExcel);

    // Establecer el ancho de las columnas
    ws["!cols"] = [
      { key: "orden", wch: 5 },
      { key: "fecha", wch: 15 },
      { key: "titular", wch: 30 },
      { key: "cuenta_bancaria", wch: 15 },
      { key: "modulo", wch: 15 },
      { key: "casa", wch: 15 },
      { key: "moneda", wch: 15 },
      { key: "monto", wch: 15 },
      { key: "pendiente", wch: 18 },
      // Añade más columnas según sea necesario
    ];

    utils.book_append_sheet(wb, ws, `Cuenta_Casas`);
    writeFile(wb, `Cuenta_Casas_${moment().format("LTS")}.xlsx`);
  };
  // ----------------------- BUSCAR POR FECHA----------------------------------------

  const handleChangeSearch = (e) => {
    setSearchCuenta({
      ...searchCuenta,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickSearch = () => {
    if (searchCuenta.date_initial == "" || searchCuenta.date_end == "") {
      mensaje("El rango de fecha debe estar completo", "top-right", 2);
      return;
    }

    const fechaInicio = new Date(searchCuenta.date_initial); //fecha de inicio
    const fechaFin = new Date(searchCuenta.date_end); // Establece la fecha de fin

    const filteredList = cuentaCasas.filter((itemFilter) => {
      const fechaRegistro = new Date(itemFilter.fecha_registro);
      return fechaRegistro >= fechaInicio && fechaRegistro <= fechaFin;
    });

    setFilterListCuenta(filteredList);
  };
  const buscar = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    getCuentaCasas();
    getModulo();
    getTarjeta();
    getCasa();

    setTimeout(() => {
      setTiempo(true);
    }, [2000]);
  }, []);

  //Paginacion
  const [currentPage, setCurrentPage] = useState(0);
  const nextPage = () => {
    const cant = filterListCuenta.filter((p) =>
      p.id_cuenta_casas.toString()
    ).length;

    if (cant > currentPage) {
      setCurrentPage(currentPage + 13);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 13);
    }
  };
  // ---------------------------

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();

  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar,
  } = useDisclosure();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: "1.2rem", textAlign: "center" }}
        pauseOnHover
      />

      <Box
        scrollBehavior={"smooth"}
        bg={"#e5e5e5"}
        height={"auto"}
        maxHeight={"100vh"}
        overflowY={"auto"}
        css={{
          "&::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#6b6b6b",
            borderRadius: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#9b9b9b",
            borderRadius: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#9b9b9b",
          },
        }}
      >
        <Box
          as="section"
          display={{
            "2xl": "unset",
            xl: "unset",
            lg: "none",
            md: "none",
            sm: "none",
          }}
        >
          <SidebarOperators />
        </Box>
        <Drawer isOpen={isOpenBar} onClose={onCloseBar} placement="left">
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w="full" borderRight="none" />
          </DrawerContent>
        </Drawer>

        <Box bg={"#e5e5e5"} h={"100vh"}>
          <Flex
            alignItems={"center"}
            justify={"flex-start"}
            // h={'100%'}
            sx={{
              "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
                {
                  padding: "1.5% 1.5% 1.5% 19%",
                },
            }}
            p={{
              "2xl": "1.5% 1% 1.5% 14%",
              xl: "1.5% 3% 1.5% 18%",
              lg: "1.5% 2% 1.5% 2%",
              md: "1.5% 2% 1.5% 2%",
            }}
            // marginLeft={'15%'}
            // marginRight='2%'
            // marginBottom='1%'
            direction="column"
            gap={"25px"}
          >
            <Box transition=".3s ease" width={"100%"}>
              <Flex gap="2%" alignItems={"center"} as="header" w="full" h="14">
                <IconButton
                  aria-label="Menu"
                  display={{
                    "2xl": "none",
                    xl: "none",
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                  }}
                  background={"#292F36"}
                  color={"#ffffff"}
                  borderRadius={"4px"}
                  fontSize={"1.3rem"}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size="md"
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink color={"#707070"} fontSize={"1.5rem"}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={"bold"}
                      color={"#292F36"}
                      fontSize={"1.5rem"}
                    >
                      CUENTAS CASAS
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>

            <Flex width={"100%"} direction={"column"} gap={"25px"}>
              <Flex width={"100%"} justifyContent={"space-between"}>
                <Flex width={"30%"}>
                  <Stack w={"100%"}>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={
                          <Search2Icon
                            color="#2a2e6e"
                            marginLeft={"20%"}
                            fontSize={"1.25rem"}
                            marginTop={"60%"}
                            _dark={{
                              color: "#f59f2c",
                            }}
                          />
                        }
                      />
                      <Input
                        onChange={buscar}
                        type="text"
                        placeholder="Buscar"
                        bg={"white"}
                        height={"40px"}
                        _focus={{
                          borderColor: "#2a2e6e",
                          boxShadow: "none",
                        }}
                        color="#2a2e6e"
                        fontSize={"1.9rem"}
                        border={"1px solid"}
                        borderColor={"#2a2e6e"}
                        borderRadius="6px"
                        _placeholder={{ color: "rgb(152, 152, 152)" }}
                        _dark={{
                          color: "white",
                          borderColor: "#686868",
                          bg: "#686868",
                        }}
                      />
                    </InputGroup>
                  </Stack>
                  {tiempo && (
                    <Button
                      onClick={exportAsExcel}
                      background={"#4caf50"}
                      color={"#fff"}
                      height={"40px"}
                      borderRadius={"5px"}
                      padding={"6px 16px"}
                      marginLeft={"15px"}
                      _hover={"none"}
                    >
                      <Icon boxSize="10" as={RiFileExcel2Fill} />
                    </Button>
                  )}
                </Flex>

                <Flex w={"40%"} alignItems={"center"} gap={"1.5%"}>
                  <Input
                    placeholder="Fecha de inicio"
                    onChange={handleChangeSearch}
                    value={searchCuenta.date_initial}
                    name="date_initial"
                    size="md"
                    type="date"
                    bg={"white"}
                    height={"40px"}
                    fontSize={"1.8rem"}
                    color="#2a2e6e"
                    borderColor={"#2a2e6e"}
                    _dark={{
                      color: "white",
                      borderColor: "#686868",
                      bg: "#686868",
                    }}
                    // onChange={handleFecha}
                  />
                  <Text fontSize={"1.8rem"}>al</Text>
                  <Input
                    placeholder="Fecha de inicio"
                    value={searchCuenta.date_end}
                    onChange={handleChangeSearch}
                    name="date_end"
                    size="md"
                    type="date"
                    bg={"white"}
                    height={"40px"}
                    fontSize={"1.8rem"}
                    color="#2a2e6e"
                    borderColor={"#2a2e6e"}
                    _dark={{
                      color: "white",
                      borderColor: "#686868",
                      bg: "#686868",
                    }}
                  />

                  <Stack align="center" width={"48%"}>
                    <Button
                      onClick={handleClickSearch}
                      w={"100%"}
                      height={"40px"}
                      borderRadius="10px"
                      color={"white"}
                      fontSize={"1.6rem"}
                      bg="#2a2e6e"
                      _dark={{
                        color: "white",
                        bg: "#f59f2c",
                      }}
                    >
                      Buscar
                    </Button>
                  </Stack>
                </Flex>
              </Flex>

              <Flex
                boxShadow={
                  "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"
                }
                background={"white"}
                borderRadius={"18px"}
                direction={"column"}
                padding={"2% 2% 2% 2%"}
              >
                <Flex width={"100%"} justifyContent={"right"}>
                  <Stack align="center" width={"auto"}>
                    <Button
                      onClick={onOpen}
                      w={"100%"}
                      height={"40px"}
                      borderRadius="8px"
                      color={"white"}
                      fontSize={"1.6rem"}
                      textTransform={"uppercase"}
                      bg="#2a2e6e"
                      _hover={{
                        color: "white",
                        bg: "#f59f2c",
                      }}
                    >
                      Añadir Cuenta
                    </Button>
                  </Stack>
                </Flex>

                <Flex
                  direction={"column"}
                  w={"100%"}
                  h="100%"
                  justifyContent="center"
                  paddingTop={"2%"}
                >
                  <TableContainer w={"100%"}>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th
                            fontSize={"1.6rem"}
                            cursor={"pointer"}
                            // onClick={() => {
                            //   var ordenDocument;
                            //   if (!ordenarCuentasCasas) {
                            //     ordenDocument = listCasa.sort((a, b) =>
                            //       a.persona?.apellidos.localeCompare(b.apellidos)
                            //     );
                            //     setListCasa(ordenDocument);
                            //     setOrdenarCuentasCasas(true);
                            //   } else {
                            //     ordenDocument = listCasa.sort((a, b) =>
                            //       b.apellidos?.localeCompare(a.apellidos)
                            //     );
                            //     setListCasa(ordenDocument);
                            //     setOrdenarCuentasCasas(false);
                            //   }
                            // }}
                          >
                            TITULAR
                          </Th>

                          <Th fontSize={"1.6rem"}>Nro.CUENTA</Th>
                          <Th
                            fontSize={"1.6rem"}
                            cursor={"pointer"}
                            onClick={() => {
                              var ordenDocument;
                              if (!ordenarCuentasCasas) {
                                ordenDocument = listCasa.sort((a, b) =>
                                  a.casa.localeCompare(b.casa)
                                );
                                setListCasa(ordenDocument);
                                setOrdenarCuentasCasas(true);
                              } else {
                                ordenDocument = listCasa.sort((a, b) =>
                                  b.casa.localeCompare(a.casa)
                                );
                                setListCasa(ordenDocument);
                                setOrdenarCuentasCasas(false);
                              }
                            }}
                          >
                            CASA
                          </Th>
                          <Th fontSize={"1.6rem"}>USUARIO</Th>
                          <Th fontSize={"1.6rem"}>CLAVE</Th>

                          <Th
                            fontSize={"1.6rem"}
                            cursor={"pointer"}
                            onClick={() => {
                              var ordenDocument;
                              if (!ordenarCuentasCasas) {
                                ordenDocument = listCasa.sort((a, b) =>
                                  a.moneda.localeCompare(b.moneda)
                                );
                                setListCasa(ordenDocument);
                                setOrdenarCuentasCasas(true);
                              } else {
                                ordenDocument = listCasa.sort((a, b) =>
                                  b.moneda.localeCompare(a.moneda)
                                );
                                setListCasa(ordenDocument);
                                setOrdenarCuentasCasas(false);
                              }
                            }}
                          >
                            MONEDA
                          </Th>

                          <Th fontSize={"1.6rem"}>MONTO</Th>
                          <Th fontSize={"1.6rem"}>VERIFICADO</Th>
                          <Th fontSize={"1.6rem"}>MODULO</Th>
                          <Th
                            fontSize={"1.6rem"}
                            cursor={"pointer"}
                            onClick={() => {
                              var ordenDocument;
                              if (!ordenarCuentasCasas) {
                                ordenDocument = listCasa.sort((a, b) =>
                                  a.fecha_registro.localeCompare(
                                    b.fecha_registro
                                  )
                                );
                                setListCasa(ordenDocument);
                                setOrdenarCuentasCasas(true);
                              } else {
                                ordenDocument = listCasa.sort((a, b) =>
                                  b.fecha_registro.localeCompare(
                                    a.fecha_registro
                                  )
                                );
                                setListCasa(ordenDocument);
                                setOrdenarCuentasCasas(false);
                              }
                            }}
                          >
                            FECHA INICIO
                          </Th>
                          <Th fontSize={"1.6rem"}>FECHA BAJA</Th>
                          <Th fontSize={"1.6rem"}>RETENIDO</Th>
                          <Th fontSize={"1.6rem"}>ESTADO</Th>
                          <Th fontSize={"1.6rem"} isNumeric>
                            ACCIONES
                          </Th>
                        </Tr>
                      </Thead>
                      {ListFiltered()?.map((p) => {
                        return (
                          <Tbody fontSize={"1.5rem"}>
                            <Tr>
                              <Td>
                                {" "}
                                {p.persona?.apellidos} {p.persona?.nombres}
                              </Td>
                              <Td>{p.cuenta_bancarium?.numero_cuenta}</Td>
                              <Td>
                                {p.casa?.alias == null
                                  ? p.casa?.nombre
                                  : p.casa?.alias}
                              </Td>
                              <Td>{p.usuario}</Td>
                              <Td>{p.clave}</Td>
                              <Td>{p.moneda?.nombre}</Td>
                              <Td>{p.monto}</Td>
                              <Td>
                                {p.verificado == 1
                                  ? "Verificado"
                                  : "No Verificado"}
                              </Td>
                              <Td>{p.modulo.nombre}</Td>

                              <Td>{p.fecha_registro}</Td>
                              <Td>{p.fecha_baja}</Td>
                              <Td>{p.retenido == 1 ? "SI" : "NO"}</Td>
                              <Td>{p.estado == 1 ? "Activado" : "Inactivo"}</Td>
                              <Td
                                fontWeight={"600"}
                                fontFamily="monospace"
                                isNumeric
                              >
                                <Flex justifyContent={"right"} gap={"4%"}>
                                  <Tooltip
                                    hasArrow
                                    borderRadius={"4px"}
                                    fontSize={"1.2rem"}
                                    label="Editar"
                                    bg="#292F36"
                                  >
                                    <IconButton
                                      onClick={() => {
                                        setModelCuenta(p);
                                        setIsverificado(
                                          p.verificado == 1 ? true : false
                                        );
                                        setIsretenido(
                                          p.retenido == 1 ? true : false
                                        );
                                        onOpen2();
                                      }}
                                      size={"md"}
                                      borderRadius={"4px"}
                                      fontSize={"1.5rem"}
                                      background={"#f59f2c"}
                                      color={"#ffffff"}
                                      aria-label="Editar"
                                      icon={<RiEditFill />}
                                    />
                                  </Tooltip>
                                  {p.estado == !1 ? (
                                    <Tooltip
                                      hasArrow
                                      borderRadius={"4px"}
                                      fontSize={"1.2rem"}
                                      label="Activar"
                                      bg="#292F36"
                                    >
                                      <IconButton
                                        onClick={() => {
                                          activar(p.id_cuenta_casas);
                                          setActivo1(false);
                                        }}
                                        size={"md"}
                                        borderRadius={"4px"}
                                        fontSize={"1.5rem"}
                                        background={"#2b752d"}
                                        color={"#ffffff"}
                                        aria-label="Activar"
                                        icon={<FaCheckCircle />}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      hasArrow
                                      borderRadius={"4px"}
                                      fontSize={"1.2rem"}
                                      label="Desactivar"
                                      bg="#292F36"
                                    >
                                      <IconButton
                                        onClick={() => {
                                          darDeBajaUsuario(p.id_cuenta_casas);
                                          setActivo1(true);
                                        }}
                                        size={"md"}
                                        borderRadius={"4px"}
                                        fontSize={"1.5rem"}
                                        background={"#be0000"}
                                        color={"#ffffff"}
                                        aria-label="Desactivar"
                                        icon={<MdDoDisturbOff />}
                                      />
                                    </Tooltip>
                                  )}
                                </Flex>
                              </Td>
                            </Tr>
                          </Tbody>
                        );
                      })}
                    </Table>
                  </TableContainer>

                  <Flex
                    marginTop={"0.5%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={"0.5%"}
                  >
                    <Icon
                      onClick={() => {
                        prevPage();
                      }}
                      color={"#13235b"}
                      cursor={"pointer"}
                      boxSize="16"
                      _hover={{
                        color: "gray !important",
                      }}
                      _dark={{
                        color: "#f59f2c",
                      }}
                      as={IoMdArrowDropleftCircle}
                    />
                    <Icon
                      onClick={() => {
                        nextPage();
                      }}
                      color={"#13235b"}
                      cursor={"pointer"}
                      boxSize="16"
                      _hover={{
                        color: "gray !important",
                      }}
                      _dark={{
                        color: "#f59f2c",
                      }}
                      as={IoMdArrowDroprightCircle}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>

      {/* ---------------Agregar cuenta casas--------- */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "35%", xl: "35%", lg: "55%", md: "70%" }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Añadir Cuenta
          </ModalHeader>
          <ModalCloseButton fontSize={"1.2rem"} />
          <ModalBody>
            <Flex width={"100%"} justifyContent={"space-between"}>
              <Flex
                direction={"column"}
                alignItems="center"
                gap="12px"
                w={"100%"}
                h="100%"
              >
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Cuenta Bancaria (opcional):
                    </FormLabel>
                    <Select
                      onChange={handleChange}
                      value={ModelCuenta.id_cuenta_bancaria}
                      name="id_cuenta_bancaria"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" disabled selected>
                        Elige la opción
                      </option>
                      {lisTarjeta &&
                        lisTarjeta.map((itemTarjeta) => {
                          return (
                            <option
                              key={itemTarjeta.id_cuenta_bancaria}
                              value={`${itemTarjeta.id_cuenta_bancaria}`}
                            >
                              {itemTarjeta.moneda.nombre} -{" "}
                              {itemTarjeta.persona.nombres}
                              {itemTarjeta.persona.apellidos}
                            </option>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Flex>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Casa:
                  </FormLabel>
                  <Select
                    onChange={handleChange}
                    value={ModelCuenta.id_casa}
                    name="id_casa"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    <option value="" disabled selected>
                      Elige la opción
                    </option>
                    {listCasa &&
                      listCasa.map((itemCasa) => (
                        <option
                          key={itemCasa.id_casa}
                          value={`${itemCasa.id_casa}`}
                        >
                          {itemCasa.alias == null
                            ? itemCasa.nombre
                            : itemCasa.alias}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Usuario:
                    </FormLabel>
                    <Input
                      onChange={handleChange}
                      value={ModelCuenta.usuario}
                      name="usuario"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Contraseña:
                    </FormLabel>
                    <Input
                      onChange={handleChange}
                      value={ModelCuenta.clave}
                      name="clave"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>
                </Flex>

                <Flex w={"100%"} gap={"5%"} alignItems={"center"}>
                  <FormControl width={"50%"}>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Modulo:
                    </FormLabel>
                    <Select
                      onChange={handleChange}
                      value={ModelCuenta.id_modulo}
                      name="id_modulo"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" disabled selected>
                        Elige la opción
                      </option>
                      {listModulo &&
                        listModulo.map((itemModule) => {
                          return (
                            <option
                              key={itemModule.id_modulo}
                              value={`${itemModule.id_modulo}`}
                            >
                              {itemModule.nombre}
                            </option>
                          );
                        })}
                    </Select>
                  </FormControl>

                  <Stack
                    w={"50%"}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={"1.5rem"}
                  >
                    <IconButton
                      onClick={() => {
                        setIsverificado(!isverificado);
                      }}
                      _hover={"none"}
                      _active={"none"}
                      fontSize={"1.7rem"}
                      background={"transparent"}
                      color={"blue"}
                      aria-label="check"
                      icon={
                        isverificado == true ? (
                          <ImCheckboxChecked />
                        ) : (
                          <ImCheckboxUnchecked />
                        )
                      }
                    />

                    <Text>Verificado</Text>
                  </Stack>

                  {/* <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Moneda:
                    </FormLabel>
                    <Select
                      // onChange={handleChangeMoneda}
                      // value={ModelCuenta.id_moneda}
                      name="id_moneda"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" disabled selected>
                        Elige la opción
                      </option>
                      {/* {listMoneda &&
                        listMoneda.map((lr) => {
                          return (
                            <option value={`${lr.id_moneda}`}>
                              {lr.nombre}
                            </option>
                          );
                        })} */}
                  {/* </Select>
                  </FormControl>  */}
                </Flex>

                {/* <FormControl>
                  <FormLabel fontSize={'1.85rem'} fontWeight='500'>
                    Denominacion:
                  </FormLabel>
                  <Input
                    value={ModelCuenta.denominacion}
                    onChange={handleChange}
                    placeholder='OPCIONAL'
                    name='denominacion'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                  />
                </FormControl> */}
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"center"} marginTop={"2%"}>
              <Button
                onClick={agregar}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                bg="#2a2e6e"
                _hover={{
                  color: "white",
                  bg: "#f59f2c",
                }}
                p={{
                  "2xl": "2% 3%",
                  xl: "2% 3%",
                  lg: "2% 3%",
                  md: "2.5% 4%",
                }}
              >
                Agregar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* --------------------Editar----------------- */}
      <Modal
        isOpen={isOpen2}
        onClose={() => {
          setModelCuenta({
            id_cuenta_bancaria: "",
            id_modulo: "",
            usuario: "",
            clave: "",
            id_casa: "",
          });
          onClose2();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW={"55%"} p={"1%"}>
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Editar Cuenta
          </ModalHeader>
          <ModalCloseButton fontSize={"1.2rem"} />
          <ModalBody>
            <Flex width={"100%"} justifyContent={"space-between"}>
              <Flex
                direction={"column"}
                alignItems="center"
                gap="12px"
                w={"100%"}
                h="100%"
              >
                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Cuenta Bancaria:
                    </FormLabel>
                    <Select
                      onChange={handleChange}
                      value={ModelCuenta.id_cuenta_bancaria}
                      name="id_cuenta_bancaria"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" disabled selected>
                        Elige la opción
                      </option>
                      {lisTarjeta &&
                        lisTarjeta.map((itemTarjeta) => {
                          return (
                            <option
                              key={itemTarjeta.id_cuenta_bancaria}
                              value={`${itemTarjeta.id_cuenta_bancaria}`}
                            >
                              {itemTarjeta.moneda.nombre}-
                              {itemTarjeta.numero_cuenta}-
                              {itemTarjeta.persona.apellidos}
                            </option>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Flex>
                <FormControl>
                  <FormLabel fontSize={"1.85rem"} fontWeight="500">
                    Casa:
                  </FormLabel>
                  <Select
                    onChange={handleChange}
                    value={ModelCuenta.id_casa}
                    name="id_casa"
                    fontSize={"1.8rem"}
                    h="35"
                  >
                    <option value="" disabled selected>
                      Elige la opción
                    </option>
                    {listCasa &&
                      listCasa.map((itemCasa) => (
                        <option
                          key={itemCasa.id_casa}
                          value={`${itemCasa.id_casa}`}
                        >
                          {itemCasa.alias == null
                            ? itemCasa.nombre
                            : itemCasa.alias}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Usuario:
                    </FormLabel>
                    <Input
                      onChange={handleChange}
                      value={ModelCuenta.usuario}
                      name="usuario"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Contraseña:
                    </FormLabel>
                    <Input
                      onChange={handleChange}
                      value={ModelCuenta.clave}
                      name="clave"
                      fontSize={"1.8rem"}
                      h="35"
                      type="text"
                    />
                  </FormControl>
                </Flex>

                <Flex w={"100%"} gap={"5%"}>
                  <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Modulo:
                    </FormLabel>
                    <Select
                      onChange={handleChange}
                      value={ModelCuenta.id_modulo}
                      name="id_modulo"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" disabled selected>
                        Elige la opción
                      </option>
                      {listModulo &&
                        listModulo.map((itemModule) => {
                          return (
                            <option
                              key={itemModule.id_modulo}
                              value={`${itemModule.id_modulo}`}
                            >
                              {itemModule.nombre}
                            </option>
                          );
                        })}
                    </Select>
                  </FormControl>
                  <Stack
                    w={"50%"}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={"1.5rem"}
                  >
                    <IconButton
                      onClick={() => {
                        setIsretenido(!isretenido);
                      }}
                      _hover={"none"}
                      _active={"none"}
                      fontSize={"1.7rem"}
                      background={"transparent"}
                      color={"blue"}
                      aria-label="check"
                      icon={
                        isretenido == true ? (
                          <ImCheckboxChecked />
                        ) : (
                          <ImCheckboxUnchecked />
                        )
                      }
                    />

                    <Text>Retenido</Text>
                  </Stack>
                  <Stack
                    w={"50%"}
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={"1.5rem"}
                  >
                    <IconButton
                      onClick={() => {
                        setIsverificado(!isverificado);
                      }}
                      _hover={"none"}
                      _active={"none"}
                      fontSize={"1.7rem"}
                      background={"transparent"}
                      color={"blue"}
                      aria-label="check"
                      icon={
                        isverificado == true ? (
                          <ImCheckboxChecked />
                        ) : (
                          <ImCheckboxUnchecked />
                        )
                      }
                    />

                    <Text>Verificado</Text>
                  </Stack>
                  {/* <FormControl>
                    <FormLabel fontSize={"1.85rem"} fontWeight="500">
                      Moneda:
                    </FormLabel>
                    <Select
                      // onChange={handleChangeMoneda}
                      // value={ModelCuenta.id_moneda}
                      name="id_moneda"
                      fontSize={"1.8rem"}
                      h="35"
                    >
                      <option value="" disabled selected>
                        Elige la opción
                      </option>
                      {/* {listMoneda &&
                        listMoneda.map((lr) => {
                          return (
                            <option value={`${lr.id_moneda}`}>
                              {lr.nombre}
                            </option>
                          );
                        })} */}
                  {/* </Select>
                  </FormControl>  */}
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex width={"100%"} justifyContent={"center"} marginTop={"2%"}>
              <Button
                onClick={handleSubmitUpdate}
                borderRadius={"8px"}
                textTransform="uppercase"
                color={"white"}
                fontSize={"1.6rem"}
                bg="#2a2e6e"
                _hover={{
                  color: "white",
                  bg: "#f59f2c",
                }}
                p="2%"
              >
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CuentasCasas;
