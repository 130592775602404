import AppRouter from './routers/AppRouter';

function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;

