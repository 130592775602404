import React from 'react';
// import './../../../assets/css/GeneralTmoney.css';

// Chakra imports
import {
  Flex,
  Box,
  useDisclosure,
  Image,
  Button,
  background
} from '@chakra-ui/react';
import { FaHome } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
function NotFoundView() {
  let history = useHistory();
  const token = localStorage.getItem('token');
  // console.log(props);
  // if (!token) {
  //   history.push('/');
  // }

  return (
    <>
      <Box
        position={'relative'}
        zIndex={'1500'}
        bg={'#FFFFFF'}
        height={'auto'}
        maxHeight={'100vh'}
        overflowY={'auto'}
        css={{
          '&::-webkit-scrollbar': {
            width: '12px'
          },
          '&::-webkit-scrollbar-track': {
            background: '#6b6b6b',
            borderRadius: '0px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#9b9b9b',
            borderRadius: '6px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#9b9b9b'
          }
        }}>
        <Flex h={'100%'} minHeight={'100vh'} flexDirection='column'>
          <Box>
            <Flex
              h={'100vh'}
              direction={'column'}
              background={'#f9f9f9'}
              _dark={{
                background: '#000411'
              }}
              gap={"20px"}
              justifyContent={'center'}
              alignItems={'center'}>
              <Flex
                direction={'column'}
                alignItems={'center'}
                borderBottom={'solid 45px'}
                borderColor={'#e5e6e7'}
                _dark={{
                  borderColor: '#000D27'
                }}
                width={'100%'}
                transition={'border-bottom ease-in-out 1s'}>
                <Image
                  width={'80%'}
                  maxWidth={'650px'}
                  zIndex={'1'}
                  transform={'translateY(40px)'}
                  src='https://res.cloudinary.com/ddsfttqtk/image/upload/v1687463624/T-Money/notFound_error_lyaqn3.png'
                  alt='NotFound'
                />
              </Flex>
              <Button
                height={'45px'}
                // marginTop={'20px'}
                alignItems={'center'}
                padding={'1% 1.5%'}
                borderRadius={'32px'}
                fontSize={'1.6rem'}
                fontWeight={'bold'}
                background={'#13235b'}
                color={'white'}
                _dark={{
                  background: '#f68b18'
                }}
                _hover={{
                  background: 'gray',
                  _dark: {
                    background: 'gray'
                  }
                }}
                onClick={() => {
                  if (!token) {
                    history.push('/');
                  } else {
                    history.push('/salas/Mantenimiento');
                  }
                }}
                leftIcon={<FaHome fontSize={'2.8rem'} />}>
                REGRESAR AL INICIO
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  );
}

export default NotFoundView;
