import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  Highlight,
  // Avatar,
  // HStack,
  // Link,
  IconButton,
  Button,
  // Menu,
  // MenuButton,
  // MenuList,
  // MenuItem,
  // Container,
  // Img,
  // MenuDivider,
  useDisclosure,
  // useColorModeValue,
  Stack,
  // MenuGroup,
  // Popover,
  // Divider,
  // PopoverTrigger,
  // PopoverContent,
  // PopoverArrow,
  // NavItem,
  // Collapse,
  Text,
  // Badge,
  Icon,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tooltip,
  // Tfoot,
  Tr,
  Th,
  Td,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  // TableCaption,
  TableContainer,
  FormControl,
  FormLabel,
  // FormErrorMessage,
  Select,
  // FormHelperText,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
  AccordionItem,
  Accordion
} from '@chakra-ui/react';
import {
  RiEditFill,
  RiInformationFill,
  RiUserAddFill
} from 'react-icons/ri';
import {
  MdDoDisturbOff,
  MdCancel,
  MdGroupAdd,
  MdAssignmentAdd,
  MdRemoveCircle
} from 'react-icons/md';
import { Search2Icon } from '@chakra-ui/icons';
import { RiFileExcel2Fill } from 'react-icons/ri';
// import { EditIcon } from '@chakra-ui/icons';
// import { FaEdit } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
import { utils, writeFile } from 'xlsx';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import SidebarOperators from '../../../components/SidebarOperators';
import axios from 'axios';
import {
  IoMdArrowDroprightCircle,
  IoMdArrowDropleftCircle
} from 'react-icons/io';
import { FaCheckCircle } from 'react-icons/fa';
import { host3 } from '../../../utils/constantes';
import jwt_decode from 'jwt-decode';

function Entidad(props) {
  let history = useHistory();
  const token = localStorage.getItem('tokenadmin');
  const { id_persona } = jwt_decode(token);
  if (!token) {
    history.push('/');
  }

  //Mensaje
  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      default:
        break;
    }
  };
  // ---------------------------------Ordenar-------------------------------

  const [ordenarEntidad, setOrdenaEntidad] = useState(false);

  // ---------------------------------------------------------------------
  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar
  } = useDisclosure();
  // -------------- Modal Nuevo entidad-------------------------
  const {
    isOpen: isOpenEntidad,
    onOpen: onOpenEntidad,
    onClose: onCloseEntidad
  } = useDisclosure();

  // -------------- Modal Nuevo entidad-------------------------
  const {
    isOpen: isOpenEditEntidad,
    onOpen: onOpenEditEntidad,
    onClose: onCloseEditEntidad
  } = useDisclosure();

  // ------------------------List entidad----------------------

  const ListEntidad = [
    {
      sala: {
        id_sala: 1,
        nombre: 'TMONEY 201',
        personas: [
          {
            id_persona: 1,
            apellidos: 'Perez',
            nombres: 'Leonardo',
            telefono: '896325632',
            correo: 'casi@gmail.com',
            tipo_documento: 'DNI',
            documento: '8965369',
            fecha_nacimiento: '2000-12-21',
            estado: '1'
          },
          {
            id_persona: 2,
            apellidos: 'Manolin',
            nombres: 'Leonardo',
            telefono: '896325632',
            correo: 'casi@gmail.com',
            tipo_documento: 'DNI',
            documento: '8965369',
            fecha_nacimiento: '2000-12-21',
            estado: '1'
          },
          {
            id_persona: 3,
            apellidos: 'Juares',
            nombres: 'Leonardo',
            telefono: '896325632',
            correo: 'casi@gmail.com',
            tipo_documento: 'DNI',
            documento: '8965369',
            fecha_nacimiento: '2000-12-21',
            estado: '1'
          }
        ]
      }
    }
  ];

  // -----------------List Entidad-----------------------

  const [entidad, setEntidad] = useState({
    nombres: '',
    apellidos: '',
    fecha_nacimiento: '',
    telefono: '',
    tipo_documento: '',
    documento: '',
    correo: '',
    roles: [{ id: 6, nombre: 'ENTIDAD' }],
    id_sala: ''
  });

  const changeEntidad = (e) => {
    const maxDigits = entidad.tipo_documento === 'DNI' ? 8 : entidad.tipo_documento === 'RUC' ? 11 : null;
    if (e.target.name === 'documento' && maxDigits !== null) {
      const inputValue = e.target.value;
      if (!(/^\d{0,}$/.test(inputValue) && inputValue.length <= maxDigits)) {
        return;
      }
    }
    setEntidad({
      ...entidad,
      [e.target.name]: e.target.value
    });
  };

  // -----------------Crear Entidad-----------------------

  const crearEntidad = async (e) => {
    e.preventDefault();
    try {
      const { status, data } = await axios.post(
        `${host3}/api/personas/${token}`,
        entidad
      );

      if (status == 201) {
        mensaje(data.message, 'top-right', 1);
        onCloseEntidad();
        setEntidad({
          apellidos: '',
          nombres: '',
          telefono: '',
          correo: '',
          tipo_documento: '',
          documento: '',
          fecha_nacimiento: '',
          roles: [{ id: 6, nombre: 'ENTIDAD' }],
          id_sala: ''
        });
        getEntidad();
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
  };

  // -------------Editar Entidad--------------------

  const [updateEntidad, setupdateEntidad] = useState({
    apellidos: '',
    nombres: '',
    telefono: '',
    correo: '',
    tipo_documento: '',
    documento: '',
    fecha_nacimiento: '',
    roles: []
  });

  const changeUpdate = (e) => {
    const maxDigits = updateEntidad.tipo_documento === 'DNI' ? 8 : updateEntidad.tipo_documento === 'RUC' ? 11 : null;
    if (e.target.name === 'documento' && maxDigits !== null) {
      const inputValue = e.target.value;
      if (!(/^\d{0,}$/.test(inputValue) && inputValue.length <= maxDigits)) {
        return;
      }
    }
    setupdateEntidad({
      ...updateEntidad,
      [e.target.name]: e.target.value
    });
  };

  const editarEntidad = async (e) => {
    e.preventDefault();
    try {
      updateEntidad.roles = [{ id: 6, nombre: 'ENTIDAD' }];
      const { data, status } = await axios.put(
        `${host3}/api/personas/${token}/${updateEntidad.id_persona}`,
        updateEntidad
      );
      if (status == 201) {
        mensaje(data.message, 'top-right', 1);
        onCloseEditEntidad();
        setEntidad({
          apellidos: '',
          nombres: '',
          telefono: '',
          correo: '',
          tipo_documento: '',
          documento: '',
          fecha_nacimiento: '',
          roles: []
        });
        getEntidad();
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
      //console.log(error);
    }
  };

  //--------------------QUITAR-----------------------------

  const quitar = async (id) => {
    try {
      const { status, data } = await axios.put(
        `${host3}/api/personas/${token}/${id}/accion/0`
      );
      if (status == 201) {
        mensaje(data.message, 'top-right', 1);
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
    getEntidad();
  };

  //Paginacion

  const [search, setSearch] = useState("");
  const [listAdministrador, setListAdministrador] = useState([]);
  const [listEntity, setListEntity] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const nextPage = () => {
    const cant = listAdministrador.filter((p) =>
      p.id_colaborador.toString().includes(search)
    ).length;

    if (cant > currentPage) {
      setCurrentPage(currentPage + 13);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 13);
    }
  };

  // ---------------------------

  // Listar Entidad
  const url = host3 + `/api/salas/${token}/list-entity/${id_persona}`;

  const getEntidad = async () => {
    await axios
      .get(url)
      .then((response) => {
        setListEntity(response.data);
        console.log(response.data);
      })
      .catch((error) => {});
  };

  const ListFilteredEntidad = () => {
    if (search) {
      var filtered = listEntity.filter(
        (c) =>
          c.nombre?.toString().includes(search) ||
          c.nombre?.toString().toUpperCase().includes(search) ||
          c.nombre?.toString().toLowerCase().includes(search)
      );
      return filtered.slice(currentPage, currentPage + 8);
    } else {
      return listEntity?.slice(currentPage, currentPage + 8);
    }
  };

  const buscarEntidad = (e) => {
    setSearch(e.target.value);
  };

  // -----------UseEffects----------------
  useEffect(() => {
    getEntidad();
  }, []);

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: '1.2rem', textAlign: 'center' }}
        pauseOnHover
      />

      <Box
        scrollBehavior={'smooth'}
        bg={'#e5e5e5'}
        height={'auto'}
        maxHeight={'100vh'}
        overflowY={'auto'}
        css={{
          '&::-webkit-scrollbar': {
            width: '12px'
          },
          '&::-webkit-scrollbar-track': {
            background: '#6b6b6b',
            borderRadius: '0px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#9b9b9b',
            borderRadius: '6px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#9b9b9b'
          }
        }}>
        <Box
          as='section'
          display={{
            '2xl': 'unset',
            xl: 'unset',
            lg: 'none',
            md: 'none',
            sm: 'none'
          }}>
          <SidebarOperators />
        </Box>
        <Drawer
          isOpen={isOpenBar}
          onClose={onCloseBar}
          placement='left'>
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w='full' borderRight='none' />
          </DrawerContent>
        </Drawer>

        <Box bg={'#e5e5e5'} h={'100vh'}>
          <Flex
            alignItems={'center'}
            justify={'flex-start'}
            // h={'100%'}
            sx={{
              '@media only screen and (min-width: 1200px) and (max-width: 1366px)':
                {
                  padding: '1.5% 1.5% 1.5% 19%'
                }
            }}
            p={{
              '2xl': '1.5% 3% 1.5% 15%',
              xl: '1.5% 3% 1.5% 18%',
              lg: '1.5% 2% 1.5% 2%',
              md: '1.5% 2% 1.5% 2%'
            }}
            // marginLeft={"15%"}
            // marginRight="2%"
            // marginBottom="1%"
            direction='column'
            gap={'25px'}>
            <Box transition='.3s ease' width={'100%'}>
              <Flex
                gap='2%'
                alignItems={'center'}
                as='header'
                w='full'
                h='14'>
                <IconButton
                  aria-label='Menu'
                  display={{
                    '2xl': 'none',
                    xl: 'none',
                    lg: 'flex',
                    md: 'flex',
                    sm: 'flex'
                  }}
                  background={'#292F36'}
                  color={'#ffffff'}
                  borderRadius={'4px'}
                  fontSize={'1.3rem'}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size='md'
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      color={'#707070'}
                      fontSize={'1.5rem'}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={"bold"}
                      textTransform={"uppercase"}
                      color={"#292F36"}
                      fontSize={"1.5rem"}
                    >
                      Entidad
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>
            {/* ------------------------------------Content ----------------------------------------- */}

            {ListFilteredEntidad().length == 0 ? (
              <Flex
                w={'100%'}
                h='100%'
                bg={'white'}
                borderRadius='10px'
                boxShadow={'rgba(0, 0, 0, 0.16) 0px 1px 4px'}
                padding={'3%'}
                align={'center'}
                justifyContent='center'>
                <Text
                  fontSize={'2rem'}
                  textTransform={'uppercase'}
                  fontWeight={'600'}
                  letterSpacing={'1.15px'}>
                  {' '}
                  Ninguna entidad
                </Text>
              </Flex>
            ) : (
              <Flex
                w={"100%"}
                direction={"column"}
                gap={"25px"}
                justifyContent={"center"}
              >
                {ListFilteredEntidad()?.map((itemSala) => {
                  return (
                    <Flex
                      w={'100%'}
                      h='100%'
                      bg={'white'}
                      borderRadius='10px'
                      boxShadow={'rgba(0, 0, 0, 0.16) 0px 1px 4px'}
                      padding={'2% 3% 3% 3%'}
                      gap={'6px'}
                      justifyContent='center'
                      direction={'column'}>
                      <Flex width={'100%'} justifyContent={'right'}>
                        <Button
                          onClick={() => {
                            entidad.id_sala = itemSala.sala.id_sala;
                            onOpenEntidad();
                          }}
                          w={'auto'}
                          height={'40px'}
                          borderRadius='8px'
                          color={'white'}
                          fontSize={'1.6rem'}
                          bg='#2a2e6e'
                          _hover={{
                            color: 'white',
                            bg: '#f59f2c'
                          }}>
                          Nueva Entidad
                        </Button>
                      </Flex>

                      <Accordion w={'100%'}>
                        <AccordionItem
                          w={'100%'}
                          borderStyle={'none'}
                          justifyContent={'center'}>
                          <AccordionButton
                            paddingRight={'2%'}
                            color={'#ffffff'}
                            _hover={{
                              bg: '#292F36'
                            }}
                            _focus={{
                              outline: 'none'
                            }}
                            _dark={{
                              bg: '#292F36'
                            }}
                            cursor={'auto'}
                            background={'#292F36'}
                            boxShadow={'0 0 4px rgb(0 0 0 / 24%)'}
                            height={'45px'}
                            borderRadius={'8px'}>
                            <Table>
                              <Thead>
                                <Tr width={'100%'}>
                                  <Th
                                    color={'#ffffff'}
                                    border={'none'}
                                    width={'33%'}
                                    fontSize={'1.8rem'}>
                                    SALA
                                  </Th>
                                  <Th
                                    textAlign={'center'}
                                    color={'#ffffff'}
                                    border={'none'}
                                    width={'33%'}
                                    fontSize={'1.8rem'}></Th>
                                  <Th
                                    color={'#ffffff'}
                                    border={'none'}
                                    width={'33%'}
                                    isNumeric
                                    fontSize={'1.8rem'}></Th>
                                </Tr>
                              </Thead>
                            </Table>
                          </AccordionButton>
                        </AccordionItem>
                      </Accordion>

                      <Accordion
                        w={'100%'}
                        gap={'15px'}
                        allowMultiple>
                        <Flex
                          w={'100%'}
                          gap={'15px'}
                          direction={'column'}>
                          <AccordionItem
                            w={'100%'}
                            borderStyle={'none'}
                            justifyContent={'center'}>
                            <AccordionButton
                              _hover={{
                                bg: 'white'
                              }}
                              _focus={{
                                outline: 'none'
                              }}
                              _dark={{
                                bg: '#292929'
                              }}
                              boxShadow={'0 0 4px rgb(0 0 0 / 24%)'}
                              bg={'white'}
                              height={'45px'}
                              borderRadius={'8px'}>
                              <Table>
                                <Tbody fontSize={'1.7rem'}>
                                  <Tr width={'100%'}>
                                    <Td
                                      width={'45.5%'}
                                      fontWeight={'600'}
                                      fontFamily='monospace'>
                                      {itemSala.sala.nombre}
                                    </Td>
                                    <Td
                                      width={'50%'}
                                      fontWeight={'600'}
                                      fontFamily='monospace'></Td>
                                    <Td
                                      width={'50%'}
                                      isNumeric
                                      fontWeight={'600'}
                                      fontFamily='monospace'></Td>
                                  </Tr>
                                </Tbody>
                              </Table>

                              <AccordionIcon fontSize={'1.6rem'} />
                            </AccordionButton>

                            <AccordionPanel
                              padding={0}
                              boxShadow={'0 0 4px rgb(0 0 0 / 24%)'}
                              bg={'white'}
                              _dark={{
                                bg: '#707070'
                              }}
                              borderRadius={'0 0 8px 8px'}
                              margin={'1px 2% 1% 2%'}>
                              <TableContainer width={'100%'}>
                                <Table width={'100%'}>
                                  <Thead>
                                    <Tr
                                      border={'none'}
                                      background={'#d9d9d9'}
                                      _dark={{
                                        bg: '#464545'
                                      }}
                                      minHeight={'28px'}>
                                      <Th
                                        border={'none'}
                                        width={'33%'}
                                        text
                                        Align={'center'}
                                        fontSize={'1.6rem'}
                                        _dark={{
                                          color: 'white'
                                        }}
                                        color={'#13235b'}>
                                        ID
                                      </Th>
                                      <Th
                                        border={'none'}
                                        width={'33%'}
                                        textAlign={'center'}
                                        fontSize={'1.6rem'}
                                        _dark={{
                                          color: 'white'
                                        }}
                                        color={'#13235b'}
                                        cursor={'pointer'}
                                        onClick={() => {
                                          let ordenDocument;
                                          if (!ordenarEntidad) {
                                            ordenDocument =
                                              listEntity.map(
                                                (item) => {
                                                  let newSala = {
                                                    ...item.sala
                                                  }; // Crear una copia de sala para evitar mutaciones
                                                  newSala.personas.sort(
                                                    (a, b) =>
                                                      a.apellidos.localeCompare(
                                                        b.apellidos
                                                      )
                                                  );
                                                  return {
                                                    sala: newSala
                                                  };
                                                }
                                              );
                                            console.log(
                                              ordenDocument
                                            );
                                            setListEntity(
                                              ordenDocument
                                            );
                                            setOrdenaEntidad(true);
                                          } else {
                                            ordenDocument =
                                              listEntity.map(
                                                (item) => {
                                                  let newSala = {
                                                    ...item.sala
                                                  }; // Crear una copia de sala para evitar mutaciones
                                                  newSala.personas.sort(
                                                    (a, b) =>
                                                      b.apellidos.localeCompare(
                                                        a.apellidos
                                                      )
                                                  );
                                                  return {
                                                    sala: newSala
                                                  };
                                                }
                                              );
                                            setListEntity(
                                              ordenDocument
                                            );
                                            setOrdenaEntidad(false);
                                          }
                                        }}>
                                        APELLIDOS
                                      </Th>
                                      <Th
                                        border={'none'}
                                        width={'33%'}
                                        textAlign={'center'}
                                        fontSize={'1.6rem'}
                                        _dark={{
                                          color: 'white'
                                        }}
                                        color={'#13235b'}>
                                        NOMBRES
                                      </Th>
                                      <Th
                                        border={'none'}
                                        width={'33%'}
                                        textAlign={'center'}
                                        fontSize={'1.6rem'}
                                        _dark={{
                                          color: 'white'
                                        }}
                                        color={'#13235b'}>
                                        TELEFONO
                                      </Th>
                                      <Th
                                        border={'none'}
                                        width={'33%'}
                                        textAlign={'center'}
                                        fontSize={'1.6rem'}
                                        _dark={{
                                          color: 'white'
                                        }}
                                        color={'#13235b'}
                                        cursor={'pointer'}
                                        onClick={() => {
                                          let ordenDocument;
                                          if (!ordenarEntidad) {
                                            ordenDocument =
                                              listEntity.map(
                                                (item) => {
                                                  let newSala = {
                                                    ...item.sala
                                                  }; // Crear una copia de sala para evitar mutaciones
                                                  newSala.personas.sort(
                                                    (a, b) =>
                                                      a.correo.localeCompare(
                                                        b.correo
                                                      )
                                                  );
                                                  return {
                                                    sala: newSala
                                                  };
                                                }
                                              );
                                            console.log(
                                              ordenDocument
                                            );
                                            setListEntity(
                                              ordenDocument
                                            );
                                            setOrdenaEntidad(true);
                                          } else {
                                            ordenDocument =
                                              listEntity.map(
                                                (item) => {
                                                  let newSala = {
                                                    ...item.sala
                                                  }; // Crear una copia de sala para evitar mutaciones
                                                  newSala.personas.sort(
                                                    (a, b) =>
                                                      b.correo.localeCompare(
                                                        a.correo
                                                      )
                                                  );
                                                  return {
                                                    sala: newSala
                                                  };
                                                }
                                              );
                                            setListEntity(
                                              ordenDocument
                                            );
                                            setOrdenaEntidad(false);
                                          }
                                        }}
                                        >
                                        CORREO
                                      </Th>
                                      <Th
                                        border={'none'}
                                        width={'33%'}
                                        textAlign={'center'}
                                        fontSize={'1.6rem'}
                                        _dark={{
                                          color: 'white'
                                        }}
                                        color={'#13235b'}>
                                        TIPO DOCUMENTO
                                      </Th>
                                      <Th
                                        border={'none'}
                                        width={'33%'}
                                        textAlign={'center'}
                                        fontSize={'1.6rem'}
                                        _dark={{
                                          color: 'white'
                                        }}
                                        color={'#13235b'}>
                                        DOCUMENTO
                                      </Th>
                                      <Th
                                        border={'none'}
                                        width={'33%'}
                                        textAlign={'center'}
                                        fontSize={'1.6rem'}
                                        _dark={{
                                          color: 'white'
                                        }}
                                        color={'#13235b'}>
                                        FECHA NACIMIENTO
                                      </Th>
                                      <Th
                                        border={'none'}
                                        width={'33%'}
                                        textAlign={'center'}
                                        fontSize={'1.6rem'}
                                        _dark={{
                                          color: 'white'
                                        }}
                                        color={'#13235b'}>
                                        ACCIONES
                                      </Th>
                                    </Tr>
                                  </Thead>

                                  <Tbody bg={'transparent'}>
                                    {itemSala.sala.personas.map(
                                      (itemEntidad, index) => {
                                        if (itemEntidad.estado == 1) {
                                          return (
                                            <Tr border={'none'}>
                                              <Td
                                                border={'none'}
                                                width={'33%'}
                                                // textAlign={'center'}
                                                fontSize={'1.7rem'}
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'#13235b'}>
                                                {
                                                  index+1
                                                }
                                              </Td>
                                              <Td
                                                border={'none'}
                                                width={'33%'}
                                                textAlign={'center'}
                                                fontSize={'1.7rem'}
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'#13235b'}>
                                                {
                                                  itemEntidad.apellidos
                                                }
                                              </Td>
                                              <Td
                                                border={'none'}
                                                width={'33%'}
                                                textAlign={'center'}
                                                fontSize={'1.7rem'}
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'#13235b'}>
                                                {itemEntidad.nombres}
                                              </Td>
                                              <Td
                                                border={'none'}
                                                width={'33%'}
                                                textAlign={'center'}
                                                fontSize={'1.7rem'}
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'#13235b'}>
                                                {itemEntidad.telefono}
                                              </Td>
                                              <Td
                                                border={'none'}
                                                width={'33%'}
                                                textAlign={'center'}
                                                fontSize={'1.7rem'}
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'#13235b'}>
                                                {itemEntidad.correo}
                                              </Td>
                                              <Td
                                                border={'none'}
                                                width={'33%'}
                                                textAlign={'center'}
                                                fontSize={'1.7rem'}
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'#13235b'}>
                                                {
                                                  itemEntidad.tipo_documento
                                                }
                                              </Td>
                                              <Td
                                                border={'none'}
                                                width={'33%'}
                                                textAlign={'center'}
                                                fontSize={'1.7rem'}
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'#13235b'}>
                                                {
                                                  itemEntidad.documento
                                                }
                                              </Td>
                                              <Td
                                                border={'none'}
                                                width={'33%'}
                                                textAlign={'center'}
                                                fontSize={'1.7rem'}
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'#13235b'}>
                                                {
                                                  itemEntidad.fecha_nacimiento
                                                }
                                              </Td>

                                              <Td
                                                border={'none'}
                                                width={'33%'}
                                                textAlign={'center'}
                                                fontSize={'1.6rem'}
                                                _dark={{
                                                  color: 'white'
                                                }}
                                                color={'#13235b'}>
                                                <Flex
                                                  justifyContent={
                                                    'center'
                                                  }
                                                  gap={'4%'}>
                                                  {/* ------------------------------------------------- */}

                                                  <Tooltip
                                                    hasArrow
                                                    borderRadius={
                                                      '4px'
                                                    }
                                                    fontSize={
                                                      '1.2rem'
                                                    }
                                                    label='Editar'
                                                    bg='#292F36'>
                                                    <IconButton
                                                      onClick={() => {
                                                        setupdateEntidad(
                                                          itemEntidad
                                                        );
                                                        onOpenEditEntidad();
                                                      }}
                                                      size={'md'}
                                                      borderRadius={
                                                        '4px'
                                                      }
                                                      fontSize={
                                                        '1.5rem'
                                                      }
                                                      background={
                                                        '#f59f2c'
                                                      }
                                                      color={
                                                        '#ffffff'
                                                      }
                                                      aria-label='Editar'
                                                      icon={
                                                        <RiEditFill />
                                                      }
                                                    />
                                                  </Tooltip>

                                                  {/* -------------------------------------------- */}

                                                  <Tooltip
                                                    hasArrow
                                                    borderRadius={
                                                      '4px'
                                                    }
                                                    fontSize={
                                                      '1.2rem'
                                                    }
                                                    label='Quitar'
                                                    bg='#292F36'>
                                                    <IconButton
                                                      onClick={() => {
                                                        quitar(
                                                          itemEntidad.id_persona
                                                        );
                                                      }}
                                                      size={'md'}
                                                      borderRadius={
                                                        '4px'
                                                      }
                                                      fontSize={
                                                        '1.5rem'
                                                      }
                                                      background={
                                                        '#be0000'
                                                      }
                                                      color={
                                                        '#ffffff'
                                                      }
                                                      aria-label='Quitar'
                                                      icon={
                                                        <MdDoDisturbOff />
                                                      }
                                                    />
                                                  </Tooltip>
                                                </Flex>
                                              </Td>
                                            </Tr>
                                          );
                                        }
                                      }
                                    )}
                                  </Tbody>
                                </Table>
                              </TableContainer>
                            </AccordionPanel>
                          </AccordionItem>
                        </Flex>
                      </Accordion>
                    </Flex>
                  );
                })}
              </Flex>
            )}
          </Flex>
        </Box>
      </Box>

      {/* -------------------Modal Crear Entidad----------------------------- */}
      <Modal
        isOpen={isOpenEntidad}
        onClose={onCloseEntidad}
        isCentered
        alignItems='center'
        display='flex'
        height='100%'>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '30%', xl: '45%', lg: '65%', sm: '80%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Nueva Entidad
          </ModalHeader>
          <ModalCloseButton fontSize={'1.4rem'} />
          <ModalBody>
            <Flex
              direction={'column'}
              alignItems='center'
              gap='20px'
              w={'100%'}
              h='100%'>
              <Flex w={'100%'} gap={'15px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Nombres:
                  </FormLabel>
                  <Input
                    name='nombres'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={changeEntidad}
                    value={entidad.nombres}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Apellidos:
                  </FormLabel>
                  <Input
                    name='apellidos'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={changeEntidad}
                    value={entidad.apellidos}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'15px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Fecha de Nacimiento:
                  </FormLabel>
                  <Input
                    name='fecha_nacimiento'
                    fontSize={'1.8rem'}
                    h='35'
                    type='date'
                    onChange={changeEntidad}
                    value={entidad.fecha_nacimiento}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Telefono:
                  </FormLabel>
                  <Input
                    name='telefono'
                    fontSize={'1.8rem'}
                    h='35'
                    type='number'
                    onChange={changeEntidad}
                    value={entidad.telefono}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'15px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Tipo de Documento:
                  </FormLabel>
                  <Select
                    onChange={changeEntidad}
                    value={entidad.tipo_documento}
                    name='tipo_documento'
                    w='100%'
                    fontSize={'1.6rem'}
                    height={'35px'}
                    fontFamily='monospace'
                    fontWeight={'600'}
                    placeholder='Seleccionar'
                    textTransform='uppercase'
                    variant='filled'>
                    <option value='DNI'>DNI</option>
                    <option value='RUC'>RUC</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Documento:
                  </FormLabel>
                  <Input
                    name='documento'
                    fontSize={'1.8rem'}
                    h='35'
                    type='number'
                    onChange={changeEntidad}
                    value={entidad.documento}
                  />
                </FormControl>
              </Flex>
              <FormControl>
                <FormLabel fontSize={'2rem'} fontWeight='500'>
                  Correo:
                </FormLabel>
                <Input
                  name='correo'
                  fontSize={'1.8rem'}
                  h='35'
                  type='text'
                  onChange={changeEntidad}
                  value={entidad.correo}
                />
              </FormControl>

              <Button
                onClick={crearEntidad}
                textTransform='uppercase'
                fontSize={'1.6rem'}
                p='3% 4%'>
                Guardar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* -------------------Modal Editar Entidad----------------------------- */}
      <Modal
        isOpen={isOpenEditEntidad}
        onClose={onCloseEditEntidad}
        isCentered
        alignItems='center'
        display='flex'
        height='100%'>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '30%', xl: '45%', lg: '65%', sm: '80%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Editar Entidad
          </ModalHeader>
          <ModalCloseButton fontSize={'1.4rem'} />
          <ModalBody>
            <Flex
              direction={'column'}
              alignItems='center'
              gap='20px'
              w={'100%'}
              h='100%'>
              <Flex w={'100%'} gap={'15px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Nombres:
                  </FormLabel>
                  <Input
                    name='nombres'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={changeUpdate}
                    value={updateEntidad.nombres}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Apellidos:
                  </FormLabel>
                  <Input
                    name='apellidos'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={changeUpdate}
                    value={updateEntidad.apellidos}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'15px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Fecha de Nacimiento:
                  </FormLabel>
                  <Input
                    name='fecha_nacimiento'
                    fontSize={'1.8rem'}
                    h='35'
                    type='date'
                    onChange={changeUpdate}
                    value={updateEntidad.fecha_nacimiento}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Telefono:
                  </FormLabel>
                  <Input
                    name='telefono'
                    fontSize={'1.8rem'}
                    h='35'
                    type='number'
                    onChange={changeUpdate}
                    value={updateEntidad.telefono}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'15px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Tipo de Documento:
                  </FormLabel>
                  <Select
                    onChange={changeUpdate}
                    value={updateEntidad.tipo_documento}
                    name='tipo_documento'
                    w='100%'
                    fontSize={'1.6rem'}
                    height={'35px'}
                    fontFamily='monospace'
                    fontWeight={'600'}
                    placeholder='Seleccionar'
                    textTransform='uppercase'
                    variant='filled'>
                    <option value='DNI'>DNI</option>
                    <option value='RUC'>RUC</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Documento:
                  </FormLabel>
                  <Input
                    name='documento'
                    fontSize={'1.8rem'}
                    h='35'
                    type='number'
                    onChange={changeUpdate}
                    value={updateEntidad.documento}
                  />
                </FormControl>
              </Flex>

              <FormControl>
                <FormLabel fontSize={'2rem'} fontWeight='500'>
                  Correo:
                </FormLabel>
                <Input
                  name='correo'
                  fontSize={'1.8rem'}
                  h='35'
                  type='text'
                  onChange={changeUpdate}
                  value={updateEntidad.correo}
                />
              </FormControl>

              <Button
                onClick={editarEntidad}
                textTransform='uppercase'
                fontSize={'1.6rem'}
                p='3% 4%'>
                Guardar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Entidad;
