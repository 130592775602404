import React, { useState, useEffect } from 'react';
import { Flex, Image, Text, Button } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

export default function DIncompatible() {
  let history = useHistory();
  return (
    <>
      <Flex
        w={'100%'}
        h={'100vh'}
        p={'5%'}
        bgColor={'#F1F1F1'}
        alignItems={'center'}
        justifyContent={'center'}
        direction={'column'}>
        <Flex
          direction={'column'}
          gap={['10px', '45px', '15px', '15px', '15px']}
          align={'center'}
          justify={'center'}>
          <Image
            w={'33%'}
            src='https://res.cloudinary.com/ddsfttqtk/image/upload/v1694704404/T-Money/dispositivo_incompatible_a1lhfj.png'></Image>
          <Flex
            direction={'column'}
            align={'center'}
            gap={['10px', '15px', '15px', '15px', '15px']}>
            <Flex
              direction={'column'}
              align={'center'}
              gap={'10px'}
              justify={'center'}
              textAlign={'center'}
              textColor={'#0A1A4A'}>
              <Text
                letterSpacing={'1.5px'}
                fontWeight={'500'}
                fontFamily={'fantasy'}
                fontSize={'2rem'}>
                Dispositivo incompatible
              </Text>
              <Text
                w={'90%'}
                lineHeight={'1'}
                fontWeight={"200"}
                fontFamily={'monospace'}
                fontSize={'1.6rem'}>
                La función a la que estás intentando acceder
                actualmente no es compatible <br /> con este modo de
                vista.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
