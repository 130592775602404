import React from 'react';
import './../../assets/css/styles.css';
// Chakra imports
import { Box, useMediaQuery } from '@chakra-ui/react';
import FormContent from './components/Formcontent';
import DIncompatible from './components/DIncompatible';

function LoginView() {
  const wrapper = React.createRef();
  const navRef = React.useRef();
  const [resolucionM] = useMediaQuery(
    '(min-width: 100px) and (max-width: 425px)'
  );
  return (
    <Box ref={navRef} w='100%'>

{resolucionM ? (
      <DIncompatible />
    ) : (
      <Box w='100%'>
        <Box ref={wrapper} w='100%'>
          <FormContent />
        </Box>
      </Box>
      // <Box px='24px' mx='auto' width='1044px' maxW='100%'></Box>
    )}
     
    </Box>
  );
}

export default LoginView;
