import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import global_es from '../src/translations/es/global.json';
import global_po from '../src/translations/po/global.json';
import global_en from '../src/translations/en/global.json';
import theme from './theme/theme';
import LanguageDectector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18next
  .use(Backend)
  .use(LanguageDectector)
  .use(initReactI18next)
  .init({
    resources: {
      fallbackLng: 'es',
      es: {
        global: global_es
      },
      po: {
        global: global_po
      },
      en: {
        global: global_en
      }
    }
  });

const root = createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider theme={theme} resetCss={false}>
    <App />
  </ChakraProvider>
);