import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  // ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  // Highlight,
  // Avatar,
  // HStack,
  // Link,
  IconButton,
  Button,
  // Menu,
  // MenuButton,
  // MenuList,
  // MenuItem,
  // Container,
  // Img,
  // MenuDivider,
  useDisclosure,
  // useColorModeValue,
  Stack,
  // MenuGroup,
  // Popover,
  // Divider,
  // PopoverTrigger,
  // PopoverContent,
  // PopoverArrow,
  // NavItem,
  // Collapse,
  // Text,
  // Badge,
  Icon,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tooltip,
  // Tfoot,
  Tr,
  Th,
  Td,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  // TableCaption,
  TableContainer,
  FormControl,
  FormLabel,
  // FormErrorMessage,
  Select,
  // FormHelperText,
  // Tabs,
  // TabList,
  // TabPanels,
  // Tab,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
  AccordionItem,
  Accordion,
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

import {
  RiEditFill,
  // RiInformationFill,
  // RiUserAddFill
} from "react-icons/ri";
import {
  MdDoDisturbOff,
  // MdCancel,
  // MdGroupAdd,
  MdAssignmentAdd,
  // MdRemoveCircle
} from "react-icons/md";
// import { Search2Icon } from '@chakra-ui/icons';
// import { RiFileExcel2Fill } from 'react-icons/ri';
// import { EditIcon } from '@chakra-ui/icons';
// import { FaEdit } from 'react-icons/fa';
import { FiMenu } from "react-icons/fi";
// import { utils, writeFile } from 'xlsx';
import { useHistory } from "react-router-dom";
import moment from "moment";
import SidebarOperators from "../../../components/SidebarOperators";
import axios from "axios";
import {
  IoMdArrowDroprightCircle,
  IoMdArrowDropleftCircle,
} from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";
import { host3 } from "../../../utils/constantes";
import jwt_decode from "jwt-decode";

function SportTrader(props) {
  let history = useHistory();
  const token = localStorage.getItem("tokenadmin");
  const { id_persona } = jwt_decode(token);
  if (!token) {
    history.push("/");
  }

  //Mensaje
  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        break;
    }
  };

  // ---------------------------------Ordenar-------------------------------

  const [ordenarSport, setOrdenarSport] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");

  // ---------------------------------------------------------------------

  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar,
  } = useDisclosure();
  // -------------- Modal Nuevo SportTrader-------------------------
  const {
    isOpen: isOpenCrearSport,
    onOpen: onOpenCrearSport,
    onClose: onCloseCrearSport,
  } = useDisclosure();

  // -------------- Modal Editar SportTrader-------------------------
  const {
    isOpen: isOpenEditSport,
    onOpen: onOpenEditSport,
    onClose: onCloseEditSport,
  } = useDisclosure();

  // -------------- Modal Asignar modulo SportTrader-------------------------
  const {
    isOpen: isOpenAsignarSport,
    onOpen: onOpenAsignarSport,
    onClose: onCloseAsignarSport,
  } = useDisclosure();

  // -------------- Modal Modulos asignados-------------------------
  const {
    isOpen: isOpenAsignadosSport,
    onOpen: onOpenAsignadosSport,
    onClose: onCloseAsignadosSport,
  } = useDisclosure();

  // -----------------List Sporttrader-----------------------

  // Listar sport trader

  const fetchSportTrader = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/salas/${token}/list-sportrader/${id_persona}`
      );
      setListSporttrader(data);
    } catch (error) {
      setListSporttrader([]);
    }
  };

  const [sporttrader, setSporttrader] = useState({
    nombres: "",
    apellidos: "",
    fecha_nacimiento: "",
    telefono: "",
    tipo_documento: "",
    documento: "",
    correo: "",
    roles: [{ id: 5, nombre: "SPORTRADER" }],
  });

  const changeSportTrader = (e) => {
    const { name, value } = e.target;
      if (name === 'documento') {
        const maxDigits = sporttrader.tipo_documento === 'DNI' ? 8 : sporttrader.tipo_documento === 'RUC' ? 11 : null;
      if (maxDigits !== null && !(/^\d{0,}$/.test(value) && value.length <= maxDigits)) {
        return;
    }
  }
    setSporttrader({
      ...sporttrader,
      [e.target.name]: e.target.value,
    });
  };

  // -----------------Crear Sporttrader-----------------------

  const crearSportTrader = async (e) => {
    e.preventDefault();
    try {
      const { status, data } = await axios.post(
        `${host3}/api/personas/${token}`,
        sporttrader
      );

      if (status === 201) {
        mensaje(data.message, "top-right", 1);
        onCloseCrearSport();
        setSporttrader({
          apellidos: "",
          nombres: "",
          telefono: "",
          correo: "",
          tipo_documento: "",
          documento: "",
          fecha_nacimiento: "",
          roles: [{ id: 5, nombre: "SPORTRADER" }],
        });
        fetchSportTrader();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  // -------------Editar SportTrader--------------------

  const [updateSportTrader, setSportTrader] = useState({
    apellidos: "",
    nombres: "",
    telefono: "",
    correo: "",
    tipo_documento: "",
    documento: "",
    fecha_nacimiento: "",
    roles: [],
  });

  const changeUpdate = (e) => {
    const maxDigits = updateSportTrader.tipo_documento === 'DNI' ? 8 : updateSportTrader.tipo_documento === 'RUC' ? 11 : null;
    if (e.target.name === 'documento' && maxDigits !== null) {
      const inputValue = e.target.value;
      if (!(/^\d{0,}$/.test(inputValue) && inputValue.length <= maxDigits)) {
        return;
      }
    }
    setSportTrader({
      ...updateSportTrader,
      [e.target.name]: e.target.value,
    });
  };

  const editarSportTrader = async (e, rol_id) => {
    e.preventDefault();
    try {
      updateSportTrader.roles = [{ id: 5, nombre: "SPORTRADER" }];
      const { data, status } = await axios.put(
        `${host3}/api/personas/${token}/${updateSportTrader.id_persona}`,
        updateSportTrader
      );
      if (status === 201) {
        mensaje(data.message, "top-right", 1);
        onCloseEditSport();
        setSportTrader({
          apellidos: "",
          nombres: "",
          telefono: "",
          correo: "",
          tipo_documento: "",
          documento: "",
          fecha_nacimiento: "",
          roles: [],
        });
        fetchSportTrader();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
      //console.log(error);
    }
  };

  //--------------------QUITAR-----------------------------

  const quitar = async (id, action) => {
    try {
      const { status, data } = await axios.put(
        `${host3}/api/personas/${token}/${id}/accion/${action}`
      );
      if (status == 201) {
        mensaje(data.message, "top-right", 1);
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
    fetchSportTrader();
  };

  //-------------------MODULO------------------------------
  const [asignation, setAsignation] = useState({
    id_persona: "",
    id_modulo: "",
  });
  const [assignedModule, setAssignedModule] = useState(null);
  const [listModule, setListModule] = useState([]);
  const [selectIdHall, setSelectIdHall] = useState(null);

  const fetchModule = async () => {
    try {
      const { data } = await axios.get(
        `${host3}/api/modulos/${token}/list-modules-for-hall`
      );

      const dataFilter = data.filter(
        (itemFilter) => itemFilter.id_sala === selectIdHall
      );

      let dataItem = [];

      dataFilter.map((itemData) => {
        itemData.modulos.map((itemModulo) => dataItem.push(itemModulo));
      });

      setListModule(dataItem);
    } catch (error) {
      console.log(error);
    }
  };

  //---------------------ASIGNAR-------------------
  const handleSubmitAsignate = async () => {
    try {
      const { status, data } = await axios.post(
        `${host3}/api/operador-modulos/${token}`,
        asignation
      );

      if (status === 201) {
        mensaje(data.message, "top-right", 1);
        fetchSportTrader();
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 2);
    }
  };

  //-----------------------OBTENER MODULOS-------------------
    
  const getAssignedModules = async (id_person) => {
      try {
        const { status, data } = await axios.get(
          `${host3}/api/operador-modulos/${token}/get-assigned-modules/${id_person}`
        );
        if (status === 200) {
          console.log(data);
          setAssignedModule(data);
        } else {
          mensaje(data.message, "top-right", 2);
        }
      } catch (error) {
        mensaje(error.response.data.message, "top-right", 2);
      }
    };
    
  
  //---------------------ELIMINAR ASIGNACION TOTAL-------------------
  const handleSubmitAsignateDelete = async (id) => {
    try {
      const { status, data } =  await axios.delete(
        `${host3}/api/operador-modulos/${token}/delete/${id}`
      );

      if(status == 201){
        mensaje(data.message, "top-right", 1);
        fetchSportTrader()
      }
    } catch (error) {
      mensaje(error.response.data.message, "top-right", 3);
    }
  };
  //---------------------BUSCAR-------------------

  const buscar = (e) => {
    setSearch(e.target.value);
  };
   useEffect(() => {
    fetchModule();
  }, [selectIdHall]);

  //Paginacion

  const [search, setSearch] = useState("");
  const [listAdministrador, setListAdministrador] = useState([]);
  const [listSporttrader, setListSporttrader] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const nextPage = () => {
    const cant = listAdministrador.filter((p) =>
      p.id_colaborador.toString().includes(search)
    ).length;

    if (cant > currentPage) {
      setCurrentPage(currentPage + 13);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 13);
    }
  };

  // ---------------------------

  // Listar sport trader
  const ListFilteredSportTrader = () => {
    if (search) {
      return listSporttrader
        .map((s) => {
          return {
            ...s,
            sala: {
              ...s.sala,
              personas: s.sala.personas.filter((t) =>
                [
                  "nombres",
                  "apellidos",
                  "telefono",
                  "correo",
                  "documento",
                ].some((prop) =>
                  t[prop]
                    ?.toString()
                    .toLowerCase()
                    .includes(search.toLowerCase())
                )
              ),
            },
          };
        })
        .slice(currentPage, currentPage + 8);
    }

    if (selectedMonth != "") {
      return listSporttrader
        .map((s) => {
          return {
            ...s,
            sala: {
              ...s.sala,
              personas: s.sala.personas.filter((t) => {
                // Filtrar por mes de nacimiento
                const birthMonthMatches = selectedMonth
                  ? new Date(t.fecha_nacimiento).getMonth() + 1 ===
                    +selectedMonth
                  : true;

                return birthMonthMatches;
              }),
            },
          };
        })
        .slice(currentPage, currentPage + 8);
    }
    return listSporttrader?.slice(currentPage, currentPage + 8);
  };

  const buscarCasas = (e) => {
    setSearch(e.target.value);
  };

  // -----------UseEffects----------------
  useEffect(() => {
    fetchSportTrader();
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: "1.2rem", textAlign: "center" }}
        pauseOnHover
      />

      <Box
        scrollBehavior={"smooth"}
        bg={"#e5e5e5"}
        height={"auto"}
        maxHeight={"100vh"}
        overflowY={"auto"}
        css={{
          "&::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#6b6b6b",
            borderRadius: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#9b9b9b",
            borderRadius: "6px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#9b9b9b",
          },
        }}
      >
        <Box
          as="section"
          display={{
            "2xl": "unset",
            xl: "unset",
            lg: "none",
            md: "none",
            sm: "none",
          }}
        >
          <SidebarOperators />
        </Box>
        <Drawer isOpen={isOpenBar} onClose={onCloseBar} placement="left">
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w="full" borderRight="none" />
          </DrawerContent>
        </Drawer>

        <Box bg={"#e5e5e5"} h={"100vh"}>
          <Flex
            alignItems={"center"}
            justify={"flex-start"}
            // h={'100%'}
            sx={{
              "@media only screen and (min-width: 1200px) and (max-width: 1366px)":
                {
                  padding: "1.5% 1.5% 1.5% 19%",
                },
            }}
            p={{
              "2xl": "1.5% 3% 1.5% 15%",
              xl: "1.5% 3% 1.5% 18%",
              lg: "1.5% 2% 1.5% 2%",
              md: "1.5% 2% 1.5% 2%",
            }}
            // marginLeft={"15%"}
            // marginRight="2%"
            // marginBottom="1%"
            direction="column"
            gap={"25px"}
          >
            <Box transition=".3s ease" width={"100%"}>
              <Flex gap="2%" alignItems={"center"} as="header" w="full" h="14">
                <IconButton
                  aria-label="Menu"
                  display={{
                    "2xl": "none",
                    xl: "none",
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                  }}
                  background={"#292F36"}
                  color={"#ffffff"}
                  borderRadius={"4px"}
                  fontSize={"1.3rem"}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size="md"
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink color={"#707070"} fontSize={"1.5rem"}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={"bold"}
                      color={"#292F36"}
                      fontSize={"1.5rem"}
                    >
                      SPORT TRADER
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>
            {/* ------------------------------------Content ----------------------------------------- */}

            <Flex
              w={"100%"}
              direction={"column"}
              gap={"25px"}
              justifyContent={"center"}
            >
              {ListFilteredSportTrader().map((s) => {
                return (
                  <Flex
                    w={"100%"}
                    h="100%"
                    bg={"white"}
                    borderRadius="10px"
                    boxShadow={"rgba(0, 0, 0, 0.16) 0px 1px 4px"}
                    padding={"2% 3% 3% 3%"}
                    gap={"6px"}
                    justifyContent="center"
                    direction={"column"}
                  >
                    <Flex width={"100%"} justifyContent={"space-between"}>
                      <Flex width={"30%"}>
                        <Stack w={"100%"}>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={
                                <Search2Icon
                                  color="#2a2e6e"
                                  marginLeft={"20%"}
                                  fontSize={"1.25rem"}
                                  marginTop={"60%"}
                                  _dark={{
                                    color: "#f59f2c",
                                  }}
                                />
                              }
                            />
                            <Input
                              onChange={buscar}
                              type="text"
                              placeholder="Buscar"
                              bg={"white"}
                              height={"40px"}
                              _focus={{
                                borderColor: "#2a2e6e",
                                boxShadow: "none",
                              }}
                              color="#2a2e6e"
                              fontSize={"1.9rem"}
                              border={"1px solid"}
                              borderColor={"#2a2e6e"}
                              borderRadius="6px"
                              _placeholder={{
                                color: "rgb(152, 152, 152)",
                              }}
                              _dark={{
                                color: "white",
                                borderColor: "#686868",
                                bg: "#686868",
                              }}
                            />
                          </InputGroup>
                        </Stack>
                      </Flex>

                      <Flex
                        w={{
                          "2xl": "20%",
                          xl: "25%",
                          lg: "30%",
                          md: "30%",
                        }}
                      >
                        <Select
                          value={selectedMonth}
                          onChange={(e) => setSelectedMonth(e.target.value)}
                          w="100%"
                          height={"40px"}
                          fontFamily="monospace"
                          fontWeight={"600"}
                          textTransform="uppercase"
                          variant="filled"
                          name="sala"
                          border={"1px solid"}
                          borderColor={"#2a2e6e"}
                          bgColor={"white"}
                          fontSize={"1.8rem"}
                          h="35"
                        >
                          <option value="" disabled selected>
                            Fecha Nacimiento
                          </option>
                          <option value="1">Enero</option>
                          <option value="2">Febrero</option>
                          <option value="3">Marzo</option>
                          <option value="4">Abril</option>
                          <option value="5">Mayo</option>
                          <option value="6">Junio</option>
                          <option value="7">Julio</option>
                          <option value="8">Agosto</option>
                          <option value="9">Septiembre</option>
                          <option value="10">Octubre</option>
                          <option value="11">Noviembre</option>
                          <option value="12">Diciembre</option>
                        </Select>
                      </Flex>

                      <Button
                        onClick={() => {
                          sporttrader.id_sala = s.sala.id_sala;
                          onOpenCrearSport();
                        }}
                        w={"auto"}
                        height={"40px"}
                        borderRadius="8px"
                        color={"white"}
                        fontSize={"1.6rem"}
                        bg="#2a2e6e"
                        _hover={{
                          color: "white",
                          bg: "#f59f2c",
                        }}
                      >
                        Nuevo SportTrader
                      </Button>
                    </Flex>

                    <Accordion w={"100%"}>
                      <AccordionItem
                        w={"100%"}
                        borderStyle={"none"}
                        justifyContent={"center"}
                      >
                        <AccordionButton
                          paddingRight={"2%"}
                          color={"#ffffff"}
                          _hover={{
                            bg: "#292F36",
                          }}
                          _focus={{
                            outline: "none",
                          }}
                          _dark={{
                            bg: "#292F36",
                          }}
                          cursor={"auto"}
                          background={"#292F36"}
                          boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                          height={"45px"}
                          borderRadius={"8px"}
                        >
                          <Table>
                            <Thead>
                              <Tr width={"100%"}>
                                <Th
                                  color={"#ffffff"}
                                  border={"none"}
                                  width={"33%"}
                                  fontSize={"1.8rem"}
                                >
                                  SALA
                                </Th>
                                <Th
                                  textAlign={"center"}
                                  color={"#ffffff"}
                                  border={"none"}
                                  width={"33%"}
                                  fontSize={"1.8rem"}
                                ></Th>
                                <Th
                                  color={"#ffffff"}
                                  border={"none"}
                                  width={"33%"}
                                  isNumeric
                                  fontSize={"1.8rem"}
                                ></Th>
                              </Tr>
                            </Thead>
                          </Table>
                        </AccordionButton>
                      </AccordionItem>
                    </Accordion>

                    <Accordion w={"100%"} gap={"15px"} allowMultiple>
                      <Flex w={"100%"} gap={"15px"} direction={"column"}>
                        <AccordionItem
                          w={"100%"}
                          borderStyle={"none"}
                          justifyContent={"center"}
                        >
                          <AccordionButton
                            _hover={{
                              bg: "white",
                            }}
                            _focus={{
                              outline: "none",
                            }}
                            _dark={{
                              bg: "#292929",
                            }}
                            boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                            bg={"white"}
                            height={"45px"}
                            borderRadius={"8px"}
                          >
                            <Table>
                              <Tbody fontSize={"1.7rem"}>
                                <Tr width={"100%"}>
                                  <Td
                                    width={"45.5%"}
                                    fontWeight={"600"}
                                    fontFamily="monospace"
                                  >
                                    {s.sala.nombre}
                                  </Td>
                                  <Td
                                    width={"50%"}
                                    fontWeight={"600"}
                                    fontFamily="monospace"
                                  ></Td>
                                  <Td
                                    width={"50%"}
                                    isNumeric
                                    fontWeight={"600"}
                                    fontFamily="monospace"
                                  ></Td>
                                </Tr>
                              </Tbody>
                            </Table>

                            <AccordionIcon fontSize={"1.6rem"} />
                          </AccordionButton>

                          <AccordionPanel
                            padding={0}
                            boxShadow={"0 0 4px rgb(0 0 0 / 24%)"}
                            bg={"white"}
                            _dark={{
                              bg: "#707070",
                            }}
                            borderRadius={"0 0 8px 8px"}
                            margin={"1px 2% 1% 2%"}
                          >
                            <TableContainer width={"100%"}>
                              <Table width={"100%"}>
                                <Thead>
                                  <Tr
                                    border={"none"}
                                    background={"#d9d9d9"}
                                    _dark={{
                                      bg: "#464545",
                                    }}
                                    minHeight={"28px"}
                                  >
                                    <Th
                                      border={"none"}
                                      width={"33%"}
                                      text
                                      Align={"center"}
                                      fontSize={"1.6rem"}
                                      _dark={{
                                        color: "white",
                                      }}
                                      color={"#13235b"}
                                    >
                                      ID
                                    </Th>
                                    <Th
                                      border={"none"}
                                      width={"33%"}
                                      textAlign={"center"}
                                      fontSize={"1.6rem"}
                                      _dark={{
                                        color: "white",
                                      }}
                                      color={"#13235b"}
                                      cursor={"pointer"}
                                      onClick={() => {
                                        let ordenDocument;
                                        if (!ordenarSport) {
                                          ordenDocument = listSporttrader.map(
                                            (item) => {
                                              let newSala = {
                                                ...item.sala,
                                              }; // Crear una copia de sala para evitar mutaciones
                                              newSala.personas.sort((a, b) =>
                                                a.apellidos.localeCompare(
                                                  b.apellidos
                                                )
                                              );
                                              return {
                                                sala: newSala,
                                              };
                                            }
                                          );
                                          console.log(ordenDocument);
                                          setListSporttrader(ordenDocument);
                                          setOrdenarSport(true);
                                        } else {
                                          ordenDocument = listSporttrader.map(
                                            (item) => {
                                              let newSala = {
                                                ...item.sala,
                                              }; // Crear una copia de sala para evitar mutaciones
                                              newSala.personas.sort((a, b) =>
                                                b.apellidos.localeCompare(
                                                  a.apellidos
                                                )
                                              );
                                              return {
                                                sala: newSala,
                                              };
                                            }
                                          );
                                          setListSporttrader(ordenDocument);
                                          setOrdenarSport(false);
                                        }
                                      }}
                                    >
                                      APELLIDOS
                                    </Th>
                                    <Th
                                      border={"none"}
                                      width={"33%"}
                                      textAlign={"center"}
                                      fontSize={"1.6rem"}
                                      _dark={{
                                        color: "white",
                                      }}
                                      color={"#13235b"}
                                    >
                                      NOMBRES
                                    </Th>
                                    <Th
                                      border={"none"}
                                      width={"33%"}
                                      textAlign={"center"}
                                      fontSize={"1.6rem"}
                                      _dark={{
                                        color: "white",
                                      }}
                                      color={"#13235b"}
                                    >
                                      TELEFONO
                                    </Th>
                                    <Th
                                      border={"none"}
                                      width={"33%"}
                                      textAlign={"center"}
                                      fontSize={"1.6rem"}
                                      _dark={{
                                        color: "white",
                                      }}
                                      color={"#13235b"}
                                      cursor={"pointer"}
                                      onClick={() => {
                                        let ordenDocument;
                                        if (!ordenarSport) {
                                          ordenDocument = listSporttrader.map(
                                            (item) => {
                                              let newSala = {
                                                ...item.sala,
                                              }; // Crear una copia de sala para evitar mutaciones
                                              newSala.personas.sort((a, b) =>
                                                a.correo.localeCompare(b.correo)
                                              );
                                              return {
                                                sala: newSala,
                                              };
                                            }
                                          );
                                          console.log(ordenDocument);
                                          setListSporttrader(ordenDocument);
                                          setOrdenarSport(true);
                                        } else {
                                          ordenDocument = listSporttrader.map(
                                            (item) => {
                                              let newSala = {
                                                ...item.sala,
                                              }; // Crear una copia de sala para evitar mutaciones
                                              newSala.personas.sort((a, b) =>
                                                b.correo.localeCompare(a.correo)
                                              );
                                              return {
                                                sala: newSala,
                                              };
                                            }
                                          );
                                          setListSporttrader(ordenDocument);
                                          setOrdenarSport(false);
                                        }
                                      }}
                                    >
                                      CORREO
                                    </Th>
                                    <Th
                                      border={"none"}
                                      width={"33%"}
                                      textAlign={"center"}
                                      fontSize={"1.6rem"}
                                      _dark={{
                                        color: "white",
                                      }}
                                      color={"#13235b"}
                                    >
                                      TIPO DOCUMENTO
                                    </Th>
                                    <Th
                                      border={"none"}
                                      width={"33%"}
                                      textAlign={"center"}
                                      fontSize={"1.6rem"}
                                      _dark={{
                                        color: "white",
                                      }}
                                      color={"#13235b"}
                                    >
                                      DOCUMENTO
                                    </Th>
                                    <Th
                                      border={"none"}
                                      width={"33%"}
                                      textAlign={"center"}
                                      fontSize={"1.6rem"}
                                      _dark={{
                                        color: "white",
                                      }}
                                      color={"#13235b"}
                                    >
                                      FECHA NACIMIENTO
                                    </Th>
                                    <Th
                                      border={"none"}
                                      width={"33%"}
                                      textAlign={"center"}
                                      fontSize={"1.6rem"}
                                      _dark={{
                                        color: "white",
                                      }}
                                      color={"#13235b"}
                                    >
                                      ACCIONES
                                    </Th>
                                  </Tr>
                                </Thead>

                                <Tbody bg={"transparent"}>
                                  {s.sala.personas.map((t , index) => {
                                    return (
                                      <Tr border={"none"}>
                                        <Td
                                          border={"none"}
                                          width={"33%"}
                                          // textAlign={'center'}
                                          fontSize={"1.7rem"}
                                          _dark={{
                                            color: "white",
                                          }}
                                          color={"#13235b"}
                                        >
                                          {index+1}
                                        </Td>
                                        <Td
                                          border={"none"}
                                          width={"33%"}
                                          textAlign={"center"}
                                          fontSize={"1.7rem"}
                                          _dark={{
                                            color: "white",
                                          }}
                                          color={"#13235b"}
                                        >
                                          {t.apellidos}
                                        </Td>
                                        <Td
                                          border={"none"}
                                          width={"33%"}
                                          textAlign={"center"}
                                          fontSize={"1.7rem"}
                                          _dark={{
                                            color: "white",
                                          }}
                                          color={"#13235b"}
                                        >
                                          {t.nombres}
                                        </Td>
                                        <Td
                                          border={"none"}
                                          width={"33%"}
                                          textAlign={"center"}
                                          fontSize={"1.7rem"}
                                          _dark={{
                                            color: "white",
                                          }}
                                          color={"#13235b"}
                                        >
                                          {t.telefono}
                                        </Td>
                                        <Td
                                          border={"none"}
                                          width={"33%"}
                                          textAlign={"center"}
                                          fontSize={"1.7rem"}
                                          _dark={{
                                            color: "white",
                                          }}
                                          color={"#13235b"}
                                        >
                                          {t.correo}
                                        </Td>
                                        <Td
                                          border={"none"}
                                          width={"33%"}
                                          textAlign={"center"}
                                          fontSize={"1.7rem"}
                                          _dark={{
                                            color: "white",
                                          }}
                                          color={"#13235b"}
                                        >
                                          {t.tipo_documento}
                                        </Td>
                                        <Td
                                          border={"none"}
                                          width={"33%"}
                                          textAlign={"center"}
                                          fontSize={"1.7rem"}
                                          _dark={{
                                            color: "white",
                                          }}
                                          color={"#13235b"}
                                        >
                                          {t.documento}
                                        </Td>
                                        <Td
                                          border={"none"}
                                          width={"33%"}
                                          textAlign={"center"}
                                          fontSize={"1.7rem"}
                                          _dark={{
                                            color: "white",
                                          }}
                                          color={"#13235b"}
                                        >
                                          {t.fecha_nacimiento}
                                        </Td>

                                        <Td
                                          border={"none"}
                                          width={"33%"}
                                          textAlign={"center"}
                                          fontSize={"1.6rem"}
                                          _dark={{
                                            color: "white",
                                          }}
                                          color={"#13235b"}
                                        >
                                          <Flex
                                            justifyContent={"center"}
                                            gap={"4%"}
                                          >
                                            {/* <Tooltip
                                          hasArrow
                                          borderRadius={'4px'}
                                          fontSize={'1.2rem'}
                                          label='info'
                                          bg='#292F36'>
                                          <IconButton
                                            // onClick={() => {
                                            //   onOpenInfoRe();
                                            // }}
                                            size={'md'}
                                            borderRadius={'4px'}
                                            fontSize={'1.5rem'}
                                            background={'#2a2e6e'}
                                            color={'#ffffff'}
                                            aria-label='info'
                                            icon={
                                              <RiInformationFill />
                                            }
                                          />
                                        </Tooltip> */}
                                            {/* ------------------------------------------------- */}

                                            <Tooltip
                                              hasArrow
                                              borderRadius={"4px"}
                                              fontSize={"1.2rem"}
                                              label="Editar"
                                              bg="#292F36"
                                            >
                                              <IconButton
                                                onClick={() => {
                                                  setSportTrader(t);
                                                  onOpenEditSport();
                                                }}
                                                size={"md"}
                                                borderRadius={"4px"}
                                                fontSize={"1.5rem"}
                                                background={"#f59f2c"}
                                                color={"#ffffff"}
                                                aria-label="Editar"
                                                icon={<RiEditFill />}
                                              />
                                            </Tooltip>

                                            {/* -------------------------------------------- */}
                                            {t.operador_modulos.length === 0 && (
                                              <Tooltip
                                                hasArrow
                                                borderRadius={"4px"}
                                                fontSize={"1.2rem"}
                                                label="Asignar Modulo"
                                                bg="#292F36"
                                              >
                                                <IconButton
                                                  onClick={() => {
                                                    asignation.id_persona =
                                                      t.id_persona;
                                                    setSelectIdHall(
                                                      s.sala.id_sala
                                                    );
                                                    onOpenAsignarSport();
                                                  }}
                                                  size={"md"}
                                                  borderRadius={"4px"}
                                                  fontSize={"1.5rem"}
                                                  background={"#2b752d"}
                                                  color={"#ffffff"}
                                                  aria-label="asignar modulo"
                                                  icon={<MdAssignmentAdd />}
                                                />
                                              </Tooltip>
                                            )}

                                            {/* -------------------------------------------- */}
                                            {t.operador_modulos.length > 0 && (
                                              <Tooltip
                                                hasArrow
                                                borderRadius={"4px"}
                                                fontSize={"1.2rem"}
                                                label=" Modulo Asignado"
                                                bg="#292F36"
                                              >
                                                <IconButton
                                                  onClick={() => 
                                                  {
                                                    asignation.id_persona =
                                                    t.id_persona;
                                                    getAssignedModules(t.id_persona);
                                                    onOpenAsignadosSport();
                                                  }
                                                    } 
                                                  size={"md"}
                                                  borderRadius={"4px"}
                                                  fontSize={"1.5rem"}
                                                  background={"blue"}
                                                  color={"#ffffff"}
                                                  aria-label="asignar modulo"
                                                  icon={<MdAssignmentAdd />}
                                                />
                                              </Tooltip>
                                            )}

                                            {/* -------------------------------------------- */}
                                            {t.estado != 1 ? (
                                              <Tooltip
                                                hasArrow
                                                borderRadius={"4px"}
                                                fontSize={"1.2rem"}
                                                label="Activar"
                                                bg="#292F36"
                                              >
                                                <IconButton
                                                  onClick={() => {
                                                    quitar(t.id_persona, 1);
                                                  }}
                                                  size={"md"}
                                                  borderRadius={"4px"}
                                                  fontSize={"1.5rem"}
                                                  background={"#2b752d"}
                                                  color={"#ffffff"}
                                                  aria-label="Activar"
                                                  icon={<FaCheckCircle />}
                                                />
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                hasArrow
                                                borderRadius={"4px"}
                                                fontSize={"1.2rem"}
                                                label="Quitar"
                                                bg="#292F36"
                                              >
                                                <IconButton
                                                  onClick={() => {
                                                    quitar(t.id_persona, 0);
                                                    handleSubmitAsignateDelete(
                                                      t.id_persona
                                                    );
                                                  }}
                                                  size={"md"}
                                                  borderRadius={"4px"}
                                                  fontSize={"1.5rem"}
                                                  background={"#be0000"}
                                                  color={"#ffffff"}
                                                  aria-label="Quitar"
                                                  icon={<MdDoDisturbOff />}
                                                />
                                              </Tooltip>
                                            )}
                                          </Flex>
                                        </Td>
                                      </Tr>
                                    );
                                  })}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </AccordionPanel>
                        </AccordionItem>
                      </Flex>
                    </Accordion>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        </Box>
      </Box>

      {/* -------------------Modal Crear SportTrader----------------------------- */}
      <Modal
        isOpen={isOpenCrearSport}
        onClose={onCloseCrearSport}
        isCentered
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "30%", xl: "45%", lg: "65%", sm: "80%" }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Nuevo Sport Trader
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex
              direction={"column"}
              alignItems="center"
              gap="20px"
              w={"100%"}
              h="100%"
            >
              <Flex w={"100%"} gap={"15px"}>
                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Nombres:
                  </FormLabel>
                  <Input
                    name="nombres"
                    fontSize={"1.8rem"}
                    h="35"
                    type="text"
                    onChange={changeSportTrader}
                    value={sporttrader.nombres}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Apellidos:
                  </FormLabel>
                  <Input
                    name="apellidos"
                    fontSize={"1.8rem"}
                    h="35"
                    type="text"
                    onChange={changeSportTrader}
                    value={sporttrader.apellidos}
                  />
                </FormControl>
              </Flex>

              <Flex w={"100%"} gap={"15px"}>
                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Fecha de Nacimiento:
                  </FormLabel>
                  <Input
                    name="fecha_nacimiento"
                    fontSize={"1.8rem"}
                    h="35"
                    type="date"
                    onChange={changeSportTrader}
                    value={sporttrader.fecha_nacimiento}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Telefono:
                  </FormLabel>
                  <Input
                    name="telefono"
                    fontSize={"1.8rem"}
                    h="35"
                    type="number"
                    onChange={changeSportTrader}
                    value={sporttrader.telefono}
                  />
                </FormControl>
              </Flex>

              <Flex w={"100%"} gap={"15px"}>
                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Tipo de Documento:
                  </FormLabel>
                  <Select
                    onChange={changeSportTrader}
                    value={sporttrader.tipo_documento}
                    name="tipo_documento"
                    w="100%"
                    fontSize={"1.6rem"}
                    height={"35px"}
                    fontFamily="monospace"
                    fontWeight={"600"}
                    placeholder="Seleccionar
                  "
                    textTransform="uppercase"
                    variant="filled"
                  >
                    <option value="DNI">DNI</option>
                    <option value="RUC">RUC</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Documento:
                  </FormLabel>
                  <Input
                    name="documento"
                    fontSize={"1.8rem"}
                    h="35"
                    type="number"
                    onChange={changeSportTrader}
                    value={sporttrader.documento}
                  />
                </FormControl>
              </Flex>
              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Correo:
                </FormLabel>
                <Input
                  name="correo"
                  fontSize={"1.8rem"}
                  h="35"
                  type="text"
                  onChange={changeSportTrader}
                  value={sporttrader.correo}
                />
              </FormControl>

              <Button
                onClick={crearSportTrader}
                textTransform="uppercase"
                fontSize={"1.6rem"}
                p="3% 4%"
              >
                Guardar
              </Button>
            </Flex>
          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>

      {/* -------------------Modal Editar SportTrader----------------------------- */}
      <Modal
        isOpen={isOpenEditSport}
        onClose={onCloseEditSport}
        isCentered
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "30%", xl: "45%", lg: "65%", sm: "80%" }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Editar Sport Trader
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex
              direction={"column"}
              alignItems="center"
              gap="20px"
              w={"100%"}
              h="100%"
            >
              <Flex w={"100%"} gap={"15px"}>
                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Nombres:
                  </FormLabel>
                  <Input
                    name="nombres"
                    fontSize={"1.8rem"}
                    h="35"
                    type="text"
                    onChange={changeUpdate}
                    value={updateSportTrader.nombres}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Apellidos:
                  </FormLabel>
                  <Input
                    name="apellidos"
                    fontSize={"1.8rem"}
                    h="35"
                    type="text"
                    onChange={changeUpdate}
                    value={updateSportTrader.apellidos}
                  />
                </FormControl>
              </Flex>

              <Flex w={"100%"} gap={"15px"}>
                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Fecha de Nacimiento:
                  </FormLabel>
                  <Input
                    name="fecha_nacimiento"
                    fontSize={"1.8rem"}
                    h="35"
                    type="date"
                    onChange={changeUpdate}
                    value={updateSportTrader.fecha_nacimiento}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Telefono:
                  </FormLabel>
                  <Input
                    name="telefono"
                    fontSize={"1.8rem"}
                    h="35"
                    type="number"
                    onChange={changeUpdate}
                    value={updateSportTrader.telefono}
                  />
                </FormControl>
              </Flex>

              <Flex w={"100%"} gap={"15px"}>
                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Tipo de Documento:
                  </FormLabel>
                  <Select
                    onChange={changeUpdate}
                    value={updateSportTrader.tipo_documento}
                    name="tipo_documento"
                    w="100%"
                    fontSize={"1.6rem"}
                    height={"35px"}
                    fontFamily="monospace"
                    fontWeight={"600"}
                    placeholder="Seleccionar"
                    textTransform="uppercase"
                    variant="filled"
                  >
                    <option value="DNI">DNI</option>
                    <option value="RUC">RUC</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={"2rem"} fontWeight="500">
                    Documento:
                  </FormLabel>
                  <Input
                    name="documento"
                    fontSize={"1.8rem"}
                    h="35"
                    type="text"
                    onChange={changeUpdate}
                    value={updateSportTrader.documento}
                  />
                </FormControl>
              </Flex>

              <FormControl>
                <FormLabel fontSize={"2rem"} fontWeight="500">
                  Correo:
                </FormLabel>
                <Input
                  name="correo"
                  fontSize={"1.8rem"}
                  h="35"
                  type="text"
                  onChange={changeUpdate}
                  value={updateSportTrader.correo}
                />
              </FormControl>

              <Button
                onClick={editarSportTrader}
                textTransform="uppercase"
                fontSize={"1.6rem"}
                p="3% 4%"
              >
                Guardar
              </Button>
            </Flex>
          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>

      {/* -------------------Modal Asignar SportTrader----------------------------- */}

      <Modal
        isOpen={isOpenAsignarSport}
        onClose={onCloseAsignarSport}
        isCentered
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "30%", xl: "45%", lg: "50%", md: "70%" }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Asignar Modulo
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex direction={"column"} gap={"15px"}>
              <TableContainer w={"100%"}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th fontSize={"1.6rem"}>Modulos</Th>
                      <Th isNumeric fontSize={"1.6rem"}>
                        ACCIONES
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody fontSize={"1.5rem"}>
                    {listModule &&
                      listModule.map((itemModule) => (
                        <Tr key={itemModule.id_modulo}>
                          <Td>
                            {itemModule.nombre} - {itemModule.usuario}
                          </Td>

                          <Td
                            fontWeight={"600"}
                            fontFamily="monospace"
                            isNumeric
                          >
                            <Flex justifyContent={"right"} gap={"4%"}>
                              <Tooltip
                                hasArrow
                                borderRadius={"4px"}
                                fontSize={"1.2rem"}
                                label="Asignar"
                                bg="#292F36"
                              >
                                <IconButton
                                  onClick={() => {
                                    asignation.id_modulo = itemModule.id_modulo;
                                    handleSubmitAsignate();

                                    onCloseAsignarSport();
                                  }}
                                  size={"md"}
                                  borderRadius={"4px"}
                                  fontSize={"1.5rem"}
                                  background={"#2b752d"}
                                  color={"#ffffff"}
                                  aria-label="Asignar"
                                  icon={<MdAssignmentAdd />}
                                />
                              </Tooltip>
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>

              <Flex
                marginTop={"0.5%"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"0.5%"}
              >
                <Icon
                  onClick={() => {
                    prevPage();
                  }}
                  color={"#13235b"}
                  cursor={"pointer"}
                  boxSize="16"
                  _hover={{
                    color: "gray !important",
                  }}
                  _dark={{
                    color: "#f59f2c",
                  }}
                  as={IoMdArrowDropleftCircle}
                />
                <Icon
                  onClick={() => {
                    nextPage();
                  }}
                  color={"#13235b"}
                  cursor={"pointer"}
                  boxSize="16"
                  _hover={{
                    color: "gray !important",
                  }}
                  _dark={{
                    color: "#f59f2c",
                  }}
                  as={IoMdArrowDroprightCircle}
                />
              </Flex>
            </Flex>
          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>

      {/* -------------------Modal Mostrar modulos----------------------------- */}

      <Modal
        isOpen={isOpenAsignadosSport}
        onClose={onCloseAsignadosSport}
        isCentered
        alignItems="center"
        display="flex"
        height="100%"
      >
        <ModalOverlay />
        <ModalContent
          maxW={{ "2xl": "30%", xl: "45%", lg: "50%", md: "70%" }}
          p={"1%"}
        >
          <ModalHeader fontSize={"2rem"} textTransform="uppercase">
            Modulos Asignados
          </ModalHeader>
          <ModalCloseButton fontSize={"1.4rem"} />
          <ModalBody>
            <Flex direction={"column"} gap={"15px"}>
              <TableContainer w={"100%"}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th fontSize={"1.6rem"}>Modulos</Th>
                      <Th isNumeric fontSize={"1.6rem"}>
                        ACCIONES
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody fontSize={"1.5rem"}>
                    {assignedModule &&
                    <Tr key={assignedModule.id_modulo}>
                    <Td>
                      {assignedModule.nombre} - {assignedModule.usuario}
                    </Td>

                    <Td
                      fontWeight={"600"}
                      fontFamily="monospace"
                      isNumeric
                    >
                      <Flex justifyContent={"right"} gap={"4%"}>
                        <Tooltip
                          hasArrow
                          borderRadius={"4px"}
                          fontSize={"1.2rem"}
                          label="Quitar"
                          bg="#292F36"
                        >
                          <IconButton
                            onClick={() => {
                              // quitar(
                              //   asignation.id_persona ,
                              //   0
                              // );
                              handleSubmitAsignateDelete(
                                asignation.id_persona
                              );

                              onCloseAsignadosSport();
                            }}
                            size={"md"}
                            borderRadius={"4px"}
                            fontSize={"1.5rem"}
                            background={"#be0000"}
                            color={"#ffffff"}
                            aria-label="Quitar"
                            icon={<MdDoDisturbOff />}
                          />
                        </Tooltip>

                        {/* <Tooltip
                          hasArrow
                          borderRadius={'4px'}
                          fontSize={'1.2rem'}
                          label='Asignar'
                          bg='#292F36'>
                          <IconButton
                            onClick={() => {
                              asignation.id_modulo =
                                itemModule.id_modulo;
                              handleSubmitAsignate();
                            }}
                            size={'md'}
                            borderRadius={'4px'}
                            fontSize={'1.5rem'}
                            background={'#2b752d'}
                            color={'#ffffff'}
                            aria-label='Asignar'
                            icon={<MdAssignmentAdd />}
                          />
                        </Tooltip> */}
                      </Flex>
                    </Td>
                  </Tr>
                    }
                  </Tbody>
                </Table>
              </TableContainer>

              <Flex
                marginTop={"0.5%"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"0.5%"}
              >
                <Icon
                  onClick={() => {
                    prevPage();
                  }}
                  color={"#13235b"}
                  cursor={"pointer"}
                  boxSize="16"
                  _hover={{
                    color: "gray !important",
                  }}
                  _dark={{
                    color: "#f59f2c",
                  }}
                  as={IoMdArrowDropleftCircle}
                />
                <Icon
                  onClick={() => {
                    nextPage();
                  }}
                  color={"#13235b"}
                  cursor={"pointer"}
                  boxSize="16"
                  _hover={{
                    color: "gray !important",
                  }}
                  _dark={{
                    color: "#f59f2c",
                  }}
                  as={IoMdArrowDroprightCircle}
                />
              </Flex>
            </Flex>
          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
}

export default SportTrader;
