export const getMonthName = (number) => {
  let name = "";

  switch (number) {
    case 1:
      name = "ENERO";
      break;
    case 2:
      name = "FEBRERO";
      break;
    case 3:
      name = "MARZO";
      break;
    case 4:
      name = "ABRIL";
      break;
    case 5:
      name = "MAYO";
      break;
    case 6:
      name = "JUNIO";
      break;
    case 7:
      name = "JULIO";
      break;
    case 8:
      name = "AGOSTO";
      break;
    case 9:
      name = "SETIEMBRE";
      break;
    case 10:
      name = "OCTUBRE";
      break;
    case 11:
      name = "NOVIEMBRE";
      break;
    case 12:
      name = "DICIEMBRE";
      break;
    default:
      name = "MES INVALIDO";
  }
  return name;
};

// Funcion Ban Total Ganancias
export const calcularTotalBan = (data) => {
  let total = 0;

  total = data.reduce((acc, value) => {
    const bantotal = parseFloat(value.ban_total);
    return isNaN(bantotal) ? acc : acc + bantotal;
  }, 0);

  return total;
};

// Funcion Retiros Ganancias
export const calcularRetiros = (data) => {
  let total = 0;

  total = data.reduce((acc, value) => {
    const retiros = parseFloat(value.retiros);
    return isNaN(retiros) ? acc : acc + retiros;
  }, 0);

  return total;
};

// Funcion Recargas Ganancias
export const calcularRecargas = (data) => {
  let total = 0;

  total = data.reduce((acc, value) => {
    const recargas = parseFloat(value.recargas);
    return isNaN(recargas) ? acc : acc + recargas;
  }, 0);

  return total;
};

// Funcion TotalInicial Ganancias
export const calcularTotalInicial = (data) => {
  let total = 0;

  total = data.reduce((acc, value) => {
    const Totalinicial = parseFloat(value.total_inicio);
    return isNaN(Totalinicial) ? acc : acc + Totalinicial;
  }, 0);

  return total;
};

// Funcion TotalTermino Ganancias
export const calcularTotalTermino = (data) => {
  let total = 0;

  total = data.reduce((acc, value) => {
    const Totaltermino = parseFloat(value.total_termino);
    return isNaN(Totaltermino) ? acc : acc + Totaltermino;
  }, 0);

  return total;
};

// Funcion Suma Total Ganancias
export const calcularSumaTotalGanancias = (data) => {
  let total = 0;

  total = data.reduce((acc, value) => {
    const ganancia = parseFloat(value.ganancia);
    return isNaN(ganancia) ? acc : acc + ganancia;
  }, 0);

  return total;
};
