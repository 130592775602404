import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  // Avatar,
  // HStack,
  // Link,
  IconButton,
  Button,
  // Menu,
  // MenuButton,
  // MenuList,
  // MenuItem,
  // Container,
  // Img,
  // MenuDivider,
  useDisclosure,
  // useColorModeValue,
  Stack,
  // MenuGroup,
  // Popover,
  // Divider,
  // PopoverTrigger,
  // PopoverContent,
  // PopoverArrow,
  // NavItem,
  // Collapse,
  // Text,
  // Badge,
  Icon,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Thead,
  Tbody,
  Tooltip,
  // Tfoot,
  Tr,
  Th,
  Td,
  BreadcrumbLink,
  BreadcrumbItem,
  Breadcrumb,
  // TableCaption,
  TableContainer,
  FormControl,
  FormLabel,
  // FormErrorMessage,
  Select,
  // FormHelperText,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel
} from '@chakra-ui/react';
import {
  RiEditFill,
  RiInformationFill
  // RiUserAddFill
} from 'react-icons/ri';
import {
  // MdDoDisturbOff,
  // MdCancel,
  // MdGroupAdd,
  MdAssignmentAdd,
  MdRemoveCircle
} from 'react-icons/md';
import { Search2Icon } from '@chakra-ui/icons';
// import { RiFileExcel2Fill } from 'react-icons/ri';
// import { EditIcon } from '@chakra-ui/icons';
// import { FaEdit } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
// import { utils, writeFile } from 'xlsx';
import { useHistory } from 'react-router-dom';
// import moment from 'moment';
import SidebarOperators from '../../../components/SidebarOperators';
import axios from 'axios';
import {
  IoMdArrowDroprightCircle,
  IoMdArrowDropleftCircle
} from 'react-icons/io';
// import { FaCheckCircle } from 'react-icons/fa';
import { host3 } from '../../../utils/constantes';
import jwt_decode from 'jwt-decode';
// import { BsHourglass } from 'react-icons/bs';

function Administracion(props) {
  let history = useHistory();
  const token = localStorage.getItem('tokenadmin');
  const { id_persona, rol } = jwt_decode(token);
  if (!token) {
    history.push('/');
  }

  useEffect(() => {
    if (token) {
      if (rol.includes('TMONEY')) {
        history.push('/salas/MSalas');
      }
      if (rol.includes('ADMINISTRADOR')) {
        history.push('/salas/Mantenimiento');
      }
      if (rol.includes('JEFE DE SALA')) {
        history.push('/salas/CuentasCasas');
      }
    }
  }, []);

  const {
    isOpen: isOpenBar,
    onOpen: onOpenBar,
    onClose: onCloseBar
  } = useDisclosure();

  //-----------------------ADMINISTRADOR------------------------
  //Mensaje
  const mensaje = (texto, posicion, tipo) => {
    switch (tipo) {
      case 1:
        toast.success(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      case 2:
        toast.warn(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      case 3:
        toast.error(texto, {
          position: posicion,
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        break;
      default:
        break;
    }
  };

  const [ordenarAdmin, setOrdenarAdmin] = useState(false);
  const [ordenarJsala, setOrdenarJsala] = useState(false);

  //----------------BUSCAR-----------------------

  const [search, setSearch] = useState('');

  const buscar = (e) => {
    setSearch(e.target.value);
  };

  //------------------------SALA CONTRATO-----------------------

  const [salaContrato, setSalaContrato] = useState([]);

  const fetchHallContract = async () => {
    try {
      const { status, data } = await axios.get(
        `${host3}/api/contratos/${token}/list-hall-for-contract/${id_persona}`
      );
      if (status === 200) {
        setSalaContrato(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //-----------------------ADMINISTRADOR------------------------

  //------------LISTAR----------------------------

  const [listAdmin, setListAdmin] = useState([]);

  const admin = async () => {
    try {
      const { status, data } = await axios.get(
        `${host3}/api/personas/${token}/listar/${id_persona}`
      );
      if (status === 200) {
        setListAdmin(data);
      }
    } catch (error) {
      console.log(error);
      setListAdmin([]);
    }
  };

  const ListFiltered = () => {
    if (search) {
      var filtered = listAdmin.filter(
        (p) =>
          p.nombres?.includes(search.toUpperCase()) ||
          p.apellidos?.includes(search.toUpperCase()) ||
          p.telefono?.includes(search) ||
          p.documento?.includes(search)
      );
      return filtered.slice(currentPage, currentPage + 13);
    }
    return listAdmin?.slice(currentPage, currentPage + 13);
  };

  // --------------------- agregar Admin--------------------------------

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [administrador, setAdministrador] = useState({
    apellidos: '',
    nombres: '',
    telefono: '',
    correo: '',
    tipo_documento: '',
    documento: '',
    id_sala: "inversionista",
    fecha_nacimiento: '',
    clave_sistema: '',
    id_sala: 'inversionista',

    roles: []
  });

  const limpiarAdministrador = () => {
    setAdministrador({
      apellidos: '',
      nombres: '',
      telefono: '',
      correo: '',
      tipo_documento: '',
      id_sala: "inversionista",
      documento: '',
      fecha_nacimiento: '',
      clave_sistema: '',
      id_sala: 'inversionista',
      roles: []
    });
  };

  const handleChangeAdministrador = ({ target: { name, value } }) => {
    const maxDigits = administrador.tipo_documento === 'DNI' ? 8 : administrador.tipo_documento === 'RUC' ? 11 : null;
    if (name === 'documento' && maxDigits !== null) {
      if (!(/^\d{0,}$/.test(value) && value.length <= maxDigits)) {
      return;
      }
    }
    setAdministrador({ ...administrador, [name]: value });
  };

  const crearAdministrador = async (e) => {
    e.preventDefault();
    try {
      const { status, data } = await axios.post(
        `${host3}/api/personas/${token}`,
        administrador
      );

      if (status === 201) {
        mensaje(data.message, 'top-right', 1);
        limpiarAdministrador();
        onClose();
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
    admin();
  };

  // --------------------- Editar administrador---------------------------------
  const {
    isOpen: isOpenEAdmin,
    onOpen: onOpenEAdmin,
    onClose: onCloseEAdmin
  } = useDisclosure();

  const updateAdministrador = async (e) => {
    e.preventDefault();
    try {
      administrador.roles = [{ id: 3, nombre: 'ADMINISTRADOR' }];
      const { status, data } = await axios.put(
        `${host3}/api/personas/${token}/${administrador.id_persona}`,
        administrador
      );

      if (status === 201) {
        mensaje(data.message, 'top-right', 1);
        limpiarAdministrador();
        onCloseEAdmin();
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
    admin();
  };

  // --------------------- Info Sala---------------------------------
  const {
    isOpen: isOpenInfoSala,
    onOpen: onOpenInfoSala,
    onClose: onCloseInfoSala
  } = useDisclosure();

  const [listHallAdministrador, setListHallAdministrador] = useState(
    []
  );
  const [idAdministrador, setIdAdministrador] = useState(null);

  const fetchSalaAdministrador = async () => {
    try {
      return await axios.get(
        `${host3}/api/personas/${token}/list-hall/${idAdministrador}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  // --------------------- Asignar  Sala---------------------------------
  const {
    isOpen: isOpenAsignarSala,
    onOpen: onOpenAsignarSala,
    onClose: onCloseAsignarSala
  } = useDisclosure();

  const [asignaAdmin, setAsignaAdmin] = useState({
    id_persona: '',
    id_sala: ''
  });

  const asignarAdministrador = async (e) => {
    try {
      e.preventDefault();
      const { status, data } = await axios.post(
        `${host3}/api/personas/${token}/asignate-hall/${asignaAdmin.id_persona}`,
        asignaAdmin
      );

      if (status === 201) {
        mensaje(data.message, 'top-right', 1);
        window.location.reload();
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
  };

  // --------------------- Quitar  Sala---------------------------------

  const deleteAdministradorHall = async (id) => {
    try {
      const { status, data } = await axios.delete(
        `${host3}/api/personas/${token}/remove-hall/${id}`
      );

      if (status === 201) {
        mensaje(data.message, 'top-right', 1);
        window.location.reload();
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
  };

  useEffect(() => {
    const loadAdministrador = async (id) => {
      try {
        const { data } = await fetchSalaAdministrador(id);
        setListHallAdministrador(data);
      } catch (error) {
        setListHallAdministrador([]);
      }
    };

    if (idAdministrador != null) {
      loadAdministrador(idAdministrador);
    }
  }, [idAdministrador]);

  // --------------------JEFE SALA-----------------------------------

  //-- Listar

  const [listJefe, setListJefe] = useState([]);

  const jefe = async () => {
    try {
      const { status, data } = await axios.get(
        `${host3}/api/personas/${token}/jefe/${id_persona}`
      );
      if (status === 200) {
        setListJefe(data);
      }
    } catch (error) {
      console.log(error);
      setListJefe([]);
    }
  };

  const ListFiltered02 = () => {
    if (search) {
      var filtered = listJefe.filter(
        (p) =>
          p.nombres?.includes(search.toUpperCase()) ||
          p.apellidos?.includes(search.toUpperCase()) ||
          p.telefono?.includes(search) ||
          p.documento?.includes(search)
      );
      return filtered.slice(currentPage, currentPage + 13);
    }
    return listJefe?.slice(currentPage, currentPage + 13);
  };

  //-- Agregar

  const [ModelJefe, setModelJefe] = useState({
    apellidos: '',
    nombres: '',
    telefono: '',
    correo: '',
    tipo_documento: '',
    documento: '',
    id_sala: 'inversionista',
    fecha_nacimiento: '',
    clave_sistema: '',
    id_sala: 'inversionista',

    roles: []
  });

  const changeJefe = (e) => {
    const maxDigits = ModelJefe.tipo_documento === 'DNI' ? 8 : ModelJefe.tipo_documento === 'RUC' ? 11 : null;
    if (e.target.name === 'documento' && maxDigits !== null) {
      const inputValue = e.target.value;
      if (!(/^\d{0,}$/.test(inputValue) && inputValue.length <= maxDigits)) {
        return;
      }
    }
    setModelJefe({
      ...ModelJefe,
      [e.target.name]: e.target.value
    });
  };

  const crearJefe = async (e) => {
    e.preventDefault();
    try {
      const { status, data } = await axios.post(
        `${host3}/api/personas/${token}`,
        ModelJefe
      );
      if (status === 201) {
        mensaje(data.message, 'top-right', 1);
        onClose();
        setModelJefe({
          apellidos: '',
          nombres: '',
          telefono: '',
          correo: '',
          tipo_documento: '',
          id_sala: "inversionista",
          documento: '',
          fecha_nacimiento: '',
          id_sala: 'inversionista',
          clave_sistema: ''
        });
      }
      jefe();
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
  };

  //-- Editar

  const [updateJefe, setJefe] = useState({
    apellidos: '',
    nombres: '',
    telefono: '',
    correo: '',
    tipo_documento: '',
    documento: '',
    fecha_nacimiento: '',
    clave_sistema: '',
    roles: []
  });

  const changeUpdate = (e) => {
    const maxDigits = updateJefe.tipo_documento === 'DNI' ? 8 : updateJefe.tipo_documento === 'RUC' ? 11 : null;
    if (e.target.name === 'documento' && maxDigits !== null) {
      const inputValue = e.target.value;
      if (!(/^\d{0,}$/.test(inputValue) && inputValue.length <= maxDigits)) {
        return;
      }
    }
    setJefe({
      ...updateJefe,
      [e.target.name]: e.target.value
    });
  };

  const editarJefe = async (e) => {
    e.preventDefault();
    try {
      updateJefe.roles = [{ id: 4, nombre: 'JEFE DE SALA' }];
      const { data, status } = await axios.put(
        `${host3}/api/personas/${token}/${updateJefe.id_persona}`,
        updateJefe
      );
      if (status === 201) {
        mensaje(data.message, 'top-right', 1);
        onCloseEditarJefe();
        setJefe({
          apellidos: '',
          nombres: '',
          telefono: '',
          correo: '',
          tipo_documento: '',
          documento: '',
          fecha_nacimiento: '',
          clave_sistema: '',
          roles: []
        });
        jefe();
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
      //console.log(error);
    }
  };

  //-- Info

  const [list, setList] = useState([]);
  const [sala, setSala] = useState(null);

  const listSala = async (id_persona) => {
    try {
      return await axios.get(
        `${host3}/api/personas/${token}/list-hall/${id_persona}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  const ListFiltered03 = () => {
    if (search) {
      var filtered = list.filter(
        (p) =>
          p.nombres?.includes(search.toUpperCase()) ||
          p.apellidos?.includes(search.toUpperCase()) ||
          p.telefono?.includes(search) ||
          p.documento?.includes(search)
      );
      return filtered.slice(currentPage, currentPage + 13);
    }
    return list?.slice(currentPage, currentPage + 13);
  };

  useEffect(() => {
    const loadData = async (id_persona) => {
      try {
        const { data } = await listSala(id_persona);
        setList(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (sala != null) {
      loadData(sala);
    }
  }, [sala]);

  //-- Quitar

  const quitar = async (id) => {
    try {
      const { status, data } = await axios.delete(
        `${host3}/api/personas/${token}/remove-hall/${id}`
      );
      if (status === 201) {
        mensaje(data.message, 'top-right', 1);
        window.location.reload();
        setSala();
        onCloseInfoJefe();
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
    // asignacion();
    jefe();
  };

  //-- Eliminar Todo

  const eliminar = async (id_persona) => {
    try {
      const { status, data } = await axios.delete(
        `${host3}/api/personas/${token}/eliminar/${id_persona}`
      );
      if (status === 201) {
        mensaje(data.message, 'top-right', 1);
        setSala();
        onCloseInfoJefe();
      }
    } catch (error) {
      mensaje(error.response.data.message, 'top-right', 2);
    }
    // asignacion();
    jefe();
    admin();
  };

  const {
    isOpen: isOpenAgregarJefe,
    onOpen: onOpenAgregarJefe,
    onClose: onCloseAgregarJefe
  } = useDisclosure();

  const {
    isOpen: isOpenEditarJefe,
    onOpen: onOpenEditarJefe,
    onClose: onCloseEditarJefe
  } = useDisclosure();

  const {
    isOpen: isOpenInfoJefe,
    onOpen: onOpenInfoJefe,
    onClose: onCloseInfoJefe
  } = useDisclosure();

  // --------------------Info Jefe de Sala -------------------------
  const {
    isOpen: isOpenAsignSalaJefe,
    onOpen: onOpenAsignSalaJefe,
    onClose: onCloseAsignSalaJefe
  } = useDisclosure();

  //Paginacion
  const [listAdministrador] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const nextPage = () => {
    const cant = listAdministrador.filter((p) =>
      p.id_colaborador.toString().includes(search)
    ).length;

    if (cant > currentPage) {
      setCurrentPage(currentPage + 13);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 13);
    }
  };

  useEffect(() => {
    admin();
    jefe();
    fetchHallContract();
    setTimeout(() => {}, 1000);
  }, []);

  // ---------------------------

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontSize: '1.2rem', textAlign: 'center' }}
        pauseOnHover
      />

      <Box
        scrollBehavior={'smooth'}
        bg={'#e5e5e5'}
        height={'auto'}
        maxHeight={'100vh'}
        overflowY={'auto'}
        css={{
          '&::-webkit-scrollbar': {
            width: '12px'
          },
          '&::-webkit-scrollbar-track': {
            background: '#6b6b6b',
            borderRadius: '0px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#9b9b9b',
            borderRadius: '6px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#9b9b9b'
          }
        }}>
        <Box
          as='section'
          display={{
            '2xl': 'unset',
            xl: 'unset',
            lg: 'none',
            md: 'none',
            sm: 'none'
          }}>
          <SidebarOperators />
        </Box>
        <Drawer
          isOpen={isOpenBar}
          onClose={onCloseBar}
          placement='left'>
          <DrawerOverlay />
          <DrawerContent>
            <SidebarOperators w='full' borderRight='none' />
          </DrawerContent>
        </Drawer>

        <Box bg={'#e5e5e5'} h={'100vh'}>
          <Flex
            alignItems={'center'}
            justify={'flex-start'}
            // h={'100%'}
            sx={{
              '@media only screen and (min-width: 1200px) and (max-width: 1366px)':
                {
                  padding: '1.5% 1.5% 1.5% 19%'
                }
            }}
            p={{
              '2xl': '1.5% 3% 1.5% 15%',
              xl: '1.5% 3% 1.5% 18%',
              lg: '1.5% 2% 1.5% 2%',
              md: '1.5% 2% 1.5% 2%'
            }}
            // marginLeft={"15%"}
            // marginRight="2%"
            // marginBottom="1%"
            direction='column'
            gap={'25px'}>
            <Box transition='.3s ease' width={'100%'}>
              <Flex
                gap='2%'
                alignItems={'center'}
                as='header'
                w='full'
                h='14'>
                <IconButton
                  aria-label='Menu'
                  display={{
                    '2xl': 'none',
                    xl: 'none',
                    lg: 'flex',
                    md: 'flex',
                    sm: 'flex'
                  }}
                  background={'#292F36'}
                  color={'#ffffff'}
                  borderRadius={'4px'}
                  fontSize={'1.3rem'}
                  onClick={onOpenBar}
                  icon={<FiMenu />}
                  size='md'
                />
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink
                      color={'#707070'}
                      fontSize={'1.5rem'}>
                      SALAS-OP
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink
                      fontWeight={'bold'}
                      color={'#292F36'}
                      fontSize={'1.5rem'}>
                      ADMINISTRADOR
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Flex>
            </Box>
            {/* ------------------------------------Content ----------------------------------------- */}

            <Tabs isFitted variant='enclosed' w={'100%'}>
              <TabList
                mb='1em'
                bg={'#FFFF'}
                borderRadius='15px'
                p={'0.5%'}>
                <Tab
                  borderRadius={'15px'}
                  _selected={{
                    color: 'white',
                    bg: '#2a2e6e'
                  }}
                  fontSize='2.1rem'
                  textTransform={'uppercase'}
                  fontFamily='monospace'
                  fontWeight={'600'}>
                  Administradores
                </Tab>
                <Tab
                  borderRadius={'15px'}
                  _selected={{
                    color: 'white',
                    bg: '#2a2e6e'
                  }}
                  fontSize='2.1rem'
                  textTransform={'uppercase'}
                  fontFamily='monospace'
                  fontWeight={'600'}>
                  Jefe de Sala
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel
                  w={'100%'}
                  bg={'transparent'}
                  borderRadius='10px'
                  p={'2%'}
                  // boxShadow='rgba(0, 0, 0, 0.35) 0px 5px 15px'
                >
                  <Flex width={'100%'} justifyContent={'center'}>
                    <Flex
                      width={'100%'}
                      direction={'column'}
                      gap={'25px'}>
                      <Flex
                        width={'100%'}
                        justifyContent={'space-between'}>
                        <Flex width={'30%'}>
                          <Stack w={'100%'}>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents='none'
                                children={
                                  <Search2Icon
                                    color='#2a2e6e'
                                    marginLeft={'20%'}
                                    fontSize={'1.25rem'}
                                    marginTop={'60%'}
                                    _dark={{
                                      color: '#f59f2c'
                                    }}
                                  />
                                }
                              />
                              <Input
                                onChange={buscar}
                                type='text'
                                placeholder='Buscar'
                                bg={'white'}
                                height={'40px'}
                                _focus={{
                                  borderColor: '#2a2e6e',
                                  boxShadow: 'none'
                                }}
                                color='#2a2e6e'
                                fontSize={'1.9rem'}
                                border={'1px solid'}
                                borderColor={'#2a2e6e'}
                                borderRadius='6px'
                                _placeholder={{
                                  color: 'rgb(152, 152, 152)'
                                }}
                                _dark={{
                                  color: 'white',
                                  borderColor: '#686868',
                                  bg: '#686868'
                                }}
                              />
                            </InputGroup>
                          </Stack>
                        </Flex>
                      </Flex>

                      {/* --------------------------------------------------------- */}

                      <Flex
                        boxShadow={
                          'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'
                        }
                        background={'white'}
                        borderRadius={'18px'}
                        direction={'column'}
                        padding={'2% 4% 2% 4%'}>
                        <Flex width={'100%'} justifyContent={'right'}>
                          <Stack align='center' width={'auto'}>
                            <Button
                              w={'100%'}
                              onClick={() => {
                                limpiarAdministrador();
                                onOpen();
                              }}
                              height={'40px'}
                              borderRadius='8px'
                              color={'white'}
                              fontSize={'1.6rem'}
                              textTransform={'uppercase'}
                              bg='#2a2e6e'
                              _hover={{
                                color: 'white',
                                bg: '#f59f2c'
                              }}>
                              Agregar
                            </Button>
                          </Stack>
                        </Flex>
                        {/* --------------------------------------------------------- */}
                        <Flex
                          direction={'column'}
                          w={'100%'}
                          h='100%'
                          justifyContent='center'
                          paddingTop={'2%'}>
                          <TableContainer w={'100%'}>
                            <Table variant='simple'>
                              <Thead>
                                <Tr>
                                  <Th fontSize={'1.6rem'}>ID</Th>
                                  <Th
                                    fontSize={'1.6rem'}
                                    cursor={'pointer'}
                                    onClick={() => {
                                      var ordenDocument;
                                      if (!ordenarAdmin) {
                                        ordenDocument =
                                          listAdmin.sort((a, b) =>
                                            a.apellidos.localeCompare(
                                              b.apellidos
                                            )
                                          );
                                        setListAdmin(ordenDocument);
                                        setOrdenarAdmin(true);
                                      } else {
                                        ordenDocument =
                                          listAdmin.sort((a, b) =>
                                            b.apellidos.localeCompare(
                                              a.apellidos
                                            )
                                          );
                                        setListAdmin(ordenDocument);
                                        setOrdenarAdmin(false);
                                      }
                                    }}>
                                    APELLIDOS
                                  </Th>
                                  <Th fontSize={'1.6rem'}>NOMBRES</Th>
                                  <Th fontSize={'1.6rem'}>
                                    TELEFONO
                                  </Th>
                                  <Th
                                    fontSize={'1.6rem'}
                                    cursor={'pointer'}
                                    onClick={() => {
                                      var ordenDocument;
                                      if (!ordenarAdmin) {
                                        ordenDocument =
                                          listAdmin.sort((a, b) =>
                                            a.correo.localeCompare(
                                              b.correo
                                            )
                                          );
                                        setListAdmin(ordenDocument);
                                        setOrdenarAdmin(true);
                                      } else {
                                        ordenDocument =
                                          listAdmin.sort((a, b) =>
                                            b.correo.localeCompare(
                                              a.correo
                                            )
                                          );
                                        setListAdmin(ordenDocument);
                                        setOrdenarAdmin(false);
                                      }
                                    }}>
                                    CORREO
                                  </Th>
                                  <Th fontSize={'1.6rem'}>
                                    TIPO DOCUMENTO
                                  </Th>
                                  <Th fontSize={'1.6rem'}>
                                    DOCUMENTO
                                  </Th>
                                  <Th fontSize={'1.6rem'}>
                                    FECHA NACIMIENTO
                                  </Th>
                                  <Th isNumeric fontSize={'1.6rem'}>
                                    ACCIONES
                                  </Th>
                                </Tr>
                              </Thead>

                              <Tbody fontSize={'1.5rem'}>
                                {ListFiltered().map(
                                  (itemAdministrador, index) => {
                                    return (
                                      <Tr
                                      // key={p.id_colaborador}
                                      >
                                        <Td>
                                          {
                                            index+1
                                          }
                                        </Td>
                                        <Td>
                                          {
                                            itemAdministrador.apellidos
                                          }
                                        </Td>
                                        <Td>
                                          {itemAdministrador.nombres}
                                        </Td>
                                        <Td>
                                          {itemAdministrador.telefono}
                                        </Td>
                                        <Td>
                                          {itemAdministrador.correo}
                                        </Td>
                                        <Td>
                                          {
                                            itemAdministrador.tipo_documento
                                          }
                                        </Td>
                                        <Td>
                                          {
                                            itemAdministrador.documento
                                          }
                                        </Td>
                                        <Td>
                                          {
                                            itemAdministrador.fecha_nacimiento
                                          }
                                        </Td>
                                        <Td
                                          fontWeight={'600'}
                                          fontFamily='monospace'
                                          isNumeric>
                                          <Flex
                                            justifyContent={'right'}
                                            gap={'4%'}>
                                            <Tooltip
                                              hasArrow
                                              borderRadius={'4px'}
                                              fontSize={'1.2rem'}
                                              label='Editar'
                                              bg='#292F36'>
                                              <IconButton
                                                onClick={() => {
                                                  setAdministrador(
                                                    itemAdministrador
                                                  );
                                                  onOpenEAdmin();
                                                }}
                                                size={'md'}
                                                borderRadius={'4px'}
                                                fontSize={'1.5rem'}
                                                background={'#f59f2c'}
                                                color={'#ffffff'}
                                                aria-label='Editar'
                                                icon={<RiEditFill />}
                                              />
                                            </Tooltip>
                                            {/* ------------------------ */}
                                            <Tooltip
                                            
                                              hasArrow
                                              borderRadius={'4px'}
                                              fontSize={'1.2rem'}
                                              label='Info'
                                              bg='#292F36'>
                                              <IconButton
                                                onClick={() => {
                                                  setIdAdministrador(
                                                    itemAdministrador.id_persona
                                                  );
                                                  onOpenInfoSala();
                                                }}
                                                size={'md'}
                                                borderRadius={'4px'}
                                                fontSize={'1.5rem'}
                                                background={'#2a2e6e'}
                                                color={'#ffffff'}
                                                aria-label='info'
                                                icon={
                                                  <RiInformationFill />
                                                }
                                              />
                                            </Tooltip>
                                            {/* ------------------------ */}
                                            <Tooltip
                                              hasArrow
                                              borderRadius={'4px'}
                                              fontSize={'1.2rem'}
                                              label='Asignar sala'
                                              bg='#292F36'>
                                              <IconButton
                                                onClick={() => {
                                                  asignaAdmin.id_persona =
                                                    itemAdministrador.id_persona;
                                                  onOpenAsignarSala();
                                                }}
                                                size={'md'}
                                                borderRadius={'4px'}
                                                fontSize={'1.5rem'}
                                                background={'#2b752d'}
                                                color={'#ffffff'}
                                                aria-label='asignar sala'
                                                icon={
                                                  <MdAssignmentAdd />
                                                }
                                              />
                                            </Tooltip>
                                            {/* ------------------------ */}
                                            <Tooltip
                                              hasArrow
                                              borderRadius={'4px'}
                                              fontSize={'1.2rem'}
                                              label='Quitar'
                                              bg='#292F36'>
                                              <IconButton
                                                onClick={() => {
                                                  eliminar(
                                                    itemAdministrador.id_persona
                                                  );
                                                }}
                                                size={'md'}
                                                borderRadius={'4px'}
                                                fontSize={'1.5rem'}
                                                background={'red'}
                                                color={'#ffffff'}
                                                aria-label='Quitar'
                                                icon={
                                                  <MdRemoveCircle />
                                                }
                                              />
                                            </Tooltip>
                                          </Flex>
                                        </Td>
                                      </Tr>
                                    );
                                  }
                                )}
                              </Tbody>
                            </Table>
                          </TableContainer>

                          <Flex
                            marginTop={'0.5%'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            gap={'0.5%'}>
                            <Icon
                              onClick={() => {
                                prevPage();
                              }}
                              color={'#13235b'}
                              cursor={'pointer'}
                              boxSize='16'
                              _hover={{
                                color: 'gray !important'
                              }}
                              _dark={{
                                color: '#f59f2c'
                              }}
                              as={IoMdArrowDropleftCircle}
                            />
                            <Icon
                              onClick={() => {
                                nextPage();
                              }}
                              color={'#13235b'}
                              cursor={'pointer'}
                              boxSize='16'
                              _hover={{
                                color: 'gray !important'
                              }}
                              _dark={{
                                color: '#f59f2c'
                              }}
                              as={IoMdArrowDroprightCircle}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </TabPanel>
                {/* -------------------------------------------------------------- */}

                <TabPanel
                  w={'100%'}
                  bg={'transparent'}
                  borderRadius='10px'
                  p={'2%'}
                  // boxShadow='rgba(0, 0, 0, 0.35) 0px 5px 15px'
                >
                  <Flex width={'100%'} justifyContent={'center'}>
                    <Flex
                      width={'100%'}
                      direction={'column'}
                      gap={'25px'}>
                      <Flex
                        width={'100%'}
                        justifyContent={'space-between'}>
                        <Flex width={'30%'}>
                          <Stack w={'100%'}>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents='none'
                                children={
                                  <Search2Icon
                                    color='#2a2e6e'
                                    marginLeft={'20%'}
                                    fontSize={'1.25rem'}
                                    marginTop={'60%'}
                                    _dark={{
                                      color: '#f59f2c'
                                    }}
                                  />
                                }
                              />
                              <Input
                                type='text'
                                placeholder='Buscar'
                                bg={'white'}
                                height={'40px'}
                                _focus={{
                                  borderColor: '#2a2e6e',
                                  boxShadow: 'none'
                                }}
                                color='#2a2e6e'
                                fontSize={'1.9rem'}
                                border={'1px solid'}
                                borderColor={'#2a2e6e'}
                                borderRadius='6px'
                                _placeholder={{
                                  color: 'rgb(152, 152, 152)'
                                }}
                                _dark={{
                                  color: 'white',
                                  borderColor: '#686868',
                                  bg: '#686868'
                                }}
                              />
                            </InputGroup>
                          </Stack>
                        </Flex>
                      </Flex>

                      {/* --------------------------------------------------------- */}

                      <Flex
                        boxShadow={
                          'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'
                        }
                        background={'white'}
                        borderRadius={'18px'}
                        direction={'column'}
                        padding={'2% 4% 2% 4%'}>
                        <Flex width={'100%'} justifyContent={'right'}>
                          <Stack align='center' width={'auto'}>
                            <Button
                              w={'100%'}
                              onClick={onOpenAgregarJefe}
                              height={'40px'}
                              borderRadius='8px'
                              color={'white'}
                              fontSize={'1.6rem'}
                              textTransform={'uppercase'}
                              bg='#2a2e6e'
                              _hover={{
                                color: 'white',
                                bg: '#f59f2c'
                              }}>
                              Agregar
                            </Button>
                          </Stack>
                        </Flex>
                        {/* --------------------------------------------------------- */}
                        <Flex
                          direction={'column'}
                          w={'100%'}
                          h='100%'
                          justifyContent='center'
                          paddingTop={'2%'}>
                          <TableContainer w={'100%'}>
                            <Table variant='simple'>
                              <Thead>
                                <Tr>
                                  <Th fontSize={'1.6rem'}>ID</Th>
                                  <Th
                                    fontSize={'1.6rem'}
                                    cursor={'pointer'}
                                    onClick={() => {
                                      var ordenDocument;
                                      if (!ordenarJsala) {
                                        ordenDocument = listJefe.sort(
                                          (a, b) =>
                                            a.apellidos.localeCompare(
                                              b.apellidos
                                            )
                                        );
                                        setListJefe(ordenDocument);
                                        setOrdenarJsala(true);
                                      } else {
                                        ordenDocument = listJefe.sort(
                                          (a, b) =>
                                            b.apellidos.localeCompare(
                                              a.apellidos
                                            )
                                        );
                                        setListJefe(ordenDocument);
                                        setOrdenarJsala(false);
                                      }
                                    }}>
                                    APELLIDOS
                                  </Th>
                                  <Th fontSize={'1.6rem'}>NOMBRES</Th>
                                  <Th fontSize={'1.6rem'}>
                                    TELEFONO
                                  </Th>
                                  <Th
                                    fontSize={'1.6rem'}
                                    cursor={'pointer'}
                                    onClick={() => {
                                      var ordenDocument;
                                      if (!ordenarJsala) {
                                        ordenDocument = listJefe.sort(
                                          (a, b) =>
                                            a.correo.localeCompare(
                                              b.correo
                                            )
                                        );
                                        setListJefe(ordenDocument);
                                        setOrdenarJsala(true);
                                      } else {
                                        ordenDocument = listJefe.sort(
                                          (a, b) =>
                                            b.correo.localeCompare(
                                              a.correo
                                            )
                                        );
                                        setListJefe(ordenDocument);
                                        setOrdenarJsala(false);
                                      }
                                    }}>
                                    CORREO
                                  </Th>
                                  <Th fontSize={'1.6rem'}>
                                    TIPO DOCUMENTO
                                  </Th>
                                  <Th fontSize={'1.6rem'}>
                                    DOCUMENTO
                                  </Th>
                                  <Th fontSize={'1.6rem'}>
                                    FECHA NACIMIENTO
                                  </Th>
                                  <Th isNumeric fontSize={'1.6rem'}>
                                    ACCIONES
                                  </Th>
                                </Tr>
                              </Thead>

                              <Tbody fontSize={'1.5rem'}>
                                {ListFiltered02().map((p , index) => {
                                  return (
                                    <Tr
                                    // key={p.id_colaborador}
                                    >
                                      <Td>{index+1}</Td>
                                      <Td>{p.apellidos}</Td>
                                      <Td>{p.nombres}</Td>
                                      <Td>{p.telefono}</Td>
                                      <Td>{p.correo}</Td>
                                      <Td>{p.tipo_documento}</Td>
                                      <Td>{p.documento}</Td>

                                      <Td>{p.fecha_nacimiento}</Td>
                                      <Td
                                        fontWeight={'600'}
                                        fontFamily='monospace'
                                        isNumeric>
                                        <Flex
                                          justifyContent={'right'}
                                          gap={'4%'}>
                                          <Tooltip
                                            hasArrow
                                            borderRadius={'4px'}
                                            fontSize={'1.2rem'}
                                            label='Editar'
                                            bg='#292F36'>
                                            <IconButton
                                              onClick={() => {
                                                onOpenEditarJefe();
                                                setJefe(p);
                                              }}
                                              size={'md'}
                                              borderRadius={'4px'}
                                              fontSize={'1.5rem'}
                                              background={'#f59f2c'}
                                              color={'#ffffff'}
                                              aria-label='Editar'
                                              icon={<RiEditFill />}
                                            />
                                          </Tooltip>
                                          {/* ------------------------ */}
                                          <Tooltip
                                            hasArrow
                                            borderRadius={'4px'}
                                            fontSize={'1.2rem'}
                                            label='Info'
                                            bg='#292F36'>
                                            <IconButton
                                              onClick={() => {
                                                onOpenInfoJefe();
                                                setSala(p.id_persona);
                                              }}
                                              // onClick={onOpenInfoJefe}
                                              size={'md'}
                                              borderRadius={'4px'}
                                              fontSize={'1.5rem'}
                                              background={'#2a2e6e'}
                                              color={'#ffffff'}
                                              aria-label='info'
                                              icon={
                                                <RiInformationFill />
                                              }
                                            />
                                          </Tooltip>
                                          {/* ------------------------ */}
                                          <Tooltip
                                            hasArrow
                                            borderRadius={'4px'}
                                            fontSize={'1.2rem'}
                                            label='Asignar sala'
                                            bg='#292F36'>
                                            <IconButton
                                              onClick={() => {
                                                asignaAdmin.id_persona =
                                                  p.id_persona;
                                                onOpenAsignSalaJefe();
                                              }}
                                              // onClick={
                                              //   onOpenAsignSalaJefe
                                              // }
                                              // size={'md'}
                                              borderRadius={'4px'}
                                              fontSize={'1.5rem'}
                                              background={'#2b752d'}
                                              color={'#ffffff'}
                                              aria-label='asignar sala'
                                              icon={
                                                <MdAssignmentAdd />
                                              }
                                            />
                                          </Tooltip>
                                          {/* ------------------------ */}
                                          <Tooltip
                                            hasArrow
                                            borderRadius={'4px'}
                                            fontSize={'1.2rem'}
                                            label='Quitar'
                                            bg='#292F36'>
                                            <IconButton
                                              onClick={() => {
                                                eliminar(
                                                  p.id_persona
                                                );
                                              }}
                                              size={'md'}
                                              borderRadius={'4px'}
                                              fontSize={'1.5rem'}
                                              background={'red'}
                                              color={'#ffffff'}
                                              aria-label='Quitar'
                                              icon={
                                                <MdRemoveCircle />
                                              }
                                            />
                                          </Tooltip>
                                        </Flex>
                                      </Td>
                                    </Tr>
                                  );
                                })}
                              </Tbody>
                            </Table>
                          </TableContainer>

                          <Flex
                            marginTop={'0.5%'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            gap={'0.5%'}>
                            <Icon
                              onClick={() => {
                                prevPage();
                              }}
                              color={'#13235b'}
                              cursor={'pointer'}
                              boxSize='16'
                              _hover={{
                                color: 'gray !important'
                              }}
                              _dark={{
                                color: '#f59f2c'
                              }}
                              as={IoMdArrowDropleftCircle}
                            />
                            <Icon
                              onClick={() => {
                                nextPage();
                              }}
                              color={'#13235b'}
                              cursor={'pointer'}
                              boxSize='16'
                              _hover={{
                                color: 'gray !important'
                              }}
                              _dark={{
                                color: '#f59f2c'
                              }}
                              as={IoMdArrowDroprightCircle}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Box>
      </Box>

      {/* ---------------------------Administradores----------------------------------- */}

      {/* ------------------------Agregar Administrador--------------------- */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '32%', xl: '40%', lg: '45%', md: '75%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Añadir Administrador
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex gap={'12px'} direction={'column'}>
              <Flex w={'100%'} gap={'20px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Nombres:
                  </FormLabel>
                  <Input
                    name='nombres'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={handleChangeAdministrador}
                    value={administrador.nombres}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Apellidos:
                  </FormLabel>
                  <Input
                    name='apellidos'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={handleChangeAdministrador}
                    value={administrador.apellidos}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'20px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Tipo Documeto:
                  </FormLabel>
                  <Select
                    name='tipo_documento'
                    w='100%'
                    fontSize={'1.6rem'}
                    height={'35px'}
                    fontFamily='monospace'
                    fontWeight={'600'}
                    placeholder='Seleccionar'
                    textTransform='uppercase'
                    variant='filled'
                    value={administrador.tipo_documento}
                    onChange={handleChangeAdministrador}>
                    <option value='DNI'>DNI</option>
                    <option value='RUC'>RUC</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Numero Documento:
                  </FormLabel>
                  <Input
                    name='documento'
                    fontSize={'1.8rem'}
                    h='35'
                    type='number'
                    onChange={handleChangeAdministrador}
                    value={administrador.documento}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'20px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Telefono:
                  </FormLabel>
                  <Input
                    name='telefono'
                    fontSize={'1.8rem'}
                    h='35'
                    type='number'
                    onChange={handleChangeAdministrador}
                    value={administrador.telefono}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'20px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Correo:
                  </FormLabel>
                  <Input
                    name='correo'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={handleChangeAdministrador}
                    value={administrador.correo}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Fecha de Nacimiento:
                  </FormLabel>
                  <Input
                    name='fecha_nacimiento'
                    fontSize={'1.8rem'}
                    h='35'
                    type='date'
                    onChange={handleChangeAdministrador}
                    value={administrador.fecha_nacimiento}
                  />
                </FormControl>
              </Flex>
              <FormControl>
                <FormLabel fontSize={'2rem'} fontWeight='500'>
                  Clave del Sistema:
                </FormLabel>
                <Input
                  name='clave_sistema'
                  fontSize={'1.8rem'}
                  h='35'
                  type='text'
                  onChange={handleChangeAdministrador}
                  value={administrador.clave_sistema}
                />
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex width={'100%'} justifyContent={'center'}>
              <Button
                onClick={(e) => {
                  administrador.roles.push({
                    id: 3,
                    nombre: 'ADMINISTRADOR'
                  });
                  crearAdministrador(e);
                }}
                borderRadius={'8px'}
                textTransform='uppercase'
                color={'white'}
                fontSize={'1.6rem'}
                bg='#2a2e6e'
                _hover={{
                  color: 'white',
                  bg: '#f59f2c'
                }}
                p='2%'>
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* ------------------------Editar Administrador--------------------- */}
      <Modal isOpen={isOpenEAdmin} onClose={onCloseEAdmin} isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '32%', xl: '40%', lg: '45%', md: '75%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Editar Administrador
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex gap={'12px'} direction={'column'}>
              <Flex w={'100%'} gap={'20px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Nombres:
                  </FormLabel>
                  <Input
                    name='nombres'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={handleChangeAdministrador}
                    value={administrador.nombres}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Apellidos:
                  </FormLabel>
                  <Input
                    name='apellidos'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={handleChangeAdministrador}
                    value={administrador.apellidos}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'20px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Tipo Documeto:
                  </FormLabel>
                  <Select
                    name='tipo_documento'
                    w='100%'
                    fontSize={'1.6rem'}
                    height={'35px'}
                    fontFamily='monospace'
                    fontWeight={'600'}
                    placeholder='Seleccionar'
                    textTransform='uppercase'
                    variant='filled'
                    onChange={handleChangeAdministrador}
                    value={administrador.tipo_documento}>
                    <option
                      value='DNI'
                      selected={
                        administrador.tipo_documento == 'DNI'
                      }>
                      DNI
                    </option>
                    <option
                      value='RUC'
                      selected={
                        administrador.tipo_documento == 'RUC'
                      }>
                      RUC
                    </option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Numero Documento:
                  </FormLabel>
                  <Input
                    name='documento'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={handleChangeAdministrador}
                    value={administrador.documento}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'20px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Telefono:
                  </FormLabel>
                  <Input
                    name='telefono'
                    fontSize={'1.8rem'}
                    h='35'
                    type='number'
                    onChange={handleChangeAdministrador}
                    value={administrador.telefono}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'20px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Correo:
                  </FormLabel>
                  <Input
                    name='correo'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={handleChangeAdministrador}
                    value={administrador.correo}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Fecha de Nacimiento:
                  </FormLabel>
                  <Input
                    name='fecha_nacimiento'
                    fontSize={'1.8rem'}
                    h='35'
                    type='date'
                    onChange={handleChangeAdministrador}
                    value={administrador.fecha_nacimiento}
                  />
                </FormControl>
              </Flex>
              <FormControl>
                <FormLabel fontSize={'2rem'} fontWeight='500'>
                  Clave del Sistema:
                </FormLabel>
                <Input
                  name='clave_sistema'
                  fontSize={'1.8rem'}
                  h='35'
                  type='text'
                  onChange={handleChangeAdministrador}
                  value={administrador.clave_sistema}
                />
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex width={'100%'} justifyContent={'center'}>
              <Button
                onClick={updateAdministrador}
                borderRadius={'8px'}
                textTransform='uppercase'
                color={'white'}
                fontSize={'1.6rem'}
                bg='#2a2e6e'
                _hover={{
                  color: 'white',
                  bg: '#f59f2c'
                }}
                p='2%'>
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* ------------------------Info Sala--------------------- */}
      <Modal
        isOpen={isOpenInfoSala}
        onClose={onCloseInfoSala}
        isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '20%', xl: '25%', lg: '40%', md: '65%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Informacion Sala
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <TableContainer w={'100%'}>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th fontSize={'1.6rem'}>SALA</Th>
                    <Th isNumeric fontSize={'1.6rem'}>
                      ACCIONES
                    </Th>
                  </Tr>
                </Thead>

                <Tbody fontSize={'1.5rem'}>
                  {listHallAdministrador?.map((itemSala) => (
                    <Tr>
                      <Td>{itemSala.sala.nombre}</Td>

                      <Td
                        fontWeight={'600'}
                        fontFamily='monospace'
                        isNumeric>
                        <Flex justifyContent={'right'} gap={'4%'}>
                          <Tooltip
                            hasArrow
                            borderRadius={'4px'}
                            fontSize={'1.2rem'}
                            label='Quitar'
                            bg='#292F36'>
                            <IconButton
                              onClick={() => {
                                deleteAdministradorHall(itemSala.id);
                              }}
                              size={'md'}
                              borderRadius={'4px'}
                              fontSize={'1.5rem'}
                              background={'#be0000'}
                              color={'#ffffff'}
                              aria-label='Quitar'
                              icon={<MdRemoveCircle />}
                            />
                          </Tooltip>
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* ------------------------Asignar Sala--------------------- */}
      <Modal
        isOpen={isOpenAsignarSala}
        onClose={onCloseAsignarSala}
        isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '25%', xl: '25%', lg: '50%', md: '75%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Asignar Sala
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex direction={'column'} gap={'15px'}>
              <TableContainer w={'100%'}>
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th fontSize={'1.6rem'}>SALA</Th>
                      <Th isNumeric fontSize={'1.6rem'}>
                        ACCIONES
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody fontSize={'1.5rem'}>
                    {salaContrato?.map((ItemSala) => (
                      <Tr>
                        <Td>{ItemSala.sala.nombre}</Td>

                        <Td
                          fontWeight={'600'}
                          fontFamily='monospace'
                          isNumeric>
                          <Flex justifyContent={'right'} gap={'4%'}>
                            <Tooltip
                              hasArrow
                              borderRadius={'4px'}
                              fontSize={'1.2rem'}
                              label='Asignar'
                              bg='#292F36'>
                              <IconButton
                                onClick={(e) => {
                                  asignaAdmin.id_sala =
                                    ItemSala.sala.id_sala;
                                  asignarAdministrador(e);
                                }}
                                size={'md'}
                                borderRadius={'4px'}
                                fontSize={'1.5rem'}
                                background={'#2b752d'}
                                color={'#ffffff'}
                                aria-label='Asignar'
                                icon={<MdAssignmentAdd />}
                              />
                            </Tooltip>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>

              <Flex
                marginTop={'0.5%'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={'0.5%'}>
                <Icon
                  onClick={() => {
                    prevPage();
                  }}
                  color={'#13235b'}
                  cursor={'pointer'}
                  boxSize='16'
                  _hover={{
                    color: 'gray !important'
                  }}
                  _dark={{
                    color: '#f59f2c'
                  }}
                  as={IoMdArrowDropleftCircle}
                />
                <Icon
                  onClick={() => {
                    nextPage();
                  }}
                  color={'#13235b'}
                  cursor={'pointer'}
                  boxSize='16'
                  _hover={{
                    color: 'gray !important'
                  }}
                  _dark={{
                    color: '#f59f2c'
                  }}
                  as={IoMdArrowDroprightCircle}
                />
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* ---------------------------Jefe de sala----------------------------------- */}

      {/* ------------------------Agregar Jefe--------------------- */}
      <Modal
        isOpen={isOpenAgregarJefe}
        onClose={onCloseAgregarJefe}
        isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '32%', xl: '40%', lg: '50%', md: '75%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Añadir Jefe de Sala
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex gap={'12px'} direction={'column'}>
              <Flex w={'100%'} gap={'20px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Nombres:
                  </FormLabel>
                  <Input
                    name='nombres'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={changeJefe}
                    value={ModelJefe.nombres}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Apellidos:
                  </FormLabel>
                  <Input
                    name='apellidos'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={changeJefe}
                    value={ModelJefe.apellidos}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'20px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Tipo Documeto:
                  </FormLabel>
                  <Select
                    value={ModelJefe.tipo_documento}
                    onChange={changeJefe}
                    name='tipo_documento'
                    w='100%'
                    fontSize={'1.6rem'}
                    height={'35px'}
                    fontFamily='monospace'
                    fontWeight={'600'}
                    placeholder='Seleccionar'
                    textTransform='uppercase'
                    variant='filled'>
                    <option value='DNI'>DNI</option>
                    <option value='RUC'>RUC</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Numero Documento:
                  </FormLabel>
                  <Input
                    name='documento'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={changeJefe}
                    value={ModelJefe.documento}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'20px'}>
                {/* <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Dirrecion:
                  </FormLabel>
                  <Input
                    name="Correo"
                    fontSize={"1.8rem"}
                    h="35"
                    type="text"
                    // onChange={handleChangeModule}
                    // value={module.nombre}
                  />
                </FormControl> */}

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Telefono:
                  </FormLabel>
                  <Input
                    name='telefono'
                    fontSize={'1.8rem'}
                    h='35'
                    type='number'
                    onChange={changeJefe}
                    value={ModelJefe.telefono}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'20px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Correo:
                  </FormLabel>
                  <Input
                    name='correo'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    onChange={changeJefe}
                    value={ModelJefe.correo}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Fecha de Nacimiento:
                  </FormLabel>
                  <Input
                    name='fecha_nacimiento'
                    fontSize={'1.8rem'}
                    h='35'
                    type='date'
                    onChange={changeJefe}
                    value={ModelJefe.fecha_nacimiento}
                  />
                </FormControl>
              </Flex>
              <FormControl>
                <FormLabel fontSize={'2rem'} fontWeight='500'>
                  Clave del Sistema:
                </FormLabel>
                <Input
                  name='clave_sistema'
                  fontSize={'1.8rem'}
                  h='35'
                  type='text'
                  onChange={changeJefe}
                  value={ModelJefe.clave_sistema}
                />
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex width={'100%'} justifyContent={'center'}>
              <Button
                onClick={(e) => {
                  ModelJefe.roles.push({
                    id: 4,
                    nombre: 'JEFE DE SALA'
                  });
                  crearJefe(e);
                  onCloseAgregarJefe();
                }}
                borderRadius={'8px'}
                textTransform='uppercase'
                color={'white'}
                fontSize={'1.6rem'}
                bg='#2a2e6e'
                _hover={{
                  color: 'white',
                  bg: '#f59f2c'
                }}
                p='2%'>
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* ------------------------Editar Jefe--------------------- */}
      <Modal
        isOpen={isOpenEditarJefe}
        onClose={onCloseEditarJefe}
        isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '32%', xl: '40%', lg: '50%', md: '75%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Editar Jefe de Sala
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex gap={'12px'} direction={'column'}>
              <Flex w={'100%'} gap={'20px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Nombres:
                  </FormLabel>
                  <Input
                    name='nombres'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    value={updateJefe.nombres}
                    onChange={changeUpdate}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Apellidos:
                  </FormLabel>
                  <Input
                    name='apellidos'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    value={updateJefe.apellidos}
                    onChange={changeUpdate}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'20px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Tipo Documeto:
                  </FormLabel>
                  <Select
                    value={updateJefe.tipo_documento}
                    onChange={changeUpdate}
                    name='tipo_documento'
                    w='100%'
                    fontSize={'1.6rem'}
                    height={'35px'}
                    fontFamily='monospace'
                    fontWeight={'600'}
                    placeholder='Seleccionar'
                    textTransform='uppercase'
                    variant='filled'>
                    <option value='DNI'>DNI</option>
                    <option value='RUC'>RUC</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Numero Documento:
                  </FormLabel>
                  <Input
                    name='documento'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    value={updateJefe.documento}
                    onChange={changeUpdate}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'20px'}>
                {/* <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Dirrecion:
                  </FormLabel>
                  <Input
                    name="Correo"
                    fontSize={"1.8rem"}
                    h="35"
                    type="text"
                    // onChange={handleChangeModule}
                    // value={module.nombre}
                  />
                </FormControl> */}

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Telefono:
                  </FormLabel>
                  <Input
                    name='telefono'
                    fontSize={'1.8rem'}
                    h='35'
                    type='number'
                    value={updateJefe.telefono}
                    onChange={changeUpdate}
                  />
                </FormControl>
              </Flex>

              <Flex w={'100%'} gap={'20px'}>
                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Correo:
                  </FormLabel>
                  <Input
                    name='correo'
                    fontSize={'1.8rem'}
                    h='35'
                    type='text'
                    value={updateJefe.correo}
                    onChange={changeUpdate}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'2rem'} fontWeight='500'>
                    Fecha de Nacimiento:
                  </FormLabel>
                  <Input
                    name='fecha_nacimiento'
                    fontSize={'1.8rem'}
                    h='35'
                    type='date'
                    value={updateJefe.fecha_nacimiento}
                    onChange={changeUpdate}
                  />
                </FormControl>
              </Flex>
              <FormControl>
                <FormLabel fontSize={'2rem'} fontWeight='500'>
                  Clave del Sistema:
                </FormLabel>
                <Input
                  name='clave_sistema'
                  fontSize={'1.8rem'}
                  h='35'
                  type='text'
                  value={updateJefe.clave_sistema}
                  onChange={changeUpdate}
                />
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex width={'100%'} justifyContent={'center'}>
              <Button
                onClick={editarJefe}
                borderRadius={'8px'}
                textTransform='uppercase'
                color={'white'}
                fontSize={'1.6rem'}
                bg='#2a2e6e'
                _hover={{
                  color: 'white',
                  bg: '#f59f2c'
                }}
                p='2%'>
                Guardar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* ------------------------Info Jefe  salas --------------------- */}
      <Modal
        isOpen={isOpenInfoJefe}
        onClose={onCloseInfoJefe}
        isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '20%', xl: '70%', lg: '80%', md: '95%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Informacion Jefe de Sala
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <TableContainer w={'100%'}>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th fontSize={'1.6rem'}>SALA</Th>
                    <Th isNumeric fontSize={'1.6rem'}>
                      ACCIONES
                    </Th>
                  </Tr>
                </Thead>

                <Tbody fontSize={'1.5rem'}>
                  {ListFiltered03().map((p) => (
                    <Tr>
                      <Td>{p.sala.nombre}</Td>

                      <Td
                        fontWeight={'600'}
                        fontFamily='monospace'
                        isNumeric>
                        <Flex justifyContent={'right'} gap={'4%'}>
                          <Tooltip
                            hasArrow
                            borderRadius={'4px'}
                            fontSize={'1.2rem'}
                            label='Quitar'
                            bg='#292F36'>
                            <IconButton
                              onClick={() => {
                                // onOpen2();
                                quitar(p.id);
                              }}
                              size={'md'}
                              borderRadius={'4px'}
                              fontSize={'1.5rem'}
                              background={'#be0000'}
                              color={'#ffffff'}
                              aria-label='Quitar'
                              icon={<MdRemoveCircle />}
                            />
                          </Tooltip>
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* ------------------------Asignar  Sala a jefe de sala--------------------- */}
      <Modal
        isOpen={isOpenAsignSalaJefe}
        onClose={onCloseAsignSalaJefe}
        isCentered>
        <ModalOverlay />
        <ModalContent
          maxW={{ '2xl': '25%', xl: '70%', lg: '80%', md: '95%' }}
          p={'1%'}>
          <ModalHeader fontSize={'2rem'} textTransform='uppercase'>
            Asignar Sala Jefe
          </ModalHeader>
          <ModalCloseButton fontSize={'1.2rem'} />
          <ModalBody>
            <Flex direction={'column'} gap={'15px'}>
              <Flex width={'100%'}>
                <Stack w={'100%'}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents='none'
                      children={
                        <Search2Icon
                          color='#2a2e6e'
                          marginLeft={'20%'}
                          fontSize={'1.25rem'}
                          marginTop={'60%'}
                          _dark={{
                            color: '#f59f2c'
                          }}
                        />
                      }
                    />
                    <Input
                      // onChange={buscar}
                      type='text'
                      placeholder='Buscar'
                      bg={'white'}
                      height={'40px'}
                      _focus={{
                        borderColor: '#2a2e6e',
                        boxShadow: 'none'
                      }}
                      color='#2a2e6e'
                      fontSize={'1.9rem'}
                      border={'1px solid'}
                      borderColor={'#2a2e6e'}
                      borderRadius='6px'
                      _placeholder={{
                        color: 'rgb(152, 152, 152)'
                      }}
                      _dark={{
                        color: 'white',
                        borderColor: '#686868',
                        bg: '#686868'
                      }}
                    />
                  </InputGroup>
                </Stack>
              </Flex>

              <TableContainer w={'100%'}>
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th fontSize={'1.6rem'}>SALA</Th>
                      <Th isNumeric fontSize={'1.6rem'}>
                        ACCIONES
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody fontSize={'1.5rem'}>
                    {salaContrato?.map((ItemSala) => (
                      <Tr>
                        <Td>{ItemSala.sala.nombre}</Td>

                        <Td
                          fontWeight={'600'}
                          fontFamily='monospace'
                          isNumeric>
                          <Flex justifyContent={'right'} gap={'4%'}>
                            <Tooltip
                              hasArrow
                              borderRadius={'4px'}
                              fontSize={'1.2rem'}
                              label='Asignar'
                              bg='#292F36'>
                              <IconButton
                                onClick={(e) => {
                                  asignaAdmin.id_sala =
                                    ItemSala.sala.id_sala;
                                  asignarAdministrador(e);
                                }}
                                size={'md'}
                                borderRadius={'4px'}
                                fontSize={'1.5rem'}
                                background={'#2b752d'}
                                color={'#ffffff'}
                                aria-label='Asignar'
                                icon={<MdAssignmentAdd />}
                              />
                            </Tooltip>
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>

              <Flex
                marginTop={'0.5%'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={'0.5%'}>
                <Icon
                  onClick={() => {
                    prevPage();
                  }}
                  color={'#13235b'}
                  cursor={'pointer'}
                  boxSize='16'
                  _hover={{
                    color: 'gray !important'
                  }}
                  _dark={{
                    color: '#f59f2c'
                  }}
                  as={IoMdArrowDropleftCircle}
                />
                <Icon
                  onClick={() => {
                    nextPage();
                  }}
                  color={'#13235b'}
                  cursor={'pointer'}
                  boxSize='16'
                  _hover={{
                    color: 'gray !important'
                  }}
                  _dark={{
                    color: '#f59f2c'
                  }}
                  as={IoMdArrowDroprightCircle}
                />
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Administracion;